import { useState } from 'react';

import { Box, Button, DialogContent, Typography } from '@mui/material';

import { Modal } from 'components/molecules';

export default function UpdateAvailable({ updateAvailable, latestVersion }) {
  const [open, setOpen] = useState(updateAvailable);

  function updateApplication() {
    localStorage.setItem('appVersion', latestVersion);
    window.location.reload(true);
  }

  return (
    <Modal
      title="Update available"
      open={updateAvailable ?? open}
      setClose={() => setOpen(false)}
      maxWidth="sm"
      fullWidth
    >
      <DialogContent>
        Update available. Reload your browser to see the latest version.
        <Typography>
          <ul>
            <li>
              New Programs:
              <ul>
                <li>Monitoring Only - Adult</li>
                <ul>
                  <li>Daily Measurements</li>
                  <ul>
                    <li>Fluency Technique and Stuttering Severity</li>
                    <li>0-8 Raters with Guides</li>
                  </ul>
                  <li>Audio Sample</li>
                </ul>
                <li>
                  Monitoring Only - Child
                  <ul>
                    <li>Daily Measurements</li>
                    <ul>
                      <li>0-10 Raters with Guides</li>
                    </ul>
                    <li>Audio Sample</li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>Change Program (e.g. Lidcombe --{'>'} Westmead)</li>
          </ul>
        </Typography>
      </DialogContent>
      <Box display="flex" width="100%" justifyContent="center">
        <Button variant="contained" onClick={updateApplication}>
          Reload
        </Button>{' '}
      </Box>
    </Modal>
  );
}

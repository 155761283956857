import React from 'react';

import { Close } from '@mui/icons-material';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Paper,
  Stack
} from '@mui/material';

export default function Modal({
  setClose,
  open,
  fullWidth,
  maxWidth,
  title,
  text,
  children,
  disableExit
}) {
  const handleClose = (event, reason) => {
    if (disableExit && reason && reason === 'backdropClick') return;
    setClose();
  };

  return (
    <React.Fragment>
      <Dialog
        disableEscapeKeyDown={disableExit}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        <Paper>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            {title && <DialogTitle>{title}</DialogTitle>}
            <IconButton
              disabled={disableExit}
              aria-label="close"
              onClick={handleClose}
              sx={{
                mr: 2
              }}
            >
              <Close />
            </IconButton>
          </Stack>
          <Divider sx={{ borderColor: 'rgba(0, 66, 130, 0.3)' }} />
          <DialogContent>
            {text && <DialogContentText>{text} </DialogContentText>}
            {children}
          </DialogContent>
        </Paper>
      </Dialog>
    </React.Fragment>
  );
}

import { Alert, AlertTitle, Link } from '@mui/material';
import { constants } from 'common/constants';

export default function ErrorMessage({ message, noBody, severity }) {
  return (
    <Alert severity={severity || 'error'}>
      <AlertTitle>{message}</AlertTitle>
      {!noBody && (
        <>
          {'Please try again or '}
          <Link href={constants.contactUrl}>contact us</Link>
        </>
      )}
    </Alert>
  );
}

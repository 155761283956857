import { useAuthenticator } from '@aws-amplify/ui-react-core';
import { ArrowForward } from '@mui/icons-material';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';

export default function SessionRow({ session, disabled }) {
  const { user } = useAuthenticator(context => [context.user]);
  const isClinician = user.attributes['custom:type'] === 'clinician';
  const navigate = useNavigate();
  const sessionDate = DateTime.fromISO(session.date);

  const FadingOverlay = styled(Box)(({ theme }) => ({
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: '100%',
    background: `linear-gradient(to top, ${theme.palette.background.paper}, transparent)`
  }));

  function deltaToPlainText(deltaOps) {
    try {
      let text = '';
      deltaOps.forEach(op => {
        if (typeof op.insert === 'string') {
          text += op.insert;
        }
      });
      return text;
    } catch (error) {
      console.error('Error processing Delta:', error);
      return '';
    }
  }

  const previewText = session?.notes
    ? deltaToPlainText(JSON.parse(session?.notes))
    : null;

  const firstTwoLines = previewText
    ? previewText.split('\n').slice(0, 2).join('\n')
    : null;

  return (
    <Box p={1}>
      <Card elevation={4}>
        <CardHeader
          sx={{ display: 'flex', mb: 0, pb: 0 }}
          disableTypography
          action={
            <IconButton
              disabled={disabled}
              onClick={() =>
                navigate(
                  isClinician
                    ? `/client/session/${session.id}`
                    : `/session/${session.id}`
                )
              }
              aria-label="settings"
            >
              <ArrowForward />
            </IconButton>
          }
          title={
            <Typography variant="h6">
              {sessionDate.weekdayLong}, {sessionDate.day}{' '}
              {sessionDate.monthLong} {sessionDate.year}
            </Typography>
          }
        />

        <CardContent
          sx={{
            position: 'relative',
            maxHeight: 100,
            mt: 0,
            paddingBottom: '16px!important',
            paddingTop: 1,
            overflow: 'hidden'
          }}
        >
          <Typography variant="body1" component="p">
            {firstTwoLines}
          </Typography>
          <FadingOverlay />
        </CardContent>
      </Card>
    </Box>
  );
}

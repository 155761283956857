import { useEffect, useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { Close, Delete } from '@mui/icons-material';
import {
  Box,
  Card,
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  TextField
} from '@mui/material';
import { blue, orange, pink, purple, red, teal } from '@mui/material/colors';
import { useAppContext } from 'context';

import { formatAudioTime } from 'utils/formatTime';

export default function Annotation(props) {
  const {
    note,
    onDelete,
    onSave,
    region,
    setAnnotations,
    onSetColor,
    onSetCategory,
    categories,
    settings
  } = props;
  const [text, setText] = useState(note || '');
  const [selectedColor, setSelectedColor] = useState(region.color);
  const [isUserChanged, setIsUserChanged] = useState(false);
  const [annotationCategory, setAnnotationCategory] = useState(
    categories ?? []
  );
  const { state } = useAppContext();
  const { user } = useAuthenticator(context => [context.user]);
  const program = state?.client?.program || user.attributes['custom:program'];

  useEffect(() => {
    setText(note);
  }, [note]);

  useEffect(() => {
    saveAnnotation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text]);

  const saveAnnotation = () => {
    if (text && isUserChanged) {
      onSave?.(region.id, text);
      setIsUserChanged(false);
    }
  };

  const deleteAnnotation = () => {
    onDelete?.(region.id);
  };

  const handleColorChange = event => {
    setSelectedColor(event.target.value);
    onSetColor(region.id, event.target.value);
  };

  const handleCategoryChange = selectedCategory => {
    if (!selectedCategory) return;

    let updatedCategories = Array.isArray(annotationCategory)
      ? [...annotationCategory]
      : [];

    if (updatedCategories.includes(selectedCategory)) {
      updatedCategories = updatedCategories.filter(
        category => category !== selectedCategory
      );
    } else {
      updatedCategories.push(selectedCategory); // Make sure selectedCategory itself isn't an array
    }

    setAnnotationCategory(updatedCategories);
    onSetCategory(region.id, updatedCategories);
  };

  const radioColors = [
    { value: 'rgba(66, 165, 245, 0.50)', color: blue[400] },
    { value: 'rgba(38, 166, 154, 0.50)', color: teal[400] },
    { value: 'rgba(255, 167, 38, 0.50)', color: orange[400] },
    { value: 'rgba(239, 83, 80, 0.50)', color: red[400] },
    { value: 'rgba(233, 30, 99, 0.50)', color: pink[500] },
    { value: 'rgba(171, 71, 188, 0.50)', color: purple[400] }
  ];

  const camperdownLabels = ['Block', 'Prolongation', 'Repetition'];
  const defaultLabels = ['Block', 'Prolongation', 'Repetition'];
  const labels = program.includes('camperdown')
    ? camperdownLabels
    : defaultLabels;

  return (
    <Card
      sx={{ my: 2, p: 2, backgroundColor: 'background.card' }}
      elevation={0}
    >
      <Stack
        direction="row"
        sx={{ justifyContent: 'space-between', alignItems: 'center', mb: 2 }}
      >
        <FormLabel>
          {formatAudioTime(region.start)} - {formatAudioTime(region.end)}
        </FormLabel>
        <Stack direction="row">
          <FormControl component="fieldset">
            <RadioGroup
              row
              aria-label="color"
              name="row-radio-buttons-group"
              value={selectedColor}
              onChange={handleColorChange}
            >
              {radioColors.map(({ value, color }) => (
                <FormControlLabel
                  key={value}
                  value={value}
                  control={<Radio style={{ color }} />}
                  label=""
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Stack>
        <Stack direction="row" spacing={2}>
          <IconButton onClick={() => deleteAnnotation()}>
            <Delete fontSize="small" />
          </IconButton>
          <IconButton
            onClick={() =>
              setAnnotations(prevAnnotations =>
                prevAnnotations.map(annotation =>
                  annotation.isActive
                    ? { ...annotation, isActive: false }
                    : annotation
                )
              )
            }
          >
            <Close />
          </IconButton>
        </Stack>
      </Stack>
      <TextField
        variant="filled"
        fullWidth
        multiline
        value={text}
        onChange={evt => {
          setText(evt.target.value);
          setIsUserChanged(true);
        }}
        label="Annotation"
      />
      {settings.annotationLabels && (
        <Box display="flex" justifyContent="center">
          <Box
            sx={{ mt: 2 }}
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            spacing={1}
          >
            {labels.map(label => (
              <Chip
                key={label}
                sx={{ mb: 1, mr: 1 }}
                label={label}
                onClick={() => handleCategoryChange(label)}
                color={
                  annotationCategory?.includes(label) ? 'primary' : 'default'
                }
              />
            ))}
          </Box>
        </Box>
      )}
    </Card>
  );
}

import { useRef, useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { Close, Delete, DragIndicator, Settings } from '@mui/icons-material';
import {
  Box,
  Card,
  Collapse,
  Hidden,
  IconButton,
  InputLabel,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import { useAppContext } from 'context';
import { createUserInput } from 'graphql/mutations';
import useEffectOnce from 'hooks/useEffectOnce';
import _ from 'lodash';
import MuiMarkdown from 'mui-markdown';

const SectionSettings = ({
  settings,
  updateSetting,
  editTitle,
  setEditTitle
}) => {
  return (
    <Card
      elevation={0}
      sx={{ my: 1, px: 1, backgroundColor: 'background.settings' }}
    >
      <Stack
        direction="row"
        spacing={1}
        px={1}
        py={1}
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        <IconButton
          color="tertiary"
          size="small"
          onClick={() => setEditTitle(!editTitle)}
        >
          <Close color="primary" fontSize="small" />
        </IconButton>
        <Typography variant="caption">Rich Text Settings</Typography>
      </Stack>
      <Stack direction="row" spacing={1}>
        <TextField
          color="tertiary"
          variant="filled"
          size="small"
          label="Section Title"
          value={settings.name}
          placeholder="Training Model"
          onChange={e => updateSetting('name', e.target.value)}
        />
      </Stack>
    </Card>
  );
};

export default function ExerciseContent({
  userInputID,
  dragHandleProps = {},
  onDelete,
  record,
  componentID,
  componentType,
  componentOrder,
  name,
  content,
  onSettingChange
}) {
  const { state } = useAppContext();
  const [editTitle, setEditTitle] = useState();
  const [settings, setSettings] = useState({ name, content });
  const { user } = useAuthenticator(context => [context.user]);
  const clientID =
    state?.client?.id || user.attributes['custom:clientID'] || user.username;
  const clientSub = state?.client?.sub || user.attributes.sub;
  const userInputIdRef = useRef(userInputID);

  useEffectOnce(() => {
    if (
      componentID &&
      record &&
      record.id &&
      !state.edit &&
      !userInputIdRef.current
    ) {
      const newInput = {
        inputName: settings.name || '',
        inputValue: settings.content || '',
        recordID: record.id,
        recordUserInputsId: record.id,
        componentID,
        componentType,
        componentOrder,
        clientID: clientID,
        owner: `${clientSub}::${clientID}`,
        enteredAt: new Date().toISOString()
      };
      API.graphql(graphqlOperation(createUserInput, { input: newInput })).catch(
        err => {
          console.error('Error creating user input:', err);
        }
      );
    }
  }, [
    componentID,
    componentType,
    record,
    settings,
    state.edit,
    userInputIdRef.current
  ]);

  const handleUpdateSetting = (settingName, settingValue) => {
    setSettings(prevSettings =>
      _.set({ ...prevSettings }, settingName, settingValue)
    );
    onSettingChange?.(settingName, settingValue);
  };

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" alignItems="center" spacing={1}>
          {state.edit && (
            <IconButton
              onClick={() => setEditTitle(prevEditTitle => !prevEditTitle)}
            >
              <Settings
                size="small"
                color={editTitle ? 'secondary' : 'primary'}
              />
            </IconButton>
          )}
          <InputLabel sx={{ color: 'text.accent' }}>{settings.name}</InputLabel>
        </Stack>
        {state.edit && (
          <Stack direction="row" alignItems="center">
            <IconButton onClick={onDelete}>
              <Delete />
            </IconButton>
            <IconButton {...dragHandleProps}>
              <DragIndicator />
            </IconButton>
          </Stack>
        )}
      </Stack>
      <Collapse in={editTitle}>
        <Hidden smDown implementation="css">
          <SectionSettings
            settings={settings}
            updateSetting={handleUpdateSetting}
            setEditTitle={setEditTitle}
            editTitle={editTitle}
          />
        </Hidden>
      </Collapse>
      <Box mt={2}>
        <Collapse in={state.edit}>
          <Box>
            <TextField
              fullWidth
              label="Rich Text Display"
              InputLabelProps={{
                shrink: true
              }}
              multiline={state.edit ? true : false}
              placeholder="####The largest snake in the world is the anaconda. Anacondas belong to the boa constrictor family and are found in the Amazon Jungle. They usually live near rivers or lakes as they like to lie in muddy waters. They are not aggressive and will usually disappear into the water when confronted."
              onChange={event =>
                handleUpdateSetting('content', event.target.value)
              }
              rows={state.edit ? 6 : 1}
              value={settings.content}
              sx={{ my: state.edit ? 1 : 0 }}
            />
          </Box>
        </Collapse>
        {!state.edit && <MuiMarkdown>{`####${settings.content}`}</MuiMarkdown>}
      </Box>
    </>
  );
}

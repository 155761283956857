import { memo } from 'react';

import {
  Box,
  FormControlLabel,
  Stack,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import { updateEntry } from 'graphql/mutations';

function EntrySettings({
  logic,
  setLogic,
  addLogic,
  setAddLogic,
  entry,
  setEntry
}) {
  async function updateLogic(newAddLogic, newLogic) {
    setLogic(newLogic);
    setAddLogic(newAddLogic);
    const { data } = await API.graphql(
      graphqlOperation(updateEntry, {
        input: {
          id: entry.id,
          addLogic: newAddLogic,
          logic: newLogic,
          _version: entry._version
        }
      })
    );
    const updatedEntry = data.updateEntry;
    setEntry(updatedEntry);
  }

  return (
    <Box display="flex" alignItems="center">
      <FormControlLabel
        control={
          <Switch
            checked={addLogic}
            onChange={e => updateLogic(e.target.checked, logic)}
          />
        }
        label="Add Logic"
      />
      {addLogic && (
        <Stack spacing={1} direction="row" alignItems="center">
          <Typography variant="body2">Highlight every</Typography>
          <Box sx={{ width: '65px' }}>
            <TextField
              value={logic}
              onChange={e => updateLogic(addLogic, e.target.value)}
              type="number"
              size="small"
            />
          </Box>
          <Typography variant="body2"> cycles</Typography>
        </Stack>
      )}
    </Box>
  );
}

export default memo(EntrySettings);

import { useCallback } from 'react';

import { arrayMove } from '@dnd-kit/sortable';
import { API, graphqlOperation } from 'aws-amplify';
import { normalizeFactor } from 'common/constants';
import {
  entrySectionsByEntryIDByOrder,
  getRecord
} from 'graphql/customQueries';
import {
  createEntrySection,
  createRecord,
  createUserInput,
  updateEntry,
  updateEntrySection
} from 'graphql/mutations';
import {
  cleanUpRecordsUserInputs,
  componentsByEntrySectionByOrder,
  getEntry,
  userInputsByRecordComponentType
} from 'graphql/queries';
import mixpanel from 'mixpanel-browser';

export const useFetchRecord = (
  entry,
  entrySections,
  dispatch,
  handleCreateRecord
) => {
  const handleFetchRecord = useCallback(async () => {
    if (entry?.draftRecordID && entry.draftRecordID !== '-') {
      let record;
      const fetchRecord = async () => {
        try {
          const { data } = await API.graphql(
            graphqlOperation(getRecord, { id: entry.draftRecordID })
          );
          const record = data.getRecord;

          if (record.userInputs.items.length === 0) {
            const { data } = await API.graphql(
              graphqlOperation(userInputsByRecordComponentType, {
                recordID: record.id
              })
            );
            const userInputs = data.userInputsByRecordComponentType.items;
            record.userInputs.items = userInputs;
          }
          return record;
        } catch (error) {
          console.error('Error fetching record:', error);
          return null;
        }
      };

      record = await fetchRecord();

      function extractComponents(entrySections) {
        let allComponents = [];
        entrySections.forEach(section => {
          if (section.components && section.components.length > 0) {
            const incompleteComponents = section.components.filter(
              component => !component.complete
            );
            allComponents.push(...incompleteComponents);
          }
        });
        return allComponents;
      }

      if (!record?.id) {
        await new Promise(resolve => setTimeout(resolve, 1000));
        record = await fetchRecord();

        if (!record?.id) {
          await handleCreateRecord();
        }
      }

      if (record) {
        const components = extractComponents(entrySections);

        if (components.length === record?.userInputs?.items?.length) {
          dispatch({ type: 'set_record', record });
        } else {
          await new Promise(resolve => setTimeout(resolve, 500));
          record = await fetchRecord();
          if (record) {
            dispatch({ type: 'set_record', record });
          }
        }
      }
    }
  }, [entry, entrySections, dispatch, handleCreateRecord]);
  return handleFetchRecord;
};

export const useCreateRecord = (
  entryID,
  state,
  clientID,
  clientSub,
  setEntry,
  entry,
  dispatch,
  setLoading,
  user
) => {
  const handleCreateRecord = useCallback(async () => {
    if (!entryID) return;
    try {
      setLoading(true);
      const {
        data: { createRecord: createdRecord }
      } = await API.graphql(
        graphqlOperation(createRecord, {
          input: {
            entryID,
            clientID: clientID,
            sectionID: state.sectionId || entry.sectionID,
            createdBy: user.username,
            owner: `${clientSub}::${clientID}`,
            enteredAt: new Date().toISOString(),
            draft: true,
            hasData: 'false'
          }
        })
      );
      mixpanel.track('create_record', { entry_name: entry.name });
      window.dataLayer.push({
        event: 'create_record',
        entry_name: entry.name
      });

      await createUserInputsForRecord(
        entry,
        createdRecord,
        clientID,
        clientSub,
        dispatch
      );

      const {
        data: { updateEntry: updatedEntry }
      } = await API.graphql(
        graphqlOperation(updateEntry, {
          input: {
            id: entryID,
            draftRecordID: createdRecord.id,
            _version: entry._version
          }
        })
      );
      setEntry(updatedEntry);
    } catch (err) {
      console.error('Error creating record:', err);
    } finally {
      setLoading(false);
    }
  }, [
    entryID,
    state.sectionId,
    clientID,
    entry,
    clientSub,
    dispatch,
    setEntry,
    setLoading,
    user.username
  ]);
  return handleCreateRecord;
};

async function createUserInputsForRecord(
  entry,
  record,
  clientID,
  clientSub,
  dispatch
) {
  try {
    let components = [];
    for (let entrySection of entry.entrySections.items) {
      const { data } = await API.graphql(
        graphqlOperation(componentsByEntrySectionByOrder, {
          entrySectionID: entrySection.id
        })
      );
      const componentsInEntrySection =
        data.componentsByEntrySectionByOrder.items.filter(
          component => !component.complete
        );
      components = components.concat(componentsInEntrySection);
    }

    const userInputs = [];
    for (let component of components) {
      const { data } = await API.graphql(
        graphqlOperation(createUserInput, {
          input: {
            clientID: clientID,
            inputName: component.name,
            recordID: record.id,
            recordUserInputsId: record.id,
            componentID: component?.id,
            componentType: component?.type,
            componentOrder: component?.order,
            owner: `${clientSub}::${clientID}`,
            enteredAt: new Date().toISOString()
          }
        })
      );
      const userInput = data.createUserInput;
      userInputs.push(userInput);
    }

    record.userInputs.items = userInputs;

    dispatch({ type: 'set_record', record: record });
  } catch (err) {
    console.error('Error creating user inputs:', err);
  }
}

export async function deleteRecordAndUserInputs(recordID) {
  try {
    await API.graphql(graphqlOperation(cleanUpRecordsUserInputs, { recordID }));
  } catch (err) {
    console.error('Error deleting record:', err);
  }
}

export function useFetchEntry(id, setEntry) {
  return useCallback(async () => {
    const getEntryResult = await API.graphql(
      graphqlOperation(getEntry, { id })
    );
    let entry = getEntryResult.data.getEntry;
    setEntry(entry);
  }, [id, setEntry]);
}

export function useCreateEntrySection(
  clientID,
  clientSub,
  entryID,
  setEntrySections
) {
  return useCallback(
    async (entrySectionIdx, order) => {
      const result = await API.graphql(
        graphqlOperation(createEntrySection, {
          input: {
            entryID,
            entryEntrySectionsId: entryID,
            name: '',
            order,
            owner: `${clientSub}::${clientID}`,
            clientID: clientID
          }
        })
      );
      const createdEntrySection = result.data.createEntrySection;

      const normalizedCreatedEntrySection = {
        ...createdEntrySection,
        components: createdEntrySection.components
          ? Array.isArray(createdEntrySection.components)
            ? createdEntrySection.components
            : Array.isArray(createdEntrySection.components.items)
            ? createdEntrySection.components.items
            : []
          : []
      };

      setEntrySections(prevEntrySections => {
        const newEntrySections = prevEntrySections.slice();
        newEntrySections.splice(
          entrySectionIdx,
          0,
          normalizedCreatedEntrySection
        );
        return newEntrySections;
      });
    },
    [clientID, clientSub, entryID, setEntrySections]
  );
}

export function useDeleteEntrySection(setEntrySections) {
  return useCallback(
    async entrySection => {
      await API.graphql(
        graphqlOperation(updateEntrySection, {
          input: {
            id: entrySection.id,
            complete: true,
            _version: entrySection._version
          }
        })
      );
      setEntrySections(prevEntrySections =>
        prevEntrySections.filter(({ id }) => id !== entrySection.id)
      );
    },
    [setEntrySections]
  );
}

export function useDragEntrySection(entrySections, setEntrySections) {
  return useCallback(
    async ({ active, over }) => {
      console.log('active', active);
      console.log('over', over);
      if (over) {
        const overIndex = entrySections.findIndex(
          section => section.id === over.id
        );
        const activeIndex = entrySections.findIndex(
          section => section.id === active.id
        );
        if (activeIndex !== overIndex) {
          const newOrder =
            activeIndex < overIndex
              ? overIndex === entrySections.length - 1
                ? entrySections[overIndex].order + 1 * normalizeFactor
                : (entrySections[overIndex].order +
                    entrySections[overIndex + 1].order) /
                  2
              : (entrySections[overIndex].order +
                  (entrySections[overIndex - 1]?.order ?? 0)) /
                2;
          const dropppedEntrySection = {
            ...entrySections[activeIndex],
            order: newOrder
          };
          const { data } = await API.graphql(
            graphqlOperation(updateEntrySection, {
              input: {
                id: dropppedEntrySection.id,
                order: dropppedEntrySection.order,
                _version: dropppedEntrySection._version
              }
            })
          );
          const updatedEntrySection = data.updateEntrySection;
          setEntrySections(prevEntrySections =>
            arrayMove(prevEntrySections, activeIndex, overIndex).map(
              entrySection =>
                entrySection.id === updatedEntrySection.id
                  ? updatedEntrySection
                  : entrySection
            )
          );
        }
      }
    },
    [entrySections, setEntrySections]
  );
}

export function useFetchEntrySections(entryID, setEntrySections, setLoading) {
  return useCallback(async () => {
    try {
      let entrySections = (
        await API.graphql(
          graphqlOperation(entrySectionsByEntryIDByOrder, { entryID })
        )
      ).data.entrySectionsByEntryIDByOrder.items;

      const normalizedSections = entrySections.map(section => {
        if (section.components && Array.isArray(section.components)) {
          const filteredComponents = section.components.filter(
            component => !component.complete
          );
          return { ...section, components: filteredComponents };
        } else if (
          section.components &&
          Array.isArray(section.components.items)
        ) {
          const filteredComponentsItems = section.components.items.filter(
            component => !component.complete
          );
          return { ...section, components: filteredComponentsItems };
        }
        return { ...section, components: [] };
      });

      const incompleteSections = normalizedSections.filter(
        section => !section.complete
      );

      setEntrySections(incompleteSections);
    } catch (error) {
      console.error('Error fetching entry sections: ', error);
    } finally {
      setLoading(false);
    }
  }, [entryID, setEntrySections, setLoading]);
}

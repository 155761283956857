import { useEffect } from 'react';

import { Box } from '@mui/material';
import { Auth } from 'aws-amplify';

export default function OnboardClinician({ user }) {
  useEffect(() => {
    const setOnboardingState = async () => {
      try {
        await Auth.updateUserAttributes(user, {
          'custom:onboarding': '4'
        });
      } catch (error) {
        console.error('Error updating user attributes:', error);
      }
    };
    setOnboardingState();
  }, [user]);
  return <Box></Box>;
}

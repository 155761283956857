import { memo, useEffect, useState } from 'react';

import { Add } from '@mui/icons-material';
import { Masonry } from '@mui/lab';
import {
  Box,
  Button,
  Collapse,
  Container,
  Pagination,
  Stack,
  Typography
} from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import { listResources } from 'graphql/queries';

import { LoadingAtom } from 'components/atoms';
import { Clinician } from 'components/layout';
import { ResourceCard, SearchBar } from 'components/molecules';
import { AddResource, AddResourceToClient } from 'components/organisms';

function Resources() {
  const [select, setSelect] = useState(false);
  const [selectedResources, setSelectedResources] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [allResources, setAllResources] = useState(null);
  const [pageTokens, setPageTokens] = useState(['']);
  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const [openResourceEditor, setOpenResourceEditor] = useState(false);
  const [addToClient, setAddToClient] = useState(false);

  useEffect(() => {
    const fetchResources = async () => {
      const queryData = {
        limit: 9
      };
      if (search) {
        queryData.filter = {
          title: {
            contains: search
          }
        };
      }

      if (pageTokens[currentPageIndex - 1] !== '') {
        queryData.nextToken = pageTokens[currentPageIndex - 1];
      }
      const { data } = await API.graphql(
        graphqlOperation(listResources, queryData)
      );
      const { items, nextToken } = data.listResources;
      setAllResources(items);
      if (nextToken) {
        if (currentPageIndex === pageTokens.length) {
          setPageTokens([...pageTokens, nextToken]);
        }
      }
      setLoading(false);
    };
    fetchResources();
    return () => {
      setAllResources([]);
    };
  }, [currentPageIndex, search, openResourceEditor, pageTokens]);

  const resources = allResources;

  const chooseResource = resource => {
    const isResourceSelected = selectedResources.some(
      selectedResource => selectedResource.id === resource.id
    );

    if (isResourceSelected) {
      setSelectedResources(prevResources =>
        prevResources.filter(
          selectedResource => selectedResource.id !== resource.id
        )
      );
    } else {
      setSelectedResources(prevResources => [...prevResources, resource]);
    }
  };

  return (
    <Clinician
      props={{ title: 'Resources' }}
      AppBarComponents={
        <Box
          pl={4}
          width="100%"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" spacing={2}>
            <Typography color="text.primary" variant="h5">
              Resources
            </Typography>
          </Stack>
          <Stack direction="row" spacing={2} alignItems="center">
            {select && (
              <Button
                disabled={selectedResources.length === 0}
                onClick={() =>
                  setAddToClient(prevAddToClient => !prevAddToClient)
                }
              >
                Add to Client
              </Button>
            )}
            {select && (
              <Button
                disabled={selectedResources.length === 0}
                onClick={() => setSelectedResources([])}
              >
                Clear
              </Button>
            )}
            <Button
              color={select ? 'secondary' : 'primary'}
              onClick={() => setSelect(prevSelect => !prevSelect)}
            >
              {select ? 'Stop Selecting' : 'Select'}
            </Button>
            <Collapse orientation="horizontal" in={!select}>
              <Button
                sx={{ whiteSpace: 'nowrap' }}
                size="small"
                startIcon={<Add />}
                onClick={() => setOpenResourceEditor(true)}
              >
                Add Resource
              </Button>
            </Collapse>
            <SearchBar state={search} setState={setSearch} />
          </Stack>
        </Box>
      }
    >
      <AddResourceToClient
        setSelectedResources={setSelectedResources}
        selectedResources={selectedResources}
        state={addToClient}
        setState={setAddToClient}
      />
      <AddResource
        state={openResourceEditor}
        setState={setOpenResourceEditor}
      />
      <>
        {loading ? (
          <LoadingAtom />
        ) : (
          <Container maxWidth="xl">
            <Box mt={4}>
              <Masonry columns={{ xs: 1, sm: 2, md: 3 }} spacing={2}>
                {resources &&
                  resources.map(resource => (
                    <ResourceCard
                      onSelect={chooseResource}
                      selected={selectedResources.some(
                        selectedResource => selectedResource.id === resource.id
                      )}
                      select={select}
                      key={resource.id}
                      resource={resource}
                    />
                  ))}
              </Masonry>
              {resources?.length > 0 && (
                <Box mt={6} mb={2} display="flex" justifyContent="center">
                  <Pagination
                    count={pageTokens.length}
                    page={currentPageIndex}
                    siblingCount={1}
                    boundaryCount={1}
                    variant="outlined"
                    color="secondary"
                    onChange={(event, page) => setCurrentPageIndex(page)}
                  />
                </Box>
              )}
            </Box>
          </Container>
        )}
      </>
    </Clinician>
  );
}

export default memo(Resources);

import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer
} from 'react';

import { AppReducer, initialState } from './Reducer';

const AppContext = createContext();

export function AppWrapper({ children }) {
  const [state, dispatch] = useReducer(AppReducer, initialState);

  useEffect(() => {
    if (JSON.parse(sessionStorage.getItem('state'))) {
      dispatch({
        type: 'init_stored',
        sessionState: JSON.parse(sessionStorage.getItem('state'))
      });
    }
    if (state !== initialState) {
      sessionStorage.setItem('state', JSON.stringify(state));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state !== initialState) {
      sessionStorage.setItem('state', JSON.stringify(state));
    }
  }, [state]);

  const contextValue = useMemo(() => {
    return { state, dispatch };
  }, [state, dispatch]);

  useEffect(() => {
    localStorage.setItem('theme', state.theme);
  }, [state.theme]);

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) {
      dispatch({ type: 'set_theme', theme: savedTheme });
    }
  }, []);

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
}
export function useAppContext() {
  return useContext(AppContext);
}

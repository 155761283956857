export function toRomanNumeral(num) {
  switch (parseInt(num)) {
    case 0:
      return 'N'; // The Romans did not have a symbol for zero, but 'N' is sometimes used.
    case 1:
      return 'I';
    case 2:
      return 'II';
    case 3:
      return 'III';
    case 4:
      return 'IV';
    case 5:
      return 'V';
    default:
      return 'Invalid number'; // For numbers not in 0-5 range
  }
}

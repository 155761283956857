import { useEffect, useState } from 'react';

import { Box, FormLabel, Stack } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import {
  ReferenceLine,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
  ZAxis
} from 'recharts';

const BubbleChart = ({ type, name, data, showLabels, error }) => {
  const [showBlank, setShowBlank] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowBlank(true);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  const domain =
    type === 'bubble' &&
    (Array.isArray(data)
      ? [0, Math.max(...data.map(item => item.value))]
      : [0, 1]);

  const range = [1, 300];

  const renderTooltip = props => {
    const { active, payload } = props;

    if (active && payload && payload.length) {
      const data = payload[0].payload;

      return (
        <Box
          sx={{
            backgroundColor: '#fff',
            border: '1px solid #999',
            margin: 0,
            px: 2,
            display: 'flex'
          }}
        >
          <p>{data.day}</p>
          <p sx={{ ml: 2 }}>&nbsp;: {data.value.toFixed(1)}</p>
        </Box>
      );
    }

    return null;
  };

  const CustomTick = props => {
    const { x, y, payload } = props;
    const [day, month] = payload.value.split('/'); // Split the string at "/" to extract day and month

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="middle"
          fill="#666"
          fontSize="10px"
        >
          {`${day}/${month}`}
        </text>
      </g>
    );
  };

  return (
    <Box sx={{ mt: 2 }}>
      {!data || error ? (
        showBlank ? (
          <Box
            height={60}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <ResponsiveContainer width="100%" height={60}>
              <ScatterChart
                width={800}
                height={60}
                margin={{
                  top: 10,
                  right: 0,
                  bottom: 0,
                  left: 0
                }}
              >
                <XAxis
                  type="category"
                  dataKey="day"
                  axisLine={false}
                  domain={['', '']}
                />
                <YAxis
                  width={1}
                  type="number"
                  dataKey="index"
                  domain={[0, 1]}
                  tick={false}
                  tickLine={false}
                  axisLine={false}
                  label={null}
                />

                <ReferenceLine
                  y={0.5}
                  stroke="rgb(9, 36, 71)"
                  strokeDasharray="5 5"
                />
              </ScatterChart>
            </ResponsiveContainer>
          </Box>
        ) : (
          <Box
            height={60}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Box>
        )
      ) : (
        <Box>
          <ResponsiveContainer width="100%" height={60}>
            <ScatterChart
              width={800}
              height={60}
              margin={{
                top: 10,
                right: 0,
                bottom: 0,
                left: 0
              }}
            >
              <ReferenceLine y={1} stroke="#173f75" strokeDasharray="5 5" />
              <XAxis
                interval={
                  data.length > 210
                    ? 42
                    : data.length > 160
                    ? 28
                    : data.length > 82
                    ? 14
                    : data.length > 41
                    ? 7
                    : data.length > 20
                    ? 2
                    : data.length > 13
                    ? 1
                    : 0
                }
                type="category"
                dataKey="day"
                name="day"
                tick={showLabels ? CustomTick : false}
                axisLine={false}
                tickLine={{ transform: 'translate(0, -6)' }}
              />
              <YAxis
                type="number"
                dataKey="index"
                height={10}
                width={1}
                tick={false}
                tickLine={false}
                axisLine={false}
                domain={[0, 1]}
              />
              <ZAxis
                type="number"
                dataKey="value"
                domain={domain}
                range={range}
              />
              <Tooltip
                cursor={{ strokeDasharray: '3 3' }}
                wrapperStyle={{ zIndex: 100 }}
                content={renderTooltip}
              />
              <Scatter
                data={
                  type === 'bubble' &&
                  (Array.isArray(data)
                    ? data.map(item => ({ ...item, index: 1 }))
                    : [])
                }
                fill="#8884d8"
              />
            </ScatterChart>
          </ResponsiveContainer>
          <Stack alignItems="center">
            <FormLabel>{name}</FormLabel>
          </Stack>
        </Box>
      )}
    </Box>
  );
};

export default BubbleChart;

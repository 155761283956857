import { useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { Check, Pending, VerifiedUser } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Chip, CircularProgress, Collapse } from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import { useAppContext } from 'context';
import { useSnackbar } from 'context/SnackBar';
import { resendInvitation } from 'graphql/queries';

import { ErrorMessage, SettingHeader } from 'components/atoms';

export default function ResendInvitation({
  emailVerified,
  loadingEmailVerified
}) {
  const [updateInvitation, setUpdateInvitation] = useState();
  const [resendingInvitation, setResendingInvitation] = useState(false);
  const [resentInvitation, setResentInvitation] = useState(false);
  const [error, setError] = useState(null);
  const { state } = useAppContext();
  const { user } = useAuthenticator(context => [context.user]);
  const clientID = state?.client?.id ?? user.username;
  const firstName = state?.client?.firstName;
  const lastName = state?.client?.lastName;
  const email = state?.client?.email;
  const { enqueueSnackbar } = useSnackbar();

  const handleResendInvitation = async () => {
    try {
      setResendingInvitation(true);
      const { data } = await API.graphql(
        graphqlOperation(resendInvitation, {
          userID: clientID,
          firstName,
          lastName,
          email
        })
      );
      const parsedData = JSON.parse(data.resendInvitation);
      if (parsedData.statusCode !== 200) {
        throw new Error(parsedData.error);
      } else {
        setResentInvitation(true);
        enqueueSnackbar(`Resent invitation to ${firstName}`, {
          severity: 'success'
        });
      }
    } catch (err) {
      setError(err.message);
      enqueueSnackbar(`Error resending invitation to ${firstName}`, {
        severity: 'error'
      });
    } finally {
      setResendingInvitation(false);
    }
  };

  return (
    <Box p={1} my={2}>
      <SettingHeader
        state={updateInvitation}
        setState={setUpdateInvitation}
        label="Account Status"
        button={'Resend Invitation'}
        noAction={loadingEmailVerified || emailVerified}
      />
      <Box p={1} sx={{ display: 'flex', alignItems: 'center' }}>
        <Chip
          label={
            loadingEmailVerified
              ? 'Loading'
              : emailVerified
              ? 'Verified'
              : 'Pending'
          }
          icon={
            loadingEmailVerified ? (
              <CircularProgress size={16} />
            ) : emailVerified ? (
              <VerifiedUser fontSize="small" color="primary" />
            ) : (
              <Pending fontSize="small" color="warning" />
            )
          }
        />
      </Box>
      <Collapse in={updateInvitation}>
        <Box>
          <Box my={1}>
            <LoadingButton
              size="small"
              disabled={resentInvitation}
              fullWidth
              variant="contained"
              loading={resendingInvitation}
              onClick={handleResendInvitation}
              endIcon={resentInvitation && <Check color="primary" />}
            >
              {resentInvitation ? 'Resent Invitation' : 'Resend Invitation'}
            </LoadingButton>
          </Box>
          {Boolean(error) && (
            <Box my={1}>
              <ErrorMessage message={error} />
            </Box>
          )}
        </Box>
      </Collapse>
    </Box>
  );
}

import React, { useEffect, useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { PhotoCamera } from '@mui/icons-material';
import { Avatar, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Storage } from 'aws-amplify';
import { useAppContext } from 'context';
import { getImageFromIndexedDB, saveImageToIndexedDB } from 'indexedDB';
import { useLocation } from 'react-router-dom';

import { getInitials } from 'utils/getInitials';

const AvatarContainer = styled('div')({
  position: 'relative',
  width: 128,
  height: 128,
  borderRadius: '50%',
  overflow: 'hidden'
});

const StyledAvatar = styled(Avatar)({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%'
});

const UploadButton = styled(Button)({
  position: 'absolute',
  bottom: 0,
  left: 0,
  height: '33.33%',
  width: '100%',
  borderRadius: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.6)'
  }
});
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});

export default function UploadAvatar({ src, onImageChange, profile, edit }) {
  const { state } = useAppContext();
  const { user } = useAuthenticator(context => [context.user]);
  const isClinician = user.attributes['custom:type'] === 'clinician';
  const imageID = profile?.id;
  const [image, setImage] = useState(src);
  const location = useLocation();

  async function getImageBlobFromS3(imageID) {
    try {
      const key = `profilepictures/${imageID}.jpg`;
      const blob = await Storage.get(key, { download: true });
      return blob.Body;
    } catch (error) {
      console.error('Error fetching image from S3:', error);
      return null;
    }
  }

  useEffect(() => {
    let imageUrl;

    async function fetchImage() {
      if (imageID) {
        const blob = await getImageFromIndexedDB(imageID);
        if (blob) {
          imageUrl = URL.createObjectURL(blob);
          setImage(imageUrl);
        } else {
          const blobFromS3 = await getImageBlobFromS3(imageID); // Using new function
          await saveImageToIndexedDB(imageID, blobFromS3);
          imageUrl = URL.createObjectURL(blobFromS3);
          setImage(imageUrl);
        }
      }
    }
    if (profile?.picture) {
      fetchImage();
    }
    return () => {
      if (imageUrl) {
        URL.revokeObjectURL(imageUrl);
      }
    };
  }, [profile, imageID, state.avatarRefreshKey]);
  return (
    <AvatarContainer>
      <StyledAvatar
        sx={{
          fontSize: '1.5rem',
          color: isClinician ? '#272941' : 'primary.darkest',
          bgcolor: isClinician
            ? location.pathname.includes('client')
              ? 'primary.light'
              : 'secondary.light'
            : 'primary.light'
        }}
        src={image || null}
      >
        {!image &&
          getInitials(
            profile?.clientFirstName || profile?.firstName,
            profile?.clientLastName || profile?.lastName
          )}
      </StyledAvatar>
      {edit && (
        <UploadButton component="label" variant="contained" color="grey">
          <PhotoCamera />
          <VisuallyHiddenInput
            type="file"
            accept="image/*"
            onChange={onImageChange}
          />
        </UploadButton>
      )}
    </AvatarContainer>
  );
}

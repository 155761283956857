import {
  Box,
  Chip,
  Container,
  Grid,
  Link,
  Stack,
  Typography
} from '@mui/material';

import { capitalizeFirstLetter } from 'utils/capitaliseFirstLetter';
import { formatProgram } from 'utils/formatProgram';

export default function ResourceInfo({ resource }) {
  return (
    <Box mt={4}>
      <Container maxWidth="sm">
        <Box>
          <Grid container alignItems="center">
            <Grid item sm={6}>
              <Typography variant="caption">
                Source:{' '}
                {resource?.sourceLink ? (
                  <Link href={resource.sourceLink}>{resource?.sourceName}</Link>
                ) : (
                  resource?.sourceName
                )}
              </Typography>
            </Grid>
            <Grid item sm={6}>
              <Stack direction="row" spacing={1}>
                {resource?.category && (
                  <Chip label={capitalizeFirstLetter(resource?.category)} />
                )}
                {resource?.type && (
                  <Chip label={capitalizeFirstLetter(resource?.type)} />
                )}
                {resource?.condition && (
                  <Chip label={capitalizeFirstLetter(resource?.condition)} />
                )}
                {resource?.program && (
                  <Chip label={formatProgram(resource?.program)} />
                )}
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}

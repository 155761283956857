import { Avatar, Box } from '@mui/material';

export default function DisplayInfinite({ input }) {
  return (
    <Box display={'flex'} alignItems="center">
      <Box sx={{ ml: 1 }} display="flex" alignItems="center" height="50px">
        <Box sx={{ height: 40, width: 2, bgcolor: 'primary.main' }} />
        <Avatar
          sx={{
            bgcolor: 'background.paper',
            color: 'text.primary'
          }}
        >
          {input.numberValue}
        </Avatar>
        <Box sx={{ height: 40, width: 2, bgcolor: 'primary.main' }} />
      </Box>
    </Box>
  );
}

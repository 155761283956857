import { Box, FormLabel, Skeleton, Stack, Typography } from '@mui/material';
import { useAppContext } from 'context';

import { capitalizeFirstLetter } from 'utils/capitaliseFirstLetter';

export default function ClientAccountType() {
  const { state } = useAppContext();
  const { client } = state;

  return (
    client?.forValue && (
      <Box p={1} my={2}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <FormLabel sx={{ fontWeight: 600 }}>Account Type</FormLabel>
        </Stack>
        <Box p={1} sx={{ display: 'flex', alignItems: 'center' }}>
          {!client?.forValue ? (
            <Skeleton width={100} />
          ) : (
            <Typography variant="h6">
              {client.forValue === 'me'
                ? 'Adult'
                : capitalizeFirstLetter(client?.forValue)}
            </Typography>
          )}
        </Box>
      </Box>
    )
  );
}

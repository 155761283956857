import { Skeleton, Typography } from '@mui/material';

export default function ClientNameSub({ client, variant }) {
  if (!client) return <Skeleton height={30} width={180} />;
  if (!client?.clientFirstName) return;
  return (
    <Typography
      fontWeight={'bold'}
      color="text.accent"
      variant={variant || 'body2'}
      align="left"
      sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
    >
      {client?.firstName + ' ' + client?.lastName}
    </Typography>
  );
}

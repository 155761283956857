import { useEffect, useState } from 'react';

import { Box, Card, CardHeader, CardMedia } from '@mui/material';
import { getImageFromIndexedDB, saveImageToIndexedDB } from 'indexedDB';

export default function ResourcePreviewCard({ resource }) {
  const [image, setImage] = useState(null);
  const imageID = resource.id;

  async function getImageBlobFromS3(imageID, imageType) {
    try {
      const key = `resources/${imageID}.${imageType}`;
      const blob = await Storage.get(key, { download: true });
      return blob.Body;
    } catch (error) {
      console.error('Error fetching image from S3:', error);
      return null;
    }
  }

  useEffect(() => {
    let imageUrl;

    async function fetchImage() {
      if (imageID) {
        const blob = await getImageFromIndexedDB(imageID);
        if (blob) {
          imageUrl = URL.createObjectURL(blob);
          setImage(imageUrl);
        } else {
          const blobFromS3 = await getImageBlobFromS3(
            imageID,
            resource.imageType
          );
          await saveImageToIndexedDB(imageID, blobFromS3);
          imageUrl = URL.createObjectURL(blobFromS3);
          setImage(imageUrl);
        }
      }
    }
    if (resource?.hasImage) {
      fetchImage();
    }
    return () => {
      if (imageUrl) {
        URL.revokeObjectURL(imageUrl);
      }
    };
  }, [imageID, resource?.hasImage, resource?.imageType]);

  return (
    <Box py={1}>
      <Card
        elevation={0}
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            maxWidth: 'calc(100% - 200px)'
          }}
        >
          <CardHeader
            title={resource.title}
            subheader={resource.subTitle || null}
            sx={{
              '& .MuiCardHeader-title': {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              },
              '& .MuiCardHeader-subheader': {
                lineHeight: '1.1'
              }
            }}
          />
        </Box>
        {image && (
          <CardMedia
            sx={{ height: 100, width: 200 }}
            component="img"
            image={image}
          />
        )}
      </Card>
    </Box>
  );
}

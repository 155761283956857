import { useEffect, useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { Check, Close } from '@mui/icons-material';
import {
  Alert,
  AlertTitle,
  Box,
  Chip,
  CircularProgress,
  Container,
  Stack,
  Typography
} from '@mui/material';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import { useSnackbar } from 'context/SnackBar';
import { getClinicShallow, getClinicianShallow } from 'graphql/customQueries';
import { getInvitation } from 'graphql/queries';
import { useParams } from 'react-router-dom';

import {
  ActionButton,
  BasicUserCard,
  ClinicCard,
  ErrorMessage
} from 'components/atoms';
import { Clinician } from 'components/layout';
import {
  handleInvitationError,
  useFetchData
} from 'components/molecules/LinkAccounts';

import { linkAction } from 'utils/linkingUtils';
import { formatRoleString, getHighestPermissionLevel } from 'utils/userRoles';

export default function TeamLink() {
  const { user } = useAuthenticator(context => [context.user]);
  const clinicID = user.attributes['custom:clinicID'];
  const locationID = user.attributes['custom:locationID'];
  const permission = getHighestPermissionLevel(user);
  const fetchData = useFetchData();
  const { id } = useParams();

  // State variables
  const [confirming, setConfirming] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [rejecting, setRejecting] = useState(false);
  const [rejected, setRejected] = useState(false);
  const [invitee, setInvitee] = useState();
  const [clinic, setClinic] = useState({});
  const [requestedRole, setRequestedRole] = useState('clinician');
  const [error, setError] = useState(null);
  const [unauthorised, setUnauthorised] = useState(false);
  const [invitation, setInvitation] = useState(null);
  const [invitedToClinic, setInvitedToClinic] = useState(false);
  const [expired, setExpired] = useState(true);
  const [loading, setLoading] = useState(true);
  const [notice, setNotice] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchInvitation = async () => {
      try {
        const data = await fetchData(getInvitation, { id });
        if (data) {
          setInvitation(data.getInvitation);
        }
      } catch (e) {
        setLoading(false);
        setError('No invitation found.');
        console.error(e);
      }
    };

    if (id) {
      fetchInvitation();
    }
    return () => {
      setInvitation(null);
    };
  }, [id, fetchData]);

  useEffect(() => {
    const checkInvitationDetails = async () => {
      try {
        if (!invitation || !user) return;
        const isExpired =
          new Date() - new Date(invitation.createdAt) > 72 * 60 * 60 * 1000;
        setExpired(isExpired);
        if (isExpired) {
          setLoading(false);
        }
        const clinicRes = await API.graphql(
          graphqlOperation(getClinicShallow, {
            id: invitation.clinicID
          })
        );
        const clinic = clinicRes.data.getClinic;
        setClinic(clinic);
        switch (invitation.action) {
          case 'admin_exists':
            if (permission !== 'admin') {
              setLoading(false);
              setError(`Unauthorised to access this link`);
              setUnauthorised(true);
              return;
            }
            break;
          case 'needs_clinician_confirmation':
            if (clinicID === invitation.clinicID && invitation.inviteeID) {
              const clinicianRes = await API.graphql(
                graphqlOperation(getClinicianShallow, {
                  id: invitation.inviteeID
                })
              );
              const clinicUser = clinicianRes.data.getClinician;
              setInvitee(clinicUser);
              setRequestedRole(invitation.role);
              setLoading(false);
            } else {
              throw new Error(`Something is wrong with your link`);
            }
            break;
          case 'clinician_with_clinic':
            setInvitedToClinic(true);
            setNotice(
              `Accepting this invitation will set your clinic to ${clinic.name}`
            );
            break;
          case 'clinician_without_clinic':
            if (invitation.inviteeID) {
              const clinicianRes = await API.graphql(
                graphqlOperation(getClinicianShallow, {
                  id: invitation.inviteeID
                })
              );
              const clinicUser = clinicianRes.data.getClinician;
              setInvitee(clinicUser);
              setRequestedRole(invitation.role);
              setLoading(false);
            } else {
              throw new Error(`Something is wrong with your link`);
            }
            setInvitedToClinic(true);
            break;
          case 'invite_admin_to_clinic':
            setInvitedToClinic(true);
            break;
          case 'selected_clinic':
            break;
          default:
            break;
        }
      } catch (e) {
        console.error(e);
      }
    };

    checkInvitationDetails();
    return () => {
      setClinic({});
      setInvitee({});
      setRequestedRole('clinician');
      setExpired(true);
      setNotice('');
    };
  }, [invitation, user, fetchData, clinicID, permission]);

  const performAction = async config => {
    const {
      userFirstName,
      userLastName,
      action,
      source,
      setInProgress,
      setResult,
      invitationID,
      locationID,
      clinicID
    } = config;

    await linkAction({
      role: invitation.role,
      userFirstName,
      userLastName,
      name: clinic.name,
      action,
      source,
      setInProgress,
      setResult,
      setError,
      handleInvitationError,
      invitationID,
      locationID,
      clinicID
    });
    await Auth.currentAuthenticatedUser({ bypassCache: true });
    enqueueSnackbar(
      `${
        action.toLowerCase().includes('reject')
          ? 'Rejected invitation to join'
          : 'Joined'
      } ${clinic.name}`,
      { severity: 'success' }
    );
  };

  const renderContent = () => {
    if (!user || !id)
      return (
        <ErrorMessage message="Something is wrong with your linking code" />
      );
    return (
      <Container maxWidth="sm">
        {!unauthorised && (
          <Box>
            {invitedToClinic ? (
              <Typography variant="h5">
                {invitee.firstName} invited you to {clinic.name}
              </Typography>
            ) : (
              <Typography variant="h5">
                {invitee.firstName} wants{' '}
                {requestedRole && formatRoleString(requestedRole)} access to
                your clinic.
              </Typography>
            )}
            <Box sx={{ maxWidth: '100%' }} my={2}>
              {clinic ? (
                <ClinicCard
                  requestedRole={requestedRole}
                  invitation={invitation}
                  clinic={clinic}
                  multipleActions={
                    <Stack direction="column">
                      <ActionButton
                        tooltip="Accept"
                        disabled={confirmed || rejected || invitation?.used}
                        loading={confirming}
                        onClick={() => {
                          const inLineVars = {
                            action: 'CONFIRM_clinician_clinic_link',
                            source: invitation.action,
                            setInProgress: setConfirming,
                            setResult: setConfirmed,
                            invitationID: invitation.id,
                            userFirstName: user.attributes.given_name,
                            userLastName: user.attributes.family_name,
                            locationID,
                            clinicID
                          };

                          performAction(inLineVars);
                        }}
                        label={
                          <Check color={confirmed ? 'success' : 'default'} />
                        }
                      />
                      <ActionButton
                        tooltip="Reject"
                        disabled={confirmed || rejected || invitation?.used}
                        loading={rejecting}
                        onClick={() =>
                          performAction({
                            actionType: 'REJECT_clinician_clinic_link',
                            source: invitation.action,
                            setInProgress: setConfirming,
                            setResult: setConfirmed,
                            invitationId: invitation.id,
                            userFirstName: user.attributes.firstName,
                            userLastName: user.attributes.lastName,
                            locationID,
                            clinicID
                          })
                        }
                        label={<Close color={rejected ? 'error' : 'default'} />}
                      />
                    </Stack>
                  }
                />
              ) : (
                <BasicUserCard
                  invitation={invitation}
                  chip={
                    <Chip
                      size="small"
                      variant="contained"
                      color="primary"
                      label={formatRoleString(requestedRole)}
                      sx={{ mr: 1, mt: 1 }}
                    />
                  }
                  profile={invitee}
                  multipleActions={
                    <Stack direction="column">
                      <ActionButton
                        tooltip="Accept"
                        disabled={confirmed || rejected || invitation?.used}
                        loading={confirming}
                        onClick={() =>
                          performAction(
                            'CONFIRM_client_clinic_link',
                            setConfirming,
                            setConfirmed
                          )
                        }
                        label={
                          <Check color={confirmed ? 'success' : 'default'} />
                        }
                      />
                      <ActionButton
                        tooltip="Reject"
                        disabled={confirmed || rejected || invitation?.used}
                        loading={rejecting}
                        onClick={() =>
                          performAction(
                            'REJECT_client_clinic_link',
                            setRejecting,
                            setRejected
                          )
                        }
                        label={<Close color={rejected ? 'error' : 'default'} />}
                      />
                    </Stack>
                  }
                />
              )}
            </Box>{' '}
          </Box>
        )}

        {notice && (
          <Alert sx={{ my: 1 }} severity="warning">
            <AlertTitle>{notice}</AlertTitle>
          </Alert>
        )}

        {expired ||
          (invitation?.used && (
            <ErrorMessage noBody={true} message="This invitation has expired" />
          ))}

        {error && <ErrorMessage message={error} />}
      </Container>
    );
  };

  return (
    <Clinician props={{ title: 'Link Client' }}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="80vh"
      >
        {loading ? <CircularProgress /> : renderContent()}
      </Box>
    </Clinician>
  );
}

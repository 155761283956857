import { useEffect, useState } from 'react';

import { Business, History, Update } from '@mui/icons-material';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Chip
} from '@mui/material';
import { Storage } from 'aws-amplify';
import { getImageFromIndexedDB, saveImageToIndexedDB } from 'indexedDB';

import { getTimeLeft } from 'utils/time';
import { formatRoleString } from 'utils/userRoles';

export default function ClinicCard({
  clinic,
  invitation,
  requestedRole,
  multipleActions
}) {
  const [image, setImage] = useState(null);

  async function getImageBlobFromS3(imageID, imageType) {
    try {
      const key = `clinics/${imageID}.${imageType}`;
      const blob = await Storage.get(key, { download: true });
      return blob.Body;
    } catch (error) {
      console.error('Error fetching image from S3:', error);
      return null;
    }
  }

  useEffect(() => {
    let imageUrl;

    async function fetchImage() {
      if (clinic?.id) {
        const blob = await getImageFromIndexedDB(clinic?.id);
        if (blob) {
          imageUrl = URL.createObjectURL(blob);
          setImage(imageUrl);
        } else {
          const blobFromS3 = await getImageBlobFromS3(
            clinic?.id,
            clinic.imageType
          ); // Using new function
          await saveImageToIndexedDB(clinic?.id, blobFromS3);
          imageUrl = URL.createObjectURL(blobFromS3);
          setImage(imageUrl);
        }
      }
    }
    if (clinic?.hasLogo) {
      fetchImage();
    }
    return () => {
      if (imageUrl) {
        URL.revokeObjectURL(imageUrl);
      }
    };
  }, [clinic]);

  let timeInfo = {};
  if (invitation && invitation.createdAt) {
    timeInfo = getTimeLeft(invitation.createdAt);
  }

  return (
    <Card sx={{ p: 1 }} elevation={0}>
      <Box display="flex">
        {image ? (
          <CardMedia
            sx={{ maxHeight: 80, width: 'auto', maxWidth: '100%' }}
            component="img"
            src={image}
          />
        ) : (
          <Business />
        )}
        <CardHeader
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between'
          }}
          title={clinic.name}
          action={multipleActions}
        />
      </Box>
      <CardContent>
        <Box display="flex" flexDirection="row" flexWrap="wrap">
          {requestedRole && (
            <Chip
              color="primary"
              size="small"
              label={formatRoleString(requestedRole)}
              variant="filled"
              sx={{ mr: 1, mt: 1 }}
            />
          )}
          <Chip
            size={'small'}
            icon={
              timeInfo.expired ? (
                <History />
              ) : (
                <Update color={timeInfo.expired ? 'warning' : 'success'} />
              )
            }
            label={timeInfo.timeLeft}
            color={timeInfo.expired ? 'warning' : 'default'}
            variant="outlined"
            sx={{ mr: 1, mt: 1 }}
          />
        </Box>
      </CardContent>
    </Card>
  );
}

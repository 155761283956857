import { useEffect, useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { Box, Container, Grow } from '@mui/material';
import { Auth } from 'aws-amplify';

import { NoClickLogo } from 'components/atoms';

import ClientStepper from './clientStepper';
import OnboardClinician from './onboardClinician';
import TypeChooser from './typeChooser';
import WelcomeLottie from './welcomeLottie';

export default function Welcome() {
  const { user } = useAuthenticator(context => [context.user]);
  const [userType, setUserType] = useState(null);
  const [hasPlayed, setHasPlayed] = useState(false);

  useEffect(() => {
    if (user) {
      const newUserType = user.attributes['custom:type'];
      setUserType(newUserType);
    }
  }, [user]);

  useEffect(() => {
    const onboardingStage = user.attributes['custom:onboarding'];
    if (onboardingStage === '3' && hasPlayed) {
      const finishOnboarding = async () => {
        try {
          await Auth.updateUserAttributes(user, {
            'custom:onboarding': '4'
          });
        } catch (error) {
          console.error('Error updating user attributes:', error);
        }
      };
      finishOnboarding();
    }
  }, [user, hasPlayed]);

  return (
    <Box
      height="100vh"
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems="stretch"
      justifyContent="flex-start"
    >
      <Box display="flex" alignItems="center" justifyContent="center" my={2}>
        <NoClickLogo />
      </Box>

      <Box
        flexGrow={1}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Container
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: { xs: 'row', sm: 'column' },
            justifyContent: 'center'
          }}
          maxWidth="md"
        >
          {hasPlayed ? (
            <>
              <Grow in={hasPlayed}>
                <Box display="flex" alignItems="center">
                  {userType === undefined && <TypeChooser />}
                  {/* {userType === 'client' && <ClientStepper />} */}
                </Box>
              </Grow>
              <Grow in={userType !== undefined}>
                <Box>
                  <Box>
                    {userType === 'client' &&
                      user.attributes['custom:onboarding'] !== '3' && (
                        <ClientStepper />
                      )}
                  </Box>
                  <Box>
                    {userType === 'clinician' && (
                      <OnboardClinician user={user} />
                    )}
                  </Box>
                </Box>
              </Grow>
            </>
          ) : (
            <WelcomeLottie setHasPlayed={setHasPlayed} />
          )}
        </Container>
      </Box>
      <Box height={30} my={2} />
    </Box>
  );
}

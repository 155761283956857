import React, { useMemo, useRef, useState } from 'react';

import { IconButton, Popover, TextField, Tooltip } from '@mui/material';
import { styled } from '@mui/system';

import Icons from './filteredIcons';

const IconContainer = styled('div')({
  textAlign: 'center',
  boxShadow:
    '0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.3)',
  padding: 20,
  maxWidth: 300
});

const IconsScrollContainer = styled('div')({
  maxHeight: 160,
  overflowY: 'scroll'
});

const StyledIconButton = styled(IconButton)(({ theme, light }) => ({
  color: light ? 'white' : 'black'
}));

export const getIconComponent = name => {
  const IconComponent = Icons[name];
  return IconComponent ? <IconComponent /> : null;
};

const MaterialIconPicker = ({
  icons,
  initialIcon = 'AddReaction',
  onSelect,
  light
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  //   const [filteredIcons, setFilteredIcons] = useState(icons);
  const [selectedIcon, setSelectedIcon] = useState(initialIcon);
  const [openTooltip, setOpenTooltip] = useState(null);

  const filteredIcons = useMemo(() => {
    if (searchTerm) {
      return icons.filter(icon =>
        icon.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    return icons;
  }, [searchTerm, icons]);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleIconClick = iconName => {
    setSelectedIcon(iconName);
    handleClose();
    if (typeof onSelect === 'function') {
      onSelect(iconName);
    }
  };

  const iconsScrollContainerRef = useRef(null);

  return (
    <div>
      <StyledIconButton onClick={handleClick} light={light}>
        {getIconComponent(selectedIcon)}
      </StyledIconButton>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        elevation={5}
      >
        <IconContainer>
          <TextField
            placeholder="Search..."
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            fullWidth
            variant="outlined"
            margin="dense"
            size="small"
          />
          <IconsScrollContainer
            ref={iconsScrollContainerRef}
            onScroll={() => setOpenTooltip(null)}
          >
            {filteredIcons.slice(0, 100).map(iconName => (
              <Tooltip
                key={iconName}
                title={iconName}
                arrow
                open={openTooltip === iconName}
                onOpen={() => setOpenTooltip(iconName)}
                onClose={() => setOpenTooltip(null)}
                PopperProps={{
                  container: iconsScrollContainerRef.current
                }}
              >
                <IconButton
                  size="large"
                  onClick={() => handleIconClick(iconName)}
                >
                  {getIconComponent(iconName)}
                </IconButton>
              </Tooltip>
            ))}
          </IconsScrollContainer>
        </IconContainer>
      </Popover>
    </div>
  );
};

export default MaterialIconPicker;

export default function timeRenderer({ data }) {
  return data.toString().padStart(2, '0');
}

export function getTimeLeft(createdDateStr) {
  const createdDate = new Date(createdDateStr);
  const expiryDate = new Date(createdDate.getTime() + 72 * 60 * 60 * 1000);
  const now = new Date();
  let timeDiff = expiryDate - now;
  let expired = false;

  if (timeDiff <= 0) {
    expired = true;
    timeDiff = Math.abs(timeDiff);
    const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
    return {
      timeLeft: `Expired ${days} days, ${hours} hours, and ${minutes} minutes ago`,
      expired
    };
  }
  const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
  return {
    timeLeft: `Expires in ${days} days, ${hours} hours, and ${minutes} minutes `,
    expired
  };
}

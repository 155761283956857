import { useEffect, useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import {
  Alert,
  AlertTitle,
  Box,
  Container,
  Divider,
  Grid,
  Typography
} from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import { useSnackbar } from 'context/SnackBar';
import { updateSupport } from 'graphql/mutations';
import { supportsByClinicianID } from 'graphql/queries';

import { SupportRequest } from 'components/atoms';
import { Clinician } from 'components/layout';
import { ClinicianOnboarding } from 'components/molecules';

import TawkChatBody from 'utils/tawkChat';

export default function Support() {
  const [openSupportRequests, setOpenSupportRequests] = useState([]);
  const { user } = useAuthenticator(context => [context.user]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchSupportRequests = async () => {
      const { data } = await API.graphql(
        graphqlOperation(supportsByClinicianID, { clinicianID: user.username })
      );
      const supportRequests = data.supportsByClinicianID.items;

      const now = new Date();

      const openRequests = supportRequests.filter(request => {
        const expirationTime = new Date(request.expiresAt);
        const isExpired = expirationTime < now;
        return !isExpired && !request.used;
      });

      setOpenSupportRequests(openRequests);
    };

    if (user.username) {
      fetchSupportRequests();
    }
    return () => {
      setOpenSupportRequests([]);
    };
  }, [user.username]);

  const handleCloseSupportRequest = async (
    supportRequestId,
    supportRequestVersion
  ) => {
    try {
      await API.graphql(
        graphqlOperation(updateSupport, {
          input: {
            id: supportRequestId,
            used: true,
            _version: supportRequestVersion
          }
        })
      );
      const updatedOpenSupportRequests = openSupportRequests.filter(
        request => request.id !== supportRequestId
      );
      setOpenSupportRequests(updatedOpenSupportRequests);
      enqueueSnackbar('Closed support request', { severity: 'success' });
    } catch (err) {
      console.error('Error closing support request', err);
      enqueueSnackbar('Error closing support request', { severity: 'error' });
    }
  };

  return (
    <Clinician props={{ title: 'Support' }}>
      <TawkChatBody />
      <Box>
        <ClinicianOnboarding disableButton />
      </Box>
      <Container maxWidth="lg">
        <Box mt={4} mb={8}>
          <Typography variant="h5">Open Support Requests</Typography>
          <Box my={2}>
            <Divider sx={{ opacity: 0.3 }} />
          </Box>
          <Box mt={3} mb={2}>
            <Grid container spacing={2}>
              {openSupportRequests && openSupportRequests?.length > 0 ? (
                openSupportRequests.map(supportRequest => (
                  <Grid key={supportRequest.id} item xs={12} sm={6} lg={4}>
                    <SupportRequest
                      handleCloseSupportRequest={handleCloseSupportRequest}
                      supportRequest={supportRequest}
                    />
                  </Grid>
                ))
              ) : (
                <Box sx={{ maxWidth: 600 }} mt={2}>
                  <Alert severity="info">
                    <AlertTitle>No open support requests</AlertTitle>
                    Support requests grant the SpeechFit team 72-hour access to
                    a client account so that we can render assistance. Requests
                    can be revoked at any time.
                    <br />
                    Please speak to us before creating a request.
                  </Alert>
                </Box>
              )}
            </Grid>
          </Box>
        </Box>
      </Container>
    </Clinician>
  );
}

import { Link } from '@mui/icons-material';
import { Box, Chip, FormLabel, Grid, Stack, Typography } from '@mui/material';
import ReactPlayer from 'react-player';

import { ErrorMessage, PDF } from 'components/atoms';

import { capitalizeFirstLetter } from 'utils/capitaliseFirstLetter';
import { formatProgram } from 'utils/formatProgram';

export default function StepThree(props) {
  const {
    title,
    subtitle,
    type,
    category,
    condition,
    program,
    sourceName,
    sourceLink,
    videoLink,
    contentImagePreviewUrl,
    pdfPreviewUrl,
    error
  } = props;
  return (
    <Box>
      {Boolean(error) && (
        <Box my={2}>
          <ErrorMessage message={error} />
        </Box>
      )}
      {title && (
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography align="center" variant="h2">
            {title}
          </Typography>
        </Box>
      )}

      {subtitle && (
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography align="center" variant="h6">
            {subtitle}
          </Typography>
        </Box>
      )}
      <Grid sx={{ py: 2 }} container spacing={2}>
        <Grid item xs={12} md={6} display="flex" justifyContent="center">
          <Stack direction="row" spacing={2}>
            {type && (
              <Stack spacing={1} direction="column">
                <FormLabel sx={{ fontSize: 'smaller' }}>Type</FormLabel>
                <Chip label={capitalizeFirstLetter(type)} />
              </Stack>
            )}
            {category && (
              <Stack spacing={1} d direction="column">
                <FormLabel sx={{ fontSize: 'smaller' }}>Category</FormLabel>
                <Chip label={capitalizeFirstLetter(category)} />
              </Stack>
            )}
            {condition && (
              <Stack spacing={1} d direction="column">
                <FormLabel sx={{ fontSize: 'smaller' }}>Condition</FormLabel>
                <Chip label={capitalizeFirstLetter(condition)} />
              </Stack>
            )}
            {program && (
              <Stack spacing={1} d direction="column">
                <FormLabel sx={{ fontSize: 'smaller' }}>Program</FormLabel>
                <Chip label={formatProgram(program)} />
              </Stack>
            )}
          </Stack>
        </Grid>
        <Grid item xs={12} md={6} display="flex" justifyContent="center">
          {sourceName && (
            <Stack direction="column" alignItems="flex-start" spacing={1}>
              <FormLabel sx={{ fontSize: 'smaller' }}>Source</FormLabel>
              <Chip
                icon={Boolean(sourceLink) ? <Link /> : null}
                label={sourceName}
                clickable={Boolean(sourceLink)}
                onClick={() => window.open(sourceLink, '_blank')}
              />
            </Stack>
          )}
        </Grid>
      </Grid>
      <Box my={2}>
        {type === 'image' && contentImagePreviewUrl && (
          <Box
            my={2}
            sx={{ width: '100%' }}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <img
              src={contentImagePreviewUrl}
              alt="Selected"
              style={{ maxWidth: '80%', height: 'auto' }}
            />
          </Box>
        )}
        {type === 'video' && videoLink && (
          <Box
            my={2}
            style={{
              position: 'relative',
              paddingTop: '56.25%'
            }}
          >
            <ReactPlayer
              pip
              width="100%"
              height="100%"
              className="react-player"
              controls
              url={videoLink}
            />
          </Box>
        )}
        {type === 'pdf' && pdfPreviewUrl && <PDF pdf={pdfPreviewUrl} />}
      </Box>
    </Box>
  );
}

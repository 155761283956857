import { useEffect, useRef, useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import { Box, Chip, CircularProgress, IconButton, Stack } from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import { useAppContext } from 'context';
import { entriesByClientID } from 'graphql/queries';

import { getIconComponent } from 'components/molecules/IconPicker';

export default function EntrySelector({ selectedEntry, setSelectedEntry }) {
  const { state } = useAppContext();
  const { user } = useAuthenticator(context => [context.user]);
  const clientID =
    state?.client?.id || user.attributes['custom:clientID'] || user.username;
  const [disableLeftButton, setDisableLeftButton] = useState(true);
  const [disableRightButton, setDisableRightButton] = useState(false);
  const [entries, setEntries] = useState([]);

  useEffect(() => {
    const container = scrollContainer.current;
    setDisableLeftButton(container?.scrollLeft <= 0);
    setDisableRightButton(
      container.scrollLeft >= container.scrollWidth - container.clientWidth
    );
  }, []);

  useEffect(() => {
    async function fetchEntries() {
      const { data } = await API.graphql(
        graphqlOperation(entriesByClientID, { clientID: clientID })
      );
      const items = data.entriesByClientID.items;
      const filteredItems = items.filter(item => !item.complete);

      setEntries(filteredItems);
      checkScrollPosition();
    }
    fetchEntries();
    return () => {
      setEntries([]);
    };
  });

  const checkScrollPosition = () => {
    const container = scrollContainer.current;
    setDisableLeftButton(container?.scrollLeft <= 0);
    setDisableRightButton(
      container?.scrollLeft >= container?.scrollWidth - container?.clientWidth
    );
  };

  const scrollContainer = useRef(null);

  const [scrollInterval, setScrollInterval] = useState(null);

  const scrollBy = amount => {
    scrollContainer.current.scrollBy({ left: amount, behavior: 'smooth' });
    checkScrollPosition();
  };

  const startScrolling = direction => {
    stopScrolling();

    setScrollInterval(
      setInterval(() => {
        const container = scrollContainer.current;

        const newScrollPosition = container?.scrollLeft + direction * 20;

        if (
          (newScrollPosition <= 0 && direction < 0) ||
          (newScrollPosition >=
            container?.scrollWidth - container?.clientWidth &&
            direction > 0)
        ) {
          stopScrolling();
          return;
        }

        container.scrollBy({ left: direction * 20, behavior: 'auto' });
      }, 33)
    );
  };

  const stopScrolling = () => {
    if (scrollInterval !== null) {
      clearInterval(scrollInterval);
      setScrollInterval(null);
    }
  };

  let initialTouchPos = null;

  const touchStart = e => {
    initialTouchPos = e.touches[0].clientX;
  };

  const touchMove = e => {
    if (!initialTouchPos) return;

    const delta = initialTouchPos - e.touches[0].clientX;
    scrollContainer.current.scrollBy(delta, 0);
    initialTouchPos = e.touches[0].clientX;
  };

  const touchEnd = () => {
    initialTouchPos = null;
  };

  return (
    <Box maxWidth={'100%'}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          maxHeight: 200,
          overflow: 'hidden',
          maxWidth: { xs: '100vw', md: '100vw' }
        }}
      >
        <IconButton
          size="small"
          onClick={() => scrollBy(-50)}
          onMouseDown={() => startScrolling(-1)}
          onMouseUp={stopScrolling}
          onMouseLeave={stopScrolling}
          onTouchStart={() => startScrolling(-1)}
          onTouchEnd={stopScrolling}
          disabled={disableLeftButton}
        >
          <NavigateBefore />
        </IconButton>

        <Box
          ref={scrollContainer}
          onTouchStart={touchStart}
          onTouchMove={touchMove}
          onTouchEnd={touchEnd}
          sx={{
            overflowX: 'auto',
            overflowY: 'hidden',
            touchAction: 'manipulation',
            flexGrow: 1,
            whiteSpace: 'nowrap',
            '&::-webkit-scrollbar': {
              display: 'none'
            },
            '-ms-overflow-style': 'none',
            'scrollbar-width': 'none'
          }}
        >
          <Stack my={2} direction="row" spacing={1}>
            {entries ? (
              entries
                .filter(
                  entry => !selectedEntry.id || selectedEntry.id === entry.id
                )
                .map(entry => (
                  <Chip
                    sx={{ mx: 0.5, flexShrink: 1 }}
                    icon={getIconComponent(entry.icon)}
                    variant={
                      selectedEntry?.id === entry.id ? 'contained' : 'outlined'
                    }
                    color="primary"
                    key={entry.id}
                    label={entry.name}
                    onClick={() =>
                      selectedEntry?.id === entry.id
                        ? setSelectedEntry({})
                        : setSelectedEntry(entry)
                    }
                  />
                ))
            ) : (
              <Box
                sx={{
                  alignItems: 'center',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  height: 32
                }}
              >
                <CircularProgress size={10} />
              </Box>
            )}
          </Stack>
        </Box>

        <IconButton
          size="small"
          onClick={() => scrollBy(50)}
          onMouseDown={() => startScrolling(1)}
          onMouseUp={stopScrolling}
          onMouseLeave={stopScrolling}
          onTouchStart={() => startScrolling(1)}
          onTouchEnd={stopScrolling}
          disabled={disableRightButton}
        >
          <NavigateNext />
        </IconButton>
      </Box>
    </Box>
  );
}

import { useState } from 'react';

import {
  Domain,
  KeyboardArrowDown,
  KeyboardArrowLeft,
  ViewAgenda
} from '@mui/icons-material';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  Collapse,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  ToggleButton,
  ToggleButtonGroup
} from '@mui/material';

import Location from './Location';
import Permissions from './Permissions';

export default function UserSettings({
  view,
  setView,
  clinicians,
  setClinicians,
  openFilter,
  setOpenFilter,
  locations,
  setLocations,
  hasClinicians,
  openAssignClinicians,
  setOpenAssignClinicians,
  selectClinicians,
  setSelectClinicians,
  selectedClinicians,
  setSelectedClinicians,
  open,
  setOpen
}) {
  const [selectedRoles, setSelectedRoles] = useState([]);

  const handleView = (event, nextView) => {
    if (nextView !== null) {
      setView(nextView);
    }
  };

  const handleRoles = (event, newRoles) => {
    setSelectedRoles(newRoles);
  };
  const ITEM_HEIGHT = 40;
  const ITEM_PADDING_TOP = 0;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        lineHeight: '1'
      }
    }
  };

  const renderSelectedLocationNames = selectedIDs => {
    const selectedNames = locations
      .filter(location => selectedIDs.includes(location.id))
      .map(location => location.name);

    return selectedNames.join(', ');
  };

  const [selectedLocationIDs, setSelectedLocationIDs] = useState([]);
  const handleLocationChange = event => {
    const value = event.target.value;
    setSelectedLocationIDs(
      typeof value === 'string' ? value.split(',') : value
    );
  };

  return (
    <Box>
      <Box display="flex" justifyContent="flex-end">
        <Stack direction="row" spacing={2} alignItems="center">
          <Collapse
            orientation="horizontal"
            in={locations.length > 0 && openFilter && view === 'location'}
          >
            <Box>
              <FormControl sx={{ m: 1, width: 300 }} size="small">
                <InputLabel>Location</InputLabel>
                <Select
                  multiple
                  value={selectedLocationIDs}
                  onChange={handleLocationChange}
                  input={<OutlinedInput label="Location" />}
                  renderValue={selected =>
                    renderSelectedLocationNames(selected)
                  }
                  MenuProps={MenuProps}
                >
                  {locations.map(location => (
                    <MenuItem key={location.id} value={location.id}>
                      <Checkbox
                        checked={selectedLocationIDs.includes(location.id)}
                      />
                      <ListItemText primary={location.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Collapse>
          <Collapse
            orientation="horizontal"
            in={openFilter && view === 'permissions'}
          >
            <Box>
              <ToggleButtonGroup
                color="tertiary"
                size="small"
                value={selectedRoles}
                onChange={handleRoles}
                aria-label="Roles Toggle"
              >
                <ToggleButton
                  value="admin"
                  aria-label="admin"
                  disabled={!hasClinicians}
                  sx={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}
                >
                  Admin
                </ToggleButton>
                <ToggleButton
                  value="practicemanager"
                  aria-label="practicemanager"
                  disabled={!hasClinicians}
                  sx={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}
                >
                  Practice Manager
                </ToggleButton>
                <ToggleButton
                  value="clinician"
                  aria-label="clinician"
                  disabled={!hasClinicians}
                  sx={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}
                >
                  Clinician
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
          </Collapse>
          <Stack direction="row" spacing={2} alignItems="center">
            <Collapse orientation="horizontal" in={openFilter}>
              <Box>
                <ToggleButtonGroup
                  color="secondary"
                  size="small"
                  value={view}
                  exclusive
                  onChange={handleView}
                  aria-label="View Toggle"
                >
                  <ToggleButton
                    value="permissions"
                    aria-label="permissions"
                    disabled={!hasClinicians}
                    sx={{ fontWeight: 'bold' }}
                  >
                    <ViewAgenda sx={{ mr: 1 }} fontSize="small" />
                    Permissions
                  </ToggleButton>
                  <ToggleButton
                    value="location"
                    aria-label="location"
                    disabled={!hasClinicians}
                    sx={{ fontWeight: 'bold' }}
                  >
                    <Domain sx={{ mr: 1 }} fontSize="small" />
                    Location
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
            </Collapse>
            <Button
              onClick={() => setOpenFilter(prevOpenFilter => !prevOpenFilter)}
              startIcon={
                openFilter ? <KeyboardArrowLeft /> : <KeyboardArrowDown />
              }
              color={openFilter ? 'secondary' : 'primary'}
              size="small"
            >
              Filter
            </Button>
          </Stack>
        </Stack>
      </Box>
      {!hasClinicians && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ maxHeight: '100%', height: '60vh', width: '100%' }}
        >
          <Alert severity="info">
            <AlertTitle>You're the first one here</AlertTitle>
            <Button onClick={() => setOpen(true)}>Invite Team</Button>
          </Alert>
        </Box>
      )}
      {hasClinicians && (
        <Box mt={4}>
          {view === 'permissions' && (
            <Permissions
              selectedRoles={selectedRoles}
              locations={locations}
              setLocations={setLocations}
              setClinicians={setClinicians}
              clinicians={clinicians}
            />
          )}
          {view === 'location' && (
            <Location
              setClinicians={setClinicians}
              openAssignClinicians={openAssignClinicians}
              setOpenAssignClinicians={setOpenAssignClinicians}
              selectedLocationIDs={selectedLocationIDs}
              selectClinicians={selectClinicians}
              setSelectClinicians={setSelectClinicians}
              selectedClinicians={selectedClinicians}
              setSelectedClinicians={setSelectedClinicians}
              locations={locations}
              clinicians={clinicians}
            />
          )}
        </Box>
      )}
    </Box>
  );
}

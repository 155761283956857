import { useCallback, useEffect, useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import {
  Alert,
  AlertTitle,
  Box,
  Collapse,
  Grid,
  Link,
  Stack
} from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import { constants } from 'common/constants';
import { useSnackbar } from 'context/SnackBar';
import { updateClient } from 'graphql/mutations';
import {
  createClinician,
  getClient,
  getClinician,
  invitationByAction,
  linkExistingClientOrClinician
} from 'graphql/queries';
import mixpanel from 'mixpanel-browser';

import { BasicUserCard, SettingHeader } from 'components/atoms';
import { AddClinician, Invitation, Participants } from 'components/molecules';

export default function ClientAccess() {
  const { user } = useAuthenticator(context => [context.user]);
  const [updateClinician, setUpdateClinician] = useState(false);
  const [removingClinician, setRemovingClinician] = useState(false);
  const [clinicianError, setClinicianError] = useState(null);
  const [clinicianEmail, setClinicianEmail] = useState('');
  const [clinicianFirstName, setClinicianFirstName] = useState('');
  const [clinicianLastName, setClinicianLastName] = useState('');
  const [addingClinician, setAddingClinician] = useState(false);
  const [clinicianExists, setClinicianExists] = useState(false);
  const [invitations, setInvitations] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [clinician, setClinician] = useState({});
  const [client, setClient] = useState({});
  const [showInfo, setShowInfo] = useState(false);
  const [loading, setLoading] = useState(true);

  const now = new Date();

  useEffect(() => {
    if (user) {
      const queryClient = async () => {
        const { data } = await API.graphql(
          graphqlOperation(getClient, { id: user.username })
        );
        setClient(data.getClient);
      };
      queryClient();
    }
    return () => {
      setClient({});
    };
  }, [user]);

  const queryInvitations = useCallback(async () => {
    if (!client?.id) return;
    try {
      const { data } = await API.graphql(
        graphqlOperation(invitationByAction, {
          action: 'link_client_and_clinician',
          clientID: { eq: client.id }
        })
      );
      const items = data.invitationByAction.items;
      const now = new Date().getTime();
      const filteredItems = items.filter(item => {
        if (item.used) {
          return false;
        }
        const createdAt = new Date(item.createdAt).getTime();
        const hoursDifference = (now - createdAt) / (1000 * 60 * 60);
        return hoursDifference <= 72;
      });
      const sortedItems = filteredItems.sort((a, b) => {
        return (
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
      });

      setInvitations(sortedItems);
    } catch (error) {
      console.error('Failed to query invitations:', error);
      setInvitations([]);
    } finally {
      setLoading(false);
    }
  }, [client?.id, setInvitations]);

  useEffect(() => {
    if (client?.id && !clinician?.id) {
      queryInvitations();
    }
    return () => {
      setInvitations([]);
    };
  }, [clinician, client, queryInvitations]);

  const queryClinician = useCallback(async () => {
    if (!client?.clinicianID) return;
    const res = await API.graphql(
      graphqlOperation(getClinician, { id: client?.clinicianID })
    );
    setClinician(res.data.getClinician);
  }, [client]);

  useEffect(() => {
    queryClinician();
    return () => {
      setClinician({});
    };
  }, [queryClinician]);

  const removeClinician = async () => {
    try {
      setRemovingClinician(true);
      const { data } = await API.graphql(
        graphqlOperation(getClient, { id: user.username })
      );
      const clientVersion = data.getClient._version;
      await API.graphql(
        graphqlOperation(updateClient, {
          input: {
            id: user.username,
            clinicianID: null,
            _version: clientVersion
          }
        })
      );
      mixpanel.track('remove_clinician');
      window.dataLayer.push({
        event: 'remove_clinician'
      });
      setClinician({});
    } catch (err) {
      setClinicianError(err.message);
    } finally {
      setRemovingClinician(false);
    }
  };

  const addClinician = async clinician => {
    try {
      setAddingClinician(true);

      if (clinicianExists) {
        const variables = {
          source: 'client',
          clinicianID: clinician.id,
          clinicianFirstName: clinician.firstName,
          clinicianLastName: clinician.lastName,
          clinicianEmail: clinician.email,
          clientID: user.username,
          clientFirstName: user.attributes.given_name,
          clientLastName: user.attributes.family_name,
          clientEmail: user.attributes.email
        };
        const res = await API.graphql(
          graphqlOperation(linkExistingClientOrClinician, variables)
        );
        const parsedRes = JSON.parse(
          res.data.linkExistingClientOrClinician.body
        );

        if (res.data.linkExistingClientOrClinician.statusCode === 500) {
          throw new Error(JSON.parse(parsedRes));
        } else {
          mixpanel.track('invite_clinician');
          window.dataLayer.push({
            event: 'invite_clinician'
          });
          setInvitations([
            {
              id: parsedRes.invitationID,
              clientID: user.username,
              clinicianID: clinician.id,
              used: false,
              createdAt: now.toISOString(),
              createdBy: user.username,
              _version: 1
            },
            ...invitations
          ]);
          setClinicianEmail('');
          setClinicianFirstName('');
          setClinicianLastName('');
          setUpdateClinician(false);
          setClinicianError(null);
        }
      } else {
        const variables = {
          clinicianEmail,
          clinicianFirstName,
          clinicianLastName,
          clientID: user.username,
          clientFirstName: user.attributes.given_name,
          clientLastName: user.attributes.family_name,
          clientEmail: user.attributes.email,
          role: 'clinician',
          source: 'client'
        };

        const res = await API.graphql(
          graphqlOperation(createClinician, variables)
        );

        const parsedRes = JSON.parse(res.data.createClinician.body);
        if (parsedRes.statusCode === 500) {
          throw new Error(parsedRes.body);
        } else {
          mixpanel.track('create_clinician');
          window.dataLayer.push({
            event: 'create_clinician'
          });
          setUpdateClinician(false);
          setClinician({
            firstName: clinicianFirstName,
            lastName: clinicianLastName,
            email: clinicianEmail
          });
          setClinicianError(null);
        }
      }
    } catch (err) {
      enqueueSnackbar(`Error inviting ${clinicianFirstName}`, {
        severity: 'error'
      });
      setClinicianError(err.message);
    } finally {
      setAddingClinician(false);
    }
  };

  return (
    <Box>
      {client && (
        <Box>
          <SettingHeader
            hasInfo={true}
            showInfo={showInfo}
            setInfo={setShowInfo}
            label="Clinician"
            state={updateClinician}
            setState={setUpdateClinician}
          />
          <Grid container>
            <Grid item xs={12}>
              <Collapse in={showInfo}>
                <Box my={1}>
                  <Alert severity="info">
                    <AlertTitle>
                      Invite your clinician to your account
                    </AlertTitle>
                    Your clinician will be able to view your data and edit your
                    account.
                  </Alert>
                </Box>
              </Collapse>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box p={1}>
                <Stack alignItems="center" direction="row" spacing={2}>
                  <Box sx={{ pt: 1, py: { xs: 0, md: 1 } }} width="100%">
                    <Collapse in={Boolean(clinician?.id) && !loading}>
                      <Box>
                        <BasicUserCard
                          remove={updateClinician}
                          action={removeClinician}
                          actionLoading={removingClinician}
                          profile={clinician}
                        />
                      </Box>
                    </Collapse>
                    <Collapse
                      in={Boolean(invitations) && invitations.length > 0}
                    >
                      {invitations.map(invitation => (
                        <Invitation
                          key={`key-${invitation.id}`}
                          invitation={invitation}
                          invitations={invitations}
                          remove={updateClinician}
                          setInvitations={setInvitations}
                        />
                      ))}
                    </Collapse>
                    <Collapse
                      in={
                        invitations.length < 1 &&
                        !Boolean(clinician?.id) &&
                        !updateClinician &&
                        !loading
                      }
                    >
                      <Box>
                        <Alert severity="info">
                          <AlertTitle>
                            Your clinician doesn't have access to your account.
                          </AlertTitle>
                          Grant them access now.
                        </Alert>
                      </Box>
                    </Collapse>
                  </Box>
                </Stack>
                {updateClinician && (
                  <Box>
                    {!clinician?.id &&
                      (invitations?.length < 1 || !invitations) && (
                        <Box my={2}>
                          <AddClinician
                            clinicianEmail={clinicianEmail}
                            setClinicianEmail={setClinicianEmail}
                            addingClinician={addingClinician}
                            handleAddClinician={addClinician}
                            setClinicianExists={setClinicianExists}
                            clinicianExists={clinicianExists}
                            clinicianFirstName={clinicianFirstName}
                            setClinicianFirstName={setClinicianFirstName}
                            clinicianLastName={clinicianLastName}
                            setClinicianLastName={setClinicianLastName}
                          />
                        </Box>
                      )}
                    {clinicianError && (
                      <Alert severity="error">
                        <AlertTitle>{clinicianError}</AlertTitle>Please try
                        again or{' '}
                        <Link href={constants.contactUrl}>contact us</Link>.
                      </Alert>
                    )}
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
      <Participants clientFirstName={user.attributes.given_name} />
    </Box>
  );
}

import { LocationOff, Place } from '@mui/icons-material';
import {
  Alert,
  Box,
  Card,
  CardHeader,
  Chip,
  FormLabel,
  Grid,
  IconButton,
  Typography
} from '@mui/material';

import { formatCountryName } from 'utils/getCountryFromTimeZone';
import { formatRoleString } from 'utils/userRoles';

export default function StepTwo({
  howManyClients,
  location,
  firstName,
  lastName,
  role,
  email
}) {
  return (
    <Box p={2}>
      <Grid sx={{ pb: 2 }} container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormLabel>First name</FormLabel>
          <Box px={1}>
            <Typography variant="h6">{firstName}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormLabel>Last Name</FormLabel>
          <Box px={1}>
            <Typography variant="h6">{lastName}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <FormLabel>Email</FormLabel>
          <Box px={1}>
            <Typography variant="h6">{email}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" flexDirection="column">
            <FormLabel>Permission</FormLabel>
            <Box px={1} mt={0.25}>
              <Chip
                size="small"
                label={formatRoleString(role)}
                color="primary"
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <FormLabel>Location</FormLabel>
          <Box mt={1}>
            {location && location.country ? (
              <Card
                elevation={0}
                sx={{
                  backgroundColor: 'background.card'
                }}
              >
                <CardHeader
                  title={location.name}
                  subheader={`${[
                    location.street,
                    location.street2,
                    location.city,
                    location.state,
                    location.postcode,
                    formatCountryName(location.country)
                  ]
                    .filter(part => part)
                    .join(', ')}${' '}`}
                  action={
                    <IconButton disabled>
                      <Place color="primary" />
                    </IconButton>
                  }
                />
              </Card>
            ) : (
              <Card elevation={0} sx={{ backgroundColor: 'background.card' }}>
                <CardHeader avatar={<LocationOff />} subheader="No location" />
              </Card>
            )}
          </Box>
        </Grid>
        {howManyClients && howManyClients !== 0 && (
          <Grid item xs={12}>
            <Alert
              sx={{ display: 'flex', alignItems: 'center', mt: 2 }}
              severity="info"
            >
              <Typography>
                This will associate {firstName}'s {howManyClients}{' '}
                {howManyClients === 1 ? 'client' : 'clients'} with your
                practice.
              </Typography>
            </Alert>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

import { Box } from '@mui/material';

import {
  ContentImage,
  ContentPDF,
  ContentText,
  ContentVideo
} from 'components/molecules';

function ResourceContent({ content }) {
  return (
    <Box>
      {content.contentType === 'markdown' && <ContentText content={content} />}
      {content.contentType === 'pdf' && <ContentPDF content={content} />}
      {content.contentType === 'video' && <ContentVideo content={content} />}
      {content.contentType === 'image' && <ContentImage content={content} />}
    </Box>
  );
}

export default ResourceContent;

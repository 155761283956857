import { useEffect, useState } from 'react';

import { Box, Container, Stack, Typography } from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import { getResource, resourceContentsByResourceID } from 'graphql/queries';
import { useParams } from 'react-router-dom';

import { BackButton } from 'components/atoms';
import { ResourceInfo } from 'components/molecules';
import { ResourceContent } from 'components/organisms';

function Resource() {
  const { id: resourceID } = useParams();
  const [resource, setResource] = useState();
  const [resourceContent, setResourceContent] = useState();

  useEffect(() => {
    const fetchResource = async () => {
      const { data } = await API.graphql(
        graphqlOperation(getResource, { id: resourceID })
      );
      setResource(data.getResource);
    };
    const fetchResourceContent = async () => {
      const { data } = await API.graphql(
        graphqlOperation(resourceContentsByResourceID, {
          resourceID: resourceID
        })
      );
      setResourceContent(data.resourceContentsByResourceID.items);
    };

    if (resourceID) {
      fetchResource();
      fetchResourceContent();
    }
  }, [resourceID]);

  return (
    <Box>
      <Container maxWidth="md">
        <Stack direction="row" justifyContent="space-between">
          <BackButton />
        </Stack>
        <Box my={1}>
          <Typography align="center" variant="h2">
            {resource?.title}
          </Typography>
        </Box>
        {resource?.subTitle && (
          <Box my={1}>
            <Typography align="center" variant="h6">
              {resource.subTitle}
            </Typography>
          </Box>
        )}
      </Container>
      <Box mt={2}>
        {resourceContent?.map(content => (
          <ResourceContent key={content.id} content={content} />
        ))}
      </Box>
      <Box>
        <ResourceInfo resource={resource} />
      </Box>
    </Box>
  );
}

export default Resource;

import { useState } from 'react';

import { ArrowForward } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import UnfoldMoreDoubleIcon from '@mui/icons-material/UnfoldMoreDouble';
import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';

import colorMapper from 'utils/colorMapper';
import timeRenderer from 'utils/time';
import truncate from 'utils/truncate';

const ExpandMore = styled(props => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest
  })
}));

export default function RecordCard({ data }) {
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const date = DateTime.fromISO(data.createdAt);
  const theme = useTheme();
  const navigate = useNavigate();
  const note = truncate(data.note, 25);

  return (
    <Box p={1}>
      <Card>
        <CardHeader
          action={
            <IconButton
              aria-label="settings"
              onClick={() => navigate(`/record/${data.id}`)}
            >
              <ArrowForward />
            </IconButton>
          }
          title={
            <Grid container sx={{ alignItems: 'center' }}>
              <Grid item xs={12} sm={6}>
                {data.name && (
                  <Typography
                    component="div"
                    // sx={{ lineHeight: 0 }}
                    variant="h6"
                  >
                    {data.name}
                  </Typography>
                )}
                <Box sx={{ display: 'flex' }}>
                  <Typography
                    sx={{ lineHeight: 1.3 }}
                    component="div"
                    variant="overline"
                  >
                    {timeRenderer({ data: date.day })}-
                    {timeRenderer({ data: date.month })}-
                    {timeRenderer({ data: date.year })}
                  </Typography>
                  <Typography
                    component="div"
                    sx={{ pl: 2, lineHeight: 1.3 }}
                    variant="overline"
                  >
                    {timeRenderer({ data: date.hour })}:
                    {timeRenderer({ data: date.minute })}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Stack
                  direction="row"
                  sx={{
                    alignItems: 'center',
                    pl: { xs: 0, sm: 1 },
                    pt: { xs: 4, sm: 0 }
                  }}
                  spacing={{ xs: 2, sm: 1 }}
                >
                  <Avatar
                    sx={{
                      backgroundColor: colorMapper({ data: data.fluencyGoal })
                    }}
                    aria-label="recipe"
                  >
                    {data.fluencyGoal}
                  </Avatar>
                  <Typography
                    sx={{ fontSize: '0.5rem', lineHeight: 1.4 }}
                    variant="overline"
                  >
                    Fluency Goal
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          }
        />
        <CardContent>
          <Grid container>
            <Grid item xs={6}>
              <Grid container sx={{ alignItems: 'center' }}>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontSize: { xs: '0.6rem' },
                      lineHeight: '1.7'
                    }}
                    variant="overline"
                    align="center"
                    color={theme.palette.text.secondary}
                  >
                    Live Evaluation
                  </Typography>
                  <Box sx={{ pr: 1 }}>
                    <Divider />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Stack
                    direction="row"
                    sx={{ py: 1, alignItems: 'center' }}
                    spacing={{ xs: 2, sm: 1 }}
                  >
                    <Avatar
                      sx={{
                        backgroundColor: colorMapper({
                          data: data.evaluationSeverity
                        })
                      }}
                    >
                      {data.evaluationSeverity}
                    </Avatar>
                    <Typography
                      sx={{ fontSize: '0.5rem', lineHeight: 1.4 }}
                      variant="overline"
                    >
                      Stuttering Severity
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Stack
                    direction="row"
                    sx={{ py: 1, alignItems: 'center' }}
                    spacing={{ xs: 2, sm: 1 }}
                  >
                    <Avatar
                      sx={{
                        backgroundColor: colorMapper({
                          data: data.evaluationFluency
                        })
                      }}
                    >
                      {data.evaluationFluency}
                    </Avatar>
                    <Typography
                      sx={{ fontSize: '0.5rem', lineHeight: 1.4 }}
                      variant="overline"
                    >
                      Fluency
                      <br /> Technique
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={4}>
                  {data.evaluationInconsistency && (
                    <Stack
                      sx={{ py: 1, alignItems: 'center' }}
                      direction="row"
                      spacing={{ xs: 2, sm: 1 }}
                    >
                      <>
                        <UnfoldMoreDoubleIcon />
                        <Typography
                          sx={{ fontSize: '0.5rem', lineHeight: 1.4 }}
                          variant="overline"
                        >
                          Consistency
                        </Typography>
                      </>
                    </Stack>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container sx={{ alignItems: 'center' }}>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontSize: { xs: '0.6rem' },
                      lineHeight: '1.7'
                    }}
                    variant="overline"
                    align="center"
                    color={theme.palette.text.secondary}
                  >
                    Recording Evaluation
                  </Typography>
                  <Box>
                    <Divider />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                  {data.recordingSeverity !== null && (
                    <Stack
                      direction="row"
                      sx={{ py: 1, alignItems: 'center' }}
                      spacing={{ xs: 2, sm: 1 }}
                    >
                      <Avatar
                        sx={{
                          backgroundColor: colorMapper({
                            data: data.recordingSeverity
                          })
                        }}
                      >
                        {data.recordingSeverity}
                      </Avatar>
                      <Typography
                        sx={{ fontSize: '0.5rem', lineHeight: 1.4 }}
                        variant="overline"
                      >
                        Stuttering <br /> Severity
                      </Typography>
                    </Stack>
                  )}
                </Grid>
                <Grid item xs={12} sm={4}>
                  {data.recordingFluency && (
                    <Stack
                      sx={{ py: 1, alignItems: 'center' }}
                      direction="row"
                      spacing={{ xs: 2, sm: 1 }}
                    >
                      <Avatar
                        sx={{
                          backgroundColor: colorMapper({
                            data: data.recordingFluency
                          })
                        }}
                      >
                        {data.recordingFluency}
                      </Avatar>
                      <Typography
                        sx={{ fontSize: '0.5rem', lineHeight: 1.4 }}
                        variant="overline"
                      >
                        Fluency <br /> Technique
                      </Typography>
                    </Stack>
                  )}
                </Grid>
                <Grid item xs={12} sm={4}>
                  {data.recordingInconsistency && (
                    <Stack
                      sx={{ py: 1, alignItems: 'center' }}
                      direction="row"
                      spacing={{ xs: 2, sm: 1 }}
                    >
                      <>
                        <UnfoldMoreDoubleIcon />
                        <Typography
                          sx={{ fontSize: '0.5rem', lineHeight: 1.4 }}
                          variant="overline"
                        >
                          Consistency
                        </Typography>
                      </>
                    </Stack>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions disableSpacing>
          <Typography variant="body2">{note}</Typography>
          {data.note && (
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          )}
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography color={theme.palette.text.secondary}>
              {data.note}
            </Typography>
          </CardContent>
        </Collapse>
      </Card>
    </Box>
  );
}

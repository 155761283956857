import { useEffect, useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { Box, Grid } from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import { useAppContext } from 'context';
import { useSnackbar } from 'context/SnackBar';
import { updateSupport } from 'graphql/mutations';
import { supportsByClientID } from 'graphql/queries';

import SupportRequest from '../SupportRequest';

export default function SupportRequests({
  supportRequestsExist,
  setSupportRequestsExist
}) {
  const { state } = useAppContext();
  const { user } = useAuthenticator(context => [context.user]);
  const clientID =
    state?.client?.id || user.attributes['custom:clientID'] || user.username;
  const [supportRequests, setSupportRequests] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchSupportRequests = async () => {
      const { data } = await API.graphql(
        graphqlOperation(supportsByClientID, { clientID })
      );
      const fetchedSupportRequests = data.supportsByClientID.items;

      function isRequestActive(supportRequest) {
        const currentTime = new Date();
        const expirationTime = new Date(supportRequest.expiresAt);
        const isExpired = expirationTime < currentTime;
        const isUsed = supportRequest.used;

        return !isExpired && !isUsed;
      }

      const activeSupportRequests =
        fetchedSupportRequests.filter(isRequestActive);

      setSupportRequests(activeSupportRequests); // Set only active requests

      if (activeSupportRequests.length > 0) {
        setSupportRequestsExist(true);
      } else {
        setSupportRequestsExist(false);
      }
    };

    if (clientID) {
      fetchSupportRequests();
    }
  }, [clientID, supportRequestsExist, setSupportRequestsExist]);

  const handleCloseSupportRequest = async (
    supportRequestId,
    supportRequestVersion
  ) => {
    try {
      await API.graphql(
        graphqlOperation(updateSupport, {
          input: {
            id: supportRequestId,
            used: true,
            _version: supportRequestVersion
          }
        })
      );
      setSupportRequestsExist(false);
      enqueueSnackbar('Closed support request', { severity: 'success' });
    } catch (err) {
      console.error('Error closing support request', err);
      enqueueSnackbar('Error closing support request', { severity: 'error' });
    }
  };

  return (
    <Box mt={4}>
      <Grid container>
        {supportRequests &&
          supportRequests.length > 0 &&
          supportRequests.map(supportRequest => (
            <Grid item xs={12} sm={6} xl={4} key={supportRequest.id}>
              <SupportRequest
                handleCloseSupportRequest={handleCloseSupportRequest}
                setSupportRequestsExist={setSupportRequestsExist}
                fromSettings
                supportRequest={supportRequest}
              />
            </Grid>
          ))}
      </Grid>
    </Box>
  );
}

import React, { memo } from 'react';

import { RadioButtonChecked } from '@mui/icons-material';
import { Card, CardActionArea, CardHeader } from '@mui/material';

const ProgramCard = ({
  title,
  suitableFor,
  program,
  selectTemplate,
  selectedTemplate
}) => {
  return (
    <Card
      elevation={0}
      sx={{
        mb: 2,
        backgroundColor:
          selectedTemplate === program ? 'primary.lightest' : 'background.card'
      }}
    >
      <CardActionArea onClick={() => selectTemplate(program)}>
        <CardHeader
          title={title}
          subheader={suitableFor}
          action={
            selectedTemplate === program ? (
              <RadioButtonChecked color="primary" />
            ) : null
          }
        />
      </CardActionArea>
    </Card>
  );
};

export default memo(ProgramCard);

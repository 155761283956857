import { Search } from '@mui/icons-material';
import { Box, InputAdornment, TextField } from '@mui/material';

export default function SearchBar({ state, setState, disabled }) {
  return (
    <Box>
      <TextField
        disabled={disabled}
        size="small"
        sx={{ width: '100%' }}
        fullWidth
        value={state}
        onChange={e => setState(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Search />
            </InputAdornment>
          )
        }}
      />
    </Box>
  );
}

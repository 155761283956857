import { Box } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

export default function ForSelector({ forValue, handleForChange }) {
  return (
    <Box my={1}>
      <FormControl>
        <FormLabel id="demo-row-radio-buttons-group-label">
          I am setting up SpeechFit for:
        </FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={forValue}
          onChange={handleForChange}
        >
          <FormControlLabel
            value="child"
            control={<Radio />}
            label="My child"
          />
          <FormControlLabel value="me" control={<Radio />} label="Myself" />
          <FormControlLabel
            value="other"
            control={<Radio />}
            label="Someone else"
          />
        </RadioGroup>
      </FormControl>
    </Box>
  );
}

import {
  Badge,
  ContactMail,
  Diversity3,
  DynamicForm,
  EmojiPeople,
  EscalatorWarning,
  Event,
  Group
} from '@mui/icons-material';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  Link,
  Stack,
  Typography
} from '@mui/material';

import { formatProgram } from 'utils/formatProgram';
import { toRomanNumeral } from 'utils/toRomanNumerals';

export default function Confirm({
  email,
  source,
  age,
  program,
  programStage,
  forValue,
  firstName,
  lastName,
  clientFirstName,
  clientLastName,
  clientExists,
  participants
}) {
  const clinician = source === 'clinician';

  const config = {
    child: {
      icon: <EscalatorWarning />,
      relation: `${source === 'clinician' ? 'a' : 'my'} child`,
      defaultName: `${firstName} ${lastName}`
    },
    adult: {
      icon: <EmojiPeople />,
      relation: 'an adult',
      defaultName: `${firstName} ${lastName}`
    },
    carer: {
      icon: <EmojiPeople />,
      relation: 'a person with a carer',
      defaultName: `${firstName} ${lastName}`
    },
    me: {
      icon: <EmojiPeople />,
      relation: 'myself',
      defaultName: `${firstName} ${lastName}`
    },
    other: {
      icon: <Diversity3 />,
      relation: `someone ${clinician ? 'in a category not listed' : 'else'}`,
      defaultName: `${firstName} ${lastName}`
    }
  };

  const currentConfig = config[forValue];

  const getEmailStatement = () => {
    if (forValue === 'child') {
      return (
        <>
          Their parent will receive an invitation at <Link>{email}</Link>.
        </>
      );
    } else if (forValue === 'carer') {
      return (
        <>
          Their carer will receive an invitation at <Link>{email}</Link>.
        </>
      );
    } else if (clinician && clientExists) {
      return (
        <>
          They will receive a linking invitation at <Link>{email}</Link>.
        </>
      );
    } else {
      return (
        <>
          They will receive an invitation at <Link>{email}</Link>.
        </>
      );
    }
  };

  const getNameStatement = () => {
    if (forValue === 'me')
      return (
        <>
          My name is <Link>{currentConfig.defaultName}</Link>
        </>
      );

    let subjectName =
      forValue === 'adult' ? (
        <Link>{currentConfig.defaultName}</Link>
      ) : clientFirstName ? (
        <>
          <Link>
            {clientFirstName} {clientLastName || lastName}
          </Link>
        </>
      ) : (
        <Link>{currentConfig.defaultName}</Link>
      );

    if (forValue === 'child' && !clientFirstName) {
      return (
        <>
          I haven't specified {clinician ? `the child's` : 'their'} name, so{' '}
          {clinician ? 'I' : 'my clinician'} will see{' '}
          {clinician ? `their parent's` : 'my'} name - {subjectName}.
        </>
      );
    } else if (forValue === 'child' && clientFirstName) {
      return (
        <>
          {clinician ? `My client's` : 'Their'} name is {subjectName}
          {clinician && ` and their parent's name is ${firstName} ${lastName}`}.
        </>
      );
    } else if (forValue === 'other' && !clientFirstName) {
      return (
        <>
          I haven't specified {clinician ? `the client's` : 'their'} name, so{' '}
          {clinician ? 'I' : 'our clinician'} will see{' '}
          {clinician ? `the account owner's` : 'my'} name - {subjectName}.
        </>
      );
    } else if (forValue === 'other' && clientFirstName) {
      return <>Their name is {subjectName}.</>;
    } else if (forValue === 'adult' && firstName) {
      return <>Their name is {subjectName}.</>;
    } else if (forValue === 'carer' && clientFirstName) {
      return (
        <>
          The client's name is {subjectName} and their carer's name is{' '}
          {firstName} {lastName}.
        </>
      );
    } else if (forValue === 'carer' && firstName) {
      return (
        <>
          I haven't specified the client's name so I will see their carer's name
          - {subjectName}.
        </>
      );
    } else {
      return <>This account will be used for {subjectName}.</>;
    }
  };

  const getProgramStatement = () => {
    const perspective = clinician
      ? 'They are'
      : !program && forValue === 'other'
      ? 'I am'
      : forValue === 'me'
      ? 'I am'
      : forValue === 'child'
      ? `${clinician ? 'They' : 'We'} are`
      : 'They are';

    if (program) {
      return (
        <>
          {perspective} on the <Link>{formatProgram(program)}</Link> program
          {programStage && ` - Stage ${toRomanNumeral(programStage)}`}.
        </>
      );
    } else if (clinician && clientExists && !program) {
      return `I am not adding any new program elements, as they already have an account.`;
    }

    return `${perspective} setting up from scratch.`;
  };

  const getAgeStatement = () => {
    const perspective =
      forValue === 'me'
        ? 'I am'
        : forValue === 'child'
        ? 'They are'
        : 'They are';
    const noAgeResponse =
      forValue === 'me'
        ? "I haven't"
        : forValue === 'child'
        ? "I haven't specified my child's"
        : "I haven't specified their";
    const setupOption = program ? 'generic program settings' : 'from scratch';

    if (age) {
      return (
        <>
          {perspective} <Link>{age}</Link> years old.
        </>
      );
    } else {
      return `${noAgeResponse} age. We'll set up with ${setupOption}.`;
    }
  };

  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="row" spacing={2}>
        <Box mt={0.25}>{currentConfig.icon}</Box>
        <Typography variant="h5">
          I am setting up SpeechFit for <Link>{currentConfig.relation}</Link>.
        </Typography>
      </Stack>

      <Stack direction="row" spacing={2}>
        <Box mt={0.5}>
          <Badge />
        </Box>
        <Typography variant="h5">{getNameStatement()}</Typography>
      </Stack>

      <Stack direction="row" spacing={2}>
        <Box mt={0.5}>
          <Event />
        </Box>
        <Typography variant="h5">{getAgeStatement()}</Typography>
      </Stack>
      <Stack direction="row" spacing={2}>
        <Box mt={0.5}>
          <DynamicForm />
        </Box>
        <Typography variant="h5">{getProgramStatement()}</Typography>
      </Stack>
      {clinician && (
        <Stack direction="row" spacing={2}>
          <Box mt={0.5}>
            <ContactMail />
          </Box>
          <Typography variant="h5">{getEmailStatement()}</Typography>
        </Stack>
      )}
      {participants?.length > 0 && (
        <Box>
          <Stack direction="row" spacing={2} alignItems="center">
            <Box pt={0.5}>
              <Group />
            </Box>
            <Stack direction="row" spacing={2} alignItems="center">
              <Typography variant="h5">Participant Invitations</Typography>
              <Chip label={participants.length} />
            </Stack>
          </Stack>
          <Box mb={2} mt={1}>
            {/* <Divider sx={{ opacity: 0.3 }} /> */}
          </Box>
          <Grid container spacing={2}>
            {participants.map(p => (
              <Grid key={p.id} item xs={12} sm={6}>
                <Card
                  elevation={0}
                  sx={{ backgroundColor: 'background.default' }}
                >
                  <CardHeader
                    title={p.firstName + ' ' + p.lastName}
                    subheader={p.role}
                  />
                  <CardContent>
                    <Typography variant="h6">{p.email}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </Stack>
  );
}

import { Launch, Person } from '@mui/icons-material';
import {
  Box,
  FormLabel,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';

import { LocationCard } from 'components/atoms';

export default function StepThree({
  adminEmail,
  adminFirstName,
  adminLastName,
  selectedClinicLocation,
  locations,
  name,
  website,
  logo
}) {
  return (
    <Box sx={{ height: '100%', width: '100%', mt: 2 }}>
      <Grid container sx={{ alignItems: 'center' }}>
        <Grid item xs={12} sm={8}>
          <Stack direction="row" spacing={4} alignContent="flex-start">
            {logo && (
              <Box>
                <Box>
                  <img
                    style={{ maxHeight: 80, width: 'auto', maxWidth: '100%' }}
                    src={URL.createObjectURL(logo)}
                    alt="Logo"
                  />
                </Box>
              </Box>
            )}
            <Box display="flex" flexDirection="column">
              <FormLabel sx={{ mb: '8px' }}>Practice Name</FormLabel>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography variant="h4">{name}</Typography>
                {website && (
                  <Tooltip title={`Website: ${website}`}>
                    <IconButton
                      onClick={() => {
                        window.open(website, '_blank', 'noopener,noreferrer');
                      }}
                    >
                      <Launch />
                    </IconButton>
                  </Tooltip>
                )}
              </Stack>
            </Box>
          </Stack>
        </Grid>
      </Grid>
      {locations.length !== 0 && (
        <Grid sx={{ mt: 2 }} container spacing={2}>
          <Grid item xs={12}>
            <FormLabel>Locations</FormLabel>
          </Grid>
          {locations.map((location, index) => (
            <Grid key={`location-${index}`} item xs={12} sm={6}>
              <LocationCard
                info
                dark
                location={location}
                selected={
                  selectedClinicLocation &&
                  selectedClinicLocation?.id === location.id
                }
              />
            </Grid>
          ))}
        </Grid>
      )}
      {adminFirstName && adminLastName && adminEmail && (
        <Box sx={{ mt: 2 }}>
          <Box>
            <FormLabel>Inviting Admin</FormLabel>
          </Box>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Person />
            <Typography variant="h6">
              {adminFirstName} {adminLastName}
            </Typography>
            <Typography variant="h6">{adminEmail}</Typography>
          </Stack>
        </Box>
      )}
    </Box>
  );
}

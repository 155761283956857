import { Box, Skeleton, Typography } from '@mui/material';
import { useAppContext } from 'context';

import SettingHeader from '../SettingHeader';

export default function ClientAccountEmail() {
  const { state } = useAppContext();
  const { client } = state;
  return (
    <Box p={1} my={2}>
      <SettingHeader label="Account Email" noAction />
      <Box p={1} sx={{ display: 'flex', alignItems: 'center' }}>
        {!client?.email ? (
          <Skeleton width={100} />
        ) : (
          <Typography variant="h6">{client?.email}</Typography>
        )}
      </Box>
    </Box>
  );
}

export const getInitials = (firstName, lastName) => {
  const firstNameInitial = firstName ? firstName.charAt(0) : '';
  const lastNameInitial = lastName ? lastName.charAt(0) : '';
  return firstNameInitial + lastNameInitial;
};

export function abbreviate(text) {
  const lowerCaseText = text.toLowerCase().trim();

  const ss = ['stuttering'];
  const ft = ['fluency'];

  if (lowerCaseText.includes(ss)) {
    return 'SR';
  }
  if (lowerCaseText.includes(ft)) {
    return 'FT';
  }

  const words = text.split(' ');
  let abbreviation = '';
  for (let word of words) {
    if (word.length > 0) {
      abbreviation += word[0].toUpperCase();
    }
  }
  return abbreviation;
}

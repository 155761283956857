import { memo, useCallback, useEffect, useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import {
  DndContext,
  MouseSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  rectSortingStrategy
} from '@dnd-kit/sortable';
import { AddBox } from '@mui/icons-material';
import { Chip, CircularProgress, Stack } from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import { useAppContext } from 'context';
import { useEntryContext } from 'context/Entry';
import { entriesBySectionByOrder } from 'graphql/customQueries';
import { createEntry, updateEntry } from 'graphql/mutations';
import { createDefaultEntry } from 'graphql/queries';
import mixpanel from 'mixpanel-browser';
import uuid from 'react-uuid';

import { Section } from 'components/molecules';

import SortableChip from './sortableChip';

const normalizeFactor = 10;

function RecordSection({
  mainSection,
  onDeleteSection,
  onUpdateSection,
  section,
  disableButtons = false,
  setSwitchingEntry,
  setSections
}) {
  const { state, dispatch } = useAppContext();
  const { user } = useAuthenticator(context => [context.user]);
  const clientID =
    state?.client?.id || user.attributes['custom:clientID'] || user.username;
  const clientSub = state?.client?.sub || user.attributes.sub;
  const clientProgram =
    state?.client?.program || user.attributes['custom:program'];
  const { setSelectedEntry } = useEntryContext();
  const sensors = useSensors(useSensor(MouseSensor), useSensor(PointerSensor));

  const [entries, setEntries] = useState([]);
  const [isCreating, setIsCreating] = useState(false);
  const fetchEntries = useFetchEntries(section.id, setEntries);

  useEffect(() => {
    const activeEntries = entries.filter(entry => !entry.inactive);
    if (activeEntries.length > 0) {
      setSelectedEntry(activeEntries[0]);
      setSwitchingEntry(false);
    }
  }, [entries, setSelectedEntry, setSwitchingEntry]);

  useEffect(() => {
    fetchEntries();
    return () => {
      setEntries([]);
    };
  }, [fetchEntries]);

  const createEntry = useCreateEntry(
    setEntries,
    setIsCreating,
    clientID,
    clientSub,
    clientProgram
  );
  const deleteEntry = useDeleteEntry(setEntries);
  const dragEntry = useDragEntry(entries, setEntries);

  const handleChipClick = entry => {
    setSwitchingEntry(true);
    if (entry !== state?.record.entryID) {
      dispatch({ type: 'set_record', record: {} });
    }
    setSelectedEntry(entry);
    dispatch({ type: 'set_section', sectionId: section.id });
    setSwitchingEntry(false);
  };

  const handleCreateEntry = async () => {
    if (!clientID) return;
    createEntry({
      name: `Entry Name`,
      clientID: clientID,
      sectionID: section.id,
      complete: true,
      order: (entries[entries.length - 1]?.order ?? 0) + 1 * normalizeFactor,
      owner: `${clientSub}::${clientID}`
    });
    mixpanel.track('create_entry');
    window.dataLayer.push({
      event: 'create_entry'
    });
  };

  const handleDeleteEntry = async entry => {
    deleteEntry(entry);
  };

  const handleDeleteSection = () => {
    onDeleteSection?.(section);
  };

  const handleDragEntry = ({ active, over }) => {
    dragEntry({ active, over });
  };

  const handleUpdateSection = updatedSection => {
    onUpdateSection?.(updatedSection);
  };

  return (
    <Section
      mainSection={mainSection}
      elevation={4}
      disableButtons={section.noEdit !== null ? section.noEdit : disableButtons}
      onDelete={handleDeleteSection}
      section={section}
      updateSection={handleUpdateSection}
      setSections={setSections}
    >
      <Stack alignItems="center" direction="row" flexWrap="wrap">
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEntry}
        >
          <SortableContext items={entries} strategy={rectSortingStrategy}>
            {entries.reduce((acc, entry) => {
              if (entry.complete !== true) {
                acc.push(
                  <SortableChip
                    setEntries={setEntries}
                    key={entry.id}
                    removeChip={handleDeleteEntry}
                    chip={entry}
                    handleChipClick={handleChipClick}
                    sortDisabled={!state.edit}
                    state={state}
                  />
                );
              }
              return acc;
            }, [])}
          </SortableContext>
        </DndContext>
        {state.edit && !section.noEdit && (
          <Chip
            sx={{ mr: 1, mt: 1 }}
            clickable
            onClick={handleCreateEntry}
            icon={isCreating ? <CircularProgress size={24} /> : <AddBox />}
            label="Add New"
            color="primary"
          />
        )}
      </Stack>
    </Section>
  );
}

function useCreateEntry(
  setEntries,
  setIsCreating,
  clientID,
  clientSub,
  clientProgram
) {
  return useCallback(
    async ({ sectionID, name = `Entry Name`, order }) => {
      try {
        setIsCreating(true);
        const entryID = uuid();
        const { data } = await API.graphql({
          query: createEntry,
          variables: {
            input: {
              id: entryID,
              clientID,
              name,
              order,
              sectionID,
              sectionEntriesId: sectionID,
              owner: `${clientSub}::${clientID}`
            }
          }
        });
        const vars = {
          entryID: entryID,
          clientSub,
          clientID,
          program: clientProgram
        };
        API.graphql(graphqlOperation(createDefaultEntry, vars));
        const createdEntry = data?.createEntry;
        if (createdEntry)
          setEntries(prevEntries => prevEntries.concat(createdEntry));
      } catch (e) {
        console.log('error', e);
      } finally {
        setIsCreating(false);
      }
    },
    [setEntries, setIsCreating, clientID, clientSub, clientProgram]
  );
}

function useDeleteEntry(setEntries) {
  return useCallback(
    async entry => {
      const { data } = await API.graphql({
        query: updateEntry,
        variables: {
          input: { id: entry.id, complete: true, _version: entry._version }
        }
      });
      const updatedEntry = data.updateEntry;
      setEntries(prevEntries =>
        prevEntries.filter(({ id }) => id !== updatedEntry.id)
      );
      mixpanel.track('completeEntry');
      window.dataLayer.push({
        event: 'complete_entry'
      });
    },
    [setEntries]
  );
}

function useFetchEntries(sectionID, setEntries) {
  return useCallback(async () => {
    const result = await API.graphql(
      graphqlOperation(entriesBySectionByOrder, { sectionID })
    );
    const entries = result.data?.entriesBySectionByOrder?.items
      .filter(entry => !entry.complete)
      .sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
    setEntries(entries);
  }, [sectionID, setEntries]);
}

function useDragEntry(entries, setEntries) {
  return useCallback(
    async ({ active, over }) => {
      if (over) {
        const overIndex = entries.findIndex(section => section.id === over.id);
        const activeIndex = entries.findIndex(
          section => section.id === active.id
        );
        if (activeIndex !== overIndex) {
          const newOrder =
            activeIndex < overIndex
              ? overIndex === entries.length - 1
                ? entries[overIndex].order + 1 * normalizeFactor
                : (entries[overIndex].order + entries[overIndex + 1].order) / 2
              : (entries[overIndex].order +
                  (entries[overIndex - 1]?.order ?? 0)) /
                2;
          const dropppedEntry = { ...entries[activeIndex], order: newOrder };
          setEntries(prevEntries =>
            arrayMove(prevEntries, activeIndex, overIndex).map(entry =>
              entry.id === dropppedEntry.id ? dropppedEntry : entry
            )
          );
          await API.graphql(
            graphqlOperation(updateEntry, {
              input: { id: dropppedEntry.id, order: dropppedEntry.order }
            })
          );
        }
      }
    },
    [entries, setEntries]
  );
}

export default memo(RecordSection);

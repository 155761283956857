import { memo, useMemo, useRef, useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react-core';
import { Delete, EditCalendar } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Chip,
  Container,
  Divider,
  Grid,
  Hidden,
  IconButton,
  Popover,
  Skeleton,
  Stack,
  ThemeProvider,
  Tooltip,
  Typography,
  createTheme,
  useTheme
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { API, graphqlOperation } from 'aws-amplify';
import { useAppContext } from 'context';
import { useEntryContext } from 'context/Entry';
import { useSnackbar } from 'context/SnackBar';
import {
  entrySectionsByEntryIDByOrder,
  userInputsByRecordComponentType
} from 'graphql/customQueries';
import { updateEntry, updateRecord, updateUserInput } from 'graphql/mutations';
import {
  cleanUpRecordsUserInputs,
  getEntry,
  getRecord,
  updateRecordDate
} from 'graphql/queries';
import useEffectOnce from 'hooks/useEffectOnce';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';

import { BackButton, LoadingAtom } from 'components/atoms';
import { useUpdateGoal } from 'components/goals/functions';
import {
  CheckBoxComponent,
  Clock,
  ExerciseContent,
  Infinite,
  Input,
  RadioComponent,
  Rater,
  Section
} from 'components/molecules';
import { getIconComponent } from 'components/molecules/IconPicker';
import { Audio as AudioComponent } from 'components/organisms';

export function EntryComponent({ component, userInput }) {
  const {
    id: componentID,
    name,
    to,
    from,
    rows,
    label,
    placeholder,
    collapse,
    upload,
    annotationLabels,
    options,
    content,
    type
  } = component;
  const { user } = useAuthenticator(context => [context.user]);
  const { state } = useAppContext();
  const userInputID = userInput?.id;
  const [currentVersion, setCurrentVersion] = useState(userInput?._version);

  const handleUpdateUserInput = async changes => {
    const input = {
      id: userInput.id,
      updatedBy: user.username,
      _version: currentVersion,
      ...changes
    };
    const { data } = await API.graphql(
      graphqlOperation(updateUserInput, {
        input
      })
    );

    const newVersion = data.updateUserInput._version;
    setCurrentVersion(newVersion);
  };
  const debouncedHandleUpdateUserInput = useDebouncedCallback(
    handleUpdateUserInput,
    300
  );

  const handleDataChange = data => {
    if (!userInput) return;

    let changes = {};
    switch (userInput.componentType) {
      case 'infinite':
        changes = { numberValue: data };
        break;
      case 'clock':
        changes = { clockValue: data };
        break;
      case 'checkboxes':
        changes = { checkboxesValue: data };
        break;
      case 'input':
        changes = { inputValue: data };
        break;
      case 'radio':
        changes = { radiosValue: data };
        break;
      case 'rater':
        changes = {
          raterValue: data.raterValue,
          raterBoolean: data.raterBooleanValue
        };
        break;
      case 'audio':
        changes = { ...data };
        break;
      default:
        break;
    }

    switch (userInput.componentType) {
      case 'infinite':
      case 'clock':
      case 'checkboxes':
      case 'radio':
      case 'audio':
      case 'rater':
        handleUpdateUserInput(changes);
        break;
      case 'input':
        debouncedHandleUpdateUserInput(changes);
        break;
      default:
        break;
    }
  };

  switch (type) {
    case 'input':
      return (
        <Input
          userInputID={userInputID}
          componentID={componentID}
          componentType="input"
          initialData={userInput?.inputValue}
          placeholder={placeholder || ''}
          label={label || ''}
          name={name}
          rows={rows || 1}
          onDataChange={handleDataChange}
        />
      );
    case 'rater':
      return (
        <Rater
          userInputID={userInputID}
          componentID={componentID}
          componentType="rater"
          initialRaterData={userInput?.raterValue}
          initialRaterBooleanData={userInput?.raterBoolean}
          name={name}
          from={from ?? 0}
          to={
            // eslint-disable-next-line
            to != undefined
              ? to
              : state?.client?.program?.includes('camperdown')
              ? 8
              : user.attributes['custom:program']?.includes('camperdown')
              ? 8
              : 10
          }
          onDataChange={handleDataChange}
        />
      );
    case 'content':
      return (
        <ExerciseContent
          userInputID={userInputID}
          component={component}
          componentType="content"
          content={content || ''}
          name={name}
        />
      );
    case 'checkboxes':
      return (
        <CheckBoxComponent
          userInputID={userInputID}
          component={component}
          componentType="checkboxes"
          initialData={userInput?.checkboxesValue ?? []}
          options={options}
          name={name}
          onDataChange={handleDataChange}
        />
      );
    case 'radio':
      return (
        <RadioComponent
          userInputID={userInputID}
          component={component}
          componentType="radio"
          initialData={userInput?.radiosValue ?? []}
          name={name}
          options={options}
          onDataChange={handleDataChange}
        />
      );

    case 'infinite':
      return (
        <Infinite
          userInput={userInput}
          component={component}
          componentType="infinite"
          initialData={userInput?.numberValue ?? 0}
          from={from ?? 0}
          to={to ?? 1000}
          name={name}
          onDataChange={handleDataChange}
        />
      );
    case 'audio':
      return (
        <AudioComponent
          userInput={userInput}
          userInputID={userInputID}
          initialMode={userInput?.audioMode}
          component={component}
          componentType="audio"
          collapse={collapse ?? true}
          upload={upload ?? true}
          name={name}
          annotationLabels={annotationLabels ?? true}
          onDataChange={handleDataChange}
        />
      );
    case 'clock':
      return (
        <Clock
          userInput={userInput}
          userInputID={userInputID}
          component={component}
          componentType="clock"
          initialData={userInput?.clockValue ?? 0}
          name={name}
          onDataChange={handleDataChange}
        />
      );
    default:
      return null;
  }
}

const EntrySection = memo(function (props) {
  const { entry, entrySection, label, userInputs } = props;

  const components = useMemo(
    () =>
      entrySection?.components?.items?.sort((a, b) => a.order - b.order) || [],
    [entrySection]
  );
  const userInputsByComponentID = useMemo(
    () => _.keyBy(userInputs, 'componentID'),
    [userInputs]
  );

  const settings = JSON.parse(entrySection.settings);

  return (
    <Section elevation={4} section={entrySection} label={label}>
      <Stack direction={settings?.orientation || 'column'} spacing={2}>
        {components
          .filter(component => {
            const userInput = userInputsByComponentID[component.id];
            const hasData =
              userInput &&
              ((userInput.componentType === 'chip' && userInput.chipsValue) ||
                (userInput.componentType === 'input' && userInput.inputValue) ||
                (userInput.componentType === 'emoji-rater' &&
                  userInput.emojiRaterValue) ||
                (userInput.componentType === 'rater' && userInput.raterValue) ||
                (userInput.componentType === 'checkboxes' &&
                  userInput.checkboxesValue) ||
                (userInput.componentType === 'radio' &&
                  userInput.radiosValue) ||
                (userInput.componentType === 'slider' &&
                  userInput.sliderValue) ||
                (userInput.componentType === 'infinite' &&
                  userInput.numberValue) ||
                (userInput.componentType === 'audio' && userInput?.audioReady));
            return !component.complete || (component.complete && hasData);
          })
          .map(component => (
            <EntryComponent
              key={component.id}
              entry={entry}
              component={component}
              userInput={userInputsByComponentID[component.id]}
            />
          ))}
      </Stack>
    </Section>
  );
});

export default function Record() {
  const { id: recordID } = useParams();
  const { user } = useAuthenticator(context => [context.user]);
  const isClinician = user.attributes['custom:type'] === 'clinician';
  const navigate = useNavigate();
  const { dispatch } = useAppContext();
  const { setSelectedEntry } = useEntryContext();
  const [entry, setEntry] = useState(null);
  const [entrySections, setEntrySections] = useState([]);
  const [userInputs, setUserInputs] = useState([]);
  const deletingRef = useRef(false);
  const [deleting, setDeleting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [record, setRecord] = useState({});
  const [enteredAt, setEnteredAt] = useState('');
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [saving, setSaving] = useState(false);
  const updateRecordAndGoalEntry = useUpdateGoal();

  const buttonRef = useRef(null);
  const currentTheme = useTheme();
  const theme = createTheme(currentTheme, {
    components: {
      MuiPickersToolbar: {
        styleOverrides: {
          penIconButton: {
            display: 'none'
          }
        }
      }
    }
  });

  useEffectOnce(() => {
    if (!recordID) return;

    fetchEntryAndEntrySectionsAndUserInputsByRecordID(recordID).then(
      ({ entry, entrySections, userInputs, record }) => {
        setRecord(record);
        setEnteredAt(record.enteredAt);
        setEntry(entry);
        setEntrySections(entrySections);
        setUserInputs(userInputs);
        setLoading(false);
      }
    );
    return () => {
      setRecord({});
      setEntry({});
      setUserInputs([]);
    };
  }, [recordID]);

  const handleDelete = async () => {
    try {
      deletingRef.current = true;
      setDeleting(true);
      if (entry?.draftRecordID === recordID) {
        await API.graphql(
          graphqlOperation(updateEntry, {
            input: {
              id: entry.id,
              draftRecordID: '-',
              _version: entry._version
            }
          })
        );
      }
      await API.graphql(
        graphqlOperation(cleanUpRecordsUserInputs, { recordID })
      );
      const { data } = await API.graphql(
        graphqlOperation(userInputsByRecordComponentType, {
          recordID
        })
      );
      const userInput = data.userInputsByRecordComponentType.items.find(
        ui => ui.componentType === 'infinite'
      );

      await updateRecordAndGoalEntry({
        passedRecord: record,
        action: 'delete',
        userInput: userInput
      });

      dispatch({ type: 'set_record', record: {} });
      navigate(isClinician ? '/client/entries' : '/entries');
    } catch (err) {
      console.log(err);
    }
  };

  const handleSaveAndExit = async () => {
    try {
      setSaving(true);
      await API.graphql(
        graphqlOperation(updateRecord, {
          input: {
            id: recordID,
            draft: false,
            enteredAt: new Date().toISOString(),
            _version: record._version
          }
        })
      );

      if (entry?.draftRecordID === recordID) {
        await API.graphql(
          graphqlOperation(updateEntry, {
            input: {
              id: entry.id,
              draftRecordID: '-',
              _version: entry._version
            }
          })
        );
      }
      setSelectedEntry({});
      dispatch({ type: 'set_record', record: {} });
      navigate(-1);
    } catch (err) {
      console.log(err);
      setSaving(false);
    }
  };

  const updateEnteredAt = async newValue => {
    try {
      const newDate = newValue.toISO();
      setEnteredAt(newDate);
      const { data } = await API.graphql(
        graphqlOperation(updateRecordDate, { recordID, newDate })
      );
      if (data.updateRecordDate.statusCode !== 200) {
        throw new Error(data.updateRecordDate.body);
      }
      setDatePickerOpen(false);
      enqueueSnackbar('Changed date', { severity: 'success' });
    } catch (err) {
      console.log(err);
      enqueueSnackbar('Error changing date', { severity: 'error' });
    }
  };

  const hasUserData = userInput => {
    const hasData =
      userInput &&
      ((userInput.componentType === 'chip' && userInput.chipsValue) ||
        (userInput.componentType === 'chip' && userInput.inputValue) ||
        (userInput.componentType === 'emoji-rater' &&
          userInput.emojiRaterValue) ||
        (userInput.componentType === 'rater' && userInput.raterValue) ||
        (userInput.componentType === 'checkbox' && userInput.checkboxesValue) ||
        (userInput.componentType === 'radio' && userInput.radiosValue) ||
        (userInput.componentType === 'slider' && userInput.sliderValue) ||
        (userInput.componentType === 'incrementer' &&
          userInput.incrementerValue) ||
        (userInput.componentType === 'audio' && userInput?.audioReady));

    return hasData;
  };
  return loading ? (
    <LoadingAtom />
  ) : (
    <Grid container sx={{ justifyContent: 'center' }}>
      <Grid item sm={9}>
        <Container maxWidth="md" sx={{ mb: 10 }}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            my={2}
          >
            <BackButton />
            <Hidden smDown>
              <LoadingButton
                loading={deleting}
                onClick={handleDelete}
                endIcon={<Delete />}
              >
                Delete
              </LoadingButton>
            </Hidden>
            {record.draft && (
              <Hidden smUp>
                <Chip
                  variant="contained"
                  color="default"
                  size="small"
                  label="DRAFT"
                  sx={{ fontWeight: 'bold' }}
                />
              </Hidden>
            )}
          </Box>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            {entry?.name ? (
              <Stack direction="row" alignItems="center" spacing={2}>
                <Chip
                  variant="contained"
                  color="primary"
                  icon={entry?.icon ? getIconComponent(entry?.icon) : null}
                  label={entry?.name}
                />
                {record.draft && (
                  <Hidden smDown>
                    <Chip
                      variant="contained"
                      color="default"
                      size="small"
                      label="DRAFT"
                      sx={{ fontWeight: 'bold' }}
                    />
                  </Hidden>
                )}
              </Stack>
            ) : (
              <Skeleton width={100} height={40} />
            )}
            <Stack direction="row" alignItems="center" spacing={1}>
              <Hidden smUp implementation="css">
                {enteredAt && (
                  <Typography variant="body2">
                    {DateTime.fromISO(enteredAt)
                      .toLocal()
                      .toFormat('ccc, dd LLL')}
                  </Typography>
                )}
              </Hidden>
              <Hidden smDown implementation="css">
                {enteredAt && (
                  <Typography variant="body2">
                    {DateTime.fromISO(enteredAt)
                      .toLocal()
                      .toFormat('ccc, dd LLL yyyy - HH:mm')}
                  </Typography>
                )}
              </Hidden>
              <Tooltip placement="top" title="Change date">
                <IconButton
                  ref={buttonRef}
                  size="small"
                  onClick={() => setDatePickerOpen(true)}
                >
                  <EditCalendar fontSize="small" />
                </IconButton>
              </Tooltip>
              <Popover
                open={datePickerOpen}
                anchorEl={buttonRef.current}
                onClose={() => setDatePickerOpen(false)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
              >
                <ThemeProvider theme={theme}>
                  <LocalizationProvider
                    dateAdapter={AdapterLuxon}
                    adapterLocale={'au'}
                  >
                    <StaticDatePicker
                      disableFuture
                      value={DateTime.fromISO(enteredAt)}
                      componentsProps={{ actionBar: { actions: [] } }}
                      openTo="day"
                      onChange={newValue => {
                        updateEnteredAt(newValue);
                      }}
                      defaultValue={DateTime.fromISO(enteredAt)}
                      renderInput={params => <div />}
                    />
                  </LocalizationProvider>
                </ThemeProvider>
              </Popover>
            </Stack>
          </Stack>
          {entrySections
            .filter(entrySection => {
              if (!entrySection.complete) {
                return true;
              }
              const componentsInThisSection = entrySection.components.items;
              return componentsInThisSection.some(component => {
                const currentInput = userInputs.find(
                  input => input.componentID === component.id
                );
                return hasUserData(currentInput);
              });
            })
            .map(entrySection => (
              <EntrySection
                entry={entry}
                key={entrySection.id}
                entrySection={entrySection}
                userInputs={userInputs}
              />
            ))}

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={2}
          >
            <LoadingButton
              loading={deleting}
              onClick={handleDelete}
              startIcon={<Delete />}
            >
              Delete
            </LoadingButton>
            <Box display="flex" alignItems="center">
              <LoadingButton
                loading={saving}
                onClick={handleSaveAndExit}
                variant="contained"
                size="small"
              >
                Save
              </LoadingButton>
            </Box>
          </Stack>
          <Box py={4}>
            <Divider sx={{ opacity: 0.3 }} />
          </Box>
          <Stack direction="row" alignItems="center" spacing={4}>
            <Stack direction="column" alignItems="flex-start" spacing={1}>
              <Typography variant="caption">Created At:</Typography>
              <Hidden smUp implementation="css">
                {record?.createdAt && (
                  <Typography variant="body2">
                    {DateTime.fromISO(record?.createdAt)
                      .toLocal()
                      .toFormat('ccc, dd LLL - HH:mm')}
                  </Typography>
                )}
              </Hidden>
              <Hidden smDown implementation="css">
                {record?.createdAt && (
                  <Typography variant="body2">
                    {DateTime.fromISO(record?.createdAt)
                      .toLocal()
                      .toFormat('ccc, dd LLL yyyy - HH:mm')}
                  </Typography>
                )}
              </Hidden>
            </Stack>
          </Stack>
        </Container>
      </Grid>
    </Grid>
  );
}

async function fetchEntryAndEntrySectionsAndUserInputsByRecordID(recordID) {
  try {
    const recordResponse = await API.graphql(
      graphqlOperation(getRecord, { id: recordID })
    );
    const record = recordResponse.data?.getRecord;

    if (!record) return;

    const entryPromise = API.graphql(
      graphqlOperation(getEntry, { id: record.entryID })
    );
    const entrySectionsPromise = API.graphql(
      graphqlOperation(entrySectionsByEntryIDByOrder, {
        entryID: record.entryID
      })
    );
    const userInputsPromise = API.graphql(
      graphqlOperation(userInputsByRecordComponentType, { recordID })
    );

    const [entryResponse, entrySectionsResponse, userInputsResponse] =
      await Promise.all([
        entryPromise,
        entrySectionsPromise,
        userInputsPromise
      ]);

    const entry = entryResponse.data?.getEntry;
    const entrySections =
      entrySectionsResponse.data?.entrySectionsByEntryIDByOrder.items;

    const userInputs =
      userInputsResponse.data?.userInputsByRecordComponentType.items;

    return { entry, entrySections, userInputs, record };
  } catch (err) {
    console.error(err);
  }
}

import { useEffect, useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import {
  DndContext,
  MouseSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import {
  restrictToVerticalAxis,
  restrictToWindowEdges
} from '@dnd-kit/modifiers';
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import { Box } from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import { ORDER_NORMALIZE_FACTOR } from 'constants';
import {
  createInsight,
  createInsightsSection,
  deleteInsightsSection,
  updateInsightsSection
} from 'graphql/mutations';
import { insightsSectionByClientIDandEntryIDandOrder } from 'graphql/queries';
import mixpanel from 'mixpanel-browser';
import Report from 'routes/clinician/client/report';

import { DividerButton } from 'components/atoms';

import InsightsSection from './InsightsSection';

function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function EntryPanel({ value, index, entryID, state, to, from }) {
  const { user } = useAuthenticator(context => [context.user]);

  const clientID =
    state.client?.id || user.attributes['custom:clientID'] || user.username;
  const clientSub = state?.client?.sub || user.attributes.sub;

  const sensors = useSensors(useSensor(MouseSensor), useSensor(PointerSensor));

  const [insightsSections, setInsightsSections] = useState([]);
  const [isCreatingInsightsSection, setIsCreatingInsightsSection] =
    useState(false);

  useEffect(() => {
    const fetchInsightsSections = async () => {
      try {
        const result = await API.graphql(
          graphqlOperation(insightsSectionByClientIDandEntryIDandOrder, {
            clientIDOrder: {
              beginsWith: {
                clientID: clientID
              }
            },
            entryID,
            sortDirection: 'ASC'
          })
        );

        let items =
          result.data?.insightsSectionByClientIDandEntryIDandOrder.items || [];

        items.sort((a, b) => a.order - b.order);
        setInsightsSections(items);
      } catch (err) {
        console.log('[insightsSectionByClientIDandEntryIDandOrder error]', err);
      }
    };
    fetchInsightsSections();
  }, [entryID, clientID]);

  const handleCreateInsightsSection = async () => {
    if (!clientID) return;

    try {
      setIsCreatingInsightsSection(true);
      const {
        data: { createInsightsSection: createdInsightsSection }
      } = await API.graphql(
        graphqlOperation(createInsightsSection, {
          input: {
            clientID: clientID,
            entryID,
            order: (insightsSections.length + 1) * ORDER_NORMALIZE_FACTOR,
            owner: `${clientSub}::${clientID}`
          }
        })
      );

      mixpanel.track('create_nsights_section');
      window.dataLayer.push({
        event: 'create_insights_section'
      });

      const {
        data: { createInsight: createdInsight }
      } = await API.graphql(
        graphqlOperation(createInsight, {
          input: {
            entryID,
            insightsSectionID: createdInsightsSection.id,
            insightsSectionInsightsId: createdInsightsSection.id,
            order: 10,
            owner: `${clientSub}::${clientID}`,
            clientID
          }
        })
      );

      mixpanel.track('create_insight');

      window.dataLayer.push({
        event: 'create_insight'
      });

      createdInsightsSection.insights = {
        items: [createdInsight]
      };

      setInsightsSections(prevInsightsSections =>
        prevInsightsSections.concat(createdInsightsSection)
      );
      setIsCreatingInsightsSection(false);
    } catch (err) {
      console.log('[createInsightsSection error]', err);
    }
  };

  const handleDeleteInsightsSection = async insightsSection => {
    try {
      await API.graphql(
        graphqlOperation(deleteInsightsSection, {
          input: { id: insightsSection.id, _version: insightsSection._version }
        })
      );
      mixpanel.track('deleteInsightsSection');
      window.dataLayer.push({
        event: 'delete_insight_section'
      });
      setInsightsSections(prevInsightsSections =>
        prevInsightsSections.filter(
          prevInsightsSection => prevInsightsSection.id !== insightsSection.id
        )
      );
    } catch (err) {
      console.log('[deleteInsightsSection error]', err);
    }
  };

  const handleDragInsightsSection = async ({ active, over }) => {
    if (over && clientID) {
      const overIndex = insightsSections.findIndex(
        insightsSection => insightsSection.id === over.id
      );
      const activeIndex = insightsSections.findIndex(
        insightsSection => insightsSection.id === active.id
      );
      if (activeIndex !== overIndex) {
        const newOrder =
          activeIndex < overIndex
            ? overIndex === insightsSections.length - 1
              ? insightsSections[overIndex].order + 1 * ORDER_NORMALIZE_FACTOR
              : (insightsSections[overIndex].order +
                  insightsSections[overIndex + 1].order) /
                2
            : (insightsSections[overIndex].order +
                (insightsSections[overIndex - 1]?.order ?? 0)) /
              2;
        const droppedInsightsSection = {
          ...insightsSections[activeIndex],
          order: newOrder
        };

        setInsightsSections(prevInsightsSections =>
          arrayMove(prevInsightsSections, activeIndex, overIndex).map(
            insightsSection =>
              insightsSection.id === droppedInsightsSection.id
                ? droppedInsightsSection
                : insightsSection
          )
        );
        const { data } = await API.graphql(
          graphqlOperation(updateInsightsSection, {
            input: {
              id: droppedInsightsSection.id,
              clientID: clientID,
              order: droppedInsightsSection.order,
              _version: droppedInsightsSection._version
            }
          })
        );
        const updatedInsightSection = data.updateInsightsSection;
        setInsightsSections(prevInsightsSections =>
          arrayMove(prevInsightsSections, activeIndex, overIndex).map(
            insightsSection =>
              insightsSection.id === updatedInsightSection.id
                ? updatedInsightSection
                : insightsSection
          )
        );
      }
    }
  };

  return (
    <TabPanel value={value} index={index}>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
        onDragEnd={handleDragInsightsSection}
      >
        {insightsSections && (
          <SortableContext
            items={insightsSections}
            strategy={verticalListSortingStrategy}
          >
            {insightsSections.map(insightsSection => (
              <InsightsSection
                insightsSectionID={insightsSection.id}
                key={insightsSection.id}
                disableDrag={insightsSections.length === 1}
                entryID={entryID}
                insightsSection={insightsSection}
                onDelete={handleDeleteInsightsSection}
                to={to}
                from={from}
                index={index}
              />
            ))}
          </SortableContext>
        )}
      </DndContext>
      {state.edit && (
        <DividerButton
          loading={isCreatingInsightsSection}
          onClick={handleCreateInsightsSection}
        />
      )}
    </TabPanel>
  );
}

export function ChartEntryPanel({ index, value }) {
  return (
    <TabPanel value={value} index={index}>
      <Report />
    </TabPanel>
  );
}

export default EntryPanel;

import { useEffect, useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { Avatar, Skeleton } from '@mui/material';
import { Storage } from 'aws-amplify';
import { useAppContext } from 'context';
import { getImageFromIndexedDB, saveImageToIndexedDB } from 'indexedDB';

import { getInitials } from 'utils/getInitials';

export default function ProfileAvatar({ profile, src, size, menu }) {
  const { state } = useAppContext();
  const { user } = useAuthenticator(context => [context.user]);
  const isClinician = user.attributes['custom:type'] === 'clinician';
  const imageID = profile?.id;
  const [image, setImage] = useState(src);

  async function getImageBlobFromS3(imageID) {
    try {
      const key = `profilepictures/${imageID}.jpg`;
      const blob = await Storage.get(key, { download: true });
      return blob.Body;
    } catch (error) {
      console.error('Error fetching image from S3:', error);
      return null;
    }
  }

  useEffect(() => {
    let imageUrl;

    async function fetchImage() {
      if (imageID) {
        const blob = await getImageFromIndexedDB(imageID);
        if (blob) {
          imageUrl = URL.createObjectURL(blob);
          setImage(imageUrl);
        } else {
          const blobFromS3 = await getImageBlobFromS3(imageID); // Using new function
          await saveImageToIndexedDB(imageID, blobFromS3);
          imageUrl = URL.createObjectURL(blobFromS3);
          setImage(imageUrl);
        }
      }
    }
    if (profile?.picture) {
      fetchImage();
    }
    return () => {
      if (imageUrl) {
        URL.revokeObjectURL(imageUrl);
      }
    };
  }, [profile, imageID, state.avatarRefreshKey]);

  if (!profile)
    return (
      <Skeleton
        sx={{
          bgcolor: isClinician
            ? menu
              ? 'secondary.light'
              : 'primary.light'
            : 'primary.light'
        }}
        variant="circular"
        height={size || 40}
        width={size || 40}
      />
    );

  return profile?.picture ? (
    src || image ? (
      <Avatar
        sx={{
          height: size || 40,
          width: size || 40
        }}
        src={src || image || null}
      >
        <Skeleton variant="circular" />
      </Avatar>
    ) : (
      <Avatar
        sx={{
          bgcolor: isClinician
            ? menu
              ? 'secondary.light'
              : 'primary.light'
            : 'primary.light',
          height: size || 40,
          width: size || 40
        }}
      >
        <Skeleton variant="circular" />
      </Avatar>
    )
  ) : (
    <Avatar
      sx={{
        color: isClinician ? (menu ? '#272941' : 'white') : 'primary.darkest',
        bgcolor: isClinician
          ? menu
            ? 'secondary.light'
            : 'primary.light'
          : 'primary.light',
        height: size || 40,
        width: size || 40
      }}
      src={image || null}
    >
      {profile.clientFirstName
        ? getInitials(profile.clientFirstName, profile.clientLastName)
        : getInitials(
            profile.firstName,
            size > 30 || !size ? profile.lastName : null
          )}
    </Avatar>
  );
}

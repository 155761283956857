/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const programChange = /* GraphQL */ `
  query ProgramChange($clientID: ID, $program: String) {
    programChange(clientID: $clientID, program: $program) {
      statusCode
      body
      __typename
    }
  }
`;
export const programSummary = /* GraphQL */ `
  query ProgramSummary(
    $clientID: ID
    $action: String
    $timeZone: String
    $startDate: String
    $endDate: String
  ) {
    programSummary(
      clientID: $clientID
      action: $action
      timeZone: $timeZone
      startDate: $startDate
      endDate: $endDate
    ) {
      statusCode
      body
      __typename
    }
  }
`;
export const manageInvitations = /* GraphQL */ `
  query ManageInvitations(
    $invitationID: ID
    $action: String
    $clientID: ID
    $invitation_action: String
    $source: String
  ) {
    manageInvitations(
      invitationID: $invitationID
      action: $action
      clientID: $clientID
      invitation_action: $invitation_action
      source: $source
    ) {
      statusCode
      body
      __typename
    }
  }
`;
export const supportRequest = /* GraphQL */ `
  query SupportRequest(
    $id: ID
    $email: String
    $firstName: String
    $lastName: String
    $language: String
    $userAgent: String
    $browser: String
    $browserInfo: String
    $os: String
    $osVersion: String
    $device: String
    $deviceType: String
    $engine: String
    $engineVersion: String
    $cpu: String
    $mediaDevicesInfo: String
    $supportRequestText: String
    $canContact: String
    $permissions_microphone: String
    $permissions_camera: String
    $permissions_geolocation: String
    $videoInputCount: String
    $audioInputCount: String
    $audioOutputCount: String
  ) {
    supportRequest(
      id: $id
      email: $email
      firstName: $firstName
      lastName: $lastName
      language: $language
      userAgent: $userAgent
      browser: $browser
      browserInfo: $browserInfo
      os: $os
      osVersion: $osVersion
      device: $device
      deviceType: $deviceType
      engine: $engine
      engineVersion: $engineVersion
      cpu: $cpu
      mediaDevicesInfo: $mediaDevicesInfo
      supportRequestText: $supportRequestText
      canContact: $canContact
      permissions_microphone: $permissions_microphone
      permissions_camera: $permissions_camera
      permissions_geolocation: $permissions_geolocation
      videoInputCount: $videoInputCount
      audioInputCount: $audioInputCount
      audioOutputCount: $audioOutputCount
    ) {
      statusCode
      body
      __typename
    }
  }
`;
export const stageChange = /* GraphQL */ `
  query StageChange($clientID: ID, $newStage: Int, $program: String) {
    stageChange(clientID: $clientID, newStage: $newStage, program: $program) {
      statusCode
      body
      __typename
    }
  }
`;
export const inviteParticipants = /* GraphQL */ `
  query InviteParticipants(
    $participants: String
    $clientID: ID
    $createdBy: ID
    $source: String
    $invitedByFirstName: String
    $invitedByLastName: String
    $invitedByEmail: String
    $action: String
  ) {
    inviteParticipants(
      participants: $participants
      clientID: $clientID
      createdBy: $createdBy
      source: $source
      invitedByFirstName: $invitedByFirstName
      invitedByLastName: $invitedByLastName
      invitedByEmail: $invitedByEmail
      action: $action
    ) {
      statusCode
      body
      __typename
    }
  }
`;
export const updateRecordDate = /* GraphQL */ `
  query UpdateRecordDate($recordID: ID, $newDate: String) {
    updateRecordDate(recordID: $recordID, newDate: $newDate) {
      statusCode
      body
      __typename
    }
  }
`;
export const cliniciansClinicConnection = /* GraphQL */ `
  query CliniciansClinicConnection(
    $adminFirstName: String
    $adminLastName: String
    $adminEmail: String
    $invitationID: ID
    $firstName: String
    $lastName: String
    $email: String
    $clinicID: ID
    $locationID: ID
    $action: String
    $role: String
    $name: String
    $inviteeID: ID
    $inviteeEmail: String
    $createdBy: ID
  ) {
    cliniciansClinicConnection(
      adminFirstName: $adminFirstName
      adminLastName: $adminLastName
      adminEmail: $adminEmail
      invitationID: $invitationID
      firstName: $firstName
      lastName: $lastName
      email: $email
      clinicID: $clinicID
      locationID: $locationID
      action: $action
      role: $role
      name: $name
      inviteeID: $inviteeID
      inviteeEmail: $inviteeEmail
      createdBy: $createdBy
    ) {
      statusCode
      body
      __typename
    }
  }
`;
export const clientsClinicConnection = /* GraphQL */ `
  query ClientsClinicConnection(
    $clinicianID: ID
    $locationID: ID
    $clinicID: ID
    $action: String
  ) {
    clientsClinicConnection(
      clinicianID: $clinicianID
      locationID: $locationID
      clinicID: $clinicID
      action: $action
    ) {
      statusCode
      body
      __typename
    }
  }
`;
export const createClinician = /* GraphQL */ `
  query CreateClinician(
    $clinicianEmail: String
    $clinicianFirstName: String
    $clinicianLastName: String
    $clientFirstName: String
    $clientLastName: String
    $clientEmail: String
    $clientID: ID
    $createdBy: ID
    $createdByFirstName: String
    $createdByLastName: String
    $source: String
    $role: String!
    $locationID: ID
    $clinicID: ID
  ) {
    createClinician(
      clinicianEmail: $clinicianEmail
      clinicianFirstName: $clinicianFirstName
      clinicianLastName: $clinicianLastName
      clientFirstName: $clientFirstName
      clientLastName: $clientLastName
      clientEmail: $clientEmail
      clientID: $clientID
      createdBy: $createdBy
      createdByFirstName: $createdByFirstName
      createdByLastName: $createdByLastName
      source: $source
      role: $role
      locationID: $locationID
      clinicID: $clinicID
    ) {
      statusCode
      body
      __typename
    }
  }
`;
export const updateUserGroups = /* GraphQL */ `
  query UpdateUserGroups(
    $userID: ID
    $action: String
    $role: String
    $suspend: Boolean
  ) {
    updateUserGroups(
      userID: $userID
      action: $action
      role: $role
      suspend: $suspend
    ) {
      statusCode
      body
      __typename
    }
  }
`;
export const insightsBubbleSummary = /* GraphQL */ `
  query InsightsBubbleSummary($sectionID: ID, $timeZone: String) {
    insightsBubbleSummary(sectionID: $sectionID, timeZone: $timeZone) {
      day
      index
      value
      __typename
    }
  }
`;
export const importClient = /* GraphQL */ `
  query ImportClient(
    $clientID: ID
    $clientSub: String
    $parsedData: String
    $selectedEntry: String
    $selectedComponents: String
    $timezone: String
  ) {
    importClient(
      clientID: $clientID
      clientSub: $clientSub
      parsedData: $parsedData
      selectedEntry: $selectedEntry
      selectedComponents: $selectedComponents
      timezone: $timezone
    ) {
      statusCode
      body
      __typename
    }
  }
`;
export const updateEntryCount = /* GraphQL */ `
  query UpdateEntryCount($clientID: ID) {
    updateEntryCount(clientID: $clientID)
  }
`;
export const getEmailVerificationStatus = /* GraphQL */ `
  query GetEmailVerificationStatus($userID: ID) {
    getEmailVerificationStatus(userID: $userID)
  }
`;
export const resendInvitation = /* GraphQL */ `
  query ResendInvitation(
    $firstName: String
    $lastName: String
    $userID: ID
    $email: String
  ) {
    resendInvitation(
      firstName: $firstName
      lastName: $lastName
      userID: $userID
      email: $email
    )
  }
`;
export const getNotesData = /* GraphQL */ `
  query GetNotesData(
    $type: String
    $timeZone: String
    $dataRecords: String
    $aggregateBy: String
    $dataType: String
    $dateFrom: String
    $dateTo: String
    $clientID: ID
    $scatter: Boolean
    $showNotes: Boolean
  ) {
    getNotesData(
      type: $type
      timeZone: $timeZone
      dataRecords: $dataRecords
      aggregateBy: $aggregateBy
      dataType: $dataType
      dateFrom: $dateFrom
      dateTo: $dateTo
      clientID: $clientID
      scatter: $scatter
      showNotes: $showNotes
    )
  }
`;
export const updateUserAttributes = /* GraphQL */ `
  query UpdateUserAttributes(
    $userID: ID
    $attributeKey: String
    $attributeValue: String
    $action: String
  ) {
    updateUserAttributes(
      userID: $userID
      attributeKey: $attributeKey
      attributeValue: $attributeValue
      action: $action
    )
  }
`;
export const upgradeProgram = /* GraphQL */ `
  query UpgradeProgram(
    $clientEmail: String
    $clientFirstName: String
    $clientLastName: String
    $clientID: ID
    $clientSub: String
    $oldProgram: String
    $newProgram: String
    $clinicianID: ID
    $clinicianFirstName: String
    $clinicianLastName: String
    $clinicianEmail: String
    $isClinician: Boolean
  ) {
    upgradeProgram(
      clientEmail: $clientEmail
      clientFirstName: $clientFirstName
      clientLastName: $clientLastName
      clientID: $clientID
      clientSub: $clientSub
      oldProgram: $oldProgram
      newProgram: $newProgram
      clinicianID: $clinicianID
      clinicianFirstName: $clinicianFirstName
      clinicianLastName: $clinicianLastName
      clinicianEmail: $clinicianEmail
      isClinician: $isClinician
    )
  }
`;
export const exportClientData = /* GraphQL */ `
  query ExportClientData(
    $clientID: ID
    $clientFirstName: String
    $clientLastName: String
    $clientEmail: String
    $clinicianID: ID
    $clinicianEmail: String
    $clinicianFirstName: String
    $clinicianLastName: String
  ) {
    exportClientData(
      clientID: $clientID
      clientFirstName: $clientFirstName
      clientLastName: $clientLastName
      clientEmail: $clientEmail
      clinicianID: $clinicianID
      clinicianEmail: $clinicianEmail
      clinicianFirstName: $clinicianFirstName
      clinicianLastName: $clinicianLastName
    )
  }
`;
export const enrollInBeta = /* GraphQL */ `
  query EnrollInBeta(
    $betaID: ID
    $clinicianID: ID
    $clinicianEmail: String
    $enrolmentStatus: Boolean
  ) {
    enrollInBeta(
      betaID: $betaID
      clinicianID: $clinicianID
      clinicianEmail: $clinicianEmail
      enrolmentStatus: $enrolmentStatus
    )
  }
`;
export const createDefaultEntry = /* GraphQL */ `
  query CreateDefaultEntry(
    $program: String
    $clientID: ID
    $entryID: ID
    $clientSub: String
  ) {
    createDefaultEntry(
      program: $program
      clientID: $clientID
      entryID: $entryID
      clientSub: $clientSub
    ) {
      statusCode
      body
      __typename
    }
  }
`;
export const createUserAfterSignUp = /* GraphQL */ `
  query CreateUserAfterSignUp(
    $type: String
    $ID: ID
    $sub: String
    $firstName: String
    $lastName: String
    $email: String
  ) {
    createUserAfterSignUp(
      type: $type
      ID: $ID
      sub: $sub
      firstName: $firstName
      lastName: $lastName
      email: $email
    ) {
      statusCode
      body
      __typename
    }
  }
`;
export const deviceInterface = /* GraphQL */ `
  query DeviceInterface($action: String, $username: String) {
    deviceInterface(action: $action, username: $username)
  }
`;
export const updatePicture = /* GraphQL */ `
  query UpdatePicture($id: ID, $type: String, $picture: Boolean) {
    updatePicture(id: $id, type: $type, picture: $picture) {
      statusCode
      body
      __typename
    }
  }
`;
export const getCardPreview = /* GraphQL */ `
  query GetCardPreview($componentID: ID, $timeZone: String, $days: Int) {
    getCardPreview(componentID: $componentID, timeZone: $timeZone, days: $days)
  }
`;
export const createClient = /* GraphQL */ `
  query CreateClient(
    $stage: Int
    $age: Int
    $program: String
    $forValue: String
    $clientFirstName: String
    $clientLastName: String
    $firstName: String
    $lastName: String
    $email: String
    $clinicianSub: String
    $clinicianID: ID
    $clinicianFirstName: String
    $clinicianLastName: String
    $clinicID: ID
    $locationID: ID
    $accountName: String
    $invitedByFirstName: String
    $invitedByLastName: String
    $invitedByEmail: String
    $participantClientID: ID
    $role: String
    $audioSampleEntryID: ID
  ) {
    createClient(
      stage: $stage
      age: $age
      program: $program
      forValue: $forValue
      clientFirstName: $clientFirstName
      clientLastName: $clientLastName
      firstName: $firstName
      lastName: $lastName
      email: $email
      clinicianSub: $clinicianSub
      clinicianID: $clinicianID
      clinicianFirstName: $clinicianFirstName
      clinicianLastName: $clinicianLastName
      clinicID: $clinicID
      locationID: $locationID
      accountName: $accountName
      invitedByFirstName: $invitedByFirstName
      invitedByLastName: $invitedByLastName
      invitedByEmail: $invitedByEmail
      participantClientID: $participantClientID
      role: $role
      audioSampleEntryID: $audioSampleEntryID
    ) {
      statusCode
      body
      __typename
    }
  }
`;
export const actionInvitationLink = /* GraphQL */ `
  query ActionInvitationLink(
    $userFirstName: String
    $userLastName: String
    $locationID: ID
    $clinicID: ID
    $role: String
    $name: String
    $source: String
    $action: String
    $clientID: ID
    $clinicianID: ID
    $clinicianSub: String
    $clinicianFirstName: String
    $clinicianLastName: String
    $clinicianEmail: String
    $clientFirstName: String
    $clientLastName: String
    $clientEmail: String
    $invitationID: ID
  ) {
    actionInvitationLink(
      userFirstName: $userFirstName
      userLastName: $userLastName
      locationID: $locationID
      clinicID: $clinicID
      role: $role
      name: $name
      source: $source
      action: $action
      clientID: $clientID
      clinicianID: $clinicianID
      clinicianSub: $clinicianSub
      clinicianFirstName: $clinicianFirstName
      clinicianLastName: $clinicianLastName
      clinicianEmail: $clinicianEmail
      clientFirstName: $clientFirstName
      clientLastName: $clientLastName
      clientEmail: $clientEmail
      invitationID: $invitationID
    ) {
      statusCode
      body
      __typename
    }
  }
`;
export const linkExistingClientOrClinician = /* GraphQL */ `
  query LinkExistingClientOrClinician(
    $source: String
    $clinicianID: ID
    $clinicianFirstName: String
    $clinicianLastName: String
    $clinicianEmail: String
    $clientID: ID
    $clientFirstName: String
    $clientLastName: String
    $clientEmail: String
  ) {
    linkExistingClientOrClinician(
      source: $source
      clinicianID: $clinicianID
      clinicianFirstName: $clinicianFirstName
      clinicianLastName: $clinicianLastName
      clinicianEmail: $clinicianEmail
      clientID: $clientID
      clientFirstName: $clientFirstName
      clientLastName: $clientLastName
      clientEmail: $clientEmail
    ) {
      statusCode
      body
      __typename
    }
  }
`;
export const verifyEmail = /* GraphQL */ `
  query VerifyEmail($userID: ID) {
    verifyEmail(userID: $userID)
  }
`;
export const createDefaultInsight = /* GraphQL */ `
  query CreateDefaultInsight($clientID: ID, $clientSub: String, $entryID: ID) {
    createDefaultInsight(
      clientID: $clientID
      clientSub: $clientSub
      entryID: $entryID
    )
  }
`;
export const createFromTemplate = /* GraphQL */ `
  query CreateFromTemplate(
    $clientID: ID
    $clientSub: String
    $program: String
    $createdByClinician: Boolean
    $stage: Int
    $updateExisting: Boolean
    $skipInsights: Boolean
  ) {
    createFromTemplate(
      clientID: $clientID
      clientSub: $clientSub
      program: $program
      createdByClinician: $createdByClinician
      stage: $stage
      updateExisting: $updateExisting
      skipInsights: $skipInsights
    ) {
      statusCode
      body
      __typename
    }
  }
`;
export const getInsightsPie = /* GraphQL */ `
  query GetInsightsPie(
    $clientID: ID
    $dateTo: String
    $dateFrom: String
    $dataType: String
    $dataRecords: String
    $aggregateBy: String
    $timeZone: String
    $scatter: Boolean
  ) {
    getInsightsPie(
      clientID: $clientID
      dateTo: $dateTo
      dateFrom: $dateFrom
      dataType: $dataType
      dataRecords: $dataRecords
      aggregateBy: $aggregateBy
      timeZone: $timeZone
      scatter: $scatter
    )
  }
`;
export const getInsightsLine = /* GraphQL */ `
  query GetInsightsLine(
    $type: String
    $timeZone: String
    $dataRecords: String
    $aggregateBy: String
    $dataType: String
    $dateFrom: String
    $dateTo: String
    $clientID: ID
    $scatter: Boolean
    $showNotes: Boolean
  ) {
    getInsightsLine(
      type: $type
      timeZone: $timeZone
      dataRecords: $dataRecords
      aggregateBy: $aggregateBy
      dataType: $dataType
      dateFrom: $dateFrom
      dateTo: $dateTo
      clientID: $clientID
      scatter: $scatter
      showNotes: $showNotes
    )
  }
`;
export const getInsightsBubble = /* GraphQL */ `
  query GetInsightsBubble(
    $timeZone: String
    $dataRecords: String
    $aggregateBy: String
    $dataType: String
    $dateFrom: String
    $dateTo: String
    $clientID: ID
  ) {
    getInsightsBubble(
      timeZone: $timeZone
      dataRecords: $dataRecords
      aggregateBy: $aggregateBy
      dataType: $dataType
      dateFrom: $dateFrom
      dateTo: $dateTo
      clientID: $clientID
    ) {
      day
      index
      value
      __typename
    }
  }
`;
export const cleanUpRecordsUserInputs = /* GraphQL */ `
  query CleanUpRecordsUserInputs($recordID: ID) {
    cleanUpRecordsUserInputs(recordID: $recordID) {
      statusCode
      body
      __typename
    }
  }
`;
export const getResource = /* GraphQL */ `
  query GetResource($id: ID!) {
    getResource(id: $id) {
      id
      hasImage
      imageType
      createdAt
      updatedAt
      title
      subTitle
      sourceName
      sourceLink
      category
      subCategory
      program
      condition
      type
      resourceContent {
        items {
          id
          createdAt
          updatedAt
          content
          contentType
          fileType
          resourceID
          order
          _version
          _deleted
          _lastChangedAt
          resourceResourceContentId
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      previewText
      createdBy
      accessType
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listResources = /* GraphQL */ `
  query ListResources(
    $filter: ModelResourceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listResources(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        hasImage
        imageType
        createdAt
        updatedAt
        title
        subTitle
        sourceName
        sourceLink
        category
        subCategory
        program
        condition
        type
        resourceContent {
          nextToken
          startedAt
          __typename
        }
        previewText
        createdBy
        accessType
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncResources = /* GraphQL */ `
  query SyncResources(
    $filter: ModelResourceFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncResources(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        hasImage
        imageType
        createdAt
        updatedAt
        title
        subTitle
        sourceName
        sourceLink
        category
        subCategory
        program
        condition
        type
        resourceContent {
          nextToken
          startedAt
          __typename
        }
        previewText
        createdBy
        accessType
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const resourceByCreatedAt = /* GraphQL */ `
  query ResourceByCreatedAt(
    $createdAt: AWSDateTime!
    $accessType: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelResourceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    resourceByCreatedAt(
      createdAt: $createdAt
      accessType: $accessType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        hasImage
        imageType
        createdAt
        updatedAt
        title
        subTitle
        sourceName
        sourceLink
        category
        subCategory
        program
        condition
        type
        resourceContent {
          nextToken
          startedAt
          __typename
        }
        previewText
        createdBy
        accessType
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const resourceByTitle = /* GraphQL */ `
  query ResourceByTitle(
    $title: String!
    $accessType: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelResourceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    resourceByTitle(
      title: $title
      accessType: $accessType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        hasImage
        imageType
        createdAt
        updatedAt
        title
        subTitle
        sourceName
        sourceLink
        category
        subCategory
        program
        condition
        type
        resourceContent {
          nextToken
          startedAt
          __typename
        }
        previewText
        createdBy
        accessType
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const resourceBySourceName = /* GraphQL */ `
  query ResourceBySourceName(
    $sourceName: String!
    $accessType: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelResourceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    resourceBySourceName(
      sourceName: $sourceName
      accessType: $accessType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        hasImage
        imageType
        createdAt
        updatedAt
        title
        subTitle
        sourceName
        sourceLink
        category
        subCategory
        program
        condition
        type
        resourceContent {
          nextToken
          startedAt
          __typename
        }
        previewText
        createdBy
        accessType
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const resourceByCategory = /* GraphQL */ `
  query ResourceByCategory(
    $category: String!
    $accessType: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelResourceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    resourceByCategory(
      category: $category
      accessType: $accessType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        hasImage
        imageType
        createdAt
        updatedAt
        title
        subTitle
        sourceName
        sourceLink
        category
        subCategory
        program
        condition
        type
        resourceContent {
          nextToken
          startedAt
          __typename
        }
        previewText
        createdBy
        accessType
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const resourceByProgram = /* GraphQL */ `
  query ResourceByProgram(
    $program: String!
    $accessType: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelResourceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    resourceByProgram(
      program: $program
      accessType: $accessType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        hasImage
        imageType
        createdAt
        updatedAt
        title
        subTitle
        sourceName
        sourceLink
        category
        subCategory
        program
        condition
        type
        resourceContent {
          nextToken
          startedAt
          __typename
        }
        previewText
        createdBy
        accessType
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const resourceByCondition = /* GraphQL */ `
  query ResourceByCondition(
    $condition: String!
    $accessType: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelResourceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    resourceByCondition(
      condition: $condition
      accessType: $accessType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        hasImage
        imageType
        createdAt
        updatedAt
        title
        subTitle
        sourceName
        sourceLink
        category
        subCategory
        program
        condition
        type
        resourceContent {
          nextToken
          startedAt
          __typename
        }
        previewText
        createdBy
        accessType
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const resourceByType = /* GraphQL */ `
  query ResourceByType(
    $type: String!
    $accessType: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelResourceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    resourceByType(
      type: $type
      accessType: $accessType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        hasImage
        imageType
        createdAt
        updatedAt
        title
        subTitle
        sourceName
        sourceLink
        category
        subCategory
        program
        condition
        type
        resourceContent {
          nextToken
          startedAt
          __typename
        }
        previewText
        createdBy
        accessType
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const resourcesByCreatedBy = /* GraphQL */ `
  query ResourcesByCreatedBy(
    $createdBy: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelResourceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    resourcesByCreatedBy(
      createdBy: $createdBy
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        hasImage
        imageType
        createdAt
        updatedAt
        title
        subTitle
        sourceName
        sourceLink
        category
        subCategory
        program
        condition
        type
        resourceContent {
          nextToken
          startedAt
          __typename
        }
        previewText
        createdBy
        accessType
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getResourceContent = /* GraphQL */ `
  query GetResourceContent($id: ID!) {
    getResourceContent(id: $id) {
      id
      createdAt
      updatedAt
      content
      contentType
      fileType
      resourceID
      resource {
        id
        hasImage
        imageType
        createdAt
        updatedAt
        title
        subTitle
        sourceName
        sourceLink
        category
        subCategory
        program
        condition
        type
        resourceContent {
          nextToken
          startedAt
          __typename
        }
        previewText
        createdBy
        accessType
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      order
      _version
      _deleted
      _lastChangedAt
      resourceResourceContentId
      owner
      __typename
    }
  }
`;
export const listResourceContents = /* GraphQL */ `
  query ListResourceContents(
    $filter: ModelResourceContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listResourceContents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        content
        contentType
        fileType
        resourceID
        resource {
          id
          hasImage
          imageType
          createdAt
          updatedAt
          title
          subTitle
          sourceName
          sourceLink
          category
          subCategory
          program
          condition
          type
          previewText
          createdBy
          accessType
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        order
        _version
        _deleted
        _lastChangedAt
        resourceResourceContentId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncResourceContents = /* GraphQL */ `
  query SyncResourceContents(
    $filter: ModelResourceContentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncResourceContents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        createdAt
        updatedAt
        content
        contentType
        fileType
        resourceID
        resource {
          id
          hasImage
          imageType
          createdAt
          updatedAt
          title
          subTitle
          sourceName
          sourceLink
          category
          subCategory
          program
          condition
          type
          previewText
          createdBy
          accessType
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        order
        _version
        _deleted
        _lastChangedAt
        resourceResourceContentId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const resourceContentsByResourceID = /* GraphQL */ `
  query ResourceContentsByResourceID(
    $resourceID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelResourceContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    resourceContentsByResourceID(
      resourceID: $resourceID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        content
        contentType
        fileType
        resourceID
        resource {
          id
          hasImage
          imageType
          createdAt
          updatedAt
          title
          subTitle
          sourceName
          sourceLink
          category
          subCategory
          program
          condition
          type
          previewText
          createdBy
          accessType
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        order
        _version
        _deleted
        _lastChangedAt
        resourceResourceContentId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getClientResource = /* GraphQL */ `
  query GetClientResource($id: ID!) {
    getClientResource(id: $id) {
      id
      createdAt
      updatedAt
      owner
      createdBy
      clientID
      clinicianID
      resourceID
      resource {
        id
        hasImage
        imageType
        createdAt
        updatedAt
        title
        subTitle
        sourceName
        sourceLink
        category
        subCategory
        program
        condition
        type
        resourceContent {
          nextToken
          startedAt
          __typename
        }
        previewText
        createdBy
        accessType
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listClientResources = /* GraphQL */ `
  query ListClientResources(
    $filter: ModelClientResourceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClientResources(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        owner
        createdBy
        clientID
        clinicianID
        resourceID
        resource {
          id
          hasImage
          imageType
          createdAt
          updatedAt
          title
          subTitle
          sourceName
          sourceLink
          category
          subCategory
          program
          condition
          type
          previewText
          createdBy
          accessType
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncClientResources = /* GraphQL */ `
  query SyncClientResources(
    $filter: ModelClientResourceFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncClientResources(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        createdAt
        updatedAt
        owner
        createdBy
        clientID
        clinicianID
        resourceID
        resource {
          id
          hasImage
          imageType
          createdAt
          updatedAt
          title
          subTitle
          sourceName
          sourceLink
          category
          subCategory
          program
          condition
          type
          previewText
          createdBy
          accessType
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const clientResourcesByClientID = /* GraphQL */ `
  query ClientResourcesByClientID(
    $clientID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelClientResourceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    clientResourcesByClientID(
      clientID: $clientID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        owner
        createdBy
        clientID
        clinicianID
        resourceID
        resource {
          id
          hasImage
          imageType
          createdAt
          updatedAt
          title
          subTitle
          sourceName
          sourceLink
          category
          subCategory
          program
          condition
          type
          previewText
          createdBy
          accessType
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getSession = /* GraphQL */ `
  query GetSession($id: ID!) {
    getSession(id: $id) {
      id
      owner
      createdAt
      updatedAt
      clientID
      date
      location
      notes
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listSessions = /* GraphQL */ `
  query ListSessions(
    $filter: ModelSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSessions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        createdAt
        updatedAt
        clientID
        date
        location
        notes
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncSessions = /* GraphQL */ `
  query SyncSessions(
    $filter: ModelSessionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSessions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        createdAt
        updatedAt
        clientID
        date
        location
        notes
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getSessionsByClientByDate = /* GraphQL */ `
  query GetSessionsByClientByDate(
    $clientID: ID!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSessionsByClientByDate(
      clientID: $clientID
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        createdAt
        updatedAt
        clientID
        date
        location
        notes
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getClinic = /* GraphQL */ `
  query GetClinic($id: ID!) {
    getClinic(id: $id) {
      id
      createdAt
      name
      description
      website
      locations {
        items {
          id
          createdAt
          updatedAt
          name
          phone
          streetAddress
          streetAddress2
          city
          postcode
          state
          country
          clinicID
          _version
          _deleted
          _lastChangedAt
          clinicLocationsId
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      clinicians {
        items {
          id
          sub
          createdAt
          updatedAt
          owner
          firstName
          lastName
          email
          picture
          showNameExercises
          exercises
          clinicID
          locationID
          role
          createdBy
          source
          suspended
          _version
          _deleted
          _lastChangedAt
          clinicCliniciansId
          locationCliniciansId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      hasLogo
      imageType
      clients {
        items {
          id
          sub
          createdAt
          updatedAt
          owner
          firstName
          lastName
          email
          mobile
          deleteIntent
          clientID
          timeZone
          forValue
          clientFirstName
          clientLastName
          clinicianID
          appointmentFrequency
          appointmentDay
          appointmentTime
          zoomLink
          date
          picture
          suds
          subscription
          stripeSubscriptionID
          stripeCustomerID
          billingCycle
          billingResetDate
          billingMethod
          billingIdentifier
          linkStatus
          audioSampleEntryID
          dailyMeasurementsSectionID
          ssComponentID
          ftComponentID
          role
          age
          condition
          program
          stage
          dataConsent
          enrollBeta
          showCount
          activatedShowCountBefore
          sessionCount
          hasResources
          enableVideoCalls
          status
          streak
          streakLastUpdated
          clinicID
          locationID
          _version
          _deleted
          _lastChangedAt
          clinicClientsId
          locationClientsId
          clinicianClientsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listClinics = /* GraphQL */ `
  query ListClinics(
    $filter: ModelClinicFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClinics(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        name
        description
        website
        locations {
          nextToken
          startedAt
          __typename
        }
        clinicians {
          nextToken
          startedAt
          __typename
        }
        hasLogo
        imageType
        clients {
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncClinics = /* GraphQL */ `
  query SyncClinics(
    $filter: ModelClinicFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncClinics(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        createdAt
        name
        description
        website
        locations {
          nextToken
          startedAt
          __typename
        }
        clinicians {
          nextToken
          startedAt
          __typename
        }
        hasLogo
        imageType
        clients {
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getLocation = /* GraphQL */ `
  query GetLocation($id: ID!) {
    getLocation(id: $id) {
      id
      createdAt
      updatedAt
      name
      phone
      streetAddress
      streetAddress2
      city
      postcode
      state
      country
      clinicID
      clinic {
        id
        createdAt
        name
        description
        website
        locations {
          nextToken
          startedAt
          __typename
        }
        clinicians {
          nextToken
          startedAt
          __typename
        }
        hasLogo
        imageType
        clients {
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      clinicians {
        items {
          id
          sub
          createdAt
          updatedAt
          owner
          firstName
          lastName
          email
          picture
          showNameExercises
          exercises
          clinicID
          locationID
          role
          createdBy
          source
          suspended
          _version
          _deleted
          _lastChangedAt
          clinicCliniciansId
          locationCliniciansId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      clients {
        items {
          id
          sub
          createdAt
          updatedAt
          owner
          firstName
          lastName
          email
          mobile
          deleteIntent
          clientID
          timeZone
          forValue
          clientFirstName
          clientLastName
          clinicianID
          appointmentFrequency
          appointmentDay
          appointmentTime
          zoomLink
          date
          picture
          suds
          subscription
          stripeSubscriptionID
          stripeCustomerID
          billingCycle
          billingResetDate
          billingMethod
          billingIdentifier
          linkStatus
          audioSampleEntryID
          dailyMeasurementsSectionID
          ssComponentID
          ftComponentID
          role
          age
          condition
          program
          stage
          dataConsent
          enrollBeta
          showCount
          activatedShowCountBefore
          sessionCount
          hasResources
          enableVideoCalls
          status
          streak
          streakLastUpdated
          clinicID
          locationID
          _version
          _deleted
          _lastChangedAt
          clinicClientsId
          locationClientsId
          clinicianClientsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      clinicLocationsId
      owner
      __typename
    }
  }
`;
export const listLocations = /* GraphQL */ `
  query ListLocations(
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLocations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        name
        phone
        streetAddress
        streetAddress2
        city
        postcode
        state
        country
        clinicID
        clinic {
          id
          createdAt
          name
          description
          website
          hasLogo
          imageType
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        clinicians {
          nextToken
          startedAt
          __typename
        }
        clients {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        clinicLocationsId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncLocations = /* GraphQL */ `
  query SyncLocations(
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLocations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        createdAt
        updatedAt
        name
        phone
        streetAddress
        streetAddress2
        city
        postcode
        state
        country
        clinicID
        clinic {
          id
          createdAt
          name
          description
          website
          hasLogo
          imageType
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        clinicians {
          nextToken
          startedAt
          __typename
        }
        clients {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        clinicLocationsId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const locationsByClinicID = /* GraphQL */ `
  query LocationsByClinicID(
    $clinicID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    locationsByClinicID(
      clinicID: $clinicID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        name
        phone
        streetAddress
        streetAddress2
        city
        postcode
        state
        country
        clinicID
        clinic {
          id
          createdAt
          name
          description
          website
          hasLogo
          imageType
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        clinicians {
          nextToken
          startedAt
          __typename
        }
        clients {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        clinicLocationsId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getClient = /* GraphQL */ `
  query GetClient($id: ID!) {
    getClient(id: $id) {
      id
      sub
      createdAt
      updatedAt
      owner
      firstName
      lastName
      email
      mobile
      deleteIntent
      clientID
      timeZone
      forValue
      clientFirstName
      clientLastName
      clinicianID
      clinician {
        id
        sub
        createdAt
        updatedAt
        owner
        firstName
        lastName
        email
        picture
        showNameExercises
        exercises
        clients {
          nextToken
          startedAt
          __typename
        }
        clinicID
        clinic {
          id
          createdAt
          name
          description
          website
          hasLogo
          imageType
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        location {
          id
          createdAt
          updatedAt
          name
          phone
          streetAddress
          streetAddress2
          city
          postcode
          state
          country
          clinicID
          _version
          _deleted
          _lastChangedAt
          clinicLocationsId
          owner
          __typename
        }
        locationID
        role
        createdBy
        source
        suspended
        _version
        _deleted
        _lastChangedAt
        clinicCliniciansId
        locationCliniciansId
        __typename
      }
      appointmentFrequency
      appointmentDay
      appointmentTime
      zoomLink
      date
      picture
      suds
      subscription
      stripeSubscriptionID
      stripeCustomerID
      billingCycle
      billingResetDate
      billingMethod
      billingIdentifier
      linkStatus
      audioSampleEntryID
      dailyMeasurementsSectionID
      ssComponentID
      ftComponentID
      role
      age
      condition
      program
      stage
      dataConsent
      enrollBeta
      showCount
      activatedShowCountBefore
      sessionCount
      hasResources
      enableVideoCalls
      status
      streak
      streakLastUpdated
      clinicID
      clinic {
        id
        createdAt
        name
        description
        website
        locations {
          nextToken
          startedAt
          __typename
        }
        clinicians {
          nextToken
          startedAt
          __typename
        }
        hasLogo
        imageType
        clients {
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      location {
        id
        createdAt
        updatedAt
        name
        phone
        streetAddress
        streetAddress2
        city
        postcode
        state
        country
        clinicID
        clinic {
          id
          createdAt
          name
          description
          website
          hasLogo
          imageType
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        clinicians {
          nextToken
          startedAt
          __typename
        }
        clients {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        clinicLocationsId
        owner
        __typename
      }
      locationID
      _version
      _deleted
      _lastChangedAt
      clinicClientsId
      locationClientsId
      clinicianClientsId
      __typename
    }
  }
`;
export const listClients = /* GraphQL */ `
  query ListClients(
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClients(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sub
        createdAt
        updatedAt
        owner
        firstName
        lastName
        email
        mobile
        deleteIntent
        clientID
        timeZone
        forValue
        clientFirstName
        clientLastName
        clinicianID
        clinician {
          id
          sub
          createdAt
          updatedAt
          owner
          firstName
          lastName
          email
          picture
          showNameExercises
          exercises
          clinicID
          locationID
          role
          createdBy
          source
          suspended
          _version
          _deleted
          _lastChangedAt
          clinicCliniciansId
          locationCliniciansId
          __typename
        }
        appointmentFrequency
        appointmentDay
        appointmentTime
        zoomLink
        date
        picture
        suds
        subscription
        stripeSubscriptionID
        stripeCustomerID
        billingCycle
        billingResetDate
        billingMethod
        billingIdentifier
        linkStatus
        audioSampleEntryID
        dailyMeasurementsSectionID
        ssComponentID
        ftComponentID
        role
        age
        condition
        program
        stage
        dataConsent
        enrollBeta
        showCount
        activatedShowCountBefore
        sessionCount
        hasResources
        enableVideoCalls
        status
        streak
        streakLastUpdated
        clinicID
        clinic {
          id
          createdAt
          name
          description
          website
          hasLogo
          imageType
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        location {
          id
          createdAt
          updatedAt
          name
          phone
          streetAddress
          streetAddress2
          city
          postcode
          state
          country
          clinicID
          _version
          _deleted
          _lastChangedAt
          clinicLocationsId
          owner
          __typename
        }
        locationID
        _version
        _deleted
        _lastChangedAt
        clinicClientsId
        locationClientsId
        clinicianClientsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncClients = /* GraphQL */ `
  query SyncClients(
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncClients(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        sub
        createdAt
        updatedAt
        owner
        firstName
        lastName
        email
        mobile
        deleteIntent
        clientID
        timeZone
        forValue
        clientFirstName
        clientLastName
        clinicianID
        clinician {
          id
          sub
          createdAt
          updatedAt
          owner
          firstName
          lastName
          email
          picture
          showNameExercises
          exercises
          clinicID
          locationID
          role
          createdBy
          source
          suspended
          _version
          _deleted
          _lastChangedAt
          clinicCliniciansId
          locationCliniciansId
          __typename
        }
        appointmentFrequency
        appointmentDay
        appointmentTime
        zoomLink
        date
        picture
        suds
        subscription
        stripeSubscriptionID
        stripeCustomerID
        billingCycle
        billingResetDate
        billingMethod
        billingIdentifier
        linkStatus
        audioSampleEntryID
        dailyMeasurementsSectionID
        ssComponentID
        ftComponentID
        role
        age
        condition
        program
        stage
        dataConsent
        enrollBeta
        showCount
        activatedShowCountBefore
        sessionCount
        hasResources
        enableVideoCalls
        status
        streak
        streakLastUpdated
        clinicID
        clinic {
          id
          createdAt
          name
          description
          website
          hasLogo
          imageType
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        location {
          id
          createdAt
          updatedAt
          name
          phone
          streetAddress
          streetAddress2
          city
          postcode
          state
          country
          clinicID
          _version
          _deleted
          _lastChangedAt
          clinicLocationsId
          owner
          __typename
        }
        locationID
        _version
        _deleted
        _lastChangedAt
        clinicClientsId
        locationClientsId
        clinicianClientsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getClientsByEmail = /* GraphQL */ `
  query GetClientsByEmail(
    $email: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getClientsByEmail(
      email: $email
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sub
        createdAt
        updatedAt
        owner
        firstName
        lastName
        email
        mobile
        deleteIntent
        clientID
        timeZone
        forValue
        clientFirstName
        clientLastName
        clinicianID
        clinician {
          id
          sub
          createdAt
          updatedAt
          owner
          firstName
          lastName
          email
          picture
          showNameExercises
          exercises
          clinicID
          locationID
          role
          createdBy
          source
          suspended
          _version
          _deleted
          _lastChangedAt
          clinicCliniciansId
          locationCliniciansId
          __typename
        }
        appointmentFrequency
        appointmentDay
        appointmentTime
        zoomLink
        date
        picture
        suds
        subscription
        stripeSubscriptionID
        stripeCustomerID
        billingCycle
        billingResetDate
        billingMethod
        billingIdentifier
        linkStatus
        audioSampleEntryID
        dailyMeasurementsSectionID
        ssComponentID
        ftComponentID
        role
        age
        condition
        program
        stage
        dataConsent
        enrollBeta
        showCount
        activatedShowCountBefore
        sessionCount
        hasResources
        enableVideoCalls
        status
        streak
        streakLastUpdated
        clinicID
        clinic {
          id
          createdAt
          name
          description
          website
          hasLogo
          imageType
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        location {
          id
          createdAt
          updatedAt
          name
          phone
          streetAddress
          streetAddress2
          city
          postcode
          state
          country
          clinicID
          _version
          _deleted
          _lastChangedAt
          clinicLocationsId
          owner
          __typename
        }
        locationID
        _version
        _deleted
        _lastChangedAt
        clinicClientsId
        locationClientsId
        clinicianClientsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const clientsByMobile = /* GraphQL */ `
  query ClientsByMobile(
    $mobile: Int!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    clientsByMobile(
      mobile: $mobile
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sub
        createdAt
        updatedAt
        owner
        firstName
        lastName
        email
        mobile
        deleteIntent
        clientID
        timeZone
        forValue
        clientFirstName
        clientLastName
        clinicianID
        clinician {
          id
          sub
          createdAt
          updatedAt
          owner
          firstName
          lastName
          email
          picture
          showNameExercises
          exercises
          clinicID
          locationID
          role
          createdBy
          source
          suspended
          _version
          _deleted
          _lastChangedAt
          clinicCliniciansId
          locationCliniciansId
          __typename
        }
        appointmentFrequency
        appointmentDay
        appointmentTime
        zoomLink
        date
        picture
        suds
        subscription
        stripeSubscriptionID
        stripeCustomerID
        billingCycle
        billingResetDate
        billingMethod
        billingIdentifier
        linkStatus
        audioSampleEntryID
        dailyMeasurementsSectionID
        ssComponentID
        ftComponentID
        role
        age
        condition
        program
        stage
        dataConsent
        enrollBeta
        showCount
        activatedShowCountBefore
        sessionCount
        hasResources
        enableVideoCalls
        status
        streak
        streakLastUpdated
        clinicID
        clinic {
          id
          createdAt
          name
          description
          website
          hasLogo
          imageType
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        location {
          id
          createdAt
          updatedAt
          name
          phone
          streetAddress
          streetAddress2
          city
          postcode
          state
          country
          clinicID
          _version
          _deleted
          _lastChangedAt
          clinicLocationsId
          owner
          __typename
        }
        locationID
        _version
        _deleted
        _lastChangedAt
        clinicClientsId
        locationClientsId
        clinicianClientsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const clientsByDeleteIntent = /* GraphQL */ `
  query ClientsByDeleteIntent(
    $deleteIntent: String!
    $sortDirection: ModelSortDirection
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    clientsByDeleteIntent(
      deleteIntent: $deleteIntent
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sub
        createdAt
        updatedAt
        owner
        firstName
        lastName
        email
        mobile
        deleteIntent
        clientID
        timeZone
        forValue
        clientFirstName
        clientLastName
        clinicianID
        clinician {
          id
          sub
          createdAt
          updatedAt
          owner
          firstName
          lastName
          email
          picture
          showNameExercises
          exercises
          clinicID
          locationID
          role
          createdBy
          source
          suspended
          _version
          _deleted
          _lastChangedAt
          clinicCliniciansId
          locationCliniciansId
          __typename
        }
        appointmentFrequency
        appointmentDay
        appointmentTime
        zoomLink
        date
        picture
        suds
        subscription
        stripeSubscriptionID
        stripeCustomerID
        billingCycle
        billingResetDate
        billingMethod
        billingIdentifier
        linkStatus
        audioSampleEntryID
        dailyMeasurementsSectionID
        ssComponentID
        ftComponentID
        role
        age
        condition
        program
        stage
        dataConsent
        enrollBeta
        showCount
        activatedShowCountBefore
        sessionCount
        hasResources
        enableVideoCalls
        status
        streak
        streakLastUpdated
        clinicID
        clinic {
          id
          createdAt
          name
          description
          website
          hasLogo
          imageType
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        location {
          id
          createdAt
          updatedAt
          name
          phone
          streetAddress
          streetAddress2
          city
          postcode
          state
          country
          clinicID
          _version
          _deleted
          _lastChangedAt
          clinicLocationsId
          owner
          __typename
        }
        locationID
        _version
        _deleted
        _lastChangedAt
        clinicClientsId
        locationClientsId
        clinicianClientsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const clientsByClientID = /* GraphQL */ `
  query ClientsByClientID(
    $clientID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    clientsByClientID(
      clientID: $clientID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sub
        createdAt
        updatedAt
        owner
        firstName
        lastName
        email
        mobile
        deleteIntent
        clientID
        timeZone
        forValue
        clientFirstName
        clientLastName
        clinicianID
        clinician {
          id
          sub
          createdAt
          updatedAt
          owner
          firstName
          lastName
          email
          picture
          showNameExercises
          exercises
          clinicID
          locationID
          role
          createdBy
          source
          suspended
          _version
          _deleted
          _lastChangedAt
          clinicCliniciansId
          locationCliniciansId
          __typename
        }
        appointmentFrequency
        appointmentDay
        appointmentTime
        zoomLink
        date
        picture
        suds
        subscription
        stripeSubscriptionID
        stripeCustomerID
        billingCycle
        billingResetDate
        billingMethod
        billingIdentifier
        linkStatus
        audioSampleEntryID
        dailyMeasurementsSectionID
        ssComponentID
        ftComponentID
        role
        age
        condition
        program
        stage
        dataConsent
        enrollBeta
        showCount
        activatedShowCountBefore
        sessionCount
        hasResources
        enableVideoCalls
        status
        streak
        streakLastUpdated
        clinicID
        clinic {
          id
          createdAt
          name
          description
          website
          hasLogo
          imageType
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        location {
          id
          createdAt
          updatedAt
          name
          phone
          streetAddress
          streetAddress2
          city
          postcode
          state
          country
          clinicID
          _version
          _deleted
          _lastChangedAt
          clinicLocationsId
          owner
          __typename
        }
        locationID
        _version
        _deleted
        _lastChangedAt
        clinicClientsId
        locationClientsId
        clinicianClientsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const clientsByClinicianID = /* GraphQL */ `
  query ClientsByClinicianID(
    $clinicianID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    clientsByClinicianID(
      clinicianID: $clinicianID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sub
        createdAt
        updatedAt
        owner
        firstName
        lastName
        email
        mobile
        deleteIntent
        clientID
        timeZone
        forValue
        clientFirstName
        clientLastName
        clinicianID
        clinician {
          id
          sub
          createdAt
          updatedAt
          owner
          firstName
          lastName
          email
          picture
          showNameExercises
          exercises
          clinicID
          locationID
          role
          createdBy
          source
          suspended
          _version
          _deleted
          _lastChangedAt
          clinicCliniciansId
          locationCliniciansId
          __typename
        }
        appointmentFrequency
        appointmentDay
        appointmentTime
        zoomLink
        date
        picture
        suds
        subscription
        stripeSubscriptionID
        stripeCustomerID
        billingCycle
        billingResetDate
        billingMethod
        billingIdentifier
        linkStatus
        audioSampleEntryID
        dailyMeasurementsSectionID
        ssComponentID
        ftComponentID
        role
        age
        condition
        program
        stage
        dataConsent
        enrollBeta
        showCount
        activatedShowCountBefore
        sessionCount
        hasResources
        enableVideoCalls
        status
        streak
        streakLastUpdated
        clinicID
        clinic {
          id
          createdAt
          name
          description
          website
          hasLogo
          imageType
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        location {
          id
          createdAt
          updatedAt
          name
          phone
          streetAddress
          streetAddress2
          city
          postcode
          state
          country
          clinicID
          _version
          _deleted
          _lastChangedAt
          clinicLocationsId
          owner
          __typename
        }
        locationID
        _version
        _deleted
        _lastChangedAt
        clinicClientsId
        locationClientsId
        clinicianClientsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const clientsByClinic = /* GraphQL */ `
  query ClientsByClinic(
    $clinicID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    clientsByClinic(
      clinicID: $clinicID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sub
        createdAt
        updatedAt
        owner
        firstName
        lastName
        email
        mobile
        deleteIntent
        clientID
        timeZone
        forValue
        clientFirstName
        clientLastName
        clinicianID
        clinician {
          id
          sub
          createdAt
          updatedAt
          owner
          firstName
          lastName
          email
          picture
          showNameExercises
          exercises
          clinicID
          locationID
          role
          createdBy
          source
          suspended
          _version
          _deleted
          _lastChangedAt
          clinicCliniciansId
          locationCliniciansId
          __typename
        }
        appointmentFrequency
        appointmentDay
        appointmentTime
        zoomLink
        date
        picture
        suds
        subscription
        stripeSubscriptionID
        stripeCustomerID
        billingCycle
        billingResetDate
        billingMethod
        billingIdentifier
        linkStatus
        audioSampleEntryID
        dailyMeasurementsSectionID
        ssComponentID
        ftComponentID
        role
        age
        condition
        program
        stage
        dataConsent
        enrollBeta
        showCount
        activatedShowCountBefore
        sessionCount
        hasResources
        enableVideoCalls
        status
        streak
        streakLastUpdated
        clinicID
        clinic {
          id
          createdAt
          name
          description
          website
          hasLogo
          imageType
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        location {
          id
          createdAt
          updatedAt
          name
          phone
          streetAddress
          streetAddress2
          city
          postcode
          state
          country
          clinicID
          _version
          _deleted
          _lastChangedAt
          clinicLocationsId
          owner
          __typename
        }
        locationID
        _version
        _deleted
        _lastChangedAt
        clinicClientsId
        locationClientsId
        clinicianClientsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const clientsByClinicAndName = /* GraphQL */ `
  query ClientsByClinicAndName(
    $clinicID: ID!
    $firstName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    clientsByClinicAndName(
      clinicID: $clinicID
      firstName: $firstName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sub
        createdAt
        updatedAt
        owner
        firstName
        lastName
        email
        mobile
        deleteIntent
        clientID
        timeZone
        forValue
        clientFirstName
        clientLastName
        clinicianID
        clinician {
          id
          sub
          createdAt
          updatedAt
          owner
          firstName
          lastName
          email
          picture
          showNameExercises
          exercises
          clinicID
          locationID
          role
          createdBy
          source
          suspended
          _version
          _deleted
          _lastChangedAt
          clinicCliniciansId
          locationCliniciansId
          __typename
        }
        appointmentFrequency
        appointmentDay
        appointmentTime
        zoomLink
        date
        picture
        suds
        subscription
        stripeSubscriptionID
        stripeCustomerID
        billingCycle
        billingResetDate
        billingMethod
        billingIdentifier
        linkStatus
        audioSampleEntryID
        dailyMeasurementsSectionID
        ssComponentID
        ftComponentID
        role
        age
        condition
        program
        stage
        dataConsent
        enrollBeta
        showCount
        activatedShowCountBefore
        sessionCount
        hasResources
        enableVideoCalls
        status
        streak
        streakLastUpdated
        clinicID
        clinic {
          id
          createdAt
          name
          description
          website
          hasLogo
          imageType
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        location {
          id
          createdAt
          updatedAt
          name
          phone
          streetAddress
          streetAddress2
          city
          postcode
          state
          country
          clinicID
          _version
          _deleted
          _lastChangedAt
          clinicLocationsId
          owner
          __typename
        }
        locationID
        _version
        _deleted
        _lastChangedAt
        clinicClientsId
        locationClientsId
        clinicianClientsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const clientsByLocationID = /* GraphQL */ `
  query ClientsByLocationID(
    $locationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    clientsByLocationID(
      locationID: $locationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sub
        createdAt
        updatedAt
        owner
        firstName
        lastName
        email
        mobile
        deleteIntent
        clientID
        timeZone
        forValue
        clientFirstName
        clientLastName
        clinicianID
        clinician {
          id
          sub
          createdAt
          updatedAt
          owner
          firstName
          lastName
          email
          picture
          showNameExercises
          exercises
          clinicID
          locationID
          role
          createdBy
          source
          suspended
          _version
          _deleted
          _lastChangedAt
          clinicCliniciansId
          locationCliniciansId
          __typename
        }
        appointmentFrequency
        appointmentDay
        appointmentTime
        zoomLink
        date
        picture
        suds
        subscription
        stripeSubscriptionID
        stripeCustomerID
        billingCycle
        billingResetDate
        billingMethod
        billingIdentifier
        linkStatus
        audioSampleEntryID
        dailyMeasurementsSectionID
        ssComponentID
        ftComponentID
        role
        age
        condition
        program
        stage
        dataConsent
        enrollBeta
        showCount
        activatedShowCountBefore
        sessionCount
        hasResources
        enableVideoCalls
        status
        streak
        streakLastUpdated
        clinicID
        clinic {
          id
          createdAt
          name
          description
          website
          hasLogo
          imageType
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        location {
          id
          createdAt
          updatedAt
          name
          phone
          streetAddress
          streetAddress2
          city
          postcode
          state
          country
          clinicID
          _version
          _deleted
          _lastChangedAt
          clinicLocationsId
          owner
          __typename
        }
        locationID
        _version
        _deleted
        _lastChangedAt
        clinicClientsId
        locationClientsId
        clinicianClientsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const clientsByLocationAndName = /* GraphQL */ `
  query ClientsByLocationAndName(
    $locationID: ID!
    $firstName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    clientsByLocationAndName(
      locationID: $locationID
      firstName: $firstName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sub
        createdAt
        updatedAt
        owner
        firstName
        lastName
        email
        mobile
        deleteIntent
        clientID
        timeZone
        forValue
        clientFirstName
        clientLastName
        clinicianID
        clinician {
          id
          sub
          createdAt
          updatedAt
          owner
          firstName
          lastName
          email
          picture
          showNameExercises
          exercises
          clinicID
          locationID
          role
          createdBy
          source
          suspended
          _version
          _deleted
          _lastChangedAt
          clinicCliniciansId
          locationCliniciansId
          __typename
        }
        appointmentFrequency
        appointmentDay
        appointmentTime
        zoomLink
        date
        picture
        suds
        subscription
        stripeSubscriptionID
        stripeCustomerID
        billingCycle
        billingResetDate
        billingMethod
        billingIdentifier
        linkStatus
        audioSampleEntryID
        dailyMeasurementsSectionID
        ssComponentID
        ftComponentID
        role
        age
        condition
        program
        stage
        dataConsent
        enrollBeta
        showCount
        activatedShowCountBefore
        sessionCount
        hasResources
        enableVideoCalls
        status
        streak
        streakLastUpdated
        clinicID
        clinic {
          id
          createdAt
          name
          description
          website
          hasLogo
          imageType
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        location {
          id
          createdAt
          updatedAt
          name
          phone
          streetAddress
          streetAddress2
          city
          postcode
          state
          country
          clinicID
          _version
          _deleted
          _lastChangedAt
          clinicLocationsId
          owner
          __typename
        }
        locationID
        _version
        _deleted
        _lastChangedAt
        clinicClientsId
        locationClientsId
        clinicianClientsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getClinician = /* GraphQL */ `
  query GetClinician($id: ID!) {
    getClinician(id: $id) {
      id
      sub
      createdAt
      updatedAt
      owner
      firstName
      lastName
      email
      picture
      showNameExercises
      exercises
      clients {
        items {
          id
          sub
          createdAt
          updatedAt
          owner
          firstName
          lastName
          email
          mobile
          deleteIntent
          clientID
          timeZone
          forValue
          clientFirstName
          clientLastName
          clinicianID
          appointmentFrequency
          appointmentDay
          appointmentTime
          zoomLink
          date
          picture
          suds
          subscription
          stripeSubscriptionID
          stripeCustomerID
          billingCycle
          billingResetDate
          billingMethod
          billingIdentifier
          linkStatus
          audioSampleEntryID
          dailyMeasurementsSectionID
          ssComponentID
          ftComponentID
          role
          age
          condition
          program
          stage
          dataConsent
          enrollBeta
          showCount
          activatedShowCountBefore
          sessionCount
          hasResources
          enableVideoCalls
          status
          streak
          streakLastUpdated
          clinicID
          locationID
          _version
          _deleted
          _lastChangedAt
          clinicClientsId
          locationClientsId
          clinicianClientsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      clinicID
      clinic {
        id
        createdAt
        name
        description
        website
        locations {
          nextToken
          startedAt
          __typename
        }
        clinicians {
          nextToken
          startedAt
          __typename
        }
        hasLogo
        imageType
        clients {
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      location {
        id
        createdAt
        updatedAt
        name
        phone
        streetAddress
        streetAddress2
        city
        postcode
        state
        country
        clinicID
        clinic {
          id
          createdAt
          name
          description
          website
          hasLogo
          imageType
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        clinicians {
          nextToken
          startedAt
          __typename
        }
        clients {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        clinicLocationsId
        owner
        __typename
      }
      locationID
      role
      createdBy
      source
      suspended
      _version
      _deleted
      _lastChangedAt
      clinicCliniciansId
      locationCliniciansId
      __typename
    }
  }
`;
export const listClinicians = /* GraphQL */ `
  query ListClinicians(
    $filter: ModelClinicianFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClinicians(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sub
        createdAt
        updatedAt
        owner
        firstName
        lastName
        email
        picture
        showNameExercises
        exercises
        clients {
          nextToken
          startedAt
          __typename
        }
        clinicID
        clinic {
          id
          createdAt
          name
          description
          website
          hasLogo
          imageType
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        location {
          id
          createdAt
          updatedAt
          name
          phone
          streetAddress
          streetAddress2
          city
          postcode
          state
          country
          clinicID
          _version
          _deleted
          _lastChangedAt
          clinicLocationsId
          owner
          __typename
        }
        locationID
        role
        createdBy
        source
        suspended
        _version
        _deleted
        _lastChangedAt
        clinicCliniciansId
        locationCliniciansId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncClinicians = /* GraphQL */ `
  query SyncClinicians(
    $filter: ModelClinicianFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncClinicians(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        sub
        createdAt
        updatedAt
        owner
        firstName
        lastName
        email
        picture
        showNameExercises
        exercises
        clients {
          nextToken
          startedAt
          __typename
        }
        clinicID
        clinic {
          id
          createdAt
          name
          description
          website
          hasLogo
          imageType
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        location {
          id
          createdAt
          updatedAt
          name
          phone
          streetAddress
          streetAddress2
          city
          postcode
          state
          country
          clinicID
          _version
          _deleted
          _lastChangedAt
          clinicLocationsId
          owner
          __typename
        }
        locationID
        role
        createdBy
        source
        suspended
        _version
        _deleted
        _lastChangedAt
        clinicCliniciansId
        locationCliniciansId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getCliniciansByEmail = /* GraphQL */ `
  query GetCliniciansByEmail(
    $email: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClinicianFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCliniciansByEmail(
      email: $email
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sub
        createdAt
        updatedAt
        owner
        firstName
        lastName
        email
        picture
        showNameExercises
        exercises
        clients {
          nextToken
          startedAt
          __typename
        }
        clinicID
        clinic {
          id
          createdAt
          name
          description
          website
          hasLogo
          imageType
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        location {
          id
          createdAt
          updatedAt
          name
          phone
          streetAddress
          streetAddress2
          city
          postcode
          state
          country
          clinicID
          _version
          _deleted
          _lastChangedAt
          clinicLocationsId
          owner
          __typename
        }
        locationID
        role
        createdBy
        source
        suspended
        _version
        _deleted
        _lastChangedAt
        clinicCliniciansId
        locationCliniciansId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const cliniciansByClinicByRole = /* GraphQL */ `
  query CliniciansByClinicByRole(
    $clinicID: ID!
    $role: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClinicianFilterInput
    $limit: Int
    $nextToken: String
  ) {
    cliniciansByClinicByRole(
      clinicID: $clinicID
      role: $role
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sub
        createdAt
        updatedAt
        owner
        firstName
        lastName
        email
        picture
        showNameExercises
        exercises
        clients {
          nextToken
          startedAt
          __typename
        }
        clinicID
        clinic {
          id
          createdAt
          name
          description
          website
          hasLogo
          imageType
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        location {
          id
          createdAt
          updatedAt
          name
          phone
          streetAddress
          streetAddress2
          city
          postcode
          state
          country
          clinicID
          _version
          _deleted
          _lastChangedAt
          clinicLocationsId
          owner
          __typename
        }
        locationID
        role
        createdBy
        source
        suspended
        _version
        _deleted
        _lastChangedAt
        clinicCliniciansId
        locationCliniciansId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const cliniciansByLocationByRole = /* GraphQL */ `
  query CliniciansByLocationByRole(
    $locationID: ID!
    $role: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClinicianFilterInput
    $limit: Int
    $nextToken: String
  ) {
    cliniciansByLocationByRole(
      locationID: $locationID
      role: $role
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sub
        createdAt
        updatedAt
        owner
        firstName
        lastName
        email
        picture
        showNameExercises
        exercises
        clients {
          nextToken
          startedAt
          __typename
        }
        clinicID
        clinic {
          id
          createdAt
          name
          description
          website
          hasLogo
          imageType
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        location {
          id
          createdAt
          updatedAt
          name
          phone
          streetAddress
          streetAddress2
          city
          postcode
          state
          country
          clinicID
          _version
          _deleted
          _lastChangedAt
          clinicLocationsId
          owner
          __typename
        }
        locationID
        role
        createdBy
        source
        suspended
        _version
        _deleted
        _lastChangedAt
        clinicCliniciansId
        locationCliniciansId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const cliniciansByRole = /* GraphQL */ `
  query CliniciansByRole(
    $role: String!
    $sortDirection: ModelSortDirection
    $filter: ModelClinicianFilterInput
    $limit: Int
    $nextToken: String
  ) {
    cliniciansByRole(
      role: $role
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sub
        createdAt
        updatedAt
        owner
        firstName
        lastName
        email
        picture
        showNameExercises
        exercises
        clients {
          nextToken
          startedAt
          __typename
        }
        clinicID
        clinic {
          id
          createdAt
          name
          description
          website
          hasLogo
          imageType
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        location {
          id
          createdAt
          updatedAt
          name
          phone
          streetAddress
          streetAddress2
          city
          postcode
          state
          country
          clinicID
          _version
          _deleted
          _lastChangedAt
          clinicLocationsId
          owner
          __typename
        }
        locationID
        role
        createdBy
        source
        suspended
        _version
        _deleted
        _lastChangedAt
        clinicCliniciansId
        locationCliniciansId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getSupport = /* GraphQL */ `
  query GetSupport($id: ID!) {
    getSupport(id: $id) {
      id
      owner
      createdAt
      updatedAt
      clientID
      clinicianID
      expiresAt
      used
      createdBy
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listSupports = /* GraphQL */ `
  query ListSupports(
    $filter: ModelSupportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSupports(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        createdAt
        updatedAt
        clientID
        clinicianID
        expiresAt
        used
        createdBy
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncSupports = /* GraphQL */ `
  query SyncSupports(
    $filter: ModelSupportFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSupports(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        createdAt
        updatedAt
        clientID
        clinicianID
        expiresAt
        used
        createdBy
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const supportsByClientID = /* GraphQL */ `
  query SupportsByClientID(
    $clientID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSupportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    supportsByClientID(
      clientID: $clientID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        createdAt
        updatedAt
        clientID
        clinicianID
        expiresAt
        used
        createdBy
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const supportsByClinicianID = /* GraphQL */ `
  query SupportsByClinicianID(
    $clinicianID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSupportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    supportsByClinicianID(
      clinicianID: $clinicianID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        createdAt
        updatedAt
        clientID
        clinicianID
        expiresAt
        used
        createdBy
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const supportsByCreatedBy = /* GraphQL */ `
  query SupportsByCreatedBy(
    $createdBy: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSupportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    supportsByCreatedBy(
      createdBy: $createdBy
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        createdAt
        updatedAt
        clientID
        clinicianID
        expiresAt
        used
        createdBy
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getInvitation = /* GraphQL */ `
  query GetInvitation($id: ID!) {
    getInvitation(id: $id) {
      id
      owner
      createdAt
      updatedAt
      locationID
      clinicID
      clientID
      clinicianID
      used
      inviteeID
      email
      firstName
      lastName
      inviteeFirstName
      inviteeEmail
      createdBy
      action
      role
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listInvitations = /* GraphQL */ `
  query ListInvitations(
    $filter: ModelInvitationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvitations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        createdAt
        updatedAt
        locationID
        clinicID
        clientID
        clinicianID
        used
        inviteeID
        email
        firstName
        lastName
        inviteeFirstName
        inviteeEmail
        createdBy
        action
        role
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncInvitations = /* GraphQL */ `
  query SyncInvitations(
    $filter: ModelInvitationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncInvitations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        createdAt
        updatedAt
        locationID
        clinicID
        clientID
        clinicianID
        used
        inviteeID
        email
        firstName
        lastName
        inviteeFirstName
        inviteeEmail
        createdBy
        action
        role
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const invitationsByLocationID = /* GraphQL */ `
  query InvitationsByLocationID(
    $locationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInvitationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    invitationsByLocationID(
      locationID: $locationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        createdAt
        updatedAt
        locationID
        clinicID
        clientID
        clinicianID
        used
        inviteeID
        email
        firstName
        lastName
        inviteeFirstName
        inviteeEmail
        createdBy
        action
        role
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const invitationsByClinicID = /* GraphQL */ `
  query InvitationsByClinicID(
    $clinicID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInvitationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    invitationsByClinicID(
      clinicID: $clinicID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        createdAt
        updatedAt
        locationID
        clinicID
        clientID
        clinicianID
        used
        inviteeID
        email
        firstName
        lastName
        inviteeFirstName
        inviteeEmail
        createdBy
        action
        role
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const invitationsByClientID = /* GraphQL */ `
  query InvitationsByClientID(
    $clientID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInvitationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    invitationsByClientID(
      clientID: $clientID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        createdAt
        updatedAt
        locationID
        clinicID
        clientID
        clinicianID
        used
        inviteeID
        email
        firstName
        lastName
        inviteeFirstName
        inviteeEmail
        createdBy
        action
        role
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const invitationsByClinicianID = /* GraphQL */ `
  query InvitationsByClinicianID(
    $clinicianID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInvitationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    invitationsByClinicianID(
      clinicianID: $clinicianID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        createdAt
        updatedAt
        locationID
        clinicID
        clientID
        clinicianID
        used
        inviteeID
        email
        firstName
        lastName
        inviteeFirstName
        inviteeEmail
        createdBy
        action
        role
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const invitationsByInviteeID = /* GraphQL */ `
  query InvitationsByInviteeID(
    $inviteeID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInvitationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    invitationsByInviteeID(
      inviteeID: $inviteeID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        createdAt
        updatedAt
        locationID
        clinicID
        clientID
        clinicianID
        used
        inviteeID
        email
        firstName
        lastName
        inviteeFirstName
        inviteeEmail
        createdBy
        action
        role
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const invitationsByInviteeEmail = /* GraphQL */ `
  query InvitationsByInviteeEmail(
    $inviteeEmail: String!
    $sortDirection: ModelSortDirection
    $filter: ModelInvitationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    invitationsByInviteeEmail(
      inviteeEmail: $inviteeEmail
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        createdAt
        updatedAt
        locationID
        clinicID
        clientID
        clinicianID
        used
        inviteeID
        email
        firstName
        lastName
        inviteeFirstName
        inviteeEmail
        createdBy
        action
        role
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const invitationsByCreatedBy = /* GraphQL */ `
  query InvitationsByCreatedBy(
    $createdBy: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInvitationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    invitationsByCreatedBy(
      createdBy: $createdBy
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        createdAt
        updatedAt
        locationID
        clinicID
        clientID
        clinicianID
        used
        inviteeID
        email
        firstName
        lastName
        inviteeFirstName
        inviteeEmail
        createdBy
        action
        role
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const invitationByAction = /* GraphQL */ `
  query InvitationByAction(
    $action: String!
    $clientID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInvitationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    invitationByAction(
      action: $action
      clientID: $clientID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        createdAt
        updatedAt
        locationID
        clinicID
        clientID
        clinicianID
        used
        inviteeID
        email
        firstName
        lastName
        inviteeFirstName
        inviteeEmail
        createdBy
        action
        role
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUsage = /* GraphQL */ `
  query GetUsage($id: ID!) {
    getUsage(id: $id) {
      id
      createdAt
      updatedAt
      clientId
      recordings
      entries
      insights
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listUsages = /* GraphQL */ `
  query ListUsages(
    $filter: ModelUsageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        clientId
        recordings
        entries
        insights
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUsages = /* GraphQL */ `
  query SyncUsages(
    $filter: ModelUsageFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        createdAt
        updatedAt
        clientId
        recordings
        entries
        insights
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getSection = /* GraphQL */ `
  query GetSection($id: ID!) {
    getSection(id: $id) {
      id
      createdAt
      updatedAt
      complete
      name
      inactive
      noEdit
      analytics
      order
      owner
      settings
      clientID
      createdBy
      entries {
        items {
          id
          createdAt
          updatedAt
          owner
          name
          icon
          type
          order
          addLogic
          logic
          inactive
          noEdit
          settings
          complete
          analytics
          draftRecordID
          clientID
          createdBy
          sectionID
          count
          goalID
          _version
          _deleted
          _lastChangedAt
          sectionEntriesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      goalID
      goal {
        id
        owner
        name
        description
        createdAt
        updatedAt
        createdBy
        updatedBy
        targetNumber
        targetFrequency
        targetType
        recurringType
        recurringUntil
        recurringNumber
        color
        section {
          id
          createdAt
          updatedAt
          complete
          name
          inactive
          noEdit
          analytics
          order
          owner
          settings
          clientID
          createdBy
          goalID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        sectionID
        entry {
          id
          createdAt
          updatedAt
          owner
          name
          icon
          type
          order
          addLogic
          logic
          inactive
          noEdit
          settings
          complete
          analytics
          draftRecordID
          clientID
          createdBy
          sectionID
          count
          goalID
          _version
          _deleted
          _lastChangedAt
          sectionEntriesId
          __typename
        }
        entryID
        component {
          id
          createdAt
          updatedAt
          clientID
          complete
          owner
          type
          order
          settings
          to
          from
          rows
          name
          noEdit
          size
          label
          placeholder
          upload
          options
          content
          boolean
          variant
          prompts
          collapse
          showInfo
          showPrompts
          multiline
          addButton
          multiselect
          information
          instructions
          removeButton
          booleanLabel
          annotationLabels
          entrySectionID
          goalID
          _version
          _deleted
          _lastChangedAt
          entrySectionComponentsId
          __typename
        }
        componentID
        clientID
        order
        goalEntries {
          nextToken
          startedAt
          __typename
        }
        complete
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listSections = /* GraphQL */ `
  query ListSections(
    $filter: ModelSectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSections(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        complete
        name
        inactive
        noEdit
        analytics
        order
        owner
        settings
        clientID
        createdBy
        entries {
          nextToken
          startedAt
          __typename
        }
        goalID
        goal {
          id
          owner
          name
          description
          createdAt
          updatedAt
          createdBy
          updatedBy
          targetNumber
          targetFrequency
          targetType
          recurringType
          recurringUntil
          recurringNumber
          color
          sectionID
          entryID
          componentID
          clientID
          order
          complete
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncSections = /* GraphQL */ `
  query SyncSections(
    $filter: ModelSectionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        createdAt
        updatedAt
        complete
        name
        inactive
        noEdit
        analytics
        order
        owner
        settings
        clientID
        createdBy
        entries {
          nextToken
          startedAt
          __typename
        }
        goalID
        goal {
          id
          owner
          name
          description
          createdAt
          updatedAt
          createdBy
          updatedBy
          targetNumber
          targetFrequency
          targetType
          recurringType
          recurringUntil
          recurringNumber
          color
          sectionID
          entryID
          componentID
          clientID
          order
          complete
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const sectionByClientIDByOrder = /* GraphQL */ `
  query SectionByClientIDByOrder(
    $clientID: ID!
    $order: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sectionByClientIDByOrder(
      clientID: $clientID
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        complete
        name
        inactive
        noEdit
        analytics
        order
        owner
        settings
        clientID
        createdBy
        entries {
          nextToken
          startedAt
          __typename
        }
        goalID
        goal {
          id
          owner
          name
          description
          createdAt
          updatedAt
          createdBy
          updatedBy
          targetNumber
          targetFrequency
          targetType
          recurringType
          recurringUntil
          recurringNumber
          color
          sectionID
          entryID
          componentID
          clientID
          order
          complete
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const sectionsByGoalID = /* GraphQL */ `
  query SectionsByGoalID(
    $goalID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sectionsByGoalID(
      goalID: $goalID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        complete
        name
        inactive
        noEdit
        analytics
        order
        owner
        settings
        clientID
        createdBy
        entries {
          nextToken
          startedAt
          __typename
        }
        goalID
        goal {
          id
          owner
          name
          description
          createdAt
          updatedAt
          createdBy
          updatedBy
          targetNumber
          targetFrequency
          targetType
          recurringType
          recurringUntil
          recurringNumber
          color
          sectionID
          entryID
          componentID
          clientID
          order
          complete
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getEntry = /* GraphQL */ `
  query GetEntry($id: ID!) {
    getEntry(id: $id) {
      id
      createdAt
      updatedAt
      owner
      name
      icon
      type
      order
      addLogic
      logic
      inactive
      noEdit
      settings
      complete
      analytics
      draftRecordID
      clientID
      createdBy
      sectionID
      section {
        id
        createdAt
        updatedAt
        complete
        name
        inactive
        noEdit
        analytics
        order
        owner
        settings
        clientID
        createdBy
        entries {
          nextToken
          startedAt
          __typename
        }
        goalID
        goal {
          id
          owner
          name
          description
          createdAt
          updatedAt
          createdBy
          updatedBy
          targetNumber
          targetFrequency
          targetType
          recurringType
          recurringUntil
          recurringNumber
          color
          sectionID
          entryID
          componentID
          clientID
          order
          complete
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      entrySections {
        items {
          id
          createdAt
          updatedAt
          name
          complete
          owner
          order
          noEdit
          settings
          showInfo
          information
          informationTitle
          orientation
          clientID
          entryID
          createdBy
          _version
          _deleted
          _lastChangedAt
          entryEntrySectionsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      count
      goalID
      goal {
        id
        owner
        name
        description
        createdAt
        updatedAt
        createdBy
        updatedBy
        targetNumber
        targetFrequency
        targetType
        recurringType
        recurringUntil
        recurringNumber
        color
        section {
          id
          createdAt
          updatedAt
          complete
          name
          inactive
          noEdit
          analytics
          order
          owner
          settings
          clientID
          createdBy
          goalID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        sectionID
        entry {
          id
          createdAt
          updatedAt
          owner
          name
          icon
          type
          order
          addLogic
          logic
          inactive
          noEdit
          settings
          complete
          analytics
          draftRecordID
          clientID
          createdBy
          sectionID
          count
          goalID
          _version
          _deleted
          _lastChangedAt
          sectionEntriesId
          __typename
        }
        entryID
        component {
          id
          createdAt
          updatedAt
          clientID
          complete
          owner
          type
          order
          settings
          to
          from
          rows
          name
          noEdit
          size
          label
          placeholder
          upload
          options
          content
          boolean
          variant
          prompts
          collapse
          showInfo
          showPrompts
          multiline
          addButton
          multiselect
          information
          instructions
          removeButton
          booleanLabel
          annotationLabels
          entrySectionID
          goalID
          _version
          _deleted
          _lastChangedAt
          entrySectionComponentsId
          __typename
        }
        componentID
        clientID
        order
        goalEntries {
          nextToken
          startedAt
          __typename
        }
        complete
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      sectionEntriesId
      __typename
    }
  }
`;
export const listEntries = /* GraphQL */ `
  query ListEntries(
    $filter: ModelEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEntries(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        owner
        name
        icon
        type
        order
        addLogic
        logic
        inactive
        noEdit
        settings
        complete
        analytics
        draftRecordID
        clientID
        createdBy
        sectionID
        section {
          id
          createdAt
          updatedAt
          complete
          name
          inactive
          noEdit
          analytics
          order
          owner
          settings
          clientID
          createdBy
          goalID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        entrySections {
          nextToken
          startedAt
          __typename
        }
        count
        goalID
        goal {
          id
          owner
          name
          description
          createdAt
          updatedAt
          createdBy
          updatedBy
          targetNumber
          targetFrequency
          targetType
          recurringType
          recurringUntil
          recurringNumber
          color
          sectionID
          entryID
          componentID
          clientID
          order
          complete
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        sectionEntriesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncEntries = /* GraphQL */ `
  query SyncEntries(
    $filter: ModelEntryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEntries(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        createdAt
        updatedAt
        owner
        name
        icon
        type
        order
        addLogic
        logic
        inactive
        noEdit
        settings
        complete
        analytics
        draftRecordID
        clientID
        createdBy
        sectionID
        section {
          id
          createdAt
          updatedAt
          complete
          name
          inactive
          noEdit
          analytics
          order
          owner
          settings
          clientID
          createdBy
          goalID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        entrySections {
          nextToken
          startedAt
          __typename
        }
        count
        goalID
        goal {
          id
          owner
          name
          description
          createdAt
          updatedAt
          createdBy
          updatedBy
          targetNumber
          targetFrequency
          targetType
          recurringType
          recurringUntil
          recurringNumber
          color
          sectionID
          entryID
          componentID
          clientID
          order
          complete
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        sectionEntriesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const entriesByDraftRecordID = /* GraphQL */ `
  query EntriesByDraftRecordID(
    $draftRecordID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    entriesByDraftRecordID(
      draftRecordID: $draftRecordID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        owner
        name
        icon
        type
        order
        addLogic
        logic
        inactive
        noEdit
        settings
        complete
        analytics
        draftRecordID
        clientID
        createdBy
        sectionID
        section {
          id
          createdAt
          updatedAt
          complete
          name
          inactive
          noEdit
          analytics
          order
          owner
          settings
          clientID
          createdBy
          goalID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        entrySections {
          nextToken
          startedAt
          __typename
        }
        count
        goalID
        goal {
          id
          owner
          name
          description
          createdAt
          updatedAt
          createdBy
          updatedBy
          targetNumber
          targetFrequency
          targetType
          recurringType
          recurringUntil
          recurringNumber
          color
          sectionID
          entryID
          componentID
          clientID
          order
          complete
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        sectionEntriesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const entriesByClientID = /* GraphQL */ `
  query EntriesByClientID(
    $clientID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    entriesByClientID(
      clientID: $clientID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        owner
        name
        icon
        type
        order
        addLogic
        logic
        inactive
        noEdit
        settings
        complete
        analytics
        draftRecordID
        clientID
        createdBy
        sectionID
        section {
          id
          createdAt
          updatedAt
          complete
          name
          inactive
          noEdit
          analytics
          order
          owner
          settings
          clientID
          createdBy
          goalID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        entrySections {
          nextToken
          startedAt
          __typename
        }
        count
        goalID
        goal {
          id
          owner
          name
          description
          createdAt
          updatedAt
          createdBy
          updatedBy
          targetNumber
          targetFrequency
          targetType
          recurringType
          recurringUntil
          recurringNumber
          color
          sectionID
          entryID
          componentID
          clientID
          order
          complete
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        sectionEntriesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const entriesBySectionByOrder = /* GraphQL */ `
  query EntriesBySectionByOrder(
    $sectionID: ID!
    $order: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    entriesBySectionByOrder(
      sectionID: $sectionID
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        owner
        name
        icon
        type
        order
        addLogic
        logic
        inactive
        noEdit
        settings
        complete
        analytics
        draftRecordID
        clientID
        createdBy
        sectionID
        section {
          id
          createdAt
          updatedAt
          complete
          name
          inactive
          noEdit
          analytics
          order
          owner
          settings
          clientID
          createdBy
          goalID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        entrySections {
          nextToken
          startedAt
          __typename
        }
        count
        goalID
        goal {
          id
          owner
          name
          description
          createdAt
          updatedAt
          createdBy
          updatedBy
          targetNumber
          targetFrequency
          targetType
          recurringType
          recurringUntil
          recurringNumber
          color
          sectionID
          entryID
          componentID
          clientID
          order
          complete
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        sectionEntriesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const entriesByGoalID = /* GraphQL */ `
  query EntriesByGoalID(
    $goalID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    entriesByGoalID(
      goalID: $goalID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        owner
        name
        icon
        type
        order
        addLogic
        logic
        inactive
        noEdit
        settings
        complete
        analytics
        draftRecordID
        clientID
        createdBy
        sectionID
        section {
          id
          createdAt
          updatedAt
          complete
          name
          inactive
          noEdit
          analytics
          order
          owner
          settings
          clientID
          createdBy
          goalID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        entrySections {
          nextToken
          startedAt
          __typename
        }
        count
        goalID
        goal {
          id
          owner
          name
          description
          createdAt
          updatedAt
          createdBy
          updatedBy
          targetNumber
          targetFrequency
          targetType
          recurringType
          recurringUntil
          recurringNumber
          color
          sectionID
          entryID
          componentID
          clientID
          order
          complete
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        sectionEntriesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getEntrySection = /* GraphQL */ `
  query GetEntrySection($id: ID!) {
    getEntrySection(id: $id) {
      id
      createdAt
      updatedAt
      name
      complete
      owner
      order
      noEdit
      settings
      showInfo
      information
      informationTitle
      orientation
      clientID
      entryID
      createdBy
      components {
        items {
          id
          createdAt
          updatedAt
          clientID
          complete
          owner
          type
          order
          settings
          to
          from
          rows
          name
          noEdit
          size
          label
          placeholder
          upload
          options
          content
          boolean
          variant
          prompts
          collapse
          showInfo
          showPrompts
          multiline
          addButton
          multiselect
          information
          instructions
          removeButton
          booleanLabel
          annotationLabels
          entrySectionID
          goalID
          _version
          _deleted
          _lastChangedAt
          entrySectionComponentsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      entryEntrySectionsId
      __typename
    }
  }
`;
export const listEntrySections = /* GraphQL */ `
  query ListEntrySections(
    $filter: ModelEntrySectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEntrySections(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        name
        complete
        owner
        order
        noEdit
        settings
        showInfo
        information
        informationTitle
        orientation
        clientID
        entryID
        createdBy
        components {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        entryEntrySectionsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncEntrySections = /* GraphQL */ `
  query SyncEntrySections(
    $filter: ModelEntrySectionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEntrySections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        createdAt
        updatedAt
        name
        complete
        owner
        order
        noEdit
        settings
        showInfo
        information
        informationTitle
        orientation
        clientID
        entryID
        createdBy
        components {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        entryEntrySectionsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const entrySectionsByClientID = /* GraphQL */ `
  query EntrySectionsByClientID(
    $clientID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEntrySectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    entrySectionsByClientID(
      clientID: $clientID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        name
        complete
        owner
        order
        noEdit
        settings
        showInfo
        information
        informationTitle
        orientation
        clientID
        entryID
        createdBy
        components {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        entryEntrySectionsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const entrySectionsByEntryIDByOrder = /* GraphQL */ `
  query EntrySectionsByEntryIDByOrder(
    $entryID: ID!
    $order: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEntrySectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    entrySectionsByEntryIDByOrder(
      entryID: $entryID
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        name
        complete
        owner
        order
        noEdit
        settings
        showInfo
        information
        informationTitle
        orientation
        clientID
        entryID
        createdBy
        components {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        entryEntrySectionsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getComponent = /* GraphQL */ `
  query GetComponent($id: ID!) {
    getComponent(id: $id) {
      id
      createdAt
      updatedAt
      clientID
      complete
      owner
      type
      order
      settings
      to
      from
      rows
      name
      noEdit
      size
      label
      placeholder
      upload
      options
      content
      boolean
      variant
      prompts
      collapse
      showInfo
      showPrompts
      multiline
      addButton
      multiselect
      information
      instructions
      removeButton
      booleanLabel
      annotationLabels
      userInputs {
        items {
          id
          inputName
          enteredAt
          createdAt
          updatedAt
          owner
          inputValue
          clockValue
          emojiRaterValue
          sliderValue
          numberValue
          incrementerValue
          raterValue
          raterBoolean
          audioCompressionStatus
          audioDurationSeconds
          audioType
          audioReady
          audioRecording
          audioUploadingStatus
          audioMode
          componentType
          componentID
          componentOrder
          recordID
          createdBy
          updatedBy
          clientID
          _version
          _deleted
          _lastChangedAt
          componentUserInputsId
          recordUserInputsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      entrySectionID
      entrySection {
        id
        createdAt
        updatedAt
        name
        complete
        owner
        order
        noEdit
        settings
        showInfo
        information
        informationTitle
        orientation
        clientID
        entryID
        createdBy
        components {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        entryEntrySectionsId
        __typename
      }
      goalID
      goal {
        id
        owner
        name
        description
        createdAt
        updatedAt
        createdBy
        updatedBy
        targetNumber
        targetFrequency
        targetType
        recurringType
        recurringUntil
        recurringNumber
        color
        section {
          id
          createdAt
          updatedAt
          complete
          name
          inactive
          noEdit
          analytics
          order
          owner
          settings
          clientID
          createdBy
          goalID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        sectionID
        entry {
          id
          createdAt
          updatedAt
          owner
          name
          icon
          type
          order
          addLogic
          logic
          inactive
          noEdit
          settings
          complete
          analytics
          draftRecordID
          clientID
          createdBy
          sectionID
          count
          goalID
          _version
          _deleted
          _lastChangedAt
          sectionEntriesId
          __typename
        }
        entryID
        component {
          id
          createdAt
          updatedAt
          clientID
          complete
          owner
          type
          order
          settings
          to
          from
          rows
          name
          noEdit
          size
          label
          placeholder
          upload
          options
          content
          boolean
          variant
          prompts
          collapse
          showInfo
          showPrompts
          multiline
          addButton
          multiselect
          information
          instructions
          removeButton
          booleanLabel
          annotationLabels
          entrySectionID
          goalID
          _version
          _deleted
          _lastChangedAt
          entrySectionComponentsId
          __typename
        }
        componentID
        clientID
        order
        goalEntries {
          nextToken
          startedAt
          __typename
        }
        complete
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      infoContent {
        items {
          id
          owner
          title
          content
          widget
          componentID
          clientID
          order
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          componentInfoContentId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      entrySectionComponentsId
      __typename
    }
  }
`;
export const listComponents = /* GraphQL */ `
  query ListComponents(
    $filter: ModelComponentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComponents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        clientID
        complete
        owner
        type
        order
        settings
        to
        from
        rows
        name
        noEdit
        size
        label
        placeholder
        upload
        options
        content
        boolean
        variant
        prompts
        collapse
        showInfo
        showPrompts
        multiline
        addButton
        multiselect
        information
        instructions
        removeButton
        booleanLabel
        annotationLabels
        userInputs {
          nextToken
          startedAt
          __typename
        }
        entrySectionID
        entrySection {
          id
          createdAt
          updatedAt
          name
          complete
          owner
          order
          noEdit
          settings
          showInfo
          information
          informationTitle
          orientation
          clientID
          entryID
          createdBy
          _version
          _deleted
          _lastChangedAt
          entryEntrySectionsId
          __typename
        }
        goalID
        goal {
          id
          owner
          name
          description
          createdAt
          updatedAt
          createdBy
          updatedBy
          targetNumber
          targetFrequency
          targetType
          recurringType
          recurringUntil
          recurringNumber
          color
          sectionID
          entryID
          componentID
          clientID
          order
          complete
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        infoContent {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        entrySectionComponentsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncComponents = /* GraphQL */ `
  query SyncComponents(
    $filter: ModelComponentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncComponents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        createdAt
        updatedAt
        clientID
        complete
        owner
        type
        order
        settings
        to
        from
        rows
        name
        noEdit
        size
        label
        placeholder
        upload
        options
        content
        boolean
        variant
        prompts
        collapse
        showInfo
        showPrompts
        multiline
        addButton
        multiselect
        information
        instructions
        removeButton
        booleanLabel
        annotationLabels
        userInputs {
          nextToken
          startedAt
          __typename
        }
        entrySectionID
        entrySection {
          id
          createdAt
          updatedAt
          name
          complete
          owner
          order
          noEdit
          settings
          showInfo
          information
          informationTitle
          orientation
          clientID
          entryID
          createdBy
          _version
          _deleted
          _lastChangedAt
          entryEntrySectionsId
          __typename
        }
        goalID
        goal {
          id
          owner
          name
          description
          createdAt
          updatedAt
          createdBy
          updatedBy
          targetNumber
          targetFrequency
          targetType
          recurringType
          recurringUntil
          recurringNumber
          color
          sectionID
          entryID
          componentID
          clientID
          order
          complete
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        infoContent {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        entrySectionComponentsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const componentsByClientID = /* GraphQL */ `
  query ComponentsByClientID(
    $clientID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelComponentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    componentsByClientID(
      clientID: $clientID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        clientID
        complete
        owner
        type
        order
        settings
        to
        from
        rows
        name
        noEdit
        size
        label
        placeholder
        upload
        options
        content
        boolean
        variant
        prompts
        collapse
        showInfo
        showPrompts
        multiline
        addButton
        multiselect
        information
        instructions
        removeButton
        booleanLabel
        annotationLabels
        userInputs {
          nextToken
          startedAt
          __typename
        }
        entrySectionID
        entrySection {
          id
          createdAt
          updatedAt
          name
          complete
          owner
          order
          noEdit
          settings
          showInfo
          information
          informationTitle
          orientation
          clientID
          entryID
          createdBy
          _version
          _deleted
          _lastChangedAt
          entryEntrySectionsId
          __typename
        }
        goalID
        goal {
          id
          owner
          name
          description
          createdAt
          updatedAt
          createdBy
          updatedBy
          targetNumber
          targetFrequency
          targetType
          recurringType
          recurringUntil
          recurringNumber
          color
          sectionID
          entryID
          componentID
          clientID
          order
          complete
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        infoContent {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        entrySectionComponentsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const componentsByEntrySectionByOrder = /* GraphQL */ `
  query ComponentsByEntrySectionByOrder(
    $entrySectionID: ID!
    $order: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelComponentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    componentsByEntrySectionByOrder(
      entrySectionID: $entrySectionID
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        clientID
        complete
        owner
        type
        order
        settings
        to
        from
        rows
        name
        noEdit
        size
        label
        placeholder
        upload
        options
        content
        boolean
        variant
        prompts
        collapse
        showInfo
        showPrompts
        multiline
        addButton
        multiselect
        information
        instructions
        removeButton
        booleanLabel
        annotationLabels
        userInputs {
          nextToken
          startedAt
          __typename
        }
        entrySectionID
        entrySection {
          id
          createdAt
          updatedAt
          name
          complete
          owner
          order
          noEdit
          settings
          showInfo
          information
          informationTitle
          orientation
          clientID
          entryID
          createdBy
          _version
          _deleted
          _lastChangedAt
          entryEntrySectionsId
          __typename
        }
        goalID
        goal {
          id
          owner
          name
          description
          createdAt
          updatedAt
          createdBy
          updatedBy
          targetNumber
          targetFrequency
          targetType
          recurringType
          recurringUntil
          recurringNumber
          color
          sectionID
          entryID
          componentID
          clientID
          order
          complete
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        infoContent {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        entrySectionComponentsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const componentsByGoalID = /* GraphQL */ `
  query ComponentsByGoalID(
    $goalID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelComponentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    componentsByGoalID(
      goalID: $goalID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        clientID
        complete
        owner
        type
        order
        settings
        to
        from
        rows
        name
        noEdit
        size
        label
        placeholder
        upload
        options
        content
        boolean
        variant
        prompts
        collapse
        showInfo
        showPrompts
        multiline
        addButton
        multiselect
        information
        instructions
        removeButton
        booleanLabel
        annotationLabels
        userInputs {
          nextToken
          startedAt
          __typename
        }
        entrySectionID
        entrySection {
          id
          createdAt
          updatedAt
          name
          complete
          owner
          order
          noEdit
          settings
          showInfo
          information
          informationTitle
          orientation
          clientID
          entryID
          createdBy
          _version
          _deleted
          _lastChangedAt
          entryEntrySectionsId
          __typename
        }
        goalID
        goal {
          id
          owner
          name
          description
          createdAt
          updatedAt
          createdBy
          updatedBy
          targetNumber
          targetFrequency
          targetType
          recurringType
          recurringUntil
          recurringNumber
          color
          sectionID
          entryID
          componentID
          clientID
          order
          complete
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        infoContent {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        entrySectionComponentsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getInfoContent = /* GraphQL */ `
  query GetInfoContent($id: ID!) {
    getInfoContent(id: $id) {
      id
      owner
      title
      content
      widget
      componentID
      clientID
      order
      component {
        id
        createdAt
        updatedAt
        clientID
        complete
        owner
        type
        order
        settings
        to
        from
        rows
        name
        noEdit
        size
        label
        placeholder
        upload
        options
        content
        boolean
        variant
        prompts
        collapse
        showInfo
        showPrompts
        multiline
        addButton
        multiselect
        information
        instructions
        removeButton
        booleanLabel
        annotationLabels
        userInputs {
          nextToken
          startedAt
          __typename
        }
        entrySectionID
        entrySection {
          id
          createdAt
          updatedAt
          name
          complete
          owner
          order
          noEdit
          settings
          showInfo
          information
          informationTitle
          orientation
          clientID
          entryID
          createdBy
          _version
          _deleted
          _lastChangedAt
          entryEntrySectionsId
          __typename
        }
        goalID
        goal {
          id
          owner
          name
          description
          createdAt
          updatedAt
          createdBy
          updatedBy
          targetNumber
          targetFrequency
          targetType
          recurringType
          recurringUntil
          recurringNumber
          color
          sectionID
          entryID
          componentID
          clientID
          order
          complete
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        infoContent {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        entrySectionComponentsId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      componentInfoContentId
      __typename
    }
  }
`;
export const listInfoContents = /* GraphQL */ `
  query ListInfoContents(
    $filter: ModelInfoContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInfoContents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        title
        content
        widget
        componentID
        clientID
        order
        component {
          id
          createdAt
          updatedAt
          clientID
          complete
          owner
          type
          order
          settings
          to
          from
          rows
          name
          noEdit
          size
          label
          placeholder
          upload
          options
          content
          boolean
          variant
          prompts
          collapse
          showInfo
          showPrompts
          multiline
          addButton
          multiselect
          information
          instructions
          removeButton
          booleanLabel
          annotationLabels
          entrySectionID
          goalID
          _version
          _deleted
          _lastChangedAt
          entrySectionComponentsId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        componentInfoContentId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncInfoContents = /* GraphQL */ `
  query SyncInfoContents(
    $filter: ModelInfoContentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncInfoContents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        title
        content
        widget
        componentID
        clientID
        order
        component {
          id
          createdAt
          updatedAt
          clientID
          complete
          owner
          type
          order
          settings
          to
          from
          rows
          name
          noEdit
          size
          label
          placeholder
          upload
          options
          content
          boolean
          variant
          prompts
          collapse
          showInfo
          showPrompts
          multiline
          addButton
          multiselect
          information
          instructions
          removeButton
          booleanLabel
          annotationLabels
          entrySectionID
          goalID
          _version
          _deleted
          _lastChangedAt
          entrySectionComponentsId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        componentInfoContentId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const infoContentByOrder = /* GraphQL */ `
  query InfoContentByOrder(
    $componentID: ID!
    $order: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInfoContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    infoContentByOrder(
      componentID: $componentID
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        title
        content
        widget
        componentID
        clientID
        order
        component {
          id
          createdAt
          updatedAt
          clientID
          complete
          owner
          type
          order
          settings
          to
          from
          rows
          name
          noEdit
          size
          label
          placeholder
          upload
          options
          content
          boolean
          variant
          prompts
          collapse
          showInfo
          showPrompts
          multiline
          addButton
          multiselect
          information
          instructions
          removeButton
          booleanLabel
          annotationLabels
          entrySectionID
          goalID
          _version
          _deleted
          _lastChangedAt
          entrySectionComponentsId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        componentInfoContentId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const infoContentsByClientID = /* GraphQL */ `
  query InfoContentsByClientID(
    $clientID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInfoContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    infoContentsByClientID(
      clientID: $clientID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        title
        content
        widget
        componentID
        clientID
        order
        component {
          id
          createdAt
          updatedAt
          clientID
          complete
          owner
          type
          order
          settings
          to
          from
          rows
          name
          noEdit
          size
          label
          placeholder
          upload
          options
          content
          boolean
          variant
          prompts
          collapse
          showInfo
          showPrompts
          multiline
          addButton
          multiselect
          information
          instructions
          removeButton
          booleanLabel
          annotationLabels
          entrySectionID
          goalID
          _version
          _deleted
          _lastChangedAt
          entrySectionComponentsId
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        componentInfoContentId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUserInput = /* GraphQL */ `
  query GetUserInput($id: ID!) {
    getUserInput(id: $id) {
      id
      inputName
      enteredAt
      createdAt
      updatedAt
      owner
      inputValue
      clockValue
      checkboxesValue {
        id
        label
        order
        __typename
      }
      emojiRaterValue
      radiosValue {
        id
        label
        order
        __typename
      }
      sliderValue
      numberValue
      incrementerValue
      chipsValue {
        id
        label
        order
        __typename
      }
      raterValue
      raterBoolean
      audioCompressionStatus
      audioDurationSeconds
      audioType
      audioReady
      audioRecording
      audioUploadingStatus
      audioMode
      componentType
      componentID
      componentOrder
      component {
        id
        createdAt
        updatedAt
        clientID
        complete
        owner
        type
        order
        settings
        to
        from
        rows
        name
        noEdit
        size
        label
        placeholder
        upload
        options
        content
        boolean
        variant
        prompts
        collapse
        showInfo
        showPrompts
        multiline
        addButton
        multiselect
        information
        instructions
        removeButton
        booleanLabel
        annotationLabels
        userInputs {
          nextToken
          startedAt
          __typename
        }
        entrySectionID
        entrySection {
          id
          createdAt
          updatedAt
          name
          complete
          owner
          order
          noEdit
          settings
          showInfo
          information
          informationTitle
          orientation
          clientID
          entryID
          createdBy
          _version
          _deleted
          _lastChangedAt
          entryEntrySectionsId
          __typename
        }
        goalID
        goal {
          id
          owner
          name
          description
          createdAt
          updatedAt
          createdBy
          updatedBy
          targetNumber
          targetFrequency
          targetType
          recurringType
          recurringUntil
          recurringNumber
          color
          sectionID
          entryID
          componentID
          clientID
          order
          complete
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        infoContent {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        entrySectionComponentsId
        __typename
      }
      recordID
      record {
        id
        enteredAt
        createdAt
        updatedAt
        updatedBy
        owner
        hasUpdatedGoalEntry
        hasData
        draft
        createdBy
        editing
        sectionID
        entryID
        entry {
          id
          createdAt
          updatedAt
          owner
          name
          icon
          type
          order
          addLogic
          logic
          inactive
          noEdit
          settings
          complete
          analytics
          draftRecordID
          clientID
          createdBy
          sectionID
          count
          goalID
          _version
          _deleted
          _lastChangedAt
          sectionEntriesId
          __typename
        }
        clientID
        userInputs {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      annotations {
        items {
          id
          categories
          createdAt
          updatedAt
          owner
          createdBy
          updatedBy
          userInputID
          isActive
          isHighlight
          mode
          note
          regionTotalDuration
          regionMinLength
          regionMaxLength
          regionID
          regionStart
          regionEnd
          regionDrag
          regionResize
          regionColor
          clientID
          _version
          _deleted
          _lastChangedAt
          userInputAnnotationsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdBy
      updatedBy
      clientID
      _version
      _deleted
      _lastChangedAt
      componentUserInputsId
      recordUserInputsId
      __typename
    }
  }
`;
export const listUserInputs = /* GraphQL */ `
  query ListUserInputs(
    $filter: ModelUserInputFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserInputs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        inputName
        enteredAt
        createdAt
        updatedAt
        owner
        inputValue
        clockValue
        checkboxesValue {
          id
          label
          order
          __typename
        }
        emojiRaterValue
        radiosValue {
          id
          label
          order
          __typename
        }
        sliderValue
        numberValue
        incrementerValue
        chipsValue {
          id
          label
          order
          __typename
        }
        raterValue
        raterBoolean
        audioCompressionStatus
        audioDurationSeconds
        audioType
        audioReady
        audioRecording
        audioUploadingStatus
        audioMode
        componentType
        componentID
        componentOrder
        component {
          id
          createdAt
          updatedAt
          clientID
          complete
          owner
          type
          order
          settings
          to
          from
          rows
          name
          noEdit
          size
          label
          placeholder
          upload
          options
          content
          boolean
          variant
          prompts
          collapse
          showInfo
          showPrompts
          multiline
          addButton
          multiselect
          information
          instructions
          removeButton
          booleanLabel
          annotationLabels
          entrySectionID
          goalID
          _version
          _deleted
          _lastChangedAt
          entrySectionComponentsId
          __typename
        }
        recordID
        record {
          id
          enteredAt
          createdAt
          updatedAt
          updatedBy
          owner
          hasUpdatedGoalEntry
          hasData
          draft
          createdBy
          editing
          sectionID
          entryID
          clientID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        annotations {
          nextToken
          startedAt
          __typename
        }
        createdBy
        updatedBy
        clientID
        _version
        _deleted
        _lastChangedAt
        componentUserInputsId
        recordUserInputsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUserInputs = /* GraphQL */ `
  query SyncUserInputs(
    $filter: ModelUserInputFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserInputs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        inputName
        enteredAt
        createdAt
        updatedAt
        owner
        inputValue
        clockValue
        checkboxesValue {
          id
          label
          order
          __typename
        }
        emojiRaterValue
        radiosValue {
          id
          label
          order
          __typename
        }
        sliderValue
        numberValue
        incrementerValue
        chipsValue {
          id
          label
          order
          __typename
        }
        raterValue
        raterBoolean
        audioCompressionStatus
        audioDurationSeconds
        audioType
        audioReady
        audioRecording
        audioUploadingStatus
        audioMode
        componentType
        componentID
        componentOrder
        component {
          id
          createdAt
          updatedAt
          clientID
          complete
          owner
          type
          order
          settings
          to
          from
          rows
          name
          noEdit
          size
          label
          placeholder
          upload
          options
          content
          boolean
          variant
          prompts
          collapse
          showInfo
          showPrompts
          multiline
          addButton
          multiselect
          information
          instructions
          removeButton
          booleanLabel
          annotationLabels
          entrySectionID
          goalID
          _version
          _deleted
          _lastChangedAt
          entrySectionComponentsId
          __typename
        }
        recordID
        record {
          id
          enteredAt
          createdAt
          updatedAt
          updatedBy
          owner
          hasUpdatedGoalEntry
          hasData
          draft
          createdBy
          editing
          sectionID
          entryID
          clientID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        annotations {
          nextToken
          startedAt
          __typename
        }
        createdBy
        updatedBy
        clientID
        _version
        _deleted
        _lastChangedAt
        componentUserInputsId
        recordUserInputsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const userInputsByComponentType = /* GraphQL */ `
  query UserInputsByComponentType(
    $componentType: ComponentType!
    $sortDirection: ModelSortDirection
    $filter: ModelUserInputFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userInputsByComponentType(
      componentType: $componentType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        inputName
        enteredAt
        createdAt
        updatedAt
        owner
        inputValue
        clockValue
        checkboxesValue {
          id
          label
          order
          __typename
        }
        emojiRaterValue
        radiosValue {
          id
          label
          order
          __typename
        }
        sliderValue
        numberValue
        incrementerValue
        chipsValue {
          id
          label
          order
          __typename
        }
        raterValue
        raterBoolean
        audioCompressionStatus
        audioDurationSeconds
        audioType
        audioReady
        audioRecording
        audioUploadingStatus
        audioMode
        componentType
        componentID
        componentOrder
        component {
          id
          createdAt
          updatedAt
          clientID
          complete
          owner
          type
          order
          settings
          to
          from
          rows
          name
          noEdit
          size
          label
          placeholder
          upload
          options
          content
          boolean
          variant
          prompts
          collapse
          showInfo
          showPrompts
          multiline
          addButton
          multiselect
          information
          instructions
          removeButton
          booleanLabel
          annotationLabels
          entrySectionID
          goalID
          _version
          _deleted
          _lastChangedAt
          entrySectionComponentsId
          __typename
        }
        recordID
        record {
          id
          enteredAt
          createdAt
          updatedAt
          updatedBy
          owner
          hasUpdatedGoalEntry
          hasData
          draft
          createdBy
          editing
          sectionID
          entryID
          clientID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        annotations {
          nextToken
          startedAt
          __typename
        }
        createdBy
        updatedBy
        clientID
        _version
        _deleted
        _lastChangedAt
        componentUserInputsId
        recordUserInputsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const userInputsByComponentID = /* GraphQL */ `
  query UserInputsByComponentID(
    $componentID: ID!
    $enteredAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserInputFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userInputsByComponentID(
      componentID: $componentID
      enteredAt: $enteredAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        inputName
        enteredAt
        createdAt
        updatedAt
        owner
        inputValue
        clockValue
        checkboxesValue {
          id
          label
          order
          __typename
        }
        emojiRaterValue
        radiosValue {
          id
          label
          order
          __typename
        }
        sliderValue
        numberValue
        incrementerValue
        chipsValue {
          id
          label
          order
          __typename
        }
        raterValue
        raterBoolean
        audioCompressionStatus
        audioDurationSeconds
        audioType
        audioReady
        audioRecording
        audioUploadingStatus
        audioMode
        componentType
        componentID
        componentOrder
        component {
          id
          createdAt
          updatedAt
          clientID
          complete
          owner
          type
          order
          settings
          to
          from
          rows
          name
          noEdit
          size
          label
          placeholder
          upload
          options
          content
          boolean
          variant
          prompts
          collapse
          showInfo
          showPrompts
          multiline
          addButton
          multiselect
          information
          instructions
          removeButton
          booleanLabel
          annotationLabels
          entrySectionID
          goalID
          _version
          _deleted
          _lastChangedAt
          entrySectionComponentsId
          __typename
        }
        recordID
        record {
          id
          enteredAt
          createdAt
          updatedAt
          updatedBy
          owner
          hasUpdatedGoalEntry
          hasData
          draft
          createdBy
          editing
          sectionID
          entryID
          clientID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        annotations {
          nextToken
          startedAt
          __typename
        }
        createdBy
        updatedBy
        clientID
        _version
        _deleted
        _lastChangedAt
        componentUserInputsId
        recordUserInputsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const userInputsByComponentOrder = /* GraphQL */ `
  query UserInputsByComponentOrder(
    $componentOrder: Float!
    $sortDirection: ModelSortDirection
    $filter: ModelUserInputFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userInputsByComponentOrder(
      componentOrder: $componentOrder
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        inputName
        enteredAt
        createdAt
        updatedAt
        owner
        inputValue
        clockValue
        checkboxesValue {
          id
          label
          order
          __typename
        }
        emojiRaterValue
        radiosValue {
          id
          label
          order
          __typename
        }
        sliderValue
        numberValue
        incrementerValue
        chipsValue {
          id
          label
          order
          __typename
        }
        raterValue
        raterBoolean
        audioCompressionStatus
        audioDurationSeconds
        audioType
        audioReady
        audioRecording
        audioUploadingStatus
        audioMode
        componentType
        componentID
        componentOrder
        component {
          id
          createdAt
          updatedAt
          clientID
          complete
          owner
          type
          order
          settings
          to
          from
          rows
          name
          noEdit
          size
          label
          placeholder
          upload
          options
          content
          boolean
          variant
          prompts
          collapse
          showInfo
          showPrompts
          multiline
          addButton
          multiselect
          information
          instructions
          removeButton
          booleanLabel
          annotationLabels
          entrySectionID
          goalID
          _version
          _deleted
          _lastChangedAt
          entrySectionComponentsId
          __typename
        }
        recordID
        record {
          id
          enteredAt
          createdAt
          updatedAt
          updatedBy
          owner
          hasUpdatedGoalEntry
          hasData
          draft
          createdBy
          editing
          sectionID
          entryID
          clientID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        annotations {
          nextToken
          startedAt
          __typename
        }
        createdBy
        updatedBy
        clientID
        _version
        _deleted
        _lastChangedAt
        componentUserInputsId
        recordUserInputsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const userInputsByRecordComponentType = /* GraphQL */ `
  query UserInputsByRecordComponentType(
    $recordID: ID!
    $componentType: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserInputFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userInputsByRecordComponentType(
      recordID: $recordID
      componentType: $componentType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        inputName
        enteredAt
        createdAt
        updatedAt
        owner
        inputValue
        clockValue
        checkboxesValue {
          id
          label
          order
          __typename
        }
        emojiRaterValue
        radiosValue {
          id
          label
          order
          __typename
        }
        sliderValue
        numberValue
        incrementerValue
        chipsValue {
          id
          label
          order
          __typename
        }
        raterValue
        raterBoolean
        audioCompressionStatus
        audioDurationSeconds
        audioType
        audioReady
        audioRecording
        audioUploadingStatus
        audioMode
        componentType
        componentID
        componentOrder
        component {
          id
          createdAt
          updatedAt
          clientID
          complete
          owner
          type
          order
          settings
          to
          from
          rows
          name
          noEdit
          size
          label
          placeholder
          upload
          options
          content
          boolean
          variant
          prompts
          collapse
          showInfo
          showPrompts
          multiline
          addButton
          multiselect
          information
          instructions
          removeButton
          booleanLabel
          annotationLabels
          entrySectionID
          goalID
          _version
          _deleted
          _lastChangedAt
          entrySectionComponentsId
          __typename
        }
        recordID
        record {
          id
          enteredAt
          createdAt
          updatedAt
          updatedBy
          owner
          hasUpdatedGoalEntry
          hasData
          draft
          createdBy
          editing
          sectionID
          entryID
          clientID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        annotations {
          nextToken
          startedAt
          __typename
        }
        createdBy
        updatedBy
        clientID
        _version
        _deleted
        _lastChangedAt
        componentUserInputsId
        recordUserInputsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const userInputsByClientID = /* GraphQL */ `
  query UserInputsByClientID(
    $clientID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserInputFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userInputsByClientID(
      clientID: $clientID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        inputName
        enteredAt
        createdAt
        updatedAt
        owner
        inputValue
        clockValue
        checkboxesValue {
          id
          label
          order
          __typename
        }
        emojiRaterValue
        radiosValue {
          id
          label
          order
          __typename
        }
        sliderValue
        numberValue
        incrementerValue
        chipsValue {
          id
          label
          order
          __typename
        }
        raterValue
        raterBoolean
        audioCompressionStatus
        audioDurationSeconds
        audioType
        audioReady
        audioRecording
        audioUploadingStatus
        audioMode
        componentType
        componentID
        componentOrder
        component {
          id
          createdAt
          updatedAt
          clientID
          complete
          owner
          type
          order
          settings
          to
          from
          rows
          name
          noEdit
          size
          label
          placeholder
          upload
          options
          content
          boolean
          variant
          prompts
          collapse
          showInfo
          showPrompts
          multiline
          addButton
          multiselect
          information
          instructions
          removeButton
          booleanLabel
          annotationLabels
          entrySectionID
          goalID
          _version
          _deleted
          _lastChangedAt
          entrySectionComponentsId
          __typename
        }
        recordID
        record {
          id
          enteredAt
          createdAt
          updatedAt
          updatedBy
          owner
          hasUpdatedGoalEntry
          hasData
          draft
          createdBy
          editing
          sectionID
          entryID
          clientID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        annotations {
          nextToken
          startedAt
          __typename
        }
        createdBy
        updatedBy
        clientID
        _version
        _deleted
        _lastChangedAt
        componentUserInputsId
        recordUserInputsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAnnotation = /* GraphQL */ `
  query GetAnnotation($id: ID!) {
    getAnnotation(id: $id) {
      id
      categories
      createdAt
      updatedAt
      owner
      createdBy
      updatedBy
      userInputID
      userInput {
        id
        inputName
        enteredAt
        createdAt
        updatedAt
        owner
        inputValue
        clockValue
        checkboxesValue {
          id
          label
          order
          __typename
        }
        emojiRaterValue
        radiosValue {
          id
          label
          order
          __typename
        }
        sliderValue
        numberValue
        incrementerValue
        chipsValue {
          id
          label
          order
          __typename
        }
        raterValue
        raterBoolean
        audioCompressionStatus
        audioDurationSeconds
        audioType
        audioReady
        audioRecording
        audioUploadingStatus
        audioMode
        componentType
        componentID
        componentOrder
        component {
          id
          createdAt
          updatedAt
          clientID
          complete
          owner
          type
          order
          settings
          to
          from
          rows
          name
          noEdit
          size
          label
          placeholder
          upload
          options
          content
          boolean
          variant
          prompts
          collapse
          showInfo
          showPrompts
          multiline
          addButton
          multiselect
          information
          instructions
          removeButton
          booleanLabel
          annotationLabels
          entrySectionID
          goalID
          _version
          _deleted
          _lastChangedAt
          entrySectionComponentsId
          __typename
        }
        recordID
        record {
          id
          enteredAt
          createdAt
          updatedAt
          updatedBy
          owner
          hasUpdatedGoalEntry
          hasData
          draft
          createdBy
          editing
          sectionID
          entryID
          clientID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        annotations {
          nextToken
          startedAt
          __typename
        }
        createdBy
        updatedBy
        clientID
        _version
        _deleted
        _lastChangedAt
        componentUserInputsId
        recordUserInputsId
        __typename
      }
      isActive
      isHighlight
      mode
      note
      regionTotalDuration
      regionMinLength
      regionMaxLength
      regionID
      regionStart
      regionEnd
      regionDrag
      regionResize
      regionColor
      clientID
      _version
      _deleted
      _lastChangedAt
      userInputAnnotationsId
      __typename
    }
  }
`;
export const listAnnotations = /* GraphQL */ `
  query ListAnnotations(
    $filter: ModelAnnotationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnnotations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        categories
        createdAt
        updatedAt
        owner
        createdBy
        updatedBy
        userInputID
        userInput {
          id
          inputName
          enteredAt
          createdAt
          updatedAt
          owner
          inputValue
          clockValue
          emojiRaterValue
          sliderValue
          numberValue
          incrementerValue
          raterValue
          raterBoolean
          audioCompressionStatus
          audioDurationSeconds
          audioType
          audioReady
          audioRecording
          audioUploadingStatus
          audioMode
          componentType
          componentID
          componentOrder
          recordID
          createdBy
          updatedBy
          clientID
          _version
          _deleted
          _lastChangedAt
          componentUserInputsId
          recordUserInputsId
          __typename
        }
        isActive
        isHighlight
        mode
        note
        regionTotalDuration
        regionMinLength
        regionMaxLength
        regionID
        regionStart
        regionEnd
        regionDrag
        regionResize
        regionColor
        clientID
        _version
        _deleted
        _lastChangedAt
        userInputAnnotationsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAnnotations = /* GraphQL */ `
  query SyncAnnotations(
    $filter: ModelAnnotationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAnnotations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        categories
        createdAt
        updatedAt
        owner
        createdBy
        updatedBy
        userInputID
        userInput {
          id
          inputName
          enteredAt
          createdAt
          updatedAt
          owner
          inputValue
          clockValue
          emojiRaterValue
          sliderValue
          numberValue
          incrementerValue
          raterValue
          raterBoolean
          audioCompressionStatus
          audioDurationSeconds
          audioType
          audioReady
          audioRecording
          audioUploadingStatus
          audioMode
          componentType
          componentID
          componentOrder
          recordID
          createdBy
          updatedBy
          clientID
          _version
          _deleted
          _lastChangedAt
          componentUserInputsId
          recordUserInputsId
          __typename
        }
        isActive
        isHighlight
        mode
        note
        regionTotalDuration
        regionMinLength
        regionMaxLength
        regionID
        regionStart
        regionEnd
        regionDrag
        regionResize
        regionColor
        clientID
        _version
        _deleted
        _lastChangedAt
        userInputAnnotationsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const annotationsByCreatedAt = /* GraphQL */ `
  query AnnotationsByCreatedAt(
    $createdAt: AWSDateTime!
    $sortDirection: ModelSortDirection
    $filter: ModelAnnotationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    annotationsByCreatedAt(
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        categories
        createdAt
        updatedAt
        owner
        createdBy
        updatedBy
        userInputID
        userInput {
          id
          inputName
          enteredAt
          createdAt
          updatedAt
          owner
          inputValue
          clockValue
          emojiRaterValue
          sliderValue
          numberValue
          incrementerValue
          raterValue
          raterBoolean
          audioCompressionStatus
          audioDurationSeconds
          audioType
          audioReady
          audioRecording
          audioUploadingStatus
          audioMode
          componentType
          componentID
          componentOrder
          recordID
          createdBy
          updatedBy
          clientID
          _version
          _deleted
          _lastChangedAt
          componentUserInputsId
          recordUserInputsId
          __typename
        }
        isActive
        isHighlight
        mode
        note
        regionTotalDuration
        regionMinLength
        regionMaxLength
        regionID
        regionStart
        regionEnd
        regionDrag
        regionResize
        regionColor
        clientID
        _version
        _deleted
        _lastChangedAt
        userInputAnnotationsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const annotationsByCreatedBy = /* GraphQL */ `
  query AnnotationsByCreatedBy(
    $createdBy: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAnnotationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    annotationsByCreatedBy(
      createdBy: $createdBy
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        categories
        createdAt
        updatedAt
        owner
        createdBy
        updatedBy
        userInputID
        userInput {
          id
          inputName
          enteredAt
          createdAt
          updatedAt
          owner
          inputValue
          clockValue
          emojiRaterValue
          sliderValue
          numberValue
          incrementerValue
          raterValue
          raterBoolean
          audioCompressionStatus
          audioDurationSeconds
          audioType
          audioReady
          audioRecording
          audioUploadingStatus
          audioMode
          componentType
          componentID
          componentOrder
          recordID
          createdBy
          updatedBy
          clientID
          _version
          _deleted
          _lastChangedAt
          componentUserInputsId
          recordUserInputsId
          __typename
        }
        isActive
        isHighlight
        mode
        note
        regionTotalDuration
        regionMinLength
        regionMaxLength
        regionID
        regionStart
        regionEnd
        regionDrag
        regionResize
        regionColor
        clientID
        _version
        _deleted
        _lastChangedAt
        userInputAnnotationsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const annotationsByUpdatedBy = /* GraphQL */ `
  query AnnotationsByUpdatedBy(
    $updatedBy: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAnnotationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    annotationsByUpdatedBy(
      updatedBy: $updatedBy
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        categories
        createdAt
        updatedAt
        owner
        createdBy
        updatedBy
        userInputID
        userInput {
          id
          inputName
          enteredAt
          createdAt
          updatedAt
          owner
          inputValue
          clockValue
          emojiRaterValue
          sliderValue
          numberValue
          incrementerValue
          raterValue
          raterBoolean
          audioCompressionStatus
          audioDurationSeconds
          audioType
          audioReady
          audioRecording
          audioUploadingStatus
          audioMode
          componentType
          componentID
          componentOrder
          recordID
          createdBy
          updatedBy
          clientID
          _version
          _deleted
          _lastChangedAt
          componentUserInputsId
          recordUserInputsId
          __typename
        }
        isActive
        isHighlight
        mode
        note
        regionTotalDuration
        regionMinLength
        regionMaxLength
        regionID
        regionStart
        regionEnd
        regionDrag
        regionResize
        regionColor
        clientID
        _version
        _deleted
        _lastChangedAt
        userInputAnnotationsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const annotationsByUserInputID = /* GraphQL */ `
  query AnnotationsByUserInputID(
    $userInputID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAnnotationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    annotationsByUserInputID(
      userInputID: $userInputID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        categories
        createdAt
        updatedAt
        owner
        createdBy
        updatedBy
        userInputID
        userInput {
          id
          inputName
          enteredAt
          createdAt
          updatedAt
          owner
          inputValue
          clockValue
          emojiRaterValue
          sliderValue
          numberValue
          incrementerValue
          raterValue
          raterBoolean
          audioCompressionStatus
          audioDurationSeconds
          audioType
          audioReady
          audioRecording
          audioUploadingStatus
          audioMode
          componentType
          componentID
          componentOrder
          recordID
          createdBy
          updatedBy
          clientID
          _version
          _deleted
          _lastChangedAt
          componentUserInputsId
          recordUserInputsId
          __typename
        }
        isActive
        isHighlight
        mode
        note
        regionTotalDuration
        regionMinLength
        regionMaxLength
        regionID
        regionStart
        regionEnd
        regionDrag
        regionResize
        regionColor
        clientID
        _version
        _deleted
        _lastChangedAt
        userInputAnnotationsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const annotationsByRegionID = /* GraphQL */ `
  query AnnotationsByRegionID(
    $regionID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAnnotationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    annotationsByRegionID(
      regionID: $regionID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        categories
        createdAt
        updatedAt
        owner
        createdBy
        updatedBy
        userInputID
        userInput {
          id
          inputName
          enteredAt
          createdAt
          updatedAt
          owner
          inputValue
          clockValue
          emojiRaterValue
          sliderValue
          numberValue
          incrementerValue
          raterValue
          raterBoolean
          audioCompressionStatus
          audioDurationSeconds
          audioType
          audioReady
          audioRecording
          audioUploadingStatus
          audioMode
          componentType
          componentID
          componentOrder
          recordID
          createdBy
          updatedBy
          clientID
          _version
          _deleted
          _lastChangedAt
          componentUserInputsId
          recordUserInputsId
          __typename
        }
        isActive
        isHighlight
        mode
        note
        regionTotalDuration
        regionMinLength
        regionMaxLength
        regionID
        regionStart
        regionEnd
        regionDrag
        regionResize
        regionColor
        clientID
        _version
        _deleted
        _lastChangedAt
        userInputAnnotationsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const annotationsByClientID = /* GraphQL */ `
  query AnnotationsByClientID(
    $clientID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAnnotationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    annotationsByClientID(
      clientID: $clientID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        categories
        createdAt
        updatedAt
        owner
        createdBy
        updatedBy
        userInputID
        userInput {
          id
          inputName
          enteredAt
          createdAt
          updatedAt
          owner
          inputValue
          clockValue
          emojiRaterValue
          sliderValue
          numberValue
          incrementerValue
          raterValue
          raterBoolean
          audioCompressionStatus
          audioDurationSeconds
          audioType
          audioReady
          audioRecording
          audioUploadingStatus
          audioMode
          componentType
          componentID
          componentOrder
          recordID
          createdBy
          updatedBy
          clientID
          _version
          _deleted
          _lastChangedAt
          componentUserInputsId
          recordUserInputsId
          __typename
        }
        isActive
        isHighlight
        mode
        note
        regionTotalDuration
        regionMinLength
        regionMaxLength
        regionID
        regionStart
        regionEnd
        regionDrag
        regionResize
        regionColor
        clientID
        _version
        _deleted
        _lastChangedAt
        userInputAnnotationsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getRecord = /* GraphQL */ `
  query GetRecord($id: ID!) {
    getRecord(id: $id) {
      id
      enteredAt
      createdAt
      updatedAt
      updatedBy
      owner
      hasUpdatedGoalEntry
      hasData
      draft
      createdBy
      editing
      sectionID
      entryID
      entry {
        id
        createdAt
        updatedAt
        owner
        name
        icon
        type
        order
        addLogic
        logic
        inactive
        noEdit
        settings
        complete
        analytics
        draftRecordID
        clientID
        createdBy
        sectionID
        section {
          id
          createdAt
          updatedAt
          complete
          name
          inactive
          noEdit
          analytics
          order
          owner
          settings
          clientID
          createdBy
          goalID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        entrySections {
          nextToken
          startedAt
          __typename
        }
        count
        goalID
        goal {
          id
          owner
          name
          description
          createdAt
          updatedAt
          createdBy
          updatedBy
          targetNumber
          targetFrequency
          targetType
          recurringType
          recurringUntil
          recurringNumber
          color
          sectionID
          entryID
          componentID
          clientID
          order
          complete
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        sectionEntriesId
        __typename
      }
      clientID
      userInputs {
        items {
          id
          inputName
          enteredAt
          createdAt
          updatedAt
          owner
          inputValue
          clockValue
          emojiRaterValue
          sliderValue
          numberValue
          incrementerValue
          raterValue
          raterBoolean
          audioCompressionStatus
          audioDurationSeconds
          audioType
          audioReady
          audioRecording
          audioUploadingStatus
          audioMode
          componentType
          componentID
          componentOrder
          recordID
          createdBy
          updatedBy
          clientID
          _version
          _deleted
          _lastChangedAt
          componentUserInputsId
          recordUserInputsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listRecords = /* GraphQL */ `
  query ListRecords(
    $filter: ModelRecordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRecords(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        enteredAt
        createdAt
        updatedAt
        updatedBy
        owner
        hasUpdatedGoalEntry
        hasData
        draft
        createdBy
        editing
        sectionID
        entryID
        entry {
          id
          createdAt
          updatedAt
          owner
          name
          icon
          type
          order
          addLogic
          logic
          inactive
          noEdit
          settings
          complete
          analytics
          draftRecordID
          clientID
          createdBy
          sectionID
          count
          goalID
          _version
          _deleted
          _lastChangedAt
          sectionEntriesId
          __typename
        }
        clientID
        userInputs {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncRecords = /* GraphQL */ `
  query SyncRecords(
    $filter: ModelRecordFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRecords(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        enteredAt
        createdAt
        updatedAt
        updatedBy
        owner
        hasUpdatedGoalEntry
        hasData
        draft
        createdBy
        editing
        sectionID
        entryID
        entry {
          id
          createdAt
          updatedAt
          owner
          name
          icon
          type
          order
          addLogic
          logic
          inactive
          noEdit
          settings
          complete
          analytics
          draftRecordID
          clientID
          createdBy
          sectionID
          count
          goalID
          _version
          _deleted
          _lastChangedAt
          sectionEntriesId
          __typename
        }
        clientID
        userInputs {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const recordsByEnteredAt = /* GraphQL */ `
  query RecordsByEnteredAt(
    $enteredAt: AWSDateTime!
    $sortDirection: ModelSortDirection
    $filter: ModelRecordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    recordsByEnteredAt(
      enteredAt: $enteredAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        enteredAt
        createdAt
        updatedAt
        updatedBy
        owner
        hasUpdatedGoalEntry
        hasData
        draft
        createdBy
        editing
        sectionID
        entryID
        entry {
          id
          createdAt
          updatedAt
          owner
          name
          icon
          type
          order
          addLogic
          logic
          inactive
          noEdit
          settings
          complete
          analytics
          draftRecordID
          clientID
          createdBy
          sectionID
          count
          goalID
          _version
          _deleted
          _lastChangedAt
          sectionEntriesId
          __typename
        }
        clientID
        userInputs {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const recordsByHasData = /* GraphQL */ `
  query RecordsByHasData(
    $hasData: String!
    $enteredAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRecordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    recordsByHasData(
      hasData: $hasData
      enteredAt: $enteredAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        enteredAt
        createdAt
        updatedAt
        updatedBy
        owner
        hasUpdatedGoalEntry
        hasData
        draft
        createdBy
        editing
        sectionID
        entryID
        entry {
          id
          createdAt
          updatedAt
          owner
          name
          icon
          type
          order
          addLogic
          logic
          inactive
          noEdit
          settings
          complete
          analytics
          draftRecordID
          clientID
          createdBy
          sectionID
          count
          goalID
          _version
          _deleted
          _lastChangedAt
          sectionEntriesId
          __typename
        }
        clientID
        userInputs {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const recordsByCreatedBy = /* GraphQL */ `
  query RecordsByCreatedBy(
    $createdBy: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRecordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    recordsByCreatedBy(
      createdBy: $createdBy
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        enteredAt
        createdAt
        updatedAt
        updatedBy
        owner
        hasUpdatedGoalEntry
        hasData
        draft
        createdBy
        editing
        sectionID
        entryID
        entry {
          id
          createdAt
          updatedAt
          owner
          name
          icon
          type
          order
          addLogic
          logic
          inactive
          noEdit
          settings
          complete
          analytics
          draftRecordID
          clientID
          createdBy
          sectionID
          count
          goalID
          _version
          _deleted
          _lastChangedAt
          sectionEntriesId
          __typename
        }
        clientID
        userInputs {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const recordsBySectionID = /* GraphQL */ `
  query RecordsBySectionID(
    $sectionID: ID!
    $enteredAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRecordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    recordsBySectionID(
      sectionID: $sectionID
      enteredAt: $enteredAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        enteredAt
        createdAt
        updatedAt
        updatedBy
        owner
        hasUpdatedGoalEntry
        hasData
        draft
        createdBy
        editing
        sectionID
        entryID
        entry {
          id
          createdAt
          updatedAt
          owner
          name
          icon
          type
          order
          addLogic
          logic
          inactive
          noEdit
          settings
          complete
          analytics
          draftRecordID
          clientID
          createdBy
          sectionID
          count
          goalID
          _version
          _deleted
          _lastChangedAt
          sectionEntriesId
          __typename
        }
        clientID
        userInputs {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const recordsByEntryID = /* GraphQL */ `
  query RecordsByEntryID(
    $entryID: ID!
    $enteredAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRecordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    recordsByEntryID(
      entryID: $entryID
      enteredAt: $enteredAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        enteredAt
        createdAt
        updatedAt
        updatedBy
        owner
        hasUpdatedGoalEntry
        hasData
        draft
        createdBy
        editing
        sectionID
        entryID
        entry {
          id
          createdAt
          updatedAt
          owner
          name
          icon
          type
          order
          addLogic
          logic
          inactive
          noEdit
          settings
          complete
          analytics
          draftRecordID
          clientID
          createdBy
          sectionID
          count
          goalID
          _version
          _deleted
          _lastChangedAt
          sectionEntriesId
          __typename
        }
        clientID
        userInputs {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const recordsByDate = /* GraphQL */ `
  query RecordsByDate(
    $clientID: ID!
    $enteredAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRecordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    recordsByDate(
      clientID: $clientID
      enteredAt: $enteredAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        enteredAt
        createdAt
        updatedAt
        updatedBy
        owner
        hasUpdatedGoalEntry
        hasData
        draft
        createdBy
        editing
        sectionID
        entryID
        entry {
          id
          createdAt
          updatedAt
          owner
          name
          icon
          type
          order
          addLogic
          logic
          inactive
          noEdit
          settings
          complete
          analytics
          draftRecordID
          clientID
          createdBy
          sectionID
          count
          goalID
          _version
          _deleted
          _lastChangedAt
          sectionEntriesId
          __typename
        }
        clientID
        userInputs {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getInsightsSection = /* GraphQL */ `
  query GetInsightsSection($id: ID!) {
    getInsightsSection(id: $id) {
      id
      createdAt
      updatedAt
      owner
      clientID
      name
      settings
      order
      orientation
      entryID
      insights {
        items {
          id
          createdAt
          updatedAt
          owner
          name
          aggregateBy
          dataType
          showLabels
          showNotes
          zoom
          connectNulls
          scatter
          countBy
          countByBoolean
          dataRecords
          clientID
          order
          type
          entryID
          insightsSectionID
          settings
          _version
          _deleted
          _lastChangedAt
          insightsSectionInsightsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listInsightsSections = /* GraphQL */ `
  query ListInsightsSections(
    $filter: ModelInsightsSectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInsightsSections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        owner
        clientID
        name
        settings
        order
        orientation
        entryID
        insights {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncInsightsSections = /* GraphQL */ `
  query SyncInsightsSections(
    $filter: ModelInsightsSectionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncInsightsSections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        createdAt
        updatedAt
        owner
        clientID
        name
        settings
        order
        orientation
        entryID
        insights {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const insightsSectionsByClientID = /* GraphQL */ `
  query InsightsSectionsByClientID(
    $clientID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInsightsSectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    insightsSectionsByClientID(
      clientID: $clientID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        owner
        clientID
        name
        settings
        order
        orientation
        entryID
        insights {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const insightsSectionByClientIDandEntryIDandOrder = /* GraphQL */ `
  query InsightsSectionByClientIDandEntryIDandOrder(
    $entryID: ID!
    $clientIDOrder: ModelInsightsSectionInsightsSectionByClientIDandEntryIDandOrderCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInsightsSectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    insightsSectionByClientIDandEntryIDandOrder(
      entryID: $entryID
      clientIDOrder: $clientIDOrder
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        owner
        clientID
        name
        settings
        order
        orientation
        entryID
        insights {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getInsight = /* GraphQL */ `
  query GetInsight($id: ID!) {
    getInsight(id: $id) {
      id
      createdAt
      updatedAt
      owner
      name
      aggregateBy
      dataType
      showLabels
      showNotes
      zoom
      connectNulls
      scatter
      countBy
      countByBoolean
      dataRecords
      clientID
      order
      type
      entryID
      insightsSectionID
      insightsSection {
        id
        createdAt
        updatedAt
        owner
        clientID
        name
        settings
        order
        orientation
        entryID
        insights {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      settings
      _version
      _deleted
      _lastChangedAt
      insightsSectionInsightsId
      __typename
    }
  }
`;
export const listInsights = /* GraphQL */ `
  query ListInsights(
    $filter: ModelInsightFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInsights(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        owner
        name
        aggregateBy
        dataType
        showLabels
        showNotes
        zoom
        connectNulls
        scatter
        countBy
        countByBoolean
        dataRecords
        clientID
        order
        type
        entryID
        insightsSectionID
        insightsSection {
          id
          createdAt
          updatedAt
          owner
          clientID
          name
          settings
          order
          orientation
          entryID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        settings
        _version
        _deleted
        _lastChangedAt
        insightsSectionInsightsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncInsights = /* GraphQL */ `
  query SyncInsights(
    $filter: ModelInsightFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncInsights(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        createdAt
        updatedAt
        owner
        name
        aggregateBy
        dataType
        showLabels
        showNotes
        zoom
        connectNulls
        scatter
        countBy
        countByBoolean
        dataRecords
        clientID
        order
        type
        entryID
        insightsSectionID
        insightsSection {
          id
          createdAt
          updatedAt
          owner
          clientID
          name
          settings
          order
          orientation
          entryID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        settings
        _version
        _deleted
        _lastChangedAt
        insightsSectionInsightsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const insightsByClientID = /* GraphQL */ `
  query InsightsByClientID(
    $clientID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInsightFilterInput
    $limit: Int
    $nextToken: String
  ) {
    insightsByClientID(
      clientID: $clientID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        owner
        name
        aggregateBy
        dataType
        showLabels
        showNotes
        zoom
        connectNulls
        scatter
        countBy
        countByBoolean
        dataRecords
        clientID
        order
        type
        entryID
        insightsSectionID
        insightsSection {
          id
          createdAt
          updatedAt
          owner
          clientID
          name
          settings
          order
          orientation
          entryID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        settings
        _version
        _deleted
        _lastChangedAt
        insightsSectionInsightsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const insightByEntryID = /* GraphQL */ `
  query InsightByEntryID(
    $entryID: ID!
    $createdAtOrder: ModelInsightInsightByEntryIDCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInsightFilterInput
    $limit: Int
    $nextToken: String
  ) {
    insightByEntryID(
      entryID: $entryID
      createdAtOrder: $createdAtOrder
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        owner
        name
        aggregateBy
        dataType
        showLabels
        showNotes
        zoom
        connectNulls
        scatter
        countBy
        countByBoolean
        dataRecords
        clientID
        order
        type
        entryID
        insightsSectionID
        insightsSection {
          id
          createdAt
          updatedAt
          owner
          clientID
          name
          settings
          order
          orientation
          entryID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        settings
        _version
        _deleted
        _lastChangedAt
        insightsSectionInsightsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const insightByInsightsSectionID = /* GraphQL */ `
  query InsightByInsightsSectionID(
    $insightsSectionID: ID!
    $order: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInsightFilterInput
    $limit: Int
    $nextToken: String
  ) {
    insightByInsightsSectionID(
      insightsSectionID: $insightsSectionID
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        owner
        name
        aggregateBy
        dataType
        showLabels
        showNotes
        zoom
        connectNulls
        scatter
        countBy
        countByBoolean
        dataRecords
        clientID
        order
        type
        entryID
        insightsSectionID
        insightsSection {
          id
          createdAt
          updatedAt
          owner
          clientID
          name
          settings
          order
          orientation
          entryID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        settings
        _version
        _deleted
        _lastChangedAt
        insightsSectionInsightsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getGoal = /* GraphQL */ `
  query GetGoal($id: ID!) {
    getGoal(id: $id) {
      id
      owner
      name
      description
      createdAt
      updatedAt
      createdBy
      updatedBy
      targetNumber
      targetFrequency
      targetType
      recurringType
      recurringUntil
      recurringNumber
      color
      section {
        id
        createdAt
        updatedAt
        complete
        name
        inactive
        noEdit
        analytics
        order
        owner
        settings
        clientID
        createdBy
        entries {
          nextToken
          startedAt
          __typename
        }
        goalID
        goal {
          id
          owner
          name
          description
          createdAt
          updatedAt
          createdBy
          updatedBy
          targetNumber
          targetFrequency
          targetType
          recurringType
          recurringUntil
          recurringNumber
          color
          sectionID
          entryID
          componentID
          clientID
          order
          complete
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      sectionID
      entry {
        id
        createdAt
        updatedAt
        owner
        name
        icon
        type
        order
        addLogic
        logic
        inactive
        noEdit
        settings
        complete
        analytics
        draftRecordID
        clientID
        createdBy
        sectionID
        section {
          id
          createdAt
          updatedAt
          complete
          name
          inactive
          noEdit
          analytics
          order
          owner
          settings
          clientID
          createdBy
          goalID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        entrySections {
          nextToken
          startedAt
          __typename
        }
        count
        goalID
        goal {
          id
          owner
          name
          description
          createdAt
          updatedAt
          createdBy
          updatedBy
          targetNumber
          targetFrequency
          targetType
          recurringType
          recurringUntil
          recurringNumber
          color
          sectionID
          entryID
          componentID
          clientID
          order
          complete
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        sectionEntriesId
        __typename
      }
      entryID
      component {
        id
        createdAt
        updatedAt
        clientID
        complete
        owner
        type
        order
        settings
        to
        from
        rows
        name
        noEdit
        size
        label
        placeholder
        upload
        options
        content
        boolean
        variant
        prompts
        collapse
        showInfo
        showPrompts
        multiline
        addButton
        multiselect
        information
        instructions
        removeButton
        booleanLabel
        annotationLabels
        userInputs {
          nextToken
          startedAt
          __typename
        }
        entrySectionID
        entrySection {
          id
          createdAt
          updatedAt
          name
          complete
          owner
          order
          noEdit
          settings
          showInfo
          information
          informationTitle
          orientation
          clientID
          entryID
          createdBy
          _version
          _deleted
          _lastChangedAt
          entryEntrySectionsId
          __typename
        }
        goalID
        goal {
          id
          owner
          name
          description
          createdAt
          updatedAt
          createdBy
          updatedBy
          targetNumber
          targetFrequency
          targetType
          recurringType
          recurringUntil
          recurringNumber
          color
          sectionID
          entryID
          componentID
          clientID
          order
          complete
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        infoContent {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        entrySectionComponentsId
        __typename
      }
      componentID
      clientID
      order
      goalEntries {
        items {
          id
          owner
          date
          goalID
          trackingNumber
          celebrated
          complete
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          goalGoalEntriesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      complete
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listGoals = /* GraphQL */ `
  query ListGoals(
    $filter: ModelGoalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGoals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        name
        description
        createdAt
        updatedAt
        createdBy
        updatedBy
        targetNumber
        targetFrequency
        targetType
        recurringType
        recurringUntil
        recurringNumber
        color
        section {
          id
          createdAt
          updatedAt
          complete
          name
          inactive
          noEdit
          analytics
          order
          owner
          settings
          clientID
          createdBy
          goalID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        sectionID
        entry {
          id
          createdAt
          updatedAt
          owner
          name
          icon
          type
          order
          addLogic
          logic
          inactive
          noEdit
          settings
          complete
          analytics
          draftRecordID
          clientID
          createdBy
          sectionID
          count
          goalID
          _version
          _deleted
          _lastChangedAt
          sectionEntriesId
          __typename
        }
        entryID
        component {
          id
          createdAt
          updatedAt
          clientID
          complete
          owner
          type
          order
          settings
          to
          from
          rows
          name
          noEdit
          size
          label
          placeholder
          upload
          options
          content
          boolean
          variant
          prompts
          collapse
          showInfo
          showPrompts
          multiline
          addButton
          multiselect
          information
          instructions
          removeButton
          booleanLabel
          annotationLabels
          entrySectionID
          goalID
          _version
          _deleted
          _lastChangedAt
          entrySectionComponentsId
          __typename
        }
        componentID
        clientID
        order
        goalEntries {
          nextToken
          startedAt
          __typename
        }
        complete
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncGoals = /* GraphQL */ `
  query SyncGoals(
    $filter: ModelGoalFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncGoals(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        name
        description
        createdAt
        updatedAt
        createdBy
        updatedBy
        targetNumber
        targetFrequency
        targetType
        recurringType
        recurringUntil
        recurringNumber
        color
        section {
          id
          createdAt
          updatedAt
          complete
          name
          inactive
          noEdit
          analytics
          order
          owner
          settings
          clientID
          createdBy
          goalID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        sectionID
        entry {
          id
          createdAt
          updatedAt
          owner
          name
          icon
          type
          order
          addLogic
          logic
          inactive
          noEdit
          settings
          complete
          analytics
          draftRecordID
          clientID
          createdBy
          sectionID
          count
          goalID
          _version
          _deleted
          _lastChangedAt
          sectionEntriesId
          __typename
        }
        entryID
        component {
          id
          createdAt
          updatedAt
          clientID
          complete
          owner
          type
          order
          settings
          to
          from
          rows
          name
          noEdit
          size
          label
          placeholder
          upload
          options
          content
          boolean
          variant
          prompts
          collapse
          showInfo
          showPrompts
          multiline
          addButton
          multiselect
          information
          instructions
          removeButton
          booleanLabel
          annotationLabels
          entrySectionID
          goalID
          _version
          _deleted
          _lastChangedAt
          entrySectionComponentsId
          __typename
        }
        componentID
        clientID
        order
        goalEntries {
          nextToken
          startedAt
          __typename
        }
        complete
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const goalsByCreatedBy = /* GraphQL */ `
  query GoalsByCreatedBy(
    $createdBy: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelGoalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    goalsByCreatedBy(
      createdBy: $createdBy
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        name
        description
        createdAt
        updatedAt
        createdBy
        updatedBy
        targetNumber
        targetFrequency
        targetType
        recurringType
        recurringUntil
        recurringNumber
        color
        section {
          id
          createdAt
          updatedAt
          complete
          name
          inactive
          noEdit
          analytics
          order
          owner
          settings
          clientID
          createdBy
          goalID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        sectionID
        entry {
          id
          createdAt
          updatedAt
          owner
          name
          icon
          type
          order
          addLogic
          logic
          inactive
          noEdit
          settings
          complete
          analytics
          draftRecordID
          clientID
          createdBy
          sectionID
          count
          goalID
          _version
          _deleted
          _lastChangedAt
          sectionEntriesId
          __typename
        }
        entryID
        component {
          id
          createdAt
          updatedAt
          clientID
          complete
          owner
          type
          order
          settings
          to
          from
          rows
          name
          noEdit
          size
          label
          placeholder
          upload
          options
          content
          boolean
          variant
          prompts
          collapse
          showInfo
          showPrompts
          multiline
          addButton
          multiselect
          information
          instructions
          removeButton
          booleanLabel
          annotationLabels
          entrySectionID
          goalID
          _version
          _deleted
          _lastChangedAt
          entrySectionComponentsId
          __typename
        }
        componentID
        clientID
        order
        goalEntries {
          nextToken
          startedAt
          __typename
        }
        complete
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const goalsByUpdatedBy = /* GraphQL */ `
  query GoalsByUpdatedBy(
    $updatedBy: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelGoalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    goalsByUpdatedBy(
      updatedBy: $updatedBy
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        name
        description
        createdAt
        updatedAt
        createdBy
        updatedBy
        targetNumber
        targetFrequency
        targetType
        recurringType
        recurringUntil
        recurringNumber
        color
        section {
          id
          createdAt
          updatedAt
          complete
          name
          inactive
          noEdit
          analytics
          order
          owner
          settings
          clientID
          createdBy
          goalID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        sectionID
        entry {
          id
          createdAt
          updatedAt
          owner
          name
          icon
          type
          order
          addLogic
          logic
          inactive
          noEdit
          settings
          complete
          analytics
          draftRecordID
          clientID
          createdBy
          sectionID
          count
          goalID
          _version
          _deleted
          _lastChangedAt
          sectionEntriesId
          __typename
        }
        entryID
        component {
          id
          createdAt
          updatedAt
          clientID
          complete
          owner
          type
          order
          settings
          to
          from
          rows
          name
          noEdit
          size
          label
          placeholder
          upload
          options
          content
          boolean
          variant
          prompts
          collapse
          showInfo
          showPrompts
          multiline
          addButton
          multiselect
          information
          instructions
          removeButton
          booleanLabel
          annotationLabels
          entrySectionID
          goalID
          _version
          _deleted
          _lastChangedAt
          entrySectionComponentsId
          __typename
        }
        componentID
        clientID
        order
        goalEntries {
          nextToken
          startedAt
          __typename
        }
        complete
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const goalsBySectionID = /* GraphQL */ `
  query GoalsBySectionID(
    $sectionID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelGoalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    goalsBySectionID(
      sectionID: $sectionID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        name
        description
        createdAt
        updatedAt
        createdBy
        updatedBy
        targetNumber
        targetFrequency
        targetType
        recurringType
        recurringUntil
        recurringNumber
        color
        section {
          id
          createdAt
          updatedAt
          complete
          name
          inactive
          noEdit
          analytics
          order
          owner
          settings
          clientID
          createdBy
          goalID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        sectionID
        entry {
          id
          createdAt
          updatedAt
          owner
          name
          icon
          type
          order
          addLogic
          logic
          inactive
          noEdit
          settings
          complete
          analytics
          draftRecordID
          clientID
          createdBy
          sectionID
          count
          goalID
          _version
          _deleted
          _lastChangedAt
          sectionEntriesId
          __typename
        }
        entryID
        component {
          id
          createdAt
          updatedAt
          clientID
          complete
          owner
          type
          order
          settings
          to
          from
          rows
          name
          noEdit
          size
          label
          placeholder
          upload
          options
          content
          boolean
          variant
          prompts
          collapse
          showInfo
          showPrompts
          multiline
          addButton
          multiselect
          information
          instructions
          removeButton
          booleanLabel
          annotationLabels
          entrySectionID
          goalID
          _version
          _deleted
          _lastChangedAt
          entrySectionComponentsId
          __typename
        }
        componentID
        clientID
        order
        goalEntries {
          nextToken
          startedAt
          __typename
        }
        complete
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const goalsByEntryID = /* GraphQL */ `
  query GoalsByEntryID(
    $entryID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelGoalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    goalsByEntryID(
      entryID: $entryID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        name
        description
        createdAt
        updatedAt
        createdBy
        updatedBy
        targetNumber
        targetFrequency
        targetType
        recurringType
        recurringUntil
        recurringNumber
        color
        section {
          id
          createdAt
          updatedAt
          complete
          name
          inactive
          noEdit
          analytics
          order
          owner
          settings
          clientID
          createdBy
          goalID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        sectionID
        entry {
          id
          createdAt
          updatedAt
          owner
          name
          icon
          type
          order
          addLogic
          logic
          inactive
          noEdit
          settings
          complete
          analytics
          draftRecordID
          clientID
          createdBy
          sectionID
          count
          goalID
          _version
          _deleted
          _lastChangedAt
          sectionEntriesId
          __typename
        }
        entryID
        component {
          id
          createdAt
          updatedAt
          clientID
          complete
          owner
          type
          order
          settings
          to
          from
          rows
          name
          noEdit
          size
          label
          placeholder
          upload
          options
          content
          boolean
          variant
          prompts
          collapse
          showInfo
          showPrompts
          multiline
          addButton
          multiselect
          information
          instructions
          removeButton
          booleanLabel
          annotationLabels
          entrySectionID
          goalID
          _version
          _deleted
          _lastChangedAt
          entrySectionComponentsId
          __typename
        }
        componentID
        clientID
        order
        goalEntries {
          nextToken
          startedAt
          __typename
        }
        complete
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const goalsByComponentID = /* GraphQL */ `
  query GoalsByComponentID(
    $componentID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelGoalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    goalsByComponentID(
      componentID: $componentID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        name
        description
        createdAt
        updatedAt
        createdBy
        updatedBy
        targetNumber
        targetFrequency
        targetType
        recurringType
        recurringUntil
        recurringNumber
        color
        section {
          id
          createdAt
          updatedAt
          complete
          name
          inactive
          noEdit
          analytics
          order
          owner
          settings
          clientID
          createdBy
          goalID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        sectionID
        entry {
          id
          createdAt
          updatedAt
          owner
          name
          icon
          type
          order
          addLogic
          logic
          inactive
          noEdit
          settings
          complete
          analytics
          draftRecordID
          clientID
          createdBy
          sectionID
          count
          goalID
          _version
          _deleted
          _lastChangedAt
          sectionEntriesId
          __typename
        }
        entryID
        component {
          id
          createdAt
          updatedAt
          clientID
          complete
          owner
          type
          order
          settings
          to
          from
          rows
          name
          noEdit
          size
          label
          placeholder
          upload
          options
          content
          boolean
          variant
          prompts
          collapse
          showInfo
          showPrompts
          multiline
          addButton
          multiselect
          information
          instructions
          removeButton
          booleanLabel
          annotationLabels
          entrySectionID
          goalID
          _version
          _deleted
          _lastChangedAt
          entrySectionComponentsId
          __typename
        }
        componentID
        clientID
        order
        goalEntries {
          nextToken
          startedAt
          __typename
        }
        complete
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const goalByClientID = /* GraphQL */ `
  query GoalByClientID(
    $clientID: ID!
    $order: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGoalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    goalByClientID(
      clientID: $clientID
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        name
        description
        createdAt
        updatedAt
        createdBy
        updatedBy
        targetNumber
        targetFrequency
        targetType
        recurringType
        recurringUntil
        recurringNumber
        color
        section {
          id
          createdAt
          updatedAt
          complete
          name
          inactive
          noEdit
          analytics
          order
          owner
          settings
          clientID
          createdBy
          goalID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        sectionID
        entry {
          id
          createdAt
          updatedAt
          owner
          name
          icon
          type
          order
          addLogic
          logic
          inactive
          noEdit
          settings
          complete
          analytics
          draftRecordID
          clientID
          createdBy
          sectionID
          count
          goalID
          _version
          _deleted
          _lastChangedAt
          sectionEntriesId
          __typename
        }
        entryID
        component {
          id
          createdAt
          updatedAt
          clientID
          complete
          owner
          type
          order
          settings
          to
          from
          rows
          name
          noEdit
          size
          label
          placeholder
          upload
          options
          content
          boolean
          variant
          prompts
          collapse
          showInfo
          showPrompts
          multiline
          addButton
          multiselect
          information
          instructions
          removeButton
          booleanLabel
          annotationLabels
          entrySectionID
          goalID
          _version
          _deleted
          _lastChangedAt
          entrySectionComponentsId
          __typename
        }
        componentID
        clientID
        order
        goalEntries {
          nextToken
          startedAt
          __typename
        }
        complete
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const goalsByComplete = /* GraphQL */ `
  query GoalsByComplete(
    $complete: String!
    $sortDirection: ModelSortDirection
    $filter: ModelGoalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    goalsByComplete(
      complete: $complete
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        name
        description
        createdAt
        updatedAt
        createdBy
        updatedBy
        targetNumber
        targetFrequency
        targetType
        recurringType
        recurringUntil
        recurringNumber
        color
        section {
          id
          createdAt
          updatedAt
          complete
          name
          inactive
          noEdit
          analytics
          order
          owner
          settings
          clientID
          createdBy
          goalID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        sectionID
        entry {
          id
          createdAt
          updatedAt
          owner
          name
          icon
          type
          order
          addLogic
          logic
          inactive
          noEdit
          settings
          complete
          analytics
          draftRecordID
          clientID
          createdBy
          sectionID
          count
          goalID
          _version
          _deleted
          _lastChangedAt
          sectionEntriesId
          __typename
        }
        entryID
        component {
          id
          createdAt
          updatedAt
          clientID
          complete
          owner
          type
          order
          settings
          to
          from
          rows
          name
          noEdit
          size
          label
          placeholder
          upload
          options
          content
          boolean
          variant
          prompts
          collapse
          showInfo
          showPrompts
          multiline
          addButton
          multiselect
          information
          instructions
          removeButton
          booleanLabel
          annotationLabels
          entrySectionID
          goalID
          _version
          _deleted
          _lastChangedAt
          entrySectionComponentsId
          __typename
        }
        componentID
        clientID
        order
        goalEntries {
          nextToken
          startedAt
          __typename
        }
        complete
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getGoalEntry = /* GraphQL */ `
  query GetGoalEntry($id: ID!) {
    getGoalEntry(id: $id) {
      id
      owner
      date
      goalID
      goal {
        id
        owner
        name
        description
        createdAt
        updatedAt
        createdBy
        updatedBy
        targetNumber
        targetFrequency
        targetType
        recurringType
        recurringUntil
        recurringNumber
        color
        section {
          id
          createdAt
          updatedAt
          complete
          name
          inactive
          noEdit
          analytics
          order
          owner
          settings
          clientID
          createdBy
          goalID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        sectionID
        entry {
          id
          createdAt
          updatedAt
          owner
          name
          icon
          type
          order
          addLogic
          logic
          inactive
          noEdit
          settings
          complete
          analytics
          draftRecordID
          clientID
          createdBy
          sectionID
          count
          goalID
          _version
          _deleted
          _lastChangedAt
          sectionEntriesId
          __typename
        }
        entryID
        component {
          id
          createdAt
          updatedAt
          clientID
          complete
          owner
          type
          order
          settings
          to
          from
          rows
          name
          noEdit
          size
          label
          placeholder
          upload
          options
          content
          boolean
          variant
          prompts
          collapse
          showInfo
          showPrompts
          multiline
          addButton
          multiselect
          information
          instructions
          removeButton
          booleanLabel
          annotationLabels
          entrySectionID
          goalID
          _version
          _deleted
          _lastChangedAt
          entrySectionComponentsId
          __typename
        }
        componentID
        clientID
        order
        goalEntries {
          nextToken
          startedAt
          __typename
        }
        complete
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      trackingNumber
      celebrated
      complete
      clientID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      goalGoalEntriesId
      __typename
    }
  }
`;
export const listGoalEntries = /* GraphQL */ `
  query ListGoalEntries(
    $filter: ModelGoalEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGoalEntries(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        date
        goalID
        goal {
          id
          owner
          name
          description
          createdAt
          updatedAt
          createdBy
          updatedBy
          targetNumber
          targetFrequency
          targetType
          recurringType
          recurringUntil
          recurringNumber
          color
          sectionID
          entryID
          componentID
          clientID
          order
          complete
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        trackingNumber
        celebrated
        complete
        clientID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        goalGoalEntriesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncGoalEntries = /* GraphQL */ `
  query SyncGoalEntries(
    $filter: ModelGoalEntryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncGoalEntries(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        date
        goalID
        goal {
          id
          owner
          name
          description
          createdAt
          updatedAt
          createdBy
          updatedBy
          targetNumber
          targetFrequency
          targetType
          recurringType
          recurringUntil
          recurringNumber
          color
          sectionID
          entryID
          componentID
          clientID
          order
          complete
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        trackingNumber
        celebrated
        complete
        clientID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        goalGoalEntriesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const goalEntriesByDate = /* GraphQL */ `
  query GoalEntriesByDate(
    $date: AWSDateTime!
    $sortDirection: ModelSortDirection
    $filter: ModelGoalEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    goalEntriesByDate(
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        date
        goalID
        goal {
          id
          owner
          name
          description
          createdAt
          updatedAt
          createdBy
          updatedBy
          targetNumber
          targetFrequency
          targetType
          recurringType
          recurringUntil
          recurringNumber
          color
          sectionID
          entryID
          componentID
          clientID
          order
          complete
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        trackingNumber
        celebrated
        complete
        clientID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        goalGoalEntriesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const goalEntryByDate = /* GraphQL */ `
  query GoalEntryByDate(
    $goalID: ID!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGoalEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    goalEntryByDate(
      goalID: $goalID
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        date
        goalID
        goal {
          id
          owner
          name
          description
          createdAt
          updatedAt
          createdBy
          updatedBy
          targetNumber
          targetFrequency
          targetType
          recurringType
          recurringUntil
          recurringNumber
          color
          sectionID
          entryID
          componentID
          clientID
          order
          complete
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        trackingNumber
        celebrated
        complete
        clientID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        goalGoalEntriesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const goalEntryByClientID = /* GraphQL */ `
  query GoalEntryByClientID(
    $clientID: ID!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGoalEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    goalEntryByClientID(
      clientID: $clientID
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        date
        goalID
        goal {
          id
          owner
          name
          description
          createdAt
          updatedAt
          createdBy
          updatedBy
          targetNumber
          targetFrequency
          targetType
          recurringType
          recurringUntil
          recurringNumber
          color
          sectionID
          entryID
          componentID
          clientID
          order
          complete
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        trackingNumber
        celebrated
        complete
        clientID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        goalGoalEntriesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;

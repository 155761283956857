import React, { useState } from 'react';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, IconButton, InputAdornment, TextField } from '@mui/material';
import * as yup from 'yup';

// Define the validation schema
const passwordSchema = yup.object().shape({
  currentPassword: yup.string().required('Current password is required'),
  newPassword: yup
    .string()
    .required('New password is required')
    .min(6, 'Password should be at least 6 characters')
    // eslint-disable-next-line no-useless-escape
    .matches(/\d/, 'Password should have at least one number')
    .matches(
      // eslint-disable-next-line no-useless-escape
      /[A-Z]|[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/,
      'Password should have at least one uppercase letter or special character'
    ),
  confirmNewPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match')
});

function ChangeCurrentPassword({
  handlePasswordChange,
  changingPassword,
  newPassword,
  setNewPassword,
  currentPassword,
  setCurrentPassword
}) {
  // State variables
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [submissionAttempted, setSubmissionAttempted] = useState(false);

  // Validate all fields
  const validateField = async () => {
    try {
      await passwordSchema.validate(
        {
          currentPassword,
          newPassword,
          confirmNewPassword
        },
        { abortEarly: false }
      );
      setErrors({});
      return true;
    } catch (err) {
      const newErrors = {};
      err.inner.forEach(error => {
        newErrors[error.path] = error.message;
      });
      setErrors(newErrors);
      return false;
    }
  };

  // Handle form submission
  const handleOnSubmit = async () => {
    setSubmissionAttempted(true);
    const isValid = await validateField();
    if (isValid) {
      handlePasswordChange();
    }
  };

  return (
    <Box width="100%">
      <Box width="100%" my={1}>
        <TextField
          size="small"
          fullWidth
          label="Current Password"
          type={showCurrentPassword ? 'text' : 'password'}
          value={currentPassword}
          onChange={e => {
            setCurrentPassword(e.target.value);
            if (submissionAttempted) {
              setSubmissionAttempted(false);
            }
          }}
          error={submissionAttempted && !!errors.currentPassword}
          helperText={submissionAttempted && errors.currentPassword}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                  edge="end"
                >
                  {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </Box>
      <Box width="100%" my={1}>
        <TextField
          size="small"
          fullWidth
          label="New Password"
          type={showNewPassword ? 'text' : 'password'}
          value={newPassword}
          onChange={e => {
            setNewPassword(e.target.value);
            if (submissionAttempted) {
              setSubmissionAttempted(false);
            }
          }}
          error={submissionAttempted && !!errors.newPassword}
          helperText={submissionAttempted && errors.newPassword}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowNewPassword(!showNewPassword)}
                  edge="end"
                >
                  {showNewPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </Box>
      <Box width="100%" my={1}>
        <TextField
          size="small"
          fullWidth
          label="Confirm New Password"
          type={showConfirmPassword ? 'text' : 'password'}
          value={confirmNewPassword}
          onChange={e => {
            setConfirmNewPassword(e.target.value);
            if (submissionAttempted) {
              setSubmissionAttempted(false);
            }
          }}
          error={submissionAttempted && !!errors.confirmNewPassword}
          helperText={submissionAttempted && errors.confirmNewPassword}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  edge="end"
                >
                  {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </Box>
      <Box display="flex" width="100%" my={2}>
        <LoadingButton
          fullWidth
          size="small"
          variant="contained"
          onClick={handleOnSubmit}
          loading={changingPassword}
        >
          Change Password
        </LoadingButton>
      </Box>
    </Box>
  );
}

export default React.memo(ChangeCurrentPassword);

import { useEffect, useRef, useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { Circle, Download } from '@mui/icons-material';
import {
  Box,
  Button,
  Grid,
  Hidden,
  Menu,
  MenuItem,
  Stack,
  Typography
} from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import { useAppContext } from 'context';
import { useSnackbar } from 'context/SnackBar';
import { programSummary } from 'graphql/queries';
import html2canvas from 'html2canvas';
import { DateTime } from 'luxon';
import moment from 'moment-timezone';
import {
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';

import { LoadingAtom } from 'components/atoms';

export default function Report() {
  const downloadButtonRef = useRef(null);
  const { state } = useAppContext();
  const { user } = useAuthenticator(context => [context.user]);
  const clientID = state.client?.id || user.username;
  const timeZone = moment.tz.guess();
  const [anchorEl, setAnchorEl] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);

  const [chartData, setChartData] = useState([]);
  const isCamperdown =
    state?.client?.program?.toLowerCase().includes('camperdown') ||
    state?.client?.program?.toLowerCase().includes('monitoringadult');

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await API.graphql(
          graphqlOperation(programSummary, {
            action: 'fetchData',
            clientID: clientID,
            timeZone
          })
        );
        const emptyData = generateEmptyChartData();
        const chartData =
          data.programSummary.statusCode === 200 && data.programSummary.body
            ? JSON.parse(data.programSummary.body)
            : emptyData;
        setChartData(chartData);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function generateEmptyChartData() {
    const days = ['Mon', 'Tues', 'Weds', 'Thurs', 'Fri', 'Sat', 'Sun'];
    const initialDate = DateTime.now();

    return Array.from({ length: 24 }).reduce((acc, _, i) => {
      const weekStartDate = initialDate
        .plus({ weeks: i })
        .startOf('week')
        .toISO();
      const weekData = days.map(day => ({
        day,
        FT: null,
        SR: null
      }));

      acc[weekStartDate] = weekData;
      return acc;
    }, {});
  }

  const chartRef = useRef(null);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const renderTooltip = props => {
    const { active, payload } = props;
    if (active && payload && payload.length) {
      const data = payload[0].payload;

      return (
        <div
          style={{
            backgroundColor: '#fff',
            border: '1px solid #999',
            margin: 0,
            padding: 10
          }}
        >
          <p>{data.day}</p>
          {payload.map((entry, index) => (
            <p key={`tooltip-${index}`}>
              <span style={{ color: entry.color }}>{entry.name}: </span>
              {typeof entry.value === 'number'
                ? entry.value.toFixed(1)
                : entry.value}
            </p>
          ))}
        </div>
      );
    }

    return null;
  };

  const handleDownload = async imageType => {
    try {
      if (downloadButtonRef.current) {
        downloadButtonRef.current.style.visibility = 'hidden';
      }
      const canvas = await html2canvas(chartRef.current);
      const image = canvas.toDataURL(`image/${imageType}`);
      const link = document.createElement('a');
      link.href = image;
      link.download = `chart-${state.client?.firstName?.toLowerCase()}-${state.client?.lastName?.toLowerCase()}.${imageType}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      enqueueSnackbar('Downloaded chart', { severity: 'success' });
    } catch (err) {
      enqueueSnackbar('Error downloading chart', { severity: 'error' });
      console.log('error', err);
    } finally {
      if (downloadButtonRef.current) {
        downloadButtonRef.current.style.visibility = 'visible';
      }
    }
  };

  function WeekChart({ weekData, weekStartDate, isCamperdown }) {
    const formattedStartDate =
      DateTime.fromISO(weekStartDate).toFormat('dd/MM/yyyy');

    return (
      <Grid item md={3} lg={2}>
        <Typography align="center" variant="body1">
          {formattedStartDate}
        </Typography>
        <ResponsiveContainer width={225} height={300}>
          <ComposedChart
            data={weekData}
            width={225}
            height={300}
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 20
            }}
          >
            <Tooltip
              cursor={{ strokeDasharray: '3 3' }}
              wrapperStyle={{ zIndex: 100 }}
              content={renderTooltip}
            />

            <XAxis
              dataKey="day"
              tick={{ fontSize: '8px' }}
              tickFormatter={day => day[0]}
            />
            <YAxis domain={[0, isCamperdown ? 8 : 10]} />
            <CartesianGrid stroke="#eee" strokeDasharray="5 5" />

            {isCamperdown && (
              <Line
                strokeWidth={3}
                type="monotone"
                dataKey="FT"
                stroke="#8884d8"
              />
            )}
            <Line
              strokeWidth={3}
              type="monotone"
              dataKey="SR"
              stroke="#82ca9d"
            />
          </ComposedChart>
        </ResponsiveContainer>
      </Grid>
    );
  }

  return (
    <Box pt={2} ref={chartRef}>
      <Grid container>
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              mb: 4
            }}
          >
            {loading ? (
              <Box width="100%">
                <LoadingAtom />
              </Box>
            ) : (
              <Stack
                alignItems={'center'}
                sx={{ ml: { xs: 0, lg: 6 } }}
                direction="row"
                spacing={2}
              >
                {isCamperdown && (
                  <Stack direction="row" spacing={1}>
                    <Circle fontSize="small" sx={{ color: '#8884d8' }} />
                    <Typography variant="body2">FT</Typography>
                  </Stack>
                )}
                <Stack direction="row" spacing={1}>
                  <Circle fontSize="small" sx={{ color: '#82ca9d' }} />
                  <Typography variant="body2">SR</Typography>
                </Stack>
              </Stack>
            )}
            <Hidden sx={{ width: '100%' }} mdDown implementation="css">
              <Box ref={downloadButtonRef}>
                <Button
                  size="small"
                  onClick={handleMenuClick}
                  startIcon={<Download fontSize="small" />}
                >
                  Download
                </Button>
                <Box>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                  >
                    <MenuItem onClick={() => handleDownload('png')}>
                      Download as PNG
                    </MenuItem>
                    <MenuItem onClick={() => handleDownload('jpeg')}>
                      Download as JPG
                    </MenuItem>
                  </Menu>
                </Box>
              </Box>
            </Hidden>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ pb: 4 }}>
        {Object.entries(chartData).map(([weekStartDate, weekData], index) => (
          <WeekChart
            isCamperdown={isCamperdown}
            key={weekStartDate}
            weekStartDate={weekStartDate}
            weekData={weekData}
          />
        ))}
      </Grid>
    </Box>
  );
}

import React, { useEffect, useMemo, useState } from 'react';

import { useTheme } from '@emotion/react';
import { Box, CircularProgress, FormLabel, Stack } from '@mui/material';
import {
  Area,
  Brush,
  CartesianGrid,
  ComposedChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';

const AreaChart = ({
  hasZoom,
  connectNulls,
  data,
  name,
  type,
  error,
  notesData
}) => {
  const [zoomRange, setZoomRange] = useState(null);

  const [selected, setSelected] = useState([]);
  const [jsonData, setJsonData] = useState([]);
  const [dataKeys, setDataKeys] = useState([]);
  const [notesMapping, setNotesMapping] = useState({});
  const theme = useTheme();

  const parsedNotesData = useMemo(() => {
    return typeof notesData === 'string' ? JSON.parse(notesData) : notesData;
  }, [notesData]);

  const parsedData = useMemo(() => {
    if (typeof data === 'object') {
      return data;
    }
    return JSON.parse(data);
  }, [data]);

  const areaColors = ['#8884d8', '#82ca9d', '#ffc658', '#ff7f0e', '#e41a1c'];

  useEffect(() => {
    if (parsedNotesData && parsedNotesData.results) {
      const notesObj = parsedNotesData.results.reduce((acc, curr) => {
        acc[curr.day] = curr.inputValues;
        return acc;
      }, {});
      setNotesMapping(notesObj);
    } else {
      setNotesMapping({});
    }
    return () => {
      setNotesMapping({});
    };
  }, [parsedNotesData]);

  useEffect(() => {
    if (type === 'area' && parsedData && parsedData.length > 0) {
      const flattenData = data => {
        return data.map(item => {
          const newItem = { ...item };

          for (let key in newItem) {
            if (newItem[key] instanceof Object) {
              const obj = newItem[key];
              if (obj.hasOwnProperty('value')) {
                newItem[key] = obj.value;
              }

              if (obj.hasOwnProperty('high')) {
                newItem[key + ' High'] = obj.high;
              }
              if (obj.hasOwnProperty('low')) {
                newItem[key + ' Low'] = obj.low;
              }
            }
          }

          return newItem;
        });
      };

      let notesMapping = {};
      if (parsedNotesData && parsedNotesData.results) {
        parsedNotesData.results.forEach(result => {
          notesMapping[result.day] = result.inputValues;
        });
      }

      if (parsedData && parsedData.length > 0) {
        const flattenedData = flattenData(parsedData);

        const keys = [
          ...new Set(
            flattenedData.reduce((acc, curr) => {
              return [
                ...acc,
                ...Object.keys(curr).filter(key => key !== 'day')
              ];
            }, [])
          )
        ];

        setSelected(keys);
        setDataKeys(keys);
        setJsonData(flattenedData);
      }
    }
    return () => {
      setJsonData([]);
      setDataKeys([]);
      setSelected([]);
    };
  }, [parsedData, type, parsedNotesData]);

  const handleLegendClick = e => {
    const { dataKey } = e;

    if (selected.length === 1 && selected.includes(dataKey)) {
      return;
    }

    if (selected.includes(dataKey)) {
      setSelected(selected.filter(item => item !== dataKey));
    } else {
      setSelected([...selected, dataKey]);
    }
  };

  const handleBrushChange = params => {
    setZoomRange([params.startIndex, params.endIndex]);
  };

  const renderTooltip = props => {
    const { active, payload } = props;

    if (active && payload && payload.length) {
      const data = payload[0].payload;

      return (
        <div
          style={{
            backgroundColor: '#fff',
            border: '1px solid #999',
            margin: 0,
            padding: 10
          }}
        >
          <p>{data.day}</p>
          {payload.map((entry, index) => (
            <p key={`tooltip-${index}`}>
              <span style={{ color: entry.color }}>{entry.name}: </span>
              {entry.value.toFixed(1)}
            </p>
          ))}
        </div>
      );
    }

    return null;
  };

  const CustomTick = ({ x, y, payload, notesMapping, strokeColor }) => {
    const [day, month] = payload.value.split('/');
    const formattedDate = `${day}/${month}`;
    const hasNotes =
      notesMapping[payload.value] && notesMapping[payload.value].length > 0;
    if (hasNotes) {
      return (
        <>
          <g transform={`translate(${x},${y})`}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="12px"
              viewBox="0 0 24 24"
              width="12px"
              fill={strokeColor}
              x={-6}
              y={-12}
            >
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path d="M20 2H4c-1.1 0-2 .9-2 2v18l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2z" />
            </svg>
          </g>
          <text
            x={x}
            y={y}
            fontSize="10px"
            fill="#666"
            textAnchor="middle"
            dy={16}
          >
            {formattedDate}
          </text>
        </>
      );
    }
    return (
      <text x={x} y={y} fill="#666" fontSize="10px" textAnchor="middle" dy={16}>
        {formattedDate}
      </text>
    );
  };

  const startDate = new Date(jsonData[zoomRange ? zoomRange[0] : 0]?.day);
  const endDate = new Date(
    jsonData[zoomRange ? zoomRange[1] : jsonData.length - 1]?.day
  );

  const isOverSixMonths = endDate - startDate > 6 * 30 * 24 * 60 * 60 * 1000; // 6 months in milliseconds

  const currentDataLength = zoomRange
    ? zoomRange[1] - zoomRange[0]
    : jsonData.length;

  const interval = isOverSixMonths
    ? 'preserveStartEnd'
    : currentDataLength > 210
    ? 42
    : currentDataLength > 160
    ? 28
    : currentDataLength > 82
    ? 14
    : currentDataLength > 41
    ? 7
    : currentDataLength > 20
    ? 2
    : currentDataLength > 13
    ? 1
    : 0;

  return (
    <Box>
      {!data || error ? (
        <Box
          height={300}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          <ResponsiveContainer width="100%" height={300}>
            <ComposedChart
              width={500}
              height={200}
              data={jsonData}
              margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
            >
              <CartesianGrid stroke="#092447" strokeDasharray="5 5" />
              <XAxis
                dataKey="day"
                axisLine={{ stroke: '#173f75' }}
                strokeDasharray="5 5"
                interval={interval}
                tick={
                  <CustomTick
                    notesMapping={notesMapping}
                    strokeColor={theme.palette.warning.main}
                  />
                }
              />
              <YAxis axisLine={{ stroke: '#173f75' }} strokeDasharray="5 5" />
              <Tooltip
                cursor={{ strokeDasharray: '3 3' }}
                wrapperStyle={{ zIndex: 100 }}
                content={renderTooltip}
              />
              <Legend
                wrapperStyle={{ paddingBottom: '30px' }}
                verticalAlign="top"
                onClick={handleLegendClick}
              />
              {dataKeys.map((key, index) => (
                <Area
                  key={key}
                  dataKey={key}
                  stroke={areaColors[index % areaColors.length]}
                  fill={areaColors[index % areaColors.length]}
                  connectNulls={connectNulls}
                  hide={!selected.includes(key)}
                />
              ))}
              {hasZoom && (
                <Brush
                  dataKey="day"
                  height={30}
                  fill={theme.palette.background.paper}
                  stroke="#8884d8"
                  onChange={handleBrushChange}
                />
              )}
            </ComposedChart>
          </ResponsiveContainer>
          <Stack sx={{ mt: 2 }} alignItems="center">
            <FormLabel>{name}</FormLabel>
          </Stack>
        </Box>
      )}
    </Box>
  );
};

export default AreaChart;

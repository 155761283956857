import { Typography } from '@mui/material';

export function CustomFormLabel({ children, ...props }) {
  return (
    <Typography
      sx={{
        fontSize: '0.8rem',
        lineHeight: '0.78rem',
        color: '#71717a',
        whiteSpace: 'nowrap'
      }}
      {...props}
    >
      {children}
    </Typography>
  );
}

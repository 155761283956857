import { useState } from 'react';

import { LoadingButton } from '@mui/lab';
import {
  Box,
  Collapse,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Typography
} from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import { useSnackbar } from 'context/SnackBar';
import { updateClient } from 'graphql/mutations';

import {
  ClinicianCard,
  ErrorMessage,
  PrivateClientCard
} from 'components/atoms';

import { getHighestPermissionLevel } from 'utils/userRoles';

import Modal from '../Modal';

export default function AssignClients({
  user,
  open,
  setOpen,
  clinicians,
  selectedClients,
  setSelectClients,
  setSelectedClients
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [assigning, setAssigning] = useState(false);
  const [error, setError] = useState(null);
  const highestPermission = getHighestPermissionLevel(user);
  const locationID = user.attributes['custom:locationID'];
  const practiceManagerWithLocation =
    highestPermission === 'practicemanager' && locationID;

  const [selectedClinician, setSelectedClinician] = useState(null);
  const handleSelectClinician = clinician => {
    setSelectedClinician(current => {
      if (current && clinician.id === current.id) {
        return null;
      } else {
        return clinician;
      }
    });
  };

  const filteredClinicians = practiceManagerWithLocation
    ? clinicians.filter(clinician => clinician.locationID === locationID)
    : clinicians;

  const handleAssignClient = async () => {
    try {
      setAssigning(true);
      for (let client of selectedClients) {
        const variables = {
          id: client.id,
          clinicianID: selectedClinician.id,
          clinicianClientsId: selectedClinician.id,
          _version: client._version
        };
        if (selectedClinician.locationID) {
          variables.locationID = selectedClinician.locationID;
          variables.locationClientsId = selectedClinician.locationID;
        }
        await API.graphql(
          graphqlOperation(updateClient, {
            input: variables
          })
        );
      }
      enqueueSnackbar(
        `Assigned ${selectedClients.length} client${
          selectedClients.length === 1 ? '' : 's'
        } to ${selectedClinician.firstName}`,
        {
          severity: 'success'
        }
      );
      setOpen(false);
      setSelectedClients([]);
      setSelectedClinician(null);
      setSelectClients(false);
    } catch (err) {
      console.log('err', err);
      enqueueSnackbar(
        `Error assigning ${setSelectedClients.length} client${
          setSelectedClients.length === 1 ? '' : 's'
        } to ${selectedClinician.firstName}`,
        {
          severity: 'error'
        }
      );
      setError(err.message);
    } finally {
      setAssigning(false);
    }
  };

  return (
    <Modal
      title="Assign clients to clinician"
      open={open}
      setClose={() => setOpen(false)}
      fullWidth
      maxWidth="md"
    >
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <Typography variant="h5">
              Assign {selectedClients.length === 1 ? 'this' : 'these'}{' '}
              {selectedClients.length > 0 && selectedClients.length}{' '}
              {selectedClients.length === 1 ? 'client' : 'clients'} ...
            </Typography>
            <Divider />
            <Box sx={{ maxHeight: 400, overflowX: 'scroll' }}>
              {selectedClients?.map(client => (
                <Box key={client.id} my={1} pr={2}>
                  <PrivateClientCard
                    slim
                    setSelectedClients={setSelectedClients}
                    selectedClients={selectedClients}
                    client={client}
                    user={user}
                  />
                </Box>
              ))}
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h5">To this clinician ...</Typography>
            <Divider />
            <Box sx={{ maxHeight: 400, overflowY: 'scroll' }}>
              {filteredClinicians?.map(clinician => {
                const shouldDisplayCard =
                  !selectedClinician || clinician.id === selectedClinician?.id;

                return (
                  <Collapse in={shouldDisplayCard} key={clinician.id}>
                    <Box my={1} pr={2}>
                      <ClinicianCard
                        selected={
                          selectedClinician &&
                          clinician.id === selectedClinician.id
                        }
                        selectClinician={() => handleSelectClinician(clinician)}
                        slim
                        dark
                        selectable
                        clinician={clinician}
                      />
                    </Box>
                  </Collapse>
                );
              })}
            </Box>
          </Grid>
        </Grid>
        {Boolean(error) && <ErrorMessage message={error} />}
      </DialogContent>
      <DialogActions>
        <LoadingButton
          variant="contained"
          onClick={handleAssignClient}
          loading={assigning}
        >
          Assign
        </LoadingButton>
      </DialogActions>
    </Modal>
  );
}

import React, { useRef, useState } from 'react';

import {
  Add,
  Close,
  Delete,
  DragIndicator,
  Info,
  Remove,
  Settings
} from '@mui/icons-material';
import {
  Box,
  Card,
  Collapse,
  Hidden,
  IconButton,
  InputLabel,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { useAppContext } from 'context';
import { useDebouncedCallback } from 'use-debounce';

import { useUpdateGoal } from 'components/goals/functions';

import InfoSection from '../InfoSection';

const InfiniteSettings = ({ onSettingChange, settings, setShowSettings }) => {
  return (
    <Card
      elevation={0}
      sx={{ my: 1, px: 1, backgroundColor: 'background.settings' }}
    >
      <Stack
        direction="row"
        spacing={1}
        px={1}
        py={1}
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        <IconButton
          size="small"
          color="tertiary"
          onClick={() => setShowSettings(prev => !prev)}
        >
          <Close fontSize="small" />
        </IconButton>
        <InputLabel>Infinite Integer Settings</InputLabel>
      </Stack>
      <Stack direction="row" spacing={1}>
        <TextField
          color="tertiary"
          variant="filled"
          size="small"
          label="Name"
          value={settings.name}
          onChange={event => onSettingChange?.('name', event.target.value)}
        />
        <TextField
          sx={{ width: 80 }}
          color="tertiary"
          variant="filled"
          size="small"
          type="number"
          label="From"
          value={settings.from}
          onChange={event =>
            onSettingChange?.('from', Number(event.target.value))
          }
        />
        <TextField
          sx={{ width: 80 }}
          variant="filled"
          size="small"
          type="number"
          color="tertiary"
          label="To"
          value={settings.to}
          onChange={event =>
            onSettingChange?.('to', Number(event.target.value))
          }
        />
      </Stack>
    </Card>
  );
};

const Infinite = ({
  userInput,
  component,
  dragHandleProps,
  initialData,
  name,
  from,
  to,
  onDelete,
  onDataChange,
  onSettingChange
}) => {
  const { state } = useAppContext();
  const [initialValue, setInitialValue] = useState(initialData ?? 0);
  const [settings, setSettings] = useState({ name, from, to });
  const [showSettings, setShowSettings] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);
  const [currentValue, setCurrentValue] = useState(initialData ?? from ?? 0);
  const [intervalId, setIntervalId] = useState(null);
  const [fastIntervalId, setFastIntervalId] = useState(null);
  const [superFastIntervalId, setSuperFastIntervalId] = useState(null);
  const [superSuperFastIntervalId, setSuperSuperFastIntervalId] =
    useState(null);
  const isPressing = useRef(false);
  const updateRecordAndGoalEntry = useUpdateGoal();

  const handleSettingChange = (settingName, settingValue) => {
    setSettings(prev => ({
      ...prev,
      [settingName]: settingValue
    }));

    onSettingChange?.(settingName, settingValue);
  };

  const debouncedDatabaseUpdate = useDebouncedCallback(newValue => {
    const change = newValue - initialValue;
    setInitialValue(newValue);
    updateRecordAndGoalEntry({
      action: 'update',
      userInput,
      change: change
    });
    onDataChange?.(newValue, userInput?.id);
  }, 500);

  const updateValue = delta => {
    setCurrentValue(prev => {
      const newValue = Math.max(
        settings.from,
        Math.min(settings.to, prev + delta)
      );

      debouncedDatabaseUpdate(newValue);
      return newValue;
    });
  };

  const handleButtonPress = delta => {
    if (!isPressing.current) {
      isPressing.current = true;
      updateValue(delta);
      const id = setInterval(() => updateValue(delta), 200);
      setIntervalId(id);

      const fastId = setTimeout(() => {
        clearInterval(id);
        const fasterIntervalId = setInterval(() => updateValue(delta), 100);
        setFastIntervalId(fasterIntervalId);

        const superFastId = setTimeout(() => {
          clearInterval(fasterIntervalId);
          const superFasterIntervalId = setInterval(
            () => updateValue(delta),
            50
          );
          setSuperFastIntervalId(superFasterIntervalId);

          const superSuperFastId = setTimeout(() => {
            clearInterval(superFasterIntervalId);
            const superSuperFasterIntervalId = setInterval(
              () => updateValue(delta),
              25
            );
            setSuperSuperFastIntervalId(superSuperFasterIntervalId);
          }, 500);

          setSuperSuperFastIntervalId(superSuperFastId);
        }, 500);

        setSuperFastIntervalId(superFastId);
      }, 500);

      setFastIntervalId(fastId);
    }
  };

  const handleButtonRelease = () => {
    if (isPressing.current) {
      clearInterval(intervalId);
      clearInterval(fastIntervalId);
      clearTimeout(fastIntervalId);
      clearInterval(superFastIntervalId);
      clearTimeout(superFastIntervalId);
      clearInterval(superSuperFastIntervalId);
      clearTimeout(superSuperFastIntervalId);
      setIntervalId(null);
      setFastIntervalId(null);
      setSuperFastIntervalId(null);
      setSuperSuperFastIntervalId(null);
      isPressing.current = false;
    }
  };

  const handleTextFieldChange = event => {
    const newValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setCurrentValue(prev => {
      const validValue = isNaN(newValue)
        ? prev
        : Math.max(settings.from, Math.min(settings.to, newValue));

      debouncedDatabaseUpdate(validValue);
      return validValue;
    });
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Stack
          width="100%"
          direction="row"
          spacing={1}
          sx={{ alignItems: 'center' }}
        >
          {state.edit && (
            <IconButton
              onClick={() => setShowSettings(prev => !prev)}
              size="small"
            >
              <Settings
                size="small"
                color={showSettings ? 'secondary' : 'primary'}
              />
            </IconButton>
          )}
          <Box
            alignItems="center"
            display="flex"
            width="100%"
            sx={{
              alignItems: { xs: 'flex-start' },
              justifyContent: 'space-between',
              flexDirection: {
                xs: 'column',
                sm: 'row'
              }
            }}
          >
            <Typography variant="h5"> {settings.name}</Typography>
            {component?.showInfo !== false && (
              <IconButton
                sx={{ ml: { xs: 0, sm: 1 }, pt: { xs: 1, sm: 0 } }}
                onClick={() => setOpenInfo(prevOpenInfo => !prevOpenInfo)}
                color={openInfo ? 'secondary' : 'grey'}
              >
                <Info fontSize="large" />
              </IconButton>
            )}
          </Box>
          {state.edit && settings.info && (
            <IconButton
              color={openInfo ? 'secondary' : 'default'}
              size="small"
              onClick={e => setOpenInfo(prevOpenInfo => !prevOpenInfo)}
            >
              <Info />
            </IconButton>
          )}
        </Stack>
        {state.edit && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton onClick={onDelete}>
              <Delete />
            </IconButton>
            <IconButton {...dragHandleProps}>
              <DragIndicator />
            </IconButton>
          </Box>
        )}
      </Box>
      <Collapse in={state.edit && showSettings}>
        <Hidden smDown implementation="css">
          <InfiniteSettings
            onSettingChange={handleSettingChange}
            settings={settings}
            setShowSettings={setShowSettings}
          />
        </Hidden>
      </Collapse>
      <Collapse in={openInfo}>
        <InfoSection component={component} />
      </Collapse>
      <Stack alignItems="center" my={2} direction="row" spacing={2}>
        <IconButton
          onMouseDown={() => handleButtonPress(-1)}
          onMouseUp={handleButtonRelease}
          onTouchStart={() => handleButtonPress(-1)}
          onTouchEnd={handleButtonRelease}
        >
          <Remove />
        </IconButton>
        <TextField
          type="number"
          onChange={handleTextFieldChange}
          value={currentValue}
          variant="outlined"
          size="small"
          sx={{
            maxWidth: '80px',
            '& .MuiInputBase-input': {
              textAlign: 'center',
              padding: 0,
              fontSize: 'x-large',
              // Hide the spinner buttons
              '-webkit-appearance': 'textfield', // for Chrome, Safari, newer versions of Opera
              '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0
              },
              '&::-webkit-clear-button': {
                display: 'none'
              },
              '&::-moz-focus-inner': {
                border: 0 // for Firefox
              },
              '-moz-appearance': 'textfield', // for Firefox
              '&::-ms-clear': {
                display: 'none' // for Internet Explorer
              }
            }
          }}
        />

        <IconButton
          onMouseDown={() => handleButtonPress(1)}
          onMouseUp={handleButtonRelease}
          onTouchStart={() => handleButtonPress(1)}
          onTouchEnd={handleButtonRelease}
        >
          <Add />
        </IconButton>
      </Stack>
    </Box>
  );
};

export default Infinite;

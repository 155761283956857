import { useCallback, useEffect, useState } from 'react';

import { Add, Edit, Save, Videocam } from '@mui/icons-material';
import {
  Box,
  Button,
  ButtonBase,
  Container,
  Divider,
  Grid,
  Hidden,
  Stack,
  Tab,
  Tabs,
  Typography
} from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import { useAppContext } from 'context';
import { createSession as createSessionMutation } from 'graphql/mutations';
import mixpanel from 'mixpanel-browser';
import {
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate
} from 'react-router-dom';
import uuid from 'react-uuid';
import Entries from 'routes/client/entries';
import Entry from 'routes/client/entry';
import ClientHome from 'routes/client/home';
import Insights from 'routes/client/insights';
import Record from 'routes/client/record';
import Resources from 'routes/client/resources';
import Resource from 'routes/client/resources/resource';
import Sessions from 'routes/client/sessions';
import Session from 'routes/client/sessions/session';

import { ClientName, ProfileAvatar } from 'components/atoms';
import { Clinician } from 'components/layout';

import Info from './info';
import Report from './report';
import ClinicianClientSettings from './settings';

export default function Client() {
  const { dispatch, state } = useAppContext();
  const client = state?.client;
  const [selectedTab, setSelectedTab] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  const handleTabChange = useCallback((event, newValue) => {
    setSelectedTab(newValue);
  }, []);

  const shouldShowEditSwitch =
    location.pathname === '/client/new' ||
    location.pathname.includes('/entry') ||
    location.pathname.includes('/insights') ||
    location.pathname.includes('/resources');

  useEffect(() => {
    const pathParts = location.pathname.split('/').slice(2);

    let newValue = 0;

    // Base paths
    if (pathParts[0] === '') newValue = 0;
    else if (pathParts[0] === 'new') newValue = 1;
    else if (pathParts[0] === 'entries') newValue = 2;
    else if (pathParts[0] === 'insights') newValue = 3;
    else if (pathParts[0] === 'sessions') newValue = 4;
    else if (pathParts[0] === 'resources') newValue = 5;
    else if (pathParts[0] === 'program') newValue = 6;
    else if (pathParts[0] === 'settings') newValue = 7;

    // Deeper paths
    if (pathParts[0] === 'entry' && pathParts[1]) newValue = 1;
    if (pathParts[0] === 'entries' && pathParts[1]) newValue = 2;
    if (pathParts[0] === 'session' && pathParts[1]) newValue = 4;
    else if (pathParts[0] === 'resource') newValue = 5;

    setSelectedTab(newValue);
  }, [location]);

  async function createSession() {
    const sessionID = uuid();
    const sessionDate = new Date().toISOString();
    if (client.id !== '') {
      const data = {
        id: sessionID,
        date: sessionDate,
        clientID: client.id,
        owner: `${client.sub}::${client.id}`
      };
      await API.graphql(
        graphqlOperation(createSessionMutation, { input: data })
      );
    }
    return sessionID;
  }

  async function newSession() {
    const sessionID = await createSession();
    dispatch({
      type: 'add_client',
      client: { ...state.client, sessionCount: state.client.sessionCount + 1 }
    });
    navigate(`/client/session/` + sessionID);
    mixpanel.track('create_session');
  }

  return (
    <Clinician
      props={{
        title: `Client - ${client?.clientFirstName || client?.firstName}`
      }}
      AppBarComponents={
        <Box pl={4} width="100%" display="flex" justifyContent="space-between">
          <Stack
            direction="row"
            spacing={2}
            textOverflow="ellipsis"
            alignItems="center"
          >
            <ButtonBase
              sx={{ borderRadius: 5, pr: 2 }}
              focusRipple
              onClick={() => navigate('/client')}
            >
              <Stack
                direction="row"
                spacing={2}
                textOverflow="ellipsis"
                alignItems="center"
              >
                <ProfileAvatar profile={client} />
                <ClientName client={client} variant="h5" />
              </Stack>
            </ButtonBase>

            {location.pathname !== '/client' &&
              !location.pathname.includes('/client/entries/') &&
              location.pathname !== '/client/new' &&
              !location.pathname.includes('/client/entry/') && (
                <Divider
                  sx={{ borderColor: 'rgba(0, 66, 130, 0.3)' }}
                  orientation="vertical"
                />
              )}
            <Typography color="text.primary" variant="h5">
              {location.pathname === '/client/entries' && 'Entries'}
              {location.pathname.includes('/client/insights') && 'Insights'}
              {location.pathname.includes('/client/sessions') && 'Sessions'}
              {location.pathname.includes('/client/session/') && 'Session'}
              {location.pathname.includes('/client/resources') && 'Resources'}
              {location.pathname.includes('/client/settings') && 'Settings'}
            </Typography>
          </Stack>
          <Stack direction={'row'}>
            <Hidden mdDown>
              {shouldShowEditSwitch && (
                <Button
                  size="small"
                  startIcon={state.edit ? <Save /> : <Edit />}
                  onClick={evt => {
                    dispatch({ type: 'set_edit', edit: !state.edit });
                    mixpanel.track(state.edit ? 'saveConfig' : 'editConfig');
                    window.dataLayer.push({
                      event: state.edit ? 'save_config' : 'edit_config'
                    });
                  }}
                  color={state.edit ? 'secondary' : 'primary'}
                >
                  {state.edit ? 'Save' : 'Edit'}
                </Button>
              )}
            </Hidden>
            {location.pathname.includes('session') && (
              <Button
                size="small"
                startIcon={<Add />}
                onClick={() => newSession()}
                color={state.edit ? 'secondary' : 'primary'}
              >
                New Note
              </Button>
            )}
            {location.pathname.includes('client/program') && (
              <Hidden mdDown>
                <Button
                  size="small"
                  onClick={() =>
                    dispatch({
                      type: 'set_change_program',
                      changeProgram: true
                    })
                  }
                  color={state.edit ? 'secondary' : 'primary'}
                >
                  Change Program
                </Button>
              </Hidden>
            )}
          </Stack>
        </Box>
      }
    >
      <Tabs
        sx={{ fontWeight: 'bold', borderBottom: 1, borderColor: '#00428233' }}
        value={selectedTab}
        onChange={handleTabChange}
        scrollButtons
        allowScrollButtonsMobile
        variant="scrollable"
      >
        <Tab
          sx={{ fontWeight: 'bold' }}
          label="Home"
          value={0}
          onClick={() => navigate('/client')}
        />

        <Tab
          sx={{ fontWeight: 'bold' }}
          label="Create"
          iconPosition="start"
          icon={<Add />}
          value={1}
          onClick={() => navigate('/client/new')}
        />
        <Tab
          sx={{ fontWeight: 'bold' }}
          label="Entries"
          value={2}
          onClick={() => navigate('/client/entries')}
        />
        <Tab
          sx={{ fontWeight: 'bold' }}
          iconPosition="start"
          icon={state?.call?.id ? <Videocam color="warning" /> : null}
          label="Notes"
          value={4}
          onClick={() => navigate('/client/sessions')}
        />
        <Tab
          sx={{ fontWeight: 'bold' }}
          label="Reports"
          value={3}
          onClick={() => navigate('/client/insights')}
        />
        <Tab
          sx={{ fontWeight: 'bold' }}
          label="Resources"
          value={5}
          onClick={() => navigate('/client/resources')}
        />
        <Tab
          sx={{ fontWeight: 'bold' }}
          label="Program"
          value={6}
          onClick={() => navigate('/client/program')}
        />
        <Tab
          sx={{ fontWeight: 'bold' }}
          label="Settings"
          value={7}
          onClick={() => navigate('/client/settings')}
        />
      </Tabs>

      <Container maxWidth="xl">
        <Box sx={{ pl: { xs: 0, sm: 2 } }}>
          <Grid container>
            <Grid item xs={12}>
              <Outlet />
              <Routes>
                <Route index element={<Report />} />
                <Route
                  path="new"
                  element={
                    <Box mt={4}>
                      <ClientHome />
                    </Box>
                  }
                />
                <Route path="entries" element={<Entries />} />
                <Route path="entry/:id" element={<Entry />} />
                <Route path="entries/:id" element={<Record />} />
                <Route path="insights" element={<Insights />} />
                <Route path="sessions" element={<Sessions />} />
                <Route path="session/:id" element={<Session />} />
                <Route path="resources" element={<Resources />} />
                <Route path="program" element={<Info />} />
                <Route path="report" element={<Report />} />
                <Route path="resource/:id" element={<Resource />} />
                <Route path="settings" element={<ClinicianClientSettings />} />
              </Routes>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Clinician>
  );
}

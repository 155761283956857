import { Delete, DragIndicator, Info, Settings } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';

export default function RaterLabel({
  dragHandleProps = {},
  label,
  showInfo,
  handleOpenInfoClick,
  setShowSettings,
  showSettings,
  openInfo,
  state,
  onDelete
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
    >
      <Box width="100%" sx={{ display: 'flex', alignItems: 'center' }}>
        {state.edit && (
          <IconButton
            sx={{ ml: 1 }}
            onClick={() =>
              setShowSettings(prevShowSettings => !prevShowSettings)
            }
            color={openInfo ? 'secondary' : 'grey'}
            size="small"
          >
            <Settings
              size="small"
              color={showSettings ? 'secondary' : 'primary'}
            />
          </IconButton>
        )}
        <Box
          alignItems="center"
          display="flex"
          width="100%"
          sx={{
            alignItems: { xs: 'flex-start', sm: 'center' },
            justifyContent: 'space-between',
            flexDirection: {
              xs: 'column',
              sm: 'row'
            }
          }}
        >
          <Typography variant="h5">{label}</Typography>
          {showInfo !== false && (
            <IconButton
              onClick={handleOpenInfoClick}
              color={openInfo ? 'secondary' : 'grey'}
            >
              <Info fontSize="large" />
            </IconButton>
          )}
        </Box>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {state.edit && (
          <>
            <IconButton onClick={onDelete}>
              <Delete />
            </IconButton>
            <IconButton {...dragHandleProps}>
              <DragIndicator />
            </IconButton>
          </>
        )}
      </Box>
    </Box>
  );
}

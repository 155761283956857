import { useEffect, useState } from 'react';

import { Edit, Share } from '@mui/icons-material';
import {
  Box,
  Button,
  Container,
  Divider,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import { getResource, resourceContentsByResourceID } from 'graphql/queries';
import { useParams } from 'react-router-dom';

import { BackButton } from 'components/atoms';
import { Clinician } from 'components/layout';
import { ResourceInfo } from 'components/molecules';
import { AddResourceToClient, ResourceContent } from 'components/organisms';

function Resource() {
  const { id: resourceID } = useParams();
  const [resource, setResource] = useState();
  const [resourceContent, setResourceContent] = useState();
  const [addToClient, setAddToClient] = useState(false);
  const [selectedResources, setSelectedResources] = useState([]);

  useEffect(() => {
    const fetchResource = async () => {
      const { data } = await API.graphql(
        graphqlOperation(getResource, { id: resourceID })
      );
      setResource(data.getResource);
    };
    const fetchResourceContent = async () => {
      const { data } = await API.graphql(
        graphqlOperation(resourceContentsByResourceID, {
          resourceID: resourceID
        })
      );
      setResourceContent(data.resourceContentsByResourceID.items);
    };

    if (resourceID) {
      fetchResource();
      fetchResourceContent();
    }
    return () => {
      setResource(null);
      setResourceContent(null);
    };
  }, [resourceID]);

  const chooseResource = resource => {
    const isResourceSelected = selectedResources.some(
      selectedResource => selectedResource.id === resource.id
    );

    if (isResourceSelected) {
      setSelectedResources(prevResources =>
        prevResources.filter(
          selectedResource => selectedResource.id !== resource.id
        )
      );
    } else {
      setSelectedResources(prevResources => [...prevResources, resource]);
    }
  };

  return (
    <Clinician
      props={{ title: 'Resource' }}
      AppBarComponents={
        <Box pl={4} width="100%" display="flex" justifyContent="space-between">
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            sx={{ display: 'flex', overflow: 'hidden' }}
          >
            <Typography color="text.primary" variant="h5">
              Resource
            </Typography>
            <Divider
              sx={{ borderColor: 'rgba(0, 66, 130, 0.3)' }}
              orientation="vertical"
            />
            <Box
              sx={{
                flex: '1 1 auto',
                whiteSpace: 'nowrap',
                overflow: 'hidden'
              }}
            >
              {resource?.title && (
                <Typography
                  color="text.primary"
                  sx={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden'
                  }}
                  variant="h6"
                >
                  {resource.title}
                </Typography>
              )}
            </Box>
          </Stack>
          <Stack>
            <Stack direction="row" spacing={2}>
              <Tooltip title="Please contact us to enable your private library.">
                <span>
                  <Button disabled size="small" startIcon={<Edit />}>
                    Edit Resource
                  </Button>
                </span>
              </Tooltip>
            </Stack>
          </Stack>
        </Box>
      }
    >
      <AddResourceToClient
        setSelectedResources={setSelectedResources}
        selectedResources={selectedResources}
        state={addToClient}
        setState={setAddToClient}
      />
      <Box sx={{ my: 4 }}>
        <Container maxWidth="md">
          <Stack direction="row" justifyContent="space-between">
            <BackButton />
            <Button
              onClick={() => {
                chooseResource(resource);
                setAddToClient(true);
              }}
              endIcon={<Share />}
            >
              Share with Client
            </Button>
          </Stack>
          <Box my={2}>
            <Typography align="center" variant="h2">
              {resource?.title}
            </Typography>
          </Box>
          {resource?.subTitle && (
            <Box pb={2} my={1}>
              <Typography align="center" variant="h6">
                {resource.subTitle}
              </Typography>
            </Box>
          )}
        </Container>
        <Box mt={2}>
          {resourceContent?.map(content => (
            <ResourceContent key={content.id} content={content} />
          ))}
        </Box>
        <Box>
          <ResourceInfo resource={resource} />
        </Box>
      </Box>
    </Clinician>
  );
}

export default Resource;

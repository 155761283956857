import { useEffect, useState } from 'react';

import { Box, Grid, Tab, Tabs, useMediaQuery, useTheme } from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import { useAppContext } from 'context';
import { updateClient } from 'graphql/mutations';
import { getEmailVerificationStatus } from 'graphql/queries';

import {
  ClientAccountEmail,
  ClientAccountName,
  ClientAccountType,
  ClientAge,
  CreateSupportRequest,
  SupportRequests,
  TabPanel
} from 'components/atoms';
import {
  AccountConnection,
  ExportClient,
  ImportClient,
  Participants,
  ProfilePicture,
  ResendInvitation
} from 'components/molecules';

export default function ClinicianClientSettings() {
  const { state, dispatch } = useAppContext();
  const client = state.client;
  const [loadingEmailVerified, setLoadingEmailVerified] = useState(true);
  const [emailVerified, setEmailVerified] = useState(
    client?.status === 'verified'
  );
  const [tabValue, setTabValue] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const tabOrientation = isMobile ? 'horizontal' : 'vertical';
  const tabWidth = isMobile ? '100%' : '200px';
  const tabPanelWidth = isMobile ? '100%' : 'calc(100% - 200px)';
  const [supportRequestsExist, setSupportRequestsExist] = useState(false);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    const checkStatus = async () => {
      const { data } = await API.graphql(
        graphqlOperation(getEmailVerificationStatus, {
          userID: client.id
        })
      );
      const parsedRes = JSON.parse(data.getEmailVerificationStatus);
      if (parsedRes.emailVerified) {
        const input = {
          id: client?.id,
          status: 'verified',
          _version: client._version
        };
        await API.graphql(graphqlOperation(updateClient, { input: input }));

        dispatch({
          type: 'set_client',
          client: {
            ...client,
            status: 'verified',
            _version: client._version + 1
          }
        });
      }
      setEmailVerified(parsedRes.emailVerified);
    };

    if (client?.id) {
      if (!emailVerified && client.status !== 'verified') {
        try {
          checkStatus();
        } catch (err) {
          console.log('err checking email verification status', err);
        } finally {
          setLoadingEmailVerified(false);
        }
      } else {
        setEmailVerified(true);
        setLoadingEmailVerified(false);
      }
    }
  }, [client, dispatch, emailVerified]);

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
        height: '100%',
        flexDirection: isMobile ? 'column' : 'row'
      }}
    >
      <Tabs
        orientation={tabOrientation}
        variant="scrollable"
        scrollButtons="auto"
        value={tabValue}
        onChange={handleTabChange}
        aria-label="Vertical tabs for settings"
        sx={{
          borderRight: isMobile ? 0 : 1,
          borderBottom: isMobile ? 1 : 0,
          borderColor: '#00428233',
          width: tabWidth
        }}
      >
        <Tab sx={{ fontWeight: 600 }} label="Account" />
        <Tab sx={{ fontWeight: 600 }} label="Participants" />
        <Tab sx={{ fontWeight: 600 }} label="Support" />
        <Tab sx={{ fontWeight: 600 }} label="Admin" />
      </Tabs>
      <Box sx={{ flexGrow: 1, width: tabPanelWidth }}>
        <TabPanel value={tabValue} index={0}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
              <ClientAccountName />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
              <ClientAccountEmail />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
              <ResendInvitation
                loadingEmailVerified={loadingEmailVerified}
                emailVerified={emailVerified}
              />
            </Grid>
            {client?.clientFirstName && (
              <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
                <ClientAccountName forClient />
              </Grid>
            )}
            <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
              <ClientAccountType />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
              <ClientAge />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
              <ProfilePicture
                firstName={state.client?.firstName}
                client={client}
                forClient
              />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <Participants
            clientFirstName={client?.clientFirstName || client?.firstName}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <CreateSupportRequest
            setSupportRequestsExist={setSupportRequestsExist}
            supportRequestsExist={supportRequestsExist}
          />
          <SupportRequests
            supportRequestsExist={supportRequestsExist}
            setSupportRequestsExist={setSupportRequestsExist}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
              <ImportClient />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
              <ExportClient />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
              <AccountConnection firstName={state.client?.firstName} />
            </Grid>
          </Grid>
        </TabPanel>
      </Box>
    </Box>
  );
}

import { useState } from 'react';

import {
  Box,
  FormLabel,
  Link,
  Slider,
  Tooltip,
  Typography
} from '@mui/material';

export default function AgeRange({ age, setAge, forValue }) {
  const [isFocused, setIsFocused] = useState(false);

  const valueConfigs = {
    child: { start: 1, end: 18 },
    me: { start: 13, end: 65 },
    adult: { start: 13, end: 65 },
    other: { start: 1, end: 75 }
  };

  const { start = 1, end = 18 } = valueConfigs[forValue] || {};
  const displayStep = ['me', 'adult', 'other'].includes(forValue) ? 5 : 1;

  const marks = Array.from({ length: end - start + 1 }, (_, i) => {
    const value = start + i;
    return {
      value: value,
      label: value % displayStep === 0 ? `${value}` : undefined
    };
  });

  const labelMessages = {
    child: "Child's Age",
    me: 'My Age:',
    adult: 'Age',
    carer: 'Their Age',
    other: 'Their Age'
  };

  const tooltipMessages = {
    child: `your child's`,
    me: 'for your',
    other: 'an',
    adult: `your client's`,
    carer: `your client's`
  };

  return (
    <Box sx={{ width: '100%' }}>
      <FormLabel
        htmlFor="age-slider"
        sx={{
          ...(isFocused && {
            color: 'primary.main'
          })
        }}
      >
        {labelMessages[forValue]}
      </FormLabel>

      <Slider
        id="age-slider"
        aria-label="Age slider"
        value={age}
        onChange={(event, newValue) => setAge(newValue)}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        step={1}
        marks={marks}
        min={start}
        max={end}
        valueLabelDisplay="auto"
      />
      <Tooltip
        placement="top"
        title={`We request ${
          tooltipMessages[forValue] || 'an'
        } age so we can tailor SpeechFit with appropriate settings. SpeechFit is structured around the clinical handbook's programs, which vary based on age.`}
      >
        <Typography variant="caption" sx={{ fontSize: 'x-small' }}>
          <Link>Why do we need this?</Link>
        </Typography>
      </Tooltip>
    </Box>
  );
}

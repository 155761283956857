import { useEffect } from 'react';

const TawkChatBody = () => {
  useEffect(() => {
    // Create script element
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://embed.tawk.to/65189bb610c0b25724874214/1hbk20hc6';
    script.charset = 'UTF-8';
    script.setAttribute('crossorigin', '*');

    // Append to the top of the body
    document.body.insertBefore(script, document.body.firstChild);

    // Clean up script when component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return null; // Component does not render anything
};

export default TawkChatBody;

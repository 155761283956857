import { Upload } from '@mui/icons-material';
import {
  Box,
  Button,
  FormControlLabel,
  FormLabel,
  Stack,
  Switch,
  TextField
} from '@mui/material';

import { EntrySelector } from 'components/atoms';

export default function StepOne({
  selectedEntry,
  setSelectedEntry,
  setDataInput,
  dataInput,
  handleFileUpload,
  hasHeader,
  setHasHeader
}) {
  return (
    <Box>
      <Box my={2}>
        <FormLabel>Select Entry Category</FormLabel>
        <EntrySelector
          selectedEntry={selectedEntry}
          setSelectedEntry={setSelectedEntry}
        />
      </Box>
      <TextField
        value={dataInput}
        onChange={e => setDataInput(e.target.value)}
        multiline
        minRows={10}
        fullWidth
        label="Add Data"
        placeholder={
          'Copy and paste spreadsheet, or add one row per line with values separated by commas.'
        }
        inputProps={{
          sx: {
            maxHeight: 250,
            overflowY: 'auto'
          }
        }}
      />
      <Stack sx={{ mt: 2 }} direction="row" spacing={1} alignItems="center">
        <Button
          color="tertiary"
          startIcon={<Upload />}
          variant="text"
          component="label"
          size="small"
        >
          Upload CSV
          <input type="file" accept=".csv" hidden onChange={handleFileUpload} />
        </Button>
        <FormControlLabel
          label="First Line is Header"
          sx={{
            '& .MuiFormControlLabel-label': {
              fontSize: '0.8125rem',
              textTransform: 'uppercase'
            }
          }}
          control={
            <Switch
              size="small"
              color="tertiary"
              checked={hasHeader}
              onChange={e => setHasHeader(e.target.checked)}
            />
          }
        />
      </Stack>
    </Box>
  );
}

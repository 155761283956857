import { useEffect, useState } from 'react';

import { Add } from '@mui/icons-material';
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { API, Storage, graphqlOperation } from 'aws-amplify';
import { deleteLocation, updateClinician } from 'graphql/mutations';
import {
  clientsClinicConnection,
  cliniciansByLocationByRole,
  updateUserAttributes
} from 'graphql/queries';
import { getImageFromIndexedDB, saveImageToIndexedDB } from 'indexedDB';

import { LocationCard, SettingHeader } from 'components/atoms';
import { AddLocation, Modal } from 'components/molecules';

export default function PracticeSettings({
  locations,
  setLocations,
  clinic,
  setClinicians
}) {
  const [updatePracticeName, setUpdatePracticeName] = useState(false);
  const [updateWebsite, setUpdateWebsite] = useState(false);
  const [updateDescription, setUpdateDescription] = useState(false);
  const [image, setImage] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [openAddLocation, setOpenAddLocation] = useState(false);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        style={{ flexGrow: 1 }}
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  async function getImageBlobFromS3(imageID, imageType) {
    try {
      const key = `clinics/${imageID}.${imageType}`;
      const blob = await Storage.get(key, { download: true });
      return blob.Body;
    } catch (error) {
      console.error('Error fetching image from S3:', error);
      return null;
    }
  }

  useEffect(() => {
    let imageUrl;

    async function fetchImage() {
      if (clinic?.id) {
        const blob = await getImageFromIndexedDB(clinic?.id);
        if (blob) {
          imageUrl = URL.createObjectURL(blob);
          setImage(imageUrl);
        } else {
          const blobFromS3 = await getImageBlobFromS3(
            clinic?.id,
            clinic.imageType
          ); // Using new function
          await saveImageToIndexedDB(clinic?.id, blobFromS3);
          imageUrl = URL.createObjectURL(blobFromS3);
          setImage(imageUrl);
        }
      }
    }
    if (clinic?.hasLogo) {
      fetchImage();
    }
    return () => {
      if (imageUrl) {
        URL.revokeObjectURL(imageUrl);
      }
    };
  }, [clinic]);

  const tabOrientation = isMobile ? 'horizontal' : 'vertical';
  const tabWidth = isMobile ? '100%' : '200px';
  const tabPanelWidth = isMobile ? '100%' : 'calc(100% - 200px)';

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleDeleteLocation = async location => {
    try {
      let promises = [];
      promises.push(
        API.graphql(
          graphqlOperation(clientsClinicConnection, {
            action: 'remove_from_location',
            locationID: location.id
          })
        )
      );
      const { data } = await API.graphql(
        graphqlOperation(cliniciansByLocationByRole, {
          locationID: location.id
        })
      );
      const clinicians = data.cliniciansByLocationByRole.items;
      for (let clinician of clinicians) {
        promises.push(
          API.graphql(
            graphqlOperation(updateClinician, {
              input: {
                id: clinician.id,
                locationID: null,
                _version: clinician._version
              }
            })
          )
        );
        promises.push(
          API.graphql(
            graphqlOperation(updateUserAttributes, {
              userID: clinician.id,
              attributeKey: 'custom:locationID',
              attributeValue: ''
            })
          )
        );
      }

      promises.push(
        API.graphql(
          graphqlOperation(deleteLocation, {
            input: { id: location.id, _version: location._version }
          })
        )
      );
      await Promise.all(promises);
      setLocations(currentLocations =>
        currentLocations.filter(loc => loc.id !== location.id)
      );
      setClinicians(currentClinicians =>
        currentClinicians.map(clinician => {
          if (clinician.locationID === location.id) {
            return { ...clinician, locationID: null };
          }
          return clinician;
        })
      );
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
        height: '100%',
        flexDirection: isMobile ? 'column' : 'row'
      }}
    >
      <Tabs
        orientation={tabOrientation}
        variant="scrollable"
        scrollButtons="auto"
        value={tabValue}
        onChange={handleTabChange}
        aria-label="Vertical tabs for settings"
        sx={{
          borderRight: isMobile ? 0 : 1,
          borderBottom: isMobile ? 1 : 0,
          borderColor: '#00428233',
          width: tabWidth
        }}
      >
        <Tab label="Details" sx={{ fontWeight: 'bold' }} />
        <Tab label="Locations" sx={{ fontWeight: 'bold' }} />
      </Tabs>
      <Box sx={{ flexGrow: 1, width: tabPanelWidth }}>
        <TabPanel value={tabValue} index={0}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="h5">Practice</Typography>
          </Stack>
          <Box mt={1} mb={2}>
            <Divider sx={{ opacity: 0.3 }} />
          </Box>
          <Stack direction="column" spacing={4}>
            {image && (
              <Box>
                <img
                  alt="Logo"
                  src={image}
                  style={{
                    maxHeight: 100,
                    height: '100%',
                    maxWidth: '100%'
                  }}
                />
              </Box>
            )}

            <Box>
              <SettingHeader
                label="Practice Name"
                state={updatePracticeName}
                setState={setUpdatePracticeName}
              />
              {updatePracticeName ? (
                <Box>
                  <TextField
                    fullWidth
                    label="Name"
                    size="small"
                    variant="filled"
                  />
                </Box>
              ) : (
                <Typography variant="h4">{clinic.name}</Typography>
              )}
            </Box>

            {clinic.website && (
              <Box>
                <SettingHeader
                  label="Website"
                  state={updateWebsite}
                  setState={setUpdateWebsite}
                />
                {updateWebsite ? (
                  <Box>
                    <TextField
                      fullWidth
                      label="Website"
                      size="small"
                      variant="filled"
                    />
                  </Box>
                ) : (
                  <Typography variant="h6">{clinic.website}</Typography>
                )}
              </Box>
            )}
          </Stack>
          {clinic.description && (
            <Grid sx={{ mt: 4 }} item xs={12} md={4}>
              <Box>
                <SettingHeader
                  label="Description"
                  state={updateDescription}
                  setState={setUpdateDescription}
                />
                {updateDescription ? (
                  <Box>
                    <TextField
                      fullWidth
                      label="Description"
                      size="small"
                      variant="filled"
                    />
                  </Box>
                ) : (
                  <Typography variant="h6">{clinic.description}</Typography>
                )}
              </Box>
            </Grid>
          )}
        </TabPanel>

        <TabPanel value={tabValue} index={1}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="h5">Locations</Typography>
            <IconButton
              size="small"
              onClick={() =>
                setOpenAddLocation(prevOpenAddLocation => !prevOpenAddLocation)
              }
            >
              <Add />
            </IconButton>
          </Stack>
          <Box mt={1} mb={2}>
            <Divider sx={{ opacity: 0.3 }} />
          </Box>
          <Grid container spacing={2}>
            {locations?.map(location => {
              return (
                <Grid key={location.id} item xs={12} sm={6}>
                  <LocationCard
                    handleDeleteLocation={handleDeleteLocation}
                    action="delete"
                    location={location}
                  />
                </Grid>
              );
            })}
          </Grid>
        </TabPanel>
      </Box>
      <Modal
        open={openAddLocation}
        setClose={() => setOpenAddLocation(false)}
        maxWidth="sm"
        fullWidth
        title="Add a new location"
      >
        <AddLocation
          setOpen={setOpenAddLocation}
          setLocations={setLocations}
          locations={locations}
        />
      </Modal>
    </Box>
  );
}

import React, { useEffect, useState } from 'react';

import { Box, Grid, Hidden } from '@mui/material';
import { useAppContext } from 'context';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

import { UpdateAvailable } from 'components/atoms';
import { Modal } from 'components/molecules';
import {
  AddClientStepper,
  BottomNavigationBar,
  MiniDrawer
} from 'components/organisms';

export default React.memo(function Clinician({
  children,
  props,
  AppBarComponents
}) {
  const { state, dispatch } = useAppContext();
  const [updateAvailable, setUpdateAvailable] = useState(false);
  const [latestVersion, setLatestVersion] = useState(null);
  const location = useLocation();
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    const checkAppVersion = async () => {
      try {
        const response = await fetch('/versions.json');
        const data = await response.json();
        const latestVersion = data.version;
        setLatestVersion(latestVersion);
        const storedVersion = localStorage.getItem('appVersion');
        // eslint-disable-next-line
        if (storedVersion == undefined) {
          localStorage.setItem('appVersion', latestVersion);
        } else if (latestVersion !== storedVersion) {
          setUpdateAvailable(true);
        } else {
          setUpdateAvailable(false);
        }
      } catch (error) {
        console.error('Error fetching app version info:', error);
      }
    };
    checkAppVersion();
  }, []);

  useEffect(() => {
    if (!location.pathname.includes('/client')) {
      dispatch({ type: 'remove_client' });
      dispatch({ type: 'end_call' });
    }
    if (
      !location.pathname.includes('/entries') ||
      !location.pathname.includes('/client/new') ||
      !location.pathname.includes('/client/insights')
    ) {
      dispatch({ type: 'set_edit', edit: false });
    }
    if (
      (!location.pathname.includes('/client/entries/') ||
        !location.pathname.includes('/client/new')) &&
      state.record?.id
    ) {
      dispatch({ type: 'set_record', record: {} });
    }
    // eslint-disable-next-line
  }, [location, dispatch]);

  const handleDrawer = () => {
    dispatch({ type: 'set_menu' });
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Helmet>
        <title>{props?.title + ' | SpeechFit' || 'SpeechFit'}</title>
      </Helmet>
      <UpdateAvailable
        updateAvailable={updateAvailable}
        latestVersion={latestVersion}
      />
      <Modal
        title="Add client"
        fullWidth
        maxWidth="md"
        open={state.modal || false}
        setClose={() => dispatch({ type: 'set_modal', modal: false })}
        disableExit={isProcessing}
      >
        <AddClientStepper setIsProcessing={setIsProcessing} />
      </Modal>
      <Hidden implementation="js" mdDown>
        <MiniDrawer
          AppBarComponents={AppBarComponents}
          open={state.menuOpen}
          handleDrawer={handleDrawer}
        >
          <Grid container>
            <Grid
              sx={{
                px: 2,
                display: 'block'
              }}
              item
              xs={12}
            >
              <Box pt={8}>{children}</Box>
            </Grid>
          </Grid>
        </MiniDrawer>
      </Hidden>
      <Hidden implementation="js" mdUp>
        <Box sx={{ width: '100%' }}>
          <BottomNavigationBar
            AppBarComponents={AppBarComponents}
            open={state.menuOpen}
            handleDrawer={handleDrawer}
          >
            <Box sx={{ width: '100%' }} mt={props?.mt || 8}>
              {children}
            </Box>
          </BottomNavigationBar>
        </Box>
      </Hidden>
    </Box>
  );
});

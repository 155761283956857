import { useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { Info } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Card,
  Collapse,
  FormLabel,
  IconButton,
  Stack
} from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import { useAppContext } from 'context';
import { useSnackbar } from 'context/SnackBar';
import { createSupport } from 'graphql/mutations';

export default function CreateSupportRequest({
  supportRequestsExist,
  setSupportRequestsExist
}) {
  const { state } = useAppContext();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuthenticator(context => [context.user]);
  const clientID =
    state?.client?.id || user.attributes['custom:clientID'] || user.username;
  const clinicianID = state?.clinician.id || user.username;
  const [creating, setCreating] = useState(false);
  const [infoOpen, setInfoOpen] = useState(true);

  const createSupportRequest = async () => {
    try {
      setCreating(true);
      const expiresAt = new Date();
      expiresAt.setHours(expiresAt.getHours() + 72);

      await API.graphql(
        graphqlOperation(createSupport, {
          input: {
            expiresAt: expiresAt.toISOString(),
            clientID: clientID,
            clinicianID: clinicianID,
            createdBy: user.username
          }
        })
      );
      setSupportRequestsExist(true);
      enqueueSnackbar('Created support request', { severity: 'success' });
    } catch (err) {
      console.log('err', err);
      enqueueSnackbar('Error creating support request', {
        severity: 'error'
      });
    } finally {
      setCreating(false);
    }
  };
  return (
    <Box my={2}>
      <Stack
        direction="row"
        sx={{ height: 30, mb: 2 }}
        alignItems="center"
        spacing={1}
      >
        <IconButton
          onClick={() => setInfoOpen(prevInfoOpen => !prevInfoOpen)}
          color={infoOpen ? 'secondary' : 'default'}
        >
          <Info />
        </IconButton>
        <FormLabel sx={{ fontWeight: 600 }}>Support</FormLabel>
      </Stack>
      <Collapse in={infoOpen}>
        <Box>
          <Card
            elevation={0}
            sx={{
              backgroundColor: 'background.settings',
              p: infoOpen ? 2 : 0,
              maxWidth: 600
            }}
          >
            If there is a problem with this account that we can not solve
            another way, you can give us limited access for 72 hours. You can
            revoke this at any time. Please email us at support@speechfit.io
            before you do this.
          </Card>
        </Box>
      </Collapse>
      <Box mt={2}>
        <LoadingButton
          disabled={supportRequestsExist}
          size="small"
          loading={creating}
          variant="contained"
          onClick={createSupportRequest}
        >
          Create Support Request
        </LoadingButton>
      </Box>
    </Box>
  );
}

import {
  Box,
  FormControl,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack
} from '@mui/material';

import { IconChip } from 'components/atoms';

import { capitalizeFirstLetter } from 'utils/capitaliseFirstLetter';
import { formatDate, isDate } from 'utils/dateMatcher';

export default function StepTwo({
  selectedEntry,
  parsedData,
  components,
  hasHeader,
  handleComponentSelection,
  selectedComponents
}) {
  const isDateSelectedElsewhere = currentKey => {
    return Object.entries(selectedComponents).some(
      ([key, value]) => key !== currentKey && value.type === 'Date'
    );
  };

  return (
    <Box>
      <Stack spacing={2} direction="row" alignItems="center" my={4}>
        <FormLabel sx={{ textTransform: 'uppercase' }}>Import To</FormLabel>
        <IconChip entry={selectedEntry} />
      </Stack>

      <Box mt={3} width="100%" sx={{ overflowX: 'auto' }}>
        <div
          style={{
            display: 'flex',
            width: 'max-content',
            flexDirection: 'column'
          }}
        >
          {/* Header Selects as the first row */}
          <div style={{ display: 'flex', width: 'max-content' }}>
            {parsedData &&
              parsedData[0] &&
              Object.keys(parsedData[0]).map((key, index) => (
                <div style={{ minWidth: 200, padding: 10 }} key={index}>
                  <FormControl fullWidth color="tertiary" variant="outlined">
                    <InputLabel id={`section-label-${index}`}>
                      {hasHeader ? capitalizeFirstLetter(key) : 'Field'}
                    </InputLabel>
                    <Select
                      label={hasHeader ? key : 'Field'}
                      fullWidth
                      onChange={e =>
                        handleComponentSelection(key, e.target.value)
                      }
                      value={selectedComponents[key] || ''}
                      renderValue={selectedValue => {
                        if (selectedValue.type === 'Date') {
                          return 'Date';
                        }
                        return selectedValue.name;
                      }}
                    >
                      {!isDateSelectedElsewhere(key) && (
                        <MenuItem value={{ type: 'Date', name: 'Date' }}>
                          Date
                        </MenuItem>
                      )}
                      {components
                        ?.filter(
                          component =>
                            !Object.values(selectedComponents)
                              .map(comp => comp.id)
                              .includes(component.id)
                        )
                        .map(component => (
                          <MenuItem
                            key={component.id}
                            value={{
                              id: component.id,
                              name:
                                component?.name ||
                                component?.entrySection?.name,
                              type: component.type
                            }}
                          >
                            {component?.name || component?.entrySection?.name}
                          </MenuItem>
                        ))}
                      <MenuItem value="">None</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              ))}
          </div>
        </div>

        {parsedData?.slice(0, 10).map((row, rowIndex) => (
          <div
            style={{ display: 'flex', width: 'max-content', marginTop: 10 }}
            key={rowIndex}
          >
            {Object.values(row).map((value, valueIndex) => (
              <div
                style={{
                  minWidth: 200,
                  minHeight: 41,
                  borderBottom: '1px solid',
                  borderColor: '#e0e0e0',
                  padding: 10
                }}
                key={valueIndex}
              >
                {isDate(value)
                  ? formatDate(value)
                  : value
                  ? String(value)
                  : '    '}
              </div>
            ))}
          </div>
        ))}
      </Box>
    </Box>
  );
}

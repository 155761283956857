import { useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { Check } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  AlertTitle,
  Box,
  Collapse,
  Skeleton,
  Typography
} from '@mui/material';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import { useAppContext } from 'context';
import { upgradeProgram as upgradeClientProgram } from 'graphql/queries';

import { ErrorMessage, SettingHeader } from 'components/atoms';

import checkUpgradeAvailability from 'utils/checkProgram';
import { formatProgram } from 'utils/formatProgram';

export default function ClientProgram() {
  const [upgradeProgram, setUpgradeProgram] = useState();
  const [upgrading, setUpgrading] = useState(false);
  const [upgraded, setUpgraded] = useState(false);
  const [error, setError] = useState(null);
  const { state, dispatch } = useAppContext();
  const { user } = useAuthenticator(context => [context.user]);
  const isClinician = user.attributes['custom:type'] === 'clinician';
  const clientID =
    state?.client?.id || user.attributes['custom:clientID'] || user.username;
  const clientSub = state?.client?.sub ?? user.attributes.sub;
  const program = state?.client?.program ?? user.attributes['custom:program'];
  const firstName =
    state?.client?.clientFirstName ||
    state?.client?.firstName ||
    user.attributes.given_name;

  const { upgradeAvailable, newProgram } = checkUpgradeAvailability(program);
  const { enqueueSnackbar } = useAppContext();

  const handleUpgrade = async () => {
    try {
      setUpgrading(true);
      const variables = {
        clientSub: clientSub,
        clientID: clientID,
        clientFirstName: state?.client?.firstName || user.attributes.given_name,
        clientLastName: state?.client?.lastName || user.attributes.family_name,
        clientEmail: state?.client?.email || user.attributes.email,
        newProgram: newProgram,
        clinicianFirstName: isClinician ? user.attributes.given_name : null,
        clinicianLastName: isClinician ? user.attributes.family_name : null,
        clinicianEmail: isClinician ? user.attributes.email : null,
        isClinician: isClinician
      };

      const { data } = await API.graphql(
        graphqlOperation(upgradeClientProgram, variables)
      );
      const parsedRes = JSON.parse(data.upgradeProgram);
      if (parsedRes.statusCode !== 200) {
        throw new Error(parsedRes.error);
      }
      if (isClinician) {
        dispatch({
          type: 'add_client',
          client: { ...state.client, program: newProgram, showCount: true }
        });
      }
      if (!isClinician) {
        await Auth.currentAuthenticatedUser({
          bypassCache: true
        });
      }
      setUpgraded(true);
      enqueueSnackbar(
        `Updated ${firstName}'s ${formatProgram(program)} Program`,
        {
          severity: 'success'
        }
      );
    } catch (err) {
      setError(err.message);
      enqueueSnackbar(
        `Error updating ${firstName}'s ${formatProgram(program)} Program`,
        {
          severity: 'error'
        }
      );
    } finally {
      setUpgrading(false);
    }
  };

  return (
    <Box p={1} my={2}>
      <SettingHeader
        state={upgradeProgram}
        setState={setUpgradeProgram}
        label="Program"
        button={upgradeAvailable && 'Update Available'}
        noAction={!upgradeAvailable}
        hasUpdate={upgradeAvailable}
      />
      <Box p={1} sx={{ display: 'flex', alignItems: 'center' }}>
        {!program ? (
          <Skeleton height={50} width={200} />
        ) : (
          <Typography variant="h6">{formatProgram(program)}</Typography>
        )}
      </Box>
      <Collapse in={upgradeProgram}>
        <Box>
          <Box my={1}>
            <Alert severity="info">
              <AlertTitle>
                New {formatProgram(program)} Program template available
              </AlertTitle>
              Working with experienced clinicians, we have updated the{' '}
              {formatProgram(program)} Program template to better suit you and
              your client's needs. <br /> <br />
              <strong>
                You will not lose data if you update to the new template.
              </strong>
            </Alert>
          </Box>
          <Box my={1}>
            <LoadingButton
              size="small"
              disabled={upgraded}
              fullWidth
              variant="contained"
              loading={upgrading}
              onClick={handleUpgrade}
              endIcon={upgraded && <Check color="primary" />}
            >
              {upgraded ? 'Updated' : 'Update Now'}
            </LoadingButton>
          </Box>
          {Boolean(error) && (
            <Box my={1}>
              <ErrorMessage message={error} />
            </Box>
          )}
        </Box>
      </Collapse>
    </Box>
  );
}

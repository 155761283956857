import { useEffect, useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { Add, Info } from '@mui/icons-material';
import {
  Alert,
  AlertTitle,
  Box,
  CircularProgress,
  Collapse,
  FormLabel,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import { useAppContext } from 'context';
import { useSnackbar } from 'context/SnackBar';
import { updateClient, updateInvitation } from 'graphql/mutations';
import {
  clientsByClientID,
  getClient,
  invitationByAction,
  updateUserAttributes,
  updateUserGroups
} from 'graphql/queries';

import { AddParticipant, Participant } from 'components/atoms';

import Modal from '../Modal';

export default function Participants({ clientFirstName }) {
  const { state } = useAppContext();
  const { user } = useAuthenticator(context => [context.user]);
  const clientID =
    state?.client?.id || user.attributes['custom:clientID'] || user.username;
  const { enqueueSnackbar } = useSnackbar();
  const [openAddParticipant, setOpenAddParticipant] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [participants, setParticipants] = useState([]);
  const [invitations, setInvitations] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchInvitedParticipants = async () => {
      try {
        const { data } = await API.graphql(
          graphqlOperation(invitationByAction, {
            action: 'existing_client_as_participant',
            clientID: { eq: clientID },
            filter: { used: { ne: true } }
          })
        );

        let invitationsToParticipants = data.invitationByAction.items;
        if (invitationsToParticipants.length < 1 && !showInfo) {
          setShowInfo(true);
        }

        const threeDaysAgo = new Date(Date.now() - 72 * 60 * 60 * 1000);
        invitationsToParticipants = invitationsToParticipants.filter(
          invitation => {
            const invitationDate = new Date(invitation.createdAt);
            return invitationDate > threeDaysAgo && !invitation.used;
          }
        );

        setInvitations(invitationsToParticipants);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading();
      }
    };

    const fetchParticipants = async () => {
      try {
        setLoading(true);
        const { data } = await API.graphql(
          graphqlOperation(clientsByClientID, { clientID })
        );
        const participants = data.clientsByClientID.items;
        if (participants.length < 1 && !showInfo) {
          setShowInfo(true);
        }
        setParticipants(participants);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };
    if (clientID) {
      fetchInvitedParticipants();
      fetchParticipants();
    }
    return () => {
      setParticipants([]);
      setInvitations([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientID, openAddParticipant]);

  const removeParticipant = async participant => {
    try {
      await API.graphql(
        graphqlOperation(updateUserAttributes, {
          userID: participant.id,
          attributeKey: 'custom:clientID',
          action: 'delete'
        })
      );
      await API.graphql(
        graphqlOperation(updateUserGroups, {
          userID: participant.id,
          role: 'participant',
          action: 'remove'
        })
      );
      const { data } = await API.graphql(
        graphqlOperation(getClient, { id: participant.id })
      );
      const clientVersion = data.getClient._version;
      await API.graphql(
        graphqlOperation(updateClient, {
          input: {
            id: participant.id,
            clientID: null,
            _version: clientVersion
          }
        })
      );
      setParticipants(currentParticipants =>
        currentParticipants.filter(
          currentParticipant => currentParticipant.id !== participant.id
        )
      );
      enqueueSnackbar(
        `Removed ${participant.firstName} as a participant on ${participant.clientFirstName}'s account`,
        { severity: 'success' }
      );
    } catch (e) {
      console.log(e);
      enqueueSnackbar(
        `Error removing ${participant.firstName} as a participant on ${participant.clientFirstName}'s account`,
        { severity: 'error' }
      );
    }
  };

  const removeInvitation = async invitation => {
    try {
      console.log('invitation', invitation);
      await API.graphql(
        graphqlOperation(updateInvitation, {
          input: {
            id: invitation.id,
            used: true,
            _version: invitation._version
          }
        })
      );
      setInvitations(currentInvitations =>
        currentInvitations.filter(inv => inv.id !== invitation.id)
      );

      enqueueSnackbar(
        `Cancelled invitation for ${invitation.firstName} to participate on ${clientFirstName}'s account`,
        { severity: 'success' }
      );
    } catch (e) {
      console.log(e);
      enqueueSnackbar(
        `Error cancelling invitation for ${invitation.firstName} to participate on ${clientFirstName}'s account`,
        { severity: 'error' }
      );
    }
  };

  return (
    <Box my={2}>
      <Modal
        fullWidth
        maxWidth="sm"
        open={openAddParticipant}
        setClose={() => setOpenAddParticipant(false)}
        title="Invite a participant"
      >
        <AddParticipant
          clientFirstName={clientFirstName}
          setParticipants={setParticipants}
          participants={participants}
          setClose={() => setOpenAddParticipant(false)}
          clientID={clientID}
        />
      </Modal>
      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
        sx={{ height: 30, mb: 2 }}
      >
        <Tooltip
          placement="top"
          title="Participants allow other caregivers access to this account with limited permissions."
        >
          <IconButton
            color={showInfo ? 'secondary' : 'default'}
            onClick={() => setShowInfo(prevShowInfo => !prevShowInfo)}
          >
            <Info />
          </IconButton>
        </Tooltip>
        <FormLabel sx={{ fontWeight: 600 }}>Participants</FormLabel>
        <Tooltip title="Add a new participant to this account." placement="top">
          <IconButton onClick={() => setOpenAddParticipant(true)}>
            <Add />
          </IconButton>
        </Tooltip>
      </Stack>

      <Collapse in={showInfo}>
        <Alert severity="info">
          <AlertTitle>
            Invite parents, family members, or others to join the therapy plan
            as Participants
          </AlertTitle>
          They'll have limited access to the account, enabling them to view{' '}
          {clientFirstName}'s progress and add records. However, they cannot
          view or modify any access connections, including yours or those of
          other participants.
        </Alert>
      </Collapse>
      {loading ? (
        <Box
          width="100%"
          height={200}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress size={24} />
        </Box>
      ) : (
        <Box>
          {participants.length > 0 && (
            <Box mb={2}>
              <Typography variant="h5">Active Participants</Typography>
            </Box>
          )}
          <Grid container spacing={2}>
            {participants &&
              participants.length > 0 &&
              participants.map(participant => (
                <Participant
                  key={`key=${participant.id}`}
                  participant={participant}
                  removeParticipant={removeParticipant}
                />
              ))}
          </Grid>
          {invitations && invitations.length > 0 && (
            <Box>
              <Box my={2}>
                <Typography variant="h5">Invited Participants</Typography>
              </Box>
              <Grid container spacing={2}>
                {invitations.map(invitation => (
                  <Participant
                    invited
                    key={`key-${invitation.id}`}
                    participant={invitation}
                    removeInvitation={removeInvitation}
                  />
                ))}
              </Grid>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}

import { useEffect, useState } from 'react';

import { Box, Card, CardMedia, Stack, Typography } from '@mui/material';

import { getBrowserName } from 'utils/getBrowser';

export default function AudioPermissions() {
  const [permissionState, setPermissionState] = useState('unknown'); // 'granted', 'denied', 'prompt', or 'unknown'

  useEffect(() => {
    if (navigator.permissions && navigator.permissions.query) {
      navigator.permissions
        .query({ name: 'microphone' })
        .then(result => {
          setPermissionState(result.state);
        })
        .catch(() => {
          // If querying permissions fails (like in Firefox for microphone), try the getUserMedia approach
          checkUsingGetUserMedia();
        });
    } else {
      checkUsingGetUserMedia();
    }
  }, []);

  const checkUsingGetUserMedia = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(() => {
        setPermissionState('granted');
      })
      .catch(err => {
        if (err.name === 'NotAllowedError') {
          setPermissionState('denied');
        } else {
          setPermissionState('unknown');
        }
      });
  };

  const browserSpecificGuidance = (browser, state) => {
    const guides = {
      chrome: {
        denied: {
          text: "You've denied microphone access in Chrome. Go to Settings > Privacy and Security > Site Settings > Microphone to change.\nAlternatively, if you see the microphone icon as pictured, click on it and allow permission.",
          img: '/img/browserInstructions/GoogleChrome-denied.png'
        },
        prompt: {
          text: 'Please allow microphone access when prompted by Chrome.',
          img: '/img/browserInstructions/GoogleChrome-prompt.png'
        },
        unknown: {
          text: "If issues persist, check Chrome's microphone settings.",
          img: '/img/browserInstructions/GoogleChrome-unknown.png'
        }
      },
      safari: {
        denied: {
          text: "You've denied microphone access in Safari. Go to Safari > Settings for This Website > Allow Microphone.",
          img: '/img/browserInstructions/Safari-denied.png'
        },
        prompt: {
          text: 'Please allow microphone access when prompted by Safari.',
          img: '/img/browserInstructions/Safari-prompt.png'
        },
        unknown: {
          text: "If issues persist, check Safari's microphone settings.",
          img: '/img/browserInstructions/Safari-unknown.png'
        }
      },
      firefox: {
        denied: {
          text: "You've denied microphone access in Firefox. Click on the lock icon in the address bar to change.",
          img: '/img/browserInstructions/Firefox-denied.png'
        },
        prompt: {
          text: 'Please allow microphone access when prompted by Firefox.',
          img: '/img/browserInstructions/Firefox-prompt.png'
        },
        unknown: {
          text: "Check Firefox's microphone settings and ensure that audio permissions are granted.",
          img: '/img/browserInstructions/Firefox-unknown.png'
        }
      },
      opera: {
        denied: {
          text: "You've denied microphone access in Opera. Click on the camera icon in the address bar to change.",
          img: '/img/browserInstructions/Opera-denied.png'
        },
        prompt: {
          text: 'Please allow microphone access when prompted by Opera.',
          img: '/img/browserInstructions/Opera-prompt.png'
        },
        unknown: {
          text: "If issues persist, check Opera's microphone settings.",
          img: '/img/browserInstructions/Opera-unknown.png'
        }
      },
      edge: {
        denied: {
          text: "You've denied microphone access in Edge. Click on the lock icon in the address bar to change.",
          img: '/img/browserInstructions/Edge-denied.png'
        },
        prompt: {
          text: 'Please allow microphone access when prompted by Edge.',
          img: '/img/browserInstructions/Edge-prompt.png'
        },
        unknown: {
          text: "If issues persist, check Edge's microphone settings.",
          img: '/img/browserInstructions/Edge-unknown.png'
        }
      },
      default: {
        denied: {
          text: "You've denied microphone access. Please grant it in your browser settings and consider using Google Chrome.",
          img: '/img/browserInstructions/GoogleChrome-denied.png'
        },
        prompt: {
          text: 'Please allow microphone access when prompted by your browser and consider using Google Chrome.',
          img: '/img/browserInstructions/browserInstructions/GoogleChrome-denied.png'
        },
        unknown: {
          text: "If issues persist, check your browser's microphone settings and consider using Google Chrome.",
          img: '/img/browserInstructions/GoogleChrome-denied.png'
        }
      }
    };

    return guides[browser] && guides[browser][state]
      ? guides[browser][state]
      : guides['default'][state];
  };

  const BrowserCard = ({ name, icon }) => (
    <Stack spacing={1} direction="row" alignItems="center">
      <img
        height={30}
        width="auto"
        src={`/icons/browsers/${icon}.svg`}
        alt={name}
      />
      <Typography variant="body1">{name}</Typography>
    </Stack>
  );

  const cardToShow = () => {
    const browser = getBrowserName();
    const { text, img } = browserSpecificGuidance(browser, permissionState);
    return (
      <Box>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="body1">Follow the instructions for</Typography>
          <BrowserCard
            name={browser.charAt(0).toUpperCase() + browser.slice(1)}
            icon={browser}
          />
        </Stack>
        <Typography variant="body2">{text}</Typography>
        <Card variant="outlined" elevation={4} sx={{ my: 4 }}>
          <CardMedia
            component="img"
            image={img}
            style={{ maxWidth: '100%', height: 'auto' }}
          />
        </Card>
      </Box>
    );
  };

  return <Box>{cardToShow()}</Box>;
}

import { useTheme } from '@emotion/react';
import { RadioButtonChecked } from '@mui/icons-material';
import {
  CardActionArea,
  Chip,
  Grid,
  Paper,
  Stack,
  Typography
} from '@mui/material';

import { adjustAlpha } from 'utils/colorChanger';
import { formatAudioTime } from 'utils/formatTime';

export default function Annotations(props) {
  const { annotations = [], onClickAnnotation } = props;
  const theme = useTheme();

  return (
    <>
      {annotations
        .sort((a, b) => a.region.start - b.region.start)
        .map(({ id, isHighlight, isActive, note, region, categories }) => {
          return (
            region && (
              <Paper
                variant="elevation"
                elevation={0}
                key={region.id}
                sx={{
                  my: 2,
                  backgroundColor: isActive
                    ? theme.palette.primary.lightest
                    : isHighlight
                    ? theme.palette.primary.lightest
                    : theme.palette.background.card
                }}
              >
                <CardActionArea
                  onClick={() => onClickAnnotation?.(id)}
                  sx={[{ p: 2 }]}
                >
                  <Grid container spacing={1} sx={{ alignItems: 'center' }}>
                    <Grid item xs={3} sm={2} md={2}>
                      <Typography variant="caption">
                        {formatAudioTime(region.start)} -{' '}
                        {formatAudioTime(region.end)}
                      </Typography>
                    </Grid>
                    {categories && categories.length > 0 ? (
                      <Grid
                        item
                        xs={12}
                        sm={10}
                        md={2}
                        sx={{ display: 'flex', alignItems: 'flex-start' }}
                      >
                        <Stack
                          direction="column"
                          spacing={1}
                          alignItems={'flex-start'}
                        >
                          {categories.map(category => (
                            <Chip
                              key={category}
                              label={category}
                              sx={{ backgroundColor: region?.color }}
                            />
                          ))}
                        </Stack>
                      </Grid>
                    ) : (
                      <Grid
                        item
                        xs={12}
                        sm={10}
                        md={1}
                        sx={{ display: 'flex', justifyContent: 'flex-start' }}
                      >
                        <RadioButtonChecked
                          fontSize="small"
                          sx={{ color: adjustAlpha(region?.color, 1) }}
                        />
                      </Grid>
                    )}
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={categories && categories.length > 0 ? 7 : 9}
                    >
                      <Typography variant="caption">{note}</Typography>
                    </Grid>
                  </Grid>
                </CardActionArea>
              </Paper>
            )
          );
        })}
    </>
  );
}

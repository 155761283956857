import { clinicRoles } from 'common/constants';

export const getUserGroups = user => {
  if (!user) return [];

  const groups =
    user.signInUserSession.accessToken.payload['cognito:groups'] || [];

  return groups;
};

export const getHighestPermissionLevel = user => {
  const groups = getUserGroups(user);
  let highestRole = null;

  groups.forEach(group => {
    if (highestRole === null || clinicRoles[group] > clinicRoles[highestRole]) {
      highestRole = group;
    }
  });

  return highestRole;
};

export function formatRoleString(roleKey) {
  const exceptions = {
    practicemanager: 'Practice Manager'
  };

  if (exceptions[roleKey]) {
    return exceptions[roleKey];
  }

  return roleKey
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/^./, str => str.toUpperCase());
}

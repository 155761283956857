import { Avatar, Box } from '@mui/material';

import { abbreviate } from 'utils/getInitials';

import { CustomFormLabel } from '../CustomFormLabel';

export default function DisplayRater({ input }) {
  return (
    <Box display="flex" alignItems="center">
      <CustomFormLabel>{abbreviate(input?.inputName)}</CustomFormLabel>
      <Box
        sx={{ ml: 1 }}
        display="flex"
        alignItems="center"
        flexDirection="column"
      >
        <Box sx={{ width: 40, height: 2, bgcolor: 'primary.main' }} />
        <Avatar
          sx={{
            bgcolor: 'background.paper',
            color: 'text.primary'
          }}
        >
          {input.raterValue}
        </Avatar>
        <Box sx={{ width: 40, height: 2, bgcolor: 'primary.main' }} />
      </Box>
    </Box>
  );
}

/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createResource = /* GraphQL */ `
  mutation CreateResource(
    $input: CreateResourceInput!
    $condition: ModelResourceConditionInput
  ) {
    createResource(input: $input, condition: $condition) {
      id
      hasImage
      imageType
      createdAt
      updatedAt
      title
      subTitle
      sourceName
      sourceLink
      category
      subCategory
      program
      condition
      type
      resourceContent {
        items {
          id
          createdAt
          updatedAt
          content
          contentType
          fileType
          resourceID
          order
          _version
          _deleted
          _lastChangedAt
          resourceResourceContentId
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      previewText
      createdBy
      accessType
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateResource = /* GraphQL */ `
  mutation UpdateResource(
    $input: UpdateResourceInput!
    $condition: ModelResourceConditionInput
  ) {
    updateResource(input: $input, condition: $condition) {
      id
      hasImage
      imageType
      createdAt
      updatedAt
      title
      subTitle
      sourceName
      sourceLink
      category
      subCategory
      program
      condition
      type
      resourceContent {
        items {
          id
          createdAt
          updatedAt
          content
          contentType
          fileType
          resourceID
          order
          _version
          _deleted
          _lastChangedAt
          resourceResourceContentId
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      previewText
      createdBy
      accessType
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteResource = /* GraphQL */ `
  mutation DeleteResource(
    $input: DeleteResourceInput!
    $condition: ModelResourceConditionInput
  ) {
    deleteResource(input: $input, condition: $condition) {
      id
      hasImage
      imageType
      createdAt
      updatedAt
      title
      subTitle
      sourceName
      sourceLink
      category
      subCategory
      program
      condition
      type
      resourceContent {
        items {
          id
          createdAt
          updatedAt
          content
          contentType
          fileType
          resourceID
          order
          _version
          _deleted
          _lastChangedAt
          resourceResourceContentId
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      previewText
      createdBy
      accessType
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createResourceContent = /* GraphQL */ `
  mutation CreateResourceContent(
    $input: CreateResourceContentInput!
    $condition: ModelResourceContentConditionInput
  ) {
    createResourceContent(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      content
      contentType
      fileType
      resourceID
      resource {
        id
        hasImage
        imageType
        createdAt
        updatedAt
        title
        subTitle
        sourceName
        sourceLink
        category
        subCategory
        program
        condition
        type
        resourceContent {
          nextToken
          startedAt
          __typename
        }
        previewText
        createdBy
        accessType
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      order
      _version
      _deleted
      _lastChangedAt
      resourceResourceContentId
      owner
      __typename
    }
  }
`;
export const updateResourceContent = /* GraphQL */ `
  mutation UpdateResourceContent(
    $input: UpdateResourceContentInput!
    $condition: ModelResourceContentConditionInput
  ) {
    updateResourceContent(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      content
      contentType
      fileType
      resourceID
      resource {
        id
        hasImage
        imageType
        createdAt
        updatedAt
        title
        subTitle
        sourceName
        sourceLink
        category
        subCategory
        program
        condition
        type
        resourceContent {
          nextToken
          startedAt
          __typename
        }
        previewText
        createdBy
        accessType
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      order
      _version
      _deleted
      _lastChangedAt
      resourceResourceContentId
      owner
      __typename
    }
  }
`;
export const deleteResourceContent = /* GraphQL */ `
  mutation DeleteResourceContent(
    $input: DeleteResourceContentInput!
    $condition: ModelResourceContentConditionInput
  ) {
    deleteResourceContent(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      content
      contentType
      fileType
      resourceID
      resource {
        id
        hasImage
        imageType
        createdAt
        updatedAt
        title
        subTitle
        sourceName
        sourceLink
        category
        subCategory
        program
        condition
        type
        resourceContent {
          nextToken
          startedAt
          __typename
        }
        previewText
        createdBy
        accessType
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      order
      _version
      _deleted
      _lastChangedAt
      resourceResourceContentId
      owner
      __typename
    }
  }
`;
export const createClientResource = /* GraphQL */ `
  mutation CreateClientResource(
    $input: CreateClientResourceInput!
    $condition: ModelClientResourceConditionInput
  ) {
    createClientResource(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      owner
      createdBy
      clientID
      clinicianID
      resourceID
      resource {
        id
        hasImage
        imageType
        createdAt
        updatedAt
        title
        subTitle
        sourceName
        sourceLink
        category
        subCategory
        program
        condition
        type
        resourceContent {
          nextToken
          startedAt
          __typename
        }
        previewText
        createdBy
        accessType
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateClientResource = /* GraphQL */ `
  mutation UpdateClientResource(
    $input: UpdateClientResourceInput!
    $condition: ModelClientResourceConditionInput
  ) {
    updateClientResource(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      owner
      createdBy
      clientID
      clinicianID
      resourceID
      resource {
        id
        hasImage
        imageType
        createdAt
        updatedAt
        title
        subTitle
        sourceName
        sourceLink
        category
        subCategory
        program
        condition
        type
        resourceContent {
          nextToken
          startedAt
          __typename
        }
        previewText
        createdBy
        accessType
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteClientResource = /* GraphQL */ `
  mutation DeleteClientResource(
    $input: DeleteClientResourceInput!
    $condition: ModelClientResourceConditionInput
  ) {
    deleteClientResource(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      owner
      createdBy
      clientID
      clinicianID
      resourceID
      resource {
        id
        hasImage
        imageType
        createdAt
        updatedAt
        title
        subTitle
        sourceName
        sourceLink
        category
        subCategory
        program
        condition
        type
        resourceContent {
          nextToken
          startedAt
          __typename
        }
        previewText
        createdBy
        accessType
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createSession = /* GraphQL */ `
  mutation CreateSession(
    $input: CreateSessionInput!
    $condition: ModelSessionConditionInput
  ) {
    createSession(input: $input, condition: $condition) {
      id
      owner
      createdAt
      updatedAt
      clientID
      date
      location
      notes
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateSession = /* GraphQL */ `
  mutation UpdateSession(
    $input: UpdateSessionInput!
    $condition: ModelSessionConditionInput
  ) {
    updateSession(input: $input, condition: $condition) {
      id
      owner
      createdAt
      updatedAt
      clientID
      date
      location
      notes
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteSession = /* GraphQL */ `
  mutation DeleteSession(
    $input: DeleteSessionInput!
    $condition: ModelSessionConditionInput
  ) {
    deleteSession(input: $input, condition: $condition) {
      id
      owner
      createdAt
      updatedAt
      clientID
      date
      location
      notes
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createClinic = /* GraphQL */ `
  mutation CreateClinic(
    $input: CreateClinicInput!
    $condition: ModelClinicConditionInput
  ) {
    createClinic(input: $input, condition: $condition) {
      id
      createdAt
      name
      description
      website
      locations {
        items {
          id
          createdAt
          updatedAt
          name
          phone
          streetAddress
          streetAddress2
          city
          postcode
          state
          country
          clinicID
          _version
          _deleted
          _lastChangedAt
          clinicLocationsId
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      clinicians {
        items {
          id
          sub
          createdAt
          updatedAt
          owner
          firstName
          lastName
          email
          picture
          showNameExercises
          exercises
          clinicID
          locationID
          role
          createdBy
          source
          suspended
          _version
          _deleted
          _lastChangedAt
          clinicCliniciansId
          locationCliniciansId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      hasLogo
      imageType
      clients {
        items {
          id
          sub
          createdAt
          updatedAt
          owner
          firstName
          lastName
          email
          mobile
          deleteIntent
          clientID
          timeZone
          forValue
          clientFirstName
          clientLastName
          clinicianID
          appointmentFrequency
          appointmentDay
          appointmentTime
          zoomLink
          date
          picture
          suds
          subscription
          stripeSubscriptionID
          stripeCustomerID
          billingCycle
          billingResetDate
          billingMethod
          billingIdentifier
          linkStatus
          audioSampleEntryID
          dailyMeasurementsSectionID
          ssComponentID
          ftComponentID
          role
          age
          condition
          program
          stage
          dataConsent
          enrollBeta
          showCount
          activatedShowCountBefore
          sessionCount
          hasResources
          enableVideoCalls
          status
          streak
          streakLastUpdated
          clinicID
          locationID
          _version
          _deleted
          _lastChangedAt
          clinicClientsId
          locationClientsId
          clinicianClientsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateClinic = /* GraphQL */ `
  mutation UpdateClinic(
    $input: UpdateClinicInput!
    $condition: ModelClinicConditionInput
  ) {
    updateClinic(input: $input, condition: $condition) {
      id
      createdAt
      name
      description
      website
      locations {
        items {
          id
          createdAt
          updatedAt
          name
          phone
          streetAddress
          streetAddress2
          city
          postcode
          state
          country
          clinicID
          _version
          _deleted
          _lastChangedAt
          clinicLocationsId
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      clinicians {
        items {
          id
          sub
          createdAt
          updatedAt
          owner
          firstName
          lastName
          email
          picture
          showNameExercises
          exercises
          clinicID
          locationID
          role
          createdBy
          source
          suspended
          _version
          _deleted
          _lastChangedAt
          clinicCliniciansId
          locationCliniciansId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      hasLogo
      imageType
      clients {
        items {
          id
          sub
          createdAt
          updatedAt
          owner
          firstName
          lastName
          email
          mobile
          deleteIntent
          clientID
          timeZone
          forValue
          clientFirstName
          clientLastName
          clinicianID
          appointmentFrequency
          appointmentDay
          appointmentTime
          zoomLink
          date
          picture
          suds
          subscription
          stripeSubscriptionID
          stripeCustomerID
          billingCycle
          billingResetDate
          billingMethod
          billingIdentifier
          linkStatus
          audioSampleEntryID
          dailyMeasurementsSectionID
          ssComponentID
          ftComponentID
          role
          age
          condition
          program
          stage
          dataConsent
          enrollBeta
          showCount
          activatedShowCountBefore
          sessionCount
          hasResources
          enableVideoCalls
          status
          streak
          streakLastUpdated
          clinicID
          locationID
          _version
          _deleted
          _lastChangedAt
          clinicClientsId
          locationClientsId
          clinicianClientsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteClinic = /* GraphQL */ `
  mutation DeleteClinic(
    $input: DeleteClinicInput!
    $condition: ModelClinicConditionInput
  ) {
    deleteClinic(input: $input, condition: $condition) {
      id
      createdAt
      name
      description
      website
      locations {
        items {
          id
          createdAt
          updatedAt
          name
          phone
          streetAddress
          streetAddress2
          city
          postcode
          state
          country
          clinicID
          _version
          _deleted
          _lastChangedAt
          clinicLocationsId
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      clinicians {
        items {
          id
          sub
          createdAt
          updatedAt
          owner
          firstName
          lastName
          email
          picture
          showNameExercises
          exercises
          clinicID
          locationID
          role
          createdBy
          source
          suspended
          _version
          _deleted
          _lastChangedAt
          clinicCliniciansId
          locationCliniciansId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      hasLogo
      imageType
      clients {
        items {
          id
          sub
          createdAt
          updatedAt
          owner
          firstName
          lastName
          email
          mobile
          deleteIntent
          clientID
          timeZone
          forValue
          clientFirstName
          clientLastName
          clinicianID
          appointmentFrequency
          appointmentDay
          appointmentTime
          zoomLink
          date
          picture
          suds
          subscription
          stripeSubscriptionID
          stripeCustomerID
          billingCycle
          billingResetDate
          billingMethod
          billingIdentifier
          linkStatus
          audioSampleEntryID
          dailyMeasurementsSectionID
          ssComponentID
          ftComponentID
          role
          age
          condition
          program
          stage
          dataConsent
          enrollBeta
          showCount
          activatedShowCountBefore
          sessionCount
          hasResources
          enableVideoCalls
          status
          streak
          streakLastUpdated
          clinicID
          locationID
          _version
          _deleted
          _lastChangedAt
          clinicClientsId
          locationClientsId
          clinicianClientsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createLocation = /* GraphQL */ `
  mutation CreateLocation(
    $input: CreateLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    createLocation(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      name
      phone
      streetAddress
      streetAddress2
      city
      postcode
      state
      country
      clinicID
      clinic {
        id
        createdAt
        name
        description
        website
        locations {
          nextToken
          startedAt
          __typename
        }
        clinicians {
          nextToken
          startedAt
          __typename
        }
        hasLogo
        imageType
        clients {
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      clinicians {
        items {
          id
          sub
          createdAt
          updatedAt
          owner
          firstName
          lastName
          email
          picture
          showNameExercises
          exercises
          clinicID
          locationID
          role
          createdBy
          source
          suspended
          _version
          _deleted
          _lastChangedAt
          clinicCliniciansId
          locationCliniciansId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      clients {
        items {
          id
          sub
          createdAt
          updatedAt
          owner
          firstName
          lastName
          email
          mobile
          deleteIntent
          clientID
          timeZone
          forValue
          clientFirstName
          clientLastName
          clinicianID
          appointmentFrequency
          appointmentDay
          appointmentTime
          zoomLink
          date
          picture
          suds
          subscription
          stripeSubscriptionID
          stripeCustomerID
          billingCycle
          billingResetDate
          billingMethod
          billingIdentifier
          linkStatus
          audioSampleEntryID
          dailyMeasurementsSectionID
          ssComponentID
          ftComponentID
          role
          age
          condition
          program
          stage
          dataConsent
          enrollBeta
          showCount
          activatedShowCountBefore
          sessionCount
          hasResources
          enableVideoCalls
          status
          streak
          streakLastUpdated
          clinicID
          locationID
          _version
          _deleted
          _lastChangedAt
          clinicClientsId
          locationClientsId
          clinicianClientsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      clinicLocationsId
      owner
      __typename
    }
  }
`;
export const updateLocation = /* GraphQL */ `
  mutation UpdateLocation(
    $input: UpdateLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    updateLocation(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      name
      phone
      streetAddress
      streetAddress2
      city
      postcode
      state
      country
      clinicID
      clinic {
        id
        createdAt
        name
        description
        website
        locations {
          nextToken
          startedAt
          __typename
        }
        clinicians {
          nextToken
          startedAt
          __typename
        }
        hasLogo
        imageType
        clients {
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      clinicians {
        items {
          id
          sub
          createdAt
          updatedAt
          owner
          firstName
          lastName
          email
          picture
          showNameExercises
          exercises
          clinicID
          locationID
          role
          createdBy
          source
          suspended
          _version
          _deleted
          _lastChangedAt
          clinicCliniciansId
          locationCliniciansId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      clients {
        items {
          id
          sub
          createdAt
          updatedAt
          owner
          firstName
          lastName
          email
          mobile
          deleteIntent
          clientID
          timeZone
          forValue
          clientFirstName
          clientLastName
          clinicianID
          appointmentFrequency
          appointmentDay
          appointmentTime
          zoomLink
          date
          picture
          suds
          subscription
          stripeSubscriptionID
          stripeCustomerID
          billingCycle
          billingResetDate
          billingMethod
          billingIdentifier
          linkStatus
          audioSampleEntryID
          dailyMeasurementsSectionID
          ssComponentID
          ftComponentID
          role
          age
          condition
          program
          stage
          dataConsent
          enrollBeta
          showCount
          activatedShowCountBefore
          sessionCount
          hasResources
          enableVideoCalls
          status
          streak
          streakLastUpdated
          clinicID
          locationID
          _version
          _deleted
          _lastChangedAt
          clinicClientsId
          locationClientsId
          clinicianClientsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      clinicLocationsId
      owner
      __typename
    }
  }
`;
export const deleteLocation = /* GraphQL */ `
  mutation DeleteLocation(
    $input: DeleteLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    deleteLocation(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      name
      phone
      streetAddress
      streetAddress2
      city
      postcode
      state
      country
      clinicID
      clinic {
        id
        createdAt
        name
        description
        website
        locations {
          nextToken
          startedAt
          __typename
        }
        clinicians {
          nextToken
          startedAt
          __typename
        }
        hasLogo
        imageType
        clients {
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      clinicians {
        items {
          id
          sub
          createdAt
          updatedAt
          owner
          firstName
          lastName
          email
          picture
          showNameExercises
          exercises
          clinicID
          locationID
          role
          createdBy
          source
          suspended
          _version
          _deleted
          _lastChangedAt
          clinicCliniciansId
          locationCliniciansId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      clients {
        items {
          id
          sub
          createdAt
          updatedAt
          owner
          firstName
          lastName
          email
          mobile
          deleteIntent
          clientID
          timeZone
          forValue
          clientFirstName
          clientLastName
          clinicianID
          appointmentFrequency
          appointmentDay
          appointmentTime
          zoomLink
          date
          picture
          suds
          subscription
          stripeSubscriptionID
          stripeCustomerID
          billingCycle
          billingResetDate
          billingMethod
          billingIdentifier
          linkStatus
          audioSampleEntryID
          dailyMeasurementsSectionID
          ssComponentID
          ftComponentID
          role
          age
          condition
          program
          stage
          dataConsent
          enrollBeta
          showCount
          activatedShowCountBefore
          sessionCount
          hasResources
          enableVideoCalls
          status
          streak
          streakLastUpdated
          clinicID
          locationID
          _version
          _deleted
          _lastChangedAt
          clinicClientsId
          locationClientsId
          clinicianClientsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      clinicLocationsId
      owner
      __typename
    }
  }
`;
export const createClient = /* GraphQL */ `
  mutation CreateClient(
    $input: CreateClientInput!
    $condition: ModelClientConditionInput
  ) {
    createClient(input: $input, condition: $condition) {
      id
      sub
      createdAt
      updatedAt
      owner
      firstName
      lastName
      email
      mobile
      deleteIntent
      clientID
      timeZone
      forValue
      clientFirstName
      clientLastName
      clinicianID
      clinician {
        id
        sub
        createdAt
        updatedAt
        owner
        firstName
        lastName
        email
        picture
        showNameExercises
        exercises
        clients {
          nextToken
          startedAt
          __typename
        }
        clinicID
        clinic {
          id
          createdAt
          name
          description
          website
          hasLogo
          imageType
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        location {
          id
          createdAt
          updatedAt
          name
          phone
          streetAddress
          streetAddress2
          city
          postcode
          state
          country
          clinicID
          _version
          _deleted
          _lastChangedAt
          clinicLocationsId
          owner
          __typename
        }
        locationID
        role
        createdBy
        source
        suspended
        _version
        _deleted
        _lastChangedAt
        clinicCliniciansId
        locationCliniciansId
        __typename
      }
      appointmentFrequency
      appointmentDay
      appointmentTime
      zoomLink
      date
      picture
      suds
      subscription
      stripeSubscriptionID
      stripeCustomerID
      billingCycle
      billingResetDate
      billingMethod
      billingIdentifier
      linkStatus
      audioSampleEntryID
      dailyMeasurementsSectionID
      ssComponentID
      ftComponentID
      role
      age
      condition
      program
      stage
      dataConsent
      enrollBeta
      showCount
      activatedShowCountBefore
      sessionCount
      hasResources
      enableVideoCalls
      status
      streak
      streakLastUpdated
      clinicID
      clinic {
        id
        createdAt
        name
        description
        website
        locations {
          nextToken
          startedAt
          __typename
        }
        clinicians {
          nextToken
          startedAt
          __typename
        }
        hasLogo
        imageType
        clients {
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      location {
        id
        createdAt
        updatedAt
        name
        phone
        streetAddress
        streetAddress2
        city
        postcode
        state
        country
        clinicID
        clinic {
          id
          createdAt
          name
          description
          website
          hasLogo
          imageType
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        clinicians {
          nextToken
          startedAt
          __typename
        }
        clients {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        clinicLocationsId
        owner
        __typename
      }
      locationID
      _version
      _deleted
      _lastChangedAt
      clinicClientsId
      locationClientsId
      clinicianClientsId
      __typename
    }
  }
`;
export const updateClient = /* GraphQL */ `
  mutation UpdateClient(
    $input: UpdateClientInput!
    $condition: ModelClientConditionInput
  ) {
    updateClient(input: $input, condition: $condition) {
      id
      sub
      createdAt
      updatedAt
      owner
      firstName
      lastName
      email
      mobile
      deleteIntent
      clientID
      timeZone
      forValue
      clientFirstName
      clientLastName
      clinicianID
      clinician {
        id
        sub
        createdAt
        updatedAt
        owner
        firstName
        lastName
        email
        picture
        showNameExercises
        exercises
        clients {
          nextToken
          startedAt
          __typename
        }
        clinicID
        clinic {
          id
          createdAt
          name
          description
          website
          hasLogo
          imageType
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        location {
          id
          createdAt
          updatedAt
          name
          phone
          streetAddress
          streetAddress2
          city
          postcode
          state
          country
          clinicID
          _version
          _deleted
          _lastChangedAt
          clinicLocationsId
          owner
          __typename
        }
        locationID
        role
        createdBy
        source
        suspended
        _version
        _deleted
        _lastChangedAt
        clinicCliniciansId
        locationCliniciansId
        __typename
      }
      appointmentFrequency
      appointmentDay
      appointmentTime
      zoomLink
      date
      picture
      suds
      subscription
      stripeSubscriptionID
      stripeCustomerID
      billingCycle
      billingResetDate
      billingMethod
      billingIdentifier
      linkStatus
      audioSampleEntryID
      dailyMeasurementsSectionID
      ssComponentID
      ftComponentID
      role
      age
      condition
      program
      stage
      dataConsent
      enrollBeta
      showCount
      activatedShowCountBefore
      sessionCount
      hasResources
      enableVideoCalls
      status
      streak
      streakLastUpdated
      clinicID
      clinic {
        id
        createdAt
        name
        description
        website
        locations {
          nextToken
          startedAt
          __typename
        }
        clinicians {
          nextToken
          startedAt
          __typename
        }
        hasLogo
        imageType
        clients {
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      location {
        id
        createdAt
        updatedAt
        name
        phone
        streetAddress
        streetAddress2
        city
        postcode
        state
        country
        clinicID
        clinic {
          id
          createdAt
          name
          description
          website
          hasLogo
          imageType
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        clinicians {
          nextToken
          startedAt
          __typename
        }
        clients {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        clinicLocationsId
        owner
        __typename
      }
      locationID
      _version
      _deleted
      _lastChangedAt
      clinicClientsId
      locationClientsId
      clinicianClientsId
      __typename
    }
  }
`;
export const deleteClient = /* GraphQL */ `
  mutation DeleteClient(
    $input: DeleteClientInput!
    $condition: ModelClientConditionInput
  ) {
    deleteClient(input: $input, condition: $condition) {
      id
      sub
      createdAt
      updatedAt
      owner
      firstName
      lastName
      email
      mobile
      deleteIntent
      clientID
      timeZone
      forValue
      clientFirstName
      clientLastName
      clinicianID
      clinician {
        id
        sub
        createdAt
        updatedAt
        owner
        firstName
        lastName
        email
        picture
        showNameExercises
        exercises
        clients {
          nextToken
          startedAt
          __typename
        }
        clinicID
        clinic {
          id
          createdAt
          name
          description
          website
          hasLogo
          imageType
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        location {
          id
          createdAt
          updatedAt
          name
          phone
          streetAddress
          streetAddress2
          city
          postcode
          state
          country
          clinicID
          _version
          _deleted
          _lastChangedAt
          clinicLocationsId
          owner
          __typename
        }
        locationID
        role
        createdBy
        source
        suspended
        _version
        _deleted
        _lastChangedAt
        clinicCliniciansId
        locationCliniciansId
        __typename
      }
      appointmentFrequency
      appointmentDay
      appointmentTime
      zoomLink
      date
      picture
      suds
      subscription
      stripeSubscriptionID
      stripeCustomerID
      billingCycle
      billingResetDate
      billingMethod
      billingIdentifier
      linkStatus
      audioSampleEntryID
      dailyMeasurementsSectionID
      ssComponentID
      ftComponentID
      role
      age
      condition
      program
      stage
      dataConsent
      enrollBeta
      showCount
      activatedShowCountBefore
      sessionCount
      hasResources
      enableVideoCalls
      status
      streak
      streakLastUpdated
      clinicID
      clinic {
        id
        createdAt
        name
        description
        website
        locations {
          nextToken
          startedAt
          __typename
        }
        clinicians {
          nextToken
          startedAt
          __typename
        }
        hasLogo
        imageType
        clients {
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      location {
        id
        createdAt
        updatedAt
        name
        phone
        streetAddress
        streetAddress2
        city
        postcode
        state
        country
        clinicID
        clinic {
          id
          createdAt
          name
          description
          website
          hasLogo
          imageType
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        clinicians {
          nextToken
          startedAt
          __typename
        }
        clients {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        clinicLocationsId
        owner
        __typename
      }
      locationID
      _version
      _deleted
      _lastChangedAt
      clinicClientsId
      locationClientsId
      clinicianClientsId
      __typename
    }
  }
`;
export const createClinician = /* GraphQL */ `
  mutation CreateClinician(
    $input: CreateClinicianInput!
    $condition: ModelClinicianConditionInput
  ) {
    createClinician(input: $input, condition: $condition) {
      id
      sub
      createdAt
      updatedAt
      owner
      firstName
      lastName
      email
      picture
      showNameExercises
      exercises
      clients {
        items {
          id
          sub
          createdAt
          updatedAt
          owner
          firstName
          lastName
          email
          mobile
          deleteIntent
          clientID
          timeZone
          forValue
          clientFirstName
          clientLastName
          clinicianID
          appointmentFrequency
          appointmentDay
          appointmentTime
          zoomLink
          date
          picture
          suds
          subscription
          stripeSubscriptionID
          stripeCustomerID
          billingCycle
          billingResetDate
          billingMethod
          billingIdentifier
          linkStatus
          audioSampleEntryID
          dailyMeasurementsSectionID
          ssComponentID
          ftComponentID
          role
          age
          condition
          program
          stage
          dataConsent
          enrollBeta
          showCount
          activatedShowCountBefore
          sessionCount
          hasResources
          enableVideoCalls
          status
          streak
          streakLastUpdated
          clinicID
          locationID
          _version
          _deleted
          _lastChangedAt
          clinicClientsId
          locationClientsId
          clinicianClientsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      clinicID
      clinic {
        id
        createdAt
        name
        description
        website
        locations {
          nextToken
          startedAt
          __typename
        }
        clinicians {
          nextToken
          startedAt
          __typename
        }
        hasLogo
        imageType
        clients {
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      location {
        id
        createdAt
        updatedAt
        name
        phone
        streetAddress
        streetAddress2
        city
        postcode
        state
        country
        clinicID
        clinic {
          id
          createdAt
          name
          description
          website
          hasLogo
          imageType
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        clinicians {
          nextToken
          startedAt
          __typename
        }
        clients {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        clinicLocationsId
        owner
        __typename
      }
      locationID
      role
      createdBy
      source
      suspended
      _version
      _deleted
      _lastChangedAt
      clinicCliniciansId
      locationCliniciansId
      __typename
    }
  }
`;
export const updateClinician = /* GraphQL */ `
  mutation UpdateClinician(
    $input: UpdateClinicianInput!
    $condition: ModelClinicianConditionInput
  ) {
    updateClinician(input: $input, condition: $condition) {
      id
      sub
      createdAt
      updatedAt
      owner
      firstName
      lastName
      email
      picture
      showNameExercises
      exercises
      clients {
        items {
          id
          sub
          createdAt
          updatedAt
          owner
          firstName
          lastName
          email
          mobile
          deleteIntent
          clientID
          timeZone
          forValue
          clientFirstName
          clientLastName
          clinicianID
          appointmentFrequency
          appointmentDay
          appointmentTime
          zoomLink
          date
          picture
          suds
          subscription
          stripeSubscriptionID
          stripeCustomerID
          billingCycle
          billingResetDate
          billingMethod
          billingIdentifier
          linkStatus
          audioSampleEntryID
          dailyMeasurementsSectionID
          ssComponentID
          ftComponentID
          role
          age
          condition
          program
          stage
          dataConsent
          enrollBeta
          showCount
          activatedShowCountBefore
          sessionCount
          hasResources
          enableVideoCalls
          status
          streak
          streakLastUpdated
          clinicID
          locationID
          _version
          _deleted
          _lastChangedAt
          clinicClientsId
          locationClientsId
          clinicianClientsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      clinicID
      clinic {
        id
        createdAt
        name
        description
        website
        locations {
          nextToken
          startedAt
          __typename
        }
        clinicians {
          nextToken
          startedAt
          __typename
        }
        hasLogo
        imageType
        clients {
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      location {
        id
        createdAt
        updatedAt
        name
        phone
        streetAddress
        streetAddress2
        city
        postcode
        state
        country
        clinicID
        clinic {
          id
          createdAt
          name
          description
          website
          hasLogo
          imageType
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        clinicians {
          nextToken
          startedAt
          __typename
        }
        clients {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        clinicLocationsId
        owner
        __typename
      }
      locationID
      role
      createdBy
      source
      suspended
      _version
      _deleted
      _lastChangedAt
      clinicCliniciansId
      locationCliniciansId
      __typename
    }
  }
`;
export const deleteClinician = /* GraphQL */ `
  mutation DeleteClinician(
    $input: DeleteClinicianInput!
    $condition: ModelClinicianConditionInput
  ) {
    deleteClinician(input: $input, condition: $condition) {
      id
      sub
      createdAt
      updatedAt
      owner
      firstName
      lastName
      email
      picture
      showNameExercises
      exercises
      clients {
        items {
          id
          sub
          createdAt
          updatedAt
          owner
          firstName
          lastName
          email
          mobile
          deleteIntent
          clientID
          timeZone
          forValue
          clientFirstName
          clientLastName
          clinicianID
          appointmentFrequency
          appointmentDay
          appointmentTime
          zoomLink
          date
          picture
          suds
          subscription
          stripeSubscriptionID
          stripeCustomerID
          billingCycle
          billingResetDate
          billingMethod
          billingIdentifier
          linkStatus
          audioSampleEntryID
          dailyMeasurementsSectionID
          ssComponentID
          ftComponentID
          role
          age
          condition
          program
          stage
          dataConsent
          enrollBeta
          showCount
          activatedShowCountBefore
          sessionCount
          hasResources
          enableVideoCalls
          status
          streak
          streakLastUpdated
          clinicID
          locationID
          _version
          _deleted
          _lastChangedAt
          clinicClientsId
          locationClientsId
          clinicianClientsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      clinicID
      clinic {
        id
        createdAt
        name
        description
        website
        locations {
          nextToken
          startedAt
          __typename
        }
        clinicians {
          nextToken
          startedAt
          __typename
        }
        hasLogo
        imageType
        clients {
          nextToken
          startedAt
          __typename
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      location {
        id
        createdAt
        updatedAt
        name
        phone
        streetAddress
        streetAddress2
        city
        postcode
        state
        country
        clinicID
        clinic {
          id
          createdAt
          name
          description
          website
          hasLogo
          imageType
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        clinicians {
          nextToken
          startedAt
          __typename
        }
        clients {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        clinicLocationsId
        owner
        __typename
      }
      locationID
      role
      createdBy
      source
      suspended
      _version
      _deleted
      _lastChangedAt
      clinicCliniciansId
      locationCliniciansId
      __typename
    }
  }
`;
export const createSupport = /* GraphQL */ `
  mutation CreateSupport(
    $input: CreateSupportInput!
    $condition: ModelSupportConditionInput
  ) {
    createSupport(input: $input, condition: $condition) {
      id
      owner
      createdAt
      updatedAt
      clientID
      clinicianID
      expiresAt
      used
      createdBy
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateSupport = /* GraphQL */ `
  mutation UpdateSupport(
    $input: UpdateSupportInput!
    $condition: ModelSupportConditionInput
  ) {
    updateSupport(input: $input, condition: $condition) {
      id
      owner
      createdAt
      updatedAt
      clientID
      clinicianID
      expiresAt
      used
      createdBy
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteSupport = /* GraphQL */ `
  mutation DeleteSupport(
    $input: DeleteSupportInput!
    $condition: ModelSupportConditionInput
  ) {
    deleteSupport(input: $input, condition: $condition) {
      id
      owner
      createdAt
      updatedAt
      clientID
      clinicianID
      expiresAt
      used
      createdBy
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createInvitation = /* GraphQL */ `
  mutation CreateInvitation(
    $input: CreateInvitationInput!
    $condition: ModelInvitationConditionInput
  ) {
    createInvitation(input: $input, condition: $condition) {
      id
      owner
      createdAt
      updatedAt
      locationID
      clinicID
      clientID
      clinicianID
      used
      inviteeID
      email
      firstName
      lastName
      inviteeFirstName
      inviteeEmail
      createdBy
      action
      role
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateInvitation = /* GraphQL */ `
  mutation UpdateInvitation(
    $input: UpdateInvitationInput!
    $condition: ModelInvitationConditionInput
  ) {
    updateInvitation(input: $input, condition: $condition) {
      id
      owner
      createdAt
      updatedAt
      locationID
      clinicID
      clientID
      clinicianID
      used
      inviteeID
      email
      firstName
      lastName
      inviteeFirstName
      inviteeEmail
      createdBy
      action
      role
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteInvitation = /* GraphQL */ `
  mutation DeleteInvitation(
    $input: DeleteInvitationInput!
    $condition: ModelInvitationConditionInput
  ) {
    deleteInvitation(input: $input, condition: $condition) {
      id
      owner
      createdAt
      updatedAt
      locationID
      clinicID
      clientID
      clinicianID
      used
      inviteeID
      email
      firstName
      lastName
      inviteeFirstName
      inviteeEmail
      createdBy
      action
      role
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createUsage = /* GraphQL */ `
  mutation CreateUsage(
    $input: CreateUsageInput!
    $condition: ModelUsageConditionInput
  ) {
    createUsage(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      clientId
      recordings
      entries
      insights
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateUsage = /* GraphQL */ `
  mutation UpdateUsage(
    $input: UpdateUsageInput!
    $condition: ModelUsageConditionInput
  ) {
    updateUsage(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      clientId
      recordings
      entries
      insights
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteUsage = /* GraphQL */ `
  mutation DeleteUsage(
    $input: DeleteUsageInput!
    $condition: ModelUsageConditionInput
  ) {
    deleteUsage(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      clientId
      recordings
      entries
      insights
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createSection = /* GraphQL */ `
  mutation CreateSection(
    $input: CreateSectionInput!
    $condition: ModelSectionConditionInput
  ) {
    createSection(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      complete
      name
      inactive
      noEdit
      analytics
      order
      owner
      settings
      clientID
      createdBy
      entries {
        items {
          id
          createdAt
          updatedAt
          owner
          name
          icon
          type
          order
          addLogic
          logic
          inactive
          noEdit
          settings
          complete
          analytics
          draftRecordID
          clientID
          createdBy
          sectionID
          count
          goalID
          _version
          _deleted
          _lastChangedAt
          sectionEntriesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      goalID
      goal {
        id
        owner
        name
        description
        createdAt
        updatedAt
        createdBy
        updatedBy
        targetNumber
        targetFrequency
        targetType
        recurringType
        recurringUntil
        recurringNumber
        color
        section {
          id
          createdAt
          updatedAt
          complete
          name
          inactive
          noEdit
          analytics
          order
          owner
          settings
          clientID
          createdBy
          goalID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        sectionID
        entry {
          id
          createdAt
          updatedAt
          owner
          name
          icon
          type
          order
          addLogic
          logic
          inactive
          noEdit
          settings
          complete
          analytics
          draftRecordID
          clientID
          createdBy
          sectionID
          count
          goalID
          _version
          _deleted
          _lastChangedAt
          sectionEntriesId
          __typename
        }
        entryID
        component {
          id
          createdAt
          updatedAt
          clientID
          complete
          owner
          type
          order
          settings
          to
          from
          rows
          name
          noEdit
          size
          label
          placeholder
          upload
          options
          content
          boolean
          variant
          prompts
          collapse
          showInfo
          showPrompts
          multiline
          addButton
          multiselect
          information
          instructions
          removeButton
          booleanLabel
          annotationLabels
          entrySectionID
          goalID
          _version
          _deleted
          _lastChangedAt
          entrySectionComponentsId
          __typename
        }
        componentID
        clientID
        order
        goalEntries {
          nextToken
          startedAt
          __typename
        }
        complete
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateSection = /* GraphQL */ `
  mutation UpdateSection(
    $input: UpdateSectionInput!
    $condition: ModelSectionConditionInput
  ) {
    updateSection(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      complete
      name
      inactive
      noEdit
      analytics
      order
      owner
      settings
      clientID
      createdBy
      entries {
        items {
          id
          createdAt
          updatedAt
          owner
          name
          icon
          type
          order
          addLogic
          logic
          inactive
          noEdit
          settings
          complete
          analytics
          draftRecordID
          clientID
          createdBy
          sectionID
          count
          goalID
          _version
          _deleted
          _lastChangedAt
          sectionEntriesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      goalID
      goal {
        id
        owner
        name
        description
        createdAt
        updatedAt
        createdBy
        updatedBy
        targetNumber
        targetFrequency
        targetType
        recurringType
        recurringUntil
        recurringNumber
        color
        section {
          id
          createdAt
          updatedAt
          complete
          name
          inactive
          noEdit
          analytics
          order
          owner
          settings
          clientID
          createdBy
          goalID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        sectionID
        entry {
          id
          createdAt
          updatedAt
          owner
          name
          icon
          type
          order
          addLogic
          logic
          inactive
          noEdit
          settings
          complete
          analytics
          draftRecordID
          clientID
          createdBy
          sectionID
          count
          goalID
          _version
          _deleted
          _lastChangedAt
          sectionEntriesId
          __typename
        }
        entryID
        component {
          id
          createdAt
          updatedAt
          clientID
          complete
          owner
          type
          order
          settings
          to
          from
          rows
          name
          noEdit
          size
          label
          placeholder
          upload
          options
          content
          boolean
          variant
          prompts
          collapse
          showInfo
          showPrompts
          multiline
          addButton
          multiselect
          information
          instructions
          removeButton
          booleanLabel
          annotationLabels
          entrySectionID
          goalID
          _version
          _deleted
          _lastChangedAt
          entrySectionComponentsId
          __typename
        }
        componentID
        clientID
        order
        goalEntries {
          nextToken
          startedAt
          __typename
        }
        complete
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteSection = /* GraphQL */ `
  mutation DeleteSection(
    $input: DeleteSectionInput!
    $condition: ModelSectionConditionInput
  ) {
    deleteSection(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      complete
      name
      inactive
      noEdit
      analytics
      order
      owner
      settings
      clientID
      createdBy
      entries {
        items {
          id
          createdAt
          updatedAt
          owner
          name
          icon
          type
          order
          addLogic
          logic
          inactive
          noEdit
          settings
          complete
          analytics
          draftRecordID
          clientID
          createdBy
          sectionID
          count
          goalID
          _version
          _deleted
          _lastChangedAt
          sectionEntriesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      goalID
      goal {
        id
        owner
        name
        description
        createdAt
        updatedAt
        createdBy
        updatedBy
        targetNumber
        targetFrequency
        targetType
        recurringType
        recurringUntil
        recurringNumber
        color
        section {
          id
          createdAt
          updatedAt
          complete
          name
          inactive
          noEdit
          analytics
          order
          owner
          settings
          clientID
          createdBy
          goalID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        sectionID
        entry {
          id
          createdAt
          updatedAt
          owner
          name
          icon
          type
          order
          addLogic
          logic
          inactive
          noEdit
          settings
          complete
          analytics
          draftRecordID
          clientID
          createdBy
          sectionID
          count
          goalID
          _version
          _deleted
          _lastChangedAt
          sectionEntriesId
          __typename
        }
        entryID
        component {
          id
          createdAt
          updatedAt
          clientID
          complete
          owner
          type
          order
          settings
          to
          from
          rows
          name
          noEdit
          size
          label
          placeholder
          upload
          options
          content
          boolean
          variant
          prompts
          collapse
          showInfo
          showPrompts
          multiline
          addButton
          multiselect
          information
          instructions
          removeButton
          booleanLabel
          annotationLabels
          entrySectionID
          goalID
          _version
          _deleted
          _lastChangedAt
          entrySectionComponentsId
          __typename
        }
        componentID
        clientID
        order
        goalEntries {
          nextToken
          startedAt
          __typename
        }
        complete
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createEntry = /* GraphQL */ `
  mutation CreateEntry(
    $input: CreateEntryInput!
    $condition: ModelEntryConditionInput
  ) {
    createEntry(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      owner
      name
      icon
      type
      order
      addLogic
      logic
      inactive
      noEdit
      settings
      complete
      analytics
      draftRecordID
      clientID
      createdBy
      sectionID
      section {
        id
        createdAt
        updatedAt
        complete
        name
        inactive
        noEdit
        analytics
        order
        owner
        settings
        clientID
        createdBy
        entries {
          nextToken
          startedAt
          __typename
        }
        goalID
        goal {
          id
          owner
          name
          description
          createdAt
          updatedAt
          createdBy
          updatedBy
          targetNumber
          targetFrequency
          targetType
          recurringType
          recurringUntil
          recurringNumber
          color
          sectionID
          entryID
          componentID
          clientID
          order
          complete
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      entrySections {
        items {
          id
          createdAt
          updatedAt
          name
          complete
          owner
          order
          noEdit
          settings
          showInfo
          information
          informationTitle
          orientation
          clientID
          entryID
          createdBy
          _version
          _deleted
          _lastChangedAt
          entryEntrySectionsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      count
      goalID
      goal {
        id
        owner
        name
        description
        createdAt
        updatedAt
        createdBy
        updatedBy
        targetNumber
        targetFrequency
        targetType
        recurringType
        recurringUntil
        recurringNumber
        color
        section {
          id
          createdAt
          updatedAt
          complete
          name
          inactive
          noEdit
          analytics
          order
          owner
          settings
          clientID
          createdBy
          goalID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        sectionID
        entry {
          id
          createdAt
          updatedAt
          owner
          name
          icon
          type
          order
          addLogic
          logic
          inactive
          noEdit
          settings
          complete
          analytics
          draftRecordID
          clientID
          createdBy
          sectionID
          count
          goalID
          _version
          _deleted
          _lastChangedAt
          sectionEntriesId
          __typename
        }
        entryID
        component {
          id
          createdAt
          updatedAt
          clientID
          complete
          owner
          type
          order
          settings
          to
          from
          rows
          name
          noEdit
          size
          label
          placeholder
          upload
          options
          content
          boolean
          variant
          prompts
          collapse
          showInfo
          showPrompts
          multiline
          addButton
          multiselect
          information
          instructions
          removeButton
          booleanLabel
          annotationLabels
          entrySectionID
          goalID
          _version
          _deleted
          _lastChangedAt
          entrySectionComponentsId
          __typename
        }
        componentID
        clientID
        order
        goalEntries {
          nextToken
          startedAt
          __typename
        }
        complete
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      sectionEntriesId
      __typename
    }
  }
`;
export const updateEntry = /* GraphQL */ `
  mutation UpdateEntry(
    $input: UpdateEntryInput!
    $condition: ModelEntryConditionInput
  ) {
    updateEntry(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      owner
      name
      icon
      type
      order
      addLogic
      logic
      inactive
      noEdit
      settings
      complete
      analytics
      draftRecordID
      clientID
      createdBy
      sectionID
      section {
        id
        createdAt
        updatedAt
        complete
        name
        inactive
        noEdit
        analytics
        order
        owner
        settings
        clientID
        createdBy
        entries {
          nextToken
          startedAt
          __typename
        }
        goalID
        goal {
          id
          owner
          name
          description
          createdAt
          updatedAt
          createdBy
          updatedBy
          targetNumber
          targetFrequency
          targetType
          recurringType
          recurringUntil
          recurringNumber
          color
          sectionID
          entryID
          componentID
          clientID
          order
          complete
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      entrySections {
        items {
          id
          createdAt
          updatedAt
          name
          complete
          owner
          order
          noEdit
          settings
          showInfo
          information
          informationTitle
          orientation
          clientID
          entryID
          createdBy
          _version
          _deleted
          _lastChangedAt
          entryEntrySectionsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      count
      goalID
      goal {
        id
        owner
        name
        description
        createdAt
        updatedAt
        createdBy
        updatedBy
        targetNumber
        targetFrequency
        targetType
        recurringType
        recurringUntil
        recurringNumber
        color
        section {
          id
          createdAt
          updatedAt
          complete
          name
          inactive
          noEdit
          analytics
          order
          owner
          settings
          clientID
          createdBy
          goalID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        sectionID
        entry {
          id
          createdAt
          updatedAt
          owner
          name
          icon
          type
          order
          addLogic
          logic
          inactive
          noEdit
          settings
          complete
          analytics
          draftRecordID
          clientID
          createdBy
          sectionID
          count
          goalID
          _version
          _deleted
          _lastChangedAt
          sectionEntriesId
          __typename
        }
        entryID
        component {
          id
          createdAt
          updatedAt
          clientID
          complete
          owner
          type
          order
          settings
          to
          from
          rows
          name
          noEdit
          size
          label
          placeholder
          upload
          options
          content
          boolean
          variant
          prompts
          collapse
          showInfo
          showPrompts
          multiline
          addButton
          multiselect
          information
          instructions
          removeButton
          booleanLabel
          annotationLabels
          entrySectionID
          goalID
          _version
          _deleted
          _lastChangedAt
          entrySectionComponentsId
          __typename
        }
        componentID
        clientID
        order
        goalEntries {
          nextToken
          startedAt
          __typename
        }
        complete
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      sectionEntriesId
      __typename
    }
  }
`;
export const deleteEntry = /* GraphQL */ `
  mutation DeleteEntry(
    $input: DeleteEntryInput!
    $condition: ModelEntryConditionInput
  ) {
    deleteEntry(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      owner
      name
      icon
      type
      order
      addLogic
      logic
      inactive
      noEdit
      settings
      complete
      analytics
      draftRecordID
      clientID
      createdBy
      sectionID
      section {
        id
        createdAt
        updatedAt
        complete
        name
        inactive
        noEdit
        analytics
        order
        owner
        settings
        clientID
        createdBy
        entries {
          nextToken
          startedAt
          __typename
        }
        goalID
        goal {
          id
          owner
          name
          description
          createdAt
          updatedAt
          createdBy
          updatedBy
          targetNumber
          targetFrequency
          targetType
          recurringType
          recurringUntil
          recurringNumber
          color
          sectionID
          entryID
          componentID
          clientID
          order
          complete
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      entrySections {
        items {
          id
          createdAt
          updatedAt
          name
          complete
          owner
          order
          noEdit
          settings
          showInfo
          information
          informationTitle
          orientation
          clientID
          entryID
          createdBy
          _version
          _deleted
          _lastChangedAt
          entryEntrySectionsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      count
      goalID
      goal {
        id
        owner
        name
        description
        createdAt
        updatedAt
        createdBy
        updatedBy
        targetNumber
        targetFrequency
        targetType
        recurringType
        recurringUntil
        recurringNumber
        color
        section {
          id
          createdAt
          updatedAt
          complete
          name
          inactive
          noEdit
          analytics
          order
          owner
          settings
          clientID
          createdBy
          goalID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        sectionID
        entry {
          id
          createdAt
          updatedAt
          owner
          name
          icon
          type
          order
          addLogic
          logic
          inactive
          noEdit
          settings
          complete
          analytics
          draftRecordID
          clientID
          createdBy
          sectionID
          count
          goalID
          _version
          _deleted
          _lastChangedAt
          sectionEntriesId
          __typename
        }
        entryID
        component {
          id
          createdAt
          updatedAt
          clientID
          complete
          owner
          type
          order
          settings
          to
          from
          rows
          name
          noEdit
          size
          label
          placeholder
          upload
          options
          content
          boolean
          variant
          prompts
          collapse
          showInfo
          showPrompts
          multiline
          addButton
          multiselect
          information
          instructions
          removeButton
          booleanLabel
          annotationLabels
          entrySectionID
          goalID
          _version
          _deleted
          _lastChangedAt
          entrySectionComponentsId
          __typename
        }
        componentID
        clientID
        order
        goalEntries {
          nextToken
          startedAt
          __typename
        }
        complete
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      sectionEntriesId
      __typename
    }
  }
`;
export const createEntrySection = /* GraphQL */ `
  mutation CreateEntrySection(
    $input: CreateEntrySectionInput!
    $condition: ModelEntrySectionConditionInput
  ) {
    createEntrySection(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      name
      complete
      owner
      order
      noEdit
      settings
      showInfo
      information
      informationTitle
      orientation
      clientID
      entryID
      createdBy
      components {
        items {
          id
          createdAt
          updatedAt
          clientID
          complete
          owner
          type
          order
          settings
          to
          from
          rows
          name
          noEdit
          size
          label
          placeholder
          upload
          options
          content
          boolean
          variant
          prompts
          collapse
          showInfo
          showPrompts
          multiline
          addButton
          multiselect
          information
          instructions
          removeButton
          booleanLabel
          annotationLabels
          entrySectionID
          goalID
          _version
          _deleted
          _lastChangedAt
          entrySectionComponentsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      entryEntrySectionsId
      __typename
    }
  }
`;
export const updateEntrySection = /* GraphQL */ `
  mutation UpdateEntrySection(
    $input: UpdateEntrySectionInput!
    $condition: ModelEntrySectionConditionInput
  ) {
    updateEntrySection(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      name
      complete
      owner
      order
      noEdit
      settings
      showInfo
      information
      informationTitle
      orientation
      clientID
      entryID
      createdBy
      components {
        items {
          id
          createdAt
          updatedAt
          clientID
          complete
          owner
          type
          order
          settings
          to
          from
          rows
          name
          noEdit
          size
          label
          placeholder
          upload
          options
          content
          boolean
          variant
          prompts
          collapse
          showInfo
          showPrompts
          multiline
          addButton
          multiselect
          information
          instructions
          removeButton
          booleanLabel
          annotationLabels
          entrySectionID
          goalID
          _version
          _deleted
          _lastChangedAt
          entrySectionComponentsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      entryEntrySectionsId
      __typename
    }
  }
`;
export const deleteEntrySection = /* GraphQL */ `
  mutation DeleteEntrySection(
    $input: DeleteEntrySectionInput!
    $condition: ModelEntrySectionConditionInput
  ) {
    deleteEntrySection(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      name
      complete
      owner
      order
      noEdit
      settings
      showInfo
      information
      informationTitle
      orientation
      clientID
      entryID
      createdBy
      components {
        items {
          id
          createdAt
          updatedAt
          clientID
          complete
          owner
          type
          order
          settings
          to
          from
          rows
          name
          noEdit
          size
          label
          placeholder
          upload
          options
          content
          boolean
          variant
          prompts
          collapse
          showInfo
          showPrompts
          multiline
          addButton
          multiselect
          information
          instructions
          removeButton
          booleanLabel
          annotationLabels
          entrySectionID
          goalID
          _version
          _deleted
          _lastChangedAt
          entrySectionComponentsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      entryEntrySectionsId
      __typename
    }
  }
`;
export const createComponent = /* GraphQL */ `
  mutation CreateComponent(
    $input: CreateComponentInput!
    $condition: ModelComponentConditionInput
  ) {
    createComponent(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      clientID
      complete
      owner
      type
      order
      settings
      to
      from
      rows
      name
      noEdit
      size
      label
      placeholder
      upload
      options
      content
      boolean
      variant
      prompts
      collapse
      showInfo
      showPrompts
      multiline
      addButton
      multiselect
      information
      instructions
      removeButton
      booleanLabel
      annotationLabels
      userInputs {
        items {
          id
          inputName
          enteredAt
          createdAt
          updatedAt
          owner
          inputValue
          clockValue
          emojiRaterValue
          sliderValue
          numberValue
          incrementerValue
          raterValue
          raterBoolean
          audioCompressionStatus
          audioDurationSeconds
          audioType
          audioReady
          audioRecording
          audioUploadingStatus
          audioMode
          componentType
          componentID
          componentOrder
          recordID
          createdBy
          updatedBy
          clientID
          _version
          _deleted
          _lastChangedAt
          componentUserInputsId
          recordUserInputsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      entrySectionID
      entrySection {
        id
        createdAt
        updatedAt
        name
        complete
        owner
        order
        noEdit
        settings
        showInfo
        information
        informationTitle
        orientation
        clientID
        entryID
        createdBy
        components {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        entryEntrySectionsId
        __typename
      }
      goalID
      goal {
        id
        owner
        name
        description
        createdAt
        updatedAt
        createdBy
        updatedBy
        targetNumber
        targetFrequency
        targetType
        recurringType
        recurringUntil
        recurringNumber
        color
        section {
          id
          createdAt
          updatedAt
          complete
          name
          inactive
          noEdit
          analytics
          order
          owner
          settings
          clientID
          createdBy
          goalID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        sectionID
        entry {
          id
          createdAt
          updatedAt
          owner
          name
          icon
          type
          order
          addLogic
          logic
          inactive
          noEdit
          settings
          complete
          analytics
          draftRecordID
          clientID
          createdBy
          sectionID
          count
          goalID
          _version
          _deleted
          _lastChangedAt
          sectionEntriesId
          __typename
        }
        entryID
        component {
          id
          createdAt
          updatedAt
          clientID
          complete
          owner
          type
          order
          settings
          to
          from
          rows
          name
          noEdit
          size
          label
          placeholder
          upload
          options
          content
          boolean
          variant
          prompts
          collapse
          showInfo
          showPrompts
          multiline
          addButton
          multiselect
          information
          instructions
          removeButton
          booleanLabel
          annotationLabels
          entrySectionID
          goalID
          _version
          _deleted
          _lastChangedAt
          entrySectionComponentsId
          __typename
        }
        componentID
        clientID
        order
        goalEntries {
          nextToken
          startedAt
          __typename
        }
        complete
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      infoContent {
        items {
          id
          owner
          title
          content
          widget
          componentID
          clientID
          order
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          componentInfoContentId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      entrySectionComponentsId
      __typename
    }
  }
`;
export const updateComponent = /* GraphQL */ `
  mutation UpdateComponent(
    $input: UpdateComponentInput!
    $condition: ModelComponentConditionInput
  ) {
    updateComponent(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      clientID
      complete
      owner
      type
      order
      settings
      to
      from
      rows
      name
      noEdit
      size
      label
      placeholder
      upload
      options
      content
      boolean
      variant
      prompts
      collapse
      showInfo
      showPrompts
      multiline
      addButton
      multiselect
      information
      instructions
      removeButton
      booleanLabel
      annotationLabels
      userInputs {
        items {
          id
          inputName
          enteredAt
          createdAt
          updatedAt
          owner
          inputValue
          clockValue
          emojiRaterValue
          sliderValue
          numberValue
          incrementerValue
          raterValue
          raterBoolean
          audioCompressionStatus
          audioDurationSeconds
          audioType
          audioReady
          audioRecording
          audioUploadingStatus
          audioMode
          componentType
          componentID
          componentOrder
          recordID
          createdBy
          updatedBy
          clientID
          _version
          _deleted
          _lastChangedAt
          componentUserInputsId
          recordUserInputsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      entrySectionID
      entrySection {
        id
        createdAt
        updatedAt
        name
        complete
        owner
        order
        noEdit
        settings
        showInfo
        information
        informationTitle
        orientation
        clientID
        entryID
        createdBy
        components {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        entryEntrySectionsId
        __typename
      }
      goalID
      goal {
        id
        owner
        name
        description
        createdAt
        updatedAt
        createdBy
        updatedBy
        targetNumber
        targetFrequency
        targetType
        recurringType
        recurringUntil
        recurringNumber
        color
        section {
          id
          createdAt
          updatedAt
          complete
          name
          inactive
          noEdit
          analytics
          order
          owner
          settings
          clientID
          createdBy
          goalID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        sectionID
        entry {
          id
          createdAt
          updatedAt
          owner
          name
          icon
          type
          order
          addLogic
          logic
          inactive
          noEdit
          settings
          complete
          analytics
          draftRecordID
          clientID
          createdBy
          sectionID
          count
          goalID
          _version
          _deleted
          _lastChangedAt
          sectionEntriesId
          __typename
        }
        entryID
        component {
          id
          createdAt
          updatedAt
          clientID
          complete
          owner
          type
          order
          settings
          to
          from
          rows
          name
          noEdit
          size
          label
          placeholder
          upload
          options
          content
          boolean
          variant
          prompts
          collapse
          showInfo
          showPrompts
          multiline
          addButton
          multiselect
          information
          instructions
          removeButton
          booleanLabel
          annotationLabels
          entrySectionID
          goalID
          _version
          _deleted
          _lastChangedAt
          entrySectionComponentsId
          __typename
        }
        componentID
        clientID
        order
        goalEntries {
          nextToken
          startedAt
          __typename
        }
        complete
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      infoContent {
        items {
          id
          owner
          title
          content
          widget
          componentID
          clientID
          order
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          componentInfoContentId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      entrySectionComponentsId
      __typename
    }
  }
`;
export const deleteComponent = /* GraphQL */ `
  mutation DeleteComponent(
    $input: DeleteComponentInput!
    $condition: ModelComponentConditionInput
  ) {
    deleteComponent(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      clientID
      complete
      owner
      type
      order
      settings
      to
      from
      rows
      name
      noEdit
      size
      label
      placeholder
      upload
      options
      content
      boolean
      variant
      prompts
      collapse
      showInfo
      showPrompts
      multiline
      addButton
      multiselect
      information
      instructions
      removeButton
      booleanLabel
      annotationLabels
      userInputs {
        items {
          id
          inputName
          enteredAt
          createdAt
          updatedAt
          owner
          inputValue
          clockValue
          emojiRaterValue
          sliderValue
          numberValue
          incrementerValue
          raterValue
          raterBoolean
          audioCompressionStatus
          audioDurationSeconds
          audioType
          audioReady
          audioRecording
          audioUploadingStatus
          audioMode
          componentType
          componentID
          componentOrder
          recordID
          createdBy
          updatedBy
          clientID
          _version
          _deleted
          _lastChangedAt
          componentUserInputsId
          recordUserInputsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      entrySectionID
      entrySection {
        id
        createdAt
        updatedAt
        name
        complete
        owner
        order
        noEdit
        settings
        showInfo
        information
        informationTitle
        orientation
        clientID
        entryID
        createdBy
        components {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        entryEntrySectionsId
        __typename
      }
      goalID
      goal {
        id
        owner
        name
        description
        createdAt
        updatedAt
        createdBy
        updatedBy
        targetNumber
        targetFrequency
        targetType
        recurringType
        recurringUntil
        recurringNumber
        color
        section {
          id
          createdAt
          updatedAt
          complete
          name
          inactive
          noEdit
          analytics
          order
          owner
          settings
          clientID
          createdBy
          goalID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        sectionID
        entry {
          id
          createdAt
          updatedAt
          owner
          name
          icon
          type
          order
          addLogic
          logic
          inactive
          noEdit
          settings
          complete
          analytics
          draftRecordID
          clientID
          createdBy
          sectionID
          count
          goalID
          _version
          _deleted
          _lastChangedAt
          sectionEntriesId
          __typename
        }
        entryID
        component {
          id
          createdAt
          updatedAt
          clientID
          complete
          owner
          type
          order
          settings
          to
          from
          rows
          name
          noEdit
          size
          label
          placeholder
          upload
          options
          content
          boolean
          variant
          prompts
          collapse
          showInfo
          showPrompts
          multiline
          addButton
          multiselect
          information
          instructions
          removeButton
          booleanLabel
          annotationLabels
          entrySectionID
          goalID
          _version
          _deleted
          _lastChangedAt
          entrySectionComponentsId
          __typename
        }
        componentID
        clientID
        order
        goalEntries {
          nextToken
          startedAt
          __typename
        }
        complete
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      infoContent {
        items {
          id
          owner
          title
          content
          widget
          componentID
          clientID
          order
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          componentInfoContentId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      entrySectionComponentsId
      __typename
    }
  }
`;
export const createInfoContent = /* GraphQL */ `
  mutation CreateInfoContent(
    $input: CreateInfoContentInput!
    $condition: ModelInfoContentConditionInput
  ) {
    createInfoContent(input: $input, condition: $condition) {
      id
      owner
      title
      content
      widget
      componentID
      clientID
      order
      component {
        id
        createdAt
        updatedAt
        clientID
        complete
        owner
        type
        order
        settings
        to
        from
        rows
        name
        noEdit
        size
        label
        placeholder
        upload
        options
        content
        boolean
        variant
        prompts
        collapse
        showInfo
        showPrompts
        multiline
        addButton
        multiselect
        information
        instructions
        removeButton
        booleanLabel
        annotationLabels
        userInputs {
          nextToken
          startedAt
          __typename
        }
        entrySectionID
        entrySection {
          id
          createdAt
          updatedAt
          name
          complete
          owner
          order
          noEdit
          settings
          showInfo
          information
          informationTitle
          orientation
          clientID
          entryID
          createdBy
          _version
          _deleted
          _lastChangedAt
          entryEntrySectionsId
          __typename
        }
        goalID
        goal {
          id
          owner
          name
          description
          createdAt
          updatedAt
          createdBy
          updatedBy
          targetNumber
          targetFrequency
          targetType
          recurringType
          recurringUntil
          recurringNumber
          color
          sectionID
          entryID
          componentID
          clientID
          order
          complete
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        infoContent {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        entrySectionComponentsId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      componentInfoContentId
      __typename
    }
  }
`;
export const updateInfoContent = /* GraphQL */ `
  mutation UpdateInfoContent(
    $input: UpdateInfoContentInput!
    $condition: ModelInfoContentConditionInput
  ) {
    updateInfoContent(input: $input, condition: $condition) {
      id
      owner
      title
      content
      widget
      componentID
      clientID
      order
      component {
        id
        createdAt
        updatedAt
        clientID
        complete
        owner
        type
        order
        settings
        to
        from
        rows
        name
        noEdit
        size
        label
        placeholder
        upload
        options
        content
        boolean
        variant
        prompts
        collapse
        showInfo
        showPrompts
        multiline
        addButton
        multiselect
        information
        instructions
        removeButton
        booleanLabel
        annotationLabels
        userInputs {
          nextToken
          startedAt
          __typename
        }
        entrySectionID
        entrySection {
          id
          createdAt
          updatedAt
          name
          complete
          owner
          order
          noEdit
          settings
          showInfo
          information
          informationTitle
          orientation
          clientID
          entryID
          createdBy
          _version
          _deleted
          _lastChangedAt
          entryEntrySectionsId
          __typename
        }
        goalID
        goal {
          id
          owner
          name
          description
          createdAt
          updatedAt
          createdBy
          updatedBy
          targetNumber
          targetFrequency
          targetType
          recurringType
          recurringUntil
          recurringNumber
          color
          sectionID
          entryID
          componentID
          clientID
          order
          complete
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        infoContent {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        entrySectionComponentsId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      componentInfoContentId
      __typename
    }
  }
`;
export const deleteInfoContent = /* GraphQL */ `
  mutation DeleteInfoContent(
    $input: DeleteInfoContentInput!
    $condition: ModelInfoContentConditionInput
  ) {
    deleteInfoContent(input: $input, condition: $condition) {
      id
      owner
      title
      content
      widget
      componentID
      clientID
      order
      component {
        id
        createdAt
        updatedAt
        clientID
        complete
        owner
        type
        order
        settings
        to
        from
        rows
        name
        noEdit
        size
        label
        placeholder
        upload
        options
        content
        boolean
        variant
        prompts
        collapse
        showInfo
        showPrompts
        multiline
        addButton
        multiselect
        information
        instructions
        removeButton
        booleanLabel
        annotationLabels
        userInputs {
          nextToken
          startedAt
          __typename
        }
        entrySectionID
        entrySection {
          id
          createdAt
          updatedAt
          name
          complete
          owner
          order
          noEdit
          settings
          showInfo
          information
          informationTitle
          orientation
          clientID
          entryID
          createdBy
          _version
          _deleted
          _lastChangedAt
          entryEntrySectionsId
          __typename
        }
        goalID
        goal {
          id
          owner
          name
          description
          createdAt
          updatedAt
          createdBy
          updatedBy
          targetNumber
          targetFrequency
          targetType
          recurringType
          recurringUntil
          recurringNumber
          color
          sectionID
          entryID
          componentID
          clientID
          order
          complete
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        infoContent {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        entrySectionComponentsId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      componentInfoContentId
      __typename
    }
  }
`;
export const createUserInput = /* GraphQL */ `
  mutation CreateUserInput(
    $input: CreateUserInputInput!
    $condition: ModelUserInputConditionInput
  ) {
    createUserInput(input: $input, condition: $condition) {
      id
      inputName
      enteredAt
      createdAt
      updatedAt
      owner
      inputValue
      clockValue
      checkboxesValue {
        id
        label
        order
        __typename
      }
      emojiRaterValue
      radiosValue {
        id
        label
        order
        __typename
      }
      sliderValue
      numberValue
      incrementerValue
      chipsValue {
        id
        label
        order
        __typename
      }
      raterValue
      raterBoolean
      audioCompressionStatus
      audioDurationSeconds
      audioType
      audioReady
      audioRecording
      audioUploadingStatus
      audioMode
      componentType
      componentID
      componentOrder
      component {
        id
        createdAt
        updatedAt
        clientID
        complete
        owner
        type
        order
        settings
        to
        from
        rows
        name
        noEdit
        size
        label
        placeholder
        upload
        options
        content
        boolean
        variant
        prompts
        collapse
        showInfo
        showPrompts
        multiline
        addButton
        multiselect
        information
        instructions
        removeButton
        booleanLabel
        annotationLabels
        userInputs {
          nextToken
          startedAt
          __typename
        }
        entrySectionID
        entrySection {
          id
          createdAt
          updatedAt
          name
          complete
          owner
          order
          noEdit
          settings
          showInfo
          information
          informationTitle
          orientation
          clientID
          entryID
          createdBy
          _version
          _deleted
          _lastChangedAt
          entryEntrySectionsId
          __typename
        }
        goalID
        goal {
          id
          owner
          name
          description
          createdAt
          updatedAt
          createdBy
          updatedBy
          targetNumber
          targetFrequency
          targetType
          recurringType
          recurringUntil
          recurringNumber
          color
          sectionID
          entryID
          componentID
          clientID
          order
          complete
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        infoContent {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        entrySectionComponentsId
        __typename
      }
      recordID
      record {
        id
        enteredAt
        createdAt
        updatedAt
        updatedBy
        owner
        hasUpdatedGoalEntry
        hasData
        draft
        createdBy
        editing
        sectionID
        entryID
        entry {
          id
          createdAt
          updatedAt
          owner
          name
          icon
          type
          order
          addLogic
          logic
          inactive
          noEdit
          settings
          complete
          analytics
          draftRecordID
          clientID
          createdBy
          sectionID
          count
          goalID
          _version
          _deleted
          _lastChangedAt
          sectionEntriesId
          __typename
        }
        clientID
        userInputs {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      annotations {
        items {
          id
          categories
          createdAt
          updatedAt
          owner
          createdBy
          updatedBy
          userInputID
          isActive
          isHighlight
          mode
          note
          regionTotalDuration
          regionMinLength
          regionMaxLength
          regionID
          regionStart
          regionEnd
          regionDrag
          regionResize
          regionColor
          clientID
          _version
          _deleted
          _lastChangedAt
          userInputAnnotationsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdBy
      updatedBy
      clientID
      _version
      _deleted
      _lastChangedAt
      componentUserInputsId
      recordUserInputsId
      __typename
    }
  }
`;
export const updateUserInput = /* GraphQL */ `
  mutation UpdateUserInput(
    $input: UpdateUserInputInput!
    $condition: ModelUserInputConditionInput
  ) {
    updateUserInput(input: $input, condition: $condition) {
      id
      inputName
      enteredAt
      createdAt
      updatedAt
      owner
      inputValue
      clockValue
      checkboxesValue {
        id
        label
        order
        __typename
      }
      emojiRaterValue
      radiosValue {
        id
        label
        order
        __typename
      }
      sliderValue
      numberValue
      incrementerValue
      chipsValue {
        id
        label
        order
        __typename
      }
      raterValue
      raterBoolean
      audioCompressionStatus
      audioDurationSeconds
      audioType
      audioReady
      audioRecording
      audioUploadingStatus
      audioMode
      componentType
      componentID
      componentOrder
      component {
        id
        createdAt
        updatedAt
        clientID
        complete
        owner
        type
        order
        settings
        to
        from
        rows
        name
        noEdit
        size
        label
        placeholder
        upload
        options
        content
        boolean
        variant
        prompts
        collapse
        showInfo
        showPrompts
        multiline
        addButton
        multiselect
        information
        instructions
        removeButton
        booleanLabel
        annotationLabels
        userInputs {
          nextToken
          startedAt
          __typename
        }
        entrySectionID
        entrySection {
          id
          createdAt
          updatedAt
          name
          complete
          owner
          order
          noEdit
          settings
          showInfo
          information
          informationTitle
          orientation
          clientID
          entryID
          createdBy
          _version
          _deleted
          _lastChangedAt
          entryEntrySectionsId
          __typename
        }
        goalID
        goal {
          id
          owner
          name
          description
          createdAt
          updatedAt
          createdBy
          updatedBy
          targetNumber
          targetFrequency
          targetType
          recurringType
          recurringUntil
          recurringNumber
          color
          sectionID
          entryID
          componentID
          clientID
          order
          complete
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        infoContent {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        entrySectionComponentsId
        __typename
      }
      recordID
      record {
        id
        enteredAt
        createdAt
        updatedAt
        updatedBy
        owner
        hasUpdatedGoalEntry
        hasData
        draft
        createdBy
        editing
        sectionID
        entryID
        entry {
          id
          createdAt
          updatedAt
          owner
          name
          icon
          type
          order
          addLogic
          logic
          inactive
          noEdit
          settings
          complete
          analytics
          draftRecordID
          clientID
          createdBy
          sectionID
          count
          goalID
          _version
          _deleted
          _lastChangedAt
          sectionEntriesId
          __typename
        }
        clientID
        userInputs {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      annotations {
        items {
          id
          categories
          createdAt
          updatedAt
          owner
          createdBy
          updatedBy
          userInputID
          isActive
          isHighlight
          mode
          note
          regionTotalDuration
          regionMinLength
          regionMaxLength
          regionID
          regionStart
          regionEnd
          regionDrag
          regionResize
          regionColor
          clientID
          _version
          _deleted
          _lastChangedAt
          userInputAnnotationsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdBy
      updatedBy
      clientID
      _version
      _deleted
      _lastChangedAt
      componentUserInputsId
      recordUserInputsId
      __typename
    }
  }
`;
export const deleteUserInput = /* GraphQL */ `
  mutation DeleteUserInput(
    $input: DeleteUserInputInput!
    $condition: ModelUserInputConditionInput
  ) {
    deleteUserInput(input: $input, condition: $condition) {
      id
      inputName
      enteredAt
      createdAt
      updatedAt
      owner
      inputValue
      clockValue
      checkboxesValue {
        id
        label
        order
        __typename
      }
      emojiRaterValue
      radiosValue {
        id
        label
        order
        __typename
      }
      sliderValue
      numberValue
      incrementerValue
      chipsValue {
        id
        label
        order
        __typename
      }
      raterValue
      raterBoolean
      audioCompressionStatus
      audioDurationSeconds
      audioType
      audioReady
      audioRecording
      audioUploadingStatus
      audioMode
      componentType
      componentID
      componentOrder
      component {
        id
        createdAt
        updatedAt
        clientID
        complete
        owner
        type
        order
        settings
        to
        from
        rows
        name
        noEdit
        size
        label
        placeholder
        upload
        options
        content
        boolean
        variant
        prompts
        collapse
        showInfo
        showPrompts
        multiline
        addButton
        multiselect
        information
        instructions
        removeButton
        booleanLabel
        annotationLabels
        userInputs {
          nextToken
          startedAt
          __typename
        }
        entrySectionID
        entrySection {
          id
          createdAt
          updatedAt
          name
          complete
          owner
          order
          noEdit
          settings
          showInfo
          information
          informationTitle
          orientation
          clientID
          entryID
          createdBy
          _version
          _deleted
          _lastChangedAt
          entryEntrySectionsId
          __typename
        }
        goalID
        goal {
          id
          owner
          name
          description
          createdAt
          updatedAt
          createdBy
          updatedBy
          targetNumber
          targetFrequency
          targetType
          recurringType
          recurringUntil
          recurringNumber
          color
          sectionID
          entryID
          componentID
          clientID
          order
          complete
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        infoContent {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        entrySectionComponentsId
        __typename
      }
      recordID
      record {
        id
        enteredAt
        createdAt
        updatedAt
        updatedBy
        owner
        hasUpdatedGoalEntry
        hasData
        draft
        createdBy
        editing
        sectionID
        entryID
        entry {
          id
          createdAt
          updatedAt
          owner
          name
          icon
          type
          order
          addLogic
          logic
          inactive
          noEdit
          settings
          complete
          analytics
          draftRecordID
          clientID
          createdBy
          sectionID
          count
          goalID
          _version
          _deleted
          _lastChangedAt
          sectionEntriesId
          __typename
        }
        clientID
        userInputs {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      annotations {
        items {
          id
          categories
          createdAt
          updatedAt
          owner
          createdBy
          updatedBy
          userInputID
          isActive
          isHighlight
          mode
          note
          regionTotalDuration
          regionMinLength
          regionMaxLength
          regionID
          regionStart
          regionEnd
          regionDrag
          regionResize
          regionColor
          clientID
          _version
          _deleted
          _lastChangedAt
          userInputAnnotationsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdBy
      updatedBy
      clientID
      _version
      _deleted
      _lastChangedAt
      componentUserInputsId
      recordUserInputsId
      __typename
    }
  }
`;
export const createAnnotation = /* GraphQL */ `
  mutation CreateAnnotation(
    $input: CreateAnnotationInput!
    $condition: ModelAnnotationConditionInput
  ) {
    createAnnotation(input: $input, condition: $condition) {
      id
      categories
      createdAt
      updatedAt
      owner
      createdBy
      updatedBy
      userInputID
      userInput {
        id
        inputName
        enteredAt
        createdAt
        updatedAt
        owner
        inputValue
        clockValue
        checkboxesValue {
          id
          label
          order
          __typename
        }
        emojiRaterValue
        radiosValue {
          id
          label
          order
          __typename
        }
        sliderValue
        numberValue
        incrementerValue
        chipsValue {
          id
          label
          order
          __typename
        }
        raterValue
        raterBoolean
        audioCompressionStatus
        audioDurationSeconds
        audioType
        audioReady
        audioRecording
        audioUploadingStatus
        audioMode
        componentType
        componentID
        componentOrder
        component {
          id
          createdAt
          updatedAt
          clientID
          complete
          owner
          type
          order
          settings
          to
          from
          rows
          name
          noEdit
          size
          label
          placeholder
          upload
          options
          content
          boolean
          variant
          prompts
          collapse
          showInfo
          showPrompts
          multiline
          addButton
          multiselect
          information
          instructions
          removeButton
          booleanLabel
          annotationLabels
          entrySectionID
          goalID
          _version
          _deleted
          _lastChangedAt
          entrySectionComponentsId
          __typename
        }
        recordID
        record {
          id
          enteredAt
          createdAt
          updatedAt
          updatedBy
          owner
          hasUpdatedGoalEntry
          hasData
          draft
          createdBy
          editing
          sectionID
          entryID
          clientID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        annotations {
          nextToken
          startedAt
          __typename
        }
        createdBy
        updatedBy
        clientID
        _version
        _deleted
        _lastChangedAt
        componentUserInputsId
        recordUserInputsId
        __typename
      }
      isActive
      isHighlight
      mode
      note
      regionTotalDuration
      regionMinLength
      regionMaxLength
      regionID
      regionStart
      regionEnd
      regionDrag
      regionResize
      regionColor
      clientID
      _version
      _deleted
      _lastChangedAt
      userInputAnnotationsId
      __typename
    }
  }
`;
export const updateAnnotation = /* GraphQL */ `
  mutation UpdateAnnotation(
    $input: UpdateAnnotationInput!
    $condition: ModelAnnotationConditionInput
  ) {
    updateAnnotation(input: $input, condition: $condition) {
      id
      categories
      createdAt
      updatedAt
      owner
      createdBy
      updatedBy
      userInputID
      userInput {
        id
        inputName
        enteredAt
        createdAt
        updatedAt
        owner
        inputValue
        clockValue
        checkboxesValue {
          id
          label
          order
          __typename
        }
        emojiRaterValue
        radiosValue {
          id
          label
          order
          __typename
        }
        sliderValue
        numberValue
        incrementerValue
        chipsValue {
          id
          label
          order
          __typename
        }
        raterValue
        raterBoolean
        audioCompressionStatus
        audioDurationSeconds
        audioType
        audioReady
        audioRecording
        audioUploadingStatus
        audioMode
        componentType
        componentID
        componentOrder
        component {
          id
          createdAt
          updatedAt
          clientID
          complete
          owner
          type
          order
          settings
          to
          from
          rows
          name
          noEdit
          size
          label
          placeholder
          upload
          options
          content
          boolean
          variant
          prompts
          collapse
          showInfo
          showPrompts
          multiline
          addButton
          multiselect
          information
          instructions
          removeButton
          booleanLabel
          annotationLabels
          entrySectionID
          goalID
          _version
          _deleted
          _lastChangedAt
          entrySectionComponentsId
          __typename
        }
        recordID
        record {
          id
          enteredAt
          createdAt
          updatedAt
          updatedBy
          owner
          hasUpdatedGoalEntry
          hasData
          draft
          createdBy
          editing
          sectionID
          entryID
          clientID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        annotations {
          nextToken
          startedAt
          __typename
        }
        createdBy
        updatedBy
        clientID
        _version
        _deleted
        _lastChangedAt
        componentUserInputsId
        recordUserInputsId
        __typename
      }
      isActive
      isHighlight
      mode
      note
      regionTotalDuration
      regionMinLength
      regionMaxLength
      regionID
      regionStart
      regionEnd
      regionDrag
      regionResize
      regionColor
      clientID
      _version
      _deleted
      _lastChangedAt
      userInputAnnotationsId
      __typename
    }
  }
`;
export const deleteAnnotation = /* GraphQL */ `
  mutation DeleteAnnotation(
    $input: DeleteAnnotationInput!
    $condition: ModelAnnotationConditionInput
  ) {
    deleteAnnotation(input: $input, condition: $condition) {
      id
      categories
      createdAt
      updatedAt
      owner
      createdBy
      updatedBy
      userInputID
      userInput {
        id
        inputName
        enteredAt
        createdAt
        updatedAt
        owner
        inputValue
        clockValue
        checkboxesValue {
          id
          label
          order
          __typename
        }
        emojiRaterValue
        radiosValue {
          id
          label
          order
          __typename
        }
        sliderValue
        numberValue
        incrementerValue
        chipsValue {
          id
          label
          order
          __typename
        }
        raterValue
        raterBoolean
        audioCompressionStatus
        audioDurationSeconds
        audioType
        audioReady
        audioRecording
        audioUploadingStatus
        audioMode
        componentType
        componentID
        componentOrder
        component {
          id
          createdAt
          updatedAt
          clientID
          complete
          owner
          type
          order
          settings
          to
          from
          rows
          name
          noEdit
          size
          label
          placeholder
          upload
          options
          content
          boolean
          variant
          prompts
          collapse
          showInfo
          showPrompts
          multiline
          addButton
          multiselect
          information
          instructions
          removeButton
          booleanLabel
          annotationLabels
          entrySectionID
          goalID
          _version
          _deleted
          _lastChangedAt
          entrySectionComponentsId
          __typename
        }
        recordID
        record {
          id
          enteredAt
          createdAt
          updatedAt
          updatedBy
          owner
          hasUpdatedGoalEntry
          hasData
          draft
          createdBy
          editing
          sectionID
          entryID
          clientID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        annotations {
          nextToken
          startedAt
          __typename
        }
        createdBy
        updatedBy
        clientID
        _version
        _deleted
        _lastChangedAt
        componentUserInputsId
        recordUserInputsId
        __typename
      }
      isActive
      isHighlight
      mode
      note
      regionTotalDuration
      regionMinLength
      regionMaxLength
      regionID
      regionStart
      regionEnd
      regionDrag
      regionResize
      regionColor
      clientID
      _version
      _deleted
      _lastChangedAt
      userInputAnnotationsId
      __typename
    }
  }
`;
export const createRecord = /* GraphQL */ `
  mutation CreateRecord(
    $input: CreateRecordInput!
    $condition: ModelRecordConditionInput
  ) {
    createRecord(input: $input, condition: $condition) {
      id
      enteredAt
      createdAt
      updatedAt
      updatedBy
      owner
      hasUpdatedGoalEntry
      hasData
      draft
      createdBy
      editing
      sectionID
      entryID
      entry {
        id
        createdAt
        updatedAt
        owner
        name
        icon
        type
        order
        addLogic
        logic
        inactive
        noEdit
        settings
        complete
        analytics
        draftRecordID
        clientID
        createdBy
        sectionID
        section {
          id
          createdAt
          updatedAt
          complete
          name
          inactive
          noEdit
          analytics
          order
          owner
          settings
          clientID
          createdBy
          goalID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        entrySections {
          nextToken
          startedAt
          __typename
        }
        count
        goalID
        goal {
          id
          owner
          name
          description
          createdAt
          updatedAt
          createdBy
          updatedBy
          targetNumber
          targetFrequency
          targetType
          recurringType
          recurringUntil
          recurringNumber
          color
          sectionID
          entryID
          componentID
          clientID
          order
          complete
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        sectionEntriesId
        __typename
      }
      clientID
      userInputs {
        items {
          id
          inputName
          enteredAt
          createdAt
          updatedAt
          owner
          inputValue
          clockValue
          emojiRaterValue
          sliderValue
          numberValue
          incrementerValue
          raterValue
          raterBoolean
          audioCompressionStatus
          audioDurationSeconds
          audioType
          audioReady
          audioRecording
          audioUploadingStatus
          audioMode
          componentType
          componentID
          componentOrder
          recordID
          createdBy
          updatedBy
          clientID
          _version
          _deleted
          _lastChangedAt
          componentUserInputsId
          recordUserInputsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateRecord = /* GraphQL */ `
  mutation UpdateRecord(
    $input: UpdateRecordInput!
    $condition: ModelRecordConditionInput
  ) {
    updateRecord(input: $input, condition: $condition) {
      id
      enteredAt
      createdAt
      updatedAt
      updatedBy
      owner
      hasUpdatedGoalEntry
      hasData
      draft
      createdBy
      editing
      sectionID
      entryID
      entry {
        id
        createdAt
        updatedAt
        owner
        name
        icon
        type
        order
        addLogic
        logic
        inactive
        noEdit
        settings
        complete
        analytics
        draftRecordID
        clientID
        createdBy
        sectionID
        section {
          id
          createdAt
          updatedAt
          complete
          name
          inactive
          noEdit
          analytics
          order
          owner
          settings
          clientID
          createdBy
          goalID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        entrySections {
          nextToken
          startedAt
          __typename
        }
        count
        goalID
        goal {
          id
          owner
          name
          description
          createdAt
          updatedAt
          createdBy
          updatedBy
          targetNumber
          targetFrequency
          targetType
          recurringType
          recurringUntil
          recurringNumber
          color
          sectionID
          entryID
          componentID
          clientID
          order
          complete
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        sectionEntriesId
        __typename
      }
      clientID
      userInputs {
        items {
          id
          inputName
          enteredAt
          createdAt
          updatedAt
          owner
          inputValue
          clockValue
          emojiRaterValue
          sliderValue
          numberValue
          incrementerValue
          raterValue
          raterBoolean
          audioCompressionStatus
          audioDurationSeconds
          audioType
          audioReady
          audioRecording
          audioUploadingStatus
          audioMode
          componentType
          componentID
          componentOrder
          recordID
          createdBy
          updatedBy
          clientID
          _version
          _deleted
          _lastChangedAt
          componentUserInputsId
          recordUserInputsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteRecord = /* GraphQL */ `
  mutation DeleteRecord(
    $input: DeleteRecordInput!
    $condition: ModelRecordConditionInput
  ) {
    deleteRecord(input: $input, condition: $condition) {
      id
      enteredAt
      createdAt
      updatedAt
      updatedBy
      owner
      hasUpdatedGoalEntry
      hasData
      draft
      createdBy
      editing
      sectionID
      entryID
      entry {
        id
        createdAt
        updatedAt
        owner
        name
        icon
        type
        order
        addLogic
        logic
        inactive
        noEdit
        settings
        complete
        analytics
        draftRecordID
        clientID
        createdBy
        sectionID
        section {
          id
          createdAt
          updatedAt
          complete
          name
          inactive
          noEdit
          analytics
          order
          owner
          settings
          clientID
          createdBy
          goalID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        entrySections {
          nextToken
          startedAt
          __typename
        }
        count
        goalID
        goal {
          id
          owner
          name
          description
          createdAt
          updatedAt
          createdBy
          updatedBy
          targetNumber
          targetFrequency
          targetType
          recurringType
          recurringUntil
          recurringNumber
          color
          sectionID
          entryID
          componentID
          clientID
          order
          complete
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        sectionEntriesId
        __typename
      }
      clientID
      userInputs {
        items {
          id
          inputName
          enteredAt
          createdAt
          updatedAt
          owner
          inputValue
          clockValue
          emojiRaterValue
          sliderValue
          numberValue
          incrementerValue
          raterValue
          raterBoolean
          audioCompressionStatus
          audioDurationSeconds
          audioType
          audioReady
          audioRecording
          audioUploadingStatus
          audioMode
          componentType
          componentID
          componentOrder
          recordID
          createdBy
          updatedBy
          clientID
          _version
          _deleted
          _lastChangedAt
          componentUserInputsId
          recordUserInputsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createInsightsSection = /* GraphQL */ `
  mutation CreateInsightsSection(
    $input: CreateInsightsSectionInput!
    $condition: ModelInsightsSectionConditionInput
  ) {
    createInsightsSection(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      owner
      clientID
      name
      settings
      order
      orientation
      entryID
      insights {
        items {
          id
          createdAt
          updatedAt
          owner
          name
          aggregateBy
          dataType
          showLabels
          showNotes
          zoom
          connectNulls
          scatter
          countBy
          countByBoolean
          dataRecords
          clientID
          order
          type
          entryID
          insightsSectionID
          settings
          _version
          _deleted
          _lastChangedAt
          insightsSectionInsightsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateInsightsSection = /* GraphQL */ `
  mutation UpdateInsightsSection(
    $input: UpdateInsightsSectionInput!
    $condition: ModelInsightsSectionConditionInput
  ) {
    updateInsightsSection(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      owner
      clientID
      name
      settings
      order
      orientation
      entryID
      insights {
        items {
          id
          createdAt
          updatedAt
          owner
          name
          aggregateBy
          dataType
          showLabels
          showNotes
          zoom
          connectNulls
          scatter
          countBy
          countByBoolean
          dataRecords
          clientID
          order
          type
          entryID
          insightsSectionID
          settings
          _version
          _deleted
          _lastChangedAt
          insightsSectionInsightsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteInsightsSection = /* GraphQL */ `
  mutation DeleteInsightsSection(
    $input: DeleteInsightsSectionInput!
    $condition: ModelInsightsSectionConditionInput
  ) {
    deleteInsightsSection(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      owner
      clientID
      name
      settings
      order
      orientation
      entryID
      insights {
        items {
          id
          createdAt
          updatedAt
          owner
          name
          aggregateBy
          dataType
          showLabels
          showNotes
          zoom
          connectNulls
          scatter
          countBy
          countByBoolean
          dataRecords
          clientID
          order
          type
          entryID
          insightsSectionID
          settings
          _version
          _deleted
          _lastChangedAt
          insightsSectionInsightsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createInsight = /* GraphQL */ `
  mutation CreateInsight(
    $input: CreateInsightInput!
    $condition: ModelInsightConditionInput
  ) {
    createInsight(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      owner
      name
      aggregateBy
      dataType
      showLabels
      showNotes
      zoom
      connectNulls
      scatter
      countBy
      countByBoolean
      dataRecords
      clientID
      order
      type
      entryID
      insightsSectionID
      insightsSection {
        id
        createdAt
        updatedAt
        owner
        clientID
        name
        settings
        order
        orientation
        entryID
        insights {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      settings
      _version
      _deleted
      _lastChangedAt
      insightsSectionInsightsId
      __typename
    }
  }
`;
export const updateInsight = /* GraphQL */ `
  mutation UpdateInsight(
    $input: UpdateInsightInput!
    $condition: ModelInsightConditionInput
  ) {
    updateInsight(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      owner
      name
      aggregateBy
      dataType
      showLabels
      showNotes
      zoom
      connectNulls
      scatter
      countBy
      countByBoolean
      dataRecords
      clientID
      order
      type
      entryID
      insightsSectionID
      insightsSection {
        id
        createdAt
        updatedAt
        owner
        clientID
        name
        settings
        order
        orientation
        entryID
        insights {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      settings
      _version
      _deleted
      _lastChangedAt
      insightsSectionInsightsId
      __typename
    }
  }
`;
export const deleteInsight = /* GraphQL */ `
  mutation DeleteInsight(
    $input: DeleteInsightInput!
    $condition: ModelInsightConditionInput
  ) {
    deleteInsight(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      owner
      name
      aggregateBy
      dataType
      showLabels
      showNotes
      zoom
      connectNulls
      scatter
      countBy
      countByBoolean
      dataRecords
      clientID
      order
      type
      entryID
      insightsSectionID
      insightsSection {
        id
        createdAt
        updatedAt
        owner
        clientID
        name
        settings
        order
        orientation
        entryID
        insights {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      settings
      _version
      _deleted
      _lastChangedAt
      insightsSectionInsightsId
      __typename
    }
  }
`;
export const createGoal = /* GraphQL */ `
  mutation CreateGoal(
    $input: CreateGoalInput!
    $condition: ModelGoalConditionInput
  ) {
    createGoal(input: $input, condition: $condition) {
      id
      owner
      name
      description
      createdAt
      updatedAt
      createdBy
      updatedBy
      targetNumber
      targetFrequency
      targetType
      recurringType
      recurringUntil
      recurringNumber
      color
      section {
        id
        createdAt
        updatedAt
        complete
        name
        inactive
        noEdit
        analytics
        order
        owner
        settings
        clientID
        createdBy
        entries {
          nextToken
          startedAt
          __typename
        }
        goalID
        goal {
          id
          owner
          name
          description
          createdAt
          updatedAt
          createdBy
          updatedBy
          targetNumber
          targetFrequency
          targetType
          recurringType
          recurringUntil
          recurringNumber
          color
          sectionID
          entryID
          componentID
          clientID
          order
          complete
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      sectionID
      entry {
        id
        createdAt
        updatedAt
        owner
        name
        icon
        type
        order
        addLogic
        logic
        inactive
        noEdit
        settings
        complete
        analytics
        draftRecordID
        clientID
        createdBy
        sectionID
        section {
          id
          createdAt
          updatedAt
          complete
          name
          inactive
          noEdit
          analytics
          order
          owner
          settings
          clientID
          createdBy
          goalID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        entrySections {
          nextToken
          startedAt
          __typename
        }
        count
        goalID
        goal {
          id
          owner
          name
          description
          createdAt
          updatedAt
          createdBy
          updatedBy
          targetNumber
          targetFrequency
          targetType
          recurringType
          recurringUntil
          recurringNumber
          color
          sectionID
          entryID
          componentID
          clientID
          order
          complete
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        sectionEntriesId
        __typename
      }
      entryID
      component {
        id
        createdAt
        updatedAt
        clientID
        complete
        owner
        type
        order
        settings
        to
        from
        rows
        name
        noEdit
        size
        label
        placeholder
        upload
        options
        content
        boolean
        variant
        prompts
        collapse
        showInfo
        showPrompts
        multiline
        addButton
        multiselect
        information
        instructions
        removeButton
        booleanLabel
        annotationLabels
        userInputs {
          nextToken
          startedAt
          __typename
        }
        entrySectionID
        entrySection {
          id
          createdAt
          updatedAt
          name
          complete
          owner
          order
          noEdit
          settings
          showInfo
          information
          informationTitle
          orientation
          clientID
          entryID
          createdBy
          _version
          _deleted
          _lastChangedAt
          entryEntrySectionsId
          __typename
        }
        goalID
        goal {
          id
          owner
          name
          description
          createdAt
          updatedAt
          createdBy
          updatedBy
          targetNumber
          targetFrequency
          targetType
          recurringType
          recurringUntil
          recurringNumber
          color
          sectionID
          entryID
          componentID
          clientID
          order
          complete
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        infoContent {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        entrySectionComponentsId
        __typename
      }
      componentID
      clientID
      order
      goalEntries {
        items {
          id
          owner
          date
          goalID
          trackingNumber
          celebrated
          complete
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          goalGoalEntriesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      complete
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateGoal = /* GraphQL */ `
  mutation UpdateGoal(
    $input: UpdateGoalInput!
    $condition: ModelGoalConditionInput
  ) {
    updateGoal(input: $input, condition: $condition) {
      id
      owner
      name
      description
      createdAt
      updatedAt
      createdBy
      updatedBy
      targetNumber
      targetFrequency
      targetType
      recurringType
      recurringUntil
      recurringNumber
      color
      section {
        id
        createdAt
        updatedAt
        complete
        name
        inactive
        noEdit
        analytics
        order
        owner
        settings
        clientID
        createdBy
        entries {
          nextToken
          startedAt
          __typename
        }
        goalID
        goal {
          id
          owner
          name
          description
          createdAt
          updatedAt
          createdBy
          updatedBy
          targetNumber
          targetFrequency
          targetType
          recurringType
          recurringUntil
          recurringNumber
          color
          sectionID
          entryID
          componentID
          clientID
          order
          complete
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      sectionID
      entry {
        id
        createdAt
        updatedAt
        owner
        name
        icon
        type
        order
        addLogic
        logic
        inactive
        noEdit
        settings
        complete
        analytics
        draftRecordID
        clientID
        createdBy
        sectionID
        section {
          id
          createdAt
          updatedAt
          complete
          name
          inactive
          noEdit
          analytics
          order
          owner
          settings
          clientID
          createdBy
          goalID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        entrySections {
          nextToken
          startedAt
          __typename
        }
        count
        goalID
        goal {
          id
          owner
          name
          description
          createdAt
          updatedAt
          createdBy
          updatedBy
          targetNumber
          targetFrequency
          targetType
          recurringType
          recurringUntil
          recurringNumber
          color
          sectionID
          entryID
          componentID
          clientID
          order
          complete
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        sectionEntriesId
        __typename
      }
      entryID
      component {
        id
        createdAt
        updatedAt
        clientID
        complete
        owner
        type
        order
        settings
        to
        from
        rows
        name
        noEdit
        size
        label
        placeholder
        upload
        options
        content
        boolean
        variant
        prompts
        collapse
        showInfo
        showPrompts
        multiline
        addButton
        multiselect
        information
        instructions
        removeButton
        booleanLabel
        annotationLabels
        userInputs {
          nextToken
          startedAt
          __typename
        }
        entrySectionID
        entrySection {
          id
          createdAt
          updatedAt
          name
          complete
          owner
          order
          noEdit
          settings
          showInfo
          information
          informationTitle
          orientation
          clientID
          entryID
          createdBy
          _version
          _deleted
          _lastChangedAt
          entryEntrySectionsId
          __typename
        }
        goalID
        goal {
          id
          owner
          name
          description
          createdAt
          updatedAt
          createdBy
          updatedBy
          targetNumber
          targetFrequency
          targetType
          recurringType
          recurringUntil
          recurringNumber
          color
          sectionID
          entryID
          componentID
          clientID
          order
          complete
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        infoContent {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        entrySectionComponentsId
        __typename
      }
      componentID
      clientID
      order
      goalEntries {
        items {
          id
          owner
          date
          goalID
          trackingNumber
          celebrated
          complete
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          goalGoalEntriesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      complete
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteGoal = /* GraphQL */ `
  mutation DeleteGoal(
    $input: DeleteGoalInput!
    $condition: ModelGoalConditionInput
  ) {
    deleteGoal(input: $input, condition: $condition) {
      id
      owner
      name
      description
      createdAt
      updatedAt
      createdBy
      updatedBy
      targetNumber
      targetFrequency
      targetType
      recurringType
      recurringUntil
      recurringNumber
      color
      section {
        id
        createdAt
        updatedAt
        complete
        name
        inactive
        noEdit
        analytics
        order
        owner
        settings
        clientID
        createdBy
        entries {
          nextToken
          startedAt
          __typename
        }
        goalID
        goal {
          id
          owner
          name
          description
          createdAt
          updatedAt
          createdBy
          updatedBy
          targetNumber
          targetFrequency
          targetType
          recurringType
          recurringUntil
          recurringNumber
          color
          sectionID
          entryID
          componentID
          clientID
          order
          complete
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      sectionID
      entry {
        id
        createdAt
        updatedAt
        owner
        name
        icon
        type
        order
        addLogic
        logic
        inactive
        noEdit
        settings
        complete
        analytics
        draftRecordID
        clientID
        createdBy
        sectionID
        section {
          id
          createdAt
          updatedAt
          complete
          name
          inactive
          noEdit
          analytics
          order
          owner
          settings
          clientID
          createdBy
          goalID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        entrySections {
          nextToken
          startedAt
          __typename
        }
        count
        goalID
        goal {
          id
          owner
          name
          description
          createdAt
          updatedAt
          createdBy
          updatedBy
          targetNumber
          targetFrequency
          targetType
          recurringType
          recurringUntil
          recurringNumber
          color
          sectionID
          entryID
          componentID
          clientID
          order
          complete
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        sectionEntriesId
        __typename
      }
      entryID
      component {
        id
        createdAt
        updatedAt
        clientID
        complete
        owner
        type
        order
        settings
        to
        from
        rows
        name
        noEdit
        size
        label
        placeholder
        upload
        options
        content
        boolean
        variant
        prompts
        collapse
        showInfo
        showPrompts
        multiline
        addButton
        multiselect
        information
        instructions
        removeButton
        booleanLabel
        annotationLabels
        userInputs {
          nextToken
          startedAt
          __typename
        }
        entrySectionID
        entrySection {
          id
          createdAt
          updatedAt
          name
          complete
          owner
          order
          noEdit
          settings
          showInfo
          information
          informationTitle
          orientation
          clientID
          entryID
          createdBy
          _version
          _deleted
          _lastChangedAt
          entryEntrySectionsId
          __typename
        }
        goalID
        goal {
          id
          owner
          name
          description
          createdAt
          updatedAt
          createdBy
          updatedBy
          targetNumber
          targetFrequency
          targetType
          recurringType
          recurringUntil
          recurringNumber
          color
          sectionID
          entryID
          componentID
          clientID
          order
          complete
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        infoContent {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        entrySectionComponentsId
        __typename
      }
      componentID
      clientID
      order
      goalEntries {
        items {
          id
          owner
          date
          goalID
          trackingNumber
          celebrated
          complete
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          goalGoalEntriesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      complete
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createGoalEntry = /* GraphQL */ `
  mutation CreateGoalEntry(
    $input: CreateGoalEntryInput!
    $condition: ModelGoalEntryConditionInput
  ) {
    createGoalEntry(input: $input, condition: $condition) {
      id
      owner
      date
      goalID
      goal {
        id
        owner
        name
        description
        createdAt
        updatedAt
        createdBy
        updatedBy
        targetNumber
        targetFrequency
        targetType
        recurringType
        recurringUntil
        recurringNumber
        color
        section {
          id
          createdAt
          updatedAt
          complete
          name
          inactive
          noEdit
          analytics
          order
          owner
          settings
          clientID
          createdBy
          goalID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        sectionID
        entry {
          id
          createdAt
          updatedAt
          owner
          name
          icon
          type
          order
          addLogic
          logic
          inactive
          noEdit
          settings
          complete
          analytics
          draftRecordID
          clientID
          createdBy
          sectionID
          count
          goalID
          _version
          _deleted
          _lastChangedAt
          sectionEntriesId
          __typename
        }
        entryID
        component {
          id
          createdAt
          updatedAt
          clientID
          complete
          owner
          type
          order
          settings
          to
          from
          rows
          name
          noEdit
          size
          label
          placeholder
          upload
          options
          content
          boolean
          variant
          prompts
          collapse
          showInfo
          showPrompts
          multiline
          addButton
          multiselect
          information
          instructions
          removeButton
          booleanLabel
          annotationLabels
          entrySectionID
          goalID
          _version
          _deleted
          _lastChangedAt
          entrySectionComponentsId
          __typename
        }
        componentID
        clientID
        order
        goalEntries {
          nextToken
          startedAt
          __typename
        }
        complete
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      trackingNumber
      celebrated
      complete
      clientID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      goalGoalEntriesId
      __typename
    }
  }
`;
export const updateGoalEntry = /* GraphQL */ `
  mutation UpdateGoalEntry(
    $input: UpdateGoalEntryInput!
    $condition: ModelGoalEntryConditionInput
  ) {
    updateGoalEntry(input: $input, condition: $condition) {
      id
      owner
      date
      goalID
      goal {
        id
        owner
        name
        description
        createdAt
        updatedAt
        createdBy
        updatedBy
        targetNumber
        targetFrequency
        targetType
        recurringType
        recurringUntil
        recurringNumber
        color
        section {
          id
          createdAt
          updatedAt
          complete
          name
          inactive
          noEdit
          analytics
          order
          owner
          settings
          clientID
          createdBy
          goalID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        sectionID
        entry {
          id
          createdAt
          updatedAt
          owner
          name
          icon
          type
          order
          addLogic
          logic
          inactive
          noEdit
          settings
          complete
          analytics
          draftRecordID
          clientID
          createdBy
          sectionID
          count
          goalID
          _version
          _deleted
          _lastChangedAt
          sectionEntriesId
          __typename
        }
        entryID
        component {
          id
          createdAt
          updatedAt
          clientID
          complete
          owner
          type
          order
          settings
          to
          from
          rows
          name
          noEdit
          size
          label
          placeholder
          upload
          options
          content
          boolean
          variant
          prompts
          collapse
          showInfo
          showPrompts
          multiline
          addButton
          multiselect
          information
          instructions
          removeButton
          booleanLabel
          annotationLabels
          entrySectionID
          goalID
          _version
          _deleted
          _lastChangedAt
          entrySectionComponentsId
          __typename
        }
        componentID
        clientID
        order
        goalEntries {
          nextToken
          startedAt
          __typename
        }
        complete
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      trackingNumber
      celebrated
      complete
      clientID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      goalGoalEntriesId
      __typename
    }
  }
`;
export const deleteGoalEntry = /* GraphQL */ `
  mutation DeleteGoalEntry(
    $input: DeleteGoalEntryInput!
    $condition: ModelGoalEntryConditionInput
  ) {
    deleteGoalEntry(input: $input, condition: $condition) {
      id
      owner
      date
      goalID
      goal {
        id
        owner
        name
        description
        createdAt
        updatedAt
        createdBy
        updatedBy
        targetNumber
        targetFrequency
        targetType
        recurringType
        recurringUntil
        recurringNumber
        color
        section {
          id
          createdAt
          updatedAt
          complete
          name
          inactive
          noEdit
          analytics
          order
          owner
          settings
          clientID
          createdBy
          goalID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        sectionID
        entry {
          id
          createdAt
          updatedAt
          owner
          name
          icon
          type
          order
          addLogic
          logic
          inactive
          noEdit
          settings
          complete
          analytics
          draftRecordID
          clientID
          createdBy
          sectionID
          count
          goalID
          _version
          _deleted
          _lastChangedAt
          sectionEntriesId
          __typename
        }
        entryID
        component {
          id
          createdAt
          updatedAt
          clientID
          complete
          owner
          type
          order
          settings
          to
          from
          rows
          name
          noEdit
          size
          label
          placeholder
          upload
          options
          content
          boolean
          variant
          prompts
          collapse
          showInfo
          showPrompts
          multiline
          addButton
          multiselect
          information
          instructions
          removeButton
          booleanLabel
          annotationLabels
          entrySectionID
          goalID
          _version
          _deleted
          _lastChangedAt
          entrySectionComponentsId
          __typename
        }
        componentID
        clientID
        order
        goalEntries {
          nextToken
          startedAt
          __typename
        }
        complete
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      trackingNumber
      celebrated
      complete
      clientID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      goalGoalEntriesId
      __typename
    }
  }
`;

import { useState } from 'react';

import { Add, Close } from '@mui/icons-material';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Chip,
  Container,
  Divider,
  Grid,
  Hidden,
  Link,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { constants } from 'common/constants';
import { useSearchParams } from 'react-router-dom';

import { Clinician } from 'components/layout';
import { ProgramCard, ProgramDetail, SearchBar } from 'components/molecules';

export default function Templates() {
  const [searchParams] = useSearchParams();
  const programParam = searchParams.get('program');
  const [search, setSearch] = useState('');
  const [selectedChip, setSelectedChip] = useState('all');
  const [selectedTemplate, setSelectedTemplate] = useState(
    programParam || 'lidcombe'
  );

  const templates = [
    {
      title: 'Lidcombe',
      suitableFor: 'Children pre-school age and above',
      program: 'lidcombe',
      category: 'stuttering'
    },
    {
      title: 'Westmead',
      suitableFor: 'Young children who stutter',
      program: 'westmead',
      category: 'stuttering'
    },
    {
      title: 'Oakville',
      suitableFor: 'Children with treatment-resistant stuttering',
      program: 'oakville',
      category: 'stuttering'
    },
    {
      title: 'Camperdown - Adult',
      suitableFor: 'Adults 18 years and older',
      program: 'camperdownAdult',
      category: 'stuttering'
    },
    {
      title: 'Camperdown - Adolescent',
      suitableFor: 'Adolescents between 12 and 18 years old',
      program: 'camperdownAdolescent',
      category: 'stuttering'
    }
  ];

  const filteredTemplates = templates.filter(template => {
    const matchesSearch =
      template.title.toLowerCase().includes(search.toLowerCase()) ||
      template.suitableFor.toLowerCase().includes(search.toLowerCase());

    const matchesCategory =
      selectedChip === 'all' || template.category === selectedChip;

    return matchesSearch && matchesCategory;
  });

  const selectTemplate = template => {
    setSelectedTemplate(template);
  };
  return (
    <Clinician
      props={{ title: 'Templates' }}
      AppBarComponents={
        <Box
          pl={4}
          width="100%"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" spacing={2}>
            <Typography color="text.primary" variant="h5">
              Templates
            </Typography>
            <Stack direction="row" spacing={1}>
              <Chip
                variant={selectedChip === 'all' ? 'filled' : 'outlined'}
                label="All"
                onClick={() => setSelectedChip('all')}
                color="primary"
              />
              <Chip
                variant={selectedChip === 'stuttering' ? 'filled' : 'outlined'}
                icon={selectedChip === 'stuttering' ? <Close /> : <Add />}
                label="Stuttering"
                onClick={() =>
                  setSelectedChip(prev =>
                    prev === 'stuttering' ? '' : 'stuttering'
                  )
                }
                color="primary"
              />
            </Stack>
          </Stack>
          <Stack direction="row" spacing={2} alignItems={'center'}>
            <Tooltip title="Please contact us to enable custom templates.">
              <span>
                <Button disabled size="small" startIcon={<Add />}>
                  Add Template
                </Button>
              </span>
            </Tooltip>
            <SearchBar state={search} setState={setSearch} />
          </Stack>
        </Box>
      }
    >
      <Box>
        {filteredTemplates.length > 0 ? (
          <Grid container spacing={2}>
            <Hidden smDown implementation="js">
              <Grid
                sx={{
                  position: 'sticky',
                  top: '100px',
                  height: 'calc(100vh - 100px)',
                  overflowY: 'auto'
                }}
                item
                sm={4}
              >
                {filteredTemplates.map(template => (
                  <ProgramCard
                    selectedTemplate={selectedTemplate}
                    selectTemplate={selectTemplate}
                    key={template.program}
                    title={template.title}
                    suitableFor={template.suitableFor}
                    program={template.program}
                  />
                ))}
              </Grid>
            </Hidden>
            <Grid item sm={8} xs={12}>
              <Hidden smDown implementation="js">
                <ProgramDetail selectedTemplate={selectedTemplate} />
              </Hidden>
              <Hidden smUp implementation="js">
                {templates.map(template => (
                  <Box key={template.program}>
                    <ProgramDetail selectedTemplate={template.program} />
                    <Divider sx={{ opacity: '0.3' }} />
                  </Box>
                ))}
              </Hidden>
            </Grid>
          </Grid>
        ) : (
          <Box minHeight="80vh" display="flex" alignItems="center">
            <Container maxWidth="md">
              <Alert severity="info">
                <AlertTitle>No Templates Available</AlertTitle>
                {selectedChip === 'stuttering'
                  ? 'No templates match your search criteria for Stuttering. '
                  : selectedChip === 'apraxia'
                  ? 'No templates match your search criteria for Apraxia. '
                  : 'Please select a category to view templates.'}{' '}
                Is there a template you would love to see here?{' '}
                <Link href={constants.contactUrl}>Speak to us</Link> or list the
                feature on our{' '}
                <Link href={constants.cannyUrl}>product roadmap</Link>.
              </Alert>
            </Container>
          </Box>
        )}
      </Box>
    </Clinician>
  );
}

import { useEffect, useState } from 'react';

import {
  Alert,
  AlertTitle,
  Avatar,
  Box,
  Button,
  Link,
  Step,
  StepButton,
  StepContent,
  StepLabel,
  Stepper,
  Typography
} from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import { useAppContext } from 'context';
import { useSnackbar } from 'context/SnackBar';
import { updateClient } from 'graphql/mutations';
import { getClient, stageChange } from 'graphql/queries';
import mixpanel from 'mixpanel-browser';
import Confetti from 'react-confetti';
import { useNavigate } from 'react-router-dom';
import useWindowSize from 'react-use/lib/useWindowSize';

import { toRomanNumeral } from 'utils/toRomanNumerals';

import { camperdownSteps } from './camperdownSteps';
import ChangeProgram from './change';
import { lidcombeSteps } from './lidcombeSteps';
import { oakvilleSteps } from './oakvilleSteps';
import { westmeadSteps } from './westmeadSteps';

export default function Info() {
  const [activeStep, setActiveStep] = useState(0);
  const { dispatch, state } = useAppContext();
  const { enqueueSnackbar } = useSnackbar();
  const [showConfetti, setShowConfetti] = useState(false);
  const navigate = useNavigate();

  const chooseStage = async newStage => {
    try {
      const { data } = await API.graphql(
        graphqlOperation(getClient, { id: state?.client?.id })
      );
      const client = data.getClient;
      const res = await API.graphql(
        graphqlOperation(updateClient, {
          input: {
            id: client.id,
            stage: newStage,
            _version: client._version
          }
        })
      );
      API.graphql(
        graphqlOperation(stageChange, {
          clientID: state?.client?.id,
          newStage: newStage,
          program: state?.client?.program
        })
      );
      const updatedClient = res.data.updateClient;
      if (updatedClient.id) {
        dispatch({ type: 'add_client', client: updatedClient });
      }
      mixpanel.track('change_program_stage');
      enqueueSnackbar(
        `Updated ${
          client.clientLastName || client.firstName
        } to Stage ${toRomanNumeral(newStage)}`,
        {
          severity: 'success'
        }
      );
    } catch (err) {
      console.log(err);
      enqueueSnackbar(
        `Error updating ${state?.client?.firstName}'s Program Stage`,
        {
          severity: 'error'
        }
      );
    }
  };

  const handleStep = step => () => {
    if (step > activeStep) {
      setShowConfetti(true);
      setTimeout(() => setShowConfetti(false), 5000);
    }
    chooseStage(step + 1);
    setActiveStep(step);
  };

  const romanNumerals = ['I', 'II', 'III', 'IV'];

  useEffect(() => {
    setActiveStep(state?.client?.stage - 1);
  }, [state?.client?.stage]);

  function RomanNumeral({ index }) {
    const color =
      index < activeStep
        ? 'primary.main'
        : index === activeStep
        ? 'secondary.main'
        : 'action.disabled';

    return (
      <Avatar sx={{ width: 24, height: 24, bgcolor: color, color: 'white' }}>
        <Typography variant="caption">{romanNumerals[index]}</Typography>
      </Avatar>
    );
  }

  let steps, name, changeNote;

  switch (state?.client?.program) {
    case 'camperdownAdolescent':
      name = 'Camperdown Program (Adolescent)';
      steps = camperdownSteps;
      changeNote = (
        <>
          Changing the client's stage may make some sections active or inactive.
          See more in{' '}
          <Link
            sx={{ cursor: 'pointer' }}
            onClick={() => navigate('/templates?program=camperdownAdolescent')}
          >
            Stage
          </Link>
          .
        </>
      );
      break;
    case 'camperdownAdult':
      name = 'Camperdown Program (Adult)';
      steps = camperdownSteps;
      changeNote = (
        <>
          Changing the client's stage may make some sections active or inactive.
          See more in{' '}
          <Link
            sx={{ cursor: 'pointer' }}
            onClick={() => navigate('/templates?program=camperdownAdult')}
          >
            Templates
          </Link>
          .
        </>
      );
      break;
    case 'westmead':
      name = 'Westmead Program';
      steps = westmeadSteps;
      break;
    case 'oakville':
      name = 'Oakville Program';
      steps = oakvilleSteps;
      changeNote = (
        <>
          Hybrid Practice Sessions and Natural Conversations are active in Late
          Stage I and inactive in Early Stage I. See more in{' '}
          <Link
            sx={{ cursor: 'pointer' }}
            onClick={() => navigate('/templates?program=oakville')}
          >
            Templates
          </Link>
        </>
      );
      break;
    case 'lidcombe':
      name = 'Lidcombe Program';
      steps = lidcombeSteps;
      break;
    default:
      steps = lidcombeSteps;
      break;
  }

  const { width, height } = useWindowSize();

  return (
    <Box my={4}>
      {showConfetti && (
        <Confetti
          run={showConfetti}
          recycle={false}
          numberOfPieces={200}
          width={width}
          height={height}
        />
      )}
      <ChangeProgram />
      {!state?.client?.program?.toLowerCase().includes('monitoring') ? (
        <Box mb={6}>
          <Typography variant="h4">{name} Progression Criteria</Typography>
          <Alert severity="info" sx={{ mt: 2 }}>
            <AlertTitle>Click on a stage to progress your client.</AlertTitle>
            {changeNote}
          </Alert>
          <Stepper
            sx={{ mt: 2 }}
            nonLinear
            activeStep={activeStep}
            orientation="vertical"
          >
            {steps?.map((step, index) => (
              <Step expanded key={step.label}>
                <StepButton color="inherit" onClick={handleStep(index)}>
                  <StepLabel
                    optional={
                      index === 2 ? (
                        <Typography variant="caption">Last step</Typography>
                      ) : null
                    }
                    StepIconComponent={() => <RomanNumeral index={index} />}
                  >
                    <Typography variant="h6"> {step.label}</Typography>
                  </StepLabel>
                </StepButton>
                <StepContent>
                  <Typography component="div">{step.description}</Typography>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </Box>
      ) : (
        <Box
          sx={{
            height: '50vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
          }}
        >
          <Typography variant="h6">Monitoring Program.</Typography>
          <Box sx={{ height: 20 }} />
          <Button
            size="small"
            onClick={() =>
              dispatch({ type: 'set_change_program', changeProgram: true })
            }
          >
            Change Program
          </Button>
        </Box>
      )}
    </Box>
  );
}

import { Alert, AlertTitle, Box, Button, Grid } from '@mui/material';

export default function NoClientError() {
  return (
    <Box mt={6} sx={{ display: 'flex', justifyContent: 'center' }}>
      <Grid container justifyContent="center" item md={4}>
        <Grid item xs={12}>
          <Alert variant="outlined" severity="warning">
            <AlertTitle>No Client Selected</AlertTitle>
            Please go to the home page to select a client.
          </Alert>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ mt: 6, mb: 6, display: 'flex', justifyContent: 'center' }}
        >
          <Button variant="contained" href="/">
            Take Me Home
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Alert icon={false} severity="info">
            Do you believe you are seeing this in error? Contact support.
          </Alert>
        </Grid>
      </Grid>
    </Box>
  );
}

import {
  Add,
  CheckBox,
  Close,
  EditAttributes,
  GraphicEq,
  Input as InputIcon,
  Iso,
  LinearScale,
  Looks3,
  Newspaper,
  RadioButtonUnchecked,
  Remove,
  SentimentDissatisfied,
  SentimentNeutral,
  SentimentSatisfied,
  SentimentVeryDissatisfied,
  SentimentVerySatisfied
} from '@mui/icons-material';
import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  Checkbox,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  Radio,
  Skeleton,
  Slider,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { grey } from '@mui/material/colors';

export const EntryComponentSelectionDialog = ({ onClose, open, onSelect }) => (
  <Dialog
    fullWidth
    maxWidth="md"
    open={open}
    onClose={onClose}
    PaperProps={{
      sx: {
        minHeight: '400px'
      },
      elevation: 1
    }}
    scroll="paper"
  >
    <DialogTitle>Add component</DialogTitle>
    <IconButton
      aria-label="close"
      onClick={onClose}
      sx={{
        position: 'absolute',
        right: 8,
        top: 8,
        color: grey[500]
      }}
    >
      <Close />
    </IconButton>
    <Divider sx={{ borderColor: 'rgba(0, 66, 130, 0.3)' }} />
    <DialogContent>
      <Stack direction="column">
        <Card elevation={0} sx={{ m: 1 }}>
          <CardActionArea onClick={() => onSelect?.('input', 'Text Input')}>
            <TextInput fullWidth />
          </CardActionArea>
        </Card>
        <Card elevation={0} sx={{ m: 1 }}>
          <CardActionArea onClick={() => onSelect?.('content', 'Rich Text')}>
            <RichTextDisplay />
          </CardActionArea>
        </Card>
        <Card elevation={0} sx={{ m: 1 }}>
          <CardActionArea onClick={() => onSelect?.('rater', 'Rater')}>
            <NumberAvatar />
          </CardActionArea>
        </Card>
        <Card elevation={0} sx={{ m: 1 }}>
          <CardActionArea onClick={() => onSelect?.('emoji-rater', 'Emoji')}>
            <Emojis />
          </CardActionArea>
        </Card>
        <Card elevation={0} sx={{ m: 1 }}>
          <CardActionArea onClick={() => onSelect?.('checkbox', 'Checkbox')}>
            <NumberCheckbox />
          </CardActionArea>
        </Card>
        <Card elevation={0} sx={{ m: 1 }}>
          <CardActionArea onClick={() => onSelect?.('radio', 'Radio')}>
            <NumberRadio />
          </CardActionArea>
        </Card>
        <Card elevation={0} sx={{ m: 1 }}>
          <CardActionArea onClick={() => onSelect?.('audio', 'Audio')}>
            <Audio />
          </CardActionArea>
        </Card>
        <Card elevation={0} sx={{ m: 1 }}>
          <CardActionArea onClick={() => onSelect?.('chip', 'Chip')}>
            <ChipSelector />
          </CardActionArea>
        </Card>
        <Card elevation={0} sx={{ m: 1 }}>
          <CardActionArea onClick={() => onSelect?.('slider', 'Slider')}>
            <SlideSelector />
          </CardActionArea>
        </Card>
        <Card elevation={0} sx={{ m: 1 }}>
          <CardActionArea
            onClick={() => onSelect?.('incrementer', 'Incrementer')}
          >
            <Incrementer />
          </CardActionArea>
        </Card>
      </Stack>
    </DialogContent>
  </Dialog>
);
const Audio = () => (
  <CardWithPadding>
    <Stack spacing={2} direction="row" alignItems="center">
      <GraphicEq fontSize="large" sx={{ color: 'primary.main' }} />
      <Typography variant="h5">Annotated Audio</Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
        <Avatar>&nbsp;</Avatar>
        <Skeleton height={4} sx={{ flexGrow: 1 }} />
      </Box>
    </Stack>
  </CardWithPadding>
);

const TextInput = () => (
  <CardWithPadding>
    <Stack spacing={2} direction="row" alignItems="center">
      <InputIcon fontSize="large" sx={{ color: 'primary.main' }} />
      <Typography variant="h5" whiteSpace="nowrap">
        Text Input
      </Typography>
      <Box
        sx={{
          border: '1px solid rgba(0, 0, 0, 0.1)',
          borderRadius: 1,
          flexGrow: 1
        }}
        height={42}
        display="flex"
        alignItems={'center'}
      >
        <Typography color="grey" sx={{ pl: 2 }} variant="body1">
          Text Input
        </Typography>
      </Box>
    </Stack>
  </CardWithPadding>
);

const RichTextDisplay = () => (
  <CardWithPadding>
    <Stack spacing={2} direction="row" alignItems="center">
      <Newspaper fontSize="large" sx={{ color: 'primary.main' }} />
      <Typography variant="h5">Rich Text, Images and Video</Typography>
    </Stack>
  </CardWithPadding>
);

const NumberAvatar = () => (
  <CardWithPadding>
    <Stack spacing={2} direction="row" alignItems="center">
      <Looks3 fontSize="large" sx={{ color: 'primary.main' }} />
      <Typography variant="h5">Numerical Rater</Typography>
      <Stack sx={{ my: 1 }} direction="row" spacing={2}>
        {[0, 1, 2, 3, 4, 5].map(num => (
          <AvatarStyled key={num}>{num}</AvatarStyled>
        ))}
        <AvatarStyled>+</AvatarStyled>
      </Stack>
    </Stack>
  </CardWithPadding>
);

const NumberCheckbox = () => (
  <CardWithPadding>
    <Stack spacing={2} direction="row" alignItems="center">
      <CheckBox fontSize="large" sx={{ color: 'primary.main' }} />
      <Typography variant="h5">Checkbox Options</Typography>
      <Stack sx={{ alignItems: 'center' }} direction="row" spacing={0}>
        {[0, 1].map(num => (
          <FormControlLabel
            key={num}
            control={<Checkbox disabled />}
            label={`Checkbox`}
          />
        ))}
        <IconButton sx={{ ml: 1 }}>
          <AvatarStyled>+</AvatarStyled>
        </IconButton>
      </Stack>
    </Stack>
  </CardWithPadding>
);

const ChipSelector = () => (
  <CardWithPadding>
    <Stack spacing={2} direction="row" alignItems="center">
      <EditAttributes fontSize="large" sx={{ color: 'primary.main' }} />
      <Typography variant="h5">Chips</Typography>
      <Stack sx={{ alignItems: 'center' }} direction="row" spacing={1}>
        {[0, 1].map(num => (
          <Chip key={`ref-${num}`} label="Chip" />
        ))}
        <IconButton sx={{ ml: 1 }}>
          <AvatarStyled>+</AvatarStyled>
        </IconButton>
      </Stack>
    </Stack>
  </CardWithPadding>
);

const SlideSelector = () => (
  <CardWithPadding>
    <Stack spacing={2} direction="row" alignItems="center">
      <LinearScale fontSize="large" sx={{ color: 'primary.main' }} />
      <Typography variant="h5">Slider</Typography>
      <Stack
        sx={{ alignItems: 'center', flexGrow: 1 }}
        direction="row"
        spacing={1}
      >
        <Slider
          disabled
          sx={{ width: '100%' }}
          aria-label="Temperature"
          defaultValue={50}
          valueLabelDisplay="auto"
          step={10}
          marks
          min={10}
          max={110}
        />
      </Stack>
    </Stack>
  </CardWithPadding>
);

const Incrementer = () => (
  <CardWithPadding>
    <Stack spacing={2} direction="row" alignItems="center">
      <Iso fontSize="large" sx={{ color: 'primary.main' }} />
      <Typography variant="h5">Incrementer</Typography>
      <Stack
        sx={{ alignItems: 'center', flexGrow: 1 }}
        direction="row"
        spacing={1}
      >
        <IconButton disabled>
          <Remove />
        </IconButton>
        <TextField
          sx={{
            maxWidth: '50px',
            '& .MuiInputBase-input': {
              textAlign: 'center',
              padding: 0,
              fontSize: 'x-large'
            }
          }}
          disabled
          variant="outlined"
          value={3}
        />
        <IconButton disabled>
          <Add />
        </IconButton>
      </Stack>
    </Stack>
  </CardWithPadding>
);

const NumberRadio = () => (
  <CardWithPadding>
    <Stack spacing={2} direction="row" alignItems="center">
      <RadioButtonUnchecked fontSize="large" sx={{ color: 'primary.main' }} />
      <Typography variant="h5">Radio Options</Typography>
      <Stack sx={{ alignItems: 'center' }} direction="row" spacing={0}>
        {[0, 1].map(num => (
          <FormControlLabel
            key={num}
            control={<Radio disabled />}
            label={`Radio`}
          />
        ))}
        <IconButton sx={{ ml: 1 }}>
          <AvatarStyled>+</AvatarStyled>
        </IconButton>
      </Stack>
    </Stack>
  </CardWithPadding>
);
const Emojis = () => {
  const emojiIcons = [
    <SentimentVerySatisfied />,
    <SentimentSatisfied />,
    <SentimentNeutral />,
    <SentimentDissatisfied />,
    <SentimentVeryDissatisfied />,
    <AvatarStyled>-</AvatarStyled>
  ];

  return (
    <CardWithPadding>
      <Stack spacing={2} direction="row" alignItems="center">
        <SentimentVerySatisfied
          fontSize="large"
          sx={{ color: 'primary.main' }}
        />
        <Typography variant="h5">Emoji Rater</Typography>
        <Stack sx={{ alignItems: 'center' }} direction="row" spacing={0}>
          {emojiIcons.map((Icon, index) => (
            <IconButton
              key={index}
              size={index === emojiIcons.length - 1 ? undefined : 'small'}
              disabled
            >
              {Icon}
            </IconButton>
          ))}
        </Stack>
      </Stack>
    </CardWithPadding>
  );
};

const CardWithPadding = ({ children }) => (
  <Card sx={{ p: 2, backgroundColor: 'background.card' }}>{children}</Card>
);

const AvatarStyled = ({ children }) => (
  <Avatar
    style={{
      height: 20,
      width: 20,
      fontSize: 12,
      fontWeight: 600,
      display: 'flex',
      alignItems: 'center'
    }}
  >
    {children}
  </Avatar>
);

import React, { useEffect, useState } from 'react';

import { Add, Info } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import { locationsByClinicID } from 'graphql/queries';

import { LocationCard } from 'components/atoms';

import AddLocation from '../AddLocation';

export default function StepTwo({
  locations,
  setLocations,
  name,
  errors,
  selectedClinic,
  role,
  selectedClinicLocation,
  setSelectedClinicLocation
}) {
  const [showHelp, setShowHelp] = useState(false);
  const [addNewLocation, setAddNewLocation] = useState(false);

  const addLocation = () => {
    setAddNewLocation(prevAddNewLocation => !prevAddNewLocation);
  };

  useEffect(() => {
    const searchLocations = async () => {
      const { data } = await API.graphql(
        graphqlOperation(locationsByClinicID, { clinicID: selectedClinic.id })
      );
      const locations = data.locationsByClinicID.items;
      setLocations(locations);
    };
    if (selectedClinic?.id) {
      searchLocations();
    }
    return () => {
      setLocations([]);
    };
  }, [selectedClinic, setLocations]);

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Box sx={{ mt: 1, mb: 4 }}>
        <Stack sx={{ mb: 1 }} alignItems="center" direction="row" spacing={2}>
          <Typography variant="h6">
            {role === 'clinician' || role === 'practicemanager'
              ? 'Where do you work?'
              : 'Set Up Locations'}{' '}
          </Typography>
          <Tooltip title="Why do you need this?">
            <IconButton
              onClick={() => setShowHelp(prevShowHelp => !prevShowHelp)}
            >
              <Info
                sx={{ display: 'flex', alignItems: 'center' }}
                color="grey"
              />
            </IconButton>
          </Tooltip>
          <Button startIcon={<Add />} onClick={addLocation}>
            Add Location
          </Button>
        </Stack>
        <Collapse in={showHelp}>
          <Card
            sx={{ backgroundColor: 'background.settings', p: 1, mb: 1 }}
            elevation={0}
          >
            <Box>
              <Typography variant="body2">
                Locations are used to group clients along with the clinicians
                who work with them. You must enter at least one location.
              </Typography>
            </Box>
          </Card>
        </Collapse>
        <Divider sx={{ opacity: 0.3 }} />
      </Box>
      {locations?.length < 1 && errors.locations && (
        <Typography color="error" variant="body2">
          {errors.locations}
        </Typography>
      )}
      <Grid container spacing={2}>
        {locations.map(location => {
          let selected;
          if (selectedClinicLocation.id && location.id) {
            if (selectedClinicLocation.id === location.id) {
              selected = true;
            } else {
              selected = false;
            }
          }
          return (
            <Grid item xs={12} sm={6} key={location.id}>
              <LocationCard
                selectLocation={() => {
                  selectedClinicLocation.id === location.id
                    ? setSelectedClinicLocation({})
                    : setSelectedClinicLocation(location);
                }}
                action="select"
                dark
                location={location}
                selected={selected}
              />
            </Grid>
          );
        })}

        {addNewLocation && (
          <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
            <AddLocation
              clinicName={name}
              setSelectedClinicLocation={setSelectedClinicLocation}
              setAddNewLocation={setAddNewLocation}
              locations={locations}
              setLocations={setLocations}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

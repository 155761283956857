import React, { useCallback, useEffect, useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import {
  DndContext,
  MouseSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import {
  restrictToVerticalAxis,
  restrictToWindowEdges
} from '@dnd-kit/modifiers';
import {
  SortableContext,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  ButtonGroup,
  CircularProgress,
  Container,
  Grid,
  Stack
} from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import { normalizeFactor } from 'common/constants';
import { useAppContext } from 'context';
import { useEntryContext } from 'context/Entry';
import { updateEntry, updateRecord } from 'graphql/mutations';
import { userInputsByRecordComponentType } from 'graphql/queries';
import useEffectOnce from 'hooks/useEffectOnce';
import mixpanel from 'mixpanel-browser';

import { useUpdateGoal } from 'components/goals/functions';
import { EntrySettings } from 'components/molecules';
import { SortableEntrySection } from 'components/organisms';

import { AddEntrySectionButton } from './addEntrySectionButton';
import {
  // Import the mixpanel module
  deleteRecordAndUserInputs,
  useCreateEntrySection,
  useCreateRecord,
  useDeleteEntrySection,
  useDragEntrySection,
  useFetchEntrySections,
  useFetchRecord
} from './functions';

export default React.memo(function Entry({ entryID, switchingEntry }) {
  // state
  const sensors = useSensors(useSensor(MouseSensor), useSensor(PointerSensor));
  const { state, dispatch } = useAppContext();
  const { user } = useAuthenticator(context => [context.user]);
  const clientID =
    state.client?.id || user.attributes['custom:clientID'] || user.username;
  const clientSub = state?.client?.sub || user.attributes.sub;
  const record = state?.record;
  const [loading, setLoading] = useState(true);
  const { selectedEntry, setSelectedEntry } = useEntryContext();
  const [entrySections, setEntrySections] = useState([]);
  const [isCanceling, setIsCanceling] = useState(false);
  const [isSaveAndExiting, setIsSaveAndExiting] = useState(false);
  const [noComponents, setNoComponents] = useState(true);
  const [addLogic, setAddLogic] = useState(false);
  const [logic, setLogic] = useState(3);
  const [changedData, setChangedData] = useState(false);

  const updateRecordAndGoalEntry = useUpdateGoal();

  const fetchEntrySections = useFetchEntrySections(
    entryID,
    setEntrySections,
    setLoading
  );
  const createEntrySection = useCreateEntrySection(
    clientID,
    clientSub,
    entryID,
    setEntrySections
  );
  const deleteEntrySection = useDeleteEntrySection(setEntrySections);
  const dragEntrySection = useDragEntrySection(entrySections, setEntrySections);
  const handleCreateRecord = useCreateRecord(
    entryID,
    state,
    clientID,
    clientSub,
    setSelectedEntry,
    selectedEntry,
    dispatch,
    setLoading,
    user
  );
  const handleFetchRecord = useFetchRecord(
    selectedEntry,
    entrySections,
    dispatch,
    handleCreateRecord,
    record
  );

  const handleCreateEntrySection = useCallback(
    entrySectionIdx => {
      const order =
        entrySectionIdx === entrySections.length
          ? (entrySectionIdx + 1) * normalizeFactor
          : ((entrySections[entrySectionIdx]?.order ?? entrySections.length) +
              (entrySections[entrySectionIdx - 1]?.order ?? 0)) /
            2;
      createEntrySection(entrySectionIdx, order);
    },
    [createEntrySection, entrySections]
  );
  const handleDeleteEntrySection = useCallback(
    entrySection => {
      deleteEntrySection(entrySection);
    },
    [deleteEntrySection]
  );

  const handleDeleteRecord = useCallback(
    async (recordID, cb) => {
      await deleteRecordAndUserInputs(recordID);
      const { data } = await API.graphql(
        graphqlOperation(updateEntry, {
          input: {
            id: selectedEntry.id,
            draftRecordID: '-',
            _version: selectedEntry._version
          }
        })
      );
      setSelectedEntry(data.updateEntry);
      cb?.();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedEntry]
  );

  const handleDragEntrySection = useCallback(
    ({ active, over }) => {
      dragEntrySection({ active, over });
    },
    [dragEntrySection]
  );

  useEffect(() => {
    if (selectedEntry) {
      setAddLogic(selectedEntry.addLogic || false);
      setLogic(selectedEntry.logic || 3);
    }
  }, [selectedEntry]);

  useEffect(() => {
    if ((!state.edit, !record?.id)) {
      handleFetchRecord();
    }
  }, [state.edit, record, handleFetchRecord]);

  useEffectOnce(() => {
    fetchEntrySections();
  }, [fetchEntrySections, record, selectedEntry]);

  useEffect(() => {
    if (
      (!selectedEntry ||
        selectedEntry.draftRecordID ||
        state.edit ||
        record?.id) &&
      selectedEntry.draftRecordID !== '-'
    )
      return;
    handleCreateRecord();
  }, [selectedEntry, handleCreateRecord, state.edit, record]); // Ensure all dependencies are listed here

  const handleCancel = async name => {
    const recordID = record.id;
    if (!recordID) return;
    try {
      setIsCanceling(true);
      const { data } = await API.graphql(
        graphqlOperation(userInputsByRecordComponentType, {
          recordID
        })
      );
      const userInput = data.userInputsByRecordComponentType.items.find(
        ui => ui.componentType === 'infinite'
      );

      await updateRecordAndGoalEntry({
        passedRecord: record,
        action: 'delete',
        userInput: userInput
      });
      dispatch({ type: 'set_record', record: {} });
      await handleDeleteRecord(recordID, () => {
        setSelectedEntry(prevEntry => ({ ...prevEntry, draftRecordID: '-' }));
      });
      mixpanel.track('cancel_create_record', { entry_name: name }); // Replace mixpanel.track with the correct syntax
      window.dataLayer.push({
        event: 'cancel_record',
        entry_name: name
      });
    } catch (err) {
      console.log(err);
    } finally {
      setIsCanceling(false);
    }
  };

  const handleSaveAndExit = async () => {
    try {
      const recordID = record.id;
      if (!recordID) return;
      setIsSaveAndExiting(true);
      await API.graphql(
        graphqlOperation(updateRecord, {
          input: { id: recordID, draft: false, _version: record._version }
        })
      );
      dispatch({ type: 'set_record', record: {} });
      setSelectedEntry({});

      const input = {
        id: entryID,
        draftRecordID: '-',
        _version: selectedEntry._version
      };
      const { data } = await API.graphql(
        graphqlOperation(updateEntry, { input })
      );
      setSelectedEntry(data.updateEntry);

      // await handleCreateRecord();
    } catch (err) {
      console.log(err);
    } finally {
      setIsSaveAndExiting(false);
    }
  };

  useEffect(() => {
    function checkNoComponents(entrySections) {
      for (let i = 0; i < entrySections.length; i++) {
        let entrySection = entrySections[i];
        if (
          Array.isArray(entrySection.components) &&
          entrySection.components.length > 0
        ) {
          return false;
        }
        if (
          entrySection.components &&
          entrySection.components.items &&
          Array.isArray(entrySection.components.items) &&
          entrySection.components.items.length > 0
        ) {
          return false;
        }
      }
      return true;
    }
    let result = checkNoComponents(entrySections);
    setNoComponents(result);
  }, [entrySections]);

  useEffect(() => {
    if (changedData) {
      const timer = setTimeout(() => {
        setChangedData(false);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [changedData]);

  return loading ? (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{ height: '80vh' }}
    >
      <CircularProgress />
    </Box>
  ) : (
    !!selectedEntry && (
      <Box sx={{ justifyContent: 'center', mt: 4 }}>
        <Container maxWidth="md" sx={{ mb: 10 }}>
          <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
            {state.edit
              ? !selectedEntry.noEdit && (
                  <Stack
                    width="100%"
                    direction="row"
                    display="flex"
                    sx={{ justifyContent: 'space-between' }}
                  >
                    <Grid container>
                      <Grid
                        item
                        sm={6}
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        <EntrySettings
                          entry={selectedEntry}
                          setEntry={setSelectedEntry}
                          addLogic={addLogic}
                          logic={logic}
                          setAddLogic={setAddLogic}
                          setLogic={setLogic}
                        />
                      </Grid>
                    </Grid>
                  </Stack>
                )
              : null}
          </Stack>

          {state.edit && !selectedEntry.noEdit && (
            <AddEntrySectionButton
              index={0}
              onAddEntrySection={handleCreateEntrySection}
            />
          )}
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
            onDragEnd={handleDragEntrySection}
          >
            <SortableContext
              items={entrySections}
              strategy={verticalListSortingStrategy}
            >
              {entrySections.map((entrySection, entrySectionIdx) => {
                if (!state.edit && entrySection.components.length === 0) {
                  return null;
                }

                if (switchingEntry) {
                  return null;
                }

                return (
                  <React.Fragment key={entrySection.id}>
                    <SortableEntrySection
                      setChangedData={setChangedData}
                      disableDrag={entrySections.length === 1}
                      edit={state.edit}
                      onDeleteEntrySection={handleDeleteEntrySection}
                      entrySection={entrySection}
                      setSections={setEntrySections}
                    />
                    {state.edit && !selectedEntry.noEdit && (
                      <AddEntrySectionButton
                        index={entrySectionIdx + 1}
                        onAddEntrySection={handleCreateEntrySection}
                      />
                    )}
                  </React.Fragment>
                );
              })}
            </SortableContext>
          </DndContext>
          {!state.edit && !noComponents && (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={2}
            >
              <LoadingButton
                disabled={changedData}
                loading={isCanceling}
                onClick={() => handleCancel(selectedEntry.name)}
                size="small"
              >
                Clear
              </LoadingButton>
              <Box display="flex" alignItems="center">
                <ButtonGroup>
                  <LoadingButton
                    loading={isSaveAndExiting || changedData}
                    onClick={handleSaveAndExit}
                    variant="contained"
                    size="small"
                  >
                    Save
                  </LoadingButton>
                </ButtonGroup>
              </Box>
            </Stack>
          )}
        </Container>
      </Box>
    )
  );
});

import { useEffect, useRef, useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import {
  Box,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  Tab,
  Tabs
} from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import { useAppContext } from 'context';
import { sectionByClientIDByOrder } from 'graphql/customQueries';

import DateRangePicker from 'components/atoms/DateRangePicker';

import { getIconComponent } from '../IconPicker';

function ScrollableTabsButtonForce({
  entryQuery,
  setEntryQuery,
  setSectionQuery,
  value,
  setValue
}) {
  const { state } = useAppContext();
  const [sections, setSections] = useState([]);
  const { user } = useAuthenticator(context => [context.user]);
  const clientID =
    state?.client?.id || user.attributes['custom:clientID'] || user.username;
  const [disableLeftButton, setDisableLeftButton] = useState(true);
  const [disableRightButton, setDisableRightButton] = useState(false);

  useEffect(() => {
    const container = scrollContainer.current;
    setDisableLeftButton(container?.scrollLeft <= 0);
    setDisableRightButton(
      container.scrollLeft >= container.scrollWidth - container.clientWidth
    );
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);

    if (newValue > 0 && sections && sections[newValue - 1]) {
      setSectionQuery(sections[newValue - 1].id);
    } else {
      setSectionQuery(null);
    }
  };

  useEffect(() => {
    async function fetchSections() {
      const data = await API.graphql(
        graphqlOperation(sectionByClientIDByOrder, { clientID: clientID })
      );
      const items = data.data.sectionByClientIDByOrder.items;
      const filteredItems = items.filter(item => !item.complete);

      setSections(filteredItems);
      checkScrollPosition();
    }
    fetchSections();
    return () => {
      setSections([]);
    };
  }, [clientID]);

  const checkScrollPosition = () => {
    const container = scrollContainer.current;
    setDisableLeftButton(container?.scrollLeft <= 0);
    setDisableRightButton(
      container?.scrollLeft >= container?.scrollWidth - container?.clientWidth
    );
  };

  const scrollContainer = useRef(null);

  const [scrollInterval, setScrollInterval] = useState(null);

  const scrollBy = amount => {
    scrollContainer.current.scrollBy({ left: amount, behavior: 'smooth' });
    checkScrollPosition();
  };

  const startScrolling = direction => {
    stopScrolling(); // Clear existing intervals

    setScrollInterval(
      setInterval(() => {
        const container = scrollContainer.current;

        const newScrollPosition = container?.scrollLeft + direction * 20;

        if (
          (newScrollPosition <= 0 && direction < 0) ||
          (newScrollPosition >=
            container?.scrollWidth - container?.clientWidth &&
            direction > 0)
        ) {
          stopScrolling();
          return;
        }

        container.scrollBy({ left: direction * 20, behavior: 'auto' });
      }, 33)
    );
  };

  const stopScrolling = () => {
    if (scrollInterval !== null) {
      clearInterval(scrollInterval);
      setScrollInterval(null);
    }
  };

  let initialTouchPos = null;

  const touchStart = e => {
    initialTouchPos = e.touches[0].clientX;
  };

  const touchMove = e => {
    if (!initialTouchPos) return;

    const delta = initialTouchPos - e.touches[0].clientX;
    scrollContainer.current.scrollBy(delta, 0);
    initialTouchPos = e.touches[0].clientX;
  };

  const touchEnd = () => {
    initialTouchPos = null;
  };

  return (
    <Box maxWidth={'100%'}>
      <Tabs
        value={value}
        onChange={handleChange}
        scrollButtons
        allowScrollButtonsMobile
        variant="scrollable"
        aria-label="scrollable force tabs example"
      >
        <Tab label="All" />
        {sections?.map((section, index) => {
          return <Tab label={section.name} key={index} />;
        })}
      </Tabs>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          maxHeight: 200,
          overflow: 'hidden',
          maxWidth: { xs: '100vw', md: '50vw' }
        }}
      >
        <IconButton
          size="small"
          onClick={() => scrollBy(-50)}
          onMouseDown={() => startScrolling(-1)}
          onMouseUp={stopScrolling}
          onMouseLeave={stopScrolling}
          onTouchStart={() => startScrolling(-1)}
          onTouchEnd={stopScrolling}
          disabled={disableLeftButton}
        >
          <NavigateBefore />
        </IconButton>

        <Box
          ref={scrollContainer}
          onTouchStart={touchStart}
          onTouchMove={touchMove}
          onTouchEnd={touchEnd}
          sx={{
            overflowX: 'auto',
            overflowY: 'hidden',
            touchAction: 'manipulation',
            flexGrow: 1,
            whiteSpace: 'nowrap',
            '&::-webkit-scrollbar': {
              display: 'none'
            },
            '-ms-overflow-style': 'none',
            'scrollbar-width': 'none'
          }}
        >
          {value === 0 && (
            <Stack my={2} direction="row" spacing={1}>
              {sections ? (
                sections?.flatMap(section =>
                  section.entries?.items.reduce((acc, entry) => {
                    if (entry.complete !== true) {
                      acc.push(
                        <Chip
                          sx={{ mx: 0.5, flexShrink: 1 }}
                          icon={getIconComponent(entry.icon)}
                          variant={
                            entryQuery === entry.id ? 'contained' : 'outlined'
                          }
                          color="primary"
                          key={entry.id}
                          label={entry.name}
                          onClick={() =>
                            entryQuery === entry.id
                              ? setEntryQuery(null)
                              : setEntryQuery(entry.id)
                          }
                        />
                      );
                    }
                    return acc;
                  }, [])
                )
              ) : (
                <Box
                  sx={{
                    alignItems: 'center',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    height: 32
                  }}
                >
                  <CircularProgress size={10} />
                </Box>
              )}
            </Stack>
          )}
          {sections?.map((section, index) => {
            return (
              value === index + 1 && (
                <Stack my={2} direction="row" spacing={1} key={index}>
                  {section.entries?.items.reduce((acc, entry) => {
                    if (entry.complete !== true) {
                      acc.push(
                        <Chip
                          sx={{ mx: 0.5, flexShrink: 1 }}
                          icon={getIconComponent(entry.icon)}
                          variant={
                            entryQuery === entry.id ? 'contained' : 'outlined'
                          }
                          color="primary"
                          key={entry.id}
                          label={entry.name}
                          onClick={() =>
                            entryQuery === entry.id
                              ? setEntryQuery(null)
                              : setEntryQuery(entry.id)
                          }
                        />
                      );
                    }
                    return acc;
                  }, [])}
                </Stack>
              )
            );
          })}
        </Box>

        <IconButton
          size="small"
          onClick={() => scrollBy(50)}
          onMouseDown={() => startScrolling(1)}
          onMouseUp={stopScrolling}
          onMouseLeave={stopScrolling}
          onTouchStart={() => startScrolling(1)}
          onTouchEnd={stopScrolling}
          disabled={disableRightButton}
        >
          <NavigateNext />
        </IconButton>
      </Box>
    </Box>
  );
}

export default function RecordSelector({
  entryQuery,
  setEntryQuery,
  setSectionQuery,
  to,
  setTo,
  from,
  setFrom,
  value,
  setValue,
  setSelectedChip,
  selectedChip
}) {
  return (
    <Grid container>
      <Grid
        sx={{
          overflowX: 'hidden'
        }}
        item
        sm={6}
        xs={12}
      >
        <ScrollableTabsButtonForce
          entryQuery={entryQuery}
          setEntryQuery={setEntryQuery}
          setSectionQuery={setSectionQuery}
          value={value}
          setValue={setValue}
        />
      </Grid>
      <Grid item sm={6} xs={12} display="flex" justifyContent="flex-end">
        <Stack direction="row" spacing={1}>
          <DateRangePicker
            setSelectedChip={setSelectedChip}
            selectedChip={selectedChip}
            to={to}
            setTo={setTo}
            from={from}
            setFrom={setFrom}
          />
        </Stack>
      </Grid>
    </Grid>
  );
}

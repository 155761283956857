import { Add, AddLink, History, Remove, Update } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Chip,
  Typography
} from '@mui/material';

import { getTimeLeft } from 'utils/time';

import ProfileAvatar from '../ProfileAvatar';

export default function BasicUserCard({
  profile,
  action,
  remove,
  actionLoading,
  add,
  search,
  selectedClinician,
  invitation,
  multipleActions,
  chip
}) {
  const handleAction = () => {
    if (typeof action === 'function') {
      action(profile);
    }
  };

  let actionIcon = null;

  if (remove) {
    actionIcon = (
      <LoadingButton loading={actionLoading} onClick={() => action()}>
        {Boolean(invitation) ? 'CANCEL' : 'REMOVE'}
      </LoadingButton>
    );
  } else if (add) {
    actionIcon = Boolean(selectedClinician) ? <Remove /> : <Add />;
  } else if (Boolean(multipleActions)) {
    actionIcon = multipleActions;
  }

  const titleComponent = (
    <Typography
      sx={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }}
      variant="h6"
    >
      {profile.firstName}{' '}
      {search ? profile.lastName.charAt(0) + '.' : profile.lastName}
    </Typography>
  );

  const subheaderComponent = (
    <Typography
      sx={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }}
      variant="body2"
      color="textSecondary"
    >
      {profile.email}
    </Typography>
  );

  const CardHeaderComponent = (
    <CardHeader
      action={actionIcon}
      avatar={<ProfileAvatar profile={profile} size={60} />}
      title={titleComponent}
      subheader={subheaderComponent}
    />
  );

  let timeInfo = {};
  if (invitation && invitation.createdAt) {
    timeInfo = getTimeLeft(invitation.createdAt);
  }

  return (
    <Card elevation={4} sx={{ width: '100%', mb: 1 }}>
      {add ? (
        <CardActionArea onClick={handleAction}>
          {CardHeaderComponent}
        </CardActionArea>
      ) : (
        <>
          {CardHeaderComponent}
          {Boolean(invitation) && (
            <CardContent>
              <Box display="flex" flexDirection="row" flexWrap="wrap">
                {chip ? (
                  chip
                ) : (
                  <Chip
                    variant="outlined"
                    color="default"
                    icon={<AddLink />}
                    label="Invitation"
                    sx={{ mr: 1, mt: 1 }}
                  />
                )}
                <Chip
                  size={chip ? 'small' : 'medium'}
                  icon={
                    timeInfo.expired ? (
                      <History />
                    ) : (
                      <Update
                        color={timeInfo.expired ? 'warning' : 'success'}
                      />
                    )
                  }
                  label={timeInfo.timeLeft}
                  color={timeInfo.expired ? 'warning' : 'default'}
                  variant="outlined"
                  sx={{ mr: 1, mt: 1 }}
                />
              </Box>
            </CardContent>
          )}
        </>
      )}
    </Card>
  );
}

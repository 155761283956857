import { Box, Paper, Stack, Typography } from '@mui/material';

import { Clinician } from 'components/layout';
import { AddClientStepper } from 'components/organisms';

export default function AddClient() {
  return (
    <Clinician
      props={{ title: 'Add Client' }}
      AppBarComponents={
        <Box pl={4} width="100%" display="flex" justifyContent="space-between">
          <Stack direction="row" spacing={2}>
            <Typography variant="h5">Add a client</Typography>
          </Stack>
        </Box>
      }
    >
      <Paper elevation={1} sx={{ width: '100%' }}>
        <AddClientStepper />
      </Paper>
    </Clinician>
  );
}

import { LoadingButton } from '@mui/lab';
import { CircularProgress, Tooltip } from '@mui/material';

export default function ActionButton({
  color,
  tooltip,
  label,
  loading,
  onClick,
  disabled,
  size
}) {
  return (
    <Tooltip placement="top" title={tooltip}>
      <LoadingButton
        size={size || 'medium'}
        disabled={disabled}
        onClick={onClick}
      >
        {loading ? <CircularProgress color={color} size={24} /> : label}
      </LoadingButton>
    </Tooltip>
  );
}

import { useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { Lock, LockOpen } from '@mui/icons-material';
import { Box, Collapse, Stack, Typography } from '@mui/material';
import { Auth } from 'aws-amplify';
import { useSnackbar } from 'context/SnackBar';
import mixpanel from 'mixpanel-browser';

import { ErrorMessage, SettingHeader } from 'components/atoms';
import { ChangeCurrentPassword } from 'components/auth';

export default function AccountPassword() {
  const { user } = useAuthenticator(context => [context.user]);
  const [updatePassword, setUpdatePassword] = useState(false);
  const noEdit = user.username.includes('google');

  const [passwordError, setPasswordError] = useState(null);
  const [changingPassword, setChangingPassword] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const handlePasswordChange = async () => {
    try {
      setChangingPassword(true);
      await Auth.changePassword(user, currentPassword, newPassword);
      setNewPassword('');
      setCurrentPassword('');
      setConfirmNewPassword('');
      setUpdatePassword(false);
      mixpanel.track('change_password');
      enqueueSnackbar('Password changed successfully!', {
        severity: 'success'
      });
    } catch (err) {
      enqueueSnackbar('Error changing password.', {
        severity: 'error'
      });
      setChangingPassword(false);
      setPasswordError(err.message);
    } finally {
      setChangingPassword(false);
    }
  };

  return (
    <Box p={1} my={2}>
      <SettingHeader
        label="Password"
        state={updatePassword}
        setState={setUpdatePassword}
        noEdit={noEdit}
        reasonNoEdit={
          "You can't change your password here because your account is managed by Google."
        }
      />

      <Box p={1} sx={{ display: 'flex', alignItems: 'center' }}>
        <Stack direction="row" spacing={1}>
          {updatePassword ? <LockOpen color="grey" /> : <Lock color="grey" />}
          <Typography color="grey">
            {updatePassword
              ? 'Editing'
              : noEdit
              ? 'Managed by Google'
              : 'Secure'}
          </Typography>
        </Stack>
      </Box>

      <Collapse in={updatePassword}>
        <Box>
          <ChangeCurrentPassword
            handlePasswordChange={handlePasswordChange}
            currentPassword={currentPassword}
            setCurrentPassword={setCurrentPassword}
            newPassword={newPassword}
            setNewPassword={setNewPassword}
            changingPassword={changingPassword}
            confirmNewPassword={confirmNewPassword}
            setConfirmNewPassword={setConfirmNewPassword}
          />
          {Boolean(passwordError) && <ErrorMessage message={passwordError} />}
        </Box>
      </Collapse>
    </Box>
  );
}

import { Box, FormLabel } from '@mui/material';
import Slider from '@mui/material/Slider';

function SessionChart({ value }) {
  const milestones = [4, 6, 12, 20, 40, 60, 80, 100];

  let max = 12;
  let marks = [
    { value: 4, label: '4' },
    { value: 6, label: '6' },
    { value: 12, label: '12' }
  ];

  if (value) {
    for (let i = 0; i < milestones.length; i++) {
      if (value <= milestones[i]) {
        max = milestones[i];
        break;
      }
    }

    if (max > 12) {
      marks = milestones
        .slice(0, milestones.indexOf(max) + 1)
        .map(m => ({ value: m, label: `${m}`, className: 'custom-mark' }));
    }
  }

  return (
    <Box>
      <FormLabel sx={{ color: 'text.accent' }}>Sessions </FormLabel>
      <Box mt={4} mx={1}>
        <Slider
          value={value || 0}
          min={0}
          max={max}
          step={1}
          marks={marks}
          valueLabelDisplay="on"
          sx={{
            height: 3,
            '& .MuiSlider-mark.MuiSlider-markActive': {
              opacity: 1,
              backgroundColor: '#8884d8',
              width: '10px',
              height: '10px',
              borderRadius: '50%',
              marginLeft: '-4px'
            },
            '& .MuiSlider-mark': {
              opacity: 0.5,
              backgroundColor: '#8884d8',
              width: '8px',
              height: '8px',
              borderRadius: '50%',
              marginLeft: '-6px'
            },
            '& .MuiSlider-rail': {
              opacity: 1,
              color: 'white'
            },
            '& .MuiSlider-track': {
              opacity: 1,
              backgroundColor: '#8884d8',
              borderColor: 'tertiary.dark'
            },
            '& .MuiSlider-thumb': {
              height: '20px',
              width: '4px',
              backgroundColor: '#8884d8',
              borderRadius: 0,
              '&:hover': {
                boxShadow: 'none'
              }
            }
          }}
        />
      </Box>
    </Box>
  );
}

export default SessionChart;

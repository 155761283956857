import { Box } from '@mui/material';
import {
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  RadarChart as RC,
  Radar
} from 'recharts';

const data = [
  {
    subject: 'Conversations',
    A: 120,
    B: 110,
    fullMark: 150
  },

  {
    subject: 'Meetings',
    A: 85,
    B: 90,
    fullMark: 150
  },
  {
    subject: 'Ordering',
    A: 65,
    B: 85,
    fullMark: 150
  }
];

export default function RadarChart() {
  return (
    <Box display="flex" justifyContent="center">
      <RC
        cx={300}
        cy={250}
        outerRadius={150}
        width={500}
        height={500}
        data={data}
      >
        <PolarGrid />
        <PolarAngleAxis dataKey="subject" />
        <PolarRadiusAxis />
        <Radar
          name="Mike"
          dataKey="A"
          stroke="#8884d8"
          fill="#8884d8"
          fillOpacity={0.6}
        />
      </RC>
    </Box>
  );
}

import { useEffect, useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { Add, KeyboardArrowDown, KeyboardArrowLeft } from '@mui/icons-material';
import { Box, Button, Collapse, Pagination, Typography } from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import { useAppContext } from 'context';
import dayjs from 'dayjs';
import {
  recordsByDate,
  recordsByEntryID,
  recordsBySectionID
} from 'graphql/customQueries';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';

import { LoadingAtom } from 'components/atoms';
import NoClientError from 'components/atoms/NoClientError';
import { RecordRow, RecordSelector } from 'components/molecules';

export default function Entries() {
  let newDate = DateTime.local();
  let dateMinus42 = newDate.minus({ days: 42 });
  const navigate = useNavigate();

  function formatDate(date) {
    return dayjs(date).format('YYYY-MM-DD');
  }
  const { state, dispatch } = useAppContext();
  let { user } = useAuthenticator(context => [context.user]);
  const clientID =
    state.client?.id || user.attributes['custom:clientID'] || user.username;

  const type = user.attributes['custom:type'];
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(0); // Add this line
  const [records, setRecords] = useState([]);
  const [pageTokens, setPageTokens] = useState(['']);
  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const [entryQuery, setEntryQuery] = useState(null);
  const [sectionQuery, setSectionQuery] = useState(null);
  const [from, setFrom] = useState(dateMinus42);
  const [to, setTo] = useState(newDate);
  const [selectedChip, setSelectedChip] = useState(42);

  useEffect(() => {
    async function fetchRecords() {
      setLoading(true);
      let queryID = clientID;

      let queryData = {
        limit: 50,
        sortDirection: 'DESC',
        filter: {
          and: [
            { hasData: { eq: 'true' } },
            { _deleted: { attributeExists: false } }
          ]
        }
      };

      if (from && to) {
        let fromISO = from.toISO();
        let toISO = to.toISO();
        queryData.enteredAt = {
          between: [`${fromISO}`, `${toISO}`]
        };
      }

      let query;
      let queryName;
      if (entryQuery) {
        queryData.entryID = entryQuery;
        query = recordsByEntryID;
        queryName = 'recordsByEntryID';
      } else if (sectionQuery) {
        queryData.sectionID = sectionQuery;
        query = recordsBySectionID;
        queryName = 'recordsBySectionID';
      } else {
        queryData.clientID = queryID;
        query = recordsByDate;
        queryName = 'recordsByDate';
        if (pageTokens[currentPageIndex - 1] !== '') {
          queryData.nextToken = pageTokens[currentPageIndex - 1];
        }
      }

      const apiData = await API.graphql(graphqlOperation(query, queryData));

      const { items, nextToken } = apiData.data[queryName];

      if (nextToken) {
        if (currentPageIndex === pageTokens.length) {
          setPageTokens([...pageTokens, nextToken]);
        }
      }

      setRecords(items);
      setLoading(false);
    }

    fetchRecords();
    return () => {
      setRecords([]);
    };
  }, [
    user,
    entryQuery,
    sectionQuery,
    from,
    to,
    currentPageIndex,
    pageTokens,
    clientID
  ]);

  const reset = () => {
    setEntryQuery(null);
    setFrom(dateMinus42);
    setTo(newDate);
    setSectionQuery(null);
    setValue(0);
    setSelectedChip(42);
  };

  const readyToReset =
    !!entryQuery ||
    formatDate(from) !== formatDate(dateMinus42) ||
    formatDate(to) !== formatDate(newDate) ||
    !!sectionQuery;

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          mt: 1
        }}
      >
        {state.filter && (
          <Button disabled={!readyToReset} onClick={reset}>
            Reset
          </Button>
        )}
        <Button
          startIcon={
            state.filter ? <KeyboardArrowLeft /> : <KeyboardArrowDown />
          }
          onClick={() =>
            dispatch({ type: 'set_filter', filter: !state.filter })
          }
          color={
            state.filter ? 'secondary' : readyToReset ? 'warning' : 'primary'
          }
        >
          {state.filter ? 'Hide Filter' : 'Filter'}
        </Button>
      </Box>
      <Collapse in={state.filter}>
        <Box mt={1}>
          <RecordSelector
            selectedChip={selectedChip}
            setSelectedChip={setSelectedChip}
            to={to}
            setTo={setTo}
            from={from}
            setFrom={setFrom}
            entryQuery={entryQuery}
            setEntryQuery={setEntryQuery}
            setSectionQuery={setSectionQuery}
            value={value}
            setValue={setValue}
          />
        </Box>
      </Collapse>

      {type === 'clinician' && !state?.client?.id ? <NoClientError /> : null}
      {!loading && records.length === 0 && (
        <Box
          mt={2}
          sx={{ height: '50vh' }}
          display="flex"
          justifyContent="center"
          flexDirection={'column'}
          alignItems="center"
        >
          <Typography variant="h6">No entries found.</Typography>
          <Box sx={{ height: 20 }} />
          <Button
            startIcon={<Add />}
            size="small"
            onClick={() => navigate('/client/new')}
          >
            Create Entry
          </Button>
        </Box>
      )}
      {loading ? (
        <LoadingAtom />
      ) : (
        <>
          <Box>
            {records.map(record => {
              return (
                <Box key={record.id}>
                  <Box>
                    <RecordRow record={record} />{' '}
                  </Box>
                </Box>
              );
            })}
          </Box>

          <Box mt={6} mb={2} display="flex" justifyContent="center">
            {records.length > 0 && (
              <Pagination
                count={pageTokens.length}
                page={currentPageIndex}
                siblingCount={1}
                boundaryCount={1}
                variant="outlined"
                color="secondary"
                onChange={(event, page) => setCurrentPageIndex(page)}
              />
            )}
          </Box>
        </>
      )}
    </Box>
  );
}

import React, { useState } from 'react';

import { LoadingButton } from '@mui/lab';
import { Box, Grid, TextField } from '@mui/material';
import * as yup from 'yup';

function ChangeName({
  firstName,
  setFirstName,
  lastName,
  setLastName,
  changingName,
  handleChangeName
}) {
  const [errors, setErrors] = useState({});
  const [submissionAttempted, setSubmissionAttempted] = useState(false);

  const validationSchema = yup.object().shape({
    firstName: yup.string().required('First Name is required'),
    lastName: yup.string().required('Last Name is required')
  });

  const validateField = async (fieldName, value) => {
    try {
      await validationSchema.validateAt(fieldName, { [fieldName]: value });
      setErrors(prevErrors => {
        const newErrors = { ...prevErrors };
        delete newErrors[fieldName];
        return newErrors;
      });
    } catch (err) {
      setErrors(prevErrors => ({ ...prevErrors, [fieldName]: err.message }));
    }
  };

  const validateForm = async () => {
    try {
      await validationSchema.validate({ firstName, lastName });
      setErrors({});
      return true;
    } catch (err) {
      setErrors({ [err.path]: err.message });
      return false;
    }
  };

  const handleOnSubmit = async () => {
    setSubmissionAttempted(true);
    const isValid = await validateForm();
    if (isValid) {
      handleChangeName();
    }
  };

  return (
    <Box>
      <Grid sx={{ my: 1 }} container spacing={1}>
        <Grid item xs={12} sm={6}>
          <Box sx={{ width: '100%' }}>
            <TextField
              size="small"
              fullWidth
              value={firstName}
              onChange={e => {
                setFirstName(e.target.value);
                if (submissionAttempted) {
                  validateField('firstName', e.target.value);
                }
                setSubmissionAttempted(false);
              }}
              label="First Name"
              error={submissionAttempted && !!errors.firstName}
              helperText={submissionAttempted && errors.firstName}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box sx={{ width: '100%' }}>
            <TextField
              size="small"
              fullWidth
              value={lastName}
              onChange={e => {
                setLastName(e.target.value);
                if (submissionAttempted) {
                  validateField('lastName', e.target.value);
                }
                setSubmissionAttempted(false);
              }}
              label="Last Name"
              error={submissionAttempted && !!errors.lastName}
              helperText={submissionAttempted && errors.lastName}
            />
          </Box>
        </Grid>
      </Grid>
      <Box my={2}>
        <LoadingButton
          fullWidth
          size="small"
          variant="contained"
          loading={changingName}
          onClick={handleOnSubmit}
        >
          Change Name
        </LoadingButton>
      </Box>
    </Box>
  );
}

export default React.memo(ChangeName);

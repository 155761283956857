export function getBrowserName() {
  const userAgent = navigator.userAgent;

  // Detect Chrome
  if (
    /(chrome|crios)\/(\d+)/.test(userAgent.toLowerCase()) &&
    !/opr\//.test(userAgent.toLowerCase()) &&
    !/edg\//.test(userAgent.toLowerCase())
  ) {
    return 'chrome';
  }

  // Detect Firefox
  if (/firefox\/(\d+)/.test(userAgent.toLowerCase())) {
    return 'firefox';
  }

  // Detect Safari
  if (
    /^((?!chrome|android).)*safari/i.test(userAgent) &&
    !/opr\//.test(userAgent.toLowerCase())
  ) {
    return 'safari';
  }

  // Detect Opera
  if (/opr\/(\d+)/.test(userAgent.toLowerCase())) {
    return 'opera';
  }

  // Detect Edge
  if (/edg\//.test(userAgent.toLowerCase())) {
    return 'edge';
  }

  // Default to 'Unknown'
  return 'unknown';
}

let db;
let dbReady = false;

export function initializeIndexedDB() {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open('SpeechFit', 2);

    request.onsuccess = event => {
      db = event.target.result;
      dbReady = true;
      resolve();
    };

    request.onerror = event => {
      dbReady = false;
      reject(new Error("Couldn't open the database"));
    };

    request.onupgradeneeded = event => {
      db = event.target.result;
      db.createObjectStore('images', {
        keyPath: 'imageID'
      });
      db.createObjectStore('pdfs', {
        keyPath: 'pdfID'
      });
    };
  });
}

function getDB() {
  return dbReady ? db : null;
}

async function retry(operation, maxRetries = 3, delay = 500) {
  for (let i = 0; i < maxRetries; i++) {
    try {
      return await operation();
    } catch (error) {
      if (i === maxRetries - 1) {
        console.error('Operation failed after maximum retries:', error);
        return null;
      }
      await new Promise(res => setTimeout(res, delay));
    }
  }
}

export async function saveImageToIndexedDB(imageID, blob) {
  return retry(() => {
    return new Promise((resolve, reject) => {
      const db = getDB();
      if (!db) {
        reject(new Error('Database is not initialized'));
        return;
      }
      const tx = db.transaction('images', 'readwrite');
      const store = tx.objectStore('images');
      const request = store.put({ imageID, blob });

      request.onsuccess = () => resolve();
      request.onerror = () => reject(request.error);
    });
  });
}

export async function getImageFromIndexedDB(imageID) {
  return retry(() => {
    return new Promise((resolve, reject) => {
      const db = getDB();
      if (!db) {
        reject(new Error('Database is not initialized'));
        return;
      }
      const tx = db.transaction('images', 'readonly');
      const store = tx.objectStore('images');
      const request = store.get(imageID);

      request.onsuccess = () =>
        resolve(request.result ? request.result.blob : null);
      request.onerror = () => reject(request.error);
    });
  });
}

export async function savePDFToIndexedDB(pdfID, blob) {
  return retry(() => {
    return new Promise((resolve, reject) => {
      const db = getDB();
      if (!db) {
        reject(new Error('Database is not initialized'));
        return;
      }
      const tx = db.transaction('pdfs', 'readwrite');
      const store = tx.objectStore('pdfs');
      const request = store.put({ pdfID, blob });

      request.onsuccess = () => resolve();
      request.onerror = () => reject(request.error);
    });
  });
}

export async function getPDFFromIndexedDB(pdfID) {
  return retry(() => {
    return new Promise((resolve, reject) => {
      const db = getDB();
      if (!db) {
        reject(new Error('Database is not initialized'));
        return;
      }
      const tx = db.transaction('pdfs', 'readonly');
      const store = tx.objectStore('pdfs');
      const request = store.get(pdfID);

      request.onsuccess = () =>
        resolve(request.result ? request.result.blob : null);
      request.onerror = () => reject(request.error);
    });
  });
}

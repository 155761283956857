import { DateTime } from 'luxon';

function isToday(enteredAt) {
  if (!enteredAt) return false;

  const enteredAtDate = DateTime.fromISO(enteredAt, { zone: 'utc' });
  const sydneyNow = DateTime.now().setZone('Australia/Sydney');
  const startOfDay = sydneyNow.startOf('day');
  const endOfDay = sydneyNow.endOf('day');

  return enteredAtDate >= startOfDay && enteredAtDate <= endOfDay;
}

export default isToday;

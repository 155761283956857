import { useCallback, useEffect } from 'react';

import { Box, Chip, Stack, TextField } from '@mui/material';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTime } from 'luxon';

export default function DateRangePicker({
  to,
  setTo,
  from,
  setFrom,
  setSelectedChip,
  selectedChip
}) {
  const newDate = DateTime.local().endOf('day');

  const updateSelectedChip = useCallback(
    (from, to) => {
      if (to.hasSame(newDate, 'day')) {
        const difference = Math.floor(to.diff(from, 'days').days);
        if (difference === 7) {
          setSelectedChip(7);
        } else if (difference === 14) {
          setSelectedChip(14);
        } else if (difference === 28) {
          setSelectedChip(28);
        } else if (difference === 42) {
          setSelectedChip(42);
        } else if (difference === 90) {
          setSelectedChip(90);
        } else {
          setSelectedChip(null);
        }
      } else {
        setSelectedChip(null);
      }
    },
    [setSelectedChip, newDate]
  );

  useEffect(() => {
    updateSelectedChip(from, to);
  }, [from, to, updateSelectedChip]);

  const handleChipClick = days => {
    const fromDate = newDate.minus({ days: days }).startOf('day');
    setFrom(fromDate);
    setTo(newDate);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          mb: 2,
          alignItems: { xs: 'center', sm: 'flex-end' }
        }}
      >
        <Stack direction="row" spacing={1}>
          <Chip
            label="1w"
            onClick={() => handleChipClick(7)}
            color={selectedChip === 7 ? 'primary' : 'default'}
          />
          <Chip
            label="2w"
            onClick={() => handleChipClick(14)}
            color={selectedChip === 14 ? 'primary' : 'default'}
          />
          <Chip
            label="4w"
            onClick={() => handleChipClick(28)}
            color={selectedChip === 28 ? 'primary' : 'default'}
          />
          <Chip
            label="6w"
            onClick={() => handleChipClick(42)}
            color={selectedChip === 42 ? 'primary' : 'default'}
          />
          <Chip
            label="3m"
            onClick={() => handleChipClick(90)}
            color={selectedChip === 90 ? 'primary' : 'default'}
          />
        </Stack>
        <Stack sx={{ mt: 2 }} direction="row" spacing={1}>
          <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={'au'}>
            <DatePicker
              disableFuture
              label="From"
              value={from}
              maxDate={to}
              onChange={newValue => {
                setFrom(newValue);
              }}
              renderInput={params => <TextField {...params} />}
            />
            <DatePicker
              disableFuture
              label="To"
              value={to}
              minDate={from}
              onChange={newValue => {
                setTo(newValue);
              }}
              renderInput={params => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Stack>
      </Box>
    </>
  );
}

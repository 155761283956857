import { Close } from '@mui/icons-material';
import {
  Card,
  FormControlLabel,
  IconButton,
  Stack,
  Switch,
  TextField,
  Typography
} from '@mui/material';

export const AudioSettings = ({
  settings,
  onSettingChange,
  setShowSettings
}) => {
  return (
    <Card
      elevation={0}
      sx={{ my: 1, px: 1, backgroundColor: 'background.settings' }}
    >
      <Stack
        direction="row"
        spacing={1}
        px={1}
        py={1}
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        <IconButton
          size="small"
          onClick={() => setShowSettings(prevShowSettings => !prevShowSettings)}
        >
          <Close fontSize="small" />
        </IconButton>
        <Typography variant="caption">Audio Settings</Typography>
      </Stack>
      <Stack direction="row" spacing={1}>
        <TextField
          variant="filled"
          size="small"
          label="Name"
          color="tertiary"
          value={settings?.name}
          onChange={event => onSettingChange?.('name', event.target.value)}
        />
        <FormControlLabel
          size="small"
          control={
            <Switch
              color="tertiary"
              onChange={event =>
                onSettingChange?.('allowUpload', event.target.checked)
              }
              checked={settings?.allowUpload}
              label="Multiline"
              size="small"
            />
          }
          label="Allow Upload"
        />
        <FormControlLabel
          size="small"
          control={
            <Switch
              color="tertiary"
              onChange={event =>
                onSettingChange?.('allowLabels', event.target.checked)
              }
              checked={settings?.allowLabels}
              label="Multiline"
              size="small"
            />
          }
          label="Allow Labeling"
        />
        <FormControlLabel
          size="small"
          control={
            <Switch
              color="tertiary"
              onChange={event =>
                onSettingChange?.('collapse', event.target.checked)
              }
              checked={settings?.collapse}
              label="Multiline"
              size="small"
            />
          }
          label="Collapse by Default"
        />
      </Stack>
    </Card>
  );
};

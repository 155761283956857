import React from 'react';

import { Avatar, Skeleton, Stack, useTheme } from '@mui/material';
import { colorMapper } from 'utils';

const avatarStyle = {
  width: 20,
  height: 20,
  fontSize: '0.9rem',
  fontWeight: 'bolder'
};

function SkeletonAvatar({ color, size }) {
  const avatarDimensions = size === 'large' ? 40 : size === 'medium' ? 30 : 20;
  return (
    <Avatar
      sx={{ ...avatarStyle, width: avatarDimensions, height: avatarDimensions }}
    >
      <Skeleton
        sx={{ bgcolor: color ? color : 'default' }}
        variant="circular"
        height={avatarDimensions}
        width={avatarDimensions}
      />
    </Avatar>
  );
}

function Preview({ inputs, size, days }) {
  const theme = useTheme();

  const renderSkeletonAvatars = () => {
    const columns = Array(days || 7).fill(null);
    return columns.map((_, index) => (
      <Stack
        key={index}
        direction="column"
        spacing={size === 'large' ? 2 : size === 'medium' ? 1.5 : 1}
      >
        <SkeletonAvatar size={size} />
        <SkeletonAvatar size={size} color={theme.palette.background.paper} />
      </Stack>
    ));
  };

  return (
    <Stack direction="row" spacing={size === 'large' ? 2 : 1}>
      {!inputs ? (
        <>{renderSkeletonAvatars()}</>
      ) : (
        inputs.map(input => (
          <Stack
            key={input.date}
            direction="column"
            spacing={size === 'large' ? 2 : size === 'medium' ? 1.5 : 1}
          >
            <RecordAvatar input={input} size={size} />
            <WeekdayAvatar input={input} size={size} />
          </Stack>
        ))
      )}
    </Stack>
  );
}

function RecordAvatar({ input, size }) {
  const avatarDimensions = size === 'large' ? 40 : size === 'medium' ? 30 : 20;
  const fontSize =
    size === 'large' ? '1.8rem' : size === 'medium' ? '1.35rem' : '0.9rem';

  return (
    <Avatar
      sx={{
        width: avatarDimensions,
        height: avatarDimensions,
        fontSize: fontSize,
        fontWeight: 'bolder',
        bgcolor: colorMapper({ data: input.value && Math.round(input.value) })
      }}
    >
      {input.value !== null ? (
        Math.round(input.value)
      ) : (
        <Skeleton
          variant="circular"
          height={avatarDimensions}
          width={avatarDimensions}
        />
      )}
    </Avatar>
  );
}

function WeekdayAvatar({ input, size }) {
  const theme = useTheme();
  const avatarDimensions = size === 'large' ? 40 : size === 'medium' ? 30 : 20;
  const fontSize = '0.75rem';
  return (
    <Avatar
      sx={{
        width: avatarDimensions,
        height: avatarDimensions,
        fontSize: fontSize,
        color: theme.palette.text.primary,
        bgcolor: theme.palette.background.paper
      }}
    >
      {size === 'large' ? input.date : input.day.toUpperCase()}
    </Avatar>
  );
}

export default React.memo(Preview);

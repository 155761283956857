import { useEffect, useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  Close,
  DragIndicator,
  Visibility,
  VisibilityOff
} from '@mui/icons-material';
import { Box, Chip, IconButton, TextField, Tooltip } from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import { useAppContext } from 'context';
import { useEntryContext } from 'context/Entry';
import { updateEntry } from 'graphql/mutations';
import { recordsByDate } from 'graphql/queries';
import debounce from 'lodash/debounce';

import { getIcon } from 'components/atoms';

export default function SortableChip({
  chip,
  removeChip = () => {},
  handleChipClick = () => {},
  sortDisabled = true,
  setEntries
}) {
  const { selectedEntry } = useEntryContext();
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({
    id: chip.id,
    disabled: sortDisabled
  });

  const { user } = useAuthenticator(context => [context.user]);
  const { state } = useAppContext();
  const clientID =
    state?.client?.id || user.attributes['custom:clientID'] || user.username;
  const [highlight, setHighlight] = useState(false);
  const [entryVersion, setEntryVersion] = useState(chip._version);

  if (transform) {
    transform.scaleY = 1;
  }

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  const [chipName, setChipName] = useState(chip.name);

  const update = value => {
    setChipName(value);
    debouncedUpdateEntryName(value);
  };

  const debouncedUpdateEntryName = debounce(async value => {
    const { data } = await API.graphql(
      graphqlOperation(updateEntry, {
        input: { id: chip.id, name: value, _version: entryVersion }
      })
    );
    const newVersion = data.updateEntry._version;
    setEntryVersion(newVersion);
  }, 300);

  useEffect(() => {
    async function getRecords() {
      const data = await API.graphql(
        graphqlOperation(recordsByDate, {
          clientID: clientID,
          sortDirection: 'DESC',
          limit: chip.logic
        })
      );

      const records = data.data.recordsByDate.items;
      const found = records.some(record => record.entryID === chip.id);
      const lastRecordTimestamp = new Date(records[0]?.createdAt);
      const currentTime = new Date();
      const timeDiff = currentTime - lastRecordTimestamp;
      const twelveHoursInMilliseconds = 12 * 60 * 60 * 1000;
      if (!found || timeDiff > twelveHoursInMilliseconds) {
        setHighlight(true);
      } else {
        setHighlight(false);
      }
      return records;
    }
    if (chip.addLogic) {
      getRecords();
    }
  }, [chip, clientID]);

  const active = selectedEntry?.id === chip.id;

  const updateEntryInactive = async () => {
    const { data } = await API.graphql(
      graphqlOperation(updateEntry, {
        input: { id: chip.id, inactive: !chip.inactive, _version: entryVersion }
      })
    );

    const updatedEntry = data.updateEntry;
    setEntryVersion(updatedEntry._version);
    setEntries(prevEntries =>
      prevEntries.map(entry =>
        entry.id === chip.id
          ? {
              ...entry,
              inactive: updatedEntry.inactive,
              _version: updatedEntry._version
            }
          : entry
      )
    );
  };

  return (
    <div
      ref={setNodeRef}
      className={`${isDragging ? 'dragging chip-dragging' : ''}`}
      style={style}
    >
      <div className="chip-wrap">
        <Chip
          disabled={chip.inactive && !state.edit}
          variant={active ? 'contained' : 'outlined'}
          icon={
            state.edit && chip.noEdit
              ? null
              : !state.edit && chip.icon
              ? getIcon(chip.icon, active)
              : null
          }
          size="large"
          sx={{
            mr: 1,
            mt: 1,
            py: 3,
            px: 1,
            fontWeight: 'bold',
            fontSize: '1rem',
            opacity: chip.inactive ? 0.5 : 1
          }}
          key={chip.id}
          clickable={sortDisabled}
          onClick={() => (sortDisabled ? handleChipClick(chip) : null)}
          label={
            <Box display={'flex'} alignItems={'center'}>
              {chip.noEdit ? null : !sortDisabled ? (
                <div
                  {...attributes}
                  {...listeners}
                  className="chip-drag-handle"
                >
                  <DragIndicator />
                </div>
              ) : null}
              {state.edit ? (
                <>
                  <TextField
                    disabled={chip.noEdit}
                    sx={{
                      // Styles applied when active, regardless of disabled state
                      ...(active
                        ? {
                            '& .MuiInputBase-input, & .MuiInputLabel-root, & .MuiOutlinedInput-root fieldset':
                              {
                                color: 'white', // Text and label color
                                borderColor: 'white' // Border color
                              },
                            '&:hover .MuiOutlinedInput-root fieldset, & .Mui-focused .MuiOutlinedInput-root fieldset':
                              {
                                borderColor: 'white' // Maintain white border on hover and focus
                              }
                          }
                        : {}),
                      // Additional styles specifically for when the TextField is disabled
                      '&.Mui-disabled': {
                        '& .MuiInputBase-input, & .MuiInputLabel-root': {
                          color: 'white', // Ensures text and label color is white when disabled
                          '-webkit-text-fill-color': 'white !important',
                          opacity: 1
                        },
                        '& .MuiOutlinedInput-root fieldset': {
                          borderColor: 'white', // Ensures border color is white when disabled,
                          '-webkit-text-fill-color': 'white !important',
                          opacity: 1
                        }
                      }
                    }}
                    size="small"
                    value={chipName}
                    onChange={evt => update(evt.target.value)}
                  />
                </>
              ) : (
                <>{chipName}</>
              )}
              {!sortDisabled && !chip.noEdit ? (
                <Tooltip title="Permanently Remove">
                  <Close
                    sx={{
                      position: 'relative',
                      right: -5,
                      cursor: 'pointer'
                    }}
                    onClick={() => removeChip(chip)}
                  />
                </Tooltip>
              ) : null}
              {state.edit && (
                <Tooltip
                  title={chip.inactive ? 'Activate' : 'Temporarily Deactivate'}
                >
                  <IconButton sx={{ ml: 1 }} onClick={updateEntryInactive}>
                    {chip.inactive ? (
                      <VisibilityOff sx={{ color: active ? 'white' : null }} />
                    ) : (
                      <Visibility sx={{ color: active ? 'white' : null }} />
                    )}
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          }
          color={state.edit ? 'primary' : highlight ? 'warning' : 'primary'}
        />
      </div>
    </div>
  );
}

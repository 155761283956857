import { Chip } from '@mui/material';

import { getIconComponent } from 'components/molecules/IconPicker';

export default function IconChip({ entry }) {
  return (
    <Chip
      sx={{ mx: 0.5, flexShrink: 1 }}
      icon={getIconComponent(entry.icon)}
      variant={'contained'}
      color="primary"
      label={entry.name}
    />
  );
}

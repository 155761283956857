import React from 'react';

import { AddBox } from '@mui/icons-material';
import {
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Stack
} from '@mui/material';

function DividerButton({
  componentSelectionDialog,
  loading = false,
  onClick,
  size
}) {
  return (
    <Grid container alignItems="center">
      <Grid item xs>
        <Divider />
      </Grid>
      <Grid item>
        {loading ? (
          <Stack alignItems="center" m="7px">
            <CircularProgress size={16} />
          </Stack>
        ) : (
          <IconButton
            color={componentSelectionDialog ? 'secondary' : 'primary'}
            size={size || 'small'}
            onClick={onClick}
          >
            <AddBox fontSize={size || 'small'} />
          </IconButton>
        )}
      </Grid>
      <Grid item xs>
        <Divider />
      </Grid>
    </Grid>
  );
}

export default DividerButton;

import { useEffect, useState } from 'react';

import { Storage } from 'aws-amplify';
import { getPDFFromIndexedDB, savePDFToIndexedDB } from 'indexedDB';

import { PDF } from 'components/atoms';

const ContentPDF = ({ content }) => {
  const [pdf, setPDF] = useState(null);

  let contentID = content.id;

  async function getPDFFromS3(contentID) {
    try {
      const key = `resources/${contentID}.pdf`;
      const blob = await Storage.get(key, { download: true });
      return blob.Body;
    } catch (error) {
      console.error('Error fetching image from S3:', error);
      return null;
    }
  }

  useEffect(() => {
    let contentURL;

    async function fetchPDF() {
      if (contentID) {
        const blob = await getPDFFromIndexedDB(contentID);
        if (blob) {
          contentURL = URL.createObjectURL(blob);
          setPDF(contentURL);
        } else {
          const blobFromS3 = await getPDFFromS3(contentID); // Using new function
          await savePDFToIndexedDB(contentID, blobFromS3);
          contentURL = URL.createObjectURL(blobFromS3);
          setPDF(contentURL);
        }
      }
    }
    if (content?.id) {
      fetchPDF();
    }
    return () => {
      if (contentURL) {
        URL.revokeObjectURL(contentURL);
      }
    };
  }, [content, contentID]);

  return <PDF pdf={pdf} />;
};

export default ContentPDF;

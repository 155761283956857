import { programVersions } from 'common/constants';

export default function checkUpgradeAvailability(program) {
  for (let item of programVersions) {
    if (item[program]) {
      if (item[program] !== program) {
        return {
          upgradeAvailable: true,
          newProgram: item[program]
        };
      }
      break;
    }
  }

  return {
    upgradeAvailable: false,
    newProgram: null
  };
}

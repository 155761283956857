import { Box, FormLabel, Skeleton, Stack, Typography } from '@mui/material';
import { useAppContext } from 'context';

export default function ClientAge() {
  const { state } = useAppContext();
  const { client } = state;
  return (
    client?.age && (
      <Box p={1} my={2}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <FormLabel sx={{ fontWeight: 600 }}>
            {client?.clientFirstName && 'Client '}Age
          </FormLabel>
        </Stack>
        <Box p={1} sx={{ display: 'flex', alignItems: 'center' }}>
          {!client?.age ? (
            <Skeleton width={100} />
          ) : (
            <Typography variant="h6">{client?.age}</Typography>
          )}
        </Box>
      </Box>
    )
  );
}

import { Close } from '@mui/icons-material';
import { Card, IconButton, Stack, TextField, Typography } from '@mui/material';

export default function RaterSettings({
  onSettingChange,
  settings,
  setShowSettings
}) {
  return (
    <Card
      elevation={0}
      sx={{ my: 1, px: 1, backgroundColor: 'background.settings' }}
    >
      <Stack
        direction="row"
        spacing={1}
        px={1}
        py={1}
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        <IconButton
          size="small"
          onClick={() => setShowSettings(prevShowSettings => !prevShowSettings)}
        >
          <Close color="primary" fontSize="small" />
        </IconButton>
        <Typography variant="caption">Rater Settings</Typography>
      </Stack>
      <Stack direction="row" spacing={1}>
        <TextField
          variant="filled"
          size="small"
          label="Name"
          color="tertiary"
          value={settings.name}
          onChange={event => onSettingChange?.('name', event.target.value)}
        />
        <TextField
          variant="filled"
          size="small"
          sx={{ width: '80px' }}
          type="number"
          label="From"
          color="tertiary"
          value={settings.from}
          onChange={event =>
            onSettingChange?.('from', Number(event.target.value))
          }
        />
        <TextField
          variant="filled"
          size="small"
          sx={{ width: '80px' }}
          type="number"
          label="To"
          color="tertiary"
          value={settings.to}
          onChange={event =>
            onSettingChange?.('to', Number(event.target.value))
          }
        />
      </Stack>
    </Card>
  );
}

import React, { useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import {
  Info,
  KeyboardArrowDown,
  KeyboardArrowLeft
} from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Collapse,
  Divider,
  FormLabel,
  Grid,
  IconButton,
  Stack,
  Tab,
  Tabs
} from '@mui/material';
import { useAppContext } from 'context';

import { DateRangePicker } from 'components/atoms';
import { getIconComponent } from 'components/molecules/IconPicker';

import EntryPanel, { ChartEntryPanel } from './EntryPanel';
import { useInsightsState } from './UseInsightsState';

export default function Insights() {
  const { user } = useAuthenticator(context => [context.user]);
  const { state, dispatch } = useAppContext();
  const [selectedChip, setSelectedChip] = useState(42);
  const isClinician = user.attributes['custom:type'] === 'clinician';

  const {
    entries,
    value,
    to,
    from,
    handleChange,
    handleCheck,
    setTo,
    setFrom,
    togglingAnalyticsByEntry,
    showChipInfo,
    setShowChipInfo
  } = useInsightsState(user);
  const filteredEntries = entries.filter(
    entry => entry.analytics && !entry.complete
  );

  return (
    <Box>
      <Collapse in={Boolean(!state.edit)}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            mt: isClinician ? 1 : 0
          }}
        >
          <Button
            startIcon={
              state.filter ? <KeyboardArrowLeft /> : <KeyboardArrowDown />
            }
            onClick={() =>
              dispatch({ type: 'set_filter', filter: !state.filter })
            }
            color={state.filter ? 'secondary' : 'primary'}
          >
            {state.filter ? 'Hide Filter' : 'Filter'}
          </Button>
        </Box>
      </Collapse>
      <Collapse in={Boolean(state.edit && entries)}>
        <Box pb={1} mb={1}>
          <Stack direction="row" alignItems="center">
            <IconButton
              onClick={() => setShowChipInfo(!showChipInfo)}
              color="primary"
              size="small"
              sx={{ mr: 1 }}
            >
              <Info
                color={showChipInfo ? 'secondary' : 'grey'}
                fontSize="small"
              />
            </IconButton>
            <FormLabel sx={{ fontSize: '0.9em' }}>
              {showChipInfo
                ? 'Click on an Entry to persist it in the Insights menu'
                : 'Entries'}
            </FormLabel>
          </Stack>
          <Box sx={{ my: 1 }} flexDirection="row" direction="row" spacing={1}>
            {entries.map(
              entry =>
                entry.complete !== true && (
                  <Chip
                    key={entry.id}
                    disabled={togglingAnalyticsByEntry[entry.id]}
                    icon={
                      togglingAnalyticsByEntry[entry.id] ? (
                        <CircularProgress
                          size={20}
                          sx={{ position: 'absolute', margin: '0 !important' }}
                        />
                      ) : (
                        getIconComponent(entry.icon)
                      )
                    }
                    label={entry.name}
                    clickable
                    onClick={() => handleCheck(entry)}
                    variant={entry.analytics ? 'filled' : 'outlined'}
                    sx={[
                      { m: 0.5 },
                      togglingAnalyticsByEntry[entry.id] && {
                        '& .MuiChip-label': { opacity: 0.2 }
                      }
                    ]}
                  />
                )
            )}
          </Box>
          <Box pb={1} mt={3}>
            <Divider sx={{ opacity: 0.2 }} />
          </Box>
        </Box>
      </Collapse>

      <Collapse in={Boolean(state.filter || state.edit)}>
        <Box pt={1}>
          <Grid container>
            <Grid item xs={12} sm={6} sx={{ mb: { xs: 2, sm: 0 } }}>
              <Box>
                {entries && (
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    scrollButtons
                    allowScrollButtonsMobile
                    variant="scrollable"
                    aria-label="scrollable force tabs example"
                  >
                    {/* <Tab sx={{ fontWeight: 'bold' }} label="Chart" /> */}
                    <Tab sx={{ fontWeight: 'bold' }} label="Summary" />
                    {filteredEntries.map((entry, index) => (
                      <Tab
                        sx={{ fontWeight: 'bold' }}
                        iconPosition="start"
                        key={entry.id}
                        label={entry.name}
                      />
                    ))}
                  </Tabs>
                )}
              </Box>
            </Grid>

            <Grid item sm={6}>
              <Collapse in={value !== 0}>
                <Box>
                  <DateRangePicker
                    selectedChip={selectedChip}
                    setSelectedChip={setSelectedChip}
                    to={to}
                    setTo={setTo}
                    from={from}
                    setFrom={setFrom}
                  />
                </Box>
              </Collapse>
            </Grid>
          </Grid>
        </Box>
      </Collapse>
      <Box mt={1}>
        {/* <ChartEntryPanel value={value} index={0} /> */}
        <EntryPanel
          value={value}
          to={to}
          from={from}
          index={0}
          entryID="summary"
          state={state}
        />
        {filteredEntries.map((entry, index) => (
          <EntryPanel
            value={value}
            to={to}
            from={from}
            index={index + 1}
            key={entry.id}
            entryID={entry.id}
            state={state}
          />
        ))}
      </Box>
    </Box>
  );
}

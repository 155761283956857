import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Box } from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import { deleteInsight } from 'graphql/mutations';
import mixpanel from 'mixpanel-browser';

import { Chart } from 'components/charts';

export default function Insight({
  disableDrag = false,
  insight,
  setInsights,
  to,
  from,
  key
}) {
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition
  } = useSortable({ id: insight.id });

  const dragHandleProps = {
    ...attributes,
    ...listeners,
    disabled: disableDrag,
    ref: setActivatorNodeRef
  };

  const wrapperStyle = {
    position: 'relative',
    transform: CSS.Translate.toString(transform),
    transition
  };

  const handleDeleteInsight = async () => {
    try {
      await API.graphql(
        graphqlOperation(deleteInsight, {
          input: { id: insight.id, _version: insight._version }
        })
      );
      mixpanel.track('delete_insight');
      window.dataLayer.push({
        event: 'delete_insight'
      });
      setInsights(prevInsights =>
        prevInsights.filter(prevInsight => prevInsight.id !== insight.id)
      );
    } catch (err) {
      console.log('[deleteInsight error]', err);
    }
  };
  return (
    <Box ref={setNodeRef} sx={[wrapperStyle, isDragging && { zIndex: 10 }]}>
      <Chart
        dragHandleProps={dragHandleProps}
        onDelete={handleDeleteInsight}
        preset={insight.type || 'bar'}
        id={insight.id}
        setInsights={setInsights}
        to={to}
        from={from}
        key={key}
      />
    </Box>
  );
}

const timezoneToCountry = {
  // New Zealand Time Zones
  'Pacific/Auckland': 'New Zealand',
  'Pacific/Chatham': 'New Zealand',

  // Australia Time Zones
  'Australia/Sydney': 'Australia',
  'Australia/Melbourne': 'Australia',
  'Australia/Brisbane': 'Australia',
  'Australia/Adelaide': 'Australia',
  'Australia/Perth': 'Australia',
  'Australia/Hobart': 'Australia',
  'Australia/Darwin': 'Australia',
  'Australia/Eucla': 'Australia',

  // Canada Time Zones
  'America/St_Johns': 'Canada',
  'America/Halifax': 'Canada',
  'America/Glace_Bay': 'Canada',
  'America/Moncton': 'Canada',
  'America/Goose_Bay': 'Canada',
  'America/Blanc-Sablon': 'Canada',
  'America/Toronto': 'Canada',
  'America/Nipigon': 'Canada',
  'America/Thunder_Bay': 'Canada',
  'America/Iqaluit': 'Canada',
  'America/Pangnirtung': 'Canada',
  'America/Atikokan': 'Canada',
  'America/Winnipeg': 'Canada',
  'America/Rainy_River': 'Canada',
  'America/Resolute': 'Canada',
  'America/Rankin_Inlet': 'Canada',
  'America/Regina': 'Canada',
  'America/Edmonton': 'Canada',
  'America/Cambridge_Bay': 'Canada',
  'America/Yellowknife': 'Canada',
  'America/Inuvik': 'Canada',
  'America/Creston': 'Canada',
  'America/Dawson_Creek': 'Canada',
  'America/Fort_Nelson': 'Canada',
  'America/Vancouver': 'Canada',
  'America/Whitehorse': 'Canada',
  'America/Dawson': 'Canada',

  // Singapore Time Zone
  'Asia/Singapore': 'Singapore',

  // United Kingdom Time Zones
  'Europe/London': 'United Kingdom',

  // United States Time Zones
  'America/New_York': 'United States',
  'America/Detroit': 'United States',
  'America/Kentucky/Louisville': 'United States',
  'America/Kentucky/Monticello': 'United States',
  'America/Indiana/Indianapolis': 'United States',
  'America/Indiana/Vincennes': 'United States',
  'America/Indiana/Winamac': 'United States',
  'America/Indiana/Marengo': 'United States',
  'America/Indiana/Petersburg': 'United States',
  'America/Indiana/Vevay': 'United States',
  'America/Chicago': 'United States',
  'America/Indiana/Tell_City': 'United States',
  'America/Indiana/Knox': 'United States',
  'America/Menominee': 'United States',
  'America/North_Dakota/Center': 'United States',
  'America/North_Dakota/New_Salem': 'United States',
  'America/North_Dakota/Beulah': 'United States',
  'America/Denver': 'United States',
  'America/Boise': 'United States',
  'America/Phoenix': 'United States',
  'America/Los_Angeles': 'United States',
  'America/Metlakatla': 'United States',
  'America/Anchorage': 'United States',
  'America/Juneau': 'United States',
  'America/Sitka': 'United States',
  'America/Yakutat': 'United States',
  'America/Nome': 'United States',
  'America/Adak': 'United States',
  'Pacific/Honolulu': 'United States',

  // United States territories
  'Pacific/Guam': 'United States',
  'Pacific/Saipan': 'United States',
  'Pacific/Wake': 'United States',
  'America/Puerto_Rico': 'United States',
  'America/Virgin': 'United States',
  'America/St_Thomas': 'United States',
  'Pacific/Pago_Pago': 'United States',

  // Additional zones
  'America/Shiprock': 'United States',
  'America/Fort_Wayne': 'United States',
  'America/Indianapolis': 'United States',
  'Pacific/Johnston': 'United States',
  'America/Swift_Current': 'United States'
};

export function getCountryByTimezone(timezone) {
  const country = timezoneToCountry[timezone];
  if (country) {
    return country.toLowerCase().replace(/\s+/g, '-');
  } else {
    return 'unknown-timezone';
  }
}

export function formatCountryName(country) {
  const words = country.split('-');

  return words
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}

import React, { useCallback, useEffect, useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { Add } from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  Collapse,
  Container,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography
} from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import { getClinic } from 'graphql/customQueries';
import {
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate
} from 'react-router-dom';

import { LoadingAtom } from 'components/atoms';
import { Clinician } from 'components/layout';
import {
  AddClinic,
  AddClinicUser,
  AdminWelcome,
  SearchBar
} from 'components/molecules';
import {
  AllClients,
  PracticeSettings,
  UserSettings
} from 'components/organisms';

import { getHighestPermissionLevel } from 'utils/userRoles';

export default function Admin() {
  const { user } = useAuthenticator(context => [context.user]);
  const clinicID = user.attributes['custom:clinicID'];
  const [clinic, setClinic] = useState({});
  const [clinicians, setClinicians] = useState([]);
  const [locations, setLocations] = useState([]);
  const [openAddClinic, setOpenAddClinic] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [hasClinicians, setHasClinicians] = useState(true);
  const [openNewUser, setOpenNewUser] = useState(false);
  const [searchClient, setSearchClient] = useState('');
  const [selectClients, setSelectClients] = useState(false);
  const [selectedClients, setSelectedClients] = useState([]);
  const [selectClinicians, setSelectClinicians] = useState(false);
  const [selectedClinicians, setSelectedClinicians] = useState(new Map());
  const [openAssignClients, setOpenAssignClients] = useState(false);
  const [openAssignClinicians, setOpenAssignClinicians] = useState(false);
  const [openFilter, setOpenFilter] = useState(true);
  const [view, setView] = useState('permissions');
  const location = useLocation();
  const navigate = useNavigate();
  const highestPermission = getHighestPermissionLevel(user);

  useEffect(() => {
    const fetchClinic = async () => {
      setLoading(true);
      const { data } = await API.graphql(
        graphqlOperation(getClinic, { id: clinicID })
      );
      const clinic = data.getClinic;
      setClinic(clinic);
      const locations = clinic.locations.items;
      setLocations(locations);
      const clinicians = clinic.clinicians.items;
      setClinicians(clinicians);
      const hasClinicians = clinicians !== undefined && clinicians?.length > 0;
      setHasClinicians(hasClinicians);
      setLoading(false);
    };

    if (clinicID) {
      fetchClinic();
    }
    return () => {
      setClinic({});
      setClinicians([]);
      setLocations([]);
    };
  }, [clinicID, openAddClinic]);

  const handleTabChange = useCallback((event, newValue) => {
    setSelectedTab(newValue);
  }, []);

  useEffect(() => {
    const pathParts = location.pathname.split('/').slice(2);
    let newValue = 0;
    if (pathParts[0] === 'team') newValue = 0;
    else if (pathParts[0] === 'clients') newValue = 1;
    else if (pathParts[0] === 'practice') newValue = 2;
    setSelectedTab(newValue);
    if (!location.pathname.includes('/admin/clients')) {
      setSelectClients(false);
      setSelectedClients([]);
    }
    if (!location.pathname.includes('/admin/team')) {
      setSelectClinicians(false);
      setSelectedClinicians(new Map());
    }
    return () => {
      setSelectedTab(0);
      setSelectClients(false);
      setSelectedClients([]);
      setSelectClinicians(false);
      setSelectedClinicians(new Map());
    };
  }, [location]);

  useEffect(() => {
    if (view === 'permissions') {
      setSelectClinicians(false);
      setSelectedClinicians(new Map());
    }
  }, [view]);

  return (
    <Clinician
      props={{ title: 'Admin' }}
      AppBarComponents={
        <Box pl={4} width="100%" display="flex" justifyContent="space-between">
          <Stack
            direction="row"
            spacing={2}
            textOverflow="ellipsis"
            alignItems="center"
          >
            <Typography color="text.primary" variant="h5">
              {clinicID ? 'Manage' : 'Add Your'} Practice
            </Typography>
          </Stack>
          <Stack spacing={2} direction={'row'} alignItems="center">
            {!clinicID && (
              <Button
                onClick={() => setOpenAddClinic(true)}
                size="small"
                startIcon={<Add />}
              >
                Add Practice
              </Button>
            )}

            {location.pathname.includes('clients') && (
              <Collapse in={Boolean(selectClients)} orientation="horizontal">
                <Stack spacing={2} direction={'row'} alignItems="center">
                  <Button
                    disabled={selectedClients.length < 1}
                    onClick={() =>
                      setOpenAssignClients(
                        prevOpenAssignClients => !prevOpenAssignClients
                      )
                    }
                    size="small"
                  >
                    Assign{' '}
                    <Collapse
                      in={selectedClients.length > 0}
                      orientation="horizontal"
                    >
                      <Chip
                        sx={{ ml: 1 }}
                        size="small"
                        label={selectedClients.length}
                      />
                    </Collapse>{' '}
                  </Button>
                  <Button
                    onClick={() => setSelectedClients([])}
                    disabled={selectedClients.length < 1}
                    size="small"
                  >
                    Clear
                  </Button>
                </Stack>
              </Collapse>
            )}
            <Collapse
              in={Boolean(
                location.pathname.includes('/admin/team') &&
                  view === 'location' &&
                  selectClinicians
              )}
              orientation="horizontal"
            >
              <Stack spacing={2} direction={'row'} alignItems="center">
                <Button
                  onClick={() =>
                    setOpenAssignClinicians(
                      prevOpenAssignClinicians => !prevOpenAssignClinicians
                    )
                  }
                  size="small"
                  disabled={selectedClinicians.size < 1}
                  sx={{ whiteSpace: 'nowrap' }}
                >
                  Move{' '}
                  <Collapse
                    in={selectedClinicians.size > 0}
                    orientation="horizontal"
                  >
                    <Chip
                      sx={{ ml: 1 }}
                      size="small"
                      label={selectedClinicians.size}
                    />
                  </Collapse>{' '}
                </Button>
                <Button
                  onClick={() => setSelectedClinicians(new Map())}
                  size="small"
                  disabled={selectedClinicians.size < 1}
                >
                  Clear
                </Button>
              </Stack>
            </Collapse>
            {location.pathname.includes('/admin/team') &&
              view === 'location' && (
                <Tooltip
                  title={
                    highestPermission !== 'admin'
                      ? 'You do not have sufficient permissions. Contact an administrator.'
                      : null
                  }
                >
                  <span>
                    <Button
                      disabled={highestPermission !== 'admin'}
                      size="small"
                      color={selectClinicians ? 'secondary' : 'primary'}
                      onClick={() =>
                        setSelectClinicians(
                          prevSelectClinicians => !prevSelectClinicians
                        )
                      }
                    >
                      Select
                    </Button>
                  </span>
                </Tooltip>
              )}
            {location.pathname.includes('clients') && (
              <Button
                onClick={() =>
                  setSelectClients(prevSelectClients => !prevSelectClients)
                }
                size="small"
                color={selectClients ? 'secondary' : 'primary'}
              >
                {selectClients ? 'Selecting' : 'Select'}
              </Button>
            )}
            {location.pathname.includes('clients') && (
              <SearchBar state={searchClient} setState={setSearchClient} />
            )}
            {location.pathname.includes('/admin/team') ? (
              !clinic?.id ? (
                <Tooltip title="Add a clinic first">
                  <span>
                    <Button disabled size="small" startIcon={<Add />}>
                      New User
                    </Button>
                  </span>
                </Tooltip>
              ) : (
                <Tooltip
                  title={
                    highestPermission !== 'admin'
                      ? 'You do not have sufficient permissions. Contact an administrator.'
                      : null
                  }
                >
                  <span>
                    <Button
                      disabled={highestPermission !== 'admin'}
                      onClick={() =>
                        setOpenNewUser(prevOpenNewUser => !prevOpenNewUser)
                      }
                      size="small"
                      startIcon={<Add />}
                    >
                      New User
                    </Button>
                  </span>
                </Tooltip>
              )
            ) : null}
          </Stack>
        </Box>
      }
    >
      <AddClinicUser
        clinic={clinic}
        clinicians={clinicians}
        setClinicians={setClinicians}
        locations={locations}
        setLocations={setLocations}
        open={openNewUser}
        setOpen={setOpenNewUser}
      />
      <AddClinic
        open={openAddClinic}
        setClose={() => setOpenAddClinic(false)}
      />

      <Container maxWidth="xl">
        <Box>
          <Collapse in={Boolean(clinicID)}>
            <Tabs
              orientation="horizontal"
              variant="scrollable"
              value={selectedTab}
              onChange={handleTabChange}
              sx={{
                fontWeight: 'bold',
                borderBottom: 1,
                borderColor: '#00428233'
              }}
            >
              <Tab
                label="Team"
                value={0}
                onClick={() => navigate('/admin/team')}
                sx={{
                  fontWeight: 'bold'
                }}
              />
              {(highestPermission === 'admin' ||
                highestPermission === 'practicemanager') &&
                clinicID && (
                  <Tab
                    label="Clients"
                    value={1}
                    onClick={() => navigate('/admin/clients')}
                    sx={{
                      fontWeight: 'bold'
                    }}
                  />
                )}
              {highestPermission === 'admin' && (
                <Tab
                  label="Practice"
                  value={2}
                  onClick={() => navigate('/admin/practice')}
                  sx={{
                    fontWeight: 'bold'
                  }}
                />
              )}
            </Tabs>
          </Collapse>
          {loading ? (
            <LoadingAtom />
          ) : (
            <Box>
              <Collapse in={!clinicID}>
                <AdminWelcome
                  clinicID={clinicID}
                  setOpenAddClinic={setOpenAddClinic}
                />
              </Collapse>
              <Outlet />
              <Routes>
                {clinicID && (
                  <Route
                    path="team"
                    element={
                      <TabPanel value={selectedTab} index={0}>
                        <UserSettings
                          open={openNewUser}
                          setOpen={setOpenNewUser}
                          setSelectClinicians={setSelectClinicians}
                          openAssignClinicians={openAssignClinicians}
                          setOpenAssignClinicians={setOpenAssignClinicians}
                          openFilter={openFilter}
                          setOpenFilter={setOpenFilter}
                          selectClinicians={selectClinicians}
                          selectedClinicians={selectedClinicians}
                          setSelectedClinicians={setSelectedClinicians}
                          view={view}
                          setView={setView}
                          locations={locations}
                          setLocations={setLocations}
                          hasClinicians={hasClinicians}
                          clinicians={clinicians}
                          setClinicians={setClinicians}
                          clinic={clinic}
                        />
                      </TabPanel>
                    }
                  />
                )}
                {(highestPermission === 'admin' ||
                  highestPermission === 'practicemanager') &&
                  clinicID && (
                    <Route
                      path="clients"
                      element={
                        <TabPanel value={selectedTab} index={1}>
                          <AllClients
                            openAssignClients={openAssignClients}
                            setOpenAssignClients={setOpenAssignClients}
                            selectClients={selectClients}
                            setSelectClients={setSelectClients}
                            setSelectedClients={setSelectedClients}
                            selectedClients={selectedClients}
                            searchClient={searchClient}
                            clinic={clinic}
                            clinicians={clinicians}
                            locations={locations}
                          />
                        </TabPanel>
                      }
                    />
                  )}
                {clinicID && (
                  <Route
                    path="practice"
                    element={
                      <TabPanel value={selectedTab} index={2}>
                        <PracticeSettings
                          hasClinicians={hasClinicians}
                          clinicians={clinicians}
                          setClinicians={setClinicians}
                          locations={locations}
                          setLocations={setLocations}
                          clinic={clinic}
                          setClinic={setClinic}
                        />
                      </TabPanel>
                    }
                  />
                )}
              </Routes>
            </Box>
          )}
        </Box>
      </Container>
    </Clinician>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

import React, { useCallback, useEffect, useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { useTheme } from '@emotion/react';
import {
  // Delete, DragIndicator,
  Settings
} from '@mui/icons-material';
import { Box, Card, IconButton, Stack } from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import { useAppContext } from 'context';
import { updateInsight } from 'graphql/mutations';
import {
  getInsight,
  getInsightsBubble,
  getInsightsLine,
  getInsightsPie,
  getNotesData
} from 'graphql/queries';
import mixpanel from 'mixpanel-browser';
import moment from 'moment-timezone';

import AreaChart from '../AreaChart';
import BarChart from '../BarChart';
import BubbleChart from '../BubbleChart';
import ChartSettings from '../ChartSettings';
import LineChart from '../LineChart';
import PieChart from '../PieChart';
import RadarChart from '../RadarChart';

export default function Chart({
  // dragHandleProps = {},
  // onDelete,
  preset,
  id,
  setInsights,
  to,
  from
}) {
  const { state } = useAppContext();
  const { user } = useAuthenticator(context => [context.user]);
  const clientID =
    state?.client?.id || user.attributes['custom:clientID'] || user.username;
  const program = state?.client?.program || user.attributes['custom:program'];
  const theme = useTheme();

  const [settingsApplied, setSettingsApplied] = useState(false);
  const [aggregateBy, setAggregateBy] = useState('count');
  const [connectNulls, setConnectNulls] = useState(false);
  const [hasZoom, setHasZoom] = useState(false);
  const [name, setName] = useState('');
  const [scatter, setScatter] = useState(false);
  const [showLabels, setShowLabels] = useState(false);
  const [showNotes, setShowNotes] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [type, setType] = useState(preset || 'line');
  const [records, setRecords] = useState([]);
  const [dataType, setDataType] = useState('all');
  const [data, setData] = useState(null);
  const [notesData, setNotesData] = useState(null);
  const [error, setError] = useState(false);
  const [insight, setInsight] = useState(null);

  const domain = program.includes('camperdown') ? 8 : 10;

  const updateInsightAPI = useCallback(
    async input => {
      const { data } = await API.graphql(
        graphqlOperation(updateInsight, {
          input: { id, _version: insight._version, ...input }
        })
      );
      const updatedInsight = data.updateInsight;
      setInsights(prevInsights => {
        return prevInsights.map(insightItem =>
          insightItem.id === id ? updatedInsight : insightItem
        );
      });
      mixpanel.track('update_insight');
      window.dataLayer.push({
        event: 'update_insight'
      });
    },
    [id, insight, setInsights]
  );

  const handleAggregateChange = useCallback(
    event => {
      const aggregateValue = event.target.value;
      setAggregateBy(aggregateValue);
      setScatter(aggregateValue !== 'average');
      updateInsightAPI({ aggregateBy: aggregateValue });
    },
    [updateInsightAPI]
  );

  const handleConnectNullsChange = useCallback(
    event => {
      const isChecked = event.target.checked;
      setConnectNulls(isChecked);
      updateInsightAPI({ connectNulls: isChecked });
    },
    [updateInsightAPI]
  );

  const handleDataRecordsChange = useCallback(
    dataRecords => {
      setRecords(dataRecords);
      updateInsightAPI({ dataRecords: JSON.stringify(dataRecords) });
    },
    [updateInsightAPI]
  );

  const handleDataTypeChange = useCallback(
    async newDataType => {
      setDataType(newDataType);
      setAggregateBy('count');
      updateInsightAPI({ dataType: newDataType });
      updateInsightAPI({ aggregateBy: 'count' });
    },
    [updateInsightAPI]
  );

  const handleHasZoomChange = useCallback(
    event => {
      const isChecked = event.target.checked;
      setHasZoom(isChecked);
      updateInsightAPI({ zoom: isChecked });
    },
    [updateInsightAPI]
  );

  const handleNameChange = useCallback(
    newName => {
      setName(newName);
      updateInsightAPI({ name: newName });
    },
    [updateInsightAPI]
  );

  const handleScatterChange = useCallback(
    event => {
      const isChecked = event.target.checked;
      setScatter(isChecked);
      updateInsightAPI({ scatter: isChecked });
    },
    [updateInsightAPI]
  );

  const handleShowLabelsChange = useCallback(
    event => {
      const isChecked = event.target.checked;
      setShowLabels(isChecked);
      updateInsightAPI({ showLabels: isChecked });
    },
    [updateInsightAPI]
  );

  const handleShowNotes = useCallback(
    event => {
      const isChecked = event.target.checked;
      setShowNotes(isChecked);
      updateInsightAPI({ showNotes: isChecked });
    },
    [updateInsightAPI]
  );

  const handleTypeChange = useCallback(
    newType => {
      setType(newType);

      if (newType === 'pie') {
        setDataType('all');
        setAggregateBy('count');
        updateInsightAPI({
          dataType: 'all',
          aggregateBy: 'count'
        });
      }
      updateInsightAPI({
        type: newType
      });
    },
    [updateInsightAPI]
  );

  useEffect(() => {
    async function fetchInsight() {
      const { data } = await API.graphql(graphqlOperation(getInsight, { id }));
      setName(data.getInsight.name || name);
      setAggregateBy(data.getInsight.aggregateBy || aggregateBy);
      setHasZoom(data.getInsight.zoom || hasZoom);
      setScatter(data.getInsight.scatter || scatter);
      setShowLabels(data.getInsight.showLabels || showLabels);
      setShowNotes(data.getInsight.showNotes || showNotes);
      setType(data.getInsight.type || type);
      setConnectNulls(data.getInsight.connectNulls || connectNulls);
      setDataType(data.getInsight.dataType || dataType);
      setRecords(JSON.parse(data.getInsight.dataRecords) || records);
      setInsight(data.getInsight);
      setSettingsApplied(true);
    }
    fetchInsight();
    return () => {
      setName('');
      setAggregateBy('count');
      setHasZoom(false);
      setScatter(false);
      setShowLabels(false);
      setShowNotes(false);
      setType('line');
      setConnectNulls(false);
      setDataType('all');
      setRecords([]);
      setSettingsApplied(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    async function query() {
      if (insight?.id) {
        if (to && from) {
          const variables = {
            dateTo: new Date(to).toISOString(),
            dateFrom: new Date(from).toISOString(),
            dataType: dataType,
            clientID: clientID,
            dataRecords: JSON.stringify(records),
            aggregateBy: aggregateBy,
            timeZone: moment.tz.guess()
          };

          try {
            let apiData;
            let notesData;
            if (type !== 'bubble' && type !== 'pie') {
              variables.scatter = scatter;
              variables.type = type;
              variables.showNotes = showNotes;

              apiData = await API.graphql(
                graphqlOperation(getInsightsLine, variables)
              );
              setData(apiData.data.getInsightsLine);

              if (showNotes) {
                notesData = await API.graphql(
                  graphqlOperation(getNotesData, variables)
                );
              } else setNotesData(null);
              setNotesData(notesData?.data.getNotesData);
            } else if (type === 'pie') {
              apiData = await API.graphql(
                graphqlOperation(getInsightsPie, variables)
              );
              setData(JSON.parse(apiData.data.getInsightsPie));
            } else {
              apiData = await API.graphql(
                graphqlOperation(getInsightsBubble, variables)
              );
              setData(apiData.data.getInsightsBubble);
            }
            setError(false);
          } catch (error) {
            console.error('Error fetching insights:', error);
            if (error.errors) {
              setError(true);
            }
          }
        }
      }
    }
    query();
    return () => {
      setData(null);
      setNotesData(null);
      setError(false);
    };
  }, [
    clientID,
    dataType,
    aggregateBy,
    records,
    type,
    to,
    from,
    scatter,
    insight,
    showNotes
  ]);

  let ChartComponent;
  switch (type) {
    case 'line':
      ChartComponent = LineChart;
      break;
    case 'area':
      ChartComponent = AreaChart;
      break;
    case 'bar':
      ChartComponent = BarChart;
      break;
    case 'bubble':
      ChartComponent = BubbleChart;
      break;
    case 'pie':
      ChartComponent = PieChart;
      break;
    case 'radar':
      ChartComponent = RadarChart;
      break;
    default:
      ChartComponent = LineChart;
  }

  return (
    <Card
      elevation={0}
      sx={{
        my: 2,
        p: 2,
        background: state.edit
          ? theme.palette.background.default
          : theme.palette.background.paper
      }}
    >
      {state.edit && (
        <Stack direction="row" justifyContent="space-between">
          <IconButton
            color={showSettings ? 'secondary' : 'primary'}
            onClick={() => setShowSettings(!showSettings)}
          >
            <Settings />
          </IconButton>
          <Box>
            {/* <IconButton onClick={onDelete}>
              <Delete />
            </IconButton>
            <IconButton {...dragHandleProps}>
              <DragIndicator />
            </IconButton> */}
          </Box>
        </Stack>
      )}
      {state.edit && showSettings && (
        <ChartSettings
          connectNulls={connectNulls}
          type={type}
          setType={handleTypeChange}
          showSettings={showSettings}
          setShowSettings={setShowSettings}
          aggregateBy={aggregateBy}
          hasZoom={hasZoom}
          scatter={scatter}
          onScatterChange={handleScatterChange}
          onAggregateChange={handleAggregateChange}
          onZoomChange={handleHasZoomChange}
          handleConnectNullsChange={handleConnectNullsChange}
          name={name}
          setName={handleNameChange}
          onShowLabelsChange={handleShowLabelsChange}
          showLabels={showLabels}
          onShowNotesChange={handleShowNotes}
          showNotes={showNotes}
          initialDataType={dataType}
          onDataTypeChange={handleDataTypeChange}
          onDataRecordsChange={handleDataRecordsChange}
          setData={setData}
          data={data}
          dataRecords={records}
          dataType={dataType}
        />
      )}
      {settingsApplied && (
        <ChartComponent
          notesData={notesData}
          error={error}
          data={data}
          aggregateBy={aggregateBy}
          connectNulls={connectNulls}
          hasZoom={hasZoom}
          scatter={scatter}
          showLabels={showLabels}
          type={type}
          domain={domain}
          name={name}
        />
      )}
    </Card>
  );
}

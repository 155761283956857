import React, { useRef, useState } from 'react';

import { Box, Stack, TextField, Typography } from '@mui/material';

const AuthCode = ({
  length,
  label,
  loading,
  onComplete,
  error,
  helperText
}) => {
  const [code, setCode] = useState([...Array(length)].map(() => ''));
  const inputs = useRef([]);

  const onPaste = e => {
    const pasteData = e.clipboardData.getData('text/plain');
    if (pasteData.length === length && /^[0-9]+$/.test(pasteData)) {
      const newCode = Array.from(pasteData);
      setCode(newCode);
      onComplete(pasteData);
    }
  };

  const processInput = (e, slot) => {
    const input = e.target.value;
    const num = input.slice(-1); // take the last digit to keep existing behavior for single digit

    if (/[^0-9]/.test(input)) return;

    if (input.length === length) {
      const newCode = [...input];
      setCode(newCode);
      onComplete(newCode.join(''));
      return;
    }

    const newCode = [...code];
    newCode[slot] = num;
    setCode(newCode);
    if (slot !== length - 1 && inputs.current[slot + 1]) {
      inputs.current[slot + 1].focus();
    }
    if (newCode.every(num => num !== '')) {
      onComplete(newCode.join(''));
    }
  };

  const onKeyUp = (e, slot) => {
    if (e.keyCode === 8 && !code[slot] && slot !== 0) {
      const newCode = [...code];
      newCode[slot - 1] = '';
      setCode(newCode);
      inputs.current[slot - 1].focus();
      onComplete(null);
    }
  };

  return (
    <Box sx={{ py: 1 }}>
      <Typography variant="h6" gutterBottom>
        {label}
      </Typography>
      <Stack spacing={1} direction="row">
        {code.map((num, idx) => {
          return (
            <TextField
              error={error}
              key={idx}
              variant="outlined"
              inputProps={{
                inputMode: 'numeric',
                maxLength: 1,
                style: { fontSize: '24px', textAlign: 'center' }
              }}
              value={num}
              autoFocus={!code[0].length && idx === 0}
              disabled={loading}
              onChange={e => processInput(e, idx)}
              onKeyUp={e => onKeyUp(e, idx)}
              onPaste={onPaste}
              inputRef={ref => (inputs.current[idx] = ref)}
              sx={{ width: '16%', textAlign: 'center' }}
            />
          );
        })}
      </Stack>
    </Box>
  );
};

export default AuthCode;

import { Box, Stack, Typography } from '@mui/material';
import { createBrowserRouter } from 'react-router-dom';
import Entries from 'routes/client/entries';
import Entry from 'routes/client/entry';
import Record from 'routes/client/record';
import Sessions from 'routes/client/sessions';
import Session from 'routes/client/sessions/session';
import ClinicianHome from 'routes/clinician/home';

import { Client as ClientLayout, Clinician } from 'components/layout';
import { ErrorBoundary } from 'components/layout';

import ClientHome from './client/home';
import Insights from './client/insights';
import LinkClinician from './client/link';
import ClientResources from './client/resources';
import ClientResource from './client/resources/resource';
import AddClient from './clinician/add';
import Admin from './clinician/admin';
import AdminLink from './clinician/admin/link';
import Client from './clinician/client';
import LinkClient from './clinician/link';
import Resources from './clinician/resources';
import Resource from './clinician/resources/resource';
import Support from './clinician/support';
import Templates from './clinician/templates';
import Settings from './common/settings';
// eslint-disable-next-line
// import UpdateInProgress from './common/update';
import Welcome from './common/welcome';

export const onboardingRoutes = createBrowserRouter([
  {
    children: [
      {
        path: '/',
        element: <Welcome />
      }
    ]
  }
]);

export const clinicianRoutes = createBrowserRouter([
  {
    errorElement: (
      <Clinician props={{ title: 'Whoopsy daisy!' }}>
        <ErrorBoundary />
      </Clinician>
    ),
    children: [
      {
        path: '/welcome',
        element: <Welcome />
      },
      {
        path: '/',
        element: (
          // <UpdateInProgress />
          <ClinicianHome />
        )
      },
      {
        path: `/add`,
        element: <AddClient />
      },
      {
        path: '/admin/*',
        element: <Admin />
      },

      {
        path: '/settings',
        element: (
          <Clinician
            props={{ title: 'Account Settings' }}
            AppBarComponents={
              <Box
                pl={4}
                width="100%"
                display="flex"
                justifyContent="space-between"
              >
                <Stack direction="row" spacing={2}>
                  <Typography color="text.primary" variant="h5">
                    Account Settings
                  </Typography>
                </Stack>
              </Box>
            }
          >
            <Settings />
          </Clinician>
        )
      },
      {
        path: '/link/:id',
        element: (
          <Clinician
            props={{ title: 'Account Settings' }}
            AppBarComponents={
              <Box
                pl={4}
                width="100%"
                display="flex"
                justifyContent="space-between"
              >
                <Stack direction="row" spacing={2}>
                  <Typography color="text.primary" variant="h5">
                    Link
                  </Typography>
                </Stack>
              </Box>
            }
          >
            <LinkClient />
          </Clinician>
        )
      },
      {
        path: '/client/*',
        element: <Client />
      },
      {
        path: '/templates',
        element: <Templates />
      },
      {
        path: '/resources',
        element: <Resources />
      },
      {
        path: '/resource/:id',
        element: <Resource />
      },
      {
        path: '/admin/link/:id',
        element: <AdminLink />
      },
      {
        path: '/support',
        element: <Support />
      }
    ]
  }
]);

export const clientRoutes = createBrowserRouter([
  {
    errorElement: (
      <ClientLayout props={{ title: 'Whoopsy daisy!' }}>
        <ErrorBoundary />
      </ClientLayout>
    ),
    children: [
      {
        path: '/welcome',
        element: <Welcome />
      },
      {
        path: '/',
        element: (
          // <UpdateInProgress />
          <ClientLayout props={{ title: 'Home - SpeechFit' }}>
            <ClientHome />
          </ClientLayout>
        )
      },
      {
        path: '/entry/:id',
        element: (
          <ClientLayout props={{ title: 'New Entry' }}>
            <Entry />
          </ClientLayout>
        )
      },
      {
        path: '/entries',
        element: (
          <ClientLayout props={{ title: 'Entries - SpeechFit' }}>
            <Entries />
          </ClientLayout>
        )
      },
      {
        path: '/entries/:id',
        element: (
          <ClientLayout props={{ title: 'Entry - SpeechFit' }}>
            <Record />
          </ClientLayout>
        )
      },
      {
        path: '/insights/',
        element: (
          <ClientLayout props={{ title: 'Insights - SpeechFit' }}>
            <Insights />
          </ClientLayout>
        )
      },
      {
        path: '/sessions',
        element: (
          <ClientLayout props={{ title: 'Sessions - SpeechFit' }}>
            <Sessions />
          </ClientLayout>
        )
      },
      {
        path: '/session/:id',
        element: (
          <ClientLayout props={{ title: 'Sessions' }}>
            <Session />
          </ClientLayout>
        )
      },
      {
        path: '/resources',
        element: (
          <ClientLayout props={{ title: 'Resources' }}>
            <ClientResources />
          </ClientLayout>
        )
      },
      {
        path: '/resource/:id',
        element: (
          <ClientLayout props={{ title: 'Resources' }}>
            <ClientResource />
          </ClientLayout>
        )
      },
      {
        path: '/link/:id',
        element: (
          <ClientLayout props={{ title: 'Link Clinician' }}>
            <LinkClinician />
          </ClientLayout>
        )
      },
      {
        path: '/settings',
        element: (
          <ClientLayout props={{ title: 'Account Settings' }}>
            <Settings />
          </ClientLayout>
        )
      }
    ]
  }
]);

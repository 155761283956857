import { useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { Box, Grid, Tab, Tabs, useMediaQuery, useTheme } from '@mui/material';

import { TabPanel } from 'components/atoms';
import {
  AccountEmail,
  AccountName,
  AccountPassword,
  ImportClient,
  ProfilePicture
} from 'components/molecules';
import { ClientAccess, Security, TreatmentDetails } from 'components/organisms';

export default function ClientSettings() {
  const { user } = useAuthenticator(context => [context.user]);
  const isClinician = user.attributes['custom:type'] === 'clinician';
  const isParticipant = Boolean(user.attributes['custom:clientID']);
  const [tabValue, setTabValue] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const tabOrientation = isMobile ? 'horizontal' : 'vertical';
  const tabWidth = isMobile ? '100%' : '200px';
  const tabPanelWidth = isMobile ? '100%' : 'calc(100% - 200px)';

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          height: '100%',
          flexDirection: isMobile ? 'column' : 'row'
        }}
      >
        <Tabs
          orientation={tabOrientation}
          variant="scrollable"
          scrollButtons="auto"
          value={tabValue}
          onChange={handleTabChange}
          aria-label="Vertical tabs for settings"
          sx={{
            borderRight: isMobile ? 0 : 1,
            borderBottom: isMobile ? 1 : 0,
            borderColor: '#00428233',
            width: tabWidth
          }}
        >
          <Tab sx={{ fontWeight: 600 }} label="Account" />
          <Tab
            sx={{ fontWeight: 600 }}
            label={isClinician ? 'Security' : !isParticipant ? 'Program' : null}
          />
          {!isClinician && !isParticipant && (
            <Tab sx={{ fontWeight: 600 }} label="Access" />
          )}
        </Tabs>
        <Box sx={{ flexGrow: 1, width: tabPanelWidth }}>
          <TabPanel value={tabValue} index={0}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <AccountName />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <AccountEmail />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <AccountPassword />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <ProfilePicture />
              </Grid>
              {!isClinician && (
                <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
                  <ImportClient />
                </Grid>
              )}
            </Grid>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            {isClinician ? (
              <Security />
            ) : !isParticipant ? (
              <TreatmentDetails />
            ) : null}
          </TabPanel>
          {!isClinician && !isParticipant && (
            <TabPanel value={tabValue} index={2}>
              <ClientAccess />
            </TabPanel>
          )}
        </Box>
      </Box>
    </>
  );
}

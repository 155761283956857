import {
  AddPhotoAlternate,
  LinkSharp,
  Lock,
  LockOpen
} from '@mui/icons-material';
import {
  Box,
  Button,
  ButtonGroup,
  FilledInput,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip
} from '@mui/material';
import { programs } from 'common/constants';

import { formatProgram } from 'utils/formatProgram';

export default function StepOne(props) {
  const {
    title,
    setTitle,
    subtitle,
    setSubtitle,
    accessType,
    setAccessType,
    type,
    setType,
    category,
    setCategory,
    condition,
    setCondition,
    program,
    setProgram,
    sourceName,
    setSourceName,
    sourceLink,
    setSourceLink,
    handleImageUpload,
    handleClearImage,
    imagePreviewUrl,
    errors,
    validateField,
    submissionAttempted,
    setSubmissionAttempted,
    previewText,
    setPreviewText
  } = props;

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={accessType}
            onChange={e => setAccessType(e.target.value)}
          >
            <FormControlLabel
              value="public"
              control={<Radio checkedIcon={<LockOpen />} icon={<LockOpen />} />}
              label="Public"
            />
            <Tooltip title="Please contact us to enable your private library.">
              <FormControlLabel
                disabled
                value="private"
                control={<Radio checkedIcon={<Lock />} icon={<Lock />} />}
                label="Private"
              />
            </Tooltip>
          </RadioGroup>
        </FormControl>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <TextField
            label="Title"
            fullWidth
            size="small"
            variant="filled"
            value={title}
            onChange={e => {
              setTitle(e.target.value);
              if (submissionAttempted) {
                validateField('title', e.target.value);
              }
              setSubmissionAttempted(false);
            }}
            error={submissionAttempted && !!errors.title}
            helperText={submissionAttempted && errors.title}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label="Subtitle"
            fullWidth
            size="small"
            variant="filled"
            value={subtitle}
            onChange={e => setSubtitle(e.target.value)}
          />
        </Grid>
      </Grid>
      <Grid sx={{ my: 1 }} container spacing={2}>
        <Grid item xs={12} md={3}>
          <FormControl
            error={submissionAttempted && !!errors.type}
            size="small"
            fullWidth
            variant="filled"
          >
            <InputLabel>Type of Resource</InputLabel>
            <Select
              value={type}
              onChange={e => {
                setType(e.target.value);
                if (submissionAttempted) {
                  validateField('type', e.target.value);
                }
                setSubmissionAttempted(false);
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={'video'}>Video</MenuItem>
              <MenuItem value={'pdf'}>PDF</MenuItem>
              <MenuItem value={'image'}>Image</MenuItem>
            </Select>
            {submissionAttempted && errors.type && (
              <FormHelperText>{errors.type}</FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControl
            error={submissionAttempted && !!errors.category}
            size="small"
            fullWidth
            variant="filled"
          >
            <InputLabel>Category</InputLabel>
            <Select
              value={category}
              onChange={e => {
                setCategory(e.target.value);
                if (submissionAttempted) {
                  validateField('category', e.target.value);
                }
                setSubmissionAttempted(false);
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={'sample'}>Sample</MenuItem>
              <MenuItem value={'training-model'}>Training Model</MenuItem>
              <MenuItem value={'exercise'}>Exercise</MenuItem>
              <MenuItem value={'game'}>Game</MenuItem>
              <MenuItem value={'other'}>Other</MenuItem>
            </Select>
            {submissionAttempted && errors.category && (
              <FormHelperText>{errors.category}</FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControl size="small" fullWidth variant="filled">
            <InputLabel>Condition</InputLabel>
            <Select
              value={condition}
              onChange={e => setCondition(e.target.value)}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={'stuttering'}>Stuttering</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControl size="small" fullWidth variant="filled">
            <InputLabel>Program</InputLabel>
            <Select
              value={program}
              onChange={e => {
                setProgram(e.target.value);
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {programs.map(program => {
                const key = Object.keys(program)[0];
                const value = program[key];
                return (
                  <MenuItem key={value} value={value}>
                    {formatProgram(key)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid sx={{ my: 1 }} container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            error={submissionAttempted && !!errors.sourceName}
            helperText={submissionAttempted && errors.sourceName}
            size="small"
            variant="filled"
            fullWidth
            label="Source Name"
            value={sourceName}
            onChange={e => {
              setSourceName(e.target.value);
              if (submissionAttempted) {
                validateField('sourceName', e.target.value);
              }
              setSubmissionAttempted(false);
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl size="small" fullWidth variant="filled">
            <InputLabel>Source Link</InputLabel>
            <FilledInput
              value={sourceLink}
              onChange={e => {
                setSourceLink(e.target.value);
              }}
              type="text"
              endAdornment={
                <InputAdornment position="end">
                  <LinkSharp />
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
      </Grid>
      {type === 'text' && !imagePreviewUrl && (
        <Grid sx={{ my: 1 }} container spacing={2}>
          <Grid item xs={12}>
            <TextField
              variant="filled"
              fullWidth
              value={previewText}
              onChange={e => setPreviewText(e.target.value)}
              label="Preview Text"
              size="small"
              minRows={3}
              multiline
            />
          </Grid>
        </Grid>
      )}
      {!previewText && (
        <Grid sx={{ my: 1 }} container spacing={2}>
          <Grid item xs={6}>
            <ButtonGroup>
              <label htmlFor="contained-button-file">
                <Button
                  size="small"
                  variant="contained"
                  component="span"
                  startIcon={<AddPhotoAlternate />}
                >
                  Cover Image
                </Button>
                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="contained-button-file"
                  type="file"
                  onChange={e => {
                    handleImageUpload(e);
                    if (submissionAttempted) {
                      validateField('image', e.target.files[0]);
                    }
                    setSubmissionAttempted(false);
                  }}
                />
              </label>
              {imagePreviewUrl && (
                <Button
                  onClick={handleClearImage}
                  size="small"
                  variant="outlined"
                >
                  Clear
                </Button>
              )}
            </ButtonGroup>
            {submissionAttempted && errors.image && (
              <FormHelperText error>{errors.image}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={6}>
            {imagePreviewUrl && (
              <img
                src={imagePreviewUrl}
                alt="Selected"
                style={{ maxWidth: '100%', maxHeight: '300px' }}
              />
            )}
          </Grid>
        </Grid>
      )}
    </Box>
  );
}

export function adjustAlpha(rgba, newAlpha) {
  const regex = /^rgba\((\d+),\s*(\d+),\s*(\d+),\s*(\d*(?:\.\d+)?)\)$/;
  const matches = rgba.match(regex);

  if (matches) {
    const r = parseInt(matches[1], 10);
    const g = parseInt(matches[2], 10);
    const b = parseInt(matches[3], 10);

    newAlpha = Math.max(0, Math.min(1, newAlpha)); // Clamping between 0 and 1

    return `rgba(${r}, ${g}, ${b}, ${newAlpha.toFixed(2)})`;
  }

  return rgba; // return the original value if the format is not recognized
}

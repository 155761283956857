import React, { useRef, useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { useTheme } from '@emotion/react';
import {
  Add, // Delete,
  //  DragIndicator,
  Download
} from '@mui/icons-material';
import {
  Box,
  Card,
  CardActionArea,
  FormLabel,
  Grid,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  Skeleton,
  Stack,
  TextField,
  Tooltip
} from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import { useAppContext } from 'context';
import { useSnackbar } from 'context/SnackBar';
import { updateInsightsSection } from 'graphql/mutations';
import html2canvas from 'html2canvas';
import _ from 'lodash';

export default function ChartContainer({
  grid,
  children,
  // dragHandleProps = {},
  // onDelete,
  onCreate,
  onGridChange,
  insightsSection
}) {
  const downloadButtonRef = useRef(null);
  const { state } = useAppContext();
  const { user } = useAuthenticator(context => [context.user]);
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [name, setName] = useState(insightsSection.name || null);
  const [insightsSectionVersion, setInsightsSectionVersion] = useState(
    insightsSection._version
  );
  const firstName =
    state?.client?.clientFirstName ||
    state?.client?.firstName ||
    user.attributes.given_name;
  const lastName =
    state?.client?.clientLastName ||
    state?.client?.lastName ||
    user.attributes.family_name;

  const handleChangeGrid = newGrid => {
    onGridChange?.(newGrid);
  };

  const updateName = async newName => {
    setName(newName);
    const { data } = await API.graphql(
      graphqlOperation(updateInsightsSection, {
        input: {
          id: insightsSection.id,
          name: newName,
          _version: insightsSectionVersion
        }
      })
    );
    const updatedInsightsSectionVersion = data.updateInsightsSection._version;
    setInsightsSectionVersion(updatedInsightsSectionVersion);
  };

  // download utils

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const chartRef = useRef(null);

  const handleDownload = async imageType => {
    try {
      if (downloadButtonRef.current) {
        downloadButtonRef.current.style.visibility = 'hidden';
      }
      const canvas = await html2canvas(chartRef.current);
      const image = canvas.toDataURL(`image/${imageType}`);
      const link = document.createElement('a');
      link.href = image;
      link.download = `chart-${insightsSection.name.toLowerCase()}-${firstName.toLowerCase()}-${lastName.toLowerCase()}.${imageType}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      enqueueSnackbar('Downloaded chart', { severity: 'success' });
    } catch (err) {
      enqueueSnackbar('Error downloading chart', { severity: 'error' });
      console.log('error', err);
    } finally {
      if (downloadButtonRef.current) {
        downloadButtonRef.current.style.visibility = 'visible';
      }
    }
  };

  // end download utils

  return (
    <Card
      ref={chartRef}
      sx={{
        p: 1,
        mb: 4,
        background: theme.palette.background.paper
      }}
      elevation={4}
    >
      {state.edit && (
        <Stack sx={{ m: 1 }} direction="row" justifyContent="space-between">
          <Box sx={{ display: 'flex' }}>
            <Card
              elevation={grid === 'row' ? 0 : 2}
              sx={{
                mr: 2,
                display: 'flex',
                backgroundColor:
                  grid === 'row' ? 'background.settings' : 'background.default'
              }}
            >
              <CardActionArea
                onClick={() => handleChangeGrid('row')}
                sx={{ px: 1 }}
              >
                <Skeleton animation={false} width={40} />
              </CardActionArea>
            </Card>
            <Card
              elevation={grid === 'column' ? 0 : 2}
              sx={{
                display: 'flex',
                backgroundColor:
                  grid === 'column'
                    ? 'background.settings'
                    : 'background.default'
              }}
            >
              <CardActionArea
                onClick={() => handleChangeGrid('column')}
                sx={{ display: 'flex', px: 1 }}
              >
                <Skeleton animation={false} sx={{ mr: 1 }} width={20} />
                <Skeleton animation={false} width={20} />
              </CardActionArea>
            </Card>
            <TextField
              sx={{ ml: 2 }}
              value={name}
              size="small"
              onChange={e => updateName(e.target.value)}
              label="Section Name"
            />
          </Box>
          <Box>
            {/* <IconButton onClick={onDelete}>
              <Delete />
            </IconButton>
            <IconButton {...dragHandleProps}>
              <DragIndicator />
            </IconButton> */}
          </Box>
        </Stack>
      )}

      <Grid container spacing={2} alignItems="center">
        {!state.edit && (
          <Grid item xs={12}>
            <Box pl={2} pt={1}>
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                justifyContent="space-between"
              >
                <FormLabel sx={{ color: 'text.accent', fontWeight: 'bold' }}>
                  {name || insightsSection.name}
                </FormLabel>
                <Hidden implementation="css" mdDown>
                  <Box>
                    <Tooltip title="Download chart" placement="top">
                      <span ref={downloadButtonRef}>
                        <IconButton size="small" onClick={handleMenuClick}>
                          <Download fontSize="small" />
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleMenuClose}
                    >
                      <MenuItem onClick={() => handleDownload('png')}>
                        Download as PNG
                      </MenuItem>
                      <MenuItem onClick={() => handleDownload('jpeg')}>
                        Download as JPG
                      </MenuItem>
                    </Menu>
                  </Box>
                </Hidden>
              </Stack>
            </Box>
          </Grid>
        )}

        {React.Children.map(children, (child, index) => (
          <Grid
            item
            sm={grid === 'row' ? 12 : 6}
            xs={12}
            key={index}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              minHeight: 0
            }}
          >
            {React.cloneElement(child, { grid })}
          </Grid>
        ))}
        {state.edit &&
          grid === 'column' &&
          _.range(2 - React.Children.count(children)).map(index => (
            <Grid
              key={index}
              item
              xs={6}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                minHeight: 0
              }}
            >
              <Card elevation={state.edit ? 2 : 0} sx={{ flexGrow: 1 }}>
                <CardActionArea
                  onClick={onCreate}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                    height: '100%',
                    p: 2
                  }}
                >
                  <Add />
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        {state.edit &&
          grid === 'row' &&
          React.Children.count(children) === 0 && (
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                minHeight: 0
              }}
            >
              <Card elevation={state.edit ? 2 : 0} sx={{ flexGrow: 1 }}>
                <CardActionArea
                  onClick={onCreate}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                    height: '100%',
                    p: 2
                  }}
                >
                  <Add />
                </CardActionArea>
              </Card>
            </Grid>
          )}
      </Grid>
    </Card>
  );
}

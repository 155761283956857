import React, { useCallback, useMemo, useState } from 'react';

import { Box, Collapse, Hidden } from '@mui/material';
import { useAppContext } from 'context';
import _ from 'lodash';

import { RaterLabel, RaterOptions, RaterSettings } from 'components/atoms';

import InfoSection from '../InfoSection';

const Rater = ({
  userInputID,
  dragHandleProps,
  initialRaterData,
  name,
  from,
  to,
  onDataChange,
  onDelete,
  onSettingChange,
  component
}) => {
  const { state } = useAppContext();

  const [data, setData] = useState(initialRaterData);
  const [openInfo, setOpenInfo] = useState(false);
  const [settings, setSettings] = useState({ name, from, to });
  const [showSettings, setShowSettings] = useState(false);

  const ratingOptions = useMemo(
    () => Array.from({ length: to - from + 1 }, (_, i) => i + from),
    [from, to]
  );

  const handleDataChange = useCallback(
    option => {
      setData(option);
      onDataChange?.({ raterValue: option }, userInputID);
    },
    [userInputID, onDataChange]
  );

  const handleSettingChange = (settingName, settingValue) => {
    setSettings(prevSettings =>
      _.set({ ...prevSettings }, settingName, settingValue)
    );
    onSettingChange?.(settingName, settingValue);
  };

  return (
    <Box>
      <RaterLabel
        dragHandleProps={dragHandleProps}
        label={settings.name}
        showInfo={component?.showInfo}
        handleOpenInfoClick={() => setOpenInfo(prevOpenInfo => !prevOpenInfo)}
        openInfo={openInfo}
        setShowSettings={setShowSettings}
        showSettings={showSettings}
        state={state}
        onDelete={onDelete}
      />
      <Collapse in={state.edit && showSettings}>
        <Hidden smDown implementation="css">
          <RaterSettings
            onSettingChange={handleSettingChange}
            settings={settings}
            setShowSettings={setShowSettings}
          />
        </Hidden>
      </Collapse>
      <Collapse in={openInfo}>
        <InfoSection component={component} />
      </Collapse>
      <RaterOptions
        options={ratingOptions}
        data={data}
        onDataClick={handleDataChange}
      />
    </Box>
  );
};

export default Rater;

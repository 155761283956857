export default function colorMapper({ data }) {
  const colors = [
    '#57bb8a',
    '#78c083',
    '#9bc57c',
    '#bbca76',
    '#ddd06e',
    '#fdd666',
    '#fbb95d',
    '#f79d53',
    '#f37f48',
    '#ef613f',
    '#ea4335'
  ];

  return colors[data] || null;
}

import React from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import {
  Add,
  ChevronLeft,
  ChevronRight,
  DynamicForm,
  ExitToApp,
  ExpandLess,
  ExpandMore,
  Groups3,
  Help,
  Settings,
  SportsGymnastics
} from '@mui/icons-material';
import {
  Box,
  Collapse,
  CssBaseline,
  Divider,
  Hidden,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Toolbar,
  Tooltip,
  useMediaQuery
} from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import MuiDrawer from '@mui/material/Drawer';
import { styled, useTheme } from '@mui/material/styles';
import { Auth } from 'aws-amplify';
import { useAppContext } from 'context';
import { useLocation, useNavigate } from 'react-router-dom';

import { Logo, ProfileAvatar } from 'components/atoms';

const drawerWidth = 240;

const openedMixin = theme => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
});

const closedMixin = theme => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  },
  [theme.breakpoints.down('md')]: {
    // this line and below is the addition
    width: 0
  }
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open'
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open'
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}));

export default React.memo(function ClinicianDrawer({
  open,
  AppBarComponents,
  handleDrawer
}) {
  const navigate = useNavigate();
  const { dispatch, state } = useAppContext();
  const { user } = useAuthenticator(context => [context.user]);
  const theme = useTheme();
  const location = useLocation();
  const isScreenSmall = useMediaQuery(theme.breakpoints.down('md'));

  const toggleAddClient = () => {
    dispatch({ type: 'set_modal', modal: true });
  };

  const handleSignOut = async () => {
    try {
      dispatch({ type: 'clear_all' });
      await Auth.signOut();
      navigate('/');
    } catch (err) {
      console.log('error signing out...', err);
    }
  };

  return (
    <Box>
      <CssBaseline />
      <AppBar
        sx={{
          zIndex: 2,
          borderBottom: '1px solid rgba(0, 66, 130, 0.2)',
          backgroundColor: 'rgba(247, 247, 247, 0.65)',
          backdropFilter: 'blur(5px)'
        }}
        position="fixed"
        open={open}
        elevation={0}
      >
        <Toolbar disableGutters>
          {!open && (
            <Box
              sx={{
                ml: { xs: 2, md: 0 },
                transition: theme.transitions.create('margin', {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.leavingScreen
                })
              }}
            >
              <Logo smallScreen={isScreenSmall} variant="icon" />
            </Box>
          )}
          <Hidden implementation="css" mdDown>
            <Box
              onClick={handleDrawer}
              sx={{
                height: { xs: '56px', sm: '58px' },
                width: '18px',
                display: 'inline-block',
                marginLeft: !open ? 1.7 : 0,
                cursor: 'pointer'
              }}
            >
              <svg
                width="100%"
                height="100%"
                xmlns="http://www.w3.org/2000/svg"
                fill={theme.palette.primary.dark}
                viewBox="0 0 24 74"
                preserveAspectRatio="xMidYMid meet"
                style={{ display: 'block' }}
              >
                <g>
                  <path d="m0,-0.23275l-0.15493,-0.15655l1.85366,2.99871c2.14811,3.37007 4.58454,5.16264 9.98812,7.8184c0.28591,0.14057 0.56778,0.27872 0.84622,0.4152l0.09645,0.04726c3.26776,1.60158 6.11938,2.99929 8.137,4.69906c1.88087,1.58457 3.00264,3.39547 3.27292,5.84035l0.06395,0l0,32.89482c0,2.99912 -1.10474,5.10953 -3.19064,6.91874c-2.02645,1.75763 -4.93521,3.18326 -8.28012,4.82262l-0.10038,0.04918c-0.27817,0.13631 -0.55976,0.27439 -0.84539,0.4148c-5.40358,2.65574 -7.33502,3.49593 -10.16606,8.04007l-1.38304,2.37589l-0.13775,-0.15655l0,-77.02201z" />
                </g>
                {open ? (
                  <ChevronLeft color="primary.lightest" />
                ) : (
                  <ChevronRight color="primary.lightest" />
                )}
              </svg>
            </Box>
          </Hidden>
          <Box sx={{ mr: { xs: 2 }, width: '100%' }}>{AppBarComponents}</Box>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          '& .MuiDrawer-paper': { border: 'none' }
        }}
        variant="permanent"
        open={open}
      >
        <Paper
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
            background: theme.palette.primary.dark
          }}
          elevation={2}
        >
          <Box>
            <DrawerHeader>
              <Box sx={{ ml: { xs: 0.5, sm: 1 } }}>
                {open ? <Logo /> : <Logo variant="icon" />}
              </Box>
            </DrawerHeader>
            <Divider sx={{ borderBottom: '1px solid rgba(0, 66, 130, 0.2)' }} />
            <List>
              {[
                { name: 'Clients', link: '/' },
                { name: 'Add Client' },
                { name: 'Resources', link: '/resources' },
                { name: 'Templates', link: '/templates' }
                // { name: 'Reports', link: '/reports' }
              ].map((item, index) => (
                <ListItem
                  key={item.name}
                  disablePadding
                  sx={{ display: 'block' }}
                >
                  <Tooltip title={open ? null : item.name} placement="right">
                    <ListItemButton
                      selected={
                        (location.pathname === item.link && !state.modal) ||
                        (item.name === 'Add Client' && state.modal) ||
                        (location.pathname.includes('/resource') &&
                          item.link === '/resources' &&
                          !location.pathname.includes('client/resource') &&
                          !state.modal)
                      }
                      onClick={() => {
                        if (item.name === 'Add Client') {
                          toggleAddClient();
                        } else {
                          navigate(item.link || '/');
                        }
                        if (isScreenSmall) {
                          handleDrawer();
                        }
                      }}
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                        '&.Mui-selected': {
                          backgroundColor: theme.palette.primary.darkest,
                          '&:hover': {
                            backgroundColor: theme.palette.primary.darkHover
                          }
                        },
                        '&:hover': {
                          backgroundColor: theme.palette.primary.darkHover
                        }
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : 'auto',
                          justifyContent: 'center',
                          color: theme.palette.primary.lightest
                        }}
                      >
                        {index === 0 && <Groups3 />}
                        {index === 1 && <Add />}
                        {index === 2 && <SportsGymnastics />}
                        {index === 3 && <DynamicForm />}
                        {/* {index === 4 && <AutoGraph />} */}
                      </ListItemIcon>

                      <ListItemText
                        primary={item.name}
                        sx={{
                          opacity: open ? 1 : 0,
                          color: 'white'
                        }}
                      />
                    </ListItemButton>
                  </Tooltip>
                </ListItem>
              ))}
            </List>
          </Box>

          <Box>
            <Box>
              <List>
                <ListItem disablePadding sx={{ display: 'block' }}>
                  <Tooltip title={'Support'} placement="right">
                    <ListItemButton
                      onClick={() => navigate('/support')}
                      selected={location.pathname === '/support'}
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                        '&.Mui-selected': {
                          backgroundColor: theme.palette.primary.darkest,
                          '&:hover': {
                            backgroundColor: theme.palette.primary.darkHover
                          }
                        },
                        '&:hover': {
                          backgroundColor: theme.palette.primary.darkHover
                        }
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : 'auto',
                          justifyContent: 'center',
                          color: theme.palette.primary.lightest
                        }}
                      >
                        <Help />
                      </ListItemIcon>
                      <ListItemText
                        primary="Support"
                        sx={{
                          opacity: open ? 1 : 0,
                          color: 'white'
                        }}
                      />
                    </ListItemButton>
                  </Tooltip>
                </ListItem>
                <Collapse in={state.accountMenu}>
                  <ListItem disablePadding sx={{ display: 'block' }}>
                    <Tooltip title={'Account Settings'} placement="right">
                      <ListItemButton
                        onClick={() => navigate('/settings')}
                        selected={location.pathname === '/settings'}
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? 'initial' : 'center',
                          px: 2.5,
                          '&.Mui-selected': {
                            backgroundColor: theme.palette.primary.darkest,
                            '&:hover': {
                              backgroundColor: theme.palette.primary.darkHover
                            }
                          },
                          '&:hover': {
                            backgroundColor: theme.palette.primary.darkHover
                          }
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                            color: theme.palette.primary.lightest
                          }}
                        >
                          <Settings />
                        </ListItemIcon>
                        <ListItemText
                          primary="Account Settings"
                          sx={{
                            opacity: open ? 1 : 0,
                            color: 'white'
                          }}
                        />
                      </ListItemButton>
                    </Tooltip>
                  </ListItem>
                  <ListItem disablePadding sx={{ display: 'block' }}>
                    <Tooltip title={'Log Out'} placement="right">
                      <ListItemButton
                        onClick={handleSignOut}
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? 'initial' : 'center',
                          px: 2.5,
                          '&.Mui-selected': {
                            backgroundColor: theme.palette.primary.darkest,
                            '&:hover': {
                              backgroundColor: theme.palette.primary.darkHover
                            }
                          },
                          '&:hover': {
                            backgroundColor: theme.palette.primary.darkHover
                          }
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                            color: theme.palette.primary.lightest
                          }}
                        >
                          <ExitToApp />
                        </ListItemIcon>
                        <ListItemText
                          primary="Log Out"
                          sx={{
                            fontWeight: 'bolder',
                            opacity: open ? 1 : 0,
                            color: 'white'
                          }}
                        />
                      </ListItemButton>
                    </Tooltip>
                  </ListItem>
                </Collapse>
              </List>
            </Box>

            <List>
              <ListItem disablePadding sx={{ display: 'block' }}>
                <Tooltip
                  title={open ? null : state.accountMenu ? null : 'Account'}
                  placement="right"
                >
                  <ListItemButton
                    onClick={() =>
                      dispatch({
                        type: 'set_account_menu'
                      })
                    }
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                      '&.Mui-selected': {
                        backgroundColor: theme.palette.primary.darkest,
                        '&:hover': {
                          backgroundColor: theme.palette.primary.darkHover
                        }
                      },
                      '&:hover': {
                        backgroundColor: theme.palette.primary.darkHover
                      }
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center'
                      }}
                    >
                      <ProfileAvatar
                        menu
                        profile={{
                          id: user.username,
                          firstName: user.attributes.given_name,
                          lastName: user.attributes.family_name,
                          picture:
                            user.attributes['picture'] === 'true' ? true : false
                        }}
                        size={open ? null : 25}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        user.attributes.given_name +
                        ' ' +
                        user.attributes.family_name
                      }
                      sx={{ opacity: open ? 1 : 0, color: 'white' }}
                      primaryTypographyProps={{
                        noWrap: true,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'
                      }}
                      // secondary={''}
                    />
                    {open &&
                      (Boolean(state.accountMenu) ? (
                        <ExpandLess color="primary" />
                      ) : (
                        <ExpandMore color="primary" />
                      ))}
                  </ListItemButton>
                </Tooltip>
              </ListItem>
            </List>
          </Box>
        </Paper>
      </Drawer>
    </Box>
  );
});

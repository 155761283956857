import { List, ListItem, ListItemText, Typography } from '@mui/material';

export const lidcombeSteps = [
  {
    label: 'Stage I - Speak With No / Almost No Stuttering',
    description: (
      <>
        <Typography>
          To progress to Stage 2, the following two criteria need to be met for
          three consecutive consultations that are 1 week apart:
        </Typography>
        <List>
          <ListItem>
            <ListItemText
              primary="(a) parent SRs
          of 0–1 during the week preceding the consultation, with at least four
          of those seven SRs being 0, and"
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="(b) clinician SRs of 0–1 during the
              consultation. A minimal requirement during Stage 2 is for the parent
              to record SRs only during the week preceding the consultation.
              However, the clinician may direct the parent to record SRs more often
              during Stage 2."
            />
          </ListItem>
        </List>
      </>
    )
  },
  {
    label: 'Stage II - Speak With No / Almost No Stuttering For a Long Time',
    description: (
      <>
        <Typography>
          Stage 2 continues until the child has sustained treatment goals for
          around a year. Subsequent to the conclusion of Stage 2, the parent is
          advised to contact the clinician if any relapse occurs that cannot be
          managed by short-term reintroduction of verbal contingencies.
        </Typography>
      </>
    )
  }
];

import React from 'react';

import { Box, Container } from '@mui/material';
import ReactPlayer from 'react-player';

const ContentVideo = ({ content }) => (
  <Container maxWidth="lg">
    <Box
      style={{
        position: 'relative',
        paddingTop: '56.25%' /* Player ratio: 100 / (1280 / 720) */
      }}
    >
      <ReactPlayer
        pip
        width="100%"
        height="100%"
        className="react-player"
        controls
        url={content.content}
      />
    </Box>
  </Container>
);

export default ContentVideo;

import React, { useEffect, useState } from 'react';

import { Box, Container } from '@mui/material';
import { Storage } from 'aws-amplify';
import { getImageFromIndexedDB, saveImageToIndexedDB } from 'indexedDB';

const ContentImage = ({ content }) => {
  const [image, setImage] = useState(null);
  const imageID = content.id;

  async function getImageBlobFromS3(imageID, imageType) {
    try {
      const key = `resources/${imageID}.${imageType}`;
      const blob = await Storage.get(key, { download: true });
      return blob.Body;
    } catch (error) {
      console.error('Error fetching image from S3:', error);
      return null;
    }
  }

  useEffect(() => {
    let imageUrl;

    async function fetchImage() {
      if (imageID) {
        const blob = await getImageFromIndexedDB(imageID);
        if (blob) {
          imageUrl = URL.createObjectURL(blob);
          setImage(imageUrl);
        } else {
          const blobFromS3 = await getImageBlobFromS3(
            imageID,
            content.fileType
          );
          await saveImageToIndexedDB(imageID, blobFromS3);
          imageUrl = URL.createObjectURL(blobFromS3);
          setImage(imageUrl);
        }
      }
    }
    if (content) {
      fetchImage();
    }
    return () => {
      if (imageUrl) {
        URL.revokeObjectURL(imageUrl);
      }
    };
  }, [imageID, content]);

  return (
    <Container maxWidth="lg">
      <Box display="flex" justifyContent="center">
        {image && (
          <img
            alt="user profile"
            style={{ maxWidth: '80%', height: 'auto' }}
            src={image}
          />
        )}
      </Box>
    </Container>
  );
};

export default ContentImage;

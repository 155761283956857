import React from 'react';

import {
  EscalatorWarning,
  EscalatorWarningOutlined,
  FormatQuote,
  FormatQuoteOutlined,
  LocalLibrary,
  LocalLibraryOutlined,
  Mic,
  MicOutlined,
  Navigation,
  NavigationOutlined,
  PhoneOutlined
} from '@mui/icons-material';
import Bathtub from '@mui/icons-material/Bathtub';
import BathtubOutlined from '@mui/icons-material/BathtubOutlined';
import Block from '@mui/icons-material/Block';
import BlockOutlined from '@mui/icons-material/BlockOutlined';
import Brightness1 from '@mui/icons-material/Brightness1';
import Brightness1Outlined from '@mui/icons-material/Brightness1Outlined';
import BubbleChart from '@mui/icons-material/BubbleChart';
import BubbleChartOutlined from '@mui/icons-material/BubbleChartOutlined';
import Edit from '@mui/icons-material/Edit';
import EditOutlined from '@mui/icons-material/EditOutlined';
import Fastfood from '@mui/icons-material/Fastfood';
import FastfoodOutlined from '@mui/icons-material/FastfoodOutlined';
import Feedback from '@mui/icons-material/Feedback';
import FeedbackOutlined from '@mui/icons-material/FeedbackOutlined';
import Forum from '@mui/icons-material/Forum';
import ForumOutlined from '@mui/icons-material/ForumOutlined';
import GraphicEq from '@mui/icons-material/GraphicEq';
import GraphicEqOutlined from '@mui/icons-material/GraphicEqOutlined';
import Group from '@mui/icons-material/Group';
import GroupOutlined from '@mui/icons-material/GroupOutlined';
import HourglassEmpty from '@mui/icons-material/HourglassEmpty';
import HourglassFull from '@mui/icons-material/HourglassFull';
import LocalCafe from '@mui/icons-material/LocalCafe';
import LocalCafeOutlined from '@mui/icons-material/LocalCafeOutlined';
import LocalHospital from '@mui/icons-material/LocalHospital';
import LocalHospitalOutlined from '@mui/icons-material/LocalHospitalOutlined';
import More from '@mui/icons-material/More';
import MoreOutlined from '@mui/icons-material/MoreOutlined';
import Panorama from '@mui/icons-material/Panorama';
import PanoramaWideAngle from '@mui/icons-material/PanoramaWideAngle';
import Person from '@mui/icons-material/Person';
import PersonOutline from '@mui/icons-material/PersonOutline';
import Phone from '@mui/icons-material/Phone';
import PresentToAll from '@mui/icons-material/PresentToAll';
import PresentToAllOutlined from '@mui/icons-material/PresentToAllOutlined';
import ReceiptLong from '@mui/icons-material/ReceiptLong';
import ReceiptLongOutlined from '@mui/icons-material/ReceiptLongOutlined';
import Restaurant from '@mui/icons-material/Restaurant';
import RestaurantMenuOutlined from '@mui/icons-material/RestaurantMenuOutlined';
import ShoppingCart from '@mui/icons-material/ShoppingCart';
import ShoppingCartOutlined from '@mui/icons-material/ShoppingCartOutlined';
import SportsEsports from '@mui/icons-material/SportsEsports';
import SportsEsportsOutlined from '@mui/icons-material/SportsEsportsOutlined';
import ThumbUp from '@mui/icons-material/ThumbUp';
import ThumbUpOffAlt from '@mui/icons-material/ThumbUpOffAlt';
import TrendingDown from '@mui/icons-material/TrendingDown';
import TrendingDownOutlined from '@mui/icons-material/TrendingDownOutlined';
import TrendingUp from '@mui/icons-material/TrendingUp';
import TrendingUpOutlined from '@mui/icons-material/TrendingUpOutlined';
import Waves from '@mui/icons-material/Waves';
import WavesOutlined from '@mui/icons-material/WavesOutlined';

const entryIconMap = {
  bathtub: { filled: Bathtub, outlined: BathtubOutlined },
  bubblechart: { filled: BubbleChart, outlined: BubbleChartOutlined },
  escalatorwarning: {
    filled: EscalatorWarning,
    outlined: EscalatorWarningOutlined
  },
  localdining: { filled: Restaurant, outlined: RestaurantMenuOutlined },
  shoppingcart: { filled: ShoppingCart, outlined: ShoppingCartOutlined },
  trendingdown: { filled: TrendingDown, outlined: TrendingDownOutlined },
  trendingup: { filled: TrendingUp, outlined: TrendingUpOutlined },
  waves: { filled: Waves, outlined: WavesOutlined },
  thumbup: { filled: ThumbUp, outlined: ThumbUpOffAlt },
  half: { filled: Brightness1, outlined: Brightness1Outlined },
  rhythm: { filled: GraphicEq, outlined: GraphicEqOutlined },
  feedback: { filled: Feedback, outlined: FeedbackOutlined },
  clinician: { filled: LocalHospital, outlined: LocalHospitalOutlined },
  presentation: { filled: PresentToAll, outlined: PresentToAllOutlined },
  hourglass: { filled: HourglassFull, outlined: HourglassEmpty },
  phonecall: { filled: Phone, outlined: PhoneOutlined },
  location: { filled: Navigation, outlined: NavigationOutlined },
  trainingmodel: { filled: FormatQuote, outlined: FormatQuoteOutlined },
  coffee: { filled: LocalCafe, outlined: LocalCafeOutlined },
  food: { filled: Fastfood, outlined: FastfoodOutlined },
  conversation: { filled: Forum, outlined: ForumOutlined },
  meeting: { filled: Group, outlined: GroupOutlined },
  audio: { filled: Mic, outlined: MicOutlined },
  book: { filled: LocalLibrary, outlined: LocalLibraryOutlined },
  game: { filled: SportsEsports, outlined: SportsEsportsOutlined },
  other: { filled: More, outlined: MoreOutlined },
  none: { filled: Block, outlined: BlockOutlined },
  write: { filled: Edit, outlined: EditOutlined },
  order: { filled: ReceiptLong, outlined: ReceiptLongOutlined },
  panorama: { filled: Panorama, outlined: PanoramaWideAngle },
  person: { filled: Person, outlined: PersonOutline }
};

const getIcon = (iconKey, filled = true) => {
  const iconEntry = entryIconMap[iconKey];
  if (!iconEntry) {
    return null;
  }
  const IconComponent = filled ? iconEntry.filled : iconEntry.outlined;
  return <IconComponent />;
};

export default getIcon;

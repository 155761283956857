import { useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { Box, Collapse, Typography } from '@mui/material';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import { useSnackbar } from 'context/SnackBar';
import { updateClient, updateClinician } from 'graphql/mutations';
import mixpanel from 'mixpanel-browser';

import { ErrorMessage, SettingHeader } from 'components/atoms';
import { ChangeEmail, Confirm } from 'components/auth';

export default function AccountEmail() {
  const { user } = useAuthenticator(context => [context.user]);
  const isClinician = user.attributes['custom:type'] === 'clinician';
  const noEdit = user.username.includes('google');

  const [updateEmail, setUpdateEmail] = useState(false);
  const [email, setEmail] = useState(user.attributes.email);
  const [changingEmail, setChangingEmail] = useState(false);
  const [emailError, setEmailError] = useState(null);
  const [confirmEmail, setConfirmEmail] = useState(false);
  const [otp, setOtp] = useState('');
  const [settingOtp, setSettingOtp] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  async function handleChangeEmail() {
    try {
      setChangingEmail(true);
      await Auth.updateUserAttributes(user, {
        email: email
      });
      setConfirmEmail(true);
    } catch (err) {
      setEmailError(err.message);
    } finally {
      setChangingEmail(false);
    }
  }

  async function handleOtpSubmit() {
    try {
      setSettingOtp(true);
      await Auth.verifyCurrentUserAttributeSubmit('email', otp);
      const data = {
        id: user.username,
        email: email
      };
      await API.graphql(
        graphqlOperation(isClinician ? updateClinician : updateClient, {
          input: data
        })
      );
      mixpanel.track('change_email');
      setUpdateEmail(false);
      enqueueSnackbar('Email changed successfully!', {
        severity: 'success'
      });
      setOtp('');
      await Auth.currentAuthenticatedUser({
        bypassCache: true
      });
    } catch (err) {
      enqueueSnackbar('Error changing email.', {
        severity: 'error'
      });
      setEmailError(err.message);
    } finally {
      setChangingEmail(false);
      setSettingOtp(false);
    }
  }

  return (
    <Box p={1} my={2}>
      <SettingHeader
        label="Email"
        state={updateEmail}
        setState={setUpdateEmail}
        noEdit={noEdit}
        reasonNoEdit={
          "You can't edit your email because your account is managed by Google."
        }
      />
      <Box p={1} style={{ maxWidth: '100%', overflow: 'hidden' }}>
        <Typography
          variant="h6"
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
        >
          {user.attributes.email}
        </Typography>
      </Box>

      <Collapse in={updateEmail}>
        <Box>
          {confirmEmail ? (
            <Confirm
              otp={otp}
              setOtp={setOtp}
              settingOtp={settingOtp}
              handleOtpSubmit={handleOtpSubmit}
            />
          ) : (
            <ChangeEmail
              email={email}
              setEmail={setEmail}
              changingEmail={changingEmail}
              handleChangeEmail={handleChangeEmail}
            />
          )}
          {Boolean(emailError) && <ErrorMessage message={emailError} />}
        </Box>
      </Collapse>
    </Box>
  );
}

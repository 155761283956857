import { useEffect, useState } from 'react';

import {
  Alert,
  AlertTitle,
  Box,
  Divider,
  Grid,
  Grow,
  Typography
} from '@mui/material';

import { ClinicianCard } from 'components/atoms';
import { AddUsersToLocation } from 'components/molecules';

export default function Location({
  clinicians,
  locations,
  selectClinicians,
  setSelectClinicians,
  selectedClinicians,
  setSelectedClinicians,
  selectedLocationIDs,
  openAssignClinicians,
  setOpenAssignClinicians,
  setClinicians
}) {
  const [groupedClinicians, setGroupedClinicians] = useState({});
  const [selectedLocation, setSelectedLocation] = useState({});

  useEffect(() => {
    const groupByLocation = {
      no_location: {
        location: { name: 'No Location' },
        clinicians: []
      }
    };

    locations.forEach(location => {
      groupByLocation[location.id] = { location: location, clinicians: [] };
    });

    clinicians.forEach(clinician => {
      const locationId = clinician.locationID || 'no_location';
      if (!groupByLocation[locationId]) {
        console.warn(
          `Warning: clinician with id ${clinician.id} has an unmatched locationID: ${clinician.locationID}`
        );
        groupByLocation[locationId] = {
          location: { name: 'Unknown' },
          clinicians: []
        };
      }
      groupByLocation[locationId].clinicians.push(clinician);
    });
    const filteredGroupedClinicians = Object.keys(groupByLocation)
      .filter(
        locationId =>
          selectedLocationIDs.length === 0 ||
          selectedLocationIDs.includes(locationId)
      )
      .reduce((acc, locationId) => {
        acc[locationId] = groupByLocation[locationId];
        return acc;
      }, {});

    setGroupedClinicians(filteredGroupedClinicians);
  }, [clinicians, locations, selectedLocationIDs]);

  const handleSelectClinician = clinician => {
    setSelectedClinicians(prevSelected => {
      const selectedCopy = new Map(prevSelected);

      const clinicianIsSelected =
        Array.from(selectedCopy.values()).findIndex(
          selectedClinician => selectedClinician.id === clinician.id
        ) !== -1;

      if (clinicianIsSelected) {
        selectedCopy.delete(clinician.id);
      } else {
        selectedCopy.set(clinician.id, clinician);
      }

      return selectedCopy;
    });
  };

  const renderClinicianGroup = (group, groupDetails) => (
    <Box key={group} sx={{ mb: 4 }}>
      <Typography color="primary.dark" variant="h4">
        {groupDetails.location.name}
      </Typography>
      <Divider sx={{ mb: 2 }} />
      <Grid container spacing={2}>
        {groupDetails.clinicians.map(clinician => (
          <Grid key={clinician.id} item xs={12} sm={6} md={4} xl={3}>
            <ClinicianCard
              selectable={selectClinicians}
              slim
              removeEdit
              clinician={clinician}
              selectClinician={handleSelectClinician}
              selected={selectedClinicians?.has(clinician.id)}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );

  return (
    <Box>
      <AddUsersToLocation
        setSelectedClinicians={setSelectedClinicians}
        setSelectClinicians={setSelectClinicians}
        selectedLocation={selectedLocation}
        setSelectedLocation={setSelectedLocation}
        locations={locations}
        clinicians={clinicians}
        setClinicians={setClinicians}
        open={openAssignClinicians}
        setOpen={setOpenAssignClinicians}
        selectedClinicians={selectedClinicians}
      />
      <Grow in={Object.keys(groupedClinicians).length !== 0}>
        <Box>
          {Object.entries(groupedClinicians).map(([group, groupDetails]) =>
            groupDetails.clinicians.length > 0
              ? renderClinicianGroup(group, groupDetails)
              : null
          )}
        </Box>
      </Grow>
      <Grow in={Object.keys(groupedClinicians).length === 0}>
        <Box
          width="100%"
          height={Object.keys(groupedClinicians).length !== 0 ? 0 : '60vh'}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box maxWidth="sm">
            <Alert severity="info">
              <AlertTitle>No users found</AlertTitle>
              Try adding some users
            </Alert>
          </Box>
        </Box>
      </Grow>
    </Box>
  );
}

import { useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  DialogActions,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import { useSnackbar } from 'context/SnackBar';
import { getClientsByEmail, inviteParticipants } from 'graphql/queries';

import ErrorMessage from '../ErrorMessage';

export default function AddParticipant({
  clientFirstName,
  setClose,
  participants,
  setParticipants,
  clientID
}) {
  const { user } = useAuthenticator(context => [context.user]);
  const [inviting, setInviting] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [role, setRole] = useState('');
  const [email, setEmail] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState('');

  const handleSendInvitations = async () => {
    try {
      const newParticipant = {
        firstName,
        lastName,
        email
      };
      const newParticipants = [newParticipant];

      setInviting(true);
      const invitationParams = {
        participants: JSON.stringify(newParticipants),
        clientID,
        createdBy: user.username,
        source: 'clinician',
        action: 'create',
        invitedByFirstName: user.attributes.given_name,
        invitedByLastName: user.attributes.family_name,
        invitedByEmail: user.attributes.email,
        role
      };

      const { data } = await API.graphql(
        graphqlOperation(inviteParticipants, invitationParams)
      );

      console.log('data', data.inviteParticipants);

      if (data.inviteParticipants.statusCode === 200) {
        const { data } = await API.graphql(
          graphqlOperation(getClientsByEmail, { email })
        );
        const participant = data.getClientsByEmail.items;
        const updatedParticipants = [...participants, participant];
        setParticipants(updatedParticipants);
        enqueueSnackbar(
          `Invited ${firstName} as a participant on ${clientFirstName}'s account `,
          { severity: 'success' }
        );
        setClose();
      } else {
        throw new Error(data.inviteParticipants.body);
      }
    } catch (e) {
      console.log(e);
      setError(e.message);
    } finally {
      setInviting(false);
    }
  };
  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            value={firstName}
            label="First Name"
            size="small"
            variant="filled"
            onChange={e => setFirstName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            value={lastName}
            label="Last Name"
            size="small"
            variant="filled"
            onChange={e => setLastName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            value={email}
            label="Email"
            size="small"
            variant="filled"
            onChange={e => setEmail(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl size="small" fullWidth>
            <InputLabel>Role</InputLabel>
            <Select
              label="Role"
              value={role}
              onChange={e => setRole(e.target.value)}
              fullWidth
              size="small"
            >
              <MenuItem value="Parent">Parent</MenuItem>
              <MenuItem value="Grandparent">Grandparent</MenuItem>
              <MenuItem value="Sibling">Sibling</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      {Boolean(error) && (
        <Box my={2}>
          <ErrorMessage message={error} />
        </Box>
      )}
      <DialogActions sx={{ mt: 2 }}>
        <LoadingButton
          onClick={handleSendInvitations}
          loading={inviting}
          size="small"
          variant="contained"
        >
          Send Invitation
        </LoadingButton>
      </DialogActions>
    </Box>
  );
}

import { useEffect, useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { useAppContext } from 'context';

export function useQueryId() {
  const { state } = useAppContext();
  const { user } = useAuthenticator();
  const type = user.attributes['custom:type'];
  const [stateError, setStateError] = useState(false);

  let queryID = undefined;
  if (type === 'clinician') {
    queryID = state?.client?.id;
  } else {
    queryID = user.username;
  }

  useEffect(() => {
    function checkQueryID() {
      if (queryID !== '' && queryID !== undefined) {
        setStateError(false);
      } else {
        setStateError(true);
      }
    }
    checkQueryID();
  }, [queryID]);

  return { queryID, stateError, state, type };
}

import { useEffect, useState } from 'react';

import { useTheme } from '@emotion/react';
import {
  Add,
  Close,
  RadioButtonChecked,
  RadioButtonUnchecked
} from '@mui/icons-material';
import {
  Alert,
  AlertTitle,
  Box,
  Card,
  CardActionArea,
  Chip,
  Collapse,
  FormControl,
  FormControlLabel,
  FormLabel,
  Link,
  Radio,
  RadioGroup,
  Stack,
  Typography
} from '@mui/material';
import { constants } from 'common/constants';

import { formatProgram } from 'utils/formatProgram';

export default function ProgramSelector({
  forValue,
  age,
  program,
  setProgram,
  clientExists,
  existingProgram,
  override,
  setOverride,
  setProgramStage,
  programStage
}) {
  const [stuttering, setStuttering] = useState(true);

  useEffect(() => {
    if (clientExists && existingProgram) {
      setProgram(existingProgram);
    }
  }, [clientExists, existingProgram, setProgram]);

  const programs = {
    child:
      age < 12
        ? ['lidcombe', 'westmead', 'oakville', 'monitoringChild']
        : [
            'lidcombe',
            'westmead',
            'oakville',
            'camperdownAdolescent',
            'monitoringChild'
          ],
    me: ['camperdownAdult', 'camperdownAdolescent'],
    adult: ['camperdownAdult', 'camperdownAdolescent', 'monitoringAdult'],
    carer:
      age < 13
        ? ['lidcombe', 'westmead', 'oakville']
        : ['camperdownAdult', 'camperdownAdolescent', 'monitoringAdult'],
    other:
      age < 13
        ? ['lidcombe', 'westmead', 'oakville', 'monitoringChild']
        : ['camperdownAdult', 'camperdownAdolescent', 'monitoringAdult']
  };

  const deselectStuttering = () => {
    setStuttering(prevStuttering => !prevStuttering);
  };

  useEffect(() => {
    if (program !== '') {
      if (!programStage) {
        setProgramStage(1);
      }
    }
  }, [program, setProgramStage, programStage]);

  const theme = useTheme();

  return (
    <Box>
      <Box>
        {clientExists && (
          <Box>
            <Alert severity="warning">
              <AlertTitle>This client already has an account.</AlertTitle>
              {existingProgram ? (
                <span>
                  Their current program has been selected. Changing it will not
                  erase their existing settings - it will add to them.
                </span>
              ) : (
                <>
                  If you add a new program, their existing settings will not be
                  erased. You will just add new program elements to their
                  account.
                  <Box py={2}>
                    <FormControl>
                      <FormLabel id="demo-row-radio-buttons-group-label">
                        Start from template anyway?
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={override}
                        onChange={e => setOverride(e.target.value)}
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                </>
              )}
            </Alert>
          </Box>
        )}
        <Collapse
          in={!clientExists || existingProgram !== '' || override === 'yes'}
        >
          <Box mt={2}>
            <Box py={1} mb={2}>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Category
                </FormLabel>
                <Stack sx={{ mt: 1 }} direction="row" spacing={1}>
                  <Chip
                    color="primary"
                    variant={stuttering ? 'filled' : 'outlined'}
                    label="Stuttering"
                    onClick={deselectStuttering}
                    icon={stuttering ? <Close /> : <Add />}
                  />
                </Stack>
              </FormControl>
            </Box>
            <Box>
              <FormLabel htmlFor="age-slider">Program</FormLabel>
            </Box>
            <Collapse in={stuttering}>
              <Box>
                <Box>
                  {programs[forValue].map(prog => (
                    <Card
                      key={prog}
                      sx={{
                        my: 1,
                        backgroundColor:
                          program === prog
                            ? theme.palette.primary.lightest
                            : theme.palette.background.card
                      }}
                      elevation={0}
                    >
                      <CardActionArea
                        sx={{ p: 2 }}
                        onClick={() => setProgram(prog)}
                      >
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Typography variant="h5">
                            {formatProgram(prog)}
                          </Typography>
                          {program === prog ? (
                            <RadioButtonChecked
                              color="primary"
                              sx={{ fontSize: 36 }}
                            />
                          ) : (
                            <RadioButtonUnchecked
                              sx={{ fontSize: 36 }}
                              color="grey"
                            />
                          )}
                        </Stack>
                      </CardActionArea>
                    </Card>
                  ))}
                </Box>
                <Collapse in={Boolean(program)}>
                  <Box my={2}>
                    {!program.toLowerCase().includes('monitoring') && (
                      <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label">
                          Stage
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={programStage}
                          onChange={e => setProgramStage(e.target.value)}
                        >
                          <FormControlLabel
                            value={1}
                            control={<Radio />}
                            label="I"
                          />

                          {program?.includes('oakville') && (
                            <FormControlLabel
                              value={2}
                              control={<Radio />}
                              label="I.5"
                            />
                          )}

                          <FormControlLabel
                            value={program?.includes('oakville') ? 3 : 2}
                            control={<Radio />}
                            label="II"
                          />
                          {program?.includes('camperdown') && (
                            <>
                              <FormControlLabel
                                value={3}
                                control={<Radio />}
                                label="III"
                              />
                              <FormControlLabel
                                value={4}
                                control={<Radio />}
                                label="IV"
                              />
                            </>
                          )}
                        </RadioGroup>
                      </FormControl>
                    )}
                  </Box>
                </Collapse>
              </Box>
            </Collapse>
            <Collapse in={!stuttering}>
              <Box>
                <Alert severity="info">
                  <AlertTitle>No Other Programs Available</AlertTitle>
                  We are working on adding more programs. If there is something
                  you need that we don't have, please{' '}
                  <Link href={constants.contactUrl}>contact us</Link> or add a
                  feature request on{' '}
                  <Link href={constants.cannyUrl}>our product roadmap</Link> and
                  we'll add it as soon as possible.
                </Alert>
              </Box>
            </Collapse>
          </Box>
        </Collapse>
      </Box>
    </Box>
  );
}

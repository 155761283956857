import { useCallback, useEffect, useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { Add } from '@mui/icons-material';
import { Box, Button, CircularProgress, Container, Grid } from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import { useAppContext } from 'context';
import { createSession as createSessionMutation } from 'graphql/mutations';
import { getSessionsByClientByDate } from 'graphql/queries';
import { useQueryId } from 'hooks/useQueryId';
import { DateTime } from 'luxon';
import mixpanel from 'mixpanel-browser';
import { useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';

import NoClientError from 'components/atoms/NoClientError';
import SessionRow from 'components/molecules/SessionRow';

export default function SessionsComponent() {
  const { state, dispatch } = useAppContext();
  const { queryID, stateError } = useQueryId();
  const { user } = useAuthenticator(context => [context.user]);
  const clientID =
    state?.client?.id || user.attributes['custom:clientID'] || user.username;
  const clientSub = state?.client?.sub || user.attributes.sub;
  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [firstSessionDate, setFirstSessionDate] = useState(DateTime.local());
  const isClinician = user.attributes['custom:type'] === 'clinician';

  const navigate = useNavigate();

  const fetchInitialData = useCallback(async () => {
    try {
      const { data } = await API.graphql(
        graphqlOperation(getSessionsByClientByDate, {
          clientID: queryID,
          sortDirection: 'ASC',
          limit: 1,
          filter: { _deleted: { attributeExists: false } }
        })
      );
      const sessions = data.getSessionsByClientByDate.items;
      if (sessions.length > 0) {
        const earliestSession = sessions[0];
        setFirstSessionDate(DateTime.fromISO(earliestSession.date));
      }
    } catch (error) {
      console.error('Error on fetching initial data', error);
    }
    setLoading(false);
  }, [queryID]);

  const fetchLastFiveSessions = useCallback(async () => {
    try {
      const { data } = await API.graphql(
        graphqlOperation(getSessionsByClientByDate, {
          clientID: queryID,
          sortDirection: 'DESC',
          limit: 20,
          filter: { _deleted: { attributeExists: false } }
        })
      );
      const fetchedSessions = data.getSessionsByClientByDate.items;
      setSessions(fetchedSessions);
    } catch (error) {
      console.error('Error on fetching last five sessions', error);
    }
  }, [queryID]);

  useEffect(() => {
    fetchInitialData();
    return () => {
      setSessions([]);
      setLoading(true);
    };
  }, [fetchInitialData]);

  useEffect(() => {
    const startDate = firstSessionDate.startOf('week').toJSDate();
    const endDate = DateTime.now().endOf('week').toJSDate();
    const marks = [];

    for (
      let weekStart = DateTime.fromJSDate(startDate);
      weekStart <= DateTime.fromJSDate(endDate);
      weekStart = weekStart.plus({ weeks: 1 })
    ) {
      marks.push({
        value: weekStart.toMillis(),
        label: weekStart.toFormat('dd/MM')
      });
    }
  }, [firstSessionDate]);

  useEffect(() => {
    fetchLastFiveSessions();
    return () => {
      setSessions([]);
      setLoading(true);
    };
  }, [fetchLastFiveSessions]);

  const sessionId = uuid();
  const sessionDate = new Date().toISOString();

  async function createSession() {
    if (clientID !== '') {
      const data = {
        id: sessionId,
        date: sessionDate,
        clientID: clientID,
        owner: `${clientSub}::${clientID}`
      };
      await API.graphql(
        graphqlOperation(createSessionMutation, { input: data })
      );
    }
  }

  async function newSession() {
    await createSession();
    dispatch({
      type: 'add_client',
      client: { ...state.client, sessionCount: state.client.sessionCount + 1 }
    });
    navigate(
      isClinician ? `/client/session/` + sessionId : `/session/` + sessionId
    );
    mixpanel.track('create_session');
  }

  return (
    <Box sx={{ mt: isClinician ? 2 : 0 }}>
      <Container maxWidth="md">
        {!isClinician && (
          <Box sx={{ my: 2, pr: 1 }} display="flex" justifyContent="flex-end">
            <Button startIcon={<Add />} onClick={() => newSession()}>
              New Note
            </Button>
          </Box>
        )}
        <Grid container sx={{ justifyContent: 'center', pb: 4 }}>
          <Grid item xs={12}>
            {loading ? (
              <Box
                py={6}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <CircularProgress />
              </Box>
            ) : sessions?.length < 1 ? (
              <Box
                height="60vh"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Button
                  variant="contained"
                  startIcon={<Add />}
                  onClick={() => newSession()}
                >
                  New Note
                </Button>
              </Box>
            ) : (
              sessions?.map(session => {
                return <SessionRow key={session.id} session={session} />;
              })
            )}
          </Grid>
        </Grid>
      </Container>
      {stateError && <NoClientError />}
    </Box>
  );
}

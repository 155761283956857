import { useEffect, useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { Masonry } from '@mui/lab';
import { Alert, AlertTitle, Box, Button, Container } from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import { useAppContext } from 'context';
import { deleteClientResource, updateClient } from 'graphql/mutations';
import {
  clientResourcesByClientID,
  updateUserAttributes
} from 'graphql/queries';
import { useNavigate } from 'react-router-dom';

import { ResourceCard } from 'components/molecules';

export default function Resources() {
  const [loading, setLoading] = useState(true);
  const [clientResources, setClientResources] = useState([]);
  const { user } = useAuthenticator(context => [context.user]);
  const { state, dispatch } = useAppContext();
  const client = state?.client;
  const isClinician = user.attributes['custom:type'] === 'clinician';
  const clientID =
    state.client?.id || user.attributes['custom:clientID'] || user.username;
  const navigate = useNavigate();

  useEffect(() => {
    const getClientResources = async () => {
      const { data } = await API.graphql(
        graphqlOperation(clientResourcesByClientID, {
          clientID
        })
      );
      setClientResources(data.clientResourcesByClientID.items);
      setLoading(false);
    };
    if (clientID) {
      getClientResources();
    }
    return () => {
      setClientResources([]);
    };
  }, [clientID]);

  useEffect(() => {
    const setClient = async value => {
      await API.graphql(
        graphqlOperation(updateClient, {
          input: {
            id: clientID,
            hasResources: value,
            _version: client._version
          }
        })
      );
      dispatch({
        type: 'set_client',
        client: {
          ...client,
          hasResources: value,
          _version: client._version + 1
        }
      });
      await API.graphql(
        graphqlOperation(updateUserAttributes, {
          userID: clientID,
          attributeKey: 'custom:hasResources',
          attributeValue: value.toString()
        })
      );
    };
    if (clientResources.length > 0 && !client.hasResources) {
      setClient(true);
    } else if (clientResources.length < 1 && client.hasResources) {
      setClient(false);
    }
    return () => {
      setClient(false);
    };
  }, [clientResources, state.edit, client, clientID, dispatch]);

  const removeResource = async resource => {
    try {
      setClientResources(prevResources =>
        prevResources.filter(
          existingResource => existingResource.id !== resource.id
        )
      );
      await API.graphql(
        graphqlOperation(deleteClientResource, { input: { id: resource.id } })
      );
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Container maxWidth="xl">
      <Box>
        {!loading && isClinician && clientResources.length < 1 && (
          <Box mt={4}>
            <Alert severity="info">
              <AlertTitle>
                Your client will not see this tab unless they have resources.
              </AlertTitle>
              <Button onClick={() => navigate('/resources')}>
                Add Resources
              </Button>
            </Alert>
          </Box>
        )}
        <Box mt={4}>
          <Masonry columns={{ xs: 1, sm: 2, md: 3 }} spacing={2}>
            {clientResources.map(clientResource => {
              return (
                <ResourceCard
                  client
                  key={clientResource.id}
                  clientResource={clientResource}
                  resource={clientResource.resource}
                  removeResource={removeResource}
                />
              );
            })}
          </Masonry>
        </Box>
      </Box>
    </Container>
  );
}

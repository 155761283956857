import React from 'react';

import { AmazonAIPredictionsProvider } from '@aws-amplify/predictions';
// eslint-disable-next-line no-unused-vars
import { Amplify } from 'aws-amplify';
import LogRocket from 'logrocket';
import mixpanel from 'mixpanel-browser';
import ReactDOM from 'react-dom/client';

import App from './App';
import config from './aws-exports';
import './index.css';
import { initializeIndexedDB } from './indexedDB';
import reportWebVitals from './reportWebVitals';

if (process.env.NODE_ENV === 'production') {
  LogRocket.init('speech-pathology-company/speechfit');
}

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
  track_pageview: true,
  persistence: 'localStorage'
});

const updatedAwsConfig = {
  ...config,
  oauth: {
    ...config.oauth,
    domain: process.env.REACT_APP_AWS_COGNITO_URL,
    redirectSignIn: process.env.REACT_APP_BASE_URL,
    redirectSignOut: process.env.REACT_APP_BASE_URL
  }
};

Amplify.configure(updatedAwsConfig);
Amplify.addPluggable(new AmazonAIPredictionsProvider());

initializeIndexedDB();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();

export const initialState = {
  clientID: '',
  edit: false,
  sectionID: '',
  filter: false,
  avatarRefreshKey: 0,
  menuOpen: false,
  client: {},
  clinician: {},
  modal: false,
  accountMenu: false,
  theme: 'light',
  record: {},
  changeProgram: false
};

export const AppReducer = (state, action) => {
  switch (action.type) {
    case 'set_theme': {
      return {
        ...state,
        theme: action.theme
      };
    }
    case 'init_stored': {
      return action.sessionState;
    }
    case 'clear_all': {
      return {
        ...initialState,
        theme: state.theme
      };
    }
    case 'set_modal': {
      return {
        ...state,
        modal: action.modal
      };
    }
    case 'add_clinician': {
      return {
        ...state,
        clinician: action.clinician
      };
    }
    case 'remove_clinician': {
      return {
        ...state,
        clinician: {}
      };
    }
    case 'remove_client': {
      return {
        ...state,
        client: {}
      };
    }
    case 'remove_client_id': {
      return {
        ...state,
        clientID: ''
      };
    }
    case 'set_menu': {
      return {
        ...state,
        menuOpen:
          action.menuOpen !== undefined ? action.menuOpen : !state.menuOpen
      };
    }
    case 'set_account_menu': {
      return {
        ...state,
        accountMenu: !state.accountMenu
      };
    }
    case 'trigger_avatar_refresh': {
      return {
        ...state,
        avatarRefreshKey: action.payload
      };
    }
    case 'add_client': {
      return {
        ...state,
        client: action.client
      };
    }
    case 'add_messages': {
      return {
        ...state,
        messages: [...state.messages, ...action.messages]
      };
    }
    case 'mark_messages_as_read':
      return {
        ...state,
        messages: state.messages.map(msg =>
          action.messages.includes(msg) ? { ...msg, read: true } : msg
        )
      };
    case 'add_client_id': {
      return {
        ...state,
        clientID: action.clientID
      };
    }
    case 'set_edit': {
      return {
        ...state,
        edit: action.edit
      };
    }
    case 'set_change_program': {
      return {
        ...state,
        changeProgram: action.changeProgram
      };
    }
    case 'set_call': {
      return {
        ...state,
        call: action.call
      };
    }
    case 'set_record': {
      return {
        ...state,
        record: action.record
      };
    }
    case 'set_call_settings': {
      return {
        ...state,
        callSettings: action.callSettings
      };
    }
    case 'set_mute': {
      return {
        ...state,
        callSettings: {
          ...state.callSettings,
          mute: action.mute
        }
      };
    }
    case 'end_call': {
      return {
        ...state,
        call: {},
        callSettings: {
          remoteStreamPresent: false,
          fitScreen: false,
          mute: false,
          camera: true,
          present: false
        },
        messages: [],
        chatDrawerOpen: false
      };
    }
    case 'set_filter': {
      return {
        ...state,
        filter: action.filter
      };
    }
    case 'set_section': {
      return {
        ...state,
        sectionId: action.sectionId
      };
    }
    case 'set_chat_drawer': {
      return {
        ...state,
        chatDrawerOpen: action.chatDrawerOpen
      };
    }
    default:
      return state;
  }
};

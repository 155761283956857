import { useRef } from 'react';

import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded';
import { Avatar, IconButton } from '@mui/material';
import { lightBlue } from '@mui/material/colors';

export default function AudioUploadButton(props) {
  const { disabled, onUpload } = props;
  const inputRef = useRef(null);

  const handleOpenUploadPopup = () => {
    inputRef.current?.click();
  };

  const handleUpload = event => {
    const file = event.target.files?.[0];
    if (file) onUpload?.(file);
  };

  return (
    <IconButton disabled={disabled} onClick={handleOpenUploadPopup}>
      <input
        accept="audio/*"
        ref={inputRef}
        onChange={handleUpload}
        type="file"
        style={{ display: 'none' }}
      />
      <Avatar
        sx={{
          color: '#FFF',
          border: '1px solid white',
          background: lightBlue[600]
        }}
      >
        <UploadFileRoundedIcon />
      </Avatar>
    </IconButton>
  );
}

import React, { useState } from 'react';

import { LoadingButton } from '@mui/lab';
import { Box, TextField } from '@mui/material';
import isEmail from '@rearguard/is-email';
import * as yup from 'yup';

function ChangeEmail({ email, setEmail, changingEmail, handleChangeEmail }) {
  const [errors, setErrors] = useState({});
  const [submissionAttempted, setSubmissionAttempted] = useState(false);

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .required('Email is required')
      .test('isValid', 'Email is not valid', value => isEmail(value))
  });

  const validateField = async (fieldName, value) => {
    try {
      await validationSchema.validateAt(fieldName, { [fieldName]: value });
      setErrors(prevErrors => {
        const newErrors = { ...prevErrors };
        delete newErrors[fieldName];
        return newErrors;
      });
    } catch (err) {
      setErrors(prevErrors => ({ ...prevErrors, [fieldName]: err.message }));
    }
  };

  const validateForm = async () => {
    try {
      await validationSchema.validate({ email });
      setErrors({});
      return true;
    } catch (err) {
      setErrors({ email: err.errors[0] });
      return false;
    }
  };

  const handleOnSubmit = async () => {
    setSubmissionAttempted(true);
    const isValid = await validateForm();
    if (isValid) {
      handleChangeEmail();
    }
  };

  return (
    <Box>
      <Box my={1}>
        <TextField
          fullWidth
          size="small"
          onChange={e => {
            setEmail(e.target.value);
            if (submissionAttempted) {
              validateField('email', e.target.value);
            }
            setSubmissionAttempted(false);
          }}
          error={submissionAttempted && !!errors.email}
          helperText={submissionAttempted && errors.email}
          value={email}
          label="New Email Address"
        />
      </Box>
      <Box my={2}>
        <LoadingButton
          variant="contained"
          fullWidth
          size="small"
          loading={changingEmail}
          onClick={handleOnSubmit}
        >
          Change Email
        </LoadingButton>
      </Box>
    </Box>
  );
}

export default React.memo(ChangeEmail);

import { Box, Skeleton, Typography } from '@mui/material';
import { useAppContext } from 'context';

import SettingHeader from '../SettingHeader';

export default function ClientAccountName({ forClient }) {
  const { state } = useAppContext();
  const { client } = state;
  return (
    <Box p={1} my={2}>
      <SettingHeader
        // eslint-disable-next-line no-useless-concat
        label={forClient ? 'Client' : 'Account' + ' Name'}
        noAction
      />
      <Box p={1} sx={{ display: 'flex', alignItems: 'center' }}>
        {!client?.firstName ? (
          <Skeleton width={100} />
        ) : (
          <Typography variant="h6">
            {forClient
              ? client?.clientFirstName + ' ' + client?.clientLastName
              : client?.firstName + ' ' + client?.lastName}
          </Typography>
        )}
      </Box>
    </Box>
  );
}

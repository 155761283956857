import { useState } from 'react';

import {
  Add,
  Check,
  ChevronLeft,
  Face2,
  Info,
  RadioButtonChecked,
  RadioButtonUnchecked
} from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  Collapse,
  Divider,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import { getCliniciansByEmail } from 'graphql/customQueries';

import { formatCountryName } from 'utils/getCountryFromTimeZone';

import AddLocation from '../AddLocation';

export default function StepOne({
  setExistingClinician,
  clinicianExists,
  setClinicianExists,
  errors,
  setErrors,
  setHowManyClients,
  location,
  setLocation,
  locations,
  setLocations,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
  role,
  setRole
}) {
  const [showPermissionsInfo, setShowPermissionsInfo] = useState(false);
  const [showLocationInfo, setShowLocationInfo] = useState(false);
  const [openAddLocation, setOpenAddLocation] = useState(false);

  function RoleChip({ label, selected, onClick }) {
    return (
      <Chip
        clickable
        size="small"
        label={label}
        onClick={onClick}
        color={selected ? 'primary' : 'default'}
        variant={selected ? 'filled' : 'outlined'}
      />
    );
  }

  const handleToggleRole = role => {
    setRole(role);
  };

  const handleFieldChange = async (field, value) => {
    if (field === 'email') setEmail(value);
    if (field === 'firstName') setFirstName(value);
    if (field === 'lastName') setLastName(value);
    if (errors && errors[field]) {
      setErrors(prevErrors => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[field];
        return updatedErrors;
      });
    }
    if (field === 'email') {
      try {
        setEmail(value);
        const { data } = await API.graphql(
          graphqlOperation(getCliniciansByEmail, { email: value })
        );
        const clinician = data.getCliniciansByEmail.items[0];

        if (clinician) {
          setExistingClinician(clinician);
          setHowManyClients(clinician.clients.items.length);
          setClinicianExists(true);

          setFirstName(clinician?.firstName);

          setLastName(clinician?.lastName);
        } else {
          setHowManyClients(null);
          setClinicianExists(false);

          setFirstName('');

          setLastName('');
        }
      } catch (err) {
        console.log('no users found');
      }
    }
  };

  return (
    <Box mt={2}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Stack sx={{ mb: 1 }} direction="row" spacing={1} alignItems="center">
            <FormLabel>Details</FormLabel>
          </Stack>
          <TextField
            error={!!errors?.email}
            helperText={errors?.email}
            size="small"
            value={email}
            onChange={e => handleFieldChange('email', e.target.value)}
            fullWidth
            variant="filled"
            label="Email Address"
            InputProps={{
              endAdornment: clinicianExists && (
                <InputAdornment position="end">
                  <Tooltip
                    placement="top"
                    title={`Invite ${
                      firstName ? firstName : 'this clinician'
                    } with their existing account.`}
                  >
                    <Face2 />
                  </Tooltip>
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            error={!!errors?.firstName}
            helperText={errors?.firstName}
            size="small"
            value={firstName}
            onChange={e => handleFieldChange('firstName', e.target.value)}
            fullWidth
            variant="filled"
            label="First Name"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            error={!!errors?.lastName}
            helperText={errors?.lastName}
            size="small"
            value={lastName}
            onChange={e => handleFieldChange('lastName', e.target.value)}
            fullWidth
            variant="filled"
            label="Last Name"
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: 4 }}>
        <Grid item xs={12}>
          <Stack direction="row" spacing={1} alignItems="center">
            <FormLabel>Permissions</FormLabel>
            <Tooltip placement="top" title="What do these permissions mean?">
              <IconButton
                color={showPermissionsInfo ? 'secondary' : 'default'}
                onClick={() =>
                  setShowPermissionsInfo(
                    prevShowPermissionsInfo => !prevShowPermissionsInfo
                  )
                }
              >
                <Info />
              </IconButton>
            </Tooltip>
          </Stack>
          <Collapse in={showPermissionsInfo}>
            <Card
              elevation={0}
              sx={{
                backgroundColor: 'background.settings',
                width: '100%',
                p: 2,
                mb: 2
              }}
            >
              <Grid container sx={{ alignItems: 'center' }}>
                <Grid item xs={4} sm={2}>
                  <Typography sx={{ fontWeight: 'bold' }} variant="body2">
                    Clinician
                  </Typography>
                </Grid>
                <Grid item xs={8} sm={10}>
                  <List dense>
                    <ListItem>
                      <ListItemIcon>
                        <Check fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Add and remove their own clients" />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <Check fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="See which other clinicians are in the organisation" />
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={12}>
                  <Divider sx={{ opacity: 0.3 }} />
                </Grid>
                <Grid item xs={4} sm={2} />
                <Grid item xs={8} sm={10}>
                  <List sx={{ pb: 0 }} dense>
                    <ListItem>
                      <ListItemText
                        primaryTypographyProps={{
                          sx: {
                            fontStyle: 'italic'
                          }
                        }}
                        primary="All of the above plus..."
                      />
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={4} sm={2}>
                  <Typography sx={{ fontWeight: 'bold' }} variant="body2">
                    Practice Manager
                  </Typography>
                </Grid>
                <Grid item xs={8} sm={10}>
                  <List dense>
                    <ListItem>
                      <ListItemIcon>
                        <Check fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Add and remove clinicians within their location" />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <Check fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Assign clients between clinicians in their location" />
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={12}>
                  <Divider sx={{ opacity: 0.3 }} />
                </Grid>
                <Grid item xs={4} sm={2} />
                <Grid item xs={8} sm={10}>
                  <List sx={{ pb: 0 }} dense>
                    <ListItem>
                      <ListItemText
                        primaryTypographyProps={{
                          sx: {
                            fontStyle: 'italic'
                          }
                        }}
                        primary="All of the above plus..."
                      />
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={4} sm={2}>
                  <Typography sx={{ fontWeight: 'bold' }} variant="body2">
                    Admin
                  </Typography>
                </Grid>
                <Grid item xs={8} sm={10}>
                  <List dense>
                    <ListItem>
                      <ListItemIcon>
                        <Check fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Add and remove clinicians in any location" />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <Check fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Assign clients between clinicians in any location" />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <Check fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Change global settings" />
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </Card>
          </Collapse>
          <Stack direction="row" spacing={1} sx={{ mt: 1 }} alignItems="center">
            <RoleChip
              label="Admin"
              selected={role.includes('admin')}
              onClick={() => handleToggleRole('admin')}
            />
            <RoleChip
              label="Practice Manager"
              selected={role.includes('practicemanager')}
              onClick={() => handleToggleRole('practicemanager')}
            />
            <RoleChip
              label="Clinician"
              selected={role.includes('clinician')}
              onClick={() => handleToggleRole('clinician')}
            />
          </Stack>
          {errors?.permission && (
            <Typography color="error" variant="body2" sx={{ mb: 2 }}>
              {errors?.permission}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Grid container sx={{ mt: 4 }}>
        <Grid item xs={12}>
          <Stack direction="row" spacing={1} alignItems="center">
            <FormLabel>Location</FormLabel>
            <IconButton
              color={showLocationInfo ? 'secondary' : 'default'}
              onClick={() =>
                setShowLocationInfo(
                  prevShowLocationInfo => !prevShowLocationInfo
                )
              }
            >
              <Info />
            </IconButton>
            {locations && locations?.length < 1 && (
              <Button
                size="small"
                color={openAddLocation ? 'secondary' : 'primary'}
                startIcon={openAddLocation ? <ChevronLeft /> : <Add />}
                onClick={() =>
                  setOpenAddLocation(
                    prevOpenAddLocation => !prevOpenAddLocation
                  )
                }
              >
                Add Location
              </Button>
            )}
          </Stack>
          <Collapse in={showLocationInfo}>
            <Card
              elevation={0}
              sx={{ p: 2, mb: 2, backgroundColor: 'background.settings' }}
            >
              <Box>
                {role === 'admin' && (
                  <Typography variant="body2">
                    Admins can administer all locations; however, assigning an
                    admin to a location can aid clinicians and practice managers
                    in knowing how to talk to about administrative queries for
                    their clinic.
                  </Typography>
                )}
                {role === 'practicemanager' && (
                  <Typography variant="body2">
                    A Practice Manager can manage Clinicians and assign Clients
                    in one Location. You are required to have a Location to add
                    Practice Managers.
                  </Typography>
                )}

                {role === 'clinician' && (
                  <Typography variant="body2">
                    Assigning a Clinician to a location will grant Practice
                    Managers the ability to assign them new Clients.
                  </Typography>
                )}
              </Box>
            </Card>
          </Collapse>
          {errors?.location && (
            <Typography color="error" variant="body2" sx={{ mb: 2 }}>
              {errors?.location}
            </Typography>
          )}
          {locations && locations?.length > 0 ? (
            locations.map(existingLocation => (
              <Card
                elevation={0}
                key={existingLocation.id}
                sx={{
                  backgroundColor: 'background.card'
                }}
              >
                <CardHeader
                  title={existingLocation.name}
                  subheader={`${[
                    existingLocation.street,
                    existingLocation.street2,
                    existingLocation.city,
                    existingLocation.state,
                    existingLocation.postcode,
                    formatCountryName(existingLocation.country)
                  ]
                    .filter(part => part)
                    .join(', ')}${' '}`}
                  action={
                    <IconButton
                      onClick={() => {
                        location.id
                          ? setLocation('')
                          : setLocation(existingLocation);
                      }}
                    >
                      {location.id === existingLocation.id ? (
                        <RadioButtonChecked
                          color={
                            location.id === existingLocation.id
                              ? 'primary'
                              : 'default'
                          }
                        />
                      ) : (
                        <RadioButtonUnchecked />
                      )}
                    </IconButton>
                  }
                />
              </Card>
            ))
          ) : (
            <Collapse in={openAddLocation}>
              <AddLocation
                setLocation={setLocation}
                setOpenAddLocation={setOpenAddLocation}
                locations={locations}
                setLocations={setLocations}
              />
            </Collapse>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

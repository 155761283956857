import { useEffect, useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  AlertTitle,
  Box,
  CircularProgress,
  Collapse,
  DialogActions,
  Divider,
  Grid,
  Typography
} from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import { useSnackbar } from 'context/SnackBar';
import { createClientResource } from 'graphql/mutations';
import { clientsByClinicianID } from 'graphql/queries';

import { ErrorMessage } from 'components/atoms';
import {
  ClientSelectCard,
  Modal,
  ResourcePreviewCard,
  SearchBar
} from 'components/molecules';

export default function AddResourceToClient({
  setSelectedResources,
  selectedResources,
  state,
  setState
}) {
  const [addingResources, setAddingResources] = useState(false);
  const [loading, setLoading] = useState(true);
  const { user } = useAuthenticator(context => [context.user]);
  const clinicianID = user.username;
  const [groupedClients, setGroupedClients] = useState();
  const [clients, setClients] = useState();
  const [search, setSearch] = useState('');
  const [showNoClientsBox, setShowNoClientsBox] = useState();
  const [selectedClient, setSelectedClient] = useState({});
  const [error, setError] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const getClients = async () => {
      const { data } = await API.graphql(
        graphqlOperation(clientsByClinicianID, {
          clinicianID
        })
      );

      setClients(data.clientsByClinicianID.items);
    };
    if (clinicianID) {
      getClients();
    }
  }, [clinicianID]);

  useEffect(() => {
    if (!clients) return;

    let filteredClients = clients.filter(client => {
      const clientFullName1 =
        `${client?.clientFirstName} ${client?.clientLastName}`.toLowerCase();
      const clientFullName2 =
        `${client?.firstName} ${client?.lastName}`.toLowerCase();
      const lowerSearch = search.toLowerCase();

      return (
        (client?.clientFirstName &&
          client?.clientFirstName.toLowerCase().includes(lowerSearch)) ||
        (client?.clientLastName &&
          client?.clientLastName.toLowerCase().includes(lowerSearch)) ||
        (client?.firstName &&
          client?.firstName.toLowerCase().includes(lowerSearch)) ||
        (client?.lastName &&
          client?.lastName.toLowerCase().includes(lowerSearch)) ||
        clientFullName1.includes(lowerSearch) ||
        clientFullName2.includes(lowerSearch)
      );
    });

    let grouped = filteredClients.reduce((r, e) => {
      let nameToUse = e.clientFirstName || e.firstName;
      let letter = nameToUse[0].toUpperCase();
      if (!r[letter]) r[letter] = { letter, children: [e] };
      else r[letter].children.push(e);
      return r;
    }, {});

    let groups;
    if (grouped) {
      groups = Object.values(grouped);
      groups.sort(function (a, b) {
        if (a.letter < b.letter) {
          return -1;
        }
        if (a.letter > b.letter) {
          return 1;
        }
        return 0;
      });
    }

    setLoading(false);
    setGroupedClients(groups);

    const timer = setTimeout(() => {
      setShowNoClientsBox(true);
    }, 500);

    return () => clearTimeout(timer);
  }, [clients, search]);

  const selectClient = client => {
    if (selectedClient && selectedClient.id === client.id) {
      setSelectedClient({});
    } else {
      setSelectedClient(client);
    }
  };

  const addResourcesToClient = async () => {
    try {
      setAddingResources(true);
      for (let resource of selectedResources) {
        await API.graphql(
          graphqlOperation(createClientResource, {
            input: {
              createdBy: user.username,
              clientID: selectedClient.id,
              owner: `${selectedClient.id}::${selectedClient.id}`,
              clinicianID,
              resourceID: resource.id
            }
          })
        );
      }
      enqueueSnackbar(
        `Added resources for ${
          selectedClient?.clientFirstName || selectedClient?.firstName
        }`,
        {
          severity: 'success'
        }
      );
      setState(false);
      setSelectedClient({});
      setSelectedResources([]);
    } catch (err) {
      console.log('error', err);
      enqueueSnackbar('Error adding resources', {
        severity: 'error'
      });
      setError(err.message);
    } finally {
      setAddingResources(false);
    }
  };

  return (
    <Box>
      <Modal
        title="Add resources to a client"
        fullWidth
        maxWidth="md"
        open={state || false}
        setClose={() => setState(state => !state)}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box>
              <Typography variant="h5">Share these resources ...</Typography>
              <Box my={2}>
                <Divider sx={{ opacity: 0.4 }} />
              </Box>
            </Box>
            <Box sx={{ height: 300, overflowY: 'auto' }}>
              {selectedResources?.map(resource => (
                <ResourcePreviewCard key={resource.id} resource={resource} />
              ))}
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box>
              <Typography variant="h5">... with this client </Typography>
              <Box my={2}>
                <Divider sx={{ opacity: 0.4 }} />
              </Box>
              <Collapse in={!selectedClient?.id}>
                <Box mt={1}>
                  <SearchBar state={search} setState={setSearch} />
                </Box>
              </Collapse>
            </Box>
            {loading ? (
              <CircularProgress />
            ) : (
              <Box display="flex">
                <Box
                  sx={{
                    height: 300,
                    overflowY: 'auto',
                    width: '100%'
                  }}
                >
                  {groupedClients &&
                    groupedClients.length > 0 &&
                    groupedClients.map(group => (
                      <Box
                        key={`ref-${group.letter}`}
                        mb={!selectedClient?.id ? 1 : 0}
                        mt={!selectedClient?.id ? 2 : 0}
                        id={`group-${group.letter}`}
                      >
                        {!selectedClient?.id && (
                          <>
                            <Typography color="text.accent" variant="h6">
                              {group.letter}
                            </Typography>
                            <Divider sx={{ opacity: 0.4 }} />
                          </>
                        )}
                        <Grid
                          container
                          sx={{ mt: !selectedClient?.id ? 1 : 0 }}
                          spacing={!selectedClient?.id ? 2 : 1}
                        >
                          {group.children.map(client => (
                            <ClientSelectCard
                              onSelect={selectClient}
                              selected={selectedClient.id === client.id}
                              key={client?.id}
                              client={client}
                              visible={
                                selectedClient.id === client.id ||
                                !selectedClient.id
                              }
                            />
                          ))}
                        </Grid>
                      </Box>
                    ))}
                  {!loading && showNoClientsBox && !groupedClients?.length && (
                    <Alert>
                      <AlertTitle>No Clients Found</AlertTitle>
                    </Alert>
                  )}
                </Box>
                {/* <ScrollToLetterBar
                  letters={existingLetters}
                  activeLetter={activeLetter}
                /> */}
              </Box>
            )}
          </Grid>
        </Grid>

        {Boolean(error) && (
          <Box my={2}>
            <ErrorMessage message={error} />
          </Box>
        )}

        <DialogActions sx={{ mt: 2 }}>
          <LoadingButton
            disabled={!Boolean(selectedClient.id)}
            onClick={addResourcesToClient}
            loading={addingResources}
            size="small"
          >
            Share Resources
          </LoadingButton>
        </DialogActions>
      </Modal>
    </Box>
  );
}

import React, { useEffect, useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react-core';
import {
  Box,
  Card,
  CardActionArea,
  Chip,
  Divider,
  FormLabel,
  Grid,
  Skeleton,
  Stack,
  Typography
} from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import { getEntry } from 'graphql/customQueries';
import { userInputsByRecordComponentType } from 'graphql/customQueries';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';
import { timeRenderer } from 'utils';

import {
  DisplayAudio,
  DisplayCheckbox,
  DisplayClock,
  DisplayInfinite,
  DisplayInput,
  DisplayRadio,
  DisplayRater
} from 'components/atoms';

import { getIconComponent } from '../IconPicker';

const renderInputComponent = input => {
  if (input.componentType === 'content') return null;
  switch (input.componentType) {
    case 'infinite':
      return input.numberValue != null && <DisplayInfinite input={input} />;
    case 'clock':
      return input.clockValue !== null && <DisplayClock input={input} />;
    case 'rater':
      return input.raterValue !== null && <DisplayRater input={input} />;
    case 'audio':
      return input.audioReady !== null && <DisplayAudio input={input} />;
    case 'input':
      return input.inputValue !== null && <DisplayInput input={input} />;
    case 'radio':
      return (
        input.radiosValue !== null &&
        input.radiosValue.length > 0 && <DisplayRadio input={input} />
      );
    case 'checkboxes':
      return (
        input.checkboxesValue !== null &&
        input.checkboxesValue.length !== 0 && <DisplayCheckbox input={input} />
      );
    default:
      return null;
  }
};

const processRecordData = async record => {
  const { data } = await API.graphql(
    graphqlOperation(userInputsByRecordComponentType, { recordID: record.id })
  );

  const sortedByComponentOrder = _.sortBy(
    data.userInputsByRecordComponentType.items,
    ['componentOrder']
  );
  const groupedByEntrySection = _.groupBy(
    sortedByComponentOrder,
    'component.entrySection.id'
  );
  const sortedGroups = _.mapValues(groupedByEntrySection, inputs =>
    _.sortBy(inputs, 'componentOrder')
  );

  const filteredGroups = _.mapValues(sortedGroups, inputs =>
    inputs.filter(input => {
      return (
        (input.componentType !== 'infinite' || input.numberValue !== null) &&
        (input.componentType !== 'input' || input.inputValue !== null) &&
        input.componentType !== 'content' &&
        (input.componentType !== 'rater' || input.raterValue !== null) &&
        (input.componentType !== 'checkboxes' ||
          input.checkboxesValue?.length) &&
        (input.componentType !== 'radio' || input.radiosValue?.length) &&
        (input.componentType !== 'clock' || input.clockValue !== null) &&
        (input.componentType !== 'audio' || input.audioReady !== null) &&
        true
      );
    })
  );

  const nonEmptyGroups = _.pickBy(filteredGroups, inputs => inputs.length > 0);

  return Object.entries(nonEmptyGroups)
    .sort(
      (a, b) =>
        a[1][0].component.entrySection.order -
        b[1][0].component.entrySection.order
    )
    .map(([id, inputs]) => inputs);
};

export default function RecordRow({ record }) {
  const navigate = useNavigate();
  const [entry, setEntry] = useState({});
  const [groupedInputs, setGroupedInputs] = useState([]);
  const { user } = useAuthenticator(context => [context.user]);
  const isClinician = user.attributes['custom:type'] === 'clinician';

  useEffect(() => {
    async function fetchEntry() {
      const data = await API.graphql(
        graphqlOperation(getEntry, { id: record.entryID })
      );
      setEntry(data.data.getEntry);
    }
    fetchEntry();
    return () => {
      setEntry({});
    };
  }, [record]);

  useEffect(() => {
    async function processRecord() {
      const groupedInputs = await processRecordData(record);
      setGroupedInputs(groupedInputs);
    }
    processRecord();
    return () => {
      setGroupedInputs([]);
    };
  }, [record]);

  const date = DateTime.fromISO(record.enteredAt);

  return (
    <Card
      sx={{ my: 1, position: 'relative' }}
      elevation={4}
      variant={record.editing ? 'outlined' : 'elevation'}
    >
      <CardActionArea
        sx={{ p: 1, pl: { xs: 2, md: 1 } }}
        onClick={() =>
          navigate(
            isClinician
              ? `/client/entries/${record.id}`
              : `/entries/${record.id}`
          )
        }
      >
        <Grid container>
          <Grid container>
            <Grid
              item
              sm={2}
              sx={{ pl: { xs: 0, md: 0.5 }, pr: { xs: 2, md: 0 } }}
            >
              <FormLabel sx={{ fontSize: '0.9rem' }}>Timestamp</FormLabel>
              <Box sx={{ mt: 1.5 }}>
                <Typography variant="overline">
                  {timeRenderer({ data: date.day })}-
                  {timeRenderer({ data: date.month })}-
                  {timeRenderer({ data: date.year })}
                </Typography>
                &nbsp;&nbsp;·&nbsp;&nbsp;
                <Typography variant="overline">
                  {timeRenderer({ data: date.hour })}:
                  {timeRenderer({ data: date.minute })}
                </Typography>
              </Box>
            </Grid>
            <Grid item sm={2} sx={{ mb: { xs: 2, md: 0 } }}>
              <FormLabel sx={{ fontSize: '0.9rem' }}>
                {entry ? entry?.section?.name : <Skeleton />}
              </FormLabel>
              <Box mt={0.5}>
                {entry ? (
                  <Chip
                    icon={getIconComponent(entry?.icon)}
                    sx={{ mt: 1 }}
                    label={entry?.name}
                  />
                ) : (
                  <Skeleton height={25} width={50} />
                )}
              </Box>
            </Grid>
            <Grid
              sx={{
                pl: { xs: 0, sm: 1, lg: 1 },
                overflowX: 'auto',
                maxWidth: '100%',
                '&::-webkit-scrollbar': {
                  display: 'none'
                },
                '-webkit-overflow-scrolling': 'touch',
                scrollbarWidth: 'none',
                '-ms-overflow-style': 'none'
              }}
              item
              sm={8}
              display="flex"
            >
              {groupedInputs.map((group, index) => (
                <Box key={index} display="flex" alignItems="flex-start">
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100%'
                    }}
                  >
                    {group[0].component.entrySection?.name && (
                      <Box>
                        <FormLabel
                          sx={{
                            fontSize: '0.9rem',
                            lineHeight: '0.8rem',
                            whiteSpace: 'nowrap'
                          }}
                        >
                          {group[0].component.entrySection.name}
                        </FormLabel>
                      </Box>
                    )}
                    <Stack
                      direction="row"
                      sx={{ alignItems: 'center', flexGrow: 1, mt: 1 }}
                    >
                      {group.map((input, index) => (
                        <React.Fragment key={input.id}>
                          {index !== 0 && (
                            <Divider
                              orientation="vertical"
                              flexItem
                              sx={{ opacity: 0.1, mx: 1 }}
                            />
                          )}
                          {renderInputComponent(input)}
                        </React.Fragment>
                      ))}
                    </Stack>
                  </Box>
                  {index !== groupedInputs.length - 1 && (
                    <Divider
                      orientation="vertical"
                      sx={{
                        opacity: 0.3,
                        mx: 2,
                        height: 'auto',
                        alignSelf: 'stretch'
                      }}
                    />
                  )}
                </Box>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </CardActionArea>
      {record.draft && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            width: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            color: 'white'
          }}
        >
          <Typography
            variant="caption"
            sx={{
              transform: 'rotate(-90deg)',
              whiteSpace: 'nowrap'
            }}
          >
            DRAFT
          </Typography>
        </Box>
      )}
    </Card>
  );
}

import { useCallback, useEffect, useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react-core';
import {
  DndContext,
  MouseSensor,
  closestCenter,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import {
  restrictToVerticalAxis,
  restrictToWindowEdges
} from '@dnd-kit/modifiers';
import {
  SortableContext,
  arrayMove,
  rectSortingStrategy,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import { Stack } from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import { normalizeFactor } from 'common/constants';
import { useAppContext } from 'context';
import {
  createComponent,
  updateComponent,
  updateEntrySection
} from 'graphql/mutations';
import { useDebouncedCallback } from 'use-debounce';

import { DividerButton } from 'components/atoms';
import { EntryComponentSelectionDialog } from 'components/molecules';
import { Section } from 'components/molecules';
import { EntryComponent } from 'components/organisms';

export const SortableEntrySection = function (props) {
  const {
    disableDrag,
    edit,
    entrySection,
    label,
    onDeleteEntrySection,
    setSections,
    setChangedData
  } = props;
  const { state } = useAppContext();
  const record = state?.record;
  const sensors = useSensors(useSensor(MouseSensor));
  const [orientation, setOrientation] = useState('column');
  const { user } = useAuthenticator(context => [context.user]);
  const clientID =
    state.client?.id || user.attributes['custom:clientID'] || user.username;

  const [components, setComponents] = useState(
    entrySection?.components?.items
      ? entrySection?.components.items
          ?.filter(comp => !comp.complete)
          .sort((a, b) => a.order - b.order)
      : entrySection?.components
          ?.filter(comp => !comp.complete)
          .sort((a, b) => a.order - b.order) || []
  );

  const [componentSelectionDialog, setComponentSelectionDialog] =
    useState(false);
  const [isCreatingComponent, setIsCreatingComponent] = useState(false);

  const { id: entrySectionID } = entrySection;

  const createComponent = useCreateComponent(
    setComponents,
    setIsCreatingComponent,
    clientID
  );
  const dragComponent = useDragComponent(components, setComponents);
  const updateEntrySection = useUpdateEntrySection(setSections);

  const debouncedUpdateEntrySectionName = useDebouncedCallback(
    updateEntrySection,
    300
  );

  const handleDeleteSection = useCallback(() => {
    onDeleteEntrySection?.(entrySection);
  }, [entrySection, onDeleteEntrySection]);

  const handleDragComponent = useCallback(
    ({ active, over }) => {
      dragComponent({ active, over });
    },
    [dragComponent]
  );

  useEffect(() => {
    setSections(prevSections =>
      prevSections.map(section =>
        section.id === entrySectionID
          ? { ...section, components: components }
          : section
      )
    );
  }, [components, entrySectionID, setSections]);

  const handleSelectComponent = (componentType, name) => {
    createComponent({
      entrySectionID,
      entrySectionComponentsId: entrySectionID,
      name,
      order:
        (components[components.length - 1]?.order ?? 0) + 1 * normalizeFactor,
      type: componentType
    });
    setComponentSelectionDialog(false);
  };

  let chosenStrategy;

  const entrySectionSettings = entrySection?.settings
    ? JSON.parse(entrySection?.settings)
    : null;
  const currentOrientation = entrySectionSettings?.orientation || orientation;

  if (currentOrientation === 'row') {
    chosenStrategy = rectSortingStrategy;
  } else {
    chosenStrategy = verticalListSortingStrategy;
  }

  const modifiers =
    currentOrientation === 'row'
      ? [restrictToWindowEdges]
      : [restrictToVerticalAxis, restrictToWindowEdges];

  const renderComponents = () => {
    if (!record?.id) return null;

    const userInputLookup = new Map(
      record.userInputs.items.map(ui => [ui.componentID, ui])
    );

    return components.map(component => {
      const userInput = userInputLookup.get(component.id);
      const userInputID = userInput?.id;

      return (
        <EntryComponent
          setChangedData={setChangedData}
          key={component.id}
          component={component}
          componentID={component.id}
          componentOrder={component.order}
          disableDrag={components.length === 1}
          edit={edit}
          setComponents={setComponents}
          userInput={userInput}
          userInputID={userInputID}
        />
      );
    });
  };

  return (
    <Section
      orientation={orientation}
      setOrientation={setOrientation}
      elevation={4}
      disableDrag={disableDrag}
      disableButtons={
        entrySection?.noEdit !== null ? entrySection?.noEdit : false
      }
      section={entrySection}
      label={label}
      onDelete={handleDeleteSection}
      updateSection={({ id, name, _version }) =>
        debouncedUpdateEntrySectionName({ id, name, _version })
      }
    >
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        modifiers={modifiers}
        onDragEnd={handleDragComponent}
      >
        <SortableContext items={components} strategy={chosenStrategy}>
          <Stack direction="column" spacing={2}>
            <Stack
              direction={
                JSON.parse(entrySection?.settings)?.orientation || orientation
              }
              spacing={2}
            >
              {renderComponents()}
            </Stack>
            {edit && !entrySection?.noEdit && (
              <DividerButton
                loading={isCreatingComponent}
                componentSelectionDialog={componentSelectionDialog}
                onClick={() =>
                  setComponentSelectionDialog(prevOpen => !prevOpen)
                }
              />
            )}
          </Stack>
        </SortableContext>
      </DndContext>
      <EntryComponentSelectionDialog
        open={componentSelectionDialog}
        onClose={() => setComponentSelectionDialog(false)}
        onSelect={handleSelectComponent}
      />
    </Section>
  );
};

function useCreateComponent(setComponents, setIsCreatingComponent, clientID) {
  return useCallback(
    async ({ entrySectionID, name = 'Component', order, type, clientID }) => {
      try {
        setIsCreatingComponent(true);
        const result = await API.graphql(
          graphqlOperation(createComponent, {
            input: {
              entrySectionID,
              entrySectionComponentsId: entrySectionID,
              name,
              order,
              owner: `${clientID}::${clientID}`,
              type,
              clientID
            }
          })
        );
        const createdComponent = result.data.createComponent;
        setComponents(prevComponents =>
          prevComponents.concat(createdComponent)
        );
      } catch {
      } finally {
        setIsCreatingComponent(false);
      }
    },
    [setComponents, setIsCreatingComponent]
  );
}

function useDragComponent(components, setComponents) {
  return useCallback(
    async ({ active, over }) => {
      try {
        if (over) {
          const overIndex = components.findIndex(
            section => section.id === over.id
          );
          const activeIndex = components.findIndex(
            section => section.id === active.id
          );
          if (activeIndex !== overIndex) {
            const newOrder =
              activeIndex < overIndex
                ? overIndex === components.length - 1
                  ? components[overIndex].order + 1 * normalizeFactor
                  : (components[overIndex].order +
                      components[overIndex + 1].order) /
                    2
                : (components[overIndex].order +
                    (components[overIndex - 1]?.order ?? 0)) /
                  2;
            const dropppedComponent = {
              ...components[activeIndex],
              order: newOrder
            };
            setComponents(prevComponents =>
              arrayMove(prevComponents, activeIndex, overIndex).map(entry =>
                entry.id === dropppedComponent.id ? dropppedComponent : entry
              )
            );
            const { data } = await API.graphql(
              graphqlOperation(updateComponent, {
                input: {
                  id: dropppedComponent.id,
                  order: dropppedComponent.order,
                  _version: dropppedComponent._version
                }
              })
            );
            const updatedComponent = data.updateComponent;
            setComponents(prevComponents =>
              arrayMove(prevComponents, activeIndex, overIndex).map(entry =>
                entry.id === updatedComponent.id ? updatedComponent : entry
              )
            );
          }
        }
      } catch (e) {
        console.log('error: ', e);
      }
    },
    [components, setComponents]
  );
}

function useUpdateEntrySection(setEntrySections) {
  return useCallback(
    async updateEntrySectionInput => {
      const result = await API.graphql(
        graphqlOperation(updateEntrySection, { input: updateEntrySectionInput })
      );
      const updatedEntrySection = result.data.updateEntrySection;

      const normalizedUpdatedEntrySection = {
        ...updatedEntrySection,
        components: updatedEntrySection.components
          ? Array.isArray(updatedEntrySection.components)
            ? updatedEntrySection.components
            : Array.isArray(updatedEntrySection.components.items)
            ? updatedEntrySection.components.items
            : []
          : []
      };

      setEntrySections(prevEntrySections =>
        prevEntrySections.map(prevEntrySection =>
          prevEntrySection.id === normalizedUpdatedEntrySection.id
            ? normalizedUpdatedEntrySection
            : prevEntrySection
        )
      );
    },
    [setEntrySections]
  );
}

import { useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  Stack,
  Step,
  StepLabel,
  Stepper
} from '@mui/material';
import { API, Auth, Storage, graphqlOperation } from 'aws-amplify';
import { ACTION } from 'common/constants';
import { useSnackbar } from 'context/SnackBar';
import {
  createClinic,
  createInvitation,
  updateClinician,
  updateLocation
} from 'graphql/mutations';
import {
  clientsClinicConnection,
  cliniciansClinicConnection,
  getClinician,
  updateUserGroups
} from 'graphql/queries';
import { DateTime } from 'luxon';
import * as yup from 'yup';

import { getCountryByTimezone } from 'utils/getCountryFromTimeZone';

import Modal from '../Modal';
import StepOne from './StepOne';
import StepThree from './StepThree';
import StepTwo from './StepTwo';

export default function AddClinic({ open, setClose }) {
  const { user } = useAuthenticator(context => [context.user]);
  const steps = ['Basic Details', 'Locations', 'Complete'];
  const [activeStep, setActiveStep] = useState(0);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [website, setWebsite] = useState('');
  const [logo, setLogo] = useState();
  const [errors, setErrors] = useState({});
  const [isFirstLocationNameEdited, setIsFirstLocationNameEdited] =
    useState(false);
  const [creating, setCreating] = useState(false);
  const [hasAdmin, setHasAdmin] = useState(false);
  const [admins, setAdmins] = useState([]);
  const [adminEmail, setAdminEmail] = useState('');
  const [adminFirstName, setAdminFirstName] = useState('');
  const [adminLastName, setAdminLastName] = useState('');
  const [role, setRole] = useState('clinician');
  const [selectedClinic, setSelectedClinic] = useState({});
  const [selectedClinicLocation, setSelectedClinicLocation] = useState({});
  const [cliniciansInClinic, setCliniciansInClinic] = useState([]);
  const [clinician, setClinician] = useState({});
  const [hasClinician, setHasClinician] = useState(false);
  const userTimezone = DateTime.local().zoneName;
  const defaultCountryKebab = getCountryByTimezone(userTimezone);
  const [locations, setLocations] = useState([]);
  const [howManyClinicians, setHowManyClinicians] = useState();
  const [shouldShowAdmin, setShouldShowAdmin] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const stepZeroValidationSchema = yup.object({
    shouldShowAdmin: yup.boolean(),
    hasAdmin: yup.boolean(),
    role: yup.string().required('Role is required.'),
    name: yup.string().required('Name is required.'),
    description: yup
      .string()
      .nullable()
      .transform(value => (value === '' ? null : value))
      .min(50, 'Description must be at least 50 characters long'),
    website: yup.string().url('Enter a valid website').notRequired(),
    adminFirstName: yup
      .string()
      .nullable()
      .when(['shouldShowAdmin', 'hasAdmin', 'role', 'name'], {
        is: (shouldShowAdmin, hasAdmin, role, name) =>
          shouldShowAdmin && !hasAdmin && role !== 'admin' && Boolean(name),
        then: () => yup.string().required('Admin first name is required'),
        otherwise: () => yup.string().nullable()
      }),
    adminLastName: yup
      .string()
      .nullable()
      .when(['shouldShowAdmin', 'hasAdmin', 'role', 'name'], {
        is: (shouldShowAdmin, hasAdmin, role, name) =>
          shouldShowAdmin && !hasAdmin && role !== 'admin' && Boolean(name),
        then: () => yup.string().required('Admin last name is required'),
        otherwise: () => yup.string().nullable()
      }),
    adminEmail: yup
      .string()
      .email('Enter a valid email')
      .nullable()
      .when(['shouldShowAdmin', 'hasAdmin', 'role', 'name'], {
        is: (shouldShowAdmin, hasAdmin, role, name) =>
          shouldShowAdmin && !hasAdmin && role !== 'admin' && Boolean(name),
        then: () => yup.string().required('Admin email is required'),
        otherwise: () => yup.string().nullable()
      })
  });

  const stepOneValidationSchema = yup.object({
    locations: yup.array().min(1, 'At least one location is required.')
  });

  const handleNext = async () => {
    let validationSchema;
    const currentData = {
      shouldShowAdmin,
      hasAdmin,
      role,
      name,
      description,
      website,
      locations,
      adminEmail,
      adminFirstName,
      adminLastName
    };
    if (activeStep === 0) {
      validationSchema = stepZeroValidationSchema;
    } else if (activeStep === 1) {
      validationSchema = stepOneValidationSchema;
    }
    try {
      if (validationSchema) {
        await validationSchema.validate(currentData, { abortEarly: false });
        setErrors({});
        setActiveStep(prevActiveStep => prevActiveStep + 1);
      }
    } catch (validationErrors) {
      const newErrors = validationErrors.inner.reduce((acc, error) => {
        const path = error.path || '';
        const pathParts = path.split('.');
        if (pathParts.length > 1 && !isNaN(parseInt(pathParts[0]))) {
          const index = parseInt(pathParts[0]);
          const field = pathParts.slice(1).join('.');
          if (!acc[index]) acc[index] = {};
          acc[index][field] = error.message;
        } else {
          acc[path] = error.message;
        }
        return acc;
      }, {});
      setErrors(newErrors);
    }
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setName('');
    setDescription('');
    setWebsite('');
    setLogo();
    setErrors({});
    setIsFirstLocationNameEdited(false);
    setLocations([]);
    setRole('clinician');
    setHasAdmin(false);
    setAdmins([]);
    setAdminEmail('');
    setAdminFirstName('');
    setAdminLastName('');
    setSelectedClinic({});
    setSelectedClinicLocation({});
    setCliniciansInClinic([]);
    setClinician({});
    setHasClinician(false);
    setHowManyClinicians();
  };

  const canReset = () =>
    name !== '' ||
    description !== '' ||
    website !== '' ||
    logo !== undefined ||
    locations.some((location, index) => {
      if (index === 0) {
        return (
          location.name !== name ||
          location.street !== '' ||
          location.street2 !== '' ||
          location.city !== '' ||
          location.postcode !== null ||
          location.state !== '' ||
          location.country !== defaultCountryKebab
        );
      }
      return (
        location.name !== '' ||
        location.street !== '' ||
        location.street2 !== '' ||
        location.city !== '' ||
        location.postcode !== null ||
        location.state !== '' ||
        location.country !== defaultCountryKebab
      );
    });

  const addImage = async (clinicID, imageType) => {
    if (!logo) return;
    try {
      const key = `clinics/${clinicID}.${imageType}`;
      await Storage.put(key, logo, {
        contentType: `image/${imageType}`
      });
    } catch (err) {
      console.log(err);
      throw new Error(err);
    }
  };

  function evaluateConditions() {
    if (hasAdmin) {
      return ACTION.ADMIN_EXISTS;
    }
    if (howManyClinicians && (role === 'admin' || role === 'practicemanager')) {
      return ACTION.NEEDS_CLINICIAN_CONFIRMATION;
    }
    if (hasClinician) {
      return clinician.clinic?.id
        ? ACTION.CLINICIAN_WITH_CLINIC
        : ACTION.CLINICIAN_WITHOUT_CLINIC;
    }
    if (adminEmail) {
      return ACTION.INVITE_ADMIN_TO_CLINIC;
    }
    if (selectedClinic.id) {
      return ACTION.SELECTED_CLINIC;
    }
    return ACTION.DEFAULT;
  }

  const createNewInvitation = async (
    action,
    inviteeID,
    clinicID,
    manualRole
  ) => {
    const variables = {
      inviteeID,
      locationID: selectedClinicLocation.id,
      clinicID: clinicID ?? selectedClinic.id,
      createdBy: user.username,
      action: action,
      role: manualRole ?? role
    };

    const { data } = await API.graphql(
      graphqlOperation(createInvitation, {
        input: variables
      })
    );
    return data.createInvitation.id;
  };

  const handleCliniciansClinicConnection = async config => {
    const {
      adminEmail,
      adminFirstName,
      adminLastName,
      invitationID,
      action,
      name = null,
      inviteeID = null,
      inviteeEmail = null,
      clinicID = null
    } = config;
    try {
      const variables = {
        adminFirstName: adminFirstName,
        adminLastName: adminLastName,
        adminEmail: adminEmail,
        invitationID,
        firstName: user.attributes.given_name,
        lastName: user.attributes.family_name,
        email: user.attributes.email,
        clinicID: clinicID ?? selectedClinic.id,
        locationID: selectedClinicLocation.id,
        action: action,
        role: role,
        name,
        inviteeID,
        inviteeEmail,
        createdBy: user.username
      };

      const { data } = await API.graphql(
        graphqlOperation(cliniciansClinicConnection, variables)
      );
      return data.cliniciansClinicConnection;
    } catch (err) {
      console.log(err);
    }
  };

  const submitForm = async () => {
    try {
      setCreating(true);
      const conditionState = evaluateConditions();
      let invitationID, actionResult, message;
      switch (conditionState) {
        case ACTION.NEEDS_CLINICIAN_CONFIRMATION:
          invitationID = await createNewInvitation(
            ACTION.NEEDS_CLINICIAN_CONFIRMATION,
            user.username
          );
          actionResult = await handleCliniciansClinicConnection({
            inviteeID: user.username,
            invitationID,
            action: ACTION.NEEDS_CLINICIAN_CONFIRMATION,
            name: selectedClinic.name
          });
          message = actionResult.body;
          break;
        case ACTION.ADMIN_EXISTS:
          invitationID = await createNewInvitation(
            ACTION.ADMIN_EXISTS,
            user.username
          );
          actionResult = await handleCliniciansClinicConnection({
            inviteeID: user.username,
            invitationID,
            action: ACTION.ADMIN_EXISTS
          });
          message = actionResult.body;
          break;
        case ACTION.CLINICIAN_WITH_CLINIC:
          invitationID = await createNewInvitation(
            ACTION.CLINICIAN_WITH_CLINIC,
            clinician.id,
            clinician.clinic.id
          );
          actionResult = await handleCliniciansClinicConnection({
            invitationID,
            action: ACTION.CLINICIAN_WITH_CLINIC,
            name,
            inviteeID: clinician.id
          });
          message = actionResult.body;
          break;
        case ACTION.CLINICIAN_WITHOUT_CLINIC:
          invitationID = await createNewInvitation(
            ACTION.CLINICIAN_WITHOUT_CLINIC,
            clinician.id
          );
          actionResult = await handleCliniciansClinicConnection({
            invitationID,
            action: ACTION.CLINICIAN_WITHOUT_CLINIC,
            name,
            inviteeID: clinician.id,
            inviteeEmail: clinician.email
          });
          message = actionResult.body;
          break;
        case ACTION.INVITE_ADMIN_TO_CLINIC:
          let clinicToAddTo;
          if (!selectedClinic.id) {
            const createClinicVariables = {
              name: name
            };
            const { data } = await API.graphql(
              graphqlOperation(createClinic, { input: createClinicVariables })
            );
            const clinicID = data.createClinic.id;
            clinicToAddTo = clinicID;
          } else {
            clinicToAddTo = selectedClinic.id;
          }

          if (locations.length > 0) {
            for (let location of locations) {
              if (!location.clinicID) {
                const location_variables = {
                  id: location.id,
                  clinicID: clinicToAddTo,
                  clinicLocationsId: clinicToAddTo,
                  _version: location._version
                };
                await API.graphql(
                  graphqlOperation(updateLocation, {
                    input: location_variables
                  })
                );
              }
            }
          }
          await API.graphql(
            graphqlOperation(clientsClinicConnection, {
              clinicianID: user.username,
              locationID: selectedClinicLocation.id,
              clinicID: clinicToAddTo,
              action: 'add_to_clinic_and_location'
            })
          );
          const clinicianToAddRes = await API.graphql(
            graphqlOperation(getClinician, { id: user.username })
          );

          const invite_admin_vars_for_clinic = {
            id: user.username,
            clinicID: clinicToAddTo,
            clinicCliniciansId: clinicToAddTo,
            role,
            _version: clinicianToAddRes.data.getClinician._version
          };
          if (selectedClinicLocation.id) {
            await Auth.updateUserAttributes(user, {
              'custom:locationID': selectedClinicLocation.id
            });
            invite_admin_vars_for_clinic.locationCliniciansId =
              selectedClinicLocation.id;
            invite_admin_vars_for_clinic.locationID = selectedClinicLocation.id;
          }
          await API.graphql(
            graphqlOperation(updateClinician, {
              input: invite_admin_vars_for_clinic
            })
          );
          await Auth.updateUserAttributes(user, {
            'custom:clinicID': clinicToAddTo
          });

          invitationID = await createNewInvitation(
            ACTION.INVITE_ADMIN_TO_CLINIC,
            clinician.id,
            clinicToAddTo,
            'admin'
          );
          actionResult = await handleCliniciansClinicConnection({
            invitationID,
            action: ACTION.INVITE_ADMIN_TO_CLINIC,
            name,
            inviteeID: clinician.id,
            inviteeEmail: clinician.email,
            adminEmail,
            adminFirstName,
            adminLastName,
            clinicID: clinicToAddTo,
            role: 'admin'
          });
          message = actionResult.body;
          break;
        case ACTION.SELECTED_CLINIC:
          if (locations.length > 0) {
            for (let location of locations) {
              if (!location.clinicID) {
                const location_variables = {
                  id: location.id,
                  clinicID: selectedClinic.id,
                  clinicLocationsId: selectedClinic.id,
                  _version: location._version
                };
                await API.graphql(
                  graphqlOperation(updateLocation, {
                    input: location_variables
                  })
                );
              }
            }
          }
          const clinicianToAddToClinicRes = await API.graphql(
            graphqlOperation(getClinician, { id: user.username })
          );
          const selected_clinic_vars = {
            id: user.username,
            clinicID: selectedClinic.id,
            clinicCliniciansId: selectedClinic.id,
            _version: clinicianToAddToClinicRes.data.getClinician._version
          };
          if (selectedClinicLocation.id) {
            await Auth.updateUserAttributes(user, {
              'custom:locationID': selectedClinicLocation.id
            });
            selected_clinic_vars.locationCliniciansId =
              selectedClinicLocation.id;
            selected_clinic_vars.locationID = selectedClinicLocation.id;
          }
          await API.graphql(
            graphqlOperation(updateClinician, {
              input: selected_clinic_vars
            })
          );
          await Auth.updateUserAttributes(user, {
            'custom:clinicID': selectedClinic.id
          });
          await API.graphql(
            graphqlOperation(clientsClinicConnection, {
              clinicianID: user.username,
              locationID: selectedClinicLocation.id,
              clinicID: selectedClinic.id,
              action: 'add_to_clinic_and_location'
            })
          );
          actionResult = await handleCliniciansClinicConnection({
            action: ACTION.SELECTED_CLINIC,
            name: selectedClinic.name
          });
          message = 'Joined clinic';
          break;
        default:
          let imageType;
          if (Boolean(logo)) {
            imageType = logo.type.split('/')[1];
          }
          const createClinicVariables = {
            name: name,
            description,
            website,
            hasLogo: Boolean(logo),
            imageType: imageType
          };
          const { data } = await API.graphql(
            graphqlOperation(createClinic, { input: createClinicVariables })
          );
          const clinicID = data.createClinic.id;
          if (Boolean(logo)) {
            await addImage(clinicID, imageType);
          }
          if (locations.length > 0) {
            for (let location of locations) {
              if (!location.clinicID) {
                const variables = {
                  id: location.id,
                  clinicID: clinicID,
                  clinicLocationsId: clinicID,
                  _version: location._version
                };
                await API.graphql(
                  graphqlOperation(updateLocation, { input: variables })
                );
              }
            }
          }
          await API.graphql(
            graphqlOperation(updateUserGroups, { userID: user.username, role })
          );
          const clinicianRes = await API.graphql(
            graphqlOperation(getClinician, { id: user.username })
          );
          const clinicianVersion = clinicianRes.getClinician._version;
          const updateClinicianVariables = {
            id: user.username,
            clinicID: clinicID,
            clinicCliniciansId: clinicID,
            role: role,
            _version: clinicianVersion
          };
          if (selectedClinicLocation.id) {
            await Auth.updateUserAttributes(user, {
              'custom:locationID': selectedClinicLocation.id
            });
            updateClinicianVariables.locationCliniciansId =
              selectedClinicLocation.id;
            updateClinicianVariables.locationID = selectedClinicLocation.id;
          }
          await API.graphql(
            graphqlOperation(updateClinician, {
              input: updateClinicianVariables
            })
          );
          await Auth.updateUserAttributes(user, {
            'custom:clinicID': clinicID
          });
          await API.graphql(
            graphqlOperation(clientsClinicConnection, {
              clinicianID: user.username,
              locationID: selectedClinicLocation.id,
              clinicID: clinicID,
              action: 'add_to_clinic_and_location'
            })
          );
          await handleCliniciansClinicConnection({
            action: ACTION.DEFAULT,
            name
          });
          message = 'Created clinic';
          break;
      }
      if (actionResult && actionResult?.statusCode !== 200) {
        throw new Error(actionResult.body);
      }
      await Auth.currentAuthenticatedUser();
      handleReset();
      enqueueSnackbar(message, {
        severity: 'success'
      });
      setClose();
    } catch (err) {
      console.log(err.message);
      enqueueSnackbar('Something went wrong. Try again.', {
        severity: 'error'
      });
    } finally {
      setCreating(false);
    }
  };

  return (
    <Modal
      fullWidth
      open={open}
      setClose={setClose}
      title="Add practice"
      maxWidth="md"
    >
      <Box>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map(label => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent={creating ? 'center' : 'flex-start'}
          alignItems="center"
          sx={{ minHeight: 400, pt: 2 }}
        >
          {creating ? (
            <Box
              sx={{
                height: '100%',
                width: '100%',
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box height="100%" width="100%" pb={4}>
              {activeStep === 0 && (
                <StepOne
                  //
                  shouldShowAdmin={shouldShowAdmin}
                  setShouldShowAdmin={setShouldShowAdmin}
                  // if there is no admin, and a user wants pm or admin status,
                  // set if there are clinicians in the clinic
                  hasClinician={hasClinician}
                  setHasClinician={setHasClinician}
                  // set how many clinicians
                  howManyClinicians={howManyClinicians}
                  setHowManyClinicians={setHowManyClinicians}
                  // set the email addresses for the clinicians
                  cliniciansInClinic={cliniciansInClinic}
                  setCliniciansInClinic={setCliniciansInClinic}
                  //
                  // if a user enters an admin email and there is an existing clinician
                  clinician={clinician}
                  setClinician={setClinician}
                  //
                  // variables to create the new admin
                  adminEmail={adminEmail}
                  setAdminEmail={setAdminEmail}
                  adminFirstName={adminFirstName}
                  setAdminFirstName={setAdminFirstName}
                  adminLastName={adminLastName}
                  setAdminLastName={setAdminLastName}
                  //
                  // if a user wants to join a clinic which has admins
                  // determine if there are admins
                  hasAdmin={hasAdmin}
                  setHasAdmin={setHasAdmin}
                  // set the admins
                  setAdmins={setAdmins}
                  admins={admins}
                  //
                  // when a user searches for a clinic and selects one select it
                  selectedClinic={selectedClinic}
                  setSelectedClinic={setSelectedClinic}
                  //
                  // set the user's preferred role in the clinc
                  role={role}
                  setRole={setRole}
                  //
                  // details for the clinic
                  // search name and practice name
                  name={name}
                  setName={setName}
                  // description
                  description={description}
                  setDescription={setDescription}
                  // website
                  website={website}
                  setWebsite={setWebsite}
                  // logo
                  logo={logo}
                  setLogo={setLogo}
                  //
                  // validation
                  // errors
                  errors={errors}
                  setErrors={setErrors}
                />
              )}
              {activeStep === 1 && (
                <StepTwo
                  //
                  // pass the variables
                  role={role}
                  name={name}
                  selectedClinic={selectedClinic}
                  //
                  // if the first name is edited
                  // we use this as the default for the location name
                  isFirstLocationNameEdited={isFirstLocationNameEdited}
                  setIsFirstLocationNameEdited={setIsFirstLocationNameEdited}
                  //
                  // set the locations for the clinic
                  selectedClinicLocation={selectedClinicLocation}
                  setSelectedClinicLocation={setSelectedClinicLocation}
                  //
                  // existing locations
                  locations={locations}
                  setLocations={setLocations}
                  //
                  // validation
                  // errors
                  errors={errors}
                  setErrors={setErrors}
                />
              )}
              {activeStep === 2 && (
                <StepThree
                  adminEmail={adminEmail}
                  adminFirstName={adminFirstName}
                  adminLastName={adminLastName}
                  selectedClinicLocation={selectedClinicLocation}
                  locations={locations}
                  name={name}
                  description={description}
                  website={website}
                  logo={logo}
                />
              )}
              {activeStep === steps.length && (
                <>
                  <Box sx={{ mb: 2 }}>
                    All steps completed - you&apos;re finished
                  </Box>
                </>
              )}
            </Box>
          )}
        </Box>
        <DialogActions
          sx={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Button disabled={activeStep === 0} onClick={handleBack}>
            Back
          </Button>

          <Stack direction="row" alignItems="center" spacing={2}>
            <Button disabled={!canReset()} onClick={handleReset}>
              Reset
            </Button>
            <LoadingButton
              loading={creating}
              variant="contained"
              onClick={
                activeStep === steps.length - 1 ? submitForm : handleNext
              }
            >
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </LoadingButton>
          </Stack>
        </DialogActions>
      </Box>
    </Modal>
  );
}

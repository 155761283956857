import { useEffect, useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { Check, Close } from '@mui/icons-material';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'context/SnackBar';
import { getClient, getClinician, getInvitation } from 'graphql/queries';
import { useParams } from 'react-router-dom';

import { ActionButton, BasicUserCard, ErrorMessage } from 'components/atoms';
import {
  handleInvitationError,
  performLinkingAction,
  useFetchData
} from 'components/molecules/LinkAccounts';

import { extractData, linkAction } from 'utils/linkingUtils';

export default function LinkClinician() {
  const { user } = useAuthenticator(context => [context.user]);
  const fetchData = useFetchData();
  const { id } = useParams();

  // State variables
  const [client, setClient] = useState(null);
  const [clinician, setClinician] = useState(null);
  const [confirming, setConfirming] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [rejecting, setRejecting] = useState(false);
  const [rejected, setRejected] = useState(false);
  const [error, setError] = useState(null);
  const [noMatch, setNoMatch] = useState(false);
  const [invitation, setInvitation] = useState(null);
  const [expired, setExpired] = useState(true);
  const [loading, setLoading] = useState(true);
  const [introMessage, setIntroMessage] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchInvitation = async () => {
      try {
        const data = await fetchData(getInvitation, { id });
        if (data) {
          setInvitation(data.getInvitation);
        }
      } catch (e) {
        setLoading(false);
        setError('No invitation found.');
        console.error(e);
      }
    };

    if (id) fetchInvitation();
  }, [id, fetchData]);

  useEffect(() => {
    const checkInvitationDetails = async () => {
      try {
        if (!invitation || !user) return;
        const isExpired =
          new Date() - new Date(invitation.createdAt) > 72 * 60 * 60 * 1000;
        setExpired(isExpired);
        if (isExpired) {
          setLoading(false);
        } else {
          const isMatch = user.username === invitation.inviteeID;
          setNoMatch(!isMatch);
          if (!isMatch) {
            setLoading(false);
          } else if (isMatch && invitation.clinicianID) {
            const data = await fetchData(getClinician, {
              id: invitation.clinicianID
            });
            if (data) {
              setClinician(data.getClinician);
              setLoading(false);
            }
          } else if (
            isMatch &&
            invitation.clientID &&
            invitation.action === 'existing_client_as_participant'
          ) {
            const data = await fetchData(getClient, {
              id: invitation.clientID
            });
            setClient(data.getClient);
            setLoading(false);
          }
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    };

    checkInvitationDetails();
  }, [invitation, user, fetchData]);

  const performAction = async (actionType, setInProgress, setResult) => {
    const response = await linkAction({
      action: actionType,
      source: 'client',
      performLinkingAction,
      setInProgress,
      setResult,
      setError,
      handleInvitationError,
      invitationID: invitation.id,
      clientData: extractData(user, 'client'),
      clinicianData: clinician ? extractData(clinician, 'clinician') : null
    });
    if (response) {
      enqueueSnackbar(
        actionType.toLowerCase().includes('confirm')
          ? 'Invitation accepted'
          : actionType.toLowerCase().includes('reject')
          ? 'Invitation rejected'
          : 'Action successful',
        { severity: 'success' }
      );
    }
  };

  useEffect(() => {
    if (invitation?.id) {
      if (invitation.action === 'link_client_and_clinician') {
        setIntroMessage('A clinician is requesting access to your account.');
      } else if (invitation.action === 'existing_client_as_participant') {
        setIntroMessage("You've been invited to be a participant.");
      }
    }
  }, [invitation]);

  const renderContent = () => {
    if (!user || !id || noMatch)
      return <ErrorMessage message="Something is wrong with your link" />;
    return (
      <Box>
        <Typography variant="h5">{introMessage}</Typography>
        {(clinician || client) && (
          <Box my={2}>
            <BasicUserCard
              profile={clinician ?? client}
              multipleActions={
                <Stack direction="column">
                  <ActionButton
                    color="success"
                    tooltip="Accept Invitation"
                    disabled={confirmed || rejected || invitation?.used}
                    loading={confirming}
                    onClick={() =>
                      performAction(
                        invitation?.action === 'link_client_and_clinician'
                          ? 'CONFIRM_client_clinician_link'
                          : 'CONFIRM_participant_link',
                        setConfirming,
                        setConfirmed
                      )
                    }
                    label={<Check color={rejected ? 'default' : 'success'} />}
                  />
                  <ActionButton
                    color="error"
                    tooltip="Reject Invitation"
                    disabled={confirmed || rejected || invitation?.used}
                    loading={rejecting}
                    onClick={() =>
                      performAction(
                        invitation?.action === 'link_client_and_clinician'
                          ? 'REJECT_client_clinician_link'
                          : 'REJECT_participant_link',
                        setRejecting,
                        setRejected
                      )
                    }
                    label={<Close color={confirmed ? 'default' : 'error'} />}
                  />
                </Stack>
              }
            />
          </Box>
        )}
        {expired ||
          (invitation?.used && (
            <ErrorMessage noBody={true} message="This invitation has expired" />
          ))}
        {error && <ErrorMessage message={error} />}
      </Box>
    );
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="80vh"
    >
      {loading ? <CircularProgress /> : renderContent()}
    </Box>
  );
}

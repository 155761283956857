import { useCallback } from 'react';

import { API, graphqlOperation } from 'aws-amplify';
import { actionInvitationLink } from 'graphql/queries';

export const useFetchData = () => {
  const fetchData = useCallback(async (query, variables) => {
    try {
      const data = await API.graphql(graphqlOperation(query, variables));
      return data.data;
    } catch (e) {
      throw e;
    }
  }, []); // Empty dependency array means this callback will never change

  return fetchData;
};

export const performLinkingAction = async (
  invitationID,
  source,
  action,
  clientData,
  clinicianData
) => {
  try {
    const res = await API.graphql(
      graphqlOperation(actionInvitationLink, {
        invitationID,
        source,
        action,
        ...clientData,
        ...clinicianData
      })
    );
    return res.data.actionInvitationLink;
  } catch (e) {
    throw e;
  }
};

export const handleInvitationError = (err, setError) => {
  setError(err.message);
};

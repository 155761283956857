import { useEffect, useState } from 'react';

import { Add, Info, Remove } from '@mui/icons-material';
import {
  Box,
  Button,
  Collapse,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import uuid from 'react-uuid';

export default function ClientDetails({
  clientFirstName,
  setClientFirstName,
  clientLastName,
  setClientLastName,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  forValue,
  setForValue,
  email,
  emailError,
  lookupEmail,
  participants,
  setParticipants
}) {
  const [clientLastNameTyped, setClientLastNameTyped] = useState(false);

  const updateClientLastName = e => {
    setClientLastNameTyped(true);
    setClientLastName(e.target.value);
  };

  useEffect(() => {
    if (!clientLastNameTyped) {
      setClientLastName(lastName);
    }
  }, [lastName, clientLastNameTyped, setClientLastName]);

  const addParticipants = () => {
    const newParticipant = {
      id: uuid(),
      firstName: '',
      lastName: '',
      email: '',
      role: '',
      errors: { firstName: '', lastName: '', email: '', role: '' }
    };
    setParticipants([...participants, newParticipant]);
  };

  const updateParticipant = (id, key, value) => {
    const updatedParticipants = participants.map(participant => {
      if (participant.id === id) {
        return {
          ...participant,
          [key]: value,
          errors: { ...participant.errors, [key]: '' } // Reset error for the updated key
        };
      }
      return participant;
    });
    setParticipants(updatedParticipants);
  };

  const removeParticipant = id => {
    const updatedParticipants = participants.filter(
      participant => participant.id !== id
    );
    setParticipants(updatedParticipants);
  };

  return (
    <Box>
      <Box py={2}>
        <FormControl>
          <FormLabel id="demo-row-radio-buttons-group-label">
            Client Details
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={forValue}
            onChange={e => setForValue(e.target.value)}
          >
            <FormControlLabel value="child" control={<Radio />} label="Child" />
            <FormControlLabel value="adult" control={<Radio />} label="Adult" />
            <FormControlLabel
              value="carer"
              control={<Radio />}
              label="Someone with a carer"
            />
            <FormControlLabel value="other" control={<Radio />} label="Other" />
          </RadioGroup>
        </FormControl>
      </Box>
      <Box py={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <TextField
              value={firstName}
              onChange={e => setFirstName(e.target.value)}
              size="small"
              fullWidth
              label={`${
                forValue === 'child'
                  ? `Parent's`
                  : forValue === 'carer'
                  ? `Carer's`
                  : forValue === 'other'
                  ? `Account Owner's`
                  : ''
              }  First Name`}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              value={lastName}
              onChange={e => setLastName(e.target.value)}
              size="small"
              fullWidth
              label={`${
                forValue === 'child'
                  ? `Parent's`
                  : forValue === 'carer'
                  ? `Carer's`
                  : forValue === 'other'
                  ? `Account Owner's`
                  : ''
              }  Last Name`}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              value={email}
              onChange={e => {
                lookupEmail(e.target.value);
              }}
              size="small"
              fullWidth
              label="Email"
              error={Boolean(emailError)}
              helperText={emailError}
            />
          </Grid>
        </Grid>
      </Box>

      <Collapse
        in={
          forValue === 'child' || forValue === 'other' || forValue === 'carer'
        }
      >
        <Box my={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                value={clientFirstName}
                onChange={e => setClientFirstName(e.target.value)}
                size="small"
                fullWidth
                label={`${
                  forValue === 'child' ? `Child's` : `Client's`
                } First Name`}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                value={clientLastName}
                onChange={updateClientLastName}
                size="small"
                fullWidth
                label={`${
                  forValue === 'child' ? `Child's` : `Client's`
                } Last Name`}
              />
            </Grid>
          </Grid>
        </Box>
      </Collapse>

      <Box>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Button onClick={addParticipants} startIcon={<Add />}>
            Add Participants
          </Button>
          <Tooltip
            placement="top"
            title="Participants are caregivers who can access this client account with limited permissions."
          >
            <Info color="grey" fontSize="small" />
          </Tooltip>
        </Stack>

        {participants?.map(p => (
          <Grid sx={{ my: 1 }} key={p.id} container spacing={2}>
            <Grid item xs={12} sm={3}>
              <TextField
                value={p.firstName}
                onChange={e =>
                  updateParticipant(p.id, 'firstName', e.target.value)
                }
                size="small"
                fullWidth
                label="First Name"
                error={!!p.errors.firstName}
                helperText={p.errors.firstName}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                value={p.lastName}
                onChange={e =>
                  updateParticipant(p.id, 'lastName', e.target.value)
                }
                size="small"
                fullWidth
                label="Last Name"
                error={!!p.errors.lastName}
                helperText={p.errors.lastName}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                value={p.email}
                onChange={e => updateParticipant(p.id, 'email', e.target.value)}
                size="small"
                fullWidth
                label="Email"
                error={!!p.errors.email}
                helperText={p.errors.email}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <FormControl size="small" fullWidth>
                <InputLabel id={`role-label-${p.id}`}>Role</InputLabel>
                <Select
                  error={!!p.errors.role}
                  label="Role"
                  labelId={`role-label-${p.id}`}
                  value={p.role}
                  onChange={e =>
                    updateParticipant(p.id, 'role', e.target.value)
                  }
                  fullWidth
                  size="small"
                >
                  <MenuItem value="Parent">Parent</MenuItem>
                  <MenuItem value="Grandparent">Grandparent</MenuItem>
                  <MenuItem value="Sibling">Sibling</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
              </FormControl>
              {p.errors.role && (
                <Typography
                  sx={{ fontSize: '0.75rem', marginTop: '4px' }}
                  variant="body2"
                  color="error"
                >
                  {p.errors.role}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={1}>
              <Tooltip title="Remove participant" placement="top">
                <IconButton onClick={() => removeParticipant(p.id)}>
                  <Remove />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        ))}
      </Box>
    </Box>
  );
}

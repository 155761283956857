import React, { useCallback, useEffect, useState } from 'react';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, IconButton, InputAdornment, TextField } from '@mui/material';
import * as yup from 'yup';

const passwordSchema = yup.object().shape({
  password: yup
    .string()
    .required('Password is required')
    .min(6, 'Password should be at least 6 characters')
    .matches(/\d/, 'Password should have at least one number')
    .matches(
      // eslint-disable-next-line no-useless-escape
      /[A-Z]|[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/,
      'Password should have at least one uppercase letter or special character'
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
});

function ChangePassword({
  handlePasswordChange,
  changingPassword,
  newPassword,
  setNewPassword,
  confirmNewPassword,
  setConfirmNewPassword
}) {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [showErrors, setShowErrors] = useState(false);

  const validateField = useCallback(async () => {
    let newErrors = {};
    try {
      await passwordSchema.validate(
        { password: newPassword, confirmPassword: confirmNewPassword },
        { abortEarly: false }
      );
    } catch (err) {
      err.inner.forEach(error => {
        newErrors[error.path] = error.message;
      });
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [newPassword, confirmNewPassword]);

  const handleOnSubmit = useCallback(async () => {
    const isValid = await validateField();
    if (isValid) {
      handlePasswordChange();
    } else {
      setShowErrors(true);
    }
  }, [validateField, handlePasswordChange]);

  useEffect(() => {
    const handleKeyPress = e => {
      if (e.key === 'Enter') {
        handleOnSubmit();
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleOnSubmit]);

  return (
    <Box width="100%">
      <Box width="100%" my={1}>
        <TextField
          size="small"
          fullWidth
          label="New Password"
          type={showPassword ? 'text' : 'password'}
          value={newPassword}
          onChange={e => {
            setNewPassword(e.target.value);
            if (showErrors) setShowErrors(false);
          }}
          error={showErrors && !!errors.password}
          helperText={showErrors && errors.password}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </Box>
      <Box display="flex" width="100%" my={1}>
        <TextField
          size="small"
          fullWidth
          label="Confirm Password"
          type={showConfirmPassword ? 'text' : 'password'}
          value={confirmNewPassword}
          onChange={e => {
            setConfirmNewPassword(e.target.value);
            if (showErrors) setShowErrors(false);
          }}
          error={showErrors && !!errors.confirmPassword}
          helperText={showErrors && errors.confirmPassword}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  edge="end"
                >
                  {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </Box>
      <Box display="flex" width="100%" my={2}>
        <LoadingButton
          fullWidth
          size="small"
          variant="contained"
          onClick={handleOnSubmit}
          loading={changingPassword}
        >
          Change Password
        </LoadingButton>
      </Box>
    </Box>
  );
}

export default React.memo(ChangePassword);

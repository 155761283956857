import { useEffect, useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { Update } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Card, CardContent, CardHeader, Chip } from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import { getClient } from 'graphql/queries';

import ClientName from '../ClientName';
import ClientNameSub from '../ClientNameSub';
import ProfileAvatar from '../ProfileAvatar';

export default function SupportRequest({
  handleCloseSupportRequest,
  supportRequest,
  fromSettings
}) {
  const [supportFor, setSupportFor] = useState({});
  const { user } = useAuthenticator(context => [context.user]);
  const createdByMe = user.username === supportRequest.createdBy;
  const isClinician = user.attributes['custom:type'] === 'clinician';
  const [canceling, setCanceling] = useState(false);

  useEffect(() => {
    const fetchClient = async () => {
      const { data } = await API.graphql(
        graphqlOperation(getClient, { id: supportRequest.clientID })
      );
      const client = data.getClient;
      setSupportFor(client);
    };
    if (isClinician && createdByMe) {
      fetchClient();
    }
  }, [supportRequest, isClinician, createdByMe]);

  function calculateTimeLeft(objectData) {
    const expirationTime = new Date(objectData.expiresAt);
    const currentTime = new Date();
    const timeLeft = expirationTime - currentTime;
    if (timeLeft < 0) {
      return 'Expired';
    }
    let seconds = Math.floor((timeLeft / 1000) % 60);
    let minutes = Math.floor((timeLeft / 1000 / 60) % 60);
    let hours = Math.floor(timeLeft / (1000 * 60 * 60));

    return `${hours} hours, ${minutes} minutes, ${seconds} seconds`;
  }

  const timeRemaining = calculateTimeLeft(supportRequest);

  function isRequestActive(supportRequest) {
    const currentTime = new Date();
    const expirationTime = new Date(supportRequest.expiresAt);
    const isExpired = expirationTime < currentTime;
    const isUsed = supportRequest.used;

    return !isExpired && !isUsed;
  }

  const isActive = isRequestActive(supportRequest);

  const closeSupportRequest = async supportRequest => {
    try {
      setCanceling(true);
      handleCloseSupportRequest(supportRequest.id, supportRequest._version);
    } catch (err) {
      console.error('Error closing support request', err);
    } finally {
      setCanceling(false);
    }
  };

  return (
    <Box>
      <Card elevation={4}>
        {fromSettings ? (
          <CardHeader
            title="Open Support Request"
            subheader="We have been notified."
          />
        ) : (
          <CardHeader
            title={<ClientName client={supportFor} />}
            subheader={<ClientNameSub client={supportFor} />}
            avatar={<ProfileAvatar profile={supportFor} />}
          />
        )}
        <CardContent>
          <Chip
            icon={<Update fontSize="small" />}
            label={timeRemaining + ' remaining'}
          />
        </CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          {isActive && (
            <LoadingButton
              size="small"
              loading={canceling}
              onClick={() => closeSupportRequest(supportRequest)}
            >
              Close
            </LoadingButton>
          )}
        </Box>
      </Card>
    </Box>
  );
}

import { useEffect, useState } from 'react';

import {
  Alert,
  AlertTitle,
  Box,
  Chip,
  Divider,
  Grid,
  Grow,
  Stack,
  Typography
} from '@mui/material';

import { ClinicianCard } from 'components/atoms';

import { formatRoleString } from 'utils/userRoles';

export default function Permissions({
  clinicians,
  setClinicians,
  selectedRoles
}) {
  const [groupedClinicians, setGroupedClinicians] = useState({});

  const getGroupedClinicians = (clinicians, selectedRoles) => {
    const roleGroups = clinicians.reduce((groups, clinician) => {
      const role = clinician.role ? clinician.role.toLowerCase() : 'none';
      if (selectedRoles.length === 0 || selectedRoles.includes(role)) {
        if (!groups[role]) groups[role] = [];
        groups[role].push(clinician);
      }
      return groups;
    }, {});

    return {
      admin: [],
      practicemanager: [],
      clinician: [],
      none: [],
      ...roleGroups
    };
  };

  useEffect(() => {
    setGroupedClinicians(getGroupedClinicians(clinicians, selectedRoles));
  }, [clinicians, selectedRoles]);

  const hasCliniciansInAnyGroup = groups => {
    return Object.values(groups).some(group => group.length > 0);
  };

  const renderGroup = (group, groupClinicians) => {
    if (groupClinicians[group].length > 0) {
      return (
        <>
          <Stack sx={{ my: 1 }} direction="row" alignItems="center" spacing={1}>
            <Typography color="primary.dark" variant="h4">
              {group === 'none'
                ? 'Suspended users'
                : formatRoleString(group) + 's'}
            </Typography>
            <Chip label={groupClinicians[group].length.toString()} />
          </Stack>
          <Divider sx={{ mb: 2 }} />
          <Grid container spacing={2}>
            {groupClinicians[group].map(clinician => (
              <Grid key={clinician.id} item xs={12} md={6} lg={4} xl={3}>
                <ClinicianCard
                  clinician={clinician}
                  setClinicians={setClinicians}
                />
              </Grid>
            ))}
          </Grid>
        </>
      );
    }
    return null;
  };
  return (
    <Box>
      <Grow in={hasCliniciansInAnyGroup(groupedClinicians)}>
        <Box>
          {Object.keys(groupedClinicians).map(group => (
            <Box key={group} sx={{ mb: 4 }}>
              {renderGroup(group, groupedClinicians)}
            </Box>
          ))}
        </Box>
      </Grow>
      <Grow in={!hasCliniciansInAnyGroup(groupedClinicians)}>
        <Box
          width="100%"
          height={hasCliniciansInAnyGroup(groupedClinicians) ? 0 : '60vh'}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box maxWidth="sm">
            <Alert severity="info">
              <AlertTitle>No users found</AlertTitle>
              {selectedRoles.length > 0 && selectedRoles.length < 3
                ? ' Try expanding your filters or add a new user.'
                : 'Try adding some users'}
            </Alert>
          </Box>
        </Box>
      </Grow>
    </Box>
  );
}

import React, { useEffect, useState } from 'react';

import { Authenticator } from '@aws-amplify/ui-react';
import { Alert, Box, CircularProgress } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { API, Auth, Hub, graphqlOperation } from 'aws-amplify';
import { AppWrapper } from 'context/Context';
import { EntryProvider } from 'context/Entry';
import { SnackbarProvider } from 'context/SnackBar';
import { verifyEmail } from 'graphql/queries';
import mixpanel from 'mixpanel-browser';
import queryString from 'query-string';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Router from 'router';
// import UpdateInProgress from 'routes/common/update';
import ThemeProvider from 'theme/provider';

import { NoClickLogo } from 'components/atoms';
import {
  ChangePassword,
  Confirm,
  FirstTimeSignIn,
  ResetPassword,
  UnifiedSignInUp
} from 'components/auth';

import './App.css';

function App() {
  const [user, setUser] = useState(null);
  const [otp, setOtp] = useState('');
  const [isSignUp, setIsSignUp] = useState(false);
  const [givenName, setGivenName] = useState('');
  const [familyName, setFamilyName] = useState('');
  const [signingIn, setSigningIn] = useState(false);
  const [signingUp, setSigningUp] = useState(false);
  const [error, setError] = useState(null);
  const [showOtpForm, setShowOtpForm] = useState(false);
  const [settingOtp, setSettingOtp] = useState(false);
  const [forceChangePassword, setForceChangePassword] = useState(false);
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [changingPassword, setChangingPassword] = useState(false);
  const params = queryString.parse(window.location.search);
  const [type, setType] = useState(
    params?.type === 'clinician' ? 'clinician' : 'client'
  );
  const [verifyUser, setVerifyUser] = useState(params.s === 'nu');
  const [email, setEmail] = useState(
    params.email ? decodeURIComponent(params.email) : ''
  );
  const invitedBy = params?.link;
  const [loading, setLoading] = useState(true);
  const [resetPassword, setResetPassword] = useState(false);
  const [confirmationCode, setConfirmationCode] = useState('');
  const [resettingPassword, setResettingPassword] = useState(false);
  const [confirmSignIn, setConfirmSignIn] = useState(false);
  const [sendingReset, setSendingReset] = useState(false);

  useEffect(() => {
    checkCurrentUser();

    const listener = async data => {
      switch (data.payload.event) {
        case 'signIn':
          setUser(data.payload.data);
          setEmail('');
          setPassword('');
          setNewPassword('');
          setConfirmNewPassword('');
          break;
        case 'signUp':
          setSigningUp(false);
          window.dataLayer.push({
            event: 'sign_up'
          });
          break;
        case 'signOut':
          window.localStorage.removeItem('googleSignIn');
          window.localStorage.removeItem('type');
          window.sessionStorage.clear();
          window.dataLayer.push({
            event: 'sign_out'
          });
          window.location.href = '/';
          // setGoogleSignIn(null);
          setVerifyUser(false);
          setUser(null);
          break;
        case 'customOAuthState':
          const customState = data.payload.data;
          if (customState) {
            window.location.href = customState.startsWith('/')
              ? customState
              : '/' + customState;
          }
          break;
        default:
      }
    };
    Hub.listen('auth', listener);
    return () => Hub.remove('auth', listener);
  }, []);

  const checkCurrentUser = async () => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      setUser(currentUser);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const handleSignIn = async () => {
    try {
      setSigningIn(true);
      const signedInUser = await Auth.signIn(email.trim(), password.trim());
      setUser(signedInUser);
      if (signedInUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setForceChangePassword(true);
      }
      if (signedInUser.challengeName === 'SOFTWARE_TOKEN_MFA') {
        setConfirmSignIn(true);
        setShowOtpForm(true);
      }
      window.dataLayer.push({
        event: 'sign_in',
        provider: 'Email'
      });
      resetError();
      checkCurrentUser();
      setEmail('');
      setPassword('');
      setConfirmNewPassword('');
    } catch (err) {
      console.log(err);
      setError(err.message || 'Error signing in');
    } finally {
      setSigningIn(false);
      setLoading(false);
    }
  };

  const handleSignUp = async () => {
    try {
      setSigningUp(true);
      await Auth.signUp({
        username: email.trim(),
        password,
        attributes: {
          given_name: givenName.trim(),
          family_name: familyName.trim(),
          'custom:type': type,
          'custom:invited_by': invitedBy
        },
        autoSignIn: {
          enabled: true
        }
      });
      mixpanel.track('sign_up');
      resetError();
    } catch (err) {
    } finally {
      setIsSignUp(false);
    }
  };

  const handleOtpSubmit = async () => {
    try {
      setSettingOtp(true);
      let signedInUser;
      if (confirmSignIn) {
        const mfaType = 'SOFTWARE_TOKEN_MFA';
        signedInUser = await Auth.confirmSignIn(user, otp, mfaType);
      } else {
        await Auth.confirmSignUp(email.trim(), otp);
        signedInUser = await Auth.signIn(email.trim(), password);
      }
      setUser(signedInUser);
      setShowOtpForm(false);
      setConfirmSignIn(false);
      resetError();
    } catch (err) {
      setError(err.message || 'Error confirming OTP');
    } finally {
      setSettingOtp(false);
    }
  };

  const handlePasswordChange = async () => {
    try {
      setChangingPassword(true);
      const newUser = await Auth.completeNewPassword(user, newPassword);
      await API.graphql(
        graphqlOperation(verifyEmail, { userID: newUser.username })
      );
      setUser(newUser);
      setForceChangePassword(false);
      setEmail('');
      setPassword('');
      setNewPassword('');
    } catch (err) {
      console.log('error changing password', err);
      setChangingPassword(false);
      setError(err);
    } finally {
      setChangingPassword(false);
    }
  };

  const handlePasswordResetRequest = async () => {
    try {
      if (email) {
        setSendingReset(true);
      }
      resetError();
      if (!email) {
        setError('Email is required');
        setResetPassword(false);
        return;
      }

      await Auth.forgotPassword(email.trim());
      setResetPassword(true);
    } catch (error) {
      setError(error.message);
    } finally {
      setResettingPassword(false);
      setSendingReset(false);
    }
  };

  const handleCompletePasswordReset = async () => {
    try {
      setResettingPassword(true);
      await Auth.forgotPasswordSubmit(
        email.trim(),
        confirmationCode,
        newPassword.trim()
      );

      const signedInUser = await Auth.signIn(email.trim(), newPassword.trim());
      setUser(signedInUser);
    } catch (error) {
      setError(error.message);
    } finally {
      setNewPassword('');
      setConfirmationCode('');
      setResettingPassword(false);
      setResetPassword(false);
    }
  };

  const renderAuthComponent = () => {
    if (forceChangePassword) {
      return (
        <ChangePassword
          handlePasswordChange={handlePasswordChange}
          changingPassword={changingPassword}
          newPassword={newPassword}
          setNewPassword={setNewPassword}
          confirmNewPassword={confirmNewPassword}
          setConfirmNewPassword={setConfirmNewPassword}
        />
      );
    }

    if (verifyUser) {
      return (
        <FirstTimeSignIn
          email={email}
          setEmail={setEmail}
          password={password}
          setPassword={setPassword}
          handleSignIn={handleSignIn}
          signingIn={signingIn}
          resetError={resetError}
          setVerifyUser={setVerifyUser}
        />
      );
    }

    if (showOtpForm) {
      return (
        <Confirm
          useDevice={confirmSignIn}
          otp={otp}
          setOtp={setOtp}
          settingOtp={settingOtp}
          handleOtpSubmit={handleOtpSubmit}
        />
      );
    }

    if (resetPassword) {
      return (
        <ResetPassword
          newPassword={newPassword}
          setNewPassword={setNewPassword}
          confirmationCode={confirmationCode}
          setConfirmationCode={setConfirmationCode}
          resettingPassword={resettingPassword}
          handleCompletePasswordReset={handleCompletePasswordReset}
        />
      );
    }

    return (
      <UnifiedSignInUp
        givenName={givenName}
        setGivenName={setGivenName}
        familyName={familyName}
        setFamilyName={setFamilyName}
        email={email}
        setEmail={setEmail}
        password={password}
        setPassword={setPassword}
        handleSignUp={handleSignUp}
        handleSignIn={handleSignIn}
        setIsSignUp={setIsSignUp}
        signingIn={signingIn}
        type={type}
        setType={setType}
        signingUp={signingUp}
        isSignUp={isSignUp}
        resetError={resetError}
        // handleGoogleSignIn={handleGoogleSignIn}
        handlePasswordResetRequest={handlePasswordResetRequest}
        sendingReset={sendingReset}
      />
    );
  };

  const resetError = () => {
    setError(null);
  };

  return (
    <HelmetProvider>
      <Helmet>
        <script>{`
          if (typeof window !== 'undefined') {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.innerHTML = \`
              (function(w,d,s,l,i){
                w[l]=w[l]||[];
                w[l].push({'gtm.start': new Date().getTime(), event:'gtm.js'});
                var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),
                dl=l!='dataLayer'?'&l='+l:'';
                j.async=true;
                j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl+'&gtm_auth=${process.env.REACT_APP_GTM_AUTH}&gtm_preview=${process.env.REACT_APP_GTM_ENV}&gtm_cookies_win=x';
                f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${process.env.REACT_APP_GTM_ID}');
            \`;
            document.body.appendChild(script);

            const noScript = document.createElement('noscript');
            noScript.innerHTML = \`
              <iframe src="https://www.googletagmanager.com/ns.html?id=${process.env.REACT_APP_GTM_ID}&gtm_auth=${process.env.REACT_APP_GTM_AUTH}&gtm_preview=${process.env.REACT_APP_GTM_ENV}&gtm_cookies_win=x" height="0" width="0" style="display:none;visibility:hidden"></iframe>
            \`;
            document.body.insertBefore(noScript, document.body.firstChild);
          }
        `}</script>
      </Helmet>
      <AppWrapper>
        <ThemeProvider>
          <CssBaseline />
          {loading ? (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              sx={{ height: '100vh', width: '100%' }}
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                width="100%"
                maxWidth="400px"
              >
                <CircularProgress />
              </Box>
            </Box>
          ) : (
            <>
              {!user || forceChangePassword || showOtpForm ? (
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  sx={{ height: '100vh', width: '100%' }}
                >
                  {/* <UpdateInProgress /> */}
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    my={2}
                  >
                    <NoClickLogo noLink={true} />
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    width="100%"
                    maxWidth="400px"
                  >
                    {renderAuthComponent()}
                    {error && (
                      <Alert severity="error" sx={{ mt: 1, width: '100%' }}>
                        {error}
                      </Alert>
                    )}
                  </Box>
                </Box>
              ) : (
                <>
                  <Authenticator.Provider>
                    <Authenticator className="hidden">
                      <SnackbarProvider>
                        <EntryProvider>
                          <Router />
                        </EntryProvider>
                      </SnackbarProvider>
                    </Authenticator>
                  </Authenticator.Provider>
                </>
              )}
            </>
          )}
        </ThemeProvider>
      </AppWrapper>
    </HelmetProvider>
  );
}

export default App;

// UseInsightsState.js
import { useCallback, useEffect, useState } from 'react';

import { API, graphqlOperation } from 'aws-amplify';
import { useAppContext } from 'context';
import { updateEntry } from 'graphql/mutations';
import { createDefaultInsight, entriesByClientID } from 'graphql/queries';
import { DateTime } from 'luxon';
import mixpanel from 'mixpanel-browser';

export function useInsightsState(user) {
  const { state } = useAppContext();
  const [entries, setEntries] = useState([]);
  const [value, setValue] = useState(0);
  const today = DateTime.local();
  const todayMinus42 = today.minus({ days: 42 });
  const [to, setTo] = useState(today);
  const [from, setFrom] = useState(todayMinus42);
  const [loadColumns, setLoadColumns] = useState(false);
  const [columns, setColumns] = useState();
  const [togglingAnalyticsByEntry, setTogglingAnalyticsByEntry] = useState({});
  const clientID =
    state.client?.id || user.attributes['custom:clientID'] || user.username;
  const clientSub = state.client?.sub || user.attributes.sub;

  const handleChange = (event, newValue) => setValue(newValue);

  const fetchEntries = useCallback(async () => {
    const data = await API.graphql(
      graphqlOperation(entriesByClientID, { clientID: clientID })
    );
    const entriesData = data.data.entriesByClientID.items;
    setEntries(entriesData);
  }, [clientID]);

  const handleCheck = useCallback(
    async entry => {
      setTogglingAnalyticsByEntry(prevTogglingAnalyticsByEntry => ({
        ...prevTogglingAnalyticsByEntry,
        [entry.id]: true
      }));
      if (entry.analytics !== true) {
        await API.graphql(
          graphqlOperation(createDefaultInsight, {
            clientSub: clientSub,
            clientID: clientID,
            entryID: entry.id
          })
        );
      }
      const {
        data: { updateEntry: updatedEntry }
      } = await API.graphql({
        query: updateEntry,
        variables: {
          input: {
            id: entry.id,
            analytics: !entry.analytics,
            _version: entry._version
          }
        }
      });

      if (!entry.analytics) {
        mixpanel.track('unpinRecord', { entry_name: entry.name });
        window.dataLayer.push({
          event: 'unpin_record'
        });
      } else {
        mixpanel.track('pinRecord', { entry_name: entry.name });
        window.dataLayer.push({
          event: 'pin_record'
        });
      }

      setEntries(prevEntries =>
        prevEntries.map(prevEntry =>
          prevEntry.id === updatedEntry.id ? updatedEntry : prevEntry
        )
      );
      setTogglingAnalyticsByEntry(prevTogglingAnalyticsByEntry => ({
        ...prevTogglingAnalyticsByEntry,
        [entry.id]: false
      }));
    },
    [clientID, clientSub]
  );

  useEffect(() => {
    fetchEntries();
  }, [fetchEntries]);

  const clickAdd = () => {
    setLoadColumns(!loadColumns);
  };

  const chooseColumns = value => {
    setColumns(value);
  };

  const [showChipInfo, setShowChipInfo] = useState(true);

  return {
    entries,
    value,
    to,
    from,
    loadColumns,
    columns,
    handleChange,
    handleCheck,
    clickAdd,
    chooseColumns,
    setTo,
    setFrom,
    togglingAnalyticsByEntry,
    showChipInfo,
    setShowChipInfo
  };
}

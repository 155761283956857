import { useCallback } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { API, graphqlOperation } from 'aws-amplify';
import { useAppContext } from 'context';
import {
  updateGoalEntry as updateGoalEntryMutation,
  updateRecord as updateRecordMutation
} from 'graphql/mutations';
import {
  goalEntryByClientID,
  userInputsByRecordComponentType
} from 'graphql/queries';
import { DateTime } from 'luxon';

import isToday from 'utils/isToday';

export function useUpdateGoal() {
  const { dispatch, state } = useAppContext();
  const { user } = useAuthenticator(context => [context.user]);

  const updateData = useCallback(
    async (goalEntry, record, action, change) => {
      const input = {
        id: goalEntry.id,
        _version: goalEntry._version
      };
      if (action === 'update') {
        input.trackingNumber = (goalEntry.trackingNumber ?? 0) + (change || 1);
      } else if (action === 'delete') {
        input.trackingNumber = (goalEntry.trackingNumber ?? 0) - (change || 1);
      }
      await API.graphql(
        graphqlOperation(updateGoalEntryMutation, { input: input })
      );

      if (record && record.id) {
        const updatedRecordResponse = await API.graphql(
          graphqlOperation(updateRecordMutation, {
            input: {
              id: record.id,
              hasUpdatedGoalEntry: true,
              _version: record._version
            }
          })
        );

        const updatedRecord = updatedRecordResponse.data.updateRecord;

        if (updatedRecord.userInputs.items.length === 0) {
          const { data } = await API.graphql(
            graphqlOperation(userInputsByRecordComponentType, {
              recordID: updatedRecord.id
            })
          );
          const userInputs = data.userInputsByRecordComponentType.items;
          updatedRecord.userInputs.items = userInputs;
        }

        dispatch({
          type: 'set_record',
          record: updatedRecord
        });
      }
    },
    [dispatch]
  );

  const updateRecordAndGoalEntry = useCallback(
    async ({ passedRecord, action, userInput, change }) => {
      console.log(passedRecord, action, userInput, change);
      let record;
      if (!passedRecord) {
        record = state.record;
      } else {
        record = passedRecord;
      }
      if ((!record || !userInput) && !user) {
        return;
      }

      const hasUpdatedGoalEntry = record.hasUpdatedGoalEntry;

      const fromLocal = DateTime.now().startOf('day');
      const toLocal = DateTime.now().endOf('day');
      const fromISO = fromLocal.toUTC().toISO();
      const toISO = toLocal.toUTC().toISO();

      const input = {
        clientID: user.username,
        date: {
          between: [fromISO, toISO]
        }
      };

      try {
        const { data } = await API.graphql(
          graphqlOperation(goalEntryByClientID, input)
        );
        const goalEntries = data.goalEntryByClientID.items.sort(
          (a, b) => a.goal.order - b.goal.order
        );

        for (const goalEntry of goalEntries) {
          if (
            goalEntry.goal &&
            isToday(record.enteredAt ? record.enteredAt : userInput?.enteredAt)
          ) {
            switch (goalEntry.goal.targetType) {
              case 'component':
                if (goalEntry.goal.componentID === userInput?.componentID) {
                  if (action === 'delete') {
                    change = userInput.numberValue;
                  }
                  if (!change) return;
                  await updateData(goalEntry, record, action, change);
                }
                break;
              case 'entry':
                if (goalEntry.goal.entryID === record.entryID) {
                  if (action === 'update' && hasUpdatedGoalEntry) return;
                  if (action === 'delete' && !hasUpdatedGoalEntry) return;
                  await updateData(goalEntry, record, action);
                }
                break;
              case 'section':
                if (goalEntry.goal.sectionID === record.sectionID) {
                  if (action === 'update' && hasUpdatedGoalEntry) return;
                  if (action === 'delete' && !hasUpdatedGoalEntry) return;
                  await updateData(goalEntry, record, action);
                }
                break;
              default:
                break;
            }
          }
        }
      } catch (error) {
        console.error('Error updating goal entry:', error);
      }
    },
    [user, updateData, state.record]
  );

  return updateRecordAndGoalEntry;
}

import { Fragment, memo, useCallback, useEffect, useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import {
  DndContext,
  MouseSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import {
  restrictToVerticalAxis,
  restrictToWindowEdges
} from '@dnd-kit/modifiers';
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import { Box, Container, Tab, Tabs, Tooltip } from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import { useAppContext } from 'context';
import { useEntryContext } from 'context/Entry';
import { createSection, updateSection } from 'graphql/mutations';
import { sectionByClientIDByOrder } from 'graphql/queries';
import mixpanel from 'mixpanel-browser';
import uuid from 'react-uuid';
import { useDebouncedCallback } from 'use-debounce';

import { DividerButton, LoadingAtom } from 'components/atoms';
import RecordSection from 'components/organisms/RecordSections';

import Entry from '../entry';

const normalizeFactor = 10;

function ClientHome() {
  // state
  const { state, dispatch } = useAppContext();
  const { user } = useAuthenticator(context => [context.user]);
  const clientID =
    state.client?.id || user.attributes['custom:clientID'] || user.username;
  const clientSub = state?.client?.sub || user.attributes.sub;
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const { selectedEntry } = useEntryContext();
  const sensors = useSensors(useSensor(MouseSensor), useSensor(PointerSensor));
  const [creating, setCreating] = useState({});
  const [sections, setSections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [switchingEntry, setSwitchingEntry] = useState(false);
  if (user.attributes['custom:type'] === 'client') {
    mixpanel.register({
      program:
        user.attributes['custom:program'] === 'monitoringChild'
          ? 'ProgramType.monitoringChild'
          : user.attributes['custom:program'] === 'monitoringAdult'
          ? 'ProgramType.monitoringAdult'
          : user.attributes['custom:program'] === 'lidcombe'
          ? 'ProgramType.lidcombe'
          : user.attributes['custom:program'] === 'westmead'
          ? 'ProgramType.westmead'
          : user.attributes['custom:program'] === 'oakville'
          ? 'ProgramType.oakville'
          : user.attributes['custom:program']
              ?.toLowerCase()
              .includes('adolescent')
          ? 'ProgramType.camperdownAdolescent'
          : 'ProgramType.camperdownAdult'
    });
  }

  // fetch
  const fetchSectionsAPI = useFetchSectionsAPI(
    clientID,
    setSections,
    setLoading
  );

  useEffect(() => {
    fetchSectionsAPI();
    return () => {
      setSections([]);
      setSwitchingEntry(false);
    };
  }, [fetchSectionsAPI, clientID]);

  // ui action

  const handleTabChange = (event, newValue) => {
    setSwitchingEntry(true);
    dispatch({ type: 'set_record', record: {} });
    setSelectedTabIndex(newValue);
  };

  // update / delete actions

  const createSection = useCreateSection(
    clientID,
    clientSub,
    setSections,
    setCreating
  );
  const dragSection = useDragSection(sections, setSections);
  const removeSection = useRemoveSection(setSections);
  const updateSection = useDebouncedCallback(
    useUpdateSection(setSections),
    300
  );

  const handleCreateSection = sectionIdx => () => {
    const order =
      sectionIdx === sections.length
        ? (sectionIdx + 1) * normalizeFactor
        : ((sections[sectionIdx]?.order ?? sections.length) +
            (sections[sectionIdx - 1]?.order ?? 0)) /
          2;
    createSection(sectionIdx, order);
  };

  const handleDeleteSection = section => {
    removeSection(section);
  };

  const handleDragSection = ({ active, over }) => {
    dragSection({ active, over });
  };

  const handleUpdateSection = updatedSection => {
    const { id, name, order, _version } = updatedSection;
    updateSection({ id, name, order, _version });
  };

  const handleDragEnd = useCallback(
    ({ active, over }) => {
      if (active.id !== over.id) {
        const oldIndex = sections.findIndex(
          section => section.id === active.id
        );
        const newIndex = sections.findIndex(section => section.id === over.id);
        const newSections = arrayMove(sections, oldIndex, newIndex);
        setSections(newSections);
      }
    },
    [sections]
  );

  return loading ? (
    <LoadingAtom />
  ) : (
    <Box>
      <Container maxWidth="md" sx={{ mb: 10, px: { xs: 0, md: 2 } }}>
        <DndContext onDragEnd={handleDragEnd}>
          <SortableContext
            items={sections}
            strategy={verticalListSortingStrategy}
          >
            <Tabs
              scrollButtons
              allowScrollButtonsMobile
              variant="scrollable"
              value={selectedTabIndex}
              onChange={handleTabChange}
            >
              {sections.map((section, index) =>
                section.inactive && !state.edit ? (
                  <Tooltip
                    placement="top"
                    title="This section will become active as you progress."
                  >
                    <span
                      style={{
                        display: 'contents',
                        maxWidth: '100%',
                        whiteSpace: 'nowrap'
                      }}
                    >
                      <Tab
                        sx={{ fontWeight: 600 }}
                        disabled={section.inactive && !state.edit}
                        key={section.id}
                        label={
                          <span
                            style={{ color: section.inactive ? 'gray' : null }}
                          >
                            {section.name}
                          </span>
                        }
                        tabIndex={index}
                        style={{ opacity: section.inactive ? 0.5 : 1 }}
                      />
                    </span>
                  </Tooltip>
                ) : (
                  <Tab
                    sx={{ fontWeight: 600 }}
                    disabled={section.inactive && !state.edit}
                    key={section.id}
                    label={
                      <span style={{ color: section.inactive ? 'gray' : null }}>
                        {section.name}
                      </span>
                    }
                    tabIndex={index}
                    style={{ opacity: section.inactive ? 0.5 : 1 }}
                  />
                )
              )}
            </Tabs>
          </SortableContext>
        </DndContext>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragSection}
          modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
        >
          {state.edit && (
            <DividerButton
              loading={!!creating[0]}
              onClick={handleCreateSection(0)}
            />
          )}

          {sections?.length > 0 && (
            <SortableContext
              items={[sections[selectedTabIndex]]}
              strategy={verticalListSortingStrategy}
            >
              <Fragment key={sections[selectedTabIndex]?.id}>
                <RecordSection
                  mainSection
                  index={selectedTabIndex}
                  onDeleteSection={handleDeleteSection}
                  onUpdateSection={handleUpdateSection}
                  section={sections[selectedTabIndex]}
                  setSwitchingEntry={setSwitchingEntry}
                  setSections={setSections}
                />
                {state.edit && (
                  <DividerButton
                    loading={!!creating[selectedTabIndex + 1]}
                    onClick={handleCreateSection(selectedTabIndex + 1)}
                  />
                )}
              </Fragment>
            </SortableContext>
          )}
        </DndContext>
        {!switchingEntry && selectedEntry?.id && !selectedEntry.inactive && (
          <Entry entryID={selectedEntry?.id} />
        )}
      </Container>
    </Box>
  );
}

// create section hook
function useCreateSection(clientID, clientSub, setSections, setCreating) {
  return useCallback(
    async (sectionIdx, order) => {
      if (!clientID) return;

      setCreating(prevCreating => ({ ...prevCreating, [sectionIdx]: true }));
      const {
        data: { createSection: createdSection }
      } = await API.graphql({
        query: createSection,
        variables: {
          input: {
            id: uuid(),
            clientID: clientID,
            name: `Section`,
            order,
            owner: `${clientSub}::${clientID}`
          }
        }
      });
      setSections(prevSections => {
        const newSections = prevSections.slice();
        const entries = createdSection.entries.items ?? [];
        newSections.splice(sectionIdx, 0, { ...createdSection, entries });
        return newSections;
      });
      setCreating(prevCreating => ({ ...prevCreating, [sectionIdx]: false }));
    },
    [clientID, clientSub, setSections, setCreating]
  );
}

function useDragSection(sections, setSections) {
  return useCallback(
    async ({ active, over }) => {
      if (over) {
        const overIndex = sections.findIndex(section => section.id === over.id);
        const activeIndex = sections.findIndex(
          section => section.id === active.id
        );
        if (activeIndex !== overIndex) {
          const newOrder =
            activeIndex < overIndex
              ? overIndex === sections.length - 1
                ? sections[overIndex].order + 1 * normalizeFactor
                : (sections[overIndex].order + sections[overIndex + 1].order) /
                  2
              : (sections[overIndex].order +
                  (sections[overIndex - 1]?.order ?? 0)) /
                2;
          const dropppedSection = { ...sections[activeIndex], order: newOrder };
          setSections(prevSections =>
            arrayMove(prevSections, activeIndex, overIndex).map(section =>
              section.id === dropppedSection.id ? dropppedSection : section
            )
          );
          const { data } = await API.graphql(
            graphqlOperation(updateSection, {
              input: {
                id: dropppedSection.id,
                order: dropppedSection.order,
                _version: dropppedSection._version
              }
            })
          );
          const updatedSection = data.updateSection;
          setSections(prevSections =>
            arrayMove(prevSections, activeIndex, overIndex).map(section =>
              section.id === updatedSection.id ? updatedSection : section
            )
          );
          mixpanel.track('drag_section');
          window.dataLayer.push({
            event: 'drag_section'
          });
        }
      }
    },
    [sections, setSections]
  );
}

function useRemoveSection(setSections) {
  return useCallback(
    async section => {
      await API.graphql({
        query: updateSection,
        variables: {
          input: { id: section.id, complete: true, _version: section._version }
        }
      });
      mixpanel.track('delete_section');
      window.dataLayer.push({
        event: 'delete_section'
      });
      setSections(prevSections =>
        prevSections.filter(x => x.id !== section.id)
      );
    },

    [setSections]
  );
}

function useUpdateSection(setSections) {
  return async updateSectionInput => {
    const result = await API.graphql(
      graphqlOperation(updateSection, { input: updateSectionInput })
    );
    const updatedSection = result.data.updateSection;
    setSections(prevSections =>
      prevSections.map(prevSection =>
        prevSection.id === updatedSection.id ? updatedSection : prevSection
      )
    );
  };
}

function useFetchSectionsAPI(clientID, setSections, setLoading) {
  return useCallback(async () => {
    setLoading(true);
    try {
      if (clientID !== '') {
        let queryData = {
          clientID
        };
        const { items } = (
          await API.graphql(
            graphqlOperation(sectionByClientIDByOrder, queryData)
          )
        ).data.sectionByClientIDByOrder;

        const filteredItems = items.filter(item => !item.complete);

        const activeSections = filteredItems.filter(item => !item.inactive);
        const inactiveSections = filteredItems.filter(item => item.inactive);

        const combinedSections = [
          ...activeSections,
          ...inactiveSections.map(section => ({ ...section, isInactive: true }))
        ];

        setSections(combinedSections);
      }
    } catch (error) {
      console.error('Error in useFetchSectionsAPI:', error);
    } finally {
      setLoading(false);
    }
  }, [clientID, setSections, setLoading]);
}

export default memo(ClientHome);

import { useEffect, useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { Check, Close } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import { updateInvitation } from 'graphql/mutations';
import { getClinician } from 'graphql/queries';

import { ActionButton, BasicUserCard } from 'components/atoms';

import { extractData, linkAction } from 'utils/linkingUtils';

import { handleInvitationError, performLinkingAction } from '../LinkAccounts';

export default function Invitation({
  invitation,
  invitations,
  setInvitations,
  remove
}) {
  const { user } = useAuthenticator(context => [context.user]);
  const [clinician, setClinician] = useState(null);
  const [confirming, setConfirming] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [rejecting, setRejecting] = useState(false);
  const [rejected, setRejected] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);
  const [removingInvitation, setRemovingInvitation] = useState(false);

  useEffect(() => {
    if (invitation && invitation.clinicianID) {
      const lookUpClinician = async () => {
        const res = await API.graphql(
          graphqlOperation(getClinician, { id: invitation.clinicianID })
        );

        setClinician(res.data.getClinician);
      };
      lookUpClinician();
    }
    return () => {
      setClinician(null);
    };
  }, [invitation]);

  const markUsed = async () => {
    setRemovingInvitation(true);
    await API.graphql(
      graphqlOperation(updateInvitation, {
        input: { id: invitation.id, used: true, _version: invitation._version }
      })
    );
    const updatedInvitations = invitations.filter(
      inv => inv.id !== invitation.id
    );
    setInvitations(updatedInvitations);
    setRemovingInvitation(false);
  };

  const performAction = async (actionType, setInProgress, setResult) => {
    await linkAction(
      actionType,
      'client',
      performLinkingAction,
      setInProgress,
      setResult,
      setError,
      handleInvitationError,
      invitation.id,
      extractData(user, 'client'),
      extractData(clinician, 'clinician')
    );
  };

  return (
    clinician && (
      <BasicUserCard
        actionLoading={removingInvitation}
        invitation={invitation}
        remove={remove}
        multipleActions={
          remove ? null : invitation.createdBy !== user.username ? (
            <Stack direction="column">
              <ActionButton
                disabled={confirmed || rejected || invitation?.used}
                loading={confirming}
                onClick={() =>
                  performAction(
                    'CONFIRM_client_clinician_link',
                    setConfirming,
                    setConfirmed
                  )
                }
                label={<Check color={confirmed ? 'success' : 'default'} />}
                size="small"
              />
              <ActionButton
                disabled={confirmed || rejected || invitation?.used}
                loading={rejecting}
                onClick={() =>
                  performAction(
                    'REJECT_client_clinician_link',
                    setRejecting,
                    setRejected
                  )
                }
                label={<Close color={rejected ? 'error' : 'default'} />}
                size="small"
              />
            </Stack>
          ) : (
            false
          )
        }
        profile={clinician}
        action={markUsed}
      />
    )
  );
}

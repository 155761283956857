import { Player } from '@lottiefiles/react-lottie-player';
import { Alert, AlertTitle, Box, Button, Link, Stack } from '@mui/material';
import { constants } from 'common/constants';

const ErrorBoundary = ({ isOnboarding }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
      sx={{ height: '75vh' }}
    >
      <Player
        src="https://lottie.host/eae6dbe0-7dbe-42bf-b73f-8f934bc45348/Oobwak2hMT.json"
        loop
        autoplay
        height="50vh"
        width="auto"
      />
      <Box display="flex" alignItems="center" flexDirection="column" my={2}>
        <Alert severity="info" size="large">
          <AlertTitle>Oops! Something went wrong</AlertTitle>
          Please reload the page or{' '}
          <Link sx={{ color: 'inherit' }} href={constants.contactUrl}>
            contact us
          </Link>{' '}
          if the error is persistent.
        </Alert>
      </Box>
      <Stack direction="row">
        <Button variant="outlined" onClick={() => window.location.reload()}>
          Reload
        </Button>
        <Button href={isOnboarding ? '/' : '/'}>Take Me Home</Button>
      </Stack>
    </Box>
  );
};

export default ErrorBoundary;

import { useCallback } from 'react';

import { API, Auth, graphqlOperation } from 'aws-amplify';
import { actionInvitationLink } from 'graphql/queries';
import mixpanel from 'mixpanel-browser';

export const useFetchData = () => {
  const fetchData = useCallback(async (query, variables) => {
    try {
      const data = await API.graphql(graphqlOperation(query, variables));

      return data.data;
    } catch (e) {
      throw new Error(e);
    }
  }, []); // Empty dependency array means this callback will never change

  return fetchData;
};

export const performLinkingAction = async (
  userFirstName,
  userLastName,
  name,
  action,
  source,
  role,
  invitationID,
  clientData,
  clinicianData,
  locationID,
  clinicID
) => {
  try {
    const vars = {
      role,
      userFirstName,
      userLastName,
      name,
      invitationID,
      source,
      action,
      ...clientData,
      ...clinicianData,
      locationID,
      clinicID
    };
    const { data } = await API.graphql(
      graphqlOperation(actionInvitationLink, vars)
    );

    if (action === 'CONFIRM_client_clinician_link') {
      mixpanel.track('confirm_link');
      window.dataLayer.push({
        event: 'confirm_link'
      });
    } else if (action === 'REJECT_client_clinician_link') {
      mixpanel.track('reject_link');
      window.dataLayer.push({
        event: 'reject_link'
      });
    }
    return data.actionInvitationLink;
  } catch (e) {
    throw new Error(e);
  }
};

export const handleInvitationError = (err, setError) => {
  setError(err);
};

export const extractData = (entity, role) => {
  if (role === 'clinician') {
    return entity.attributes
      ? {
          clinicianID: entity.username,
          clinicianSub: entity.attributes.sub,
          clinicianFirstName: entity.attributes.given_name,
          clinicianLastName: entity.attributes.family_name,
          clinicianEmail: entity.attributes.email
        }
      : {
          clinicianID: entity.id,
          clinicianSub: entity.sub,
          clinicianFirstName: entity.firstName,
          clinicianLastName: entity.lastName,
          clinicianEmail: entity.email
        };
  } else if (role === 'client') {
    return entity.attributes
      ? {
          clientID: entity.username,
          clientFirstName: entity.attributes.given_name,
          clientLastName: entity.attributes.family_name,
          clientEmail: entity.attributes.email
        }
      : {
          clientID: entity.id,
          clientFirstName: entity.firstName,
          clientLastName: entity.lastName,
          clientEmail: entity.email
        };
  }
};

export const confirm = async (
  userFirstName,
  userLastName,
  name,
  action,
  source,
  invitationID,
  clientData,
  clinicianData,
  locationID,
  clinicID,
  role,
  performLinkingAction,
  setConfirming,
  setConfirmed,
  setError,
  user,
  clinician,
  extractClientData,
  extractClinicianData
) => {
  try {
    setConfirming(true);
    const vars = {
      source,
      invitationID,
      clientData,
      clinicianData,
      locationID,
      clinicID,
      role,
      action,
      userFirstName,
      userLastName,
      name
    };
    if (action.includes('client')) {
      vars.clientData = extractClientData(user);
      vars.clinicianData = extractClinicianData(clinician);
    }
    const res = await performLinkingAction(vars);
    if (res.statusCode !== 500) {
      setConfirmed(true);
    } else {
      throw new Error(res.body);
    }
  } catch (err) {
    setError(err.message); // You may need to adapt this to fit your actual error handling
  } finally {
    setConfirming(false);
  }
};

export const linkAction = async config => {
  const {
    userFirstName,
    userLastName,
    name,
    action,
    source,
    role,
    setInProgress,
    setResult,
    setError,
    handleInvitationError,
    invitationID,
    clientData,
    clinicianData,
    locationID,
    clinicID
  } = config;

  try {
    setInProgress(true);
    const res = await performLinkingAction(
      userFirstName,
      userLastName,
      name,
      action,
      source,
      role,
      invitationID,
      clientData,
      clinicianData,
      locationID,
      clinicID
    );

    if (res.statusCode !== 500) {
      setResult(true);
      await Auth.currentAuthenticatedUser({ bypassCache: true });
      return true;
    } else {
      throw new Error(res.body);
    }
  } catch (err) {
    handleInvitationError(err, setError);
  } finally {
    setInProgress(false);
  }
};

import { useCallback, useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Card } from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import { useAppContext } from 'context';
import {
  updateComponent,
  updateRecord,
  updateUserInput
} from 'graphql/mutations';
import { userInputsByRecordComponentType } from 'graphql/queries';
import { useDebouncedCallback } from 'use-debounce';

import { useUpdateGoal } from 'components/goals/functions';
import {
  CheckBoxComponent,
  Clock,
  ExerciseContent,
  Infinite,
  Input,
  RadioComponent,
  Rater
} from 'components/molecules';
import { Audio } from 'components/organisms';

function EntryComponent({
  component,
  disableDrag = false,
  edit,
  setComponents,
  userInput,
  userInputID,
  setChangedData
}) {
  const {
    id,
    name,
    to,
    from,
    rows,
    noEdit,
    label,
    placeholder,
    collapse,
    upload,
    annotationLabels,
    options,
    content,
    multiselect,
    type
  } = component;
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition
  } = useSortable({ id });
  const { user } = useAuthenticator(context => [context.user]);
  const { state, dispatch } = useAppContext();
  const record = state?.record;

  const [userInputVersion, setUserInputVersion] = useState(userInput?._version);
  const [componentVersion, setComponentVersion] = useState(component?._version);
  const updateRecordAndGoalEntry = useUpdateGoal();

  const deleteComponent = useDeleteComponent(setComponents, componentVersion);
  const updateComponent = useUpdateComponent(
    setComponents,
    setComponentVersion,
    componentVersion
  );

  const handleUpdateUserInput = async (userInputId, changes) => {
    try {
      setChangedData(true);
      const input = {
        id: userInputId,
        updatedBy: user.username,
        _version: userInputVersion,
        ...changes
      };

      const updatedUserInput = await API.graphql(
        graphqlOperation(updateUserInput, {
          input: input
        })
      );

      const newVersion = updatedUserInput.data.updateUserInput._version;
      setUserInputVersion(newVersion);
      if (!record.hasData || record.hasData === 'false') {
        const updatedRecordRes = await API.graphql(
          graphqlOperation(updateRecord, {
            input: {
              id: record.id,
              hasData: 'true',
              _version: record._version
            }
          })
        );
        const updatedRecord = updatedRecordRes.data.updateRecord;
        if (updatedRecord.userInputs.items.length === 0) {
          const { data } = await API.graphql(
            graphqlOperation(userInputsByRecordComponentType, {
              recordID: updatedRecord.id
            })
          );
          const userInputs = data.userInputsByRecordComponentType.items;
          updatedRecord.userInputs.items = userInputs;
        }
        dispatch({
          type: 'set_record',
          record: updatedRecord
        });
      }
    } catch (error) {
      console.error('Error updating user input:', error);
    }
    await updateRecordAndGoalEntry({
      action: 'update'
    });
  };

  const debouncedHandleUpdateUserInput = useDebouncedCallback(
    handleUpdateUserInput,
    300
  );
  const debouncedUpdateComponent = useDebouncedCallback(updateComponent, 300);

  const wrapperStyle = {
    position: 'relative',
    transform: CSS.Translate.toString(transform),
    transition
  };

  let dragHandleProps = {
    ...attributes,
    ...listeners,
    disabled: disableDrag,
    ref: setActivatorNodeRef
  };

  const handleDeleteComponent = () => {
    deleteComponent(component);
  };

  const handleDataChange = (data, userInputId) => {
    if (!userInputId) return;

    let changes = {};
    switch (type) {
      case 'infinite':
        changes = { numberValue: data };
        break;
      case 'clock':
        changes = { clockValue: data };
        break;
      case 'checkboxes':
        changes = {
          checkboxesValue: data
        };
        break;
      case 'emoji-rater':
        changes = { emojiRaterValue: data };
        break;
      case 'input':
        changes = { inputValue: data };
        break;
      case 'radio':
        changes = { radiosValue: data };
        break;
      case 'rater':
        changes = {
          raterValue: data.raterValue,
          raterBoolean: data.raterBooleanValue
        };
        break;
      case 'audio':
        changes = { ...data };
        break;
      default:
        break;
    }

    switch (type) {
      case 'infinite':
      case 'slider':
      case 'clock':
      case 'checkboxes':
      case 'emoji-rater':
      case 'radio':
      case 'rater':
        handleUpdateUserInput(userInputId, changes);
        break;
      case 'input':
        debouncedHandleUpdateUserInput(userInputId, changes);
        break;
      case 'audio':
        handleUpdateUserInput(userInputId, changes);
        break;
      default:
        break;
    }
  };

  const handleSettingChange = (settingName, settingValue) => {
    switch (settingName) {
      case 'options':
        updateComponent({ id, [settingName]: JSON.stringify(settingValue) });
        break;
      case 'allowUpload':
      case 'collapse':
      case 'allowLabels':
      case 'from':
      case 'to':
      case 'rows':
        const variables = {
          id,
          [settingName]: settingValue
        };
        updateComponent(variables);
        break;
      case 'label':
      case 'boolean.label':
      case 'content':
      case (settingName.match(/options\[(\d+)\]\.label/) || {}).input:
      case (settingName.match(/radioOptions\[(\d+)\]\.label/) || {}).input:
        debouncedUpdateComponent({
          id,
          [settingName]: settingValue
        });
        break;
      case 'name':
        debouncedUpdateComponent({
          id,
          name: settingValue
        });
        break;
      default:
        break;
    }
  };

  let initialData = undefined;

  if (userInput) {
    switch (type) {
      case 'infinite':
        initialData = userInput.numberValue;
        break;
      case 'slider':
        initialData = userInput.sliderValue;
        break;
      case 'clock':
        initialData = userInput.clockValue ?? undefined;
        break;
      case 'checkboxes':
        initialData = userInput?.checkboxesValue ?? undefined;
        break;
      case 'emoji-rater':
        initialData = userInput.emojiRaterValue;
        break;
      case 'input':
        initialData = userInput.inputValue;
        break;
      case 'radio':
        initialData = userInput.radiosValue ?? undefined;
        break;
      case 'rater':
        initialData = {
          raterValue: userInput.raterValue,
          raterBooleanValue: userInput.raterBoolean
        };
        break;
      default:
        initialData = null;
        break;
    }
  }

  let componentToRender = null;

  if (!userInput) {
    return;
  }

  switch (type) {
    case 'input':
      componentToRender = (
        <Input
          userInputID={userInputID}
          component={component}
          dragHandleProps={dragHandleProps}
          initialData={initialData}
          placeholder={placeholder || ''}
          label={label || ''}
          name={name}
          rows={rows || 1}
          onDataChange={handleDataChange}
          onDelete={handleDeleteComponent}
          onSettingChange={handleSettingChange}
          record={record}
        />
      );
      break;
    case 'rater':
      componentToRender = (
        <Rater
          userInputID={userInputID}
          component={component}
          initialRaterData={initialData?.raterValue && initialData?.raterValue}
          initialRaterBooleanData={initialData?.raterBooleanValue}
          dragHandleProps={dragHandleProps}
          name={name}
          from={from ?? 0}
          to={
            // eslint-disable-next-line
            to != undefined
              ? to
              : state?.client?.program?.includes('camperdown')
              ? 8
              : user.attributes['custom:program']?.includes('camperdown')
              ? 8
              : 10
          }
          onDataChange={handleDataChange}
          onDelete={handleDeleteComponent}
          onSettingChange={handleSettingChange}
        />
      );
      break;

    case 'content':
      componentToRender = (
        <ExerciseContent
          userInputID={userInputID}
          component={component}
          record={record}
          dragHandleProps={dragHandleProps}
          content={
            content ??
            '####The largest snake in the world is the anaconda. Anacondas belong to the boa constrictor family and are found in the Amazon Jungle. They usually live near rivers or lakes as they like to lie in muddy waters. They are not aggressive and will usually disappear into the water when confronted.'
          }
          name={name}
          onDelete={handleDeleteComponent}
          onSettingChange={handleSettingChange}
        />
      );
      break;

    case 'checkboxes':
      componentToRender = (
        <CheckBoxComponent
          userInputID={userInputID}
          component={component}
          record={record}
          dragHandleProps={dragHandleProps}
          initialData={initialData}
          options={options}
          multiselect={multiselect ?? true}
          name={name}
          onDataChange={handleDataChange}
          onDelete={handleDeleteComponent}
          onSettingChange={handleSettingChange}
        />
      );
      break;
    case 'radio':
      componentToRender = (
        <RadioComponent
          initialData={initialData}
          userInputID={userInputID}
          component={component}
          record={record}
          dragHandleProps={dragHandleProps}
          name={name}
          options={options}
          onDataChange={handleDataChange}
          onDelete={handleDeleteComponent}
          onSettingChange={handleSettingChange}
        />
      );
      break;
    case 'audio':
      componentToRender = (
        <Audio
          userInput={userInput}
          component={component}
          record={record}
          dragHandleProps={dragHandleProps}
          noEdit={noEdit}
          collapse={collapse === null ? true : collapse}
          upload={upload || true}
          name={name}
          annotationLabels={annotationLabels || true}
          onDelete={handleDeleteComponent}
          onSettingChange={handleSettingChange}
          onDataChange={handleDataChange}
        />
      );
      break;
    case 'infinite':
      componentToRender = (
        <Infinite
          userInput={userInput}
          component={component}
          initialData={initialData}
          record={record}
          dragHandleProps={dragHandleProps}
          from={from ?? 0}
          to={to ?? 1000}
          name={name}
          annotationLabels={annotationLabels ?? true}
          onDelete={handleDeleteComponent}
          onSettingChange={handleSettingChange}
          onDataChange={handleDataChange}
        />
      );
      break;
    case 'clock':
      componentToRender = (
        <Clock
          userInput={userInput}
          userInputID={userInputID}
          component={component}
          initialData={initialData}
          record={record}
          dragHandleProps={dragHandleProps}
          name={name}
          onDelete={handleDeleteComponent}
          onSettingChange={handleSettingChange}
          onDataChange={handleDataChange}
        />
      );
      break;
    default:
      break;
  }
  return edit ? (
    <Card
      ref={setNodeRef}
      raised
      sx={[
        { p: 1 },
        // { backgroundColor: 'background.card' },
        isDragging && { zIndex: 10 }
      ]}
      style={wrapperStyle}
    >
      {componentToRender}
    </Card>
  ) : (
    componentToRender
  );
}

function useDeleteComponent(setComponents, componentVersion) {
  return useCallback(
    async component => {
      await API.graphql({
        query: updateComponent,
        variables: {
          input: {
            id: component?.id,
            complete: true,
            _version: componentVersion
          }
        }
      });
      setComponents(prevComponents =>
        prevComponents.filter(({ id }) => id !== component.id)
      );
    },
    [setComponents, componentVersion]
  );
}

function useUpdateComponent(
  setComponents,
  setComponentVersion,
  componentVersion
) {
  return async updateComponentInput => {
    const input = { ...updateComponentInput, _version: componentVersion };
    const result = await API.graphql(
      graphqlOperation(updateComponent, {
        input
      })
    );
    const updatedComponent = result.data.updateComponent;
    setComponentVersion(updatedComponent._version);
    setComponents(prevComponents =>
      prevComponents.map(prevComponent =>
        prevComponent.id === updatedComponent.id
          ? updatedComponent
          : prevComponent
      )
    );
  };
}

export default EntryComponent;

import { useEffect, useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Step,
  StepLabel,
  Stepper
} from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import { useAppContext } from 'context';
import { useSnackbar } from 'context/SnackBar';
import {
  componentsByEntrySectionByOrder,
  entrySectionsByEntryIDByOrder,
  importClient
} from 'graphql/queries';
import moment from 'moment-timezone';
import Papa from 'papaparse';

import { ErrorMessage, SettingHeader } from 'components/atoms';

import StepOne from './stepOne';
import StepThree from './stepThree';
import StepTwo from './stepTwo';

export default function ImportClient() {
  const { state } = useAppContext();
  const { user } = useAuthenticator(context => [context.user]);
  const client = state?.client;
  const clientID =
    state?.client?.id || user.attributes['custom:clientID'] || user.username;
  const clientSub = client?.sub || user.attributes.sub;
  const [openImport, setOpenImport] = useState(false);
  const [dataInput, setDataInput] = useState('');
  const [parsedData, setParsedData] = useState(null);
  const [hasHeader, setHasHeader] = useState(true);
  const [selectedEntry, setSelectedEntry] = useState({});
  const [disableNext, setDisableNext] = useState(true);
  const [activeStep, setActiveStep] = useState(0);
  const [components, setComponents] = useState([]);
  const [selectedComponents, setSelectedComponents] = useState({});
  const [error, setError] = useState(null);
  const [importing, setImporting] = useState(false);
  const timeZone = moment.tz.guess();
  const { enqueueSnackbar } = useSnackbar();

  const steps = ['Step 1: Import', 'Step 2: Review', 'Step 3: Complete'];

  const handleNext = () => {
    if (activeStep === 2) {
      handleSubmit();
    } else {
      setActiveStep(prevStep => prevStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep(prevStep => prevStep - 1);
  };

  const handleClose = () => {
    setOpenImport(false);
  };
  const handleFileUpload = event => {
    const file = event.target.files[0];

    const reader = new FileReader();
    reader.onload = function (evt) {
      setDataInput(evt.target.result);
    };
    reader.readAsText(file);
  };

  useEffect(() => {
    const parseData = () => {
      const jsonData = Papa.parse(dataInput, {
        header: hasHeader,
        skipEmptyLines: true,
        dynamicTyping: true
      });
      setParsedData(jsonData.data);
    };
    if (dataInput) {
      parseData();
    } else {
      setParsedData(null);
    }
    return () => {
      setParsedData(null);
    };
  }, [dataInput, hasHeader]);

  useEffect(() => {
    const isDateAndAnotherColumnMapped = () => {
      const dateSelected = Object.values(selectedComponents).some(
        value => value.type === 'Date'
      );

      const anotherComponentSelected = Object.values(selectedComponents).some(
        value => value && value.type !== 'Date'
      );

      return dateSelected && anotherComponentSelected;
    };

    switch (activeStep) {
      case 0:
        if (selectedEntry?.id && parsedData?.length > 0) {
          setDisableNext(false);
        } else {
          setDisableNext(true);
        }
        break;
      case 1:
        if (isDateAndAnotherColumnMapped()) {
          setDisableNext(false);
        } else {
          setDisableNext(true);
        }
        break;
      case 2:
        if (
          (parsedData?.length > 0,
          selectedEntry?.id && isDateAndAnotherColumnMapped())
        ) {
          setDisableNext(false);
        } else {
          setDisableNext(true);
        }
        break;
      default:
        setDisableNext(true);
        break;
    }
  }, [parsedData, selectedEntry, activeStep, selectedComponents]);

  useEffect(() => {
    const getDataFields = async () => {
      const { data } = await API.graphql(
        graphqlOperation(entrySectionsByEntryIDByOrder, {
          entryID: selectedEntry.id
        })
      );
      const entrySections = data.entrySectionsByEntryIDByOrder.items;
      const filteredEntrySections = entrySections.filter(
        item => !item.complete
      );
      let components = [];
      for (let entrySection of filteredEntrySections) {
        const { data } = await API.graphql(
          graphqlOperation(componentsByEntrySectionByOrder, {
            entrySectionID: entrySection.id
          })
        );
        const componentData = data.componentsByEntrySectionByOrder.items;
        const filteredComponents = componentData.filter(
          item => !item.complete && item.type !== 'audio'
        );
        for (let component of filteredComponents) {
          components.push(component);
        }
      }
      setComponents(components);
    };

    if (selectedEntry?.id) {
      getDataFields();
    }
    return () => {
      setComponents([]);
    };
  }, [selectedEntry]);

  const handleComponentSelection = (key, value) => {
    if (value === '') {
      setSelectedComponents(prevState => {
        const newState = { ...prevState };
        delete newState[key];
        return newState;
      });
    } else if (value.type === 'Date') {
      setSelectedComponents(prevState => ({ ...prevState, [key]: value }));
    } else {
      if (
        Object.values(selectedComponents)
          .map(comp => comp.id)
          .includes(value.id)
      ) {
        console.warn('This component is already selected!');
        return;
      }
      setSelectedComponents(prevState => ({ ...prevState, [key]: value }));
    }
  };

  const handleSubmit = async () => {
    try {
      setImporting(true);
      const variables = {
        clientID: clientID,
        clientSub: clientSub,
        parsedData: JSON.stringify(parsedData),
        selectedEntry: JSON.stringify(selectedEntry),
        selectedComponents: JSON.stringify(selectedComponents),
        timezone: timeZone
      };
      const { data } = await API.graphql(
        graphqlOperation(importClient, variables)
      );

      const parsedRes = JSON.parse(data.importClient);

      if (parsedRes.statusCode !== 200) {
        throw new Error(parsedData.error);
      }
      enqueueSnackbar(`Import complete`, {
        severity: 'success'
      });
      handleClose();
      setDataInput('');
      setParsedData({});
      setSelectedComponents({});
      setSelectedEntry({});
      setActiveStep(0);
    } catch (err) {
      enqueueSnackbar(`Error while importing`, {
        severity: 'error'
      });
      setError(err.message);
    } finally {
      setImporting(false);
    }
  };

  return (
    <Box p={1} my={2}>
      <SettingHeader
        state={openImport}
        setState={setOpenImport}
        label="Import Client Data"
        button="Import"
      />

      <Dialog fullWidth maxWidth="md" open={openImport} onClose={handleClose}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Stack alignItems="center" direction="row" spacing={1}>
            <DialogTitle sx={{ justifyContent: 'space-between' }}>
              Import Client Data
            </DialogTitle>
            <Chip color="info" size="small" label="BETA" />
          </Stack>
          <IconButton sx={{ mr: 2 }} onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>

        <Divider sx={{ opacity: 0.3 }} />
        <DialogContent>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            sx={{ minHeight: 400 }}
          >
            {activeStep === 0 && (
              <Box>
                <StepOne
                  selectedEntry={selectedEntry}
                  setSelectedEntry={setSelectedEntry}
                  setDataInput={setDataInput}
                  dataInput={dataInput}
                  handleFileUpload={handleFileUpload}
                  hasHeader={hasHeader}
                  setHasHeader={setHasHeader}
                />
              </Box>
            )}

            {activeStep === 1 && (
              <Box>
                <StepTwo
                  selectedEntry={selectedEntry}
                  parsedData={parsedData}
                  components={components}
                  hasHeader={hasHeader}
                  handleComponentSelection={handleComponentSelection}
                  selectedComponents={selectedComponents}
                />
              </Box>
            )}
            {activeStep === 2 && (
              <Box flexDirection="column" display="flex">
                {importing ? (
                  <Box
                    sx={{ flexGrow: 1 }}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <CircularProgress />
                  </Box>
                ) : Boolean(error) ? (
                  <ErrorMessage message={error} />
                ) : (
                  <StepThree
                    hasHeader={hasHeader}
                    parsedData={parsedData}
                    selectedEntry={selectedEntry}
                    selectedComponents={selectedComponents}
                  />
                )}
              </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <Button disabled={activeStep === 0} onClick={handleBack}>
            Back
          </Button>
          <Button disabled={disableNext || importing} onClick={handleNext}>
            {activeStep === 2 ? 'Import' : 'Next'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

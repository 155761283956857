import {
  Close,
  Email,
  Face,
  Face2,
  PersonOff,
  RadioButtonChecked,
  RadioButtonUnchecked
} from '@mui/icons-material';
import {
  Card,
  CardHeader,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip
} from '@mui/material';

import ClientName from '../ClientName';
import ClientNameSub from '../ClientNameSub';
import ProfileAvatar from '../ProfileAvatar';

export default function PrivateClientCard({
  slim,
  user,
  setSelectedClients,
  client,
  selectedClients,
  selectClients
}) {
  const toggleSelectClient = client => {
    setSelectedClients(currentSelectedClients => {
      const isAlreadySelected = currentSelectedClients.some(
        selectedClient => selectedClient.id === client.id
      );

      if (isAlreadySelected) {
        return currentSelectedClients.filter(
          selectedClient => selectedClient.id !== client.id
        );
      } else {
        return [...currentSelectedClients, client];
      }
    });
  };

  return (
    <Card
      elevation={slim ? 0 : 4}
      sx={{ backgroundColor: slim ? 'background.default' : 'background.paper' }}
    >
      <CardHeader
        action={
          slim ? (
            <>
              {client.clinician?.id && (
                <Tooltip
                  placement="top"
                  title={
                    client.clinician?.id === user.username
                      ? 'Assigned to me'
                      : 'Assigned to a clinician'
                  }
                >
                  <span>
                    <IconButton disabled>
                      {client.clinician?.id === user.username ? (
                        <Face2 color="primary" />
                      ) : (
                        <Face />
                      )}
                    </IconButton>
                  </span>
                </Tooltip>
              )}
              <Tooltip
                placement="top"
                title={`Don't ${
                  client.clinician?.id ? 're-assign' : 'assign'
                } this client`}
              >
                <IconButton onClick={() => toggleSelectClient(client)}>
                  <Close />
                </IconButton>
              </Tooltip>
            </>
          ) : selectClients ? (
            <IconButton onClick={() => toggleSelectClient(client)}>
              {selectedClients.some(
                selectedClient => selectedClient.id === client.id
              ) ? (
                <RadioButtonChecked />
              ) : (
                <RadioButtonUnchecked />
              )}
            </IconButton>
          ) : null
        }
        title={<ClientName client={client} />}
        avatar={<ProfileAvatar profile={client} />}
        subheader={<ClientNameSub client={client} />}
      />
      <List dense>
        <ListItem>
          <ListItemIcon sx={{ paddingLeft: 1 }}>
            <Email />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{ sx: { paddingLeft: 0 } }}
            primary={client.email}
          />
        </ListItem>
        {!slim && !client.clinician?.id && (
          <ListItem sx={{ mb: 0.25 }}>
            <ListItemIcon sx={{ paddingLeft: 1 }}>
              <PersonOff />
            </ListItemIcon>
          </ListItem>
        )}
        {!slim && client.clinician?.id && (
          <ListItem>
            <ListItemIcon sx={{ paddingLeft: 1 }}>
              {client.clinician.id === user.username ? (
                <Tooltip placement="top" title="This is my client">
                  <Face2 />
                </Tooltip>
              ) : (
                <Tooltip placement="top" title="Assigned to a clinician">
                  <Face />
                </Tooltip>
              )}
            </ListItemIcon>
            <ListItemText
              primary={
                client.clinician.firstName + ' ' + client.clinician.lastName
              }
            />
          </ListItem>
        )}
      </List>
    </Card>
  );
}

import { useEffect, useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Fade,
  Link,
  Step,
  StepLabel,
  Stepper,
  Typography
} from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import { constants } from 'common/constants';
import { useAppContext } from 'context';
import { useSnackbar } from 'context/SnackBar';
import { updateClient } from 'graphql/mutations';
import {
  createClient,
  createFromTemplate,
  getClient,
  getClientsByEmail,
  inviteParticipants,
  linkExistingClientOrClinician
} from 'graphql/queries';
import mixpanel from 'mixpanel-browser';
import { useNavigate } from 'react-router';
import uuid from 'react-uuid';
import * as yup from 'yup';

import { ClientDetails, ProgramSelector } from 'components/molecules';

export default function AddClientStepper({ setIsProcessing }) {
  const navigate = useNavigate();
  const { user } = useAuthenticator(context => [context.user]);
  const { dispatch } = useAppContext();
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [forValue, setForValue] = useState('');
  const [participants, setParticipants] = useState([]);
  const [program, setProgram] = useState('');
  const [programStage, setProgramStage] = useState(null);
  const [existingProgram, setExistingProgram] = useState('');
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [clientFirstName, setClientFirstName] = useState('');
  const [clientLastName, setClientLastName] = useState('');
  const [complete, setComplete] = useState(false);
  const [error, setError] = useState(null);
  const [creatingClient, setCreatingClient] = useState(false);
  const [creatingTemplate, setCreatingTemplate] = useState(false);
  const [invitingParticipants, setInvitingParticipants] = useState(false);
  const [sendingInvite, setSendingInvite] = useState(false);
  const [clientExists, setClientExists] = useState(false);
  const [client, setClient] = useState(null);
  const [override, setOverride] = useState('no');
  const [updatingClient, setUpdatingClient] = useState(false);
  const steps = ['Client Details', 'Choose Program'];
  const clinicID = user.attributes['custom:clinicID'];
  const locationID = user.attributes['custom:locationID'];
  const audioSampleEntryID = uuid();

  const { enqueueSnackbar } = useSnackbar();

  const [emailError, setEmailError] = useState('');
  const emailSchema = yup.object().shape({
    email: yup.string().email('Invalid email').required('Email is required')
  });

  const validateEmail = async () => {
    try {
      await emailSchema.validate({ email });
      setEmailError('');
      return true;
    } catch (err) {
      setEmailError(err.message);
      return false;
    }
  };

  const lookupEmail = async newEmail => {
    setEmail(newEmail);
    if (newEmail !== '') {
      if (emailError) {
        setEmailError('');
      }
      const search = await API.graphql(
        graphqlOperation(getClientsByEmail, { email: newEmail.trim() })
      );
      const client = search.data.getClientsByEmail.items;
      if (user.attributes.email === newEmail.trim()) {
        setEmailError('You cannot add yourself as a client.');
      }
      if (client.length > 0) {
        setClientExists(true);
        setClient(client[0]);
      } else {
        setClientExists(false);
        setClient(null);
        setOverride('yes');
      }
    }
  };

  useEffect(() => {
    if (client?.program) {
      setExistingProgram(client.program || '');
    }
  }, [client]);

  useEffect(() => {
    setOverride('no');
    if (clientExists) {
      setProgramStage(client.stage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientExists]);

  const isStepOptional = step => step === 4;

  const isStepSkipped = step => skipped.has(step);

  const handleNext = async () => {
    if (activeStep === 0) {
      if (participants.length > 0 && !validateParticipants()) {
        return;
      }
      const isEmailValid = await validateEmail();
      if (!isEmailValid) {
        return;
      }
    }
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep(prevActiveStep => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }
    setActiveStep(prevActiveStep => prevActiveStep + 1);
    setSkipped(prevSkipped => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const isValidStep = () => {
    if (!forValue) return false;

    if (emailError) return false;

    if (participants.length > 0) {
      const areParticipantsValid = participants.every(p => {
        const isEmailValid =
          p.email && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(p.email);
        return p.firstName && p.lastName && isEmailValid && p.role;
      });
      if (!areParticipantsValid) return false;
    }

    switch (forValue) {
      case 'child':
        return (
          firstName && lastName && email && clientFirstName && clientLastName
        );
      case 'adult':
        return firstName && lastName && email;
      case 'carer':
        return (
          firstName && lastName && email && clientFirstName && clientLastName
        );
      case 'other':
        return (
          firstName && lastName && email && clientFirstName && clientLastName
        );
      default:
        return false;
    }
  };

  const validateParticipants = () => {
    let isValid = true;
    const updatedParticipants = participants.map(p => {
      const errors = {
        firstName: p.firstName ? '' : 'First name is required',
        lastName: p.lastName ? '' : 'Last name is required',
        email:
          p.email && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(p.email)
            ? ''
            : 'Valid email is required',
        role: p.role ? '' : 'Role is required'
      };

      if (!p.firstName || !p.lastName || !p.email || !p.role) {
        isValid = false;
      }

      return { ...p, errors };
    });

    setParticipants(updatedParticipants);
    return isValid;
  };

  const handleCreateClient = async () => {
    try {
      setIsProcessing(true);
      setCreatingClient(true);
      const variables = {
        program,
        stage: programStage,
        forValue,
        firstName,
        lastName,
        clientFirstName,
        clientLastName,
        email,
        clinicianID: user.username,
        clinicianClientsId: user.username,
        clinicianSub: user.attributes.sub,
        clinicianFirstName: user.attributes.given_name,
        clinicianLastName: user.attributes.family_name,
        locationID,
        clinicID,
        audioSampleEntryID
      };

      const { data } = await API.graphql(
        graphqlOperation(createClient, variables)
      );

      const res = data.createClient;

      console.log('res', res);
      const body = JSON.parse(res.body);
      if (res.statusCode === 500) {
        throw new Error(res.body);
      } else {
        setClient({
          id: body.clientID,
          program,
          stage: programStage,
          forValue,
          firstName,
          lastName,
          clientFirstName,
          clientLastName,
          email,
          clinicianID: user.username,
          clinicianSub: user.attributes.sub,
          clinicianFirstName: user.attributes.given_name,
          clinicianLastName: user.attributes.family_name
        });
        return body.clientID;
      }
    } catch (err) {
      console.log('err.message', err.message || err.body);
      setError(err.message || err.body || 'Something went wrong');
    } finally {
      setCreatingClient(false);
      setIsProcessing(false);
    }
  };

  const handleCreateFromTemplate = async (clientID, clientSub) => {
    try {
      setIsProcessing(true);
      setCreatingTemplate(true);
      if (program !== '') {
        const variables = {
          clientSub: clientSub,
          clientID: clientID,
          program: program,
          createdByClinician: true
        };
        const { data } = await API.graphql(
          graphqlOperation(createFromTemplate, variables)
        );
        const res = data.createFromTemplate;

        const body = JSON.parse(res.body);
        if (res.statusCode !== 200) {
          throw new Error(body);
        }
      }
    } catch (err) {
      setError(err.message || err.body || 'Something went wrong');
    } finally {
      setCreatingTemplate(false);
      setIsProcessing(false);
    }
  };

  const inviteClient = async clientID => {
    const variables = {
      source: 'clinician',
      clinicianID: user.username,
      clinicianFirstName: user.attributes.given_name,
      clinicianLastName: user.attributes.family_name,
      clinicianEmail: user.attributes.email,
      clientID,
      clientFirstName: firstName,
      clientLastName: lastName,
      clientEmail: email
    };
    try {
      setSendingInvite(true);
      await API.graphql(
        graphqlOperation(linkExistingClientOrClinician, variables)
      );
      setComplete(true);
    } catch (err) {
      setError(err.message || 'Sorry, something went wrong.');
    } finally {
      setSendingInvite(false);
    }
  };

  const handleCreateAndNext = async () => {
    try {
      handleNext();
      let clientID;
      let clientSub;
      if (clientExists) {
        clientID = client.id;
        clientSub = client.sub;
      } else {
        clientID = await handleCreateClient();
        clientSub = clientID;
        await handleCreateFromTemplate(clientID, clientSub);
        if (participants?.length > 0) {
          await handleCreateParticipants(clientID);
        }
        setComplete(true);
      }
      if (clientExists && (override === 'yes' || existingProgram !== program)) {
        await handleCreateFromTemplate(clientID, clientSub);
        await inviteClient(clientID);
        setComplete(true);
      } else if (
        clientExists &&
        (override !== 'yes' || existingProgram === program)
      ) {
        setUpdatingClient(true);
        const { data } = await API.graphql(
          graphqlOperation(getClient, { id: clientID })
        );
        const clientVersion = data.getClient._version;
        await API.graphql(
          graphqlOperation(updateClient, {
            input: {
              id: clientID,
              program,
              stage: programStage,
              forValue,
              clientFirstName,
              clientLastName,
              _version: clientVersion
            }
          })
        );
        setUpdatingClient(false);
        await inviteClient(clientID);
      }
      enqueueSnackbar(`Invited ${firstName} to connect on SpeechFit`, {
        severity: 'success'
      });
    } catch (err) {
      console.log('err handleCreateAndNext', err);
      setError(err.message);
      enqueueSnackbar(`Error inviting ${firstName} to connect on SpeechFit`, {
        severity: 'error'
      });
    }
  };

  const handleCreateParticipants = async clientID => {
    try {
      setInvitingParticipants(true);
      const participantVariables = {
        participants: JSON.stringify(participants),
        clientID: clientID,
        createdBy: user.username,
        action: 'create',
        source: 'clinician',
        invitedByFirstName: user.attributes.given_name,
        invitedByLastName: user.attributes.family_name,
        invitedByEmail: user.attributes.email
      };
      await API.graphql(
        graphqlOperation(inviteParticipants, participantVariables)
      );
    } catch (e) {
      console.log('e', e);
      return e;
    } finally {
      setInvitingParticipants(false);
    }
  };

  const addNew = async () => {
    setOverride('no');
    setClient(null);
    setClientExists(false);
    setCreatingClient(false);
    setCreatingTemplate(false);
    setSendingInvite(false);
    setError(null);
    setComplete(false);
    setClientLastName('');
    setClientFirstName('');
    setLastName('');
    setFirstName('');
    setEmail('');
    setExistingProgram('');
    setProgram('');
    setProgramStage('');
    setForValue('');
    setSkipped(new Set());
    setActiveStep(0);
    setParticipants([]);
  };

  useEffect(() => {
    if (complete && !error) {
      dispatch({ type: 'set_modal', modal: false });
      navigate('/');
      mixpanel.track('create_client');
      window.dataLayer.push({
        event: 'add_client'
      });
    }
  }, [complete, error, dispatch, navigate]);

  return (
    <Box>
      <Box sx={{ width: '100%' }}>
        <Box
          sx={{
            p: 2,
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            width: '100%'
          }}
        >
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <Box
            sx={{ py: { xs: 0, sm: 2 }, px: { xs: 0, sm: 2 } }}
            height="100%"
          >
            {activeStep === steps.length ? (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                minHeight="50vh"
                width="100%"
              >
                {complete && error && (
                  <Fade in={complete}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <Box my={2}>
                        <Button
                          variant="contained"
                          size="small"
                          onClick={() => addNew()}
                        >
                          Try again
                        </Button>
                      </Box>
                    </Box>
                  </Fade>
                )}
                {!complete && (
                  <Fade in={!complete}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <CircularProgress />
                      {creatingClient && (
                        <Fade in={creatingClient}>
                          <Box my={2}>
                            <Typography variant="h6">
                              Creating client...
                            </Typography>
                          </Box>
                        </Fade>
                      )}
                      {creatingTemplate && (
                        <Fade in={creatingTemplate}>
                          <Box my={2}>
                            <Typography variant="h6">
                              Creating from template...
                            </Typography>
                          </Box>
                        </Fade>
                      )}
                      {invitingParticipants && (
                        <Fade in={invitingParticipants}>
                          <Box my={2}>
                            <Typography variant="h6">
                              Inviting participants...
                            </Typography>
                          </Box>
                        </Fade>
                      )}
                      {sendingInvite && (
                        <Fade in={sendingInvite}>
                          <Box my={2}>
                            <Typography variant="h6">
                              Sending invite...
                            </Typography>
                          </Box>
                        </Fade>
                      )}
                      {updatingClient && (
                        <Fade in={updatingClient}>
                          <Box my={2}>
                            <Typography variant="h6">
                              Updating client...
                            </Typography>
                          </Box>
                        </Fade>
                      )}
                    </Box>
                  </Fade>
                )}
                {Boolean(error) && (
                  <Alert severity="error">
                    <AlertTitle>{error}</AlertTitle>Please try again or{' '}
                    <Link href={constants.contactUrl}>contact us</Link>.
                  </Alert>
                )}
              </Box>
            ) : (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="stretch"
                justifyContent="center"
                minHeight="55vh"
                height="100%"
              >
                {activeStep === 0 && (
                  <Fade timeout={500} in={activeStep === 0}>
                    <Box>
                      <ClientDetails
                        forValue={forValue}
                        setForValue={setForValue}
                        email={email}
                        firstName={firstName}
                        setFirstName={setFirstName}
                        lastName={lastName}
                        setLastName={setLastName}
                        clientFirstName={clientFirstName}
                        setClientFirstName={setClientFirstName}
                        clientLastName={clientLastName}
                        setClientLastName={setClientLastName}
                        emailError={emailError}
                        lookupEmail={lookupEmail}
                        participants={participants}
                        setParticipants={setParticipants}
                      />
                    </Box>
                  </Fade>
                )}
                {activeStep === 1 && (
                  <Fade timeout={500} in={activeStep === 1}>
                    <Box>
                      <ProgramSelector
                        clientExists={clientExists}
                        program={program}
                        setProgram={setProgram}
                        forValue={forValue}
                        existingProgram={existingProgram}
                        override={override}
                        setOverride={setOverride}
                        programStage={programStage}
                        setProgramStage={setProgramStage}
                      />
                    </Box>
                  </Fade>
                )}
              </Box>
            )}
          </Box>
          <Box>
            {activeStep === steps.length ? (
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button
                  variant="contained"
                  disabled={!complete}
                  onClick={() => dispatch({ type: 'set_modal', modal: false })}
                >
                  Exit
                </Button>
              </Box>
            ) : (
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                {isStepOptional(activeStep) && (
                  <Button
                    variant="contained"
                    onClick={handleSkip}
                    sx={{ mr: 1 }}
                  >
                    Skip
                  </Button>
                )}
                <Button
                  variant="contained"
                  disabled={!isValidStep()}
                  onClick={
                    activeStep === steps.length - 1
                      ? handleCreateAndNext
                      : handleNext
                  }
                >
                  {activeStep === steps.length - 1 ? 'Confirm' : 'Next'}
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

import { useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { Check } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import { createLocation } from 'graphql/mutations';
import { DateTime } from 'luxon';
import * as yup from 'yup';

import { ErrorMessage } from 'components/atoms';

import { getCountryByTimezone } from 'utils/getCountryFromTimeZone';

const locationSchema = yup.object().shape({
  name: yup.string().required('Location name is required.'),
  streetAddress: yup.string(),
  streetAddress2: yup.string(),
  city: yup.string(),
  postcode: yup.string(),
  state: yup.string(),
  country: yup.string()
});

export default function AddLocation({
  clinicName,
  setOpen,
  locations,
  setLocations,
  setOpenAddLocation,
  setLocation,
  setAddNewLocation,
  setSelectedClinicLocation
}) {
  const { user } = useAuthenticator(context => [context.user]);
  const clinicID = user.attributes['custom:clinicID'];
  const userTimezone = DateTime.local().zoneName;
  const defaultCountryKebab = getCountryByTimezone(userTimezone);
  const [name, setName] = useState(clinicName || '');
  const [streetAddress, setStreetAddress] = useState('');
  const [streetAddress2, setStreetAddress2] = useState('');
  const [city, setCity] = useState('');
  const [postcode, setPostcode] = useState();
  const [state, setState] = useState('');
  const [country, setCountry] = useState(defaultCountryKebab);
  const [error, setError] = useState(null);
  const [creatingLocation, setCreatingLocation] = useState(false);
  const [createdLocation, setCreatedLocation] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});

  const handleCreateLocation = async () => {
    try {
      setCreatingLocation(true);
      const locationData = {
        name,
        streetAddress,
        streetAddress2,
        city,
        postcode,
        state,
        country
      };
      await locationSchema.validate(locationData, { abortEarly: false });
      setValidationErrors({});
      setError(null);
      if (clinicID) {
        locationData.clinicID = clinicID;
        locationData.clinicLocationsId = clinicID;
      }
      const { data } = await API.graphql(
        graphqlOperation(createLocation, { input: locationData })
      );
      const newLocation = data.createLocation;
      setLocations([...locations, newLocation]);
      if (typeof setLocation === 'function') {
        setLocation(newLocation);
      }
      if (typeof setOpenAddLocation === 'function') {
        setOpenAddLocation(false);
      }
      setCreatedLocation(true);
      if (typeof setOpen === 'function') {
        setOpen(false);
      }
      if (typeof setAddNewLocation === 'function') {
        setAddNewLocation(false);
      }
      if (typeof setSelectedClinicLocation === 'function') {
        setSelectedClinicLocation(newLocation);
      }
      reset();
    } catch (err) {
      console.log('err', err);
      if (err instanceof yup.ValidationError) {
        const newValidationErrors = err.inner.reduce(
          (acc, curr) => ({ ...acc, [curr.path]: curr.message }),
          {}
        );
        setValidationErrors(newValidationErrors);
      } else {
        setError(err.message);
      }
    } finally {
      setCreatingLocation(false);
    }
  };

  const handleChange = (event, field) => {
    const { value } = event.target;
    if (field === 'name') setName(value);
    if (field === 'streetAddress') setStreetAddress(value);
    if (field === 'streetAddress2') setStreetAddress2(value);
    if (field === 'city') setCity(value);
    if (field === 'postcode') setPostcode(value);
    if (field === 'state') setState(value);
    if (field === 'country') setCountry(value);

    if (validationErrors && validationErrors[field]) {
      setValidationErrors(prev => ({ ...prev, [field]: undefined }));
    }
  };

  const reset = () => {
    setName('');
    setStreetAddress('');
    setStreetAddress2('');
    setCity('');
    setPostcode('');
    setState('');
    setCountry('');
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextField
          error={!!validationErrors?.name}
          helperText={validationErrors?.name || ''}
          value={name}
          onChange={e => handleChange(e, 'name')}
          fullWidth
          size="small"
          variant="filled"
          label="Location Name"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={streetAddress}
          onChange={e => handleChange(e, 'streetAddress')}
          fullWidth
          size="small"
          variant="filled"
          label="Street Address 1"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={streetAddress2}
          onChange={e => handleChange(e, 'streetAddress2')}
          fullWidth
          size="small"
          variant="filled"
          label="Street Address 2"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          value={city}
          onChange={e => handleChange(e, 'city')}
          fullWidth
          size="small"
          variant="filled"
          label="City"
        />
      </Grid>

      <Grid item xs={12} sm={3}>
        <TextField
          type="number"
          value={postcode}
          onChange={e => handleChange(e, 'postcode')}
          fullWidth
          size="small"
          variant="filled"
          label="Post Code"
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextField
          value={state}
          onChange={e => handleChange(e, 'state')}
          fullWidth
          size="small"
          variant="filled"
          label="State"
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl variant="filled" fullWidth size="small">
          <InputLabel>Country</InputLabel>
          <Select
            value={country}
            onChange={e => handleChange(e, 'country')}
            label="Country"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value="australia">Australia</MenuItem>
            <MenuItem value="canada">Canada</MenuItem>
            <MenuItem value="new-zealand">New Zealand</MenuItem>
            <MenuItem value="singapore">Singapore</MenuItem>
            <MenuItem value="united-kingdom">United Kingdom</MenuItem>
            <MenuItem value="united-states">United States</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid display="flex" justifyContent="flex-end" item xs={12}>
        <LoadingButton
          disabled={Boolean(validationErrors?.name)}
          loading={creatingLocation}
          onClick={handleCreateLocation}
          variant="contained"
          size="small"
          endIcon={createdLocation && <Check color="primary" />}
        >
          Add Location
        </LoadingButton>
      </Grid>
      {Boolean(error) && (
        <Grid item xs={12}>
          <ErrorMessage message={error} />
        </Grid>
      )}
    </Grid>
  );
}

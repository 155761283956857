import { memo } from 'react';

import { Avatar, Box, IconButton, Stack } from '@mui/material';
import { colorMapper } from 'utils';

export const RaterOptions = memo(({ data, onDataClick, options }) => (
  <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
    <Stack
      direction="row"
      spacing={{ xs: 0 }}
      mt={1}
      mb={2}
      sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}
    >
      {options.map(option => (
        <IconButton
          key={option}
          onClick={() => onDataClick(data === option ? null : option)}
        >
          <Avatar
            sx={{
              bgcolor: data === option ? colorMapper({ data: option }) : null
            }}
          >
            {option}
          </Avatar>
        </IconButton>
      ))}
    </Stack>
  </Box>
));

import React, { createContext, useContext, useState } from 'react';

const EntryContext = createContext();

export const EntryProvider = ({ children }) => {
  const [selectedEntry, setSelectedEntry] = useState(null);

  return (
    <EntryContext.Provider value={{ selectedEntry, setSelectedEntry }}>
      {children}
    </EntryContext.Provider>
  );
};

export const useEntryContext = () => useContext(EntryContext);

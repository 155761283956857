import { Skeleton, Typography } from '@mui/material';

export default function ClientName({ client, variant }) {
  if (!client?.firstName)
    return (
      <>
        <Skeleton height={variant === 'h5' ? 40 : 30} width={180} />{' '}
        <Skeleton height={variant === 'h5' ? 40 : 30} width={180} />
      </>
    );
  return (
    <Typography
      fontWeight={'bold'}
      color="text.accent"
      variant={variant || 'h6'}
      align="left"
      lineHeight={1}
      sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
    >
      {client?.clientFirstName
        ? client?.clientFirstName + ' ' + client?.clientLastName
        : client?.firstName + ' ' + client?.lastName}
    </Typography>
  );
}

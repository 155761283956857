import { Close, Info, Settings } from '@mui/icons-material';
import {
  Badge,
  Box,
  Button,
  FormLabel,
  IconButton,
  Stack,
  Tooltip
} from '@mui/material';

function SettingHeader({
  state,
  setState,
  label,
  noEdit,
  hasInfo,
  showInfo,
  setInfo,
  reasonNoEdit,
  customAction,
  button,
  noAction,
  hasUpdate
}) {
  return (
    <Stack direction="row" alignItems="center" spacing={1} sx={{ height: 30 }}>
      {hasInfo && (
        <IconButton
          color={showInfo ? 'secondary' : 'grey'}
          onClick={() => setInfo(prevInfo => !prevInfo)}
        >
          <Info />
        </IconButton>
      )}
      <FormLabel sx={{ fontWeight: 600 }}>{label}</FormLabel>
      {noEdit && (
        <Box display={'flex'} justifyContent={'center'} width={64}>
          <Tooltip title={reasonNoEdit}>
            <span>
              <IconButton disabled size="small">
                <Info size="small" />
              </IconButton>
            </span>
          </Tooltip>
        </Box>
      )}

      {hasUpdate && (
        <IconButton onClick={() => setState(prevState => !prevState)}>
          <Tooltip placement="top" title="Software Update Available">
            <Badge badgeContent={1} color="warning">
              <Settings />
            </Badge>
          </Tooltip>
        </IconButton>
      )}

      {!noEdit &&
        (state ? (
          <Box display={'flex'} justifyContent={'center'} width={64}>
            <IconButton
              size="small"
              onClick={() => setState(prevState => !prevState)}
            >
              <Close fontSize="small" />
            </IconButton>
          </Box>
        ) : noAction ? null : (
          <Button
            sx={{ lineHeight: 1 }}
            onClick={() => setState(prevState => !prevState)}
            size="small"
            color={
              button?.toLowerCase().includes('update') ? 'warning' : 'primary'
            }
          >
            {button || 'Edit'}
          </Button>
        ))}
      {Boolean(customAction) && customAction}
    </Stack>
  );
}

export default SettingHeader;

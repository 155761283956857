import { Box, Divider, Grid, Typography } from '@mui/material';

function RatingGuideItem({ label, description, isLast }) {
  return (
    <>
      <Grid item xs={2} sm={1}>
        <Typography variant="caption">
          <strong>{label}</strong>
        </Typography>
      </Grid>
      <Grid item xs={10} sm={11}>
        <Typography variant="caption">{description}</Typography>
      </Grid>
      {!isLast && (
        <Grid item xs={12}>
          <Box pt={0.5}>
            <Divider sx={{ opacity: 0.3 }} />
          </Box>
        </Grid>
      )}
    </>
  );
}

export default function RatingGuideFluency() {
  const ratingGuideData = [
    {
      label: '0',
      description: 'no technique',
      isLast: false
    },
    {
      label: '3 - 4',
      description: 'less technique',
      isLast: false
    },
    {
      label: '4 - 5',
      description: 'more technique',
      isLast: false
    },
    {
      label: '7 - 8',
      description: 'maximum technique',
      isLast: true
    }
  ];

  return (
    <Box>
      <Grid container>
        {ratingGuideData.map(({ label, description, isLast }) => (
          <RatingGuideItem
            key={label}
            label={label}
            description={description}
            isLast={isLast}
          />
        ))}
      </Grid>
    </Box>
  );
}

import { List, ListItem, ListItemText, Typography } from '@mui/material';

export const westmeadSteps = [
  {
    label:
      'Stage I - Speak With No / Almost No Stuttering in Everyday Conversations',
    description: (
      <>
        <Typography>
          To progress to Stage 2, the following criteria need to be met for two
          consecutive fortnightly consults:
        </Typography>
        <List>
          <ListItem>
            <ListItemText
              primary="(a) clinician SR of 0 or 1 during
              the consultation, and "
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="(b) daily parent typical SRs of 0–1 during the
              week preceding the consultation, with at least four of those seven SRs
              being 0. A minimum requirement during Stage 2 is for the parent to
              document SRs during the week preceding the consultation. However, the
              clinician may request the parent to document SRs more often."
            />
          </ListItem>
        </List>
      </>
    )
  },
  {
    label: 'Stage II - Speak With No / Almost No Stuttering For a Long Time',
    description: (
      <>
        <Typography>
          Stage 2 continues until the child has sustained minimal stuttering for
          around a year. Subsequent to the conclusion of Stage 2, the parent is
          advised to contact the clinician if any relapse occurs that cannot be
          managed by short-term reintroduction of STS practice.
        </Typography>
      </>
    )
  }
];

import { useMemo, useState } from 'react';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  Close,
  Delete,
  DragIndicator,
  Settings,
  Visibility,
  VisibilityOff
} from '@mui/icons-material';
import {
  Box,
  Card,
  Collapse,
  FormControl,
  FormControlLabel,
  Hidden,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import { useAppContext } from 'context';
import {
  updateEntrySection,
  updateSection as updateSectionMutation
} from 'graphql/mutations';
import { getSection } from 'graphql/queries';
import _ from 'lodash';

function useAppEditState() {
  const { state } = useAppContext();
  return state.edit;
}

const SectionSettings = ({
  orientation,
  handleSettingsOrientationChange,
  mainSection,
  section,
  updateSection,
  setEditTitle,
  editTitle
}) => {
  const [sectionName, setSectionName] = useState(section.name || '');

  const handleSectionNameChange = e => {
    setSectionName(e.target.value);
    updateSection({ ...section, name: e.target.value });
  };

  return (
    <Card
      elevation={0}
      sx={{ my: 1, p: 1, backgroundColor: 'background.settings' }}
    >
      <Stack
        direction="row"
        spacing={2}
        mb={1}
        sx={{
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <IconButton onClick={() => setEditTitle(!editTitle)} size="small">
          <Close fontSize="small" />
        </IconButton>
        <Typography variant="caption">Section Settings</Typography>
      </Stack>
      <Stack direction="row" spacing={1} alignItems="center">
        <TextField
          variant="filled"
          size="small"
          color="tertiary"
          label="Title"
          value={sectionName}
          onChange={handleSectionNameChange}
        />
        {!mainSection && (
          <FormControl sx={{ pl: 2 }}>
            <RadioGroup
              value={orientation}
              onChange={handleSettingsOrientationChange}
              row
            >
              <FormControlLabel
                value="column"
                control={<Radio size="small" />}
                label="Column"
              />
              <FormControlLabel
                value="row"
                control={<Radio size="small" />}
                label="Row"
              />
            </RadioGroup>
          </FormControl>
        )}
      </Stack>
    </Card>
  );
};

export default function Section({
  orientation,
  setOrientation,
  mainSection,
  elevation,
  bg,
  children,
  disableButtons,
  disableDrag = false,
  onDelete,
  section,
  setSections,
  updateSection = () => {}
}) {
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition
  } = useSortable({
    id: section.id
  });

  const settings = useMemo(
    () => JSON.parse(section.settings),
    [section.settings]
  );
  const edit = useAppEditState();
  const [hasSectionInfo, setHasSectionInfo] = useState(settings?.info ?? false);
  const [hasChart, setHasChart] = useState(settings?.hasChart ?? false);
  const [inactive, setInactive] = useState(section.inactive ?? false);

  const handleSettingsChartChange = async e => {
    setHasChart(e.target.checked);
    await API.graphql(
      graphqlOperation(
        mainSection ? updateSectionMutation : updateEntrySection,
        {
          input: {
            id: section.id,
            _version: section._version,
            settings: JSON.stringify(
              _.set({ ...settings, hasChart: e.target.checked })
            )
          }
        }
      )
    );
  };

  const handleSettingsInfoChange = async e => {
    setHasSectionInfo(e.target.checked);
    await API.graphql(
      graphqlOperation(
        mainSection ? updateSectionMutation : updateEntrySection,
        {
          input: {
            id: section.id,
            _version: section._version,
            settings: JSON.stringify(
              _.set({ ...settings, info: e.target.checked })
            )
          }
        }
      )
    );
  };

  const handleSettingsOrientationChange = async e => {
    setOrientation(e.target.value);
    await API.graphql(
      graphqlOperation(
        mainSection ? updateSectionMutation : updateEntrySection,
        {
          input: {
            id: section.id,
            _version: section._version,
            settings: JSON.stringify(
              _.set({ ...settings, orientation: e.target.value })
            )
          }
        }
      )
    );
  };

  const handleInactiveChange = async () => {
    setInactive(prevInactive => !prevInactive);
    const { data } = await API.graphql(
      graphqlOperation(getSection, { id: section.id })
    );
    const version = data.getSection._version;
    await API.graphql(
      graphqlOperation(
        mainSection ? updateSectionMutation : updateEntrySection,
        {
          input: {
            id: section.id,
            _version: version,
            inactive: !inactive
          }
        }
      )
    );
    setSections(prevSections => {
      const index = prevSections.findIndex(s => s.id === section.id);
      return [
        ...prevSections.slice(0, index),
        { ...section, inactive: !inactive },
        ...prevSections.slice(index + 1)
      ];
    });
  };

  const [editTitle, setEditTitle] = useState(false);

  const wrapperStyle = {
    position: 'relative',
    transform: CSS.Translate.toString(transform),
    transition
  };

  return (
    <Box ref={setNodeRef} sx={[wrapperStyle, isDragging && { zIndex: 10 }]}>
      <Card
        elevation={elevation || 0}
        sx={[
          {
            my: 2,
            p: 2,
            transform: 'scale(1)',
            transition: 'transform 200ms ease 0s',
            backgroundColor:
              bg === 'none' ? 'background.default' : 'background.paper'
          },
          isDragging && { transform: 'scale(1.02)' }
        ]}
      >
        <Stack
          direction="row"
          sx={{ alignItems: 'center', justifyContent: 'space-between' }}
        >
          <Stack direction="row" spacing={1} alignItems={'center'}>
            {edit && !disableButtons && (
              <IconButton size="small" onClick={() => setEditTitle(!editTitle)}>
                <Settings
                  color={editTitle ? 'secondary' : 'primary'}
                  size="small"
                />
              </IconButton>
            )}
            <Typography
              variant="h4"
              sx={{ flexGrow: 1, color: inactive ? 'grey' : null }}
            >
              {section.name}
            </Typography>
            {edit && mainSection && (
              <Tooltip title={inactive ? 'Activate' : 'Temporarily Deactivate'}>
                <IconButton size="small" onClick={() => handleInactiveChange()}>
                  {inactive ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </Tooltip>
            )}
          </Stack>
          {!disableButtons && edit && !mainSection && (
            <Stack direction="row" sx={{ alignItems: 'center' }}>
              <IconButton onClick={() => onDelete?.()}>
                <Delete />
              </IconButton>
              <IconButton
                ref={setActivatorNodeRef}
                disabled={disableDrag}
                {...attributes}
                {...listeners}
              >
                <DragIndicator />
              </IconButton>
            </Stack>
          )}
        </Stack>

        <Collapse in={edit && editTitle}>
          <Hidden smDown implementation="css">
            <SectionSettings
              handleSettingsChartChange={handleSettingsChartChange}
              mainSection={mainSection}
              info={hasSectionInfo}
              section={section}
              hasChart={hasChart}
              updateSection={updateSection}
              setEditTitle={setEditTitle}
              editTitle={editTitle}
              settings={settings}
              handleSettingsInfoChange={handleSettingsInfoChange}
              handleSettingsOrientationChange={handleSettingsOrientationChange}
              orientation={settings?.orientation ?? orientation}
            />
          </Hidden>
        </Collapse>

        <Box mt={2}>{children}</Box>
      </Card>
    </Box>
  );
}

import React, { useState } from 'react';

import {
  Box,
  FormLabel,
  Grid,
  Link,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';

export default function EnterName({
  firstName,
  clientLastName,
  clientFirstName,
  setClientLastName,
  setClientFirstName,
  forValue
}) {
  const [isFocused, setIsFocused] = useState(false);

  const toggleFocus = focus => {
    setIsFocused(focus);
  };

  const namePrefixes = {
    child: "Child's",
    other: 'Their'
  };

  const tooltipMessages = {
    child: {
      relation: "your child's",
      subject: 'your child'
    },
    other: {
      relation: 'a',
      subject: 'their client'
    }
  };

  const currentTooltipMessage = tooltipMessages[forValue] || {};

  return (
    <Box pb={2}>
      <Box mb={1}>
        <FormLabel
          sx={{
            ...(isFocused && {
              color: 'primary.main'
            })
          }}
        >
          {namePrefixes[forValue] || ''} name:
        </FormLabel>
      </Box>
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <TextField
            value={clientFirstName}
            onChange={e => setClientFirstName(e.target.value)}
            onFocus={() => toggleFocus(true)}
            onBlur={() => toggleFocus(false)}
            size="small"
            fullWidth
            label="First Name"
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            value={clientLastName}
            onChange={e => setClientLastName(e.target.value)}
            onFocus={() => toggleFocus(true)}
            onBlur={() => toggleFocus(false)}
            size="small"
            fullWidth
            label="Last Name"
          />
        </Grid>
      </Grid>
      <Tooltip
        placement="top"
        title={`If you grant your clinician access to your account, a first name will make it easy for them to identify ${
          currentTooltipMessage.subject || ''
        } from their dashboard. If you don't wish to provide a first name, we'll use yours, which is ${firstName}.`}
      >
        <Typography variant="caption" sx={{ fontSize: 'x-small' }}>
          <Link>Why do we need this?</Link>
        </Typography>
      </Tooltip>
    </Box>
  );
}

import React, { useEffect, useState } from 'react';

import {
  Close,
  Delete,
  DragIndicator,
  Info,
  Settings
} from '@mui/icons-material';
import {
  Box,
  Card,
  Checkbox,
  Collapse,
  FormControlLabel,
  IconButton,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { useAppContext } from 'context';
import _ from 'lodash';
import uuid from 'react-uuid';

import InfoSection from '../InfoSection';
import getEmoji from './emojimap';

const CheckBoxSettings = ({ onSettingChange, settings, setShowSettings }) => {
  return (
    <Card
      elevation={0}
      sx={{ my: 1, px: 1, backgroundColor: 'background.settings' }}
    >
      <Stack
        direction="row"
        spacing={1}
        px={1}
        py={1}
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        <IconButton
          color="tertiary"
          size="small"
          onClick={() => setShowSettings(prevShowSettings => !prevShowSettings)}
        >
          <Close color="primary" fontSize="small" />
        </IconButton>
        <Typography variant="caption">CheckBox Settings</Typography>
      </Stack>
      <Stack direction="row" spacing={1}>
        <TextField
          color="tertiary"
          variant="filled"
          size="small"
          label="Name"
          value={settings.name}
          onChange={e => onSettingChange?.('name', e.target.value)}
        />
        <TextField
          variant="filled"
          size="small"
          type="number"
          label="Checkboxes"
          color="tertiary"
          value={settings.options?.length}
          onChange={event => {
            const count = Number(event.target.value);
            const optionsLength = settings.options?.length;
            if (typeof count === 'number')
              onSettingChange?.(
                'options',
                optionsLength < count
                  ? settings.options.concat(
                      ..._.range(count - optionsLength).map(i => ({
                        id: uuid(),
                        label: `Option ${optionsLength + i + 1}`,
                        order: i + 1
                      }))
                    )
                  : _.take(settings.options, count)
              );
          }}
        />
      </Stack>
    </Card>
  );
};

const CheckboxOption = React.memo(function ({
  checked,
  edit,
  label,
  onLabelChange,
  onSelect
}) {
  const [optionLabel, setOptionLabel] = useState(label);

  const handleChange = event => {
    setOptionLabel(event.target.value);
    onLabelChange?.(event.target.value);
  };
  return (
    <Box mr={2}>
      <FormControlLabel
        control={
          <Checkbox
            size="large"
            checkedIcon={getEmoji(label, checked)}
            icon={getEmoji(label)}
            checked={checked}
            onChange={onSelect}
          />
        }
        label={
          edit ? (
            <TextField
              size="small"
              value={optionLabel}
              onChange={handleChange}
            />
          ) : (
            optionLabel
          )
        }
      />
    </Box>
  );
});

const CheckBoxes = ({
  state,
  options,
  data,
  handleDataClick,
  onNameChange
}) => {
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
      <Stack
        direction="row"
        mt={1}
        ml={2}
        mb={2}
        sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}
      >
        {options?.map(option => (
          <CheckboxOption
            key={option.id}
            checked={data.some(
              selectedOption => selectedOption.id === option.id
            )}
            edit={state.edit}
            label={option.label}
            onLabelChange={newLabel => onNameChange(option.id, newLabel)}
            onSelect={() => handleDataClick(option)}
          />
        ))}
      </Stack>
    </Box>
  );
};

const CheckBox = ({
  userInputID,
  dragHandleProps,
  initialData,
  options,
  name,
  onDataChange,
  onDelete,
  onSettingChange,
  component
}) => {
  const { state } = useAppContext();
  const [settings, setSettings] = useState({
    options: JSON.parse(options),
    name
  });
  const [openInfo, setOpenInfo] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(initialData ?? []);

  const removeTypeNameFromOptions = options =>
    options.map(({ __typeName, ...rest }) => rest);

  useEffect(() => {
    if (!settings.options || !settings.options.length) {
      const options = _.range(10).map(i => ({
        id: uuid(),
        label: `Option ${i + 1}`,
        order: (i + 1) * 2
      }));
      const cleanedOptions = removeTypeNameFromOptions(options); // Clean options here
      setSettings(prevSettings => ({
        ...prevSettings,
        options: cleanedOptions
      }));
      onSettingChange?.('options', JSON.stringify(cleanedOptions));
    }
  }, [settings, onSettingChange]);

  const handleDataClick = option => {
    let newSelectedOptions;
    const isOptionSelected = selectedOptions.some(
      selectedOption => selectedOption.id === option.id
    );
    if (isOptionSelected) {
      newSelectedOptions = selectedOptions.filter(
        selectedOption => selectedOption.id !== option.id
      );
    } else {
      newSelectedOptions = selectedOptions.find(
        selectedOption => selectedOption.id === option.id
      )
        ? [...selectedOptions]
        : [...selectedOptions, option];
    }
    setSelectedOptions(newSelectedOptions);
    onDataChange?.(newSelectedOptions, userInputID);
  };

  const handleSettingChange = (settingName, settingValue) => {
    setSettings(prevSettings =>
      _.set({ ...prevSettings }, settingName, settingValue)
    );
    onSettingChange?.(settingName, settingValue);
  };

  const handleChipNameUpdate = (id, newName) => {
    const updatedOptions = settings.options.map(chip =>
      chip.id === id ? { ...chip, label: newName } : chip
    );
    handleSettingChange('options', updatedOptions);
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Box width="100%" sx={{ display: 'flex', alignItems: 'center' }}>
          {state.edit && (
            <IconButton
              sx={{ ml: 1 }}
              onClick={() =>
                setShowSettings(prevShowSettings => !prevShowSettings)
              }
              size="small"
            >
              <Settings
                size="small"
                color={showSettings ? 'secondary' : 'primary'}
              />
            </IconButton>
          )}
          <Stack
            alignItems="center"
            width="100%"
            direction="row"
            spacing={2}
            justifyContent="space-between"
          >
            <Typography variant="h5"> {settings.name}</Typography>
            {component?.showInfo !== false && (
              <IconButton
                size="large"
                sx={{ ml: 1 }}
                onClick={() => setOpenInfo(prevOpenInfo => !prevOpenInfo)}
                color={openInfo ? 'secondary' : 'grey'}
              >
                <Info fontSize="large" />
              </IconButton>
            )}
          </Stack>
        </Box>
        {state.edit && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton onClick={onDelete}>
              <Delete />
            </IconButton>
            <IconButton {...dragHandleProps}>
              <DragIndicator />
            </IconButton>
          </Box>
        )}
      </Box>
      <Collapse in={state.edit && showSettings}>
        <CheckBoxSettings
          onSettingChange={handleSettingChange}
          settings={settings}
          setShowSettings={setShowSettings}
        />
      </Collapse>
      <Collapse in={openInfo}>
        <InfoSection component={component} />
      </Collapse>
      <CheckBoxes
        options={settings.options}
        data={selectedOptions}
        state={state}
        setSettings={setSettings}
        handleDataClick={handleDataClick}
        onNameChange={handleChipNameUpdate}
      />
    </Box>
  );
};

export default CheckBox;

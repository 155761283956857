import * as AllIcons from '@mui/icons-material';

import filledIconNames from './iconNames.json';

const Icons = {};

filledIconNames.forEach(iconName => {
  Icons[iconName] = AllIcons[iconName];
});

export default Icons;

import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { useTheme } from '@emotion/react';
import {
  Delete,
  DeleteForever,
  DragIndicator,
  Info,
  Iso,
  Mic,
  Settings,
  Upload
} from '@mui/icons-material';
import PauseRoundedIcon from '@mui/icons-material/PauseRounded';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import StopIcon from '@mui/icons-material/Stop';
import {
  Avatar,
  Box,
  Button,
  Card,
  Collapse,
  Hidden,
  IconButton,
  LinearProgress,
  Skeleton,
  Slider,
  Stack,
  Typography
} from '@mui/material';
import { lightBlue, red, teal } from '@mui/material/colors';
import { API, Storage, graphqlOperation } from 'aws-amplify';
import { useAppContext } from 'context';
import {
  createAnnotation,
  deleteAnnotation,
  updateAnnotation
} from 'graphql/mutations';
import {
  annotationsByUserInputID,
  getAnnotation,
  getUserInput
} from 'graphql/queries';
import useEffectOnce from 'hooks/useEffectOnce';
import _, { debounce } from 'lodash';
import mixpanel from 'mixpanel-browser';
import WaveSurfer from 'wavesurfer.js';
import MinimapPlugin from 'wavesurfer.js/dist/plugins/minimap';
import AudioRecorder from 'wavesurfer.js/dist/plugins/record';
import Regions from 'wavesurfer.js/dist/plugins/regions';

// import TimelinePlugin from 'wavesurfer.js/dist/plugins/timeline';
import { AudioPermissions, AudioUploadButton } from 'components/atoms';
import { InfoSection, Modal } from 'components/molecules';

import checkMicrophonePermission from 'utils/checkMicPermissions';
import { adjustAlpha } from 'utils/colorChanger';

import ProgressIndicator from './ProgressIndicator';
import Annotation from './annotation';
import Annotations from './annotations';
import TimelinePlugin from './plugins/Timeline';
import { AudioSettings } from './settings';

const AudioMode = {
  PLAYER: 'player',
  RECORDER: 'recorder'
};

const Mode = {
  MIC: 'mic',
  FILE: 'file'
};

const generateModeMap = (value = null) => {
  return Object.keys(Mode).reduce((accumulator, current) => {
    accumulator[Mode[current]] = value;
    return accumulator;
  }, {});
};

export default function Audio(props) {
  const {
    initialMode,
    dragHandleProps = {},
    component,
    noEdit,
    collapse,
    upload,
    name,
    annotationLabels,
    onDelete,
    onSettingChange,
    onDataChange,
    userInput
  } = props;
  const { state } = useAppContext();
  const { user } = useAuthenticator(context => [context.user]);
  const clientID =
    state?.client?.id || user.attributes['custom:clientID'] || user.username;
  const clientSub = state?.client?.sub || user.attributes.sub;

  const audioRecorderRef = useRef(null);
  const regionsPluginRef = useRef(generateModeMap());
  const wsContainerRef = useRef(generateModeMap());
  const playPauseButtonRef = useRef(null);
  const wavesurferRef = useRef(generateModeMap());

  const [annotations, setAnnotations] = useState([]);
  const [audioMode, setAudioMode] = useState(generateModeMap());
  const [audioCompressionProgress, setAudioCompressionProgress] = useState(
    generateModeMap(0)
  );
  const [audioUploadProgress, setAudioUploadProgress] = useState(
    generateModeMap(0)
  );
  const [isCompressing, setIsCompressing] = useState(generateModeMap(false));
  const [isPlaying, setIsPlaying] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [isUploading, setIsUploading] = useState(generateModeMap(false));
  const [isWaveformDrawn, setIsWaveformDrawn] = useState(
    generateModeMap(false)
  );
  const [wavesurfer, setWavesurfer] = useState(generateModeMap());
  const [mode, setMode] = useState(initialMode || Mode.MIC);
  const [settings, setSettings] = useState({
    noEdit,
    collapse,
    upload,
    name,
    annotationLabels
  });
  const [showSettings, setShowSettings] = useState(false);
  const [loadingAudio, setLoadingAudio] = useState(null);
  const [micPermission, setMicPermission] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const theme = useTheme();
  const [openInfo, setOpenInfo] = useState(false);

  let regionNotes = {};
  let regionCategories = {};

  const activeAnnotation = useMemo(
    () => annotations.find(annotation => annotation.isActive),
    [annotations]
  );
  const annotationsByMode = useMemo(
    () =>
      annotations.reduce((result, annotation) => {
        (result[annotation.mode] = result[annotation.mode] || []).push(
          annotation
        );
        return result;
      }, []),
    [annotations]
  );

  const fetchPermissionStatus = useCallback(async () => {
    const status = await checkMicrophonePermission();
    setMicPermission(status);
  }, []);

  useEffect(() => {
    fetchPermissionStatus();
  }, [fetchPermissionStatus]);

  useEffectOnce(() => {
    function initWavesurfers() {
      return Object.keys(Mode).reduce((wavesurfers, key) => {
        wavesurfers[Mode[key]] = createWavesurfer(Mode[key]);
        return wavesurfers;
      }, {});
    }

    wavesurferRef.current = initWavesurfers();

    return () => {
      for (const mode in wavesurferRef.current) {
        wavesurferRef.current[mode].destroy();
      }
    };
  }, []);

  useEffect(() => {
    let unsubscribeDragSelection;

    if (audioMode[mode] === AudioMode.PLAYER) {
      // playPauseButtonRef.current?.focus();
      unsubscribeDragSelection = regionsPluginRef.current[
        mode
      ]?.enableDragSelection({
        color: 'rgba(66, 165, 245, 0.50)'
      });
    } else if (unsubscribeDragSelection) {
      unsubscribeDragSelection();
    }

    return () => {
      if (unsubscribeDragSelection) unsubscribeDragSelection();
    };
  }, [audioMode, mode]);

  useEffect(() => {
    if (!wavesurfer[mode]) return;

    setIsPlaying(false);

    const subscriptions = [
      wavesurfer[mode].on('play', () => setIsPlaying(true)),
      wavesurfer[mode].on('pause', () => setIsPlaying(false))
    ];

    return () => {
      subscriptions.forEach(unsub => unsub());
    };
  }, [mode, wavesurfer]);

  useEffect(() => {
    if (!userInput) return;

    function getAudioFile(filename) {
      wavesurferRef.current[mode]?.destroy();
      wavesurferRef.current[mode] = createWavesurfer(mode, {
        uploadable: false
      });
      Storage.get(`compressed/${filename}`)
        .then(signedUrl => {
          const wavesurferInstance = wavesurferRef.current[mode];

          wavesurferInstance.on('loading', (percent, xhrEvent) => {
            setLoadingAudio(percent);
          });
          wavesurferRef.current[mode].load(signedUrl);
        })
        .catch(error => {
          console.log('Error fetching audio from S3:', error);
        });
      mixpanel.track('load_audio');
      window.dataLayer.push({
        event: 'load_audio'
      });
    }

    API.graphql(
      graphqlOperation(getUserInput, {
        id: userInput?.id
      })
    )
      .then(({ data: { getUserInput: userInput } }) => {
        if (userInput.componentType !== 'audio') return;

        if (
          typeof userInput.audioCompressionStatus === 'number' &&
          userInput.audioCompressionStatus < 100
        ) {
          setIsCompressing(prevIsCompressing => ({
            ...prevIsCompressing,
            [Mode.MIC]: true
          }));
        } else if (userInput.audioReady) {
          getAudioFile(`compressed_${userInput.id}.${userInput.audioType}`);
        }
      })
      .catch(error => {
        console.log('[Audio component getUserInput error]', error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, userInput]);

  const createWavesurfer = (mode, options = {}) => {
    let container = wsContainerRef.current[mode];

    if (!container || !(container instanceof HTMLElement)) return null;

    let activeRegion = null;
    let isDestroyed = false;

    options.uploadable = options.uploadable ?? true;

    const uploadAudioToS3 = async url => {
      if (!userInput?.id) return;

      setAudioCompressionProgress(prevAudioCompressionProgress => ({
        ...prevAudioCompressionProgress,
        [mode]: 0
      }));
      setAudioUploadProgress(prevAudioUploadProgress => ({
        ...prevAudioUploadProgress,
        [mode]: 0
      }));
      setIsUploading(prevIsUploading => ({
        ...prevIsUploading,
        [mode]: true
      }));
      try {
        const response = await fetch(url);
        const audioBlob = await response.blob();

        let audioType = audioBlob.type.split('/')[1];
        if (audioType === 'x-m4a') {
          audioType = 'm4a';
        }

        let key;
        if (mode === 'mic') {
          key = `raw/${userInput?.id}.${audioType}`;
        } else {
          key = `raw/${userInput?.id}.${audioType}`;
        }

        onDataChange?.(
          { audioMode: mode, audioType: audioType },
          userInput?.id
        );

        await Storage.put(key, audioBlob, {
          contentType: audioBlob.type,
          progressCallback: async progress => {
            const loaded = parseInt(progress.loaded) || 0;
            const total = parseInt(progress.total) || 1;
            const newProgress = Math.floor((loaded / total) * 100);
            setAudioUploadProgress(prevAudioUploadProgress => ({
              ...prevAudioUploadProgress,
              [mode]: newProgress
            }));
            onDataChange?.(
              { audioUploadingStatus: newProgress, updatedBy: user.username },
              userInput?.id
            );
          }
        });

        setIsUploading(prevIsUploading => ({
          ...prevIsUploading,
          [mode]: false
        }));
      } catch (error) {
        console.error('Error uploading audio to S3:', error);
        setIsUploading(prevIsUploading => ({
          ...prevIsUploading,
          [mode]: false
        }));
      }
    };

    const ws = WaveSurfer.create({
      container,
      cursorWidth: 0,
      waveColor: theme.palette.waveColor.main,
      progressColor: 'grey',
      backgroundColor: 'red',
      height: 200,
      interact: false,
      normalize: true,
      barWidth: 2,
      barGap: 2,
      barRadius: 2,
      autoCenter: false,
      scrollParent: true
    });

    if (mode === Mode.MIC) {
      const audioRecorder = ws.registerPlugin(
        AudioRecorder.create({
          audioBitsPerSecond: 32000
        })
      );
      audioRecorder.on('record-end', async () => {
        try {
          onDataChange?.(
            { audioRecording: false, updatedBy: user.username },
            userInput?.id
          );

          mixpanel.track('create_audio_recording');
          window.dataLayer.push({
            event: 'create_audio_recording'
          });
          ws.setOptions({ normalize: true });
          setIsRecording(false);
        } catch (error) {
          console.error('Error updating userInput in stopRecording:', error);
        }
      });

      audioRecorder.on('record-start', async () => {
        try {
          ws.setOptions({ normalize: false });
          setAudioMode(prevAudioMode => ({
            ...prevAudioMode,
            [mode]: AudioMode.RECORDER
          }));
          setIsRecording(true);
        } catch (error) {
          console.error('Error updating userInput in startRecording:', error);
        }
      });

      audioRecorderRef.current = audioRecorder;
    }

    const regionsPlugin = ws.registerPlugin(Regions.create());

    const setActiveRegion = activeRegion => {
      regionsPlugin.getRegions().forEach(region => {
        if (activeRegion.id === region.id) {
          region.setOptions({ color: adjustAlpha(region.color, 0.5) });
        } else {
          region.setOptions({ color: adjustAlpha(region.color, 0.25) }); // Or any other factor
        }
      });
    };

    regionsPlugin.on('region-created', async region => {
      const note = regionNotes[region.id] || '';
      const categories = regionCategories[region.id] || [];
      let annotation = {
        id: region.id,
        isHighlight: false,
        isActive: region.content?.innerHTML !== ' ' ? true : false,
        mode,
        note,
        region,
        categories
      };

      if (region.content?.innerHTML !== ' ') {
        setActiveRegion(region);
      }

      setAnnotations(prevAnnotations => {
        const updatedAnnotations = prevAnnotations.map(existingAnnotation => ({
          ...existingAnnotation,
          isActive: existingAnnotation.id === annotation.id
        }));
        const filteredAnnotations = updatedAnnotations.filter(
          existingAnnotation => existingAnnotation.id !== annotation.id
        );
        return [...filteredAnnotations, annotation];
      });

      if (region.content?.innerHTML !== ' ') {
        const input = {
          id: region.id,
          userInputID: userInput?.id,
          isActive: true,
          isHighlight: false,
          mode,
          note: '',
          regionTotalDuration: region.totalDuration,
          regionMinLength: region.minLength,
          regionMaxLength: region.maxLength,
          regionID: region.id,
          regionStart: region.start,
          regionEnd: region.end,
          regionResize: region.resize,
          regionColor: 'rgba(66, 165, 245, 0.50)',
          owner: `${clientSub}::${clientID}`,
          createdBy: user.username,
          updatedBy: user.username,
          clientID: user.username
        };

        await API.graphql(graphqlOperation(createAnnotation, { input }));

        mixpanel.track('create_annotation');
        window.dataLayer.push({
          event: 'create_annotation'
        });
      }
    });

    regionsPlugin.on('region-updated', async region => {
      setAnnotations(prevAnnotations =>
        prevAnnotations.map(annotation =>
          annotation.region.id === region.id
            ? { ...annotation, region }
            : annotation
        )
      );
      const annotationRes = await API.graphql(
        graphqlOperation(getAnnotation, { id: region.id })
      );
      const annotationVersion = annotationRes.data.getAnnotation._version;
      await API.graphql(
        graphqlOperation(updateAnnotation, {
          input: {
            id: region.id,
            isActive: true,
            isHighlight: false,
            mode,
            regionTotalDuration: region.totalDuration,
            regionMinLength: region.minLength,
            regionMaxLength: region.maxLength,
            regionID: region.id,
            regionStart: region.start,
            regionEnd: region.end,
            regionResize: region.resize,
            regionColor: region.color,
            updatedBy: user.username,
            _version: annotationVersion
          }
        })
      );
      mixpanel.track('update_annotation');
      window.dataLayer.push({
        event: 'update_annotation'
      });
    });
    regionsPlugin.on('region-clicked', (region, event) => {
      event.stopPropagation();
      region._setTotalDuration(ws.getDuration());
      activeRegion = region;
      setActiveRegion(region);
      ws.setTime(region.start);
      setAnnotations(prevAnnotations =>
        prevAnnotations.map(annotation =>
          annotation.region.id === region.id
            ? { ...annotation, region, isActive: true, isHighlight: true }
            : { ...annotation, isActive: false, isHighlight: false }
        )
      );
    });
    regionsPlugin.on('region-double-clicked', (region, event) => {
      event.stopPropagation();
      region._setTotalDuration(ws.getDuration());
      activeRegion = region;
      setActiveRegion(region);
      region.play();
      setAnnotations(prevAnnotations =>
        prevAnnotations.map(annotation =>
          annotation.region.id === region.id
            ? { ...annotation, region, isActive: true, isHighlight: true }
            : { ...annotation, isActive: false, isHighlight: false }
        )
      );
    });

    ws.on('decode', duration => {
      if (
        !isDestroyed &&
        userInput?.id &&
        !!ws.getMediaElement().src &&
        options.uploadable
      ) {
        onDataChange?.(
          { audioDurationSeconds: duration, updatedBy: user.username },
          userInput?.id
        );
      }
    });
    ws.on('destroy', () => {
      isDestroyed = true;
    });
    ws.on('interaction', () => {
      activeRegion = null;
      regionsPlugin.getRegions().forEach(region => {
        region.setOptions({ color: adjustAlpha(region.color, 0.25) });
      });
    });
    ws.on('timeupdate', currentTime => {
      if (activeRegion && ws.isPlaying() && currentTime >= activeRegion.end) {
        ws.pause();
        ws.setTime(activeRegion.start);
      }
      const containedCursorRegion = regionsPlugin
        .getRegions()
        .find(
          region => region.start <= currentTime && currentTime <= region.end
        );
      setAnnotations(prevAnnotations => {
        const activeAnnotation = prevAnnotations.find(
          annotation => annotation.region.id === containedCursorRegion?.id
        );
        return activeAnnotation?.isHighlight
          ? prevAnnotations
          : prevAnnotations.map(annotation =>
              annotation.region.id === containedCursorRegion?.id
                ? { ...annotation, isHighlight: true }
                : { ...annotation, isHighlight: false }
            );
      });
    });
    ws.on('load', url => {
      if (isDestroyed || !url) return;
      // regionsPlugin.clearRegions();
      const activePlugins = ws.getActivePlugins();
      if (!activePlugins.find(plugin => plugin instanceof MinimapPlugin)) {
        ws.registerPlugin(
          MinimapPlugin.create({
            height: 40,
            insertPosition: 'beforebegin',
            progressColor: '#999',
            waveColor: theme.palette.waveColor.main
          })
        );
      }
      if (!activePlugins.find(plugin => plugin instanceof TimelinePlugin)) {
        ws.registerPlugin(
          TimelinePlugin.create({
            height: 20,
            primaryLabelInterval: 5,
            secondaryLabelInterval: 1,
            style: {
              fontSize: '10px',
              paddingTop: '10px',
              paddingBottom: '10px'
            }
          })
        );
      }
      ws.setOptions({ cursorWidth: 2, interact: true });
      setAudioMode(prevAudioMode => ({
        ...prevAudioMode,
        [mode]: AudioMode.PLAYER
      }));
    });
    ws.on('ready', () => {
      const url = ws.getMediaElement().src;

      // If there are additional conditions or actions, like uploading audio to S3
      if (!isDestroyed && url && options.uploadable) {
        uploadAudioToS3(url);
      }

      if (!isDestroyed) {
        API.graphql(
          graphqlOperation(annotationsByUserInputID, {
            userInputID: userInput?.id
          })
        )
          .then(({ data }) => {
            const newRegionNotes = {};
            const newRegionCategories = {};
            const filteredItems = data.annotationsByUserInputID.items.filter(
              item => !item._deleted
            );
            filteredItems.forEach(apiAnnotation => {
              newRegionNotes[apiAnnotation.id] = apiAnnotation.note;
              newRegionCategories[apiAnnotation.id] = apiAnnotation.categories;
              regionNotes = newRegionNotes;
              regionCategories = newRegionCategories;
              regionsPluginRef.current[mode].addRegion({
                id: apiAnnotation.regionID,
                totalDuration: apiAnnotation.regionTotalDuration,
                minLength: apiAnnotation.regionMinLength,
                maxLength: apiAnnotation.regionMaxLength,
                start: apiAnnotation.regionStart,
                end: apiAnnotation.regionEnd,
                drag: apiAnnotation.regionDrag,
                resize: apiAnnotation.regionResize,
                color: apiAnnotation.regionColor,
                content: ' '
              });
            });
          })
          .catch(error => {
            console.log({ error });
          });
      }
    });
    ws.once('redraw', () => {
      if (isDestroyed) return;

      setIsWaveformDrawn(prevValue => ({
        ...prevValue,
        [mode]: true
      }));
    });

    regionsPluginRef.current[mode] = regionsPlugin;
    setWavesurfer(prevValue => ({ ...prevValue, [mode]: ws }));

    return ws;
  };

  const handlePlayPause = () => {
    const ws = wavesurfer[mode];
    if (!ws) return;

    ws.isPlaying() ? ws.pause() : ws.play();
  };

  const handleClearWaveform = () => {
    if (!isWaveformDrawn[mode]) return;
    wavesurferRef.current[mode]?.destroy();
    wavesurferRef.current[mode] = createWavesurfer(mode);
    setAnnotations(prevAnnotations =>
      prevAnnotations.some(annotation => annotation.mode === mode)
        ? prevAnnotations.filter(annotation => annotation.mode !== mode)
        : prevAnnotations
    );
    setAudioMode(prevAudioMode =>
      !prevAudioMode[mode] ? prevAudioMode : { ...prevAudioMode, [mode]: null }
    );
    setIsCompressing(prevIsUploading => ({
      ...prevIsUploading,
      [mode]: false
    }));
    setIsUploading(prevIsUploading => ({
      ...prevIsUploading,
      [mode]: false
    }));
    setIsWaveformDrawn(prevIsWaveformDrawn =>
      !prevIsWaveformDrawn[mode]
        ? prevIsWaveformDrawn
        : { ...prevIsWaveformDrawn, [mode]: false }
    );
  };

  const handleClickAnnotation = annotationId => {
    const clickedAnnotation = annotations.find(
      annotation => annotation.id === annotationId
    );
    clickedAnnotation?.region.element.click();
  };

  const handleDeleteAnnotation = async annotationId => {
    try {
      const deletedAnnotation = annotations.find(
        annotation => annotation.id === annotationId
      );
      if (deletedAnnotation) {
        deletedAnnotation.region.element.remove();
        setAnnotations(
          annotations.filter(annotation => annotation.id !== annotationId)
        );
        const { data } = await API.graphql(
          graphqlOperation(getAnnotation, { id: annotationId })
        );
        const annotationVersion = data.getAnnotation._version;
        await API.graphql(
          graphqlOperation(deleteAnnotation, {
            input: { id: annotationId, _version: annotationVersion }
          })
        );
      }
    } catch (e) {
      console.log('error in handleDeleteAnnotation: ', e);
    }
  };

  const handleSaveAnnotation = (annotationId, text) => {
    setAnnotations(prevAnnotations => {
      const currentAnnotation = prevAnnotations.find(
        annotation => annotation.id === annotationId
      );
      return !currentAnnotation || currentAnnotation.note === text
        ? prevAnnotations
        : prevAnnotations.map(annotation =>
            annotation.id === annotationId
              ? { ...annotation, note: text }
              : annotation
          );
    });
    debouncedApiUpdate(annotationId, text, user.username);
  };

  const debouncedApiUpdate = debounce(async (annotationId, text, username) => {
    const { data } = await API.graphql(
      graphqlOperation(getAnnotation, { id: annotationId })
    );
    const annotation = data.getAnnotation;

    await API.graphql(
      graphqlOperation(updateAnnotation, {
        input: {
          id: annotation.id,
          updatedBy: username,
          note: text,
          _version: annotation._version
        }
      })
    );
  }, 300);

  const handleSettingChange = (settingName, settingValue) => {
    setSettings(prevSettings =>
      _.set({ ...prevSettings }, settingName, settingValue)
    );
    if (settingName === 'upload' && !settingValue) setMode(Mode.MIC);
    onSettingChange?.(settingName, settingValue);
  };

  const handleStartRecording = () => {
    try {
      fetchPermissionStatus();
      audioRecorderRef.current?.startRecording();
    } catch (e) {
      console.log(e);
    }
  };

  const handleStopRecording = async () => {
    audioRecorderRef.current?.destroy();
  };

  const handleUploadAudioFile = file => {
    const reader = new FileReader();
    reader.onload = () => {
      wavesurfer[mode]?.load(reader.result);
    };
    mixpanel.track('upload_audio_file');
    window.dataLayer.push({
      event: 'upload_audio_file'
    });
    if (file) reader.readAsDataURL(file);
  };

  const handleZoomChange = event => {
    const ws = wavesurfer[mode];
    if (!ws) return;

    const minPxPerSec = event.target.value;
    ws.zoom(minPxPerSec);
  };

  const handleSetColor = async (id, color) => {
    regionsPluginRef.current[mode].getRegions().forEach(region => {
      if (id === region.id) {
        region.setOptions({ color: color });

        const updatedAnnotations = annotations.map(annotation => {
          if (annotation.id === id) {
            return {
              ...annotation,
              region: {
                ...annotation.region,
                color: color
              }
            };
          }
          return annotation;
        });
        setAnnotations(updatedAnnotations);
      }
    });
    const { data } = await API.graphql(
      graphqlOperation(getAnnotation, { id: id })
    );
    const annotationVersion = data.getAnnotation._version;
    await API.graphql(
      graphqlOperation(updateAnnotation, {
        input: {
          id: id,
          regionColor: color,
          updatedBy: user.username,
          _version: annotationVersion
        }
      })
    );
  };

  const handleSetCategory = async (id, selectedCategory) => {
    let updatedAnnotations;

    regionsPluginRef.current[mode].getRegions().forEach(region => {
      if (id === region.id) {
        updatedAnnotations = annotations.map(annotation => {
          if (annotation.id === id) {
            return {
              ...annotation,
              categories: Array.isArray(selectedCategory)
                ? selectedCategory
                : [selectedCategory]
            };
          }
          return annotation;
        });
        setAnnotations(updatedAnnotations);
      }
    });

    const currentAnnotation = updatedAnnotations.find(
      annotation => annotation.id === id
    );
    const updatedCategories = currentAnnotation
      ? currentAnnotation.categories
      : null;
    const { data } = await API.graphql(graphqlOperation(getAnnotation, { id }));
    const annotationVersion = data.getAnnotation._version;
    await API.graphql(
      graphqlOperation(updateAnnotation, {
        input: {
          id: id,
          categories: updatedCategories,
          updatedBy: user.username,
          _version: annotationVersion
        }
      })
    );
  };
  const openModal = () => {
    setOpenDialog(true);
  };
  const closeModal = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    const requestPermissions = () => {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then(() => {
          // Handle granted permissions here if needed
          setMicPermission('granted');
        })
        .catch(err => {
          // Handle denied permissions here if needed
          if (err.name === 'NotAllowedError') {
            setMicPermission('denied');
          } else {
            setMicPermission('unknown');
          }
        });
    };

    if (openDialog) {
      requestPermissions();
    }
  }, [openDialog]);

  useEffect(() => {
    if (micPermission === 'granted' && openDialog) {
      closeModal();
      handleStartRecording();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [micPermission, openDialog]);

  return (
    <Box>
      <Modal
        title="Allow access to your microphone"
        fullWidth
        maxWidth="md"
        open={openDialog}
        setClose={closeModal}
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <AudioPermissions />
        </Stack>
      </Modal>
      <Card
        elevation={0}
        sx={{
          backgroundColor: 'background.paper'
        }}
      >
        <Stack
          direction="row"
          sx={{
            alignItems: 'center',
            justifyContent: state.edit ? 'space-between' : 'center'
          }}
        >
          <Box width="100%" sx={{ display: 'flex', alignItems: 'center' }}>
            {state.edit && !component?.noEdit && (
              <IconButton
                size="small"
                onClick={() =>
                  setShowSettings(prevShowSettings => !prevShowSettings)
                }
              >
                <Settings
                  color={showSettings ? 'secondary' : 'primary'}
                  size="small"
                />
              </IconButton>
            )}
            <Stack
              alignItems="center"
              width="100%"
              direction="row"
              spacing={2}
              justifyContent="space-between"
            >
              <Typography variant="h5">
                {settings?.name ? settings?.name : 'Record or Upload Sample'}
              </Typography>

              {component?.showInfo !== false && (
                <IconButton
                  size="large"
                  sx={{ ml: 1 }}
                  onClick={() => setOpenInfo(prevOpenInfo => !prevOpenInfo)}
                  color={openInfo ? 'secondary' : 'grey'}
                >
                  <Info fontSize="large" />
                </IconButton>
              )}
            </Stack>
          </Box>
          {state.edit && !component?.noEdit && (
            <Stack direction="row" sx={{ alignItems: 'center' }}>
              <IconButton onClick={onDelete}>
                <Delete />
              </IconButton>
              <IconButton {...dragHandleProps}>
                <DragIndicator />
              </IconButton>
            </Stack>
          )}
        </Stack>

        <Collapse in={showSettings}>
          <Hidden smDown implementation="css">
            <AudioSettings
              onSettingChange={handleSettingChange}
              settings={settings}
              setShowSettings={setShowSettings}
            />
          </Hidden>
        </Collapse>
        <Collapse in={openInfo}>
          <InfoSection component={component} />
        </Collapse>
        <Stack
          direction="row"
          mb={1}
          sx={{ justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Box>
            {initialMode ? (
              <Stack spacing={1} direction="row">
                {initialMode === 'file' && (
                  <Upload color="grey" fontSize="small" />
                )}
                {initialMode === 'mic' && <Mic color="grey" fontSize="small" />}
                <Typography color="grey" variant="body2">
                  {initialMode === 'mic' ? 'RECORDED' : 'UPLOADED'}
                </Typography>
              </Stack>
            ) : (
              <>
                <Button
                  disabled={initialMode && initialMode !== 'mic'}
                  color={mode === 'mic' ? 'secondary' : 'lightGrey'}
                  startIcon={<Mic />}
                  onClick={() => setMode('mic')}
                  variant="text"
                  size="small"
                >
                  Mic
                </Button>
                <Hidden smDown implementation="css">
                  {!!settings?.upload && (
                    <Button
                      color={mode === 'file' ? 'secondary' : 'lightGrey'}
                      startIcon={<Upload />}
                      variant="text"
                      size="small"
                      onClick={() => setMode('file')}
                    >
                      Upload
                    </Button>
                  )}
                </Hidden>
              </>
            )}
          </Box>
          {audioMode[mode] === AudioMode.PLAYER && (
            <Box width={200}>
              <Stack
                height="100%"
                direction="row"
                alignItems="center"
                spacing={2}
              >
                <Iso fontSize="small" />
                <Box display="flex" alignItems="center" flex={1}>
                  {Object.keys(Mode).map(key => (
                    <Slider
                      key={`${key}-zoom`}
                      defaultValue={0}
                      onChange={handleZoomChange}
                      min={0}
                      max={1000}
                      step={1}
                      size="small"
                      sx={{
                        display: mode === Mode[key] ? 'block' : 'none'
                      }}
                    />
                  ))}
                </Box>
              </Stack>
            </Box>
          )}
          <Box sx={{ visibility: initialMode ? 'hidden' : 'none' }}>
            <IconButton
              disabled={!isWaveformDrawn[mode]}
              onClick={handleClearWaveform}
            >
              <DeleteForever />
            </IconButton>
          </Box>
        </Stack>
        <Card elevation={0} sx={{ p: 2, py: 3 }}>
          <Stack alignItems="center" spacing={2}>
            <Stack
              width="100%"
              direction="row"
              spacing={1}
              sx={{ alignItems: 'center' }}
            >
              {(audioMode[mode] === AudioMode.RECORDER || !audioMode[mode]) &&
                mode === Mode.MIC && (
                  <IconButton
                    onClick={
                      micPermission === 'granted'
                        ? isRecording
                          ? handleStopRecording
                          : handleStartRecording
                        : openModal
                    }
                  >
                    <Avatar
                      sx={{
                        color: isRecording ? '#FFF' : red[900],
                        border: '1px solid white',
                        background: red[900]
                      }}
                    >
                      {isRecording && <StopIcon />}
                    </Avatar>
                  </IconButton>
                )}

              {!audioMode[mode] && mode === Mode.FILE && (
                <AudioUploadButton onUpload={handleUploadAudioFile} />
              )}
              {audioMode[mode] === AudioMode.PLAYER && (
                <IconButton
                  ref={playPauseButtonRef}
                  color="primary"
                  onClick={handlePlayPause}
                >
                  <Avatar
                    sx={{
                      color: '#FFF',
                      border: '1px solid white',
                      background: !isPlaying ? teal[600] : lightBlue[600]
                    }}
                  >
                    {!isPlaying ? (
                      <PlayArrowRoundedIcon fontSize="large" />
                    ) : (
                      <PauseRoundedIcon fontSize="large" />
                    )}
                  </Avatar>
                </IconButton>
              )}
              {Object.keys(Mode).map(key => (
                <Fragment key={key}>
                  <Box
                    ref={el => (wsContainerRef.current[Mode[key]] = el)}
                    width="100%"
                    sx={{
                      display: mode === Mode[key] ? 'block' : 'none',
                      position: 'relative',
                      overflow: 'auto hidden',
                      background: theme.palette.background.card
                    }}
                  >
                    {loadingAudio && loadingAudio !== 100 ? (
                      <LinearProgress
                        variant="determinate"
                        value={loadingAudio}
                        sx={{
                          position: 'absolute',
                          top: '50%',
                          transform: 'translateY(-50%)',
                          width: '100%' // Ensure the progress bar spans the full width of the container
                        }}
                      />
                    ) : !isWaveformDrawn[Mode[key]] ? (
                      <Skeleton
                        animation={false}
                        height={2}
                        width={'100%'}
                        sx={{
                          position: 'absolute',
                          top: '50%',
                          transform: 'translateY(-50%)'
                        }}
                      />
                    ) : null}
                  </Box>
                </Fragment>
              ))}
            </Stack>
            <Box width="100%">
              {isUploading[mode] && (
                <ProgressIndicator
                  label="Uploading"
                  size={100}
                  thickness={2}
                  value={audioUploadProgress[mode]}
                />
              )}
              {isCompressing[mode] && (
                <ProgressIndicator
                  label="Processing"
                  size={100}
                  thickness={2}
                  value={audioCompressionProgress[mode]}
                />
              )}
            </Box>
          </Stack>
        </Card>
        {activeAnnotation && activeAnnotation.mode === mode && (
          <Annotation
            key={activeAnnotation.region.id}
            onDelete={handleDeleteAnnotation}
            onSave={handleSaveAnnotation}
            onSetColor={handleSetColor}
            note={activeAnnotation.note}
            region={activeAnnotation.region}
            setAnnotations={setAnnotations}
            onSetCategory={handleSetCategory}
            categories={activeAnnotation.categories}
            settings={settings}
          />
        )}
        <Annotations
          annotations={annotationsByMode[mode]}
          onClickAnnotation={handleClickAnnotation}
        />
      </Card>
    </Box>
  );
}

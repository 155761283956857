import { useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import {
  Close,
  Face2,
  Lock,
  RadioButtonChecked,
  RadioButtonUnchecked
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import { useSnackbar } from 'context/SnackBar';
import { updateClinician } from 'graphql/mutations';
import {
  clientsClinicConnection,
  updateUserAttributes,
  updateUserGroups
} from 'graphql/queries';

import { formatRoleString, getHighestPermissionLevel } from 'utils/userRoles';

import ProfileAvatar from '../ProfileAvatar';

export default function ClinicianCard({
  noAction,
  removeEdit,
  slim,
  dark,
  clinician,
  selected,
  setClinicians,
  selectClinician,
  selectable
}) {
  const [edit, setEdit] = useState(false);
  const [role, setRole] = useState(clinician.role || '');
  const [originalRole, setOriginalRole] = useState(clinician.role || '');
  const [saving, setSaving] = useState(false);
  const { user } = useAuthenticator(context => [context => context.user]);
  const [openConfirmLock, setOpenConfirmLock] = useState(false);
  const [remove, setRemove] = useState(false);
  const [originalSuspension, setOriginalSuspension] = useState();
  const [suspend, setSuspend] = useState(clinician.suspended ?? false);
  const [retain, setRetain] = useState(false);
  const highestPermission = getHighestPermissionLevel(user);
  const { enqueueSnackbar } = useSnackbar();
  const [clinicianVersion, setClinicianVersion] = useState(clinician?._version);

  const handleEditEnter = () => {
    setOriginalSuspension(clinician.suspended);
    setOriginalRole(role);
    setEdit(true);
  };

  const handleCancelEdit = () => {
    setOpenConfirmLock(false);
    setSuspend(false);
    setRemove(false);
    setRole(originalRole);
    setEdit(false);
  };

  function RoleChip({ label, disabled, onClick, selected, customMsg }) {
    return (
      <Tooltip
        placement="top"
        title={selected ? '' : customMsg ? customMsg : `Change to ${label}`}
      >
        <Chip
          onClick={disabled ? null : onClick}
          clickable={!disabled}
          size="small"
          label={label}
          color={!edit ? 'primary' : selected ? 'primary' : 'default'}
          variant={!edit ? 'primary' : selected ? 'filled' : 'outlined'}
        />
      </Tooltip>
    );
  }

  const handleToggleRole = role => {
    setRole(role);
  };

  const titleComponent = (
    <Typography
      lineHeight={1}
      sx={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }}
      variant="h6"
    >
      {clinician.firstName + ' ' + clinician.lastName}
    </Typography>
  );

  const handleConfirmEdit = async () => {
    try {
      setSaving(true);
      if (remove) {
        await handleRemove();
      } else {
        await handleUpdate();
      }
    } catch (err) {
      console.error(err);
    } finally {
      setSaving(false);
    }
  };
  const handleUpdate = async () => {
    try {
      const updateClinicianVariables = {
        id: clinician.id,
        role: originalSuspension ? role : suspend ? null : role,
        suspended: originalSuspension && role ? false : suspend,
        _version: clinicianVersion
      };
      const { data } = await API.graphql(
        graphqlOperation(updateClinician, { input: updateClinicianVariables })
      );
      setClinicianVersion(data.updateClinician._version);
      const updateUserGroupsVariables = {
        userID: clinician.id,
        role: role,
        suspend: originalSuspension && role ? false : suspend
      };
      await API.graphql(
        graphqlOperation(updateUserGroups, updateUserGroupsVariables)
      );

      enqueueSnackbar(
        suspend
          ? originalSuspension
            ? `Re-instated ${clinician.firstName} as ${formatRoleString(role)}`
            : `Suspended ${clinician.firstName}'s permissions`
          : `Update ${clinician.firstName}${
              role && `'s role to ${formatRoleString(role)}`
            }`,
        {
          severity: 'success'
        }
      );

      setClinicians(prevClinicians =>
        prevClinicians.map(c =>
          c.id === clinician.id
            ? {
                ...c,
                role: originalSuspension ? role : suspend ? null : role,
                suspended: originalSuspension && role ? false : suspend
              }
            : c
        )
      );
    } catch (e) {
      console.log(e);

      enqueueSnackbar(`Error updating ${clinician.firstName}`, {
        severity: 'error'
      });
    }
  };
  const handleRemove = async () => {
    try {
      const clientConnectionVariables = {
        clinicianID: clinician.id,
        action: retain
          ? 'remove_from_clinician'
          : 'remove_from_clinic_and_location'
      };
      const { data } = await API.graphql(
        graphqlOperation(clientsClinicConnection, clientConnectionVariables)
      );
      if (data.clientsClinicConnection.statusCode !== 200) {
        throw new Error(data.clientsClinicConnection.body);
      }
      const clinicianVariables = {
        id: clinician.id,
        clinicID: null,
        clinicCliniciansId: null,
        locationCliniciansId: null,
        _version: clinician._version
      };
      const res = await API.graphql(
        graphqlOperation(updateClinician, { input: clinicianVariables })
      );
      setClinicianVersion(res.data.updateClinician._version);

      await API.graphql(
        graphqlOperation(updateUserAttributes, {
          userID: clinician.id,
          attributeKey: 'custom:locationID',
          attributeValue: ''
        })
      );
      await API.graphql(
        graphqlOperation(updateUserAttributes, {
          userID: clinician.id,
          attributeKey: 'custom:clinicID',
          attributeValue: ''
        })
      );
      setClinicians(prevClinicians =>
        prevClinicians.filter(c => c.id !== clinician.id)
      );
      enqueueSnackbar(
        `Removed ${clinician.firstName}${
          retain ? ' and retained their clients' : ''
        }`,
        {
          severity: 'success'
        }
      );
    } catch (e) {
      console.log(e);
      enqueueSnackbar(`Error removing ${clinician.firstName}`, {
        severity: 'error'
      });
    }
  };

  const handleEditToggle = async () => {
    if (edit) {
      handleConfirmEdit();
    }
    setEdit(!edit);
  };

  const handleSelectClinician = () => {
    selectClinician(clinician);
  };

  const Action = () => (
    <IconButton color="primary" onClick={handleSelectClinician}>
      {selected ? <RadioButtonChecked /> : <RadioButtonUnchecked />}
    </IconButton>
  );

  const Cancel = () => (
    <Tooltip placement="top" title="Cancel">
      <IconButton onClick={handleCancelEdit}>
        <Close />
      </IconButton>
    </Tooltip>
  );

  return (
    <Card
      elevation={dark ? 0 : 4}
      sx={{
        backgroundColor: dark
          ? selected
            ? 'background.default'
            : 'background.card'
          : 'background.paper'
      }}
    >
      <CardHeader
        avatar={<ProfileAvatar profile={clinician} />}
        title={titleComponent}
        subheader={clinician.email}
        subheaderTypographyProps={{ sx: { py: 0 } }}
        action={
          noAction ? null : edit ? <Cancel /> : selectable ? <Action /> : null
        }
      />

      <Collapse in={edit}>
        <CardContent>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display={'flex'}>
              <Collapse orientation="horizontal" in={!openConfirmLock}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <RoleChip
                    label="Admin"
                    selected={role === 'admin'}
                    onClick={() => handleToggleRole('admin')}
                  />
                  <RoleChip
                    customMsg={
                      clinician.locationID
                        ? null
                        : `You must add this user to a location before making them a Practice Manager. Click the 'Locations' filter at the top, select the user, and add them to a location.`
                    }
                    disabled={!clinician.locationID}
                    label="Practice Manager"
                    selected={role === 'practicemanager'}
                    onClick={() => handleToggleRole('practicemanager')}
                  />
                  <RoleChip
                    label="Clinician"
                    selected={role === 'clinician'}
                    onClick={() => handleToggleRole('clinician')}
                  />
                </Stack>
              </Collapse>
              <Collapse orientation="vertical" in={openConfirmLock}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Tooltip
                    placement="top"
                    title="Removes account from the clinic."
                  >
                    <Button
                      sx={{ height: 24 }}
                      onClick={() => {
                        setRemove(prevRemove => !prevRemove);
                        if (suspend) {
                          setSuspend(false);
                        }
                      }}
                      variant={remove ? 'contained' : 'text'}
                      size="small"
                      color="error"
                    >
                      Remove
                    </Button>
                  </Tooltip>

                  {remove && (
                    <Tooltip
                      placement="top"
                      title="When removing this user, unassign their clients and keep them with the clinic."
                    >
                      <Button
                        onClick={() => setRetain(prevRetain => !prevRetain)}
                        color="tertiary"
                        size="small"
                        variant={retain ? 'contained' : 'text'}
                        sx={{
                          color: retain ? 'white' : 'tertiary.dark',
                          height: 24,
                          display: 'flex',
                          alignItems: 'center',
                          whiteSpace: 'nowrap'
                        }}
                      >
                        Retain clients
                      </Button>
                    </Tooltip>
                  )}
                  {!remove && (
                    <Tooltip
                      placement="top"
                      title="Temporarily suspends permissions."
                    >
                      <Button
                        sx={{ height: 24 }}
                        onClick={() => {
                          setSuspend(prevLock => !prevLock);
                          if (remove) {
                            setRemove(false);
                          }
                        }}
                        variant={suspend ? 'contained' : 'text'}
                        color="warning"
                        size="small"
                      >
                        Suspend
                      </Button>
                    </Tooltip>
                  )}
                </Stack>
              </Collapse>
            </Box>
          </Stack>
        </CardContent>
      </Collapse>
      <Collapse in={!edit}>
        {clinician?.suspended || suspend ? (
          <Tooltip placement="top" title="Can not perform any action.">
            <Alert severity="warning">
              <AlertTitle>Suspended</AlertTitle>
            </Alert>
          </Tooltip>
        ) : (
          <List dense>
            <ListItem sx={{ py: 0.25 }}>
              <ListItemIcon sx={{ paddingLeft: 1 }}>
                {clinician.id === user.username && (
                  <Tooltip placement="top" title={'Me'}>
                    <span>
                      <IconButton sx={{ p: 0 }} disabled>
                        <Face2 color="primary" />
                      </IconButton>
                    </span>
                  </Tooltip>
                )}
              </ListItemIcon>
              <ListItemText
                primary={
                  <RoleChip disabled label={formatRoleString(role)} selected />
                }
              />
            </ListItem>
          </List>
        )}
      </Collapse>

      {!slim && (
        <CardActions
          sx={{
            p: 0,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Collapse in={edit}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Tooltip
                placement="top"
                title={openConfirmLock ? '' : 'Connection settings'}
              >
                <IconButton
                  color={openConfirmLock ? 'secondary' : 'default'}
                  onClick={() =>
                    setOpenConfirmLock(
                      prevOpenConfirmLock => !prevOpenConfirmLock
                    )
                  }
                >
                  <Lock />
                </IconButton>
              </Tooltip>
              <Collapse
                sx={{ display: 'flex', alignItems: 'center' }}
                orientation="horizontal"
                in={edit && openConfirmLock && (remove || suspend)}
              >
                <Box pl={2}>
                  <Typography sx={{ whiteSpace: 'nowrap' }} variant="body2">
                    {remove && 'Remove from the clinic?'}
                    {suspend && 'Suspend all permissions?'}
                  </Typography>
                </Box>
              </Collapse>
            </Box>
          </Collapse>
          <Collapse in={!removeEdit && highestPermission === 'admin'}>
            <Box>
              <Box display="flex" alignItems="center" height={40}>
                <LoadingButton
                  loading={saving}
                  onClick={edit ? handleEditToggle : handleEditEnter}
                  size="small"
                  color={
                    edit
                      ? remove
                        ? 'error'
                        : originalSuspension
                        ? 'secondary'
                        : suspend
                        ? 'warning'
                        : 'secondary'
                      : 'primary'
                  }
                  variant="text"
                >
                  {edit
                    ? remove
                      ? 'Remove Now'
                      : originalSuspension
                      ? 'Save'
                      : suspend
                      ? 'Lock Now'
                      : 'Save'
                    : 'Edit'}
                </LoadingButton>
              </Box>
            </Box>
          </Collapse>
        </CardActions>
      )}
    </Card>
  );
}

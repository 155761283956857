import React from 'react';

import { Box } from '@mui/material';

import { ClinicianDrawer } from 'components/molecules';

export default React.memo(function MiniDrawer({
  children,
  AppBarComponents,
  open,
  handleDrawer
}) {
  return (
    <Box display="flex">
      <ClinicianDrawer
        AppBarComponents={AppBarComponents}
        handleDrawer={handleDrawer}
        open={open}
      />

      {/* <Box component="main" sx={{ flexGrow: 1 }}> */}
      {children}
      {/* </Box> */}
    </Box>
  );
});

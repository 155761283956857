import TimelinePlugin from 'wavesurfer.js/dist/plugins/timeline';

export default class Timeline extends TimelinePlugin {
  static create(options) {
    return new Timeline(options);
  }

  defaultTimeInterval(pxPerSec) {
    if (pxPerSec >= 25) {
      return 1;
    } else if (pxPerSec * 5 >= 25) {
      return 5;
    } else if (pxPerSec * 15 >= 25) {
      return 15;
    }
    return Math.ceil(1 / pxPerSec) * 60;
  }
}

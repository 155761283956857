import { useState } from 'react';

import {
  Close,
  Delete,
  DragIndicator,
  Info,
  Settings
} from '@mui/icons-material';
import {
  Box,
  Card,
  Collapse,
  Hidden,
  IconButton,
  Stack,
  Typography
} from '@mui/material';
import TextField from '@mui/material/TextField';
import { useAppContext } from 'context';

import InfoSection from '../InfoSection';

export default function FullWidthTextField({
  userInputID,
  dragHandleProps = {},
  initialData = '',
  label,
  component,
  name,
  rows,
  onDataChange,
  onDelete,
  onSettingChange
}) {
  const { state } = useAppContext();

  const [data, setData] = useState(initialData || '');
  const [settings, setSettings] = useState({
    label,
    name,
    rows
  });
  const [showSettings, setShowSettings] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);

  const handleDataChange = event => {
    setData(event.target.value || '');
    onDataChange?.(event.target.value, userInputID);
  };

  const handleUpdateSetting = (settingName, settingValue) => {
    setSettings(prevSettings => ({
      ...prevSettings,
      [settingName]: settingValue
    }));
    onSettingChange?.(settingName, settingValue);
  };

  return (
    <Box className="test">
      <Stack direction="row" sx={{ mb: 1, justifyContent: 'space-between' }}>
        <Stack direction="row" alignItems="center" spacing={1}>
          {state.edit && !component?.noEdit && (
            <IconButton onClick={() => setShowSettings(!showSettings)}>
              <Settings
                size="small"
                color={showSettings ? 'secondary' : 'primary'}
              />
            </IconButton>
          )}
          <Stack
            alignItems="center"
            width="100%"
            direction="row"
            spacing={2}
            justifyContent="space-between"
          >
            <Typography variant="h5"> {settings.name}</Typography>
            {component?.showInfo !== false && (
              <IconButton
                size="large"
                sx={{ ml: 1 }}
                onClick={() => setOpenInfo(prevOpenInfo => !prevOpenInfo)}
                color={openInfo ? 'secondary' : 'grey'}
              >
                <Info fontSize="large" />
              </IconButton>
            )}
          </Stack>
        </Stack>
        {state.edit && !component?.noEdit && (
          <Stack direction="row" sx={{ alignItems: 'center' }}>
            <IconButton onClick={onDelete}>
              <Delete />
            </IconButton>
            <IconButton {...dragHandleProps}>
              <DragIndicator />
            </IconButton>
          </Stack>
        )}
      </Stack>

      <Collapse in={showSettings}>
        <Hidden smDown implementation="css">
          <Box mb={2}>
            <InputSettings
              settings={settings}
              updateSetting={handleUpdateSetting}
              showSettings={showSettings}
              setShowSettings={setShowSettings}
            />
          </Box>
        </Hidden>
      </Collapse>

      <Collapse in={openInfo}>
        <InfoSection component={component} />
      </Collapse>

      <TextField
        variant="filled"
        color="primary"
        value={data || ''}
        fullWidth
        onChange={handleDataChange}
        multiline
        minRows={settings.multiline}
        label={settings.label}
        id="fullWidth"
      />
    </Box>
  );
}

const InputSettings = ({
  settings,
  updateSetting,
  showSettings,
  setShowSettings
}) => {
  return (
    <Card
      elevation={0}
      sx={{ my: 1, px: 1, backgroundColor: 'background.settings' }}
    >
      <Stack
        direction="row"
        spacing={1}
        px={1}
        py={1}
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        <IconButton size="small" onClick={() => setShowSettings(!showSettings)}>
          <Close color="default" fontSize="small" />
        </IconButton>
        <Typography variant="caption">Input Settings</Typography>
      </Stack>
      <Stack direction="row" spacing={1}>
        <TextField
          sx={{ width: '180px' }}
          variant="filled"
          size="small"
          label="Name"
          color="tertiary"
          value={settings.name}
          onChange={event => updateSetting('name', event.target.value)}
        />
        <TextField
          sx={{ width: '180px' }}
          variant="filled"
          size="small"
          label="Label"
          color="tertiary"
          value={settings.label}
          onChange={event => updateSetting('label', event.target.value)}
        />
        <TextField
          color="tertiary"
          variant="filled"
          size="small"
          type="number"
          label="Rows"
          sx={{ width: '80px' }}
          value={settings.rows}
          onChange={event => updateSetting('rows', event.target.value)}
        />
      </Stack>
    </Card>
  );
};

import { useState } from 'react';

import {
  Assessment,
  BarChart,
  BubbleChart,
  Close,
  PieChart,
  Timeline
} from '@mui/icons-material';
import {
  Box,
  Card,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  TextField,
  Typography
} from '@mui/material';

import { ChipTable } from 'components/molecules';

const IconList = ({ icons, type, handleClick }) => {
  return icons.map(({ key, label, icon: IconComponent }) => (
    <Box key={key} display="flex" alignItems="center" mr={2}>
      <IconButton
        sx={{ mr: 1 }}
        onClick={() => handleClick(key)}
        color={type === key ? 'tertiary' : 'default'}
      >
        <IconComponent />
      </IconButton>
      <Typography variant="body1">{label}</Typography>
    </Box>
  ));
};

const RadioGroupComponent = ({ options, value, handleChange }) => {
  return (
    <FormControl component="fieldset">
      <RadioGroup
        row
        aria-label="option"
        name="option"
        value={value}
        onChange={handleChange}
      >
        {options.map(option => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio color="tertiary" />}
            label={option.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default function ChartSettings({
  setType,
  type,
  showSettings,
  setShowSettings,
  aggregateBy,
  hasZoom,
  connectNulls,
  scatter,
  onScatterChange,
  name,
  setName,
  showLabels,
  onShowNotesChange,
  showNotes,
  initialDataType = 'all',
  onAggregateChange,
  onDataRecordsChange,
  onDataTypeChange,
  onZoomChange,
  handleConnectNullsChange,
  onShowLabelsChange,
  dataRecords,
  dataType
}) {
  const icons = [
    { key: 'bar', label: 'Bar Chart', icon: BarChart },
    { key: 'line', label: 'Line Chart', icon: Timeline },
    { key: 'area', label: 'Area Chart', icon: Assessment },
    { key: 'pie', label: 'Pie Chart', icon: PieChart },
    { key: 'bubble', label: 'Linear Bubble Chart', icon: BubbleChart }
  ];

  const [value, setValue] = useState(initialDataType);

  const handleChange = event => {
    setValue(event.target.value);
    onDataTypeChange?.(event.target.value);
  };
  const handleClick = newType => {
    setType(newType);
  };

  const radioGroupOptions =
    dataType === 'all'
      ? [{ value: 'count', label: 'Count' }]
      : [
          { value: 'count', label: 'Count' },
          { value: 'average', label: 'Average' }
        ];

  return (
    <Card
      sx={{ p: 2, my: 2, backgroundColor: 'background.settings' }}
      elevation={0}
    >
      <Stack
        direction="row"
        spacing={1}
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        <IconButton
          size="small"
          onClick={() => setShowSettings(showSettings => !showSettings)}
        >
          <Close fontSize="small" />
        </IconButton>
        <Typography variant="caption">Chart Settings</Typography>
      </Stack>
      <Stack my={2} direction="row">
        <TextField
          variant="filled"
          size="small"
          color="tertiary"
          label="Chart Name"
          value={name}
          onChange={evt => setName(evt.target.value)}
        />
      </Stack>
      <Grid container>
        <Grid my={2} item xs={12}>
          <InputLabel>Type</InputLabel>
          <Stack sx={{ flexWrap: 'wrap' }} direction="row">
            <IconList icons={icons} type={type} handleClick={handleClick} />
          </Stack>
        </Grid>
        <Grid my={2} item xs={12}>
          <Stack direction="row" spacing={2}>
            <Box>
              <InputLabel>Data</InputLabel>
              <Stack direction="row" spacing={2}>
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    aria-label="option"
                    name="option"
                    value={dataType}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="all"
                      control={<Radio color="tertiary" />}
                      label="All records"
                    />
                    <FormControlLabel
                      disabled={type === 'pie'}
                      value="some"
                      control={<Radio color="tertiary" />}
                      label="Specific records"
                    />
                  </RadioGroup>
                </FormControl>
              </Stack>
            </Box>
            <Box>
              <FormLabel>Aggregate by</FormLabel>
              <Stack direction="row" spacing={1}>
                <RadioGroupComponent
                  options={radioGroupOptions}
                  value={aggregateBy}
                  handleChange={onAggregateChange}
                />
              </Stack>
            </Box>
          </Stack>
        </Grid>
        {dataType === 'some' && (
          <Box sx={{ overflow: 'overlay' }}>
            <ChipTable
              type={type}
              value={value}
              dataType={dataType}
              aggregateBy={aggregateBy}
              dataRecords={dataRecords}
              onRowsChange={onDataRecordsChange}
            />
          </Box>
        )}

        {type !== 'pie' && (
          <Grid my={2} item xs={12}>
            <InputLabel>Options</InputLabel>
            <Stack direction="row" spacing={2}>
              {type !== 'radar' && type !== 'pie' && type !== 'bubble' && (
                <>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={hasZoom}
                        onChange={onZoomChange}
                        name="hasZoom"
                        color="tertiary"
                      />
                    }
                    label="Zoom"
                  />
                  {type !== 'bar' && (
                    <FormControlLabel
                      control={
                        <Switch
                          checked={connectNulls}
                          onChange={handleConnectNullsChange}
                          name="connectNulls"
                          color="tertiary"
                        />
                      }
                      label="Connect Nulls"
                    />
                  )}
                  {aggregateBy === 'average' && type === 'line' && (
                    <>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={scatter}
                            onChange={onScatterChange}
                            name="scatter"
                            color="tertiary"
                          />
                        }
                        label="Scatter Highs / Lows"
                      />
                      <FormControlLabel
                        control={
                          <Switch
                            checked={showNotes}
                            onChange={onShowNotesChange}
                            name="showNotes"
                            color="tertiary"
                          />
                        }
                        label="Show Notes"
                      />
                    </>
                  )}
                </>
              )}
              {type === 'bubble' && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={showLabels}
                      onChange={onShowLabelsChange}
                      name="labels"
                      color="tertiary"
                    />
                  }
                  label="Show Labels"
                />
              )}
            </Stack>
          </Grid>
        )}
      </Grid>
    </Card>
  );
}

import { useCallback, useEffect, useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { Box, Container, Grid, TextField, Typography } from '@mui/material';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { API, graphqlOperation } from 'aws-amplify';
import { useAppContext } from 'context';
import { updateSession as updateSessionMutation } from 'graphql/mutations';
import { getSession } from 'graphql/queries';
import { debounce } from 'lodash';
import { DateTime } from 'luxon';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useParams } from 'react-router-dom';

import { BackButton, LoadingAtom } from 'components/atoms';

export default function Session() {
  const { id } = useParams();
  const { user } = useAuthenticator(context => [context.user]);
  const { state } = useAppContext();
  const clientID =
    state.client?.id || user.attributes['custom:clientID'] || user.username;
  const now = new Date();
  const [loading, setLoading] = useState(true);
  const [session, setSession] = useState();
  const [notes, setNotes] = useState('');
  const [sessionDate, setSessionDate] = useState(now);
  const [savingSession, setSavingSession] = useState(false);
  const [lastSavedTimestamp, setLastSavedTimestamp] = useState(new Date());
  const [displayedLastSaved, setDisplayedLastSaved] = useState('');
  const [sessionVersion, setSessionVersion] = useState(1);
  const [hasSaved, setHasSaved] = useState(false);

  const debouncedUpdateSession = debounce(async sessionData => {
    try {
      setSavingSession(true);
      const { data } = await API.graphql({
        query: updateSessionMutation,
        variables: { input: { ...sessionData, _version: sessionVersion } }
      });
      setSessionVersion(data.updateSession._version);
      setLastSavedTimestamp(new Date());
      setHasSaved(true);
    } catch (err) {
      console.log('err', err);
    } finally {
      setSavingSession(false);
    }
  }, 500);

  const getTimeSinceLastSaved = useCallback(() => {
    const now = DateTime.now();
    const lastSaved = DateTime.fromJSDate(lastSavedTimestamp);
    const diff = now.diff(lastSaved, ['hours', 'minutes', 'seconds']);

    if (diff.hours < 1) {
      return `Last saved ${Math.floor(diff.minutes)} minutes, ${Math.floor(
        diff.seconds
      )} seconds ago`;
    } else {
      return `Last saved on ${lastSaved.toLocaleString(
        DateTime.DATETIME_MED_WITH_SECONDS
      )}`;
    }
  }, [lastSavedTimestamp]);

  useEffect(() => {
    setDisplayedLastSaved(getTimeSinceLastSaved());
    const sessionData = {
      id: id,
      date: sessionDate,
      notes: JSON.stringify(notes.ops)
    };
    if ((notes || sessionDate) && !hasSaved) {
      debouncedUpdateSession(sessionData);
    }
    return () => {
      debouncedUpdateSession.cancel();
    };
  }, [
    sessionDate,
    notes,
    debouncedUpdateSession,
    id,
    getTimeSinceLastSaved,
    hasSaved
  ]);

  useEffect(() => {
    const getSessionDetails = async () => {
      setLoading(true);
      const apiData = await API.graphql(
        graphqlOperation(getSession, {
          id: id
        })
      );

      const sessionsFromApi = apiData.data.getSession;
      setSessionVersion(sessionsFromApi?._version);
      setSession(sessionsFromApi);
      setSessionDate(DateTime.fromISO(apiData.data.getSession?.date));
      setNotes({ ops: JSON.parse(sessionsFromApi?.notes) });
      setLoading(false);
    };

    if (clientID) {
      getSessionDetails();
    }
    return () => {
      setSession();
      setLoading(true);
    };
  }, [clientID, id]);

  useEffect(() => {
    const interval = setInterval(() => {
      setDisplayedLastSaved(getTimeSinceLastSaved());
    }, 1000);
    return () => clearInterval(interval);
  }, [lastSavedTimestamp, getTimeSinceLastSaved]);

  function LoadingEllipsis() {
    const [dots, setDots] = useState('');

    useEffect(() => {
      const interval = setInterval(() => {
        setDots(prevDots => (prevDots.length < 3 ? prevDots + '.' : ''));
      }, 200);

      return () => clearInterval(interval);
    }, []);

    return <Typography variant="body2">Saving {dots} </Typography>;
  }

  const editorStyles = {
    height: '48vh'
  };

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' }
      ],
      ['clean']
    ]
  };

  function handleChange(content, delta, source, editor) {
    const currentContents = editor.getContents();
    setNotes(currentContents);
    setHasSaved(false);
  }

  return (
    <>
      {loading || !session ? (
        <LoadingAtom />
      ) : (
        <Container maxWidth="lg" sx={{ mb: 10, px: { xs: 0, md: 2 } }}>
          <Grid container>
            <Grid item xs={12} sm={12}>
              <Box mt={4}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  sx={{ mt: 4, mb: 2 }}
                >
                  <BackButton />
                </Box>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12} md={6}>
                        <Box mb={4} p={1}>
                          {session && sessionDate && (
                            <Typography align="left" variant="h4">
                              {sessionDate
                                ? DateTime.fromISO(sessionDate).weekdayLong
                                : DateTime.fromISO(session?.date).weekdayLong}
                              , {DateTime.fromISO(session?.date).day}{' '}
                              {DateTime.fromISO(session?.date).monthLong}{' '}
                              {DateTime.fromISO(session?.date).year}
                            </Typography>
                          )}
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Box
                          p={1}
                          sx={{
                            display: 'flex',
                            justifyContent: { xs: 'flex-start', md: 'flex-end' }
                          }}
                        >
                          {sessionDate && (
                            <LocalizationProvider
                              dateAdapter={AdapterLuxon}
                              adapterLocale={'au'}
                            >
                              <DatePicker
                                label="Date"
                                value={sessionDate}
                                onChange={newValue => {
                                  setSessionDate(newValue);
                                }}
                                renderInput={params => (
                                  <TextField {...params} />
                                )}
                              />
                            </LocalizationProvider>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                    <Box minHeight="56vh">
                      <ReactQuill
                        style={editorStyles}
                        theme="snow"
                        value={notes}
                        onChange={handleChange}
                        modules={modules}
                      />
                    </Box>
                    <Box ml={1}>
                      {savingSession ? (
                        <LoadingEllipsis />
                      ) : (
                        <Typography variant="body2">
                          {displayedLastSaved}
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  );
}

export function formatProgram(location, shorthand) {
  let formattedName = '';

  // Capitalize the first letter of each word and join back into a string
  formattedName = location
    .split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' - ');

  if (formattedName.toLowerCase().includes('lidcombe')) {
    return 'Lidcombe';
  }

  if (formattedName.toLowerCase().includes('westmead')) {
    return 'Westmead';
  }

  if (shorthand) {
    if (formattedName.toLowerCase().includes('monitoring')) {
      return 'Monitoring';
    }
    if (formattedName === 'CamperdownAdult') {
      return 'Camperdown - A';
    }
    if (formattedName === 'CamperdownAdolescent') {
      return 'Camperdown - Y';
    }
  } else {
    if (formattedName.toLowerCase().includes('monitoringchild')) {
      return 'Monitoring - Child';
    }
    if (formattedName.toLowerCase().includes('monitoringadult')) {
      return 'Monitoring - Adult';
    }
    if (formattedName === 'CamperdownAdolescent') {
      return 'Camperdown - Adolescent';
    }
    if (formattedName === 'CamperdownAdult') {
      return 'Camperdown - Adult';
    }
  }

  return formattedName;
}

import {
  Box,
  FormLabel,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';

import { IconChip } from 'components/atoms';

import { formatDate, isDate } from 'utils/dateMatcher';

export default function StepThree({
  hasHeader,
  parsedData,
  selectedEntry,
  selectedComponents
}) {
  return (
    <Box>
      <Stack spacing={2} direction="row" alignItems="center" my={4}>
        <FormLabel sx={{ textTransform: 'uppercase' }}>
          Importing {hasHeader ? parsedData.length - 1 : parsedData.length}{' '}
          Records To{' '}
        </FormLabel>
        <IconChip entry={selectedEntry} />
      </Stack>

      <Box mt={3}>
        <Table sx={{ width: '100%' }}>
          <TableHead>
            <TableRow>
              {parsedData[0] &&
                Object.keys(parsedData[0])
                  .filter(key => selectedComponents[key]) // Filter out keys that are not in selectedComponents
                  .map((key, idx) => (
                    <TableCell key={idx}>
                      {selectedComponents[key] && selectedComponents[key].name}
                    </TableCell>
                  ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {parsedData?.length > 0 &&
              parsedData?.slice(0, 5).map((record, rowIndex) => (
                <TableRow key={rowIndex}>
                  {Object.keys(parsedData[0])
                    .filter(key => selectedComponents[key])
                    .map((key, idx) => (
                      <TableCell key={idx}>
                        <span
                          style={{
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden'
                          }}
                        >
                          {isDate(record[key])
                            ? formatDate(record[key])
                            : record[key]
                            ? String(record[key])
                            : '    '}
                        </span>
                      </TableCell>
                    ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
}

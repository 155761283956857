import React, { useCallback, useEffect, useState } from 'react';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  IconButton,
  InputAdornment,
  TextField
} from '@mui/material';
import * as yup from 'yup';

import { AuthCode } from 'components/atoms';

const passwordSchema = yup.object().shape({
  password: yup
    .string()
    .required('Password is required')
    .min(6, 'Password should be at least 6 characters')
    // eslint-disable-next-line no-useless-escape
    .matches(/\d/, 'Password should have at least one number')
    .matches(
      // eslint-disable-next-line no-useless-escape
      /[A-Z]|[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/,
      'Password should have at least one uppercase letter or special character'
    )
});

const codeSchema = yup
  .number()
  .typeError('Confirmation code must be a number')
  .required('Confirmation code is required')
  .test('len', 'Must be exactly 6 digits', val => val?.toString().length === 6);

function ResetPassword({
  confirmationCode,
  setConfirmationCode,
  newPassword,
  setNewPassword,
  resettingPassword,
  handleCompletePasswordReset
}) {
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [showErrors, setShowErrors] = useState(false);

  const validateField = useCallback(async () => {
    let newErrors = {};

    try {
      await codeSchema.validate(confirmationCode, { abortEarly: false });
    } catch (err) {
      newErrors.code = err.message;
    }

    try {
      await passwordSchema.validate(
        { password: newPassword },
        { abortEarly: false }
      );
    } catch (err) {
      err.inner.forEach(error => {
        newErrors[error.path] = error.message;
      });
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [confirmationCode, newPassword]);

  const handleOnSubmit = useCallback(async () => {
    const isValid = await validateField();
    if (isValid) {
      handleCompletePasswordReset();
    } else {
      setShowErrors(true);
    }
  }, [handleCompletePasswordReset, validateField]);

  useEffect(() => {
    const handleKeyPress = e => {
      if (e.key === 'Enter') {
        handleOnSubmit();
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleOnSubmit]);

  return (
    <Box width="100%">
      <Alert severity="info">
        We sent a code to your email address. Enter it here.
      </Alert>
      <Box width="100%" my={1}>
        <AuthCode
          error={showErrors && !!errors.code}
          helperText={showErrors && errors.code}
          length={6}
          onComplete={confirmationCode => setConfirmationCode(confirmationCode)}
        />
      </Box>
      <Box display="flex" width="100%" my={1}>
        <TextField
          size="small"
          fullWidth
          label="New Password"
          type={showPassword ? 'text' : 'password'}
          value={newPassword}
          onChange={e => {
            setNewPassword(e.target.value);
            if (showErrors) setShowErrors(false);
          }}
          error={showErrors && !!errors.password}
          helperText={showErrors && errors.password}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </Box>
      <Box display="flex" width="100%" my={2}>
        <LoadingButton
          fullWidth
          size="small"
          variant="contained"
          onClick={handleOnSubmit}
          loading={resettingPassword}
        >
          Reset Password
        </LoadingButton>
      </Box>
    </Box>
  );
}

export default ResetPassword;

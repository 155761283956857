import React from 'react';

import { Avatar, Box, CircularProgress } from '@mui/material';

function CircularProgressWithAvatar({ value, children }) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        variant="determinate"
        value={value}
        size={42}
        thickness={4}
      />
      <Avatar
        sx={{
          width: 38,
          height: 38,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          color: 'text.primary',
          backgroundColor: 'background.paper'
        }}
      >
        {children}
      </Avatar>
    </Box>
  );
}

export default function DisplayClock({ input }) {
  const calculateProgress = () => {
    const minutes = input?.clockValue || 0;
    return (minutes / 60) * 100;
  };

  return (
    <Box display="flex" alignItems="center">
      <Box sx={{ ml: 1, display: 'flex', alignItems: 'center' }}>
        <CircularProgressWithAvatar value={calculateProgress()}>
          {input.clockValue}
        </CircularProgressWithAvatar>
      </Box>
    </Box>
  );
}

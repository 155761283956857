import { useEffect, useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import {
  DndContext,
  MouseSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import {
  restrictToHorizontalAxis,
  restrictToVerticalAxis,
  restrictToWindowEdges
} from '@dnd-kit/modifiers';
import {
  SortableContext,
  arrayMove,
  horizontalListSortingStrategy,
  useSortable,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Box } from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import { ORDER_NORMALIZE_FACTOR } from 'constants';
import { useAppContext } from 'context';
import {
  createInsight,
  updateInsight,
  updateInsightsSection
} from 'graphql/mutations';
import { insightByInsightsSectionID } from 'graphql/queries';
import mixpanel from 'mixpanel-browser';

import { ChartContainer } from 'components/charts';

import Insight from './Insight';

export default function InsightsSection({
  disableDrag = false,
  entryID,
  insightsSection,
  onDelete,
  to,
  from,
  index
}) {
  const { state } = useAppContext();
  const { user } = useAuthenticator(context => [context.user]);
  const clientID =
    state.client?.id || user.attributes['custom:clientID'] || user.username;
  const clientSub = state?.client?.sub || user.attributes.sub;
  const [insightSectionVersion, setInsightSectionVersion] = useState(
    insightsSection._version
  );
  const [insights, setInsights] = useState(null);
  const [grid, setGrid] = useState(insightsSection.orientation || 'row');
  const sensors = useSensors(useSensor(MouseSensor), useSensor(PointerSensor));
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition
  } = useSortable({ id: insightsSection.id });

  const dragHandleProps = {
    ...attributes,
    ...listeners,
    disabled: disableDrag,
    ref: setActivatorNodeRef
  };

  const wrapperStyle = {
    position: 'relative',
    transform: CSS.Translate.toString(transform),
    transition
  };

  useEffect(() => {
    const getInsight = async () => {
      const data = await API.graphql(
        graphqlOperation(insightByInsightsSectionID, {
          insightsSectionID: insightsSection.id,
          sortDirection: 'ASC'
        })
      );

      setInsights(data.data.insightByInsightsSectionID.items);
    };
    getInsight();
  }, [insightsSection.id]);

  const handleChartContainerGridChange = grid => {
    setGrid(grid);
    API.graphql(
      graphqlOperation(updateInsightsSection, {
        input: {
          id: insightsSection.id,
          orientation: grid,
          _version: insightSectionVersion
        }
      })
    )
      .then(response => {
        const newVersion = response.data.updateInsightsSection._version;
        setInsightSectionVersion(newVersion);
      })
      .catch(error => {
        console.error('Error updating insights section:', error);
      });
  };

  const handleCreateInsight = async () => {
    try {
      const {
        data: { createInsight: createdInsight }
      } = await API.graphql(
        graphqlOperation(createInsight, {
          input: {
            entryID,
            insightsSectionID: insightsSection.id,
            insightsSectionInsightsId: insightsSection.id,
            order: 10,
            owner: `${clientSub}::${clientID}`,
            clientID
          }
        })
      );
      mixpanel.track('create_insight');
      window.dataLayer.push({
        event: 'create_insight'
      });
      setInsights(prevInsights => prevInsights.concat(createdInsight));
    } catch (err) {
      console.log('[createInsight error]', err);
    }
  };

  const handleDragInsights = async ({ active, over }) => {
    if (over) {
      const overIndex = insights.findIndex(
        insightsSection => insightsSection.id === over.id
      );
      const activeIndex = insights.findIndex(
        insightsSection => insightsSection.id === active.id
      );
      if (activeIndex !== overIndex) {
        const newOrder =
          activeIndex < overIndex
            ? overIndex === insights.length - 1
              ? insights[overIndex].order + 1 * ORDER_NORMALIZE_FACTOR
              : (insights[overIndex].order + insights[overIndex + 1].order) / 2
            : (insights[overIndex].order +
                (insights[overIndex - 1]?.order ?? 0)) /
              2;
        const droppedInsight = {
          ...insights[activeIndex],
          order: newOrder
        };
        const { data } = await API.graphql(
          graphqlOperation(updateInsight, {
            input: {
              id: droppedInsight.id,
              createdAt: droppedInsight.createdAt,
              order: droppedInsight.order,
              _version: droppedInsight._version
            }
          })
        );
        const updatedInsight = data.updateInsight;
        setInsights(prevInsights =>
          arrayMove(prevInsights, activeIndex, overIndex).map(insight =>
            insight.id === updatedInsight.id ? updatedInsight : insight
          )
        );
        mixpanel.track('update_insight');
        window.dataLayer.push({
          event: 'update_insight'
        });
      }
    }
  };

  return (
    <Box ref={setNodeRef} sx={[wrapperStyle, isDragging && { zIndex: 10 }]}>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        modifiers={[
          grid === 'row' ? restrictToVerticalAxis : restrictToHorizontalAxis,
          restrictToWindowEdges
        ]}
        onDragEnd={handleDragInsights}
      >
        {insights && (
          <SortableContext
            items={insights}
            strategy={
              grid === 'row'
                ? verticalListSortingStrategy
                : horizontalListSortingStrategy
            }
          >
            <ChartContainer
              grid={grid}
              dragHandleProps={dragHandleProps}
              onCreate={handleCreateInsight}
              onDelete={() => onDelete?.(insightsSection)}
              onGridChange={handleChartContainerGridChange}
              insightsSection={insightsSection}
            >
              {insights.map(insight => (
                <Insight
                  key={insight.id}
                  disableDrag={insights.length === 1}
                  insight={insight}
                  setInsights={setInsights}
                  to={to}
                  from={from}
                  index={index}
                />
              ))}
            </ChartContainer>
          </SortableContext>
        )}
      </DndContext>
    </Box>
  );
}

import { useEffect, useState } from 'react';

import { Add, Menu, People } from '@mui/icons-material';
import { ClickAwayListener } from '@mui/material';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { useAppContext } from 'context';
import { useLocation, useNavigate } from 'react-router-dom';

import { ClinicianDrawer } from 'components/molecules';

function BottomNavigationBar(props) {
  const { children, open, handleDrawer, AppBarComponents } = props;
  const [value, setValue] = useState(0);
  const { state, dispatch } = useAppContext();
  const location = useLocation();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(open);

  useEffect(() => {
    if (state.modal) {
      setValue(1);
    } else if (state.menuOpen) {
      setValue(2);
    } else if (location.pathname !== '/') {
      setValue(null);
    } else {
      setValue(0);
    }
  }, [state.modal, state.menuOpen, location]); // Fixed typo here

  const [canCloseDrawer, setCanCloseDrawer] = useState(false);

  useEffect(() => {
    if (open) {
      setCanCloseDrawer(false);
      setTimeout(() => setCanCloseDrawer(true), 100);
    }
  }, [open]);

  const handleDrawerClose = () => {
    if (open && canCloseDrawer) {
      dispatch({ type: 'set_menu' });
      setCanCloseDrawer(false);
    }
  };

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  return (
    <Box sx={{ pb: 7 }}>
      <ClickAwayListener onClickAway={handleDrawerClose}>
        <Box>
          <ClinicianDrawer
            AppBarComponents={AppBarComponents}
            open={open}
            handleDrawer={handleDrawer}
          />
        </Box>
      </ClickAwayListener>

      {children}
      <div onClick={e => e.stopPropagation()}>
        <Paper
          sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
          elevation={3}
        >
          <BottomNavigation
            showLabels
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
          >
            <BottomNavigationAction
              label="Clients"
              icon={<People />}
              onClick={() => navigate('/')}
            />
            <BottomNavigationAction
              onClick={() => dispatch({ type: 'set_modal', modal: true })}
              label="Add Client"
              icon={<Add />}
            />
            <BottomNavigationAction
              label="Menu"
              icon={<Menu />}
              onClick={e => {
                e.stopPropagation();
                isOpen
                  ? dispatch({ type: 'set_menu', menuOpen: false })
                  : dispatch({ type: 'set_menu' });
              }}
            />
          </BottomNavigation>
        </Paper>
      </div>
    </Box>
  );
}

export default BottomNavigationBar;

import { useAuthenticator } from '@aws-amplify/ui-react';
import mixpanel from 'mixpanel-browser';
import { RouterProvider } from 'react-router-dom';
import { clientRoutes, clinicianRoutes, onboardingRoutes } from 'routes';

export default function Router() {
  const { user } = useAuthenticator(context => [context.user]);
  const extractUserAttribute = attribute => {
    try {
      return user.attributes[attribute];
    } catch (error) {
      console.error(`Error accessing ${attribute}:`, error);
      return undefined;
    }
  };
  mixpanel.identify(user.username);
  mixpanel.register({
    type:
      extractUserAttribute['custom:type'] === 'client' &&
      extractUserAttribute['custom:clientID'] != null
        ? 'participant'
        : extractUserAttribute['custom:type'] === 'client'
        ? 'client'
        : 'clinician'
  });

  const getRoutes = () => {
    const userType = extractUserAttribute('custom:type');
    const onboarding = extractUserAttribute('custom:onboarding');
    if (!userType || (onboarding !== '4' && onboarding !== '5')) {
      return onboardingRoutes;
    }
    switch (userType) {
      case 'client':
        return clientRoutes;
      case 'clinician':
        return clinicianRoutes;
      default:
        console.warn('Unknown user type, using onboarding routes as default.');
        return onboardingRoutes;
    }
  };

  return <RouterProvider router={getRoutes()} />;
}

import { Check, Close } from '@mui/icons-material';
import { Box, FormControlLabel } from '@mui/material';

import { CustomFormLabel } from '../CustomFormLabel';

export default function DisplayCheckbox({ input }) {
  return (
    <Box display="flex" alignItems="center" height="50px">
      <CustomFormLabel>{input?.inputName}</CustomFormLabel>
      <Box>
        {input.radiosValue &&
          input.radiosValue?.map(radio => (
            <FormControlLabel
              sx={{ ml: 0.1 }}
              key={radio.id}
              control={radio.label === 'No' ? <Close /> : <Check />}
            />
          ))}
      </Box>
    </Box>
  );
}

import { useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { Article, Close, PlayArrow } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { Auth } from 'aws-amplify';
import ReactPlayer from 'react-player';

export default function ClinicianOnboarding({ disableButton }) {
  const { user } = useAuthenticator(context => [context.user]);
  const [openModal, setOpenModal] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const [dialogTitle, setDialogTitle] = useState('');

  const handleCloseOnboarding = async () => {
    await Auth.updateUserAttributes(user, {
      'custom:onboarding': '5'
    });
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  return (
    <Box mb={4}>
      <Card elevation={0} sx={{ p: 2 }}>
        <Box
          mb={2}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box />
          <Typography variant="h5">Get started with SpeechFit</Typography>
          {!disableButton && (
            <Tooltip title="Don't show again">
              <IconButton onClick={handleCloseOnboarding}>
                <Close />
              </IconButton>
            </Tooltip>
          )}
          {disableButton && <Box />}
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6">
                  Video walk-through of SpeechFit
                </Typography>
              </CardContent>
              <CardActionArea
                onClick={() => {
                  setVideoUrl('https://www.youtube.com/watch?v=ReeJDh7wOko');
                  setDialogTitle('Video walk-through of SpeechFit');
                  setOpenModal(true);
                }}
                sx={{
                  p: 2,
                  display: 'flex',
                  justifyContent: 'center',
                  backgroundColor: 'background.settings'
                }}
              >
                <img
                  alt="video icon"
                  style={{ height: '70px' }}
                  src="/icons/video.svg"
                />
              </CardActionArea>
              <CardActions>
                <Button
                  onClick={() => {
                    setVideoUrl('https://www.youtube.com/watch?v=ReeJDh7wOko');
                    setDialogTitle('Video walk-through of SpeechFit');
                    setOpenModal(true);
                  }}
                  startIcon={<PlayArrow />}
                >
                  Play Video
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6">Invite your clients</Typography>
              </CardContent>
              <CardActionArea
                onClick={() => {
                  setVideoUrl('https://youtu.be/kfD1SzOoO7I');
                  setDialogTitle('Invite clients and test clients');
                  setOpenModal(true);
                }}
                sx={{
                  p: 2,
                  display: 'flex',
                  justifyContent: 'center',
                  backgroundColor: 'background.settings'
                }}
              >
                <img
                  alt="client icon"
                  style={{ height: '70px' }}
                  src="/icons/client.svg"
                />
              </CardActionArea>
              <CardActions>
                <Button
                  onClick={() => {
                    setVideoUrl('https://youtu.be/kfD1SzOoO7I');
                    setDialogTitle('Invite clients and test clients');
                    setOpenModal(true);
                  }}
                  startIcon={<PlayArrow />}
                >
                  Play Video
                </Button>
                <Button
                  onClick={() =>
                    window.open(
                      'https://docs.speechfit.io/clinicians/add-new-clients',
                      '_blank'
                    )
                  }
                  startIcon={<Article />}
                >
                  Read
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6">
                  Customise with drag-and-drop
                </Typography>
              </CardContent>
              <CardActionArea
                onClick={() => {
                  setVideoUrl('https://youtu.be/Xr6ReWFbdec');
                  setDialogTitle('Customise clients with drag-and-drop');
                  setOpenModal(true);
                }}
                sx={{
                  p: 2,
                  display: 'flex',
                  justifyContent: 'center',
                  backgroundColor: 'background.settings'
                }}
              >
                <img
                  alt="build icon"
                  style={{ height: '70px' }}
                  src="/icons/build.svg"
                />
              </CardActionArea>
              <CardActions>
                <Button
                  onClick={() => {
                    setVideoUrl('https://youtu.be/Xr6ReWFbdec');
                    setDialogTitle('Customise clients with drag-and-drop');
                    setOpenModal(true);
                  }}
                  startIcon={<PlayArrow />}
                >
                  Play Video
                </Button>
                <Button
                  onClick={() =>
                    window.open(
                      'https://docs.speechfit.io/stuttering-programs',
                      '_blank'
                    )
                  }
                  startIcon={<Article />}
                >
                  Read
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Card>
      <Dialog
        PaperProps={{ style: { width: '100%' } }}
        maxWidth="md"
        sx={{ width: '100%' }}
        open={openModal}
        onClose={handleCloseModal}
      >
        <Paper sx={{ width: '100%' }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <DialogTitle>{dialogTitle}</DialogTitle>
            <Box pr={2}>
              <IconButton onClick={handleCloseModal}>
                <Close />
              </IconButton>
            </Box>
          </Stack>
          <Divider sx={{ opacity: 0.3 }} />
          <DialogContent>
            <Box
              style={{
                position: 'relative',
                paddingTop: '56.25%'
              }}
            >
              <ReactPlayer
                pip
                width="100%"
                height="100%"
                className="react-player"
                controls
                url={videoUrl}
              />
            </Box>
          </DialogContent>
        </Paper>
      </Dialog>
    </Box>
  );
}

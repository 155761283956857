import { useEffect, useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { Box, Grid, Pagination } from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import {
  clientsByClinic,
  clientsByClinicAndName,
  clientsByLocationAndName,
  clientsByLocationID
} from 'graphql/queries';

import { LoadingAtom, PrivateClientCard } from 'components/atoms';
import { AssignClients } from 'components/molecules';

import { getHighestPermissionLevel } from 'utils/userRoles';

export default function AllClients({
  clinic,
  clinicians,
  searchClient,
  selectClients,
  selectedClients,
  setSelectClients,
  setSelectedClients,
  openAssignClients,
  setOpenAssignClients
}) {
  const { user } = useAuthenticator(context => [context.user]);
  const [clients, setClients] = useState([]);
  const [pageTokens, setPageTokens] = useState([null]);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [currentToken, setCurrentToken] = useState(null);
  const [hasMorePages, setHasMorePages] = useState(true);
  const [loading, setLoading] = useState(false);
  const highestPermission = getHighestPermissionLevel(user);
  const locationID = user.attributes['custom:locationID'];
  const practiceManagerWithLocation =
    highestPermission === 'practicemanager' && locationID;
  const admin = highestPermission === 'admin';

  useEffect(() => {
    const fetchClients = async () => {
      setLoading(true);

      let query;
      let responseKey;

      const baseVariables = {
        limit: searchClient ? 1000 : 20,
        nextToken: currentToken
      };

      if (practiceManagerWithLocation) {
        baseVariables.locationID = locationID;
        if (searchClient) {
          query = clientsByLocationID;
          responseKey = 'clientsByLocationID';
        } else {
          query = clientsByLocationAndName;
          responseKey = 'clientsByLocationAndName';
        }
      } else if (admin) {
        baseVariables.clinicID = clinic.id;
        if (searchClient) {
          query = clientsByClinic;
          responseKey = 'clientsByClinic';
        } else {
          query = clientsByClinicAndName;
          responseKey = 'clientsByClinicAndName';
        }
      }

      if (searchClient) {
        setCurrentPageIndex(0);
        setCurrentToken(null);
        baseVariables.filter = {
          or: [
            { clientFirstName: { contains: searchClient } },
            { firstName: { contains: searchClient } },
            { lastName: { contains: searchClient } },
            { clientLastName: { contains: searchClient } },
            { clientFirstName: { contains: searchClient.toLowerCase() } },
            { firstName: { contains: searchClient.toLowerCase() } },
            { lastName: { contains: searchClient.toLowerCase() } },
            { clientLastName: { contains: searchClient.toLowerCase() } }
          ]
        };
      }

      try {
        const response = await API.graphql(
          graphqlOperation(query, baseVariables)
        );
        const fetchedClients = response.data[responseKey].items;
        setClients(fetchedClients);
        const nextToken = response.data[responseKey].nextToken;

        if (currentPageIndex === pageTokens.length - 1) {
          setPageTokens([...pageTokens, nextToken]);
        }
        setHasMorePages(nextToken !== null);
      } catch (error) {
        console.error('Error fetching clients', error);
      } finally {
        setLoading(false);
      }
    };

    fetchClients();
  }, [
    currentPageIndex,
    pageTokens,
    clinic.id,
    currentToken,
    searchClient,
    practiceManagerWithLocation,
    locationID,
    admin
  ]);

  const handlePaginationChange = (event, value) => {
    const newCurrentPageIndex = value - 1;
    const newCurrentToken = pageTokens[newCurrentPageIndex];
    if (newCurrentPageIndex !== currentPageIndex) {
      setCurrentPageIndex(newCurrentPageIndex);
      setCurrentToken(newCurrentToken);
    }
  };

  return (
    <Box>
      <AssignClients
        user={user}
        clinicians={clinicians}
        open={openAssignClients}
        setOpen={setOpenAssignClients}
        selectedClients={selectedClients}
        setSelectClients={setSelectClients}
        setSelectedClients={setSelectedClients}
      />
      {loading ? (
        <LoadingAtom />
      ) : (
        <Box>
          <Grid spacing={2} container>
            {clients.map(client => (
              <Grid key={client.id} item xs={12} sm={6} xl={3}>
                <PrivateClientCard
                  user={user}
                  key={client.id}
                  client={client}
                  selectClients={selectClients}
                  selectedClients={selectedClients}
                  setSelectedClients={setSelectedClients}
                />
              </Grid>
            ))}
          </Grid>
          <Box mt={6} mb={2} display="flex" justifyContent="center">
            <Pagination
              count={hasMorePages ? currentPageIndex + 2 : currentPageIndex + 1}
              page={currentPageIndex + 1}
              onChange={handlePaginationChange}
              color="secondary"
              variant="outlined"
              siblingCount={1}
              boundaryCount={1}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
}

import { commands as editorCommands } from '@uiw/react-md-editor';

export const commands = [
  // Custom Toolbars
  editorCommands.group(
    [
      editorCommands.title1,
      editorCommands.title2,
      editorCommands.title3,
      editorCommands.title4,
      editorCommands.title5,
      editorCommands.title6
    ],
    {
      name: 'title',
      groupName: 'title',
      buttonProps: { 'aria-label': 'Insert title' }
    }
  ),
  editorCommands.bold,
  editorCommands.italic,
  editorCommands.strikethrough,
  editorCommands.quote,
  editorCommands.hr,
  editorCommands.divider,
  editorCommands.checkedListCommand,
  editorCommands.orderedListCommand,
  editorCommands.unorderedListCommand,
  editorCommands.divider,
  editorCommands.link,
  editorCommands.divider,
  editorCommands.image
];

import { useEffect, useState } from 'react';

import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  CardActionArea,
  Collapse,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  useTheme
} from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import { useAppContext } from 'context';
import { useSnackbar } from 'context/SnackBar';
import { createFromTemplate, programChange } from 'graphql/queries';
import mixpanel from 'mixpanel-browser';

import { Modal } from 'components/molecules';

import { formatProgram } from 'utils/formatProgram';

export default function ChangeProgram() {
  const { state, dispatch } = useAppContext();
  const { enqueueSnackbar } = useSnackbar();
  const client = state.client;
  const [program, setProgram] = useState(client.program);
  const [programStage, setProgramStage] = useState(client.stage);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const programs = {
    child: [
      'lidcombe',
      'westmead',
      'oakville',
      'camperdownAdolescent',
      'monitoringChild'
    ],
    me: ['camperdownAdult', 'camperdownAdolescent'],
    adult: ['camperdownAdult', 'camperdownAdolescent', 'monitoringAdult'],
    carer: ['camperdownAdult', 'camperdownAdolescent', 'monitoringAdult'],
    other: [
      'lidcombe',
      'westmead',
      'oakville',
      'camperdownAdolescent',
      'camperdownAdult',
      'monitoringAdult',
      'monitoringChild'
    ]
  };
  const theme = useTheme();

  const handleCancel = () => {
    setProgram(client.program);
    setProgramStage(client.stage);
    dispatch({ type: 'set_change_program', changeProgram: false });
  };

  const handleConfirm = async () => {
    try {
      setLoading(true);
      await API.graphql(
        graphqlOperation(programChange, { clientID: client.id, program })
      );
      await API.graphql(
        graphqlOperation(createFromTemplate, {
          clientID: client.id,
          clientSub: client.id,
          program: program,
          updateExisting: true,
          skipInsights: true,
          stage: programStage
        })
      );
      dispatch({
        type: 'add_client',
        client: { ...client, program, stage: programStage }
      });
      mixpanel.track('change_program', { program });
      enqueueSnackbar(
        `Changed ${
          client?.clientFirstName || client?.firstName
        }'s Program to ${formatProgram(program)}`,
        { severity: 'success' }
      );
      dispatch({ type: 'set_change_program', changeProgram: false });
    } catch (e) {
      console.log(e);
      setError(e.message);
      enqueueSnackbar(
        `Error changing ${
          client?.clientFirstName || client?.firstName
        }'s Program to ${formatProgram(program)}`,
        { severity: 'error' }
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    switch (program) {
      case 'lidcombe':
      case 'westmead':
      case 'oakville':
        if (programStage === 0) {
          setProgramStage(1);
        }
        if (programStage > 2) {
          setProgramStage(1);
        }
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [program]);

  return (
    <Modal
      disableExit={loading}
      title="Change Program"
      open={state.changeProgram}
      setClose={() =>
        dispatch({ type: 'set_change_program', changeProgram: false })
      }
      fullWidth
      maxWidth={'md'}
    >
      <Collapse in={Boolean(error)}>
        <Box my={2}>
          <Typography color="error">{error}</Typography>
        </Box>
      </Collapse>
      {programs[client?.forValue || 'other'].map(prog => (
        <Card
          key={prog}
          sx={{
            my: 1,
            backgroundColor:
              program === prog
                ? theme.palette.primary.lightest
                : theme.palette.background.card
          }}
          elevation={0}
        >
          <CardActionArea sx={{ p: 2 }} onClick={() => setProgram(prog)}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h5">{formatProgram(prog)}</Typography>
              {program === prog ? (
                <RadioButtonChecked color="primary" sx={{ fontSize: 36 }} />
              ) : (
                <RadioButtonUnchecked sx={{ fontSize: 36 }} color="grey" />
              )}
            </Stack>
          </CardActionArea>
        </Card>
      ))}
      <Collapse in={Boolean(program)}>
        <Box my={2}>
          {!program.toLowerCase().includes('monitoring') && (
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">
                Stage
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={programStage}
                onChange={e => setProgramStage(e.target.value)}
              >
                <FormControlLabel value={1} control={<Radio />} label="I" />

                {program?.includes('oakville') && (
                  <FormControlLabel value={2} control={<Radio />} label="I.5" />
                )}

                <FormControlLabel
                  value={program?.includes('oakville') ? 3 : 2}
                  control={<Radio />}
                  label="II"
                />
                {program?.includes('camperdown') && (
                  <>
                    <FormControlLabel
                      value={3}
                      control={<Radio />}
                      label="III"
                    />
                    <FormControlLabel
                      value={4}
                      control={<Radio />}
                      label="IV"
                    />
                  </>
                )}
              </RadioGroup>
            </FormControl>
          )}
        </Box>
      </Collapse>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
      >
        <Button disabled={loading} onClick={handleCancel} color="grey">
          Cancel
        </Button>
        <LoadingButton loading={loading} onClick={handleConfirm}>
          Confirm
        </LoadingButton>
      </Box>
    </Modal>
  );
}

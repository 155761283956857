import { Box, Container } from '@mui/material';
import MuiMarkdown from 'mui-markdown';

function ContentText({ content }) {
  return (
    <Box pt={6}>
      <Container maxWidth="md">
        <MuiMarkdown>{content.content}</MuiMarkdown>
      </Container>
    </Box>
  );
}

export default ContentText;

import React, { useEffect, useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { Remove } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  Radio,
  RadioGroup,
  Switch
} from '@mui/material';
import {
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import { useAppContext } from 'context';
import {
  componentsByEntrySectionByOrder,
  entriesBySectionByOrder,
  entrySectionsByEntryIDByOrder,
  sectionByClientIDByOrder
} from 'graphql/queries';

import ordinalSuffix from 'utils/ordinalSuffix';

const ChipTable = ({
  aggregateBy,
  dataType,
  type,
  dataRecords,
  onRowsChange
}) => {
  const { user } = useAuthenticator(context => [context.user]);
  const { state } = useAppContext();
  const clientID =
    state?.client?.id || user.attributes['custom:clientID'] || user.username;
  const [addedRows, setAddedRows] = useState(dataRecords || []);
  const [loadingSections, setLoadingSections] = useState(true);
  const [sections, setSections] = useState([]);
  const [selectedSection, setSelectedSection] = useState('');
  const [loadingEntries, setLoadingEntries] = useState(false);
  const [entries, setEntries] = useState('');
  const [selectedEntry, setSelectedEntry] = useState('');
  const [loadingEntrySections, setLoadingEntrySections] = useState(false);
  const [entrySections, setEntrySections] = useState('');
  const [selectedEntrySection, setSelectedEntrySection] = useState('');
  const [loadingComponents, setLoadingComponents] = useState(false);
  const [components, setComponents] = useState('');
  const [selectedComponent, setSelectedComponent] = useState('');

  useEffect(() => {
    setAddedRows([]);
  }, [aggregateBy]);

  useEffect(() => {
    if (dataRecords) {
      setAddedRows([dataRecords]);
    }
    return () => {
      setAddedRows([]);
    };
  }, [dataRecords]);

  useEffect(() => {
    const fetchSections = async () => {
      const apiData = await API.graphql(
        graphqlOperation(sectionByClientIDByOrder, {
          clientID: clientID,
          sortDirection: 'ASC'
        })
      );
      const items = apiData.data.sectionByClientIDByOrder.items;
      const filteredItems = items.filter(item => !item.complete);
      setSections(filteredItems);
      setLoadingSections(false);
    };
    fetchSections();
    return () => {
      setSections([]);
    };
  }, [clientID]);

  useEffect(() => {
    if (selectedSection?.id) {
      setLoadingEntries(true);
      const fetchEntries = async () => {
        const apiData = await API.graphql(
          graphqlOperation(entriesBySectionByOrder, {
            sectionID: selectedSection.id,
            sortDirection: 'ASC'
          })
        );
        const items = apiData.data.entriesBySectionByOrder.items;
        const filteredItems = items.filter(item => !item.complete);
        setEntries(filteredItems);
        setLoadingEntries(false);
      };
      fetchEntries();
      return () => {
        setEntries([]);
      };
    }
  }, [selectedSection]);

  useEffect(() => {
    if (selectedEntry?.id) {
      setLoadingEntrySections(true);
      const fetchEntrySections = async () => {
        const apiData = await API.graphql(
          graphqlOperation(entrySectionsByEntryIDByOrder, {
            entryID: selectedEntry.id,
            sortDirection: 'ASC'
          })
        );
        const items = apiData.data.entrySectionsByEntryIDByOrder.items;
        const filteredItems = items.filter(item => !item.complete);
        setEntrySections(filteredItems);
        setLoadingEntrySections(false);
      };
      fetchEntrySections();
      return () => {
        setEntrySections([]);
      };
    }
  }, [selectedEntry]);

  useEffect(() => {
    if (selectedEntrySection?.id) {
      setLoadingComponents(true);
      const fetchComponents = async () => {
        const apiData = await API.graphql(
          graphqlOperation(componentsByEntrySectionByOrder, {
            entrySectionID: selectedEntrySection.id,
            sortDirection: 'ASC'
          })
        );
        const items = apiData.data.componentsByEntrySectionByOrder.items;
        const filteredItems = items.filter(item => !item.complete);
        setComponents(filteredItems);
        setLoadingComponents(false);
      };

      fetchComponents();
      return () => {
        setComponents([]);
      };
    }
  }, [selectedEntrySection]);

  const selectSection = section => {
    setSelectedSection(section);
    setSelectedEntry('');
    setSelectedEntrySection('');
    setSelectedComponent('');
  };

  const selectEntry = entry => {
    setSelectedEntry(entry);
    setSelectedEntrySection('');
    setSelectedComponent('');
  };

  const selectEntrySection = entrySection => {
    setSelectedEntrySection(entrySection);
    setSelectedComponent('');
  };

  const selectComponent = component => {
    setSelectedComponent(component);
  };

  const [countBy, setCountBy] = useState('rater');

  const [countByBoolean, setCountByBoolean] = useState(true);

  const handleChange = event => {
    setCountBy(event.target.value);
  };

  const addButtonEnabled =
    dataType === 'some' &&
    ((aggregateBy === 'count' && selectedSection && selectedEntry) ||
      (aggregateBy === 'average' &&
        selectedSection &&
        selectedEntry &&
        selectedEntrySection &&
        selectedComponent));

  const handleAddRow = () => {
    setAddedRows([
      ...addedRows,
      {
        selectedSection,
        selectedEntry,
        selectedEntrySection,
        selectedComponent,
        countBy,
        countByBoolean
      }
    ]);
  };

  useEffect(() => {
    if (onRowsChange && typeof onRowsChange === 'function') {
      onRowsChange(addedRows);
    }
  }, [addedRows, onRowsChange]);

  const handleRemoveRow = indexToRemove => {
    setAddedRows(addedRows.filter((_, index) => index !== indexToRemove));
  };

  const LoadingDropdown = () => (
    <MenuItem key="loading" value="">
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <CircularProgress sx={{ height: '20px', width: '20px' }} size="small" />
      </Box>
    </MenuItem>
  );

  return (
    <TableContainer sx={{ my: 2 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Section</TableCell>
            <TableCell>Entry</TableCell>
            {(aggregateBy === 'average' || type === 'pie') && (
              <>
                {type === 'pie' && aggregateBy === 'count' && (
                  <TableCell>Count</TableCell>
                )}
                <TableCell>Entry Section</TableCell>
                <TableCell>Component</TableCell>
              </>
            )}
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {addedRows.map((row, index) => (
            <TableRow key={index}>
              <TableCell>{row.selectedSection?.name}</TableCell>
              <TableCell>{row.selectedEntry?.name}</TableCell>
              {(aggregateBy === 'average' || type === 'pie') && (
                <>
                  {type === 'pie' && aggregateBy === 'count' && (
                    <TableCell>
                      {row.countBy === 'rater' ? 'Rating' : 'Boolean'}
                    </TableCell>
                  )}
                  <TableCell>{row.selectedEntrySection?.name}</TableCell>
                  <TableCell>{row.selectedComponent?.name}</TableCell>
                  {type === 'pie' &&
                    aggregateBy === 'count' &&
                    row.countBy === 'boolean' && (
                      <TableCell>
                        {row.countByBoolean ? 'True' : 'False'}
                      </TableCell>
                    )}
                </>
              )}
              <TableCell>
                <IconButton onClick={() => handleRemoveRow(index)}>
                  <Remove />
                </IconButton>
              </TableCell>
              <TableCell />
            </TableRow>
          ))}
          <TableRow>
            <TableCell>
              <FormControl
                color="tertiary"
                variant="outlined"
                sx={{ minWidth: '200px' }}
              >
                <InputLabel id="section-label">Section</InputLabel>
                <Select
                  label="Section"
                  value={selectedSection}
                  onChange={evt => selectSection(evt.target.value)}
                  displayEmpty
                  renderValue={selected => {
                    if (!selected) {
                      return <span></span>;
                    }
                    return selected.name;
                  }}
                >
                  {loadingSections
                    ? [[<LoadingDropdown />]]
                    : [
                        <MenuItem key="none" value="">
                          <em>None</em>
                        </MenuItem>,
                        ...sections.map(section => (
                          <MenuItem key={section.id} value={section}>
                            {section.name}
                          </MenuItem>
                        ))
                      ]}
                </Select>
              </FormControl>
            </TableCell>
            <TableCell>
              <FormControl
                color="tertiary"
                variant="outlined"
                sx={{ minWidth: '200px' }}
              >
                <InputLabel id="section-label">Entry</InputLabel>
                <Select
                  value={selectedEntry}
                  onChange={evt => selectEntry(evt.target.value)}
                  displayEmpty
                  label="Entry"
                  renderValue={selected => {
                    if (!selected) {
                      return <span></span>;
                    }
                    return selected.name;
                  }}
                >
                  {loadingEntries
                    ? [[<LoadingDropdown />]]
                    : [
                        <MenuItem key="none" value="">
                          <em>None</em>
                        </MenuItem>,
                        ...(entries.length > 0
                          ? entries.map(entry => (
                              <MenuItem key={entry.id} value={entry}>
                                {entry.name}
                              </MenuItem>
                            ))
                          : [])
                      ]}
                </Select>
              </FormControl>
            </TableCell>
            {type === 'pie' && aggregateBy === 'count' && (
              <TableCell>
                <FormControl
                  color="tertiary"
                  sx={{ width: 'max-content' }}
                  component="fieldset"
                >
                  <RadioGroup
                    aria-label="options"
                    name="options"
                    value={countBy}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="rater"
                      control={<Radio />}
                      label="Rating"
                    />
                    <FormControlLabel
                      value="boolean"
                      control={<Radio />}
                      label="Boolean"
                    />
                  </RadioGroup>
                </FormControl>
              </TableCell>
            )}
            {(aggregateBy === 'average' || type === 'pie') && (
              <>
                <TableCell>
                  <FormControl
                    color="tertiary"
                    variant="outlined"
                    sx={{ minWidth: '200px' }}
                  >
                    <InputLabel id="section-label">Entry Section</InputLabel>
                    <Select
                      value={selectedEntrySection}
                      onChange={evt => selectEntrySection(evt.target.value)}
                      displayEmpty
                      disabled={loadingEntrySections}
                      label="Entry Section"
                      renderValue={selected => {
                        if (!selected) {
                          return <span></span>;
                        }
                        return selected.name;
                      }}
                    >
                      {loadingEntrySections
                        ? [[<LoadingDropdown />]]
                        : [
                            <MenuItem key="none" value="">
                              <em>None</em>
                            </MenuItem>,
                            ...(entrySections.length > 0
                              ? entrySections.map((entrySection, index) => (
                                  <MenuItem
                                    key={entrySection.id}
                                    value={entrySection}
                                  >
                                    {entrySection.name ||
                                      ordinalSuffix(index + 1) +
                                        ' Entry Section'}
                                  </MenuItem>
                                ))
                              : [])
                          ]}
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell>
                  <FormControl
                    color="tertiary"
                    variant="outlined"
                    sx={{ minWidth: '200px' }}
                  >
                    <InputLabel id="section-label">Component</InputLabel>
                    <Select
                      value={selectedComponent}
                      onChange={evt => selectComponent(evt.target.value)}
                      displayEmpty
                      disabled={loadingComponents}
                      label="Component"
                      renderValue={selected => {
                        if (!selected) {
                          return <span></span>;
                        }
                        return selected.name;
                      }}
                    >
                      {loadingComponents
                        ? [<LoadingDropdown />]
                        : [
                            <MenuItem key="none" value="">
                              <em>None</em>
                            </MenuItem>,
                            ...(components.length > 0
                              ? components.map((component, index) => (
                                  <MenuItem
                                    key={component.id}
                                    value={component}
                                  >
                                    {component.name ||
                                      ordinalSuffix(index + 1) + ' Component'}
                                  </MenuItem>
                                ))
                              : [])
                          ]}
                    </Select>
                  </FormControl>
                </TableCell>
                {type === 'pie' &&
                  aggregateBy === 'count' &&
                  countBy === 'boolean' && (
                    <TableCell>
                      <FormControlLabel
                        color="tertiary"
                        sx={{ width: 140 }}
                        control={
                          <Switch
                            checked={countByBoolean}
                            onChange={evt =>
                              setCountByBoolean(evt.target.checked)
                            }
                            color="primary"
                          />
                        }
                        label={countByBoolean ? 'True' : 'False'}
                      />
                    </TableCell>
                  )}
              </>
            )}
            <TableCell>
              <IconButton disabled={!addButtonEnabled} onClick={handleAddRow}>
                <AddIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ChipTable;

import { memo, useState } from 'react';

import { Link } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Alert, AlertTitle, Box, Chip } from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import { useAppContext } from 'context';
import { useSnackbar } from 'context/SnackBar';
import { updateClient } from 'graphql/mutations';
import { getClient } from 'graphql/queries';
import mixpanel from 'mixpanel-browser';
import { useNavigate } from 'react-router-dom';

import { ErrorMessage, SettingHeader } from 'components/atoms';

import Modal from '../Modal';

function AccountConnection({ firstName }) {
  const [updateConnection, setUpdateConnection] = useState(false);
  const [unlinking, setUnlinking] = useState(false);
  const [error, setError] = useState(null);
  const { state } = useAppContext();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const unlinkAccount = async () => {
    try {
      setUnlinking(true);
      const { data } = await API.graphql(
        graphqlOperation(getClient, { id: state.client?.id })
      );
      const clientVersion = data.getClient._version;
      await API.graphql(
        graphqlOperation(updateClient, {
          input: {
            id: state.client?.id,
            clinicianID: null,
            _version: clientVersion
          }
        })
      );
      mixpanel.track('remove_client');
      window.dataLayer.push({
        event: 'remove_client'
      });
      navigate('/');
      enqueueSnackbar(`Unlinked ${firstName}'s account`, {
        severity: 'success'
      });
    } catch (err) {
      setError(err.message);
      enqueueSnackbar(`Error unlinking ${firstName}'s account`, {
        severity: 'error'
      });
    } finally {
      setUnlinking(false);
    }
  };

  return (
    <Box p={1} my={2}>
      <SettingHeader
        state={updateConnection}
        setState={setUpdateConnection}
        label="Account Connection"
      />
      <Modal
        open={updateConnection}
        setClose={() => setUpdateConnection(false)}
        title="Unlink account"
        maxWidth="sm"
      >
        <Box>
          <Box p={1} display="flex" flexDirection="column">
            <Alert severity="warning">
              <AlertTitle>
                Caution: Do you want to unlink this account?
              </AlertTitle>
              If you unlink this account, your client will still be able to
              access it but you won't. You can always link the account again,
              and no data will be lost. Your client will not be notified.
            </Alert>
            <Box my={2}>
              <LoadingButton
                variant="contained"
                fullWidth
                loading={unlinking}
                onClick={unlinkAccount}
                size="small"
              >
                Remove Account
              </LoadingButton>
            </Box>
          </Box>
          {Boolean(error) && <ErrorMessage message={error} />}
        </Box>
      </Modal>
      {!updateConnection && (
        <Box p={1} sx={{ display: 'flex', alignItems: 'center' }}>
          <Chip
            size="large"
            label="Connected"
            icon={<Link color="primary" />}
          />
        </Box>
      )}
    </Box>
  );
}

export default memo(AccountConnection);

// microphonePermissionChecker.js

const MicrophonePermissionChecker = {
  checkPermission: () => {
    return new Promise((resolve, reject) => {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then(stream => {
          // Once we have the stream, we know the microphone permission has been granted.
          resolve('granted');

          // Stop the tracks to release the microphone.
          stream.getTracks().forEach(track => {
            track.stop();
          });
        })
        .catch(err => {
          if (
            err.name === 'NotAllowedError' ||
            err.name === 'PermissionDeniedError'
          ) {
            resolve('denied');
          } else if (err.name === 'NotFoundError') {
            resolve('no-microphone-found');
          } else {
            reject(err);
          }
        });
    });
  }
};

const checkMicrophonePermission = async () => {
  try {
    const permissionStatus = await navigator.permissions.query({
      name: 'microphone'
    });

    // Check the state of the permission, not just the presence of permissionStatus object
    if (permissionStatus.state === 'granted') {
      return 'granted';
    } else {
      return 'denied-or-not-asked'; // Adjust based on your needs
    }
  } catch (error) {
    try {
      return await MicrophonePermissionChecker.checkPermission();
    } catch (innerError) {
      console.error(
        'Error checking microphone permission using getUserMedia:',
        innerError
      );
      return 'error';
    }
  }
};

export default checkMicrophonePermission;

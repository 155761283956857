import {
  SentimentDissatisfied,
  SentimentNeutral,
  SentimentSatisfied,
  SentimentVeryDissatisfied,
  SentimentVerySatisfied,
  ThumbDown,
  ThumbUp
} from '@mui/icons-material';
import { Tooltip } from '@mui/material';

export const constants = {
  cannyUrl: 'https://speechfit.canny.io/',
  contactUrl: `${process.env.REACT_APP_SITE_URL}/contact`,
  privacyUrl: `${process.env.REACT_APP_SITE_URL}/privcy-policy`,
  termsUrl: `${process.env.REACT_APP_SITE_URL}/terms-and-conditions`,
  baaURL: `${process.env.REACT_APP_SITE_URL}/business-associate-agreement`
};

export const priceIDs = {
  Standard: {
    Monthly: 'price_1NapnkFVqA7Kd8LjBvxszEWG',
    Annual: 'price_1NZBAfFVqA7Kd8LjFdqnfC0O'
  },
  Pro: {
    Monthly: 'price_1NapixFVqA7Kd8Ljx5QGUIW7',
    Annual: 'price_1Napl1FVqA7Kd8LjbYudycmt'
  }
};

export const normalizeFactor = 10;

export const emojiMapperOneThumb = [
  {
    id: '1-satisfied-thumb',
    component: (
      <Tooltip title="Positive">
        <ThumbUp />
      </Tooltip>
    ),
    color: '#57bb8a'
  }
];

export const emojiMapperTwoThumbs = [
  {
    id: '2-dissatisfied-thumb',
    component: (
      <Tooltip title="Negative">
        <ThumbDown />
      </Tooltip>
    ),
    color: '#ea4335'
  },
  {
    id: '2-satisfied-thumb',
    component: (
      <Tooltip title="Positive">
        <ThumbUp />
      </Tooltip>
    ),
    color: '#57bb8a'
  }
];

export const emojiMapperTwo = [
  {
    id: '2-dissatisfied',
    component: (
      <Tooltip title="Negative">
        <SentimentDissatisfied />
      </Tooltip>
    ),
    color: '#ea4335'
  },
  {
    id: '2-satisfied',
    component: (
      <Tooltip title="Positive">
        <SentimentSatisfied />
      </Tooltip>
    ),
    color: '#57bb8a'
  }
];

export const emojiMapperThree = [
  {
    id: '3-dissatisfied',
    component: (
      <Tooltip title="Negative">
        <SentimentDissatisfied />
      </Tooltip>
    ),
    color: '#ea4335'
  },
  {
    id: '3-neutral',
    component: (
      <Tooltip title="Neutral">
        <SentimentNeutral />
      </Tooltip>
    ),
    color: '#fdd666'
  },
  {
    id: '3-satisfied',
    component: (
      <Tooltip title="Positive">
        <SentimentSatisfied />
      </Tooltip>
    ),
    color: '#57bb8a'
  }
];

export const emojiMapperFive = [
  {
    id: '5-very-dissatisfied',
    component: <SentimentVeryDissatisfied />,
    color: '#ef613f'
  },
  {
    id: '5-dissatisfied',
    component: <SentimentDissatisfied />,
    color: '#f79d53'
  },
  { id: '5-neutral', component: <SentimentNeutral />, color: '#fdd666' },
  { id: '5-satisfied', component: <SentimentSatisfied />, color: '#bbca76' },
  {
    id: '5-very-satisfied',
    component: <SentimentVerySatisfied />,
    color: '#57bb8a'
  }
];

export const programs = [
  {
    lidcombe: 'lidcombe'
  },
  { westmead: 'westmead' },
  { oakville: 'oakville' },
  { camperdownAdult: 'camperdownAdult' },
  { camperdownAdolescent: 'camperdownAdolescent' }
];

const programObj = Object.assign({}, ...programs);

export const programVersions = [
  { lidcombe: programObj['lidcombe'] },
  { 'lidcombe-2': programObj['lidcombe'] },
  { camperdownAdult: programObj['camperdownAdult'] },
  { camperdownAdolescent: programObj['camperdownAdolescent'] },
  { westmead: programObj['westmead'] },
  { oakville: programObj['oakville'] }
];

export const rolePriorities = {
  Client: 1,
  Clinician: 2,
  PracticeManager: 3,
  Admin: 4
};

export const clinicRoles = {
  clinician: 2,
  practicemanager: 3,
  admin: 4
};

export const ACTION = Object.freeze({
  ADMIN_EXISTS: 'admin_exists',
  NEEDS_CLINICIAN_CONFIRMATION: 'needs_clinician_confirmation',
  CLINICIAN_WITH_CLINIC: 'clinician_with_clinic',
  CLINICIAN_WITHOUT_CLINIC: 'clinician_without_clinic',
  INVITE_ADMIN_TO_CLINIC: 'invite_admin_to_clinic',
  SELECTED_CLINIC: 'selected_clinic',
  DEFAULT: 'default'
});

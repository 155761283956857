import React, { useCallback, useEffect, useState } from 'react';

import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Collapse,
  Divider,
  FormControlLabel,
  FormGroup,
  Link,
  TextField,
  Typography
} from '@mui/material';
import isEmail from '@rearguard/is-email';
import { constants } from 'common/constants';
import * as yup from 'yup';

import { AuthCode } from 'components/atoms';

function UnifiedSignInUp({
  email,
  setEmail,
  password,
  setPassword,
  handleSignIn,
  signingIn,
  setVerifyUser
}) {
  const [errors, setErrors] = useState({});
  const [submissionAttempted, setSubmissionAttempted] = useState(false);
  const [agreeToTerms, setAgreeToTerms] = useState(true);

  const validateForm = useCallback(async () => {
    let validationSchema;

    validationSchema = yup.object().shape({
      email: yup
        .string()
        .required('Email is required')
        .test('isValid', 'Email is not valid', value => isEmail(value)),
      password: yup.string().required('Password is required'),
      agreeToTerms: yup
        .bool()
        .oneOf([true], 'You must agree to the terms to proceed.')
    });

    try {
      await validationSchema.validate(
        {
          email,
          password,
          agreeToTerms
        },
        { abortEarly: false }
      );

      setErrors({});
      return true;
    } catch (err) {
      let newErrors = {};
      err.inner.forEach(e => {
        newErrors[e.path] = e.message;
      });
      setErrors(newErrors);
      return false;
    }
  }, [email, password, agreeToTerms]);

  const validateField = (fieldName, value) => {
    let validationSchema;
    if (!submissionAttempted && !errors[fieldName]) return;

    switch (fieldName) {
      case 'email':
        validationSchema = yup
          .string()
          .required('Email is required')
          .test('isValid', 'Email is not valid', val => isEmail(val));
        break;
      case 'password':
        validationSchema = yup.string().required('Password is required');
        break;
      case 'agreeToTerms':
        validationSchema = yup
          .bool()
          .oneOf([true], 'You must agree to the terms to proceed.');
        break;
      default:
        return;
    }

    try {
      validationSchema.validateSync(value);
      setErrors(prevErrors => {
        const newErrors = { ...prevErrors };
        delete newErrors[fieldName];
        return newErrors;
      });
    } catch (err) {
      setErrors(prevErrors => ({
        ...prevErrors,
        [fieldName]: err.message
      }));
    }
  };

  const handleFormSubmit = useCallback(async () => {
    const isValid = await validateForm();
    if (isValid) {
      const action = handleSignIn;
      action().catch(err => console.error(err));
    } else {
      setSubmissionAttempted(true);
    }
  }, [validateForm, handleSignIn]);

  useEffect(() => {
    const handleKeyPress = e => {
      if (e.key === 'Enter') {
        handleFormSubmit();
      }
    };
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleFormSubmit]);

  const removeParams = () => {
    const currentUrl = new URL(window.location.href);
    const newPathname = currentUrl.pathname;
    window.history.replaceState({}, '', newPathname);
    setVerifyUser(false);
  };

  return (
    <Box width="100%" sx={{ px: { xs: 2, sm: 0 } }}>
      <Box mt={1} mb={3} width="100%">
        <Divider sx={{ opacity: 0.5 }}>VERIFY ACCOUNT</Divider>
      </Box>
      <Box width="100%" my={1}>
        <TextField
          size="small"
          fullWidth
          label="Email"
          value={email}
          onChange={e => {
            setEmail(e.target.value);
            if (submissionAttempted || errors.email) {
              validateField('email', e.target.value);
            }
          }}
          error={!!errors.email}
          helperText={errors.email}
        />
      </Box>

      <Collapse in={!!email}>
        <Box>
          <Box mt={2}>
            <Alert severity="info">Enter your verification code below.</Alert>
          </Box>
          <Box width="100%" my={1}>
            <AuthCode
              error={!!errors.password}
              helperText={errors.password}
              length={6}
              onComplete={code => setPassword(code)}
            />
          </Box>
          <Box width="100%" p={1}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={agreeToTerms}
                    onChange={e => {
                      setAgreeToTerms(e.target.checked);
                      validateField('agreeToTerms', e.target.checked);
                    }}
                  />
                }
                label={
                  <>
                    &nbsp;I agree to the{' '}
                    <Link href={constants.termsUrl}>Terms and Conditions</Link>{' '}
                    and <Link href={constants.privacyUrl}>Privacy Policy</Link>.
                  </>
                }
                sx={{ '.MuiTypography-body1': { fontSize: '0.7rem' } }}
              />
              {errors.agreeToTerms && (
                <Typography variant="body2" color="error">
                  {errors.agreeToTerms}
                </Typography>
              )}
            </FormGroup>
          </Box>
        </Box>
      </Collapse>

      <Box display="flex" width="100%" my={1}>
        <LoadingButton
          size="small"
          variant="contained"
          fullWidth
          onClick={handleFormSubmit}
          loading={signingIn}
        >
          Complete Sign Up
        </LoadingButton>
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <Box>
          <Typography variant="caption">Already have an account?</Typography>
          <Button size="small" onClick={removeParams}>
            Sign In
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default UnifiedSignInUp;

import { useEffect, useState } from 'react';

import { Box, CircularProgress, Pagination } from '@mui/material';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

const PDF = ({ pdf }) => {
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [renderedPageNumber, setRenderedPageNumber] = useState(null);

  const isLoading = renderedPageNumber !== currentPage;

  pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.js',
    import.meta.url
  ).toString();

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    if (pdf) {
      setLoading(false);
    }
  }, [pdf]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '80vh'
      }}
    >
      {loading ? (
        <CircularProgress />
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Pagination
            count={numPages}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              overflow: 'auto',
              margin: '10px',
              position: 'relative' // Important for positioning the pages
            }}
          >
            <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
              {isLoading && renderedPageNumber ? (
                <Page
                  key={renderedPageNumber}
                  className="prevPage"
                  pageNumber={renderedPageNumber}
                  style={{ width: 'auto', maxWidth: '100%', height: 'auto' }}
                />
              ) : null}
              <Page
                key={currentPage}
                pageNumber={currentPage}
                onRenderSuccess={() => setRenderedPageNumber(currentPage)}
                style={{ width: 'auto', maxWidth: '100%', height: 'auto' }}
              />
            </Document>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default PDF;

import React, { useRef, useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react-core';
import { LoadingButton } from '@mui/lab';
import { Box, ButtonGroup, Collapse, Slider } from '@mui/material';
import { API, Auth, Storage, graphqlOperation } from 'aws-amplify';
import { useAppContext } from 'context';
import { useSnackbar } from 'context/SnackBar';
import { updatePicture } from 'graphql/queries';
import { saveImageToIndexedDB } from 'indexedDB';
import mixpanel from 'mixpanel-browser';
import AvatarEditor from 'react-avatar-editor';

import { ErrorMessage, SettingHeader, UploadAvatar } from 'components/atoms';

const ProfilePicture = ({ forClient }) => {
  const { state, dispatch } = useAppContext();
  const { user } = useAuthenticator(context => [context.user]);
  const isClinician = user.attributes['custom:type'] === 'clinician';

  const firstName = state.client?.firstName || user.attributes.given_name;

  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const clientID = state?.client?.id || user.username;
  const client = state?.client;

  const [image, setImage] = useState(null);
  const [scale, setScale] = useState(1);
  const [changePicture, setChangePicture] = useState(false);

  const editorRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  const uploadImageToS3 = async (imageData, key) => {
    try {
      setSaving(true);
      await Storage.put(key, imageData, {
        contentType: 'image/jpeg'
      });
      await API.graphql(
        graphqlOperation(updatePicture, {
          id: clientID,
          picture: true,
          type: isClinician ? (forClient ? 'client' : 'clinician') : 'client'
        })
      );

      mixpanel.track('update_profile_picture');
      if (forClient) {
        dispatch({ type: 'add_client', client: { ...client, picture: true } });
      }
      await Auth.currentAuthenticatedUser({
        bypassCache: true
      });
      enqueueSnackbar(
        forClient
          ? `Updated profile picture for ${firstName}`
          : 'Profile picture updated',
        {
          severity: 'success'
        }
      );
    } catch (error) {
      console.error('Error uploading image: ', error);
      setError('Something went wrong while saving your picture.');
      enqueueSnackbar(
        forClient
          ? `Error updating profile picture for ${firstName}`
          : 'Error updating profile picture',
        {
          severity: 'error'
        }
      );
    } finally {
      setSaving(false);
      setImage(null);
      setChangePicture(false);
    }
  };

  const onImageChange = e => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const reader = new FileReader();
      reader.onload = e => {
        setImage(e.target.result);
      };
      reader.readAsDataURL(files[0]);
    }
  };

  const onLoadSuccess = imgInfo => {
    const { width, height } = imgInfo;
    const frameDimension = 128;
    const scaleWidth = frameDimension / width;
    const scaleHeight = frameDimension / height;
    const initialScale = Math.max(scaleWidth, scaleHeight);
    setScale(initialScale);
  };

  const getCroppedImage = () => {
    if (editorRef.current) {
      const canvas = editorRef.current.getImage();

      // Create a temporary canvas to resize the image
      const tempCanvas = document.createElement('canvas');
      const tempCtx = tempCanvas.getContext('2d');
      const MAX_WIDTH = 100;
      const MAX_HEIGHT = 100;
      tempCanvas.width = MAX_WIDTH;
      tempCanvas.height = MAX_HEIGHT;
      tempCtx.drawImage(canvas, 0, 0, MAX_WIDTH, MAX_HEIGHT);

      // Get the resized image as a blob
      tempCanvas.toBlob(async blob => {
        // Upload to S3
        const key = `profilepictures/${clientID}.jpg`;
        await uploadImageToS3(blob, key);
        await saveImageToIndexedDB(clientID, blob);
        dispatch({ type: 'trigger_avatar_refresh', payload: Date.now() });
      }, 'image/jpeg');
    }
  };

  const profile = {
    id: isClinician && forClient ? client?.id : user.username,
    firstName:
      isClinician && forClient ? client?.firstName : user.attributes.given_name,
    lastName:
      isClinician && forClient ? client?.lastName : user.attributes.family_name,
    clientFirstName: client?.clientFirstName,
    clientLastName: client?.clientLastName,
    picture:
      isClinician && forClient
        ? client?.picture
        : user.attributes.picture === 'true'
  };

  return (
    <Box p={1} my={2}>
      <SettingHeader
        label="Profile Picture"
        state={changePicture}
        setState={setChangePicture}
        customAction={
          changePicture && (
            <Box>
              <ButtonGroup>
                <LoadingButton
                  variant="outlined"
                  onClick={() => setImage(null)}
                  size="small"
                >
                  Clear
                </LoadingButton>
                <LoadingButton
                  size="small"
                  loading={saving}
                  variant="contained"
                  color="primary"
                  onClick={getCroppedImage}
                >
                  Save
                </LoadingButton>
              </ButtonGroup>
            </Box>
          )
        }
      />

      {((changePicture && !image) || !changePicture) && (
        <Box m={2} sx={{ display: 'flex', alignItems: 'center' }}>
          <UploadAvatar
            edit={changePicture}
            onImageChange={onImageChange}
            profile={profile}
          />
        </Box>
      )}

      <Collapse in={changePicture}>
        <Box>
          {changePicture && (
            <Box
              m={2}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              {image && (
                <>
                  <AvatarEditor
                    ref={editorRef}
                    image={image}
                    width={128}
                    height={128}
                    border={[0, 0]}
                    borderRadius={100}
                    color={[0, 14, 33, 0.6]} // RGBA
                    backgroundColor="#000e21"
                    scale={scale}
                    onLoadSuccess={onLoadSuccess}
                  />
                  <Slider
                    orientation="vertical"
                    min={1}
                    max={10}
                    step={0.01}
                    value={scale}
                    onChange={(e, val) => setScale(val)}
                    sx={{ height: 128 }}
                  />
                </>
              )}
            </Box>
          )}

          <Box pt={2}></Box>
          {error && <ErrorMessage message={error} />}
        </Box>
      </Collapse>
    </Box>
  );
};

export default ProfilePicture;

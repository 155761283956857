import {
  Add,
  AdminPanelSettings,
  Contacts,
  Groups2,
  Handshake
} from '@mui/icons-material';
import { Box, Button, Grid, Typography } from '@mui/material';

export default function AdminWelcome({ setOpenAddClinic, clinicID }) {
  return (
    <Box
      height={clinicID ? 0 : '92vh'}
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{
        background: `url('/img/circles.svg') no-repeat center center`,
        backgroundSize: 'cover'
      }}
    >
      <Box sx={{ maxWidth: 500 }} display="flex" flexDirection="column">
        <Typography align="center" color="primary.dark" variant="h4">
          Set up your practice
        </Typography>
        <Grid sx={{ mt: 2 }} container>
          <Grid
            sx={{
              p: 2,
              borderBottom: '1px solid lightgrey',
              borderRight: { xs: 'none', sm: '1px solid lightgrey' }
            }}
            item
            xs={12}
            sm={6}
          >
            <Box display="flex" justifyContent="center">
              <Groups2 />
            </Box>
            <Box mt={1}>
              <Typography variant="body2" align="center">
                Invite Admins, Practice Managers and Clinicians
              </Typography>
            </Box>
          </Grid>
          <Grid
            sx={{ p: 2, borderBottom: '1px solid lightgrey' }}
            item
            xs={12}
            sm={6}
          >
            <Box display="flex" justifyContent="center">
              <Contacts fontSize="small" />
            </Box>
            <Box mt={1}>
              <Typography variant="body2" align="center">
                View Client contacts, filtered by Location or Clinician
              </Typography>
            </Box>
          </Grid>
          <Grid
            sx={{
              p: 2,
              borderBottom: { sm: 'none', xs: '1px solid lightgrey' },
              borderRight: { xs: 'none', sm: '1px solid lightgrey' }
            }}
            item
            xs={12}
            sm={6}
          >
            <Box display="flex" justifyContent="center">
              <Handshake />
            </Box>
            <Box mt={1}>
              <Typography variant="body2" align="center">
                Create Clients and Assign them to Clinicians
              </Typography>
            </Box>
          </Grid>
          <Grid sx={{ p: 2 }} item xs={12} sm={6}>
            <Box display="flex" justifyContent="center">
              <AdminPanelSettings />
            </Box>
            <Box mt={1}>
              <Typography variant="body2" align="center">
                Suspend or Remove Clinicians from your Practice
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Box mt={2}>
          <Typography align="center" variant="body2">
            If you are a clinician, you can request to join an existing practice
            or invite an admin to manage the practice by clicking on the button
            below.
          </Typography>
        </Box>
        <Box sx={{ pt: 2, width: '100%' }}>
          <Button
            fullWidth
            onClick={() =>
              setOpenAddClinic(prevOpenAddClinic => !prevOpenAddClinic)
            }
            variant="contained"
            startIcon={<Add />}
          >
            Add Practice
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

import { GraphicEq } from '@mui/icons-material';
import { Box } from '@mui/material';

import { formatAudioTime } from 'utils/formatTime';

import { CustomFormLabel } from '../CustomFormLabel';

export default function DisplayAudio({ input }) {
  return (
    <Box display="flex" alignItems="center">
      {input?.inputName ? (
        <CustomFormLabel>{input?.inputName}</CustomFormLabel>
      ) : (
        input?.audioMode && (
          <CustomFormLabel>
            {input?.audioMode.toLowerCase().includes('file')
              ? 'Uploaded'
              : 'Recorded'}
          </CustomFormLabel>
        )
      )}

      <Box mx={1} display="flex" alignItems="center" height="50px">
        <GraphicEq />
      </Box>
      <CustomFormLabel>
        {input?.audioDurationSeconds &&
          formatAudioTime(input?.audioDurationSeconds)}
      </CustomFormLabel>
    </Box>
  );
}

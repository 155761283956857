import { List, ListItem, ListItemText, Typography } from '@mui/material';

export const camperdownSteps = [
  {
    label: 'Stage I - Teaching Treatment Components',
    description: (
      <>
        Clients move from Stage I to Stage II of the Camperdown Program when
        they are able to:
        <List>
          <ListItem>
            <ListItemText primary="1. Assign self scores with the Stuttering Severity Scale that are similar to the clinician’s scores" />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="2. Consistently throughout the consultation use a fluency technique that approximates the Training
                            Model to control stuttering, with a stuttering severity score of 0 and a fluency technique score of
                            7–8. It is very important for clients to also report feeling in control of their stuttering."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="3. Recognise various fluency technique scores when demonstrated by the clinician or from
                            recordings at the Australian Stuttering Research Centre website."
            />
          </ListItem>
        </List>
      </>
    )
  },
  {
    label: 'Stage II - Establishing Stutter-Free Speech',
    description: (
      <>
        <Typography>
          At the end of Stage II, clients are encouraged to use their fluency
          technique during their everyday talking. Clients move from Stage II to
          Stage III of the Camperdown Program when an individualised fluency
          technique to control stuttering in conversation has been developed.
          Stage III begins when clients can speak with the clinician for the
          entire consultation at a stuttering severity score of 0–1 and a
          fluency technique score acceptable to the client.
        </Typography>
        <Typography sx={{ mt: 2 }}>
          {' '}
          The number of Stage II consultations will vary depending on factors
          including the client’s stuttering severity pre-treatment, commitment
          to and opportunities for regular practice, and regularity of
          attendance at consultations.
        </Typography>
      </>
    )
  },
  {
    label: 'Stage III - Generalisation',
    description: (
      <>
        <Typography>
          The client moves from Stage III to Stage IV of the Camperdown Program
          when stuttering and fluency technique goals, and any others, are met
          for three consecutive, weekly consultations. Typically, such goals are
          for stuttering severity scores to be around 1 in most everyday
          speaking situations, with no situation avoidance, and fluency
          technique scores that the client finds acceptable.{' '}
        </Typography>
        <Typography sx={{ mt: 2 }}>
          Ultimately, clients decide goals appropriate to their needs. Some
          clients will prioritise reduced stuttering over natural-sounding
          speech while for others sounding natural is most important, even if
          that involves some stuttering. Other clients may be comfortable with
          reduced stuttering when required in certain situations, rather than in
          all situations.
        </Typography>
      </>
    )
  },
  {
    label: 'Stage IV - Maintenance of Treatment Gains',
    description: (
      <>
        <Typography>
          It is important to note that clients will need to continue to manage
          the control of their stuttering and practice regularly to maintain
          treatment gains over the longer term. Continued attendance at
          self-help group meetings can be useful for this. It is common for
          clients after discharge from treatment to need or request further
          treatment at a future time. Stuttering is a relapse-prone disorder and
          for a number of reasons clients may not be able to sustain their focus
          on their fluency technique at some times in their lives. This should
          be discussed with the client before discharge and clients should be
          encouraged to seek support from a speech pathologist at any time in
          the future.
        </Typography>
        <Typography sx={{ mt: 2 }}>
          Discharge from treatment will be negotiated between clinician and
          client when they can demonstrate:
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="1. Skills for monitoring their speech and controlling their stuttering" />
          </ListItem>
          <ListItem>
            <ListItemText primary="2. An ability to address fluctuations in stuttering severity" />
          </ListItem>
          <ListItem>
            <ListItemText primary="3. Achievement of personalised goals for stuttering treatment" />
          </ListItem>
        </List>
      </>
    )
  }
];

import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';
import { Card, CardHeader, Grid, IconButton } from '@mui/material';

import { ClientName, ClientNameSub, ProfileAvatar } from 'components/atoms';

export default function ClientSelectCard({
  onSelect,
  selected,
  client,
  visible
}) {
  return (
    <Grid
      item
      xs={12}
      sx={{ width: '100%', display: visible ? 'block' : 'none' }}
    >
      <Card elevation={0} sx={{ backgroundColor: 'background.default', p: 1 }}>
        <CardHeader
          sx={{ display: 'flex' }}
          disableTypography
          title={<ClientName client={client} />}
          avatar={<ProfileAvatar profile={client} />}
          subheader={<ClientNameSub client={client} />}
          action={
            selected ? (
              <IconButton onClick={() => onSelect(client)}>
                <RadioButtonChecked />
              </IconButton>
            ) : (
              <IconButton onClick={() => onSelect(client)}>
                <RadioButtonUnchecked />
              </IconButton>
            )
          }
        />
      </Card>
    </Grid>
  );
}

import { memo, useCallback, useState } from 'react';

import { DividerButton } from 'components/atoms';

export const AddEntrySectionButton = memo(function ({
  index,
  onAddEntrySection
}) {
  const [loading, setLoading] = useState(false);

  const handleClick = useCallback(async () => {
    setLoading(true);
    try {
      await onAddEntrySection(index);
    } finally {
      setLoading(false);
    }
  }, [index, onAddEntrySection]);

  return <DividerButton loading={loading} onClick={handleClick} />;
});

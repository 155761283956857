import { Box, Divider, Grid, Typography } from '@mui/material';

const RatingGuideSUDS = () => {
  const descriptions = [
    {
      title: '0',
      description: 'Peace and complete calm'
    },
    {
      title: '1',
      description: 'No distress; alert and focused'
    },
    {
      title: '2',
      description: 'A little bit sad or distressed'
    },
    {
      title: '3',
      description: 'Worried or upset; still able to function'
    },
    {
      title: '4',
      description: 'Mild to moderate anxiety and worry'
    },
    {
      title: '5',
      description: 'Upset and uncomfortable; still functional'
    },
    {
      title: '6',
      description: 'Moderate to strong levels of discomfort'
    },
    {
      title: '7',
      description:
        'Discomfort dominates your thoughts and you struggle to function normally'
    },
    {
      title: '8',
      description:
        'Worried and panicky; losing focus and feeling anxious in the body'
    },
    {
      title: '9',
      description:
        'Extremely anxious and desperate, helpless and unable to handle it'
    },
    {
      title: '10',
      description:
        'Unbearably upset to the point that you cannot function and may be on the verge of a breakdown'
    }
  ];

  return (
    <Box>
      {descriptions.map((description, index) => (
        <Grid container key={index}>
          <Grid item xs={2} sm={1}>
            <Typography variant="caption">
              <strong>{description.title}</strong>
            </Typography>
          </Grid>
          <Grid item xs={10} sm={11}>
            <Typography variant="caption">{description.description}</Typography>
          </Grid>
          {index !== 10 && (
            <Grid item xs={12}>
              <Box pt={0.5}>
                <Divider sx={{ opacity: 0.3 }} />
              </Box>
            </Grid>
          )}
        </Grid>
      ))}
    </Box>
  );
};

export default RatingGuideSUDS;

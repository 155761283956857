import { useState } from 'react';

import { Check } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Alert, AlertTitle, Box, Link } from '@mui/material';
import { API, Storage, graphqlOperation } from 'aws-amplify';
import { constants } from 'common/constants';
import { useAppContext } from 'context';
import { useSnackbar } from 'context/SnackBar';
import { exportClientData } from 'graphql/queries';

import { ErrorMessage, SettingHeader } from 'components/atoms';

import Modal from '../Modal';

export default function ExportClient() {
  const [openExport, setOpenExport] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const { state } = useAppContext();
  const [error, setError] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const handleDownload = async () => {
    try {
      const variables = {
        clientID: state?.client?.id,
        clientLastName: state?.client?.lastName
      };
      setLoading(true);
      const { data } = await API.graphql(
        graphqlOperation(exportClientData, variables)
      );
      const key = `exports/export-${state?.client?.lastName}-${state?.client?.id}.xlsx`;
      const parsedRes = JSON.parse(data.exportClientData);
      if (parsedRes.statusCode === 200) {
        const { Body } = await Storage.get(key, { download: true });
        const blob = new Blob([Body], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `export-${state?.client?.lastName}-${state?.client?.id}.xlsx`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);

        setLoaded(true);
        enqueueSnackbar(
          `Successfully exported ${state?.client?.firstName}'s data`,
          {
            severity: 'success'
          }
        );
      } else {
        setError(parsedRes.error);
      }
    } catch (err) {
      console.log(err);
      enqueueSnackbar(`Error exporting ${state?.client?.firstName}'s data`, {
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <Box p={1} my={2}>
      <SettingHeader
        state={openExport}
        setState={setOpenExport}
        label="Export Client Data"
        button="Export"
      />
      <Modal
        open={openExport || false}
        setClose={() => setOpenExport(false)}
        title="Export"
        maxWidth="sm"
      >
        <Box>
          <Box p={1} display="flex" flexDirection="column">
            <Alert severity="warning">
              <AlertTitle>Caution: Exporting Client Data</AlertTitle>
              Please note that, in agreement with our{' '}
              <Link
                href={constants.termsUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms and Conditions
              </Link>
              , once you receive a download link to export your client's data,
              you take full responsibilities over securing this link along with
              your client's data.
            </Alert>
            <Box my={2}>
              <LoadingButton
                onClick={handleDownload}
                disabled={loaded}
                endIcon={loaded && <Check color="primary" />}
                variant="contained"
                fullWidth
                loading={loading}
                size="small"
              >
                {loaded ? 'Exported' : 'I understand, export now'}
              </LoadingButton>
            </Box>
            <Box my={2}>
              {Boolean(error) && <ErrorMessage message={error} />}
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

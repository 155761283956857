import { useState } from 'react';

import { Close } from '@mui/icons-material';
import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material';

import ClientName from '../ClientName';
import ProfileAvatar from '../ProfileAvatar';

export default function Participant({
  participant,
  removeParticipant,
  invited,
  removeInvitation
}) {
  const [removing, setRemoving] = useState(false);

  const handleRemoveParticipant = async () => {
    try {
      setRemoving(true);
      await removeParticipant(participant);
    } catch (e) {
      console.log(e);
    } finally {
      setRemoving(false);
    }
  };
  const handleRemoveInvitation = async () => {
    try {
      setRemoving(true);
      await removeInvitation(participant);
    } catch (e) {
      console.log(e);
    } finally {
      setRemoving(false);
    }
  };
  return (
    <Grid key={participant.id} item xs={12} md={6}>
      <Card elevation={4}>
        <CardHeader
          avatar={<ProfileAvatar profile={participant} />}
          title={<ClientName client={participant} />}
          subheader={participant.role}
          action={
            <Tooltip
              title={
                invited ? 'Remove this invitation' : 'Remove this participant'
              }
              placement="top"
            >
              <IconButton
                onClick={
                  invited ? handleRemoveInvitation : handleRemoveParticipant
                }
                disabled={removing}
              >
                {removing ? <CircularProgress size={24} /> : <Close />}
              </IconButton>
            </Tooltip>
          }
        />
        <CardContent>
          <Typography variant="h6">{participant.email}</Typography>
        </CardContent>
      </Card>
    </Grid>
  );
}

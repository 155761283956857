import React, { useState } from 'react';

import {
  Close,
  Delete,
  DragIndicator,
  Info,
  Settings
} from '@mui/icons-material';
import {
  Box,
  Card,
  Collapse,
  Hidden,
  IconButton,
  InputLabel,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimeClock } from '@mui/x-date-pickers/TimeClock';
import { useAppContext } from 'context';
import dayjs from 'dayjs';
import { useDebouncedCallback } from 'use-debounce';

import InfoSection from '../InfoSection';

const ClockSettings = ({ onSettingChange, settings, setShowSettings }) => {
  return (
    <Card
      elevation={0}
      sx={{ my: 1, px: 1, backgroundColor: 'background.settings' }}
    >
      <Stack
        direction="row"
        spacing={1}
        px={1}
        py={1}
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        <IconButton
          size="small"
          color="tertiary"
          onClick={() => setShowSettings(prev => !prev)}
        >
          <Close fontSize="small" />
        </IconButton>
        <InputLabel>Clock Settings</InputLabel>
      </Stack>
      <Stack direction="row" spacing={1}>
        <TextField
          color="tertiary"
          variant="filled"
          size="small"
          label="Name"
          value={settings.name}
          onChange={event => onSettingChange?.('name', event.target.value)}
        />
      </Stack>
    </Card>
  );
};

const Clock = ({
  userInputID,
  component,
  dragHandleProps,
  initialData,
  name,
  from,
  to,
  onDelete,
  onDataChange,
  onSettingChange
}) => {
  const { state } = useAppContext();
  const [settings, setSettings] = useState({ name, from, to });
  const [showSettings, setShowSettings] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);
  const [currentValue, setCurrentValue] = useState(
    dayjs().set('minute', initialData ?? 0)
  );

  const handleSettingChange = (settingName, settingValue) => {
    setSettings(prev => ({
      ...prev,
      [settingName]: settingValue
    }));

    onSettingChange?.(settingName, settingValue);
  };

  const debouncedDatabaseUpdate = useDebouncedCallback(newValue => {
    onDataChange?.(newValue, userInputID);
  }, 500);

  const handleTimeChange = newValue => {
    const minutesValue = dayjs(newValue).minute();
    setCurrentValue(dayjs(newValue));
    debouncedDatabaseUpdate(minutesValue);
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Stack
          width="100%"
          direction="row"
          spacing={1}
          sx={{ alignItems: 'center' }}
        >
          {state.edit && (
            <IconButton
              onClick={() => setShowSettings(prev => !prev)}
              size="small"
            >
              <Settings
                size="small"
                color={showSettings ? 'secondary' : 'primary'}
              />
            </IconButton>
          )}
          <Stack
            alignItems="center"
            width="100%"
            direction="row"
            spacing={2}
            justifyContent="space-between"
          >
            <Typography variant="h5"> {settings.name}</Typography>
            {component?.showInfo !== false && (
              <IconButton
                size="large"
                sx={{ ml: 1 }}
                onClick={() => setOpenInfo(prevOpenInfo => !prevOpenInfo)}
                color={openInfo ? 'secondary' : 'grey'}
              >
                <Info fontSize="large" />
              </IconButton>
            )}
          </Stack>
          {state.edit && settings.info && (
            <IconButton
              color={openInfo ? 'secondary' : 'default'}
              size="small"
              onClick={e => setOpenInfo(prevOpenInfo => !prevOpenInfo)}
            >
              <Info />
            </IconButton>
          )}
        </Stack>
        {state.edit && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton onClick={onDelete}>
              <Delete />
            </IconButton>
            <IconButton {...dragHandleProps}>
              <DragIndicator />
            </IconButton>
          </Box>
        )}
      </Box>
      <Collapse in={state.edit && showSettings}>
        <Hidden smDown implementation="css">
          <ClockSettings
            onSettingChange={handleSettingChange}
            settings={settings}
            setShowSettings={setShowSettings}
          />
        </Hidden>
      </Collapse>
      <Collapse in={openInfo}>
        <InfoSection component={component} />
      </Collapse>
      <Box mt={2} width="fit-content">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimeClock
            sx={{ width: '100%' }}
            value={currentValue}
            onChange={handleTimeChange}
            views={['minutes']}
            ampm={false}
          />
        </LocalizationProvider>
      </Box>
    </Box>
  );
};

export default Clock;

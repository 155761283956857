import { memo, useCallback, useEffect, useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { Check } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  AlertTitle,
  Box,
  CircularProgress,
  FormControlLabel,
  FormLabel,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Switch,
  Tooltip,
  Typography
} from '@mui/material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import { useSnackbar } from 'context/SnackBar';
import { deviceInterface } from 'graphql/queries';
import mixpanel from 'mixpanel-browser';
import QRCode from 'react-qr-code';

import { AuthCode, Section } from 'components/atoms';

import { formatDateTime } from 'utils/formatTime';

function Security() {
  const { user } = useAuthenticator(context => [context.user]);
  const isGoogle = user.username.includes('google');
  const mfaType = user.preferredMFA;
  const [mfa, setMFA] = useState(mfaType === 'NOMFA' ? false : true);
  const [qrCodeValue, setQrCodeValue] = useState(null);
  const [open, setOpen] = useState(false);
  const [totpCode, setTotpCode] = useState('');
  const [activeStep, setActiveStep] = useState(0);
  const [verifying, setVerifying] = useState(false);
  const [remembering, setRemembering] = useState(false);
  const [remembered, setRemembered] = useState(false);
  const [forgetting, setForgetting] = useState(false);
  const [forgotten, setForgotten] = useState(false);
  const [errors, setErrors] = useState({});
  const [submissionAttempted, setSubmissionAttempted] = useState(false);
  const [error, setError] = useState(null);
  const [devices, setDevices] = useState([]);
  const [loadingDevices, setLoadingDevices] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  const steps = ['Scan QR Code', 'Enter TOTP Code'];

  const validateTotp = value => {
    if (!value) {
      setErrors({ totpCode: 'Please add a code.' });
      return false;
    } else if (value.length < 6) {
      setErrors({ totpCode: 'Code should be 6 characters long.' });
      return false;
    } else {
      setErrors({});
      return true;
    }
  };

  const handleSwitchChange = async () => {
    const currentUser = await Auth.currentAuthenticatedUser();
    if (!mfa) {
      try {
        const setupInfo = await Auth.setupTOTP(currentUser);
        const totpUri = `otpauth://totp/${encodeURIComponent(
          'SpeechFit'
        )}:${encodeURIComponent(
          user.attributes.email
        )}?secret=${setupInfo}&issuer=${encodeURIComponent('SpeechFit')}`;
        setQrCodeValue(totpUri);
        setOpen(true);
      } catch (error) {
        enqueueSnackbar(`Error changing 2FA status`, {
          severity: 'error'
        });
        console.error('Failed to setup TOTP', error);
      }
    } else {
      try {
        await Auth.setPreferredMFA(currentUser, 'NOMFA');
        setMFA(false);
        mixpanel.track('deactivate_2fA');
        enqueueSnackbar(`Disabled 2FA`, {
          severity: 'success'
        });
      } catch (error) {
        console.error('Failed to disable TOTP MFA', error);
      }
    }
  };

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleVerify = async () => {
    setSubmissionAttempted(true);
    if (validateTotp(totpCode)) {
      try {
        setVerifying(true);
        await completeVerification();
      } catch (err) {
        console.log(err.message);
        setVerifying(false);
      }
    }
  };

  useEffect(() => {
    let timer;
    if (!open) {
      timer = setTimeout(() => {
        setActiveStep(0);
      }, 750);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [open]);

  const handleCancel = () => {
    setActiveStep(0);
    setTotpCode('');
    setOpen(false);
  };

  const completeVerification = async () => {
    if (submissionAttempted || errors.totpCode) {
      validateTotp(totpCode);
    }
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.verifyTotpToken(user, totpCode);
      await Auth.setPreferredMFA(user, 'TOTP');
      setMFA(true);
      setOpen(false);
      setTotpCode('');
      setQrCodeValue(null);
      mixpanel.track('activate_2fA');
      enqueueSnackbar(`Enabled 2FA`, {
        severity: 'success'
      });
    } catch (error) {
      console.error('Failed to verify TOTP Token', error);
      setError(error.message);
    } finally {
      setVerifying(false);
    }
  };

  const fetchDevices = useCallback(async () => {
    try {
      setLoadingDevices(true);
      const { data } = await API.graphql(
        graphqlOperation(deviceInterface, {
          username: user.username,
          action: 'list'
        })
      );
      const parsedRes = JSON.parse(data.deviceInterface);
      setDevices(parsedRes.body.Devices);
      setLoadingDevices(false);
    } catch (err) {
      console.log('Error fetching devices', err);
    }
  }, [user.username]);

  useEffect(() => {
    fetchDevices();
  }, [fetchDevices]);

  async function rememberDevice() {
    try {
      setRemembered(false);
      setRemembering(true);
      await Auth.rememberDevice();
      mixpanel.track('remember_device');
      await fetchDevices();
      setRemembered(true);
    } catch (error) {
      setError(error.message);
    } finally {
      setRemembering(false);
    }
  }

  async function forgetDevice() {
    try {
      setForgotten(false);
      setForgetting(true);
      await Auth.forgetDevice();
      mixpanel.track('forget_device');
      setForgetting(false);
      setForgotten(true);
    } catch (error) {
      setError(error.message);
    }
  }

  const completeCode = code => {
    setTotpCode(code);
    if (submissionAttempted || errors.totpCode) {
      validateTotp(code);
    }
  };

  useEffect(() => {
    setError(null);
  }, [totpCode]);

  const DeviceInfo = ({ label, value }) => (
    <Grid sx={{ display: 'flex', flexDirection: 'column' }} item sm={6}>
      <FormLabel sx={{ fontSize: 'small' }}>{label}</FormLabel>
      <Typography sx={{ fontSize: 'small' }}>{value}</Typography>
    </Grid>
  );

  const DeviceAttribute = ({ attributes }) => (
    <Grid
      key={attributes.Name}
      item
      xs={12}
      sm={
        attributes.Name === 'last_ip_used' ||
        attributes.Name === 'dev:device_remembered_status'
          ? 6
          : 12
      }
    >
      <FormLabel sx={{ fontSize: 'small' }}>
        {attributes.Name === 'device_name' && 'Device Name'}
        {attributes.Name === 'last_ip_used' && 'Last IP Used'}
        {attributes.Name === 'dev:device_remembered_status' &&
          'Remembered Status'}
      </FormLabel>
      <Typography sx={{ fontSize: 'small' }}>
        {attributes.Name === 'device_name' && attributes.Value}
        {attributes.Name === 'last_ip_used' && attributes.Value}
        {attributes.Name === 'dev:device_remembered_status' &&
          (attributes.Value === 'remembered' ? 'Remembered' : 'Not Remembered')}
      </Typography>
    </Grid>
  );
  const DeviceGrid = ({ devices }) => {
    if (devices.length === 0) return null;

    return (
      <>
        {devices.map((device, index) => (
          <Grid sx={{ mt: 2, mb: 4 }} key={`key-${index}`} container>
            <DeviceInfo
              label="Last Authenticated"
              value={formatDateTime(device.DeviceLastAuthenticatedDate)}
            />
            <DeviceInfo
              label="Last Modified"
              value={formatDateTime(device.DeviceLastModifiedDate)}
            />
            {device.DeviceAttributes.map((attributes, index) => (
              <DeviceAttribute key={`key-${index}`} attributes={attributes} />
            ))}
          </Grid>
        ))}
      </>
    );
  };

  return (
    <Grid item xs={12} sm={6}>
      <Section
        label="2FA"
        info="This will require you to use an app like Google Authenticator or Authy to generate a time-based one time password at login. You can remember the device on computers that you deem secure."
      >
        {isGoogle ? (
          <Tooltip title="2FA is not available when logging in with Google">
            <span>
              <FormControlLabel
                disabled={isGoogle}
                control={<Switch checked={mfa} onChange={handleSwitchChange} />}
                label="Enable TOTP MFA"
              />
            </span>
          </Tooltip>
        ) : (
          <FormControlLabel
            control={<Switch checked={mfa} onChange={handleSwitchChange} />}
            label="Enable TOTP MFA"
          />
        )}
      </Section>

      <Box>
        {mfa && (
          <Box>
            <Section
              label="Remember This Device"
              info="This will allow you to bypass MFA when logging in on this device. Do not enable this if you are using a shared device."
            >
              <LoadingButton
                size="small"
                startIcon={remembered && <Check />}
                variant="contained"
                fullWidth
                loading={remembering}
                onClick={rememberDevice}
              >
                Remember device
              </LoadingButton>
            </Section>
            <Section
              label="Forget This Device"
              info={`This will "Forget" the device. We'll still track it for security reasons, but you'll be asked to use MFA with the device.`}
            >
              <LoadingButton
                size="small"
                startIcon={forgotten && <Check />}
                variant="contained"
                fullWidth
                loading={forgetting}
                onClick={forgetDevice}
              >
                Forget device
              </LoadingButton>
            </Section>
          </Box>
        )}
        {isGoogle && (
          <Section
            label="Devices"
            info={
              isGoogle
                ? 'Since you are logging in with a Google account, use your Google security settings to see logged in devices.'
                : 'These are the devices and IP addresses that your account has been signed into with.'
            }
          >
            {loadingDevices ? (
              <Box
                minHeight={100}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <CircularProgress size={20} />
              </Box>
            ) : (
              devices.length > 0 && <DeviceGrid devices={devices} />
            )}
          </Section>
        )}
      </Box>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>Setup TOTP</DialogTitle>
        <DialogContent>
          <Stepper activeStep={activeStep}>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          <Box
            pt={2.5}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {activeStep === 0 && open && qrCodeValue && (
              <Box display="flex" flexDirection="column" alignItems="center">
                <Typography sx={{ mb: 3 }} fontSize="small">
                  Use an app like Google Authenticator or Authy to scan the
                  code.
                </Typography>
                <QRCode value={qrCodeValue} />
              </Box>
            )}
            {activeStep === 1 && (
              <Box>
                <AuthCode
                  error={!!errors.totpCode}
                  helperText={errors.totpCode}
                  length={6}
                  onComplete={code => {
                    completeCode(code);
                  }}
                />
                {error && (
                  <Alert severity="error">
                    <AlertTitle>{error}</AlertTitle>Please try again.
                  </Alert>
                )}
              </Box>
            )}
          </Box>
        </DialogContent>

        <DialogActions
          sx={{ display: 'flex', justifyContent: 'space-between' }}
        >
          {activeStep === 0 ? (
            <Button onClick={() => handleCancel()} color="primary">
              Cancel
            </Button>
          ) : (
            <Button onClick={handleBack} color="primary">
              Back
            </Button>
          )}
          {activeStep === steps.length - 1 ? (
            <LoadingButton
              size="small"
              loading={verifying}
              onClick={() => handleVerify()}
              color="primary"
            >
              Verify
            </LoadingButton>
          ) : (
            <Button onClick={handleNext} color="primary">
              Next
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

export default memo(Security);

import {
  Brightness2,
  Brightness2Outlined,
  Brightness6,
  Brightness6Outlined,
  Brightness7,
  Brightness7Outlined,
  Casino,
  CasinoOutlined,
  CheckBox,
  CheckBoxOutlined,
  DoNotDisturbOn,
  DoNotDisturbOnOutlined,
  Forum,
  ForumOutlined,
  LocalLibrary,
  LocalLibraryOutlined
} from '@mui/icons-material';

export default function getEmoji(label, filled, small) {
  switch (label.toLowerCase()) {
    case 'morning':
      if (filled) {
        return (
          <Brightness6 color="primary" sx={{ fontSize: small ? 32 : 40 }} />
        );
      } else {
        return (
          <Brightness6Outlined
            color="primary"
            sx={{ fontSize: small ? 32 : 40, opacity: 0.3 }}
          />
        );
      }
    case 'afternoon':
      if (filled) {
        return (
          <Brightness7 color="primary" sx={{ fontSize: small ? 32 : 40 }} />
        );
      } else {
        return (
          <Brightness7Outlined
            color="primary"
            sx={{ fontSize: small ? 32 : 40, opacity: 0.3 }}
          />
        );
      }
    case 'evening':
      if (filled) {
        return (
          <Brightness2 color="primary" sx={{ fontSize: small ? 32 : 40 }} />
        );
      } else {
        return (
          <Brightness2Outlined
            color="primary"
            sx={{ fontSize: small ? 32 : 40, opacity: 0.3 }}
          />
        );
      }
    case 'book':
      if (filled) {
        return (
          <LocalLibrary color="primary" sx={{ fontSize: small ? 32 : 40 }} />
        );
      } else {
        return (
          <LocalLibraryOutlined
            color="primary"
            sx={{ fontSize: small ? 32 : 40, opacity: 0.3 }}
          />
        );
      }
    case 'game':
      if (filled) {
        return <Casino color="primary" sx={{ fontSize: small ? 32 : 40 }} />;
      } else {
        return (
          <CasinoOutlined
            color="primary"
            sx={{ fontSize: small ? 32 : 40, opacity: 0.3 }}
          />
        );
      }
    case 'other':
      if (filled) {
        return <Forum color="primary" sx={{ fontSize: small ? 32 : 40 }} />;
      } else {
        return (
          <ForumOutlined
            color="primary"
            sx={{ fontSize: small ? 32 : 40, opacity: 0.3 }}
          />
        );
      }
    case 'none':
      if (filled) {
        return (
          <DoNotDisturbOn color="primary" sx={{ fontSize: small ? 32 : 40 }} />
        );
      } else {
        return (
          <DoNotDisturbOnOutlined
            color="primary"
            sx={{ fontSize: small ? 32 : 40, opacity: 0.3 }}
          />
        );
      }
    default:
      if (filled) {
        return <CheckBox color="primary" sx={{ fontSize: small ? 32 : 40 }} />;
      } else {
        return (
          <CheckBoxOutlined
            color="primary"
            sx={{ fontSize: small ? 32 : 40, opacity: 0.3 }}
          />
        );
      }
  }
}

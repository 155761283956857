import { Box, FormControlLabel } from '@mui/material';

import getEmoji from 'components/molecules/CheckBox/emojimap';

export default function DisplayCheckbox({ input }) {
  return (
    <Box display="flex" alignItems="center" height="50px">
      <Box display="flex" alignItems="center" sx={{ ml: 2 }}>
        {input?.checkboxesValue &&
          input?.checkboxesValue?.map(checkbox => (
            <FormControlLabel
              key={checkbox.id}
              control={getEmoji(checkbox.label, true, true)}
              label={checkbox.label}
              sx={{
                '& .MuiFormControlLabel-label': { ml: 1 } // Add more space as needed
              }}
            />
          ))}
      </Box>
    </Box>
  );
}

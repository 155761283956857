import { DateTime } from 'luxon';

const datePatterns = [
  // Day / Month / Year formats
  /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/,
  /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{2}$/,
  /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-(19|20)\d{2}$/,
  /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.(19|20)\d{2}$/,
  /^(0[1-9]|[12][0-9]|3[01]) (0[1-9]|1[0-2]) (19|20)\d{2}$/,

  // Month / Day / Year formats
  /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/(19|20)\d{2}$/,
  /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{2}$/,
  /^(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])-(19|20)\d{2}$/,
  /^(0[1-9]|1[0-2])\.(0[1-9]|[12][0-9]|3[01])\.(19|20)\d{2}$/,
  /^(0[1-9]|1[0-2]) (0[1-9]|[12][0-9]|3[01]) (19|20)\d{2}$/,

  // Year / Month / Day formats
  /^(19|20)\d{2}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/,
  /^(19|20)\d{2}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/,
  /^(19|20)\d{2}\.(0[1-9]|1[0-2])\.(0[1-9]|[12][0-9]|3[01])$/,
  /^(19|20)\d{2} (0[1-9]|1[0-2]) (0[1-9]|[12][0-9]|3[01])$/,

  // Other formats (like ISO, UTC)
  /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/, // ISO String
  /^[MonTuesWedThursFriSatSun]{3}, \d{2} [JanFebMarAprMayJunJulAugSepOctNovDec]{3} \d{4} \d{2}:\d{2}:\d{2} GMT$/, // UTC String
  /^\w{3} \w{3} \d{2} \d{4} \d{2}:\d{2}:\d{2} GMT[+-]\d{4} \(\w{3}([+-]\d{2}:\d{2})?\)$/ // e.g., Mon Jul 17 2023 10:52:39 GMT+1000 (GMT+10:00)

  // Add any other patterns here...
];

export function isDate(value) {
  return datePatterns.some(pattern => pattern.test(value));
}

export function formatDate(dateStr) {
  let dt;

  // Use Luxon's fromISO method for ISO strings
  if (DateTime.fromISO(dateStr).isValid) {
    dt = DateTime.fromISO(dateStr);
  }

  // Use fromJSDate for JavaScript Date string format
  else if (
    /^\w{3} \w{3} \d{2} \d{4} \d{2}:\d{2}:\d{2} GMT[+-]\d{4} \(\w{3}([+-]\d{2}:\d{2})?\)$/.test(
      dateStr
    )
  ) {
    const jsDate = new Date(dateStr);
    dt = DateTime.fromJSDate(jsDate);
  }

  // If it's not one of the above formats, use fromRFC2822 which covers a broad range of date strings
  else if (DateTime.fromRFC2822(dateStr).isValid) {
    dt = DateTime.fromRFC2822(dateStr);
  }

  // Lastly, use fromSQL for formats like "YYYY-MM-DD HH:mm"
  else if (DateTime.fromSQL(dateStr).isValid) {
    dt = DateTime.fromSQL(dateStr);
  }

  // If none of the above methods worked, return the original string
  if (!dt || !dt.isValid) {
    return dateStr;
  }

  // Convert to desired format
  return dt.toFormat('dd/MM/yyyy');
}

import { useEffect, useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { Check, Close } from '@mui/icons-material';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'context/SnackBar';
import { getClient, getInvitation } from 'graphql/queries';
import { useParams } from 'react-router-dom';

import { ActionButton, BasicUserCard, ErrorMessage } from 'components/atoms';
import {
  handleInvitationError,
  performLinkingAction,
  useFetchData
} from 'components/molecules/LinkAccounts';

import { extractData, linkAction } from 'utils/linkingUtils';

export default function LinkClient() {
  const { user } = useAuthenticator(context => [context.user]);
  const clinicID = user.attributes['custom:clinicID'];
  const locationID = user.attributes['custom:locationID'];
  const fetchData = useFetchData();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  // State variables
  const [client, setClient] = useState(null);
  const [confirming, setConfirming] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [rejecting, setRejecting] = useState(false);
  const [rejected, setRejected] = useState(false);
  const [error, setError] = useState(null);
  const [noMatch, setNoMatch] = useState(false);
  const [invitation, setInvitation] = useState(null);
  const [expired, setExpired] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchInvitation = async () => {
      try {
        const data = await fetchData(getInvitation, { id });
        if (data) {
          setInvitation(data.getInvitation);
        }
      } catch (e) {
        setLoading(false);
        setError('No invitation found.');
        console.error(e);
      }
    };

    if (id) {
      fetchInvitation();
    }
    return () => {
      setInvitation(null);
    };
  }, [id, fetchData]);

  useEffect(() => {
    const checkInvitationDetails = async () => {
      try {
        if (!invitation || !user) return;
        const isExpired =
          new Date() - new Date(invitation.createdAt) > 72 * 60 * 60 * 1000;
        setExpired(isExpired);
        if (invitation.clinicID) {
          setLoading(false);
        }
        if (isExpired) {
          setLoading(false);
        } else {
          const isMatch = user.username === invitation.clinicianID;
          setNoMatch(!isMatch);
          if (!isMatch) {
            setLoading(false);
          } else if (isMatch && invitation.clientID) {
            const data = await fetchData(getClient, {
              id: invitation.clientID
            });
            if (data) {
              setClient(data.getClient);
              setLoading(false);
            }
          }
        }
      } catch (e) {
        console.error(e);
      }
    };

    checkInvitationDetails();
    return () => {
      setClient(null);
      setExpired(true);
      setNoMatch(false);
    };
  }, [invitation, user, fetchData]);

  const performAction = async (action, setInProgress, setResult) => {
    const executeAction = await linkAction({
      action,
      source: 'clinician',
      performLinkingAction,
      setInProgress,
      setResult,
      setError,
      handleInvitationError,
      invitationID: invitation.id,
      clientData: extractData(client, 'client'),
      clinicianData: extractData(user, 'clinician'),
      locationID,
      clinicID
    });
    if (executeAction === true) {
      if (action.toLowerCase().includes('confirm')) {
        enqueueSnackbar('Accepted invitation', { severity: 'success' });
      } else {
        enqueueSnackbar('Rejected invitation', { severity: 'success' });
      }
    }
  };

  const renderContent = () => {
    if (!user || !id || noMatch)
      return (
        <ErrorMessage message="Something is wrong with your linking code" />
      );
    return (
      <Box>
        <Typography variant="h5">
          A client wants to give you access to their account.
        </Typography>

        {client && (
          <Box my={2}>
            <BasicUserCard
              profile={client}
              multipleActions={
                <Stack direction="column">
                  <ActionButton
                    tooltip="Accept Invitation"
                    color="success"
                    disabled={confirmed || rejected || invitation?.used}
                    loading={confirming}
                    onClick={() =>
                      performAction(
                        'CONFIRM_client_clinician_link',
                        setConfirming,
                        setConfirmed
                      )
                    }
                    label={<Check color={rejected ? 'default' : 'success'} />}
                  />
                  <ActionButton
                    tooltip="Reject Invitation"
                    color="error"
                    disabled={confirmed || rejected || invitation?.used}
                    loading={rejecting}
                    onClick={() =>
                      performAction(
                        'REJECT_client_clinician_link',
                        setRejecting,
                        setRejected
                      )
                    }
                    label={<Close color={confirmed ? 'default' : 'error'} />}
                  />
                </Stack>
              }
            />
          </Box>
        )}
        {expired ||
          (invitation?.used && (
            <ErrorMessage noBody={true} message="This invitation has expired" />
          ))}

        {error && <ErrorMessage message={error} />}
      </Box>
    );
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="80vh"
    >
      {loading ? <CircularProgress /> : renderContent()}
    </Box>
  );
}

import { Box, CircularProgress, FormLabel, Stack } from '@mui/material';
import { Cell, Legend, PieChart as PC, Pie, Tooltip } from 'recharts';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export default function PieChart({ data, name, type, error }) {
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      {!data || error ? (
        <Box
          minHeight={500}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          <PC width={300} height={500}>
            {Array.isArray(data) && (
              <Pie
                data={data}
                cx="50%"
                cy="50%"
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={150}
                fill="#8884d8"
                dataKey="value"
              >
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
            )}
            <Tooltip />
            <Legend verticalAlign="bottom" />
          </PC>
          <Stack sx={{ mt: 2 }} alignItems="center">
            <FormLabel>{name}</FormLabel>
          </Stack>
        </Box>
      )}
    </Box>
  );
}

import { useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  DialogActions,
  Step,
  StepLabel,
  Stepper
} from '@mui/material';
import { API, Storage, graphqlOperation } from 'aws-amplify';
import { useSnackbar } from 'context/SnackBar';
import { createResource, createResourceContent } from 'graphql/mutations';
import uuid from 'react-uuid';
import * as yup from 'yup';

import { Modal } from 'components/molecules';

import StepOne from './Step1';
import StepTwo from './Step2';
import StepThree from './Step3';

const stepSchemas = {
  0: yup.object().shape({
    title: yup.string().required('Title is required'),
    type: yup.string().required('Type is required'),
    category: yup.string().required('Category is required'),
    sourceName: yup.string().required('Source Name is required'),
    image: yup
      .object()
      .nullable()
      .when('type', {
        is: val => {
          return val !== 'text';
        },
        then: () => yup.object().required('Image is required')
      })
  }),

  1: yup.object().shape({
    type: yup.string().required(),
    videoLink: yup.string().when('type', {
      is: 'video',
      then: () => yup.string().required('Video Link is required')
    }),
    textContent: yup.string().when('type', {
      is: 'text',
      then: () => yup.string().required('Text Content is required')
    }),
    contentImage: yup
      .object()
      .nullable()
      .when('type', {
        is: 'image',
        then: () => yup.object().required('Content Image is required')
      }),
    pdf: yup.mixed().when('type', {
      is: 'pdf',
      then: () => yup.mixed().required('PDF is required')
    })
  })
};

export default function AddResource({ state, setState }) {
  const { user } = useAuthenticator(context => [context.user]);
  const [creating, setCreating] = useState(false);
  const [submissionAttempted, setSubmissionAttempted] = useState(false);
  const [error, setError] = useState(null);
  const resourceID = uuid();
  const resourceContentID = uuid();
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [accessType, setAccessType] = useState('public');
  const [condition, setCondition] = useState(null);
  const [category, setCategory] = useState('');
  const [type, setType] = useState(null);
  const [program, setProgram] = useState(null);
  const [sourceName, setSourceName] = useState('');
  const [sourceLink, setSourceLink] = useState('');
  const [image, setImage] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState('');
  const [activeStep, setActiveStep] = useState(0);
  const [videoLink, setVideoLink] = useState('');
  const [errors, setErrors] = useState({});
  const [textContent, setTextContent] = useState(
    JSON.stringify({
      root: {
        children: [
          {
            children: [],
            direction: null,
            format: '',
            indent: 0,
            type: 'paragraph',
            version: 1
          }
        ],
        direction: null,
        format: '',
        indent: 0,
        type: 'root',
        version: 1
      }
    })
  );
  const [pdf, setPDF] = useState();
  const [pdfPreviewUrl, setPdfPreviewUrl] = useState('');
  const [contentImage, setContentImage] = useState(null);
  const [contentImagePreviewUrl, setContentImagePreviewUrl] = useState('');
  const [previewText, setPreviewText] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const steps = ['Details', 'Content', 'Save'];

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleNext = async () => {
    setSubmissionAttempted(true);

    try {
      if (activeStep === steps.length - 1) {
        const created = await create();
        if (created) {
          reset();
          setState(false);
        } else {
          throw new Error(created);
        }
      } else {
        const currentSchema = stepSchemas[activeStep];
        const currentStepValues = getCurrentStepValues();
        await currentSchema.validate(currentStepValues, { abortEarly: false }); // <-- Make sure abortEarly is set to false here
        setActiveStep(prevActiveStep => prevActiveStep + 1);
      }
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        const newErrors = err.inner.reduce((acc, currErr) => {
          return { ...acc, [currErr.path]: currErr.message };
        }, {});
        setErrors(newErrors);
      } else {
        setError(err.message);
        console.log(err.message);
      }
    }
  };

  const getCurrentStepValues = () => {
    switch (activeStep) {
      case 0:
        return {
          title,
          type,
          category,
          sourceName,
          image
        };
      case 1:
        return {
          type,
          videoLink,
          textContent,
          contentImage,
          pdf
        };
      default:
        return {};
    }
  };

  const addImage = async () => {
    if (!image) return;

    try {
      const key = `resources/${resourceID}.${image.type}`;
      await Storage.put(key, image.file, {
        contentType: `image/${image.type}`
      });
    } catch (err) {
      console.log(err);
    }
  };

  const addContentImage = async () => {
    if (!contentImage) return;

    try {
      const key = `resources/${resourceContentID}.${contentImage.type}`;
      await Storage.put(key, contentImage.file, {
        contentType: `image/${contentImage.type}`
      });
    } catch (err) {
      console.log(err);
    }
  };

  const addPDF = async () => {
    if (!pdf) return;

    try {
      const key = `resources/${resourceContentID}.pdf`;
      await Storage.put(key, pdf, {
        contentType: 'application/pdf'
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleFileUpload = e => {
    const file = e.target.files[0];
    if (file) {
      const fullMimeType = file.type;
      const fileType = file.type.split('/')[1];
      if (type === 'image' && fullMimeType.startsWith('image')) {
        setContentImage({
          file: file,
          type: fileType
        });
        const previewUrl = URL.createObjectURL(file);
        setContentImagePreviewUrl(previewUrl);
      }
      if (type === 'pdf' && fullMimeType.toLowerCase().includes('pdf')) {
        setPDF(file);
        const previewUrl = URL.createObjectURL(file);
        setPdfPreviewUrl(previewUrl);
      }
    }
  };

  const handleImageUpload = e => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file.type.split('/')[1];
      setImage({
        file: file,
        type: fileType
      });
      const previewUrl = URL.createObjectURL(file);
      setImagePreviewUrl(previewUrl);
    }
  };

  const handleClearImage = () => {
    const fileInput = document.getElementById('contained-button-file');
    if (fileInput) fileInput.value = '';
    setImage(null);
    setImagePreviewUrl('');
  };
  const handleClearContentImage = () => {
    const fileInput = document.getElementById(
      'contained-button-file-content-image'
    );
    if (fileInput) fileInput.value = '';
    setContentImage(null);
    setContentImagePreviewUrl('');
  };

  const handleClearContentPDF = () => {
    const fileInput = document.getElementById('pdf-upload-button');
    if (fileInput) fileInput.value = '';
    setPDF(null);
    setPdfPreviewUrl('');
  };

  const validateField = async (fieldName, value) => {
    try {
      await stepSchemas.validateAt(fieldName, { [fieldName]: value });
      setErrors(prevErrors => {
        const newErrors = { ...prevErrors };
        delete newErrors[fieldName];
        return newErrors;
      });
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setErrors(prevErrors => ({ ...prevErrors, [fieldName]: err.message }));
      } else {
        console.error('An unexpected error occurred:', err);
      }
    }
  };

  const resourceContentType =
    type === 'text'
      ? 'lexical'
      : type === 'video'
      ? 'video'
      : type === 'pdf'
      ? 'pdf'
      : type === 'image'
      ? 'image'
      : null;

  const content =
    type === 'text'
      ? textContent
      : type === 'video'
      ? videoLink
      : type === 'pdf'
      ? null
      : type === 'image'
      ? null
      : null;

  const create = async () => {
    try {
      setCreating(true);
      if (image) {
        addImage();
      }

      const variables = {
        id: resourceID,
        hasImage: Boolean(imagePreviewUrl),
        imageType: image ? image.type : null,
        title,
        subTitle: subtitle,
        sourceName,
        sourceLink,
        category,
        program,
        condition,
        type,
        createdBy: user.username,
        previewText,
        accessType
      };

      await API.graphql(
        graphqlOperation(createResource, {
          input: variables
        })
      );

      if (type === 'pdf' && pdf) {
        await addPDF();
      }
      if (type === 'image' && contentImage) {
        await addContentImage();
      }

      const contentVariables = {
        id: resourceContentID,
        resourceID,
        order: 10,
        contentType: resourceContentType,
        content: content,
        fileType:
          type === 'image' ? contentImage.type : type === 'pdf' ? 'pdf' : null
      };

      await API.graphql(
        graphqlOperation(createResourceContent, {
          input: contentVariables
        })
      );
      enqueueSnackbar('Created new resource', {
        severity: 'success'
      });
      return true;
    } catch (err) {
      enqueueSnackbar('Error creating resource', {
        severity: 'error'
      });
      setError(err);
      return err;
    } finally {
      setCreating(false);
    }
  };

  const reset = () => {
    setSubmissionAttempted(false);
    setError(null);
    setTitle('');
    setSubtitle('');
    setAccessType('public');
    setCondition(null);
    setCategory('');
    setType(null);
    setProgram(null);
    setSourceName('');
    setSourceLink('');
    setImage(null);
    setImagePreviewUrl('');
    setActiveStep(0);
    setVideoLink('');
    setErrors({});
    setTextContent(
      JSON.stringify({
        root: {
          children: [
            {
              children: [],
              direction: null,
              format: '',
              indent: 0,
              type: 'paragraph',
              version: 1
            }
          ],
          direction: null,
          format: '',
          indent: 0,
          type: 'root',
          version: 1
        }
      })
    );
    setPDF();
    setPdfPreviewUrl('');
    setContentImage(null);
    setContentImagePreviewUrl('');
    setPreviewText('');
  };

  return (
    <Box>
      <Modal
        title="Add a resource"
        fullWidth
        maxWidth="md"
        open={state || false}
        setClose={() => setState(state => !state)}
      >
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map(label => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box sx={{ minHeight: 400 }} my={2}>
          {activeStep === 0 && (
            <StepOne
              title={title}
              setTitle={setTitle}
              subtitle={subtitle}
              setSubtitle={setSubtitle}
              accessType={accessType}
              setAccessType={setAccessType}
              type={type}
              setType={setType}
              category={category}
              setCategory={setCategory}
              condition={condition}
              setCondition={setCondition}
              program={program}
              setProgram={setProgram}
              sourceName={sourceName}
              setSourceName={setSourceName}
              sourceLink={sourceLink}
              setSourceLink={setSourceLink}
              handleImageUpload={handleImageUpload}
              handleClearImage={handleClearImage}
              imagePreviewUrl={imagePreviewUrl}
              errors={errors}
              setErrors={setErrors}
              validateField={validateField}
              submissionAttempted={submissionAttempted}
              setSubmissionAttempted={setSubmissionAttempted}
              previewText={previewText}
              setPreviewText={setPreviewText}
            />
          )}
          {activeStep === 1 && (
            <StepTwo
              type={type}
              videoLink={videoLink}
              setVideoLink={setVideoLink}
              textContent={textContent}
              setTextContent={setTextContent}
              handleFileUpload={handleFileUpload}
              contentImagePreviewUrl={contentImagePreviewUrl}
              handleClearContentImage={handleClearContentImage}
              handleClearContentPDF={handleClearContentPDF}
              pdfPreviewUrl={pdfPreviewUrl}
              errors={errors}
              setErrors={setErrors}
              validateField={validateField}
              submissionAttempted={submissionAttempted}
              setSubmissionAttempted={setSubmissionAttempted}
            />
          )}
          {activeStep === 2 &&
            (creating ? (
              <Box display="flex" alignItems="center" justifyContent="center">
                <CircularProgress />
              </Box>
            ) : (
              <StepThree
                title={title}
                subtitle={subtitle}
                type={type}
                category={category}
                condition={condition}
                program={program}
                sourceName={sourceName}
                sourceLink={sourceLink}
                textContent={textContent}
                videoLink={videoLink}
                contentImagePreviewUrl={contentImagePreviewUrl}
                pdfPreviewUrl={pdfPreviewUrl}
                errors={errors}
                setErrors={setErrors}
                validateField={validateField}
                error={error}
              />
            ))}
        </Box>
        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <ButtonGroup>
            <Button
              onClick={handleBack}
              size="small"
              variant="contained"
              disabled={activeStep === 0}
            >
              Back
            </Button>
            <Button onClick={reset} size="small" variant="outlined">
              Reset
            </Button>
          </ButtonGroup>
          <LoadingButton
            loading={creating}
            onClick={handleNext}
            size="small"
            variant="contained"
          >
            {activeStep === 2 ? 'Create' : 'Next'}
          </LoadingButton>
        </DialogActions>
      </Modal>
    </Box>
  );
}

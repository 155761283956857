import { useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Collapse,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Typography
} from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import { useSnackbar } from 'context/SnackBar';
import { updateClinician } from 'graphql/mutations';
import { clientsClinicConnection, updateUserAttributes } from 'graphql/queries';

import { ClinicianCard, ErrorMessage, LocationCard } from 'components/atoms';

import Modal from '../Modal';

export default function AddUsersToLocation({
  open,
  setOpen,
  setClinicians,
  selectedClinicians,
  locations,
  selectedLocation,
  setSelectedLocation,
  setSelectClinicians,
  setSelectedClinicians
}) {
  const { user } = useAuthenticator(context => [context.user]);
  const clinicID = user.attributes['custom:clinicID'];
  const [addingUsersToLocation, setAddingUsersToLocation] = useState(false);
  const [error, setError] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const handleSelectLocation = location => {
    setSelectedLocation(prevLocation => {
      return prevLocation && prevLocation.id === location.id ? null : location;
    });
  };

  const handleAddUsers = async () => {
    try {
      setAddingUsersToLocation(true);

      let updatedClinicianData = [];
      let errorOccurred = false;
      for (let [clinicianID, clinician] of selectedClinicians.entries()) {
        try {
          await API.graphql(
            graphqlOperation(updateUserAttributes, {
              userID: clinicianID,
              attributeKey: 'custom:locationID',
              attributeValue: selectedLocation.id
            })
          );
          await API.graphql(
            graphqlOperation(updateClinician, {
              input: {
                id: clinicianID,
                locationID: selectedLocation.id,
                locationCliniciansId: selectedLocation.id,
                _version: clinician._version
              }
            })
          );
          const clientsClinicConnectionVariables = {
            clinicianID: clinicianID,
            locationID: selectedLocation.id,
            action: 'add_to_clinic_and_location',
            clinicID: selectedLocation.clinicID ?? clinicID
          };
          await API.graphql(
            graphqlOperation(
              clientsClinicConnection,
              clientsClinicConnectionVariables
            )
          );
        } catch (err) {
          console.log('error');
          setError(err.message);
          errorOccurred = true;
          break;
        }

        updatedClinicianData.push({
          ...clinician,
          locationID: selectedLocation.id,
          locationCliniciansId: selectedLocation.id
        });
      }
      if (!errorOccurred) {
        setClinicians(prevClinicians => {
          return prevClinicians.map(clinician => {
            const updated = updatedClinicianData.find(
              u => u.id === clinician.id
            );
            if (updated) {
              return updated;
            }
            return clinician;
          });
        });
      }
      setSelectClinicians(false);
      setSelectedClinicians(new Map());
      setSelectedLocation({});
      enqueueSnackbar(`Assigned clinicians to ${selectedLocation.name}`, {
        severity: 'success'
      });
      setOpen(false);
    } catch (err) {
      console.log(err);
      enqueueSnackbar(`Error assigning clinicians to location`, {
        severity: 'error'
      });

      setError(err.message);
    } finally {
      setAddingUsersToLocation(false);
    }
  };

  return (
    <Modal
      disableExit={addingUsersToLocation}
      fullWidth
      title="Add user to location"
      maxWidth="md"
      open={open}
      setClose={() => setOpen(false)}
    >
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <Typography variant="h5">
              Assign {selectedClinicians.size === 1 ? 'this' : 'these'}{' '}
              {selectedClinicians.size > 0 && selectedClinicians.size}{' '}
              {selectedClinicians.size === 1 ? 'clinician' : 'clinicians'} ...
            </Typography>
            <Divider />
            <Box sx={{ maxHeight: 400, overflowX: 'scroll' }}>
              {Array.from(selectedClinicians.values()).map(clinician => (
                <Box key={clinician.id} my={1} pr={2}>
                  <ClinicianCard
                    noAction
                    selected={selectedClinicians.has(clinician.id)}
                    slim
                    dark
                    selectable
                    clinician={clinician}
                  />
                </Box>
              ))}
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h5">To this location ...</Typography>
            <Divider />
            <Box sx={{ maxHeight: 400, overflowY: 'scroll' }}>
              {locations?.map(location => {
                const shouldDisplayCard =
                  !selectedLocation?.id || location.id === selectedLocation?.id;

                return (
                  <Collapse in={shouldDisplayCard} key={location.id}>
                    <Box my={1} pr={2}>
                      <LocationCard
                        action="select"
                        dark
                        selected={location.id === selectedLocation?.id}
                        selectLocation={handleSelectLocation}
                        location={location}
                      />
                    </Box>
                  </Collapse>
                );
              })}
            </Box>
          </Grid>
        </Grid>
        {Boolean(error) && <ErrorMessage message={error} />}
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={addingUsersToLocation}
          onClick={handleAddUsers}
          disabled={!selectedLocation.id}
        >
          Assign
        </LoadingButton>
      </DialogActions>
    </Modal>
  );
}

import { useEffect, useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Fade,
  Paper,
  Stack,
  Step,
  StepLabel,
  Stepper
} from '@mui/material';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import { updateClient } from 'graphql/mutations';
import { createFromTemplate, getClient } from 'graphql/queries';

import {
  AgeRange,
  Confirm,
  ForSelector,
  ProgramSelector
} from 'components/molecules';

import EnterName from './enterName';

const steps = ['Start', 'Choose Program', 'Finish'];

export default function ClientStepper() {
  const { user } = useAuthenticator(context => [context.user]);
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [forValue, setForValue] = useState('');
  const [age, setAge] = useState(4);
  const [program, setProgram] = useState('');
  const [programStage, setProgramStage] = useState(1);
  const firstName = user.attributes.given_name;
  const lastName = user.attributes.family_name;
  const [clientFirstName, setClientFirstName] = useState('');
  const [clientLastName, setClientLastName] = useState(
    user.attributes.family_name
  );
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);

  useEffect(() => {
    switch (forValue) {
      case 'child':
        setAge(4);
        break;
      case 'me':
      case 'other':
        setAge(27);
        break;
      default:
        break;
    }
  }, [forValue]);

  const isValidStep = () => {
    if (!forValue) {
      return false;
    } else {
      return true;
    }
  };

  const handleForChange = event => {
    setForValue(event.target.value);
  };

  const isStepOptional = step => step === 1;

  const isStepSkipped = step => skipped.has(step);

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep(prevActiveStep => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep(prevActiveStep => prevActiveStep + 1);
    setSkipped(prevSkipped => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => setActiveStep(0);

  const finishOnboarding = async () => {
    try {
      await Auth.updateUserAttributes(user, {
        'custom:onboarding': '4'
      });
    } catch (error) {
      console.error('Error updating user attributes:', error);
    }
  };

  const handleCreate = async () => {
    try {
      if (program !== '') {
        const { data } = await API.graphql(
          graphqlOperation(getClient, { id: user.username })
        );
        if (data.getClient === null) {
          throw new Error('Client not found');
        }
        const input = {
          id: user.username,
          sub: user.attributes.sub,
          program: program,
          stage: programStage,
          age: age,
          forValue,
          clientFirstName: clientFirstName,
          clientLastName: clientLastName,
          _version: data.getClient._version
        };

        await API.graphql(graphqlOperation(updateClient, { input: input }));

        const created = await API.graphql(
          graphqlOperation(createFromTemplate, {
            clientSub: user.attributes.sub,
            clientID: user.username,
            program: program,
            stage: programStage
          })
        );

        if (created.data.createFromTemplate.statusCode === 200) {
          await finishOnboarding();
        } else {
          throw new Error(created.data.createFromTemplate.body);
        }
      } else {
        await finishOnboarding();
      }
    } catch (err) {
      setError(err.message || 'Something went wrong - please contact us');
    }
  };

  const handleCreateAndNext = async () => {
    handleNext();
    await handleCreate();
  };

  return (
    <Box>
      <Paper
        sx={{
          p: 2,
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
          width: '100%'
        }}
      >
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <Box sx={{ py: { xs: 0, sm: 2 }, px: { xs: 0, sm: 2 } }} height="100%">
          {activeStep === steps.length ? (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              minHeight="50vh"
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="stretch"
              justifyContent="center"
              minHeight="55vh"
              height="100%"
            >
              {activeStep === 0 && (
                <Fade timeout={500} in={activeStep === 0}>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Stack direction="column" sx={{ my: 2 }} spacing={2}>
                      <ForSelector
                        forValue={forValue}
                        handleForChange={handleForChange}
                      />
                    </Stack>

                    <Collapse timeout={'auto'} in={!!forValue}>
                      <>
                        <Stack direction="column" sx={{ my: 2 }} spacing={2}>
                          {forValue !== 'me' && (
                            <Collapse in={forValue !== 'me'} timeout={'auto'}>
                              <EnterName
                                forValue={forValue}
                                firstName={firstName}
                                clientFirstName={clientFirstName}
                                setClientFirstName={setClientFirstName}
                                clientLastName={clientLastName}
                                setClientLastName={setClientLastName}
                              />
                            </Collapse>
                          )}
                        </Stack>
                        <Stack direction="column" sx={{ my: 2 }} spacing={2}>
                          <AgeRange
                            age={age}
                            setAge={setAge}
                            forValue={forValue}
                          />
                        </Stack>
                      </>
                    </Collapse>
                  </Box>
                </Fade>
              )}
              {activeStep === 1 && (
                <Fade timeout={500} in={activeStep === 1}>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <ProgramSelector
                      program={program}
                      setProgram={setProgram}
                      age={age}
                      forValue={forValue}
                      programStage={programStage}
                      setProgramStage={setProgramStage}
                    />
                  </Box>
                </Fade>
              )}
              {activeStep === 2 && (
                <Fade timeout={500} in={activeStep === 2}>
                  <Box>
                    <Confirm
                      programStage={programStage}
                      program={program}
                      age={age}
                      forValue={forValue}
                      firstName={firstName}
                      lastName={lastName}
                      clientFirstName={clientFirstName}
                      clientLastName={clientLastName}
                    />
                  </Box>
                </Fade>
              )}
            </Box>
          )}
        </Box>
        <Box>
          {activeStep === steps.length ? (
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button disabled onClick={handleReset}>
                Reset
              </Button>
            </Box>
          ) : (
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              {isStepOptional(activeStep) && (
                <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                  Skip
                </Button>
              )}
              <Button
                disabled={!isValidStep()}
                onClick={
                  activeStep === steps.length - 1
                    ? handleCreateAndNext
                    : handleNext
                }
              >
                {activeStep === steps.length - 1 ? 'Confirm' : 'Next'}
              </Button>
            </Box>
          )}
        </Box>
      </Paper>
    </Box>
  );
}

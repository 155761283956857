import { Info } from '@mui/icons-material';
import { Box, FormLabel, IconButton, Stack, Tooltip } from '@mui/material';

export default function Section({ children, label, action, info }) {
  return (
    <Box p={1} my={2}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <FormLabel sx={{ color: 'text.accent', fontWeight: 600 }}>
          {label}
        </FormLabel>
        {info && (
          <Tooltip title={info}>
            <span>
              <IconButton disabled>
                <Info />
              </IconButton>
            </span>
          </Tooltip>
        )}
        {action && action}
      </Stack>
      {children}
    </Box>
  );
}

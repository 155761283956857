import { AddPhotoAlternate, NoteAdd } from '@mui/icons-material';
import {
  Box,
  Button,
  ButtonGroup,
  FormHelperText,
  TextField
} from '@mui/material';
import ReactPlayer from 'react-player';

import { PDF } from 'components/atoms';

export default function StepTwo(props) {
  const {
    errors,
    type,
    videoLink,
    setVideoLink,

    handleFileUpload,
    contentImagePreviewUrl,
    handleClearContentImage,
    handleClearContentPDF,
    pdfPreviewUrl,
    validateField,
    submissionAttempted,
    setSubmissionAttempted
  } = props;

  return (
    <Box>
      {type === 'video' && (
        <Box>
          <TextField
            error={submissionAttempted && !!errors.videoLink}
            helperText={submissionAttempted && errors.videoLink}
            size="small"
            variant="filled"
            value={videoLink}
            onChange={e => {
              setVideoLink(e.target.value);
              if (submissionAttempted) {
                validateField('videoLink', e.target.value);
              }
              setSubmissionAttempted(false);
            }}
            fullWidth
            label="Video Link"
          />
          {videoLink && (
            <Box
              my={2}
              style={{
                position: 'relative',
                paddingTop: '56.25%'
              }}
            >
              <ReactPlayer
                pip
                width="100%"
                height="100%"
                className="react-player"
                controls
                url={videoLink}
              />
            </Box>
          )}
        </Box>
      )}

      {type === 'image' && (
        <Box>
          <Box display="flex" alignItems="center" justifyContent="center">
            <ButtonGroup size="small">
              <label htmlFor="contained-button-file-content-image">
                <Button
                  variant="contained"
                  component="span"
                  startIcon={<AddPhotoAlternate />}
                >
                  Upload Image
                </Button>

                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="contained-button-file-content-image"
                  type="file"
                  onChange={handleFileUpload}
                />
              </label>
              {contentImagePreviewUrl && (
                <Button variant="outlined" onClick={handleClearContentImage}>
                  Clear
                </Button>
              )}
            </ButtonGroup>
            {submissionAttempted && !!errors.contentImage && (
              <FormHelperText error>{errors.contentImage}</FormHelperText>
            )}
          </Box>
          {contentImagePreviewUrl && (
            <Box
              my={2}
              sx={{ width: '100%' }}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <img
                src={contentImagePreviewUrl}
                alt="Selected"
                style={{ maxWidth: '80%', height: 'auto' }}
              />
            </Box>
          )}
        </Box>
      )}
      {type === 'pdf' && (
        <Box>
          <Box display="flex" alignItems="center" justifyContent="center">
            <ButtonGroup size="small">
              <label htmlFor="pdf-upload-button">
                <Button
                  size="small"
                  variant="contained"
                  component="span"
                  startIcon={<NoteAdd />}
                >
                  Upload PDF
                </Button>
                <input
                  accept=".pdf"
                  style={{ display: 'none' }}
                  id="pdf-upload-button"
                  type="file"
                  onChange={handleFileUpload}
                />
              </label>
              {pdfPreviewUrl && (
                <Button variant="outlined" onClick={handleClearContentPDF}>
                  Clear
                </Button>
              )}
            </ButtonGroup>
            {submissionAttempted && !!errors.pdf && (
              <FormHelperText error>{errors.pdf}</FormHelperText>
            )}
          </Box>
          {pdfPreviewUrl && (
            <Box my={2}>
              <PDF pdf={pdfPreviewUrl} />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}

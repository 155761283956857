import { useEffect, useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  TextField,
  Typography
} from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import { useAppContext } from 'context';
import { useEntryContext } from 'context/Entry';
import { getClient, supportRequest } from 'graphql/queries';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import UAParser from 'ua-parser-js';

import { Modal } from 'components/molecules';

import AppBar from '../../organisms/AppBar';

export default function Client({ children, props, isOnboarding }) {
  const { state, dispatch } = useAppContext();
  const { setSelectedEntry, selectedEntry } = useEntryContext();
  const location = useLocation();
  const [openHelpModal, setOpenHelpModal] = useState(false);
  const [supportRequestText, setSupportRequestText] = useState('');
  const [canContact, setCanContact] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');

  const { user } = useAuthenticator(context => [context.user]);

  useEffect(() => {
    const queryClient = async () => {
      const { data } = await API.graphql(
        graphqlOperation(getClient, { id: user.username })
      );
      const clinician = data.getClient?.clinician;

      dispatch({ type: 'add_clinician', clinician: clinician });
    };
    if (!state.clinician?.id && !user.username) {
      queryClient();
    }
  }, [state?.clinician?.id, dispatch, user.username]);

  useEffect(() => {
    const shouldResetRecord =
      location.pathname === '/' ||
      (location.pathname.includes('/entries/') &&
        !location.pathname.includes('/new'));
    const shouldResetSelectedEntry =
      location.pathname.includes('/entries/') &&
      !location.pathname.includes('/new');

    if (shouldResetRecord) {
      dispatch({ type: 'set_record', record: {} });
    }
    if (shouldResetSelectedEntry && selectedEntry) {
      setSelectedEntry({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, dispatch, setSelectedEntry]);

  const handleOpenHelpModal = () => {
    setOpenHelpModal(prev => !prev);
  };

  async function queryPermission(name) {
    try {
      const permissionStatus = await navigator.permissions.query({
        name: name
      });
      return `${name}: ${permissionStatus.state}`;
    } catch (error) {
      console.error(`Error querying ${name} permission:`, error);
      return `${name}: error`;
    }
  }

  async function getPermissionsInfo() {
    const permissionsToCheck = ['microphone', 'camera', 'geolocation'];
    const permissionsStatus = await Promise.all(
      permissionsToCheck.map(queryPermission)
    );
    // Convert array of strings to a flat object
    const permissionsObj = permissionsStatus.reduce((acc, curr) => {
      const [name, state] = curr.split(': ');
      acc[`permission_${name}`] = state;
      return acc;
    }, {});
    return permissionsObj;
  }

  async function getMediaDevices() {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      // Instead of returning an array, concatenate device info into a single object with unique keys
      const deviceInfo = devices.reduce((acc, device, index) => {
        acc[`mediadevice_${index}_kind`] = device.kind;
        acc[`mediadevice_${index}_label`] = device.label;
        acc[`medidevice_${index}_id`] = device.deviceId;
        acc[`mediagroup_${index}_id`] = device.groupId;
        return acc;
      }, {});
      return deviceInfo;
    } catch (error) {
      console.error('Error getting media devices:', error);
      return { mediaDevicesError: 'Error getting media devices' };
    }
  }
  const submitSupportRequest = async () => {
    try {
      setSubmitting(true);
      const parser = new UAParser();
      const result = parser.getResult();

      const permissionsInfo = await getPermissionsInfo();
      const mediaDevicesInfo = await getMediaDevices();
      const supportRequestData = {
        id: user.username,
        email: user.attributes.email,
        firstName: user.attributes.given_name,
        lastName: user.attributes.family_name,
        language: window.navigator.language,
        userAgent: window.navigator.userAgent,
        browser: result.browser.name,
        browserInfo: result.browser.version,
        os: result.os.name,
        osVersion: result.os.version,
        device: result.device.model || 'N/A',
        deviceType: result.device.type || 'N/A',
        engine: result.engine.name,
        engineVersion: result.engine.version,
        cpu: result.cpu.architecture || 'N/A',
        mediaDevicesInfo: JSON.stringify(mediaDevicesInfo),
        permissions_microphone: permissionsInfo.permission_microphone,
        permissions_camera: permissionsInfo.permissions_camera,
        videoInputCount: mediaDevicesInfo.videoInputCount,
        audioInputCount: mediaDevicesInfo.audioInputCount,
        audioOutputCount: mediaDevicesInfo.audioOutputCount,
        supportRequestText,
        canContact
      };
      await API.graphql(graphqlOperation(supportRequest, supportRequestData));
      setSupportRequestText('');
      setOpenHelpModal(false);
    } catch (e) {
      console.error(e);
      setError(e.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Box pb={4}>
      <Helmet>
        <title>{props?.title + ' | SpeechFit' || 'SpeechFit'}</title>
      </Helmet>
      <Grid container>
        <Grid item xs={12} sm={12}>
          <Container maxWidth="xl">
            {!isOnboarding && <AppBar setOpenHelpModal={setOpenHelpModal} />}
            <Box display="flex">
              <Box width="100%">
                <Container maxWidth="xl">
                  <Modal
                    open={openHelpModal}
                    text="If you are experiencing difficulty using SpeechFit, please let us know so we can fix it or make it easier."
                    fullWidth
                    maxWidth="md"
                    title="Feedback & Comments"
                    setClose={handleOpenHelpModal}
                  >
                    <Grid container sx={{ pt: 4 }}>
                      <Grid item xs={12}>
                        <TextField
                          minRows={3}
                          placeholder="Describe your problem, suggestion or feedback here."
                          fullWidth
                          value={supportRequestText}
                          onChange={e => setSupportRequestText(e.target.value)}
                          label="How can we improve SpeechFit?"
                          multiline
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} sx={{ mt: 2 }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={canContact}
                              onChange={e => setCanContact(e.target.checked)}
                            />
                          }
                          label="You can contact me in relation to this report."
                        />
                      </Grid>
                      <Grid
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          mt: 2
                        }}
                        item
                        xs={12}
                        sm={6}
                      >
                        <LoadingButton
                          size="small"
                          variant="contained"
                          loading={submitting}
                          onClick={submitSupportRequest}
                        >
                          Submit
                        </LoadingButton>
                      </Grid>
                    </Grid>
                    {error && (
                      <Box my={2}>
                        <Typography color="error" variant="body2">
                          {error}
                        </Typography>
                      </Box>
                    )}
                  </Modal>
                  <Box mt={props?.mt || 4}>{children}</Box>
                </Container>
              </Box>
            </Box>
          </Container>
        </Grid>
      </Grid>
    </Box>
  );
}

import { useCallback, useEffect, useState } from 'react';

import { Box, TextField, Typography } from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import { useAppContext } from 'context';
import { updateInfoContent } from 'graphql/mutations';
import { infoContentByOrder } from 'graphql/queries';
import { debounce } from 'lodash';

import { RatingGuideSUDS, Video } from 'components/atoms';

import { RatingGuideFluency, RatingGuideSeverity } from '..';

export default function InfoSection({ component }) {
  const [infoContents, setInfoContents] = useState([]);

  useEffect(() => {
    async function fetchInfoContents() {
      const { data } = await API.graphql(
        graphqlOperation(infoContentByOrder, {
          componentID: component.id,
          sortDirection: 'ASC'
        })
      );

      setInfoContents(data.infoContentByOrder.items);
    }

    if (component?.id) {
      fetchInfoContents();
    }
    return () => {
      setInfoContents([]);
    };
  }, [component]);

  return (
    <Box mb={2}>
      {infoContents &&
        infoContents.length > 0 &&
        infoContents?.map(infoContent => (
          <InfoContent
            key={infoContent.id}
            setInfoContents={setInfoContents}
            infoContent={infoContent}
          />
        ))}
    </Box>
  );
}

function InfoContent({ infoContent, setInfoContents }) {
  const [version, setVersion] = useState(infoContent?._version || 1);
  const [title, setTitle] = useState(infoContent.title);
  const [content, setContent] = useState(infoContent.content);
  const { state } = useAppContext();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdateInfoContent = useCallback(
    debounce(async (id, title, content, widget) => {
      const input = {
        id: id,
        _version: version,
        ...(infoContent.title !== title && { title: title }),
        ...(infoContent.content !== content && { content: content }),
        ...(infoContent.widget !== widget && { widget: widget })
      };

      try {
        const { data } = await API.graphql(
          graphqlOperation(updateInfoContent, { input })
        );

        setVersion(data.updateInfoContent._version);
        setInfoContents(prevInfoContents =>
          prevInfoContents.map(item =>
            item.id === id ? { ...item, ...data.updateInfoContent } : item
          )
        );
      } catch (error) {
        console.error('Error updating info content:', error);
      }
    }, 300),
    [
      version,
      setVersion,
      setInfoContents,
      infoContent.title,
      infoContent.content,
      infoContent.widget
    ]
  );

  const handleUpdateInfoContent = (id, title, content, widget) => {
    debouncedUpdateInfoContent(id, title, content, widget);
  };

  return (
    <Box mt={2}>
      {state.edit ? (
        <TextField
          label="Title"
          variant="filled"
          fullWidth
          value={title}
          onChange={e => {
            setTitle(e.target.value);
            handleUpdateInfoContent(
              infoContent.id,
              e.target.value,
              content,
              infoContent.widget
            );
          }}
        />
      ) : (
        <Typography variant="h6">{title}</Typography>
      )}
      {(() => {
        switch (infoContent.widget) {
          case 'video':
            return <Video content={`/${infoContent.content}`} />;
          case 'paedStuttering':
            return <RatingGuideSeverity />;
          case 'adultStuttering':
            return <RatingGuideSeverity />;
          case 'adultFluency':
            return <RatingGuideFluency />;
          case 'suds':
            return <RatingGuideSUDS />;
          default:
            if (state.edit) {
              return (
                <TextField
                  label="Content"
                  variant="filled"
                  fullWidth
                  multiline
                  value={content}
                  onChange={e => {
                    setContent(e.target.value); // Update local state immediately
                    handleUpdateInfoContent(
                      infoContent.id,
                      title,
                      e.target.value,
                      infoContent.widget
                    );
                  }}
                />
              );
            } else {
              return <Typography>{content}</Typography>;
            }
        }
      })()}
    </Box>
  );
}

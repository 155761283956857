import { ThemeProvider as MUIProvider } from '@mui/material/styles';
import { useAppContext } from 'context';
import { darkTheme, lightTheme } from 'theme';

export default function ThemeProvider({ children }) {
  const { state } = useAppContext();

  const appliedTheme = state.theme === 'dark' ? darkTheme : lightTheme;

  return <MUIProvider theme={appliedTheme}>{children}</MUIProvider>;
}

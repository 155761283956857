import { useState } from 'react';

import {
  Delete,
  Place,
  RadioButtonChecked,
  RadioButtonUnchecked
} from '@mui/icons-material';
import {
  Button,
  Card,
  CardActions,
  CardHeader,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material';

import { formatCountryName } from 'utils/getCountryFromTimeZone';

export default function LocationCard({
  info,
  dark,
  location,
  selected,
  hasEdit,
  selectLocation,
  action,
  handleDeleteLocation
}) {
  const [deletingLocation, setDeletingLocation] = useState(false);

  const handleSelectLocation = () => {
    selectLocation(location);
  };
  const SelectAction = () => (
    <IconButton color="primary" onClick={handleSelectLocation}>
      {selected ? <RadioButtonChecked /> : <RadioButtonUnchecked />}
    </IconButton>
  );

  const DeleteAction = () => (
    <IconButton
      size="small"
      disabled={deletingLocation}
      color="primary"
      onClick={async () => {
        setDeletingLocation(true);
        await handleDeleteLocation(location);
        setDeletingLocation(false);
      }}
    >
      {deletingLocation ? (
        <CircularProgress size={25} />
      ) : (
        <Delete fontSize="small" />
      )}
    </IconButton>
  );

  const titleComponent = (
    <Typography
      lineHeight={1}
      sx={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }}
      variant="h6"
    >
      {location.name}
    </Typography>
  );

  return (
    <Card
      elevation={dark ? 0 : 4}
      variant={selected ? 'outlined' : 'elevation'}
      sx={{
        backgroundColor: dark
          ? selected
            ? 'background.default'
            : 'background.card'
          : 'background.paper'
      }}
    >
      <CardHeader
        avatar={
          info ? (
            <Tooltip placement="top" title="Selected as my location">
              <Place color="primary" />
            </Tooltip>
          ) : (
            <Place />
          )
        }
        title={titleComponent}
        subheader={`${[
          location.street,
          location.street2,
          location.city,
          location.state,
          location.postcode,
          formatCountryName(location.country)
        ]
          .filter(part => part)
          .join(', ')}${' '}`}
        subheaderTypographyProps={{ sx: { py: 0 } }}
        action={
          action === 'select' ? (
            <SelectAction />
          ) : action === 'delete' ? (
            <DeleteAction />
          ) : null
        }
      />
      <CardActions
        sx={{
          height: 50,
          display: 'flex',
          justifyContent: 'flex-end',
          padding: '0px'
        }}
      >
        {hasEdit && <Button size="small">Edit</Button>}
      </CardActions>
    </Card>
  );
}

import { useState } from 'react';

import {
  Add,
  Brightness1Outlined,
  BubbleChart,
  Coffee,
  Edit,
  EscalatorWarningOutlined,
  Face4,
  Fastfood,
  ForumOutlined,
  GraphicEq,
  GroupOutlined,
  HourglassTopOutlined,
  LocalHospitalOutlined,
  LocalLibraryOutlined,
  MicOutlined,
  NavigationOutlined,
  PanoramaWideAngleOutlined,
  People,
  PersonOutline,
  PhoneOutlined,
  PresentToAll,
  ReceiptLongOutlined,
  ShoppingCartOutlined,
  ThumbUpOutlined,
  VisibilityOffOutlined,
  Waves
} from '@mui/icons-material';
import {
  Box,
  Card,
  Chip,
  FormLabel,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
  Typography
} from '@mui/material';

import { BubbleChart as BChart, LineChart, PieChart } from 'components/charts';

import { formatProgram } from 'utils/formatProgram';

import SessionRow from '../SessionRow';

export default function ProgramDetail({ selectedTemplate }) {
  let isCamperdown = selectedTemplate?.includes('camperdown');
  let isLidcombe = selectedTemplate?.includes('lidcombe');
  let isWestmead = selectedTemplate?.includes('westmead');
  let isOakville = selectedTemplate?.includes('oakville');

  const ResourcesSection = () => {
    const Resource = () => (
      <Grid item xs={4}>
        <Card elevation={4} sx={{ display: 'flex', justifyContent: 'center' }}>
          <IconButton disabled>
            <Add />
          </IconButton>
        </Card>
      </Grid>
    );
    return (
      <Box mt={2}>
        <Grid spacing={2} container>
          <Resource />
          <Resource />
          <Resource />
        </Grid>
      </Box>
    );
  };
  const SessionsSection = () => {
    const now = new Date();
    const nowToISo = now.toISOString();
    const sevenDaysAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
    const sevenDaysAgoToIso = sevenDaysAgo.toISOString();
    return (
      <Box mt={2}>
        <SessionRow disabled session={{ date: nowToISo }} />
        <SessionRow
          disabled
          session={{ date: sevenDaysAgoToIso, location: 'Clinic' }}
        />
      </Box>
    );
  };

  const InsightsSection = ({ selectedTemplate, tabRequiresHighLow }) => {
    const [currentTab, setCurrentTab] = useState(0);

    const handleChange = (event, newValue) => {
      setCurrentTab(newValue);
    };

    let domain = isCamperdown ? 8 : 10;

    const addHighsAndLows = data => {
      return data.map(entry => {
        if (entry['Stuttering Severity'] === 10) {
          return {
            ...entry,
            'SR High': entry['Stuttering Severity'],
            'SR Low': entry['Stuttering Severity']
          };
        } else {
          return {
            ...entry,
            'SR High': entry['Stuttering Severity'] + 2,
            'SR Low': entry['Stuttering Severity'] - 2
          };
        }
      });
    };

    const getBubbleData = flag => {
      return [
        {
          day: '01/01',
          index: 1,
          value: 1
        },
        {
          day: '02/01',
          index: 2,
          value: flag ? 2 : 1
        },
        {
          day: '03/01',
          index: 3,
          value: flag ? 5 : 1
        },
        {
          day: '04/01',
          index: 4,
          value: 1
        }
      ];
    };

    const getLineData = requireHiLo => {
      if (isCamperdown) {
        return [
          {
            day: '01/01',
            'Stuttering Severity': 8,
            'Fluency Technique': 0
          },
          {
            day: '02/01',
            'Stuttering Severity': 3,
            'Fluency Technique': 4
          },
          {
            day: '03/01',
            'Stuttering Severity': 3,
            'Fluency Technique': 4
          },
          {
            day: '04/01',
            'Stuttering Severity': 3,
            'Fluency Technique': 4
          }
        ];
      }
      if (requireHiLo) {
        return addHighsAndLows([
          {
            day: '01/01',
            'Stuttering Severity': 10
          },
          {
            day: '02/01',
            'Stuttering Severity': 6
          },
          {
            day: '03/01',
            'Stuttering Severity': 4
          },
          {
            day: '04/01',
            'Stuttering Severity': 3
          }
        ]);
      } else {
        return [
          {
            day: '01/01',
            'Stuttering Severity': 10
          },
          {
            day: '02/01',
            'Stuttering Severity': 6
          },
          {
            day: '03/01',
            'Stuttering Severity': 4
          },
          {
            day: '04/01',
            'Stuttering Severity': 3
          }
        ];
      }
    };
    const pieData = isCamperdown
      ? [
          {
            name: 'Daily Measurements',
            value: 4
          },
          {
            name: 'Training Model',
            value: 4
          },
          {
            name: 'Conversations',
            value: 3
          }
        ]
      : [
          {
            name: 'Daily Measurements',
            value: 4
          },
          {
            name: 'Practice Sessions',
            value: 1
          }
        ];

    return (
      <Box mt={2}>
        {isLidcombe || isWestmead ? (
          <>
            <Tabs value={currentTab} onChange={handleChange}>
              <Tab label="Summary" />
            </Tabs>
            {currentTab === 0 && (
              <Box>
                <Card sx={{ pt: 1, pl: 1, my: 1, mb: 2, pb: 2 }} elevation={4}>
                  <TitleSection title="Daily Measurements" />
                  <LineChart
                    domain={domain}
                    width="90%"
                    height={250}
                    data={JSON.stringify(getLineData())}
                    type="line"
                  />
                  <BChart
                    name="Reporting Frequency"
                    type="bubble"
                    showLabels={true}
                    data={getBubbleData()}
                  />
                </Card>
                <Card sx={{ pt: 1, pl: 1, my: 1, mb: 2, pb: 2 }} elevation={4}>
                  <TitleSection title="All Categories" />
                  <PieChart data={pieData} />
                  <BChart
                    type="bubble"
                    showLabels={true}
                    data={getBubbleData('multi')}
                    name="Reporting Frequency"
                  />
                </Card>
              </Box>
            )}
            {currentTab === 1 && (
              <Box>
                <Card sx={{ pt: 1, pl: 1, my: 1, mb: 2, pb: 2 }} elevation={4}>
                  <TitleSection title="All Daily Measurements" />
                  <BChart
                    type="bubble"
                    showLabels={true}
                    data={getBubbleData()}
                    name="Reporting Frequency"
                  />
                </Card>
                <Card sx={{ pt: 1, pl: 1, my: 1, mb: 2, pb: 2 }} elevation={4}>
                  <TitleSection title="Stuttering Severity" />
                  <LineChart
                    name="Average of All Daily Measurements"
                    domain={domain}
                    width="90%"
                    height={250}
                    data={JSON.stringify(getLineData(true))}
                    type="line"
                  />
                </Card>
              </Box>
            )}
            {currentTab === 2 && (
              <Box>
                <Card sx={{ pt: 1, pl: 1, my: 1, mb: 2, pb: 2 }} elevation={4}>
                  <TitleSection title="Natural Conversations" />
                  <BChart
                    type="bubble"
                    showLabels={true}
                    data={getBubbleData()}
                    name="Reporting Frequency"
                  />
                </Card>
                <Card sx={{ pt: 1, pl: 1, my: 1, mb: 2, pb: 2 }} elevation={4}>
                  <TitleSection title="Stuttering Severity" />
                  <LineChart
                    domain={domain}
                    width="90%"
                    height={250}
                    data={JSON.stringify(getLineData(true))}
                    type="line"
                  />
                </Card>
              </Box>
            )}
            {currentTab === 2 && (
              <Box>
                <Card sx={{ pt: 1, pl: 1, my: 1, mb: 2, pb: 2 }} elevation={4}>
                  <TitleSection title="Practice Sessions" />
                  <BChart
                    type="bubble"
                    showLabels={true}
                    data={getBubbleData()}
                    name="Reporting Frequency"
                  />
                </Card>
                <Card sx={{ pt: 1, pl: 1, my: 1, mb: 2, pb: 2 }} elevation={4}>
                  <TitleSection title="Stuttering Severity" />
                  <LineChart
                    domain={domain}
                    width="90%"
                    height={250}
                    data={JSON.stringify(getLineData(true))}
                    type="line"
                  />
                </Card>
              </Box>
            )}
            {currentTab === 3 && (
              <Box>
                <Card sx={{ pt: 1, pl: 1, my: 1, mb: 2, pb: 2 }} elevation={4}>
                  <TitleSection title="Practice Sessions" />
                  <BChart
                    type="bubble"
                    showLabels={true}
                    data={getBubbleData()}
                    name="Reporting Frequency"
                  />
                </Card>
                <Card sx={{ pt: 1, pl: 1, my: 1, mb: 2, pb: 2 }} elevation={4}>
                  <TitleSection title="Stuttering Severity" />
                  <LineChart
                    domain={domain}
                    width="90%"
                    height={250}
                    data={JSON.stringify(getLineData(true))}
                    type="line"
                  />
                </Card>
              </Box>
            )}
          </>
        ) : (
          <>
            <Card sx={{ pt: 1, pl: 1, my: 1, mb: 2, pb: 2 }} elevation={4}>
              <DailyMeasurementsSection />
              <LineChart
                domain={domain}
                width="90%"
                height={250}
                data={JSON.stringify(getLineData())}
                type="line"
              />
              <BChart type="bubble" showLabels={true} data={getBubbleData()} />
            </Card>
            <Card sx={{ pt: 1, pl: 1, my: 1, mb: 2, pb: 2 }} elevation={4}>
              <SummarySection />
              <PieChart data={pieData} />
              <BChart
                type="bubble"
                showLabels={true}
                data={getBubbleData('multi')}
              />
            </Card>
          </>
        )}
      </Box>
    );
  };

  const TitleSection = ({ title }) => (
    <Box mb={1}>
      <FormLabel sx={{ fontSize: 'smaller' }}>{title}</FormLabel>
    </Box>
  );

  const DailyMeasurementsSection = () => (
    <Box mb={1}>
      <FormLabel sx={{ fontSize: 'smaller' }}>Daily Measurements</FormLabel>
    </Box>
  );

  const SummarySection = () => (
    <Box mb={1}>
      <FormLabel sx={{ fontSize: 'smaller' }}>All Records Summary</FormLabel>
    </Box>
  );

  const renderChips = chipsData => {
    return chipsData.map(({ icon: Icon, label, color, tooltip }, index) =>
      tooltip ? (
        <Tooltip title={tooltip} key={`tooltip-${index}`}>
          <Chip
            key={label}
            variant="outlined"
            color={color || 'primary'}
            sx={{ mr: 1, mb: 1 }}
            icon={<Icon />}
            label={label}
          />
        </Tooltip>
      ) : (
        <Chip
          key={`chip-${index}`}
          variant="outlined"
          color={color || 'primary'}
          sx={{ mr: 1, mb: 1 }}
          icon={<Icon />}
          label={label}
        />
      )
    );
  };

  const renderCardSection = (title, chipsData, key) => (
    <Card key={`key-${key}`} sx={{ p: 1.5, my: 2 }} elevation={4}>
      <Box mb={1}>
        <FormLabel sx={{ fontSize: 'smaller' }}>{title}</FormLabel>
      </Box>
      {renderChips(chipsData)}
    </Card>
  );

  const LidcombeEntries = () => {
    const sections = [
      {
        title: 'Daily Measurements',
        chips: [
          {
            icon: BubbleChart,
            label: (
              <Box display="flex" alignItems="center">
                Daily Average{' '}
              </Box>
            )
          },

          {
            icon: Edit,
            label: (
              <Box display="flex" alignItems="center">
                Other Rating
              </Box>
            )
          }
        ]
      },
      {
        title: 'Practice Sessions',
        chips: [
          {
            icon: Waves,
            label: (
              <Box display="flex" alignItems="center">
                Practice Session{' '}
              </Box>
            )
          }
        ]
      },
      {
        title: 'Natural Conversations',
        chips: [
          {
            icon: EscalatorWarningOutlined,
            label: (
              <Box display="flex" alignItems="center">
                Conversation{' '}
              </Box>
            )
          }
        ]
      },
      {
        title: 'Audio Sample',
        chips: [
          {
            icon: MicOutlined,
            label: (
              <Box display="flex" alignItems="center">
                Audio Sample{' '}
                <Tooltip title="Has annotated audio">
                  <GraphicEq color="secondary" sx={{ ml: 1 }} />
                </Tooltip>
              </Box>
            )
          }
        ]
      }
    ];

    return (
      <Box mb={2}>
        {sections.map(({ title, chips }, index) =>
          renderCardSection(title, chips, index)
        )}
      </Box>
    );
  };
  const WestmeadEntries = () => {
    const sections = [
      {
        title: 'Daily Measurements',
        chips: [
          {
            icon: BubbleChart,
            label: (
              <Box display="flex" alignItems="center">
                Daily Average{' '}
              </Box>
            )
          },

          {
            icon: Edit,
            label: (
              <Box display="flex" alignItems="center">
                Other Rating
              </Box>
            )
          }
        ]
      },
      {
        title: 'Practice Sessions',
        chips: [
          {
            icon: Waves,
            label: (
              <Box display="flex" alignItems="center">
                Practice Session{' '}
              </Box>
            )
          }
        ]
      },
      {
        title: 'Prompts',
        chips: [
          {
            icon: EscalatorWarningOutlined,
            label: (
              <Box display="flex" alignItems="center">
                Prompts{' '}
              </Box>
            )
          }
        ]
      },
      {
        title: 'Audio Sample',
        chips: [
          {
            icon: MicOutlined,
            label: (
              <Box display="flex" alignItems="center">
                Audio Sample{' '}
                <Tooltip title="Has annotated audio">
                  <GraphicEq color="secondary" sx={{ ml: 1 }} />
                </Tooltip>
              </Box>
            )
          }
        ]
      }
    ];

    return (
      <Box mb={2}>
        {sections.map(({ title, chips }, index) =>
          renderCardSection(title, chips, index)
        )}
      </Box>
    );
  };
  const OakvilleEntries = () => {
    const sections = [
      {
        title: 'Daily Measurements',
        chips: [
          {
            icon: BubbleChart,
            label: (
              <Box display="flex" alignItems="center">
                Daily Average{' '}
              </Box>
            )
          },
          {
            icon: Edit,
            label: (
              <Box display="flex" alignItems="center">
                Other Rating
              </Box>
            )
          }
        ]
      },
      {
        title: 'Practice Sessions',
        chips: [
          {
            icon: GraphicEq,
            label: (
              <Box display="flex" alignItems="center">
                STS Practice Session{' '}
                <Tooltip title="Inactive when Stage > 1">
                  <VisibilityOffOutlined sx={{ marginLeft: 1 }} />
                </Tooltip>
              </Box>
            )
          },
          {
            icon: Brightness1Outlined,
            label: (
              <Box display="flex" alignItems="center">
                Hybrid Practice Session{' '}
                <Tooltip title="Inactive when Stage < 2">
                  <VisibilityOffOutlined sx={{ marginLeft: 1 }} />
                </Tooltip>
              </Box>
            )
          }
        ]
      },
      {
        title: 'Natural Conversations',
        chips: [
          {
            icon: ThumbUpOutlined,
            label: (
              <Box display="flex" alignItems="center">
                Prompts{' '}
              </Box>
            )
          },
          {
            icon: ForumOutlined,
            label: (
              <Box display="flex" alignItems="center">
                Natural Conversations{' '}
              </Box>
            )
          }
        ]
      },
      {
        title: 'Audio Sample',
        chips: [
          {
            icon: MicOutlined,
            label: (
              <Box display="flex" alignItems="center">
                Audio Sample{' '}
                <Tooltip title="Has annotated audio">
                  <GraphicEq color="secondary" sx={{ ml: 1 }} />
                </Tooltip>
              </Box>
            )
          }
        ]
      }
    ];

    return (
      <Box mb={2}>
        {sections.map(({ title, chips }, index) =>
          renderCardSection(title, chips, index)
        )}
      </Box>
    );
  };
  const CamperdownEntries = () => {
    const sections = [
      {
        title: (
          <Box display="flex" alignItems="center">
            Situations {' - '}
            <em style={{ marginLeft: 2 }}>Active in Stage 3</em>
          </Box>
        ),
        chips: [
          {
            icon: PresentToAll,
            label: (
              <Box display="flex" alignItems="center">
                Presentation{' '}
                <Tooltip title="Has annotated audio">
                  <GraphicEq color="secondary" sx={{ ml: 1 }} />
                </Tooltip>
                <Tooltip title="Inactive when Stage < 3">
                  <VisibilityOffOutlined sx={{ marginLeft: 1 }} />
                </Tooltip>
              </Box>
            )
          },
          {
            icon: ShoppingCartOutlined,
            label: (
              <Box display="flex" alignItems="center">
                Shopping{' '}
                <Tooltip title="Inactive when Stage < 3">
                  <VisibilityOffOutlined sx={{ marginLeft: 1 }} />
                </Tooltip>
              </Box>
            )
          },
          {
            icon: PhoneOutlined,
            label: (
              <Box display="flex" alignItems="center">
                Phone Call{' '}
                <Tooltip title="Has annotated audio">
                  <GraphicEq color="secondary" sx={{ ml: 1 }} />
                </Tooltip>
                <Tooltip title="Inactive when Stage < 3">
                  <VisibilityOffOutlined sx={{ marginLeft: 1 }} />
                </Tooltip>
              </Box>
            )
          },
          {
            icon: GroupOutlined,
            label: (
              <Box display="flex" alignItems="center">
                Meeting{' '}
                <Tooltip title="Has annotated audio">
                  <GraphicEq color="secondary" sx={{ ml: 1 }} />
                </Tooltip>
                <Tooltip title="Inactive when Stage < 3">
                  <VisibilityOffOutlined sx={{ marginLeft: 1 }} />
                </Tooltip>
              </Box>
            )
          },
          {
            icon: ForumOutlined,
            label: (
              <Box display="flex" alignItems="center">
                Conversation{' '}
                <Tooltip title="Has annotated audio">
                  <GraphicEq color="secondary" sx={{ ml: 1 }} />
                </Tooltip>
                <Tooltip title="Inactive when Stage < 3">
                  <VisibilityOffOutlined sx={{ marginLeft: 1 }} />
                </Tooltip>
              </Box>
            )
          },
          {
            icon: ReceiptLongOutlined,
            label: (
              <Box display="flex" alignItems="center">
                Order{' '}
                <Tooltip title="Inactive when Stage < 3">
                  <VisibilityOffOutlined sx={{ marginLeft: 1 }} />
                </Tooltip>
              </Box>
            )
          },
          {
            icon: Edit,
            label: (
              <Box display="flex" alignItems="center">
                Other Rating{' '}
                <Tooltip title="Inactive when Stage < 3">
                  <VisibilityOffOutlined sx={{ marginLeft: 1 }} />
                </Tooltip>
              </Box>
            )
          }
        ]
      },
      {
        title: (
          <Box display="flex" alignItems="center">
            Daily Measurements{' - '}
            <em style={{ marginLeft: 2 }}>Active in Stage 2</em>
          </Box>
        ),
        chips: [
          {
            icon: BubbleChart,
            label: (
              <Box display="flex" alignItems="center">
                Daily Measurements{' '}
                <Tooltip title="Inactive when Stage < 2">
                  <VisibilityOffOutlined sx={{ marginLeft: 1 }} />
                </Tooltip>
              </Box>
            )
          }
        ]
      },
      {
        title: 'Exercises',
        chips: [
          {
            icon: LocalLibraryOutlined,
            label: (
              <Box display="flex" alignItems="center">
                Training Model{' '}
              </Box>
            )
          }
        ]
      },
      {
        title: (
          <Box display="flex" alignItems="center">
            Practice{' - '}
            <em style={{ marginLeft: 2 }}>Active in Stage 2</em>
          </Box>
        ),
        chips: [
          {
            icon: HourglassTopOutlined,
            label: (
              <Box display="flex" alignItems="center">
                Summarising{' '}
                <Tooltip title="Has annotated audio">
                  <GraphicEq color="secondary" sx={{ ml: 1 }} />
                </Tooltip>
                <Tooltip title="Inactive when Stage < 2">
                  <VisibilityOffOutlined sx={{ marginLeft: 1 }} />
                </Tooltip>
              </Box>
            )
          },
          {
            icon: NavigationOutlined,
            label: (
              <Box display="flex" alignItems="center">
                Planning{' '}
                <Tooltip title="Has annotated audio">
                  <GraphicEq color="secondary" sx={{ ml: 1 }} />
                </Tooltip>
                <Tooltip title="Inactive when Stage < 2">
                  <VisibilityOffOutlined sx={{ marginLeft: 1 }} />
                </Tooltip>
              </Box>
            )
          },
          {
            icon: ForumOutlined,
            label: (
              <Box display="flex" alignItems="center">
                Practice Conversation{' '}
                <Tooltip title="Has annotated audio">
                  <GraphicEq color="secondary" sx={{ ml: 1 }} />
                </Tooltip>
                <Tooltip title="Inactive when Stage < 2">
                  <VisibilityOffOutlined sx={{ marginLeft: 1 }} />
                </Tooltip>
              </Box>
            )
          },
          {
            icon: PanoramaWideAngleOutlined,
            label: (
              <Box display="flex" alignItems="center">
                Simulated Situation{' '}
                <Tooltip title="Has annotated audio">
                  <GraphicEq color="secondary" sx={{ ml: 1 }} />
                </Tooltip>
                <Tooltip title="Inactive when Stage < 2">
                  <VisibilityOffOutlined sx={{ marginLeft: 1 }} />
                </Tooltip>
              </Box>
            )
          },
          {
            icon: PersonOutline,
            label: (
              <Box display="flex" alignItems="center">
                Monologue{' '}
                <Tooltip title="Has annotated audio">
                  <GraphicEq color="secondary" sx={{ ml: 1 }} />
                </Tooltip>
                <Tooltip title="Inactive when Stage < 2">
                  <VisibilityOffOutlined sx={{ marginLeft: 1 }} />
                </Tooltip>
              </Box>
            )
          },
          {
            icon: LocalLibraryOutlined,
            label: (
              <Box display="flex" alignItems="center">
                Reading{' '}
                <Tooltip title="Has annotated audio">
                  <GraphicEq color="secondary" sx={{ ml: 1 }} />
                </Tooltip>
                <Tooltip title="Inactive when Stage < 2">
                  <VisibilityOffOutlined sx={{ marginLeft: 1 }} />
                </Tooltip>
              </Box>
            )
          }
        ]
      },
      {
        title: 'Clinic Session',
        chips: [
          {
            icon: LocalHospitalOutlined,
            label: (
              <Box display="flex" alignItems="center">
                Clinic Session{' '}
                <Tooltip title="Has annotated audio">
                  <GraphicEq color="secondary" sx={{ ml: 1 }} />
                </Tooltip>
              </Box>
            )
          }
        ]
      },
      {
        title: 'Audio Sample',
        chips: [
          {
            icon: MicOutlined,
            label: (
              <Box display="flex" alignItems="center">
                Audio Sample{' '}
                <Tooltip title="Has annotated audio">
                  <GraphicEq color="secondary" sx={{ ml: 1 }} />
                </Tooltip>
              </Box>
            )
          }
        ]
      }
    ];

    return (
      <Box mb={2}>
        {sections.map(({ title, chips }, index) =>
          renderCardSection(title, chips, index)
        )}
      </Box>
    );
  };

  const DefaultEntries = () => {
    const sections = [
      {
        title: 'Clinic Session',
        chips: [
          {
            icon: Face4,
            label: (
              <Box display="flex" alignItems="center">
                Follow-Up{' '}
                <Tooltip title="Has annotated audio">
                  <GraphicEq color="secondary" sx={{ ml: 1 }} />
                </Tooltip>
              </Box>
            )
          }
        ]
      },
      {
        title: 'Daily Measurements',
        chips: [{ icon: BubbleChart, label: 'Daily Average' }]
      },
      {
        title: 'Situations',
        chips: [
          { icon: Coffee, label: 'Dinner' },
          { icon: Fastfood, label: 'Bath Time' },
          { icon: People, label: 'Shopping' }
        ]
      }
    ];

    return (
      <Box mb={2}>
        {sections.map(({ title, chips }, index) =>
          renderCardSection(title, chips, index)
        )}
      </Box>
    );
  };

  const EntriesComponent = isCamperdown
    ? CamperdownEntries
    : isLidcombe
    ? LidcombeEntries
    : isWestmead
    ? WestmeadEntries
    : isOakville
    ? OakvilleEntries
    : DefaultEntries;

  return (
    <Box>
      <Box sx={{ p: 1, m: 2 }}>
        <Box>
          <Box mb={2}>
            <Typography variant="h5">
              {formatProgram(selectedTemplate)} Template
            </Typography>
          </Box>
        </Box>
        <Typography variant="body1">Entries</Typography>
        <EntriesComponent />
      </Box>
      <Box sx={{ p: 1, m: 2 }}>
        <Typography variant="body1">Insights</Typography>
        <InsightsSection selectedTemplate={selectedTemplate} />
      </Box>
      <Box sx={{ p: 1, m: 2 }}>
        <Typography variant="body1">Sessions</Typography>
        <SessionsSection />
      </Box>
      <Box sx={{ p: 1, m: 2 }}>
        <Typography variant="body1">Resources</Typography>
        <ResourcesSection />
      </Box>
    </Box>
  );
}

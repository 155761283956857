import { useTheme } from '@emotion/react';
import { Player } from '@lottiefiles/react-lottie-player';
import { Box } from '@mui/material';

import lightLottie from './welcomeLottie.json';
import darkLottie from './welcomeLottieDark.json';

export default function WelcomeLottie({ setHasPlayed }) {
  const handleEvent = event => {
    if (event === 'complete') {
      setHasPlayed(true);
    }
  };
  let animationData;
  const theme = useTheme();
  if (theme.palette.mode === 'light') {
    animationData = darkLottie;
  } else {
    animationData = lightLottie;
  }
  return (
    <Box
      sx={{ height: '100%', display: 'flex' }}
      justifyContent="center"
      alignItems="center"
    >
      <Player
        style={{ maxWidth: '400px', height: 'auto' }}
        onEvent={handleEvent}
        autoplay
        src={animationData}
      />
    </Box>
  );
}

import { memo, useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { Box, Collapse, Skeleton, Typography } from '@mui/material';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import { useSnackbar } from 'context/SnackBar';
import { updateClient, updateClinician } from 'graphql/mutations';
import mixpanel from 'mixpanel-browser';

import { ErrorMessage, SettingHeader } from 'components/atoms';
import { ChangeName } from 'components/auth';

function AccountName() {
  const { user } = useAuthenticator(context => [context.user]);
  const isClinician = user.attributes['custom:type'] === 'clinician';
  const [updateName, setUpdateName] = useState(false);
  const [firstName, setFirstName] = useState(user.attributes.given_name);
  const [lastName, setLastName] = useState(user.attributes.family_name);
  const [changingName, setChangingName] = useState(false);
  const [error, setError] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  async function handleChangeName() {
    try {
      setChangingName(true);
      await Auth.updateUserAttributes(user, {
        given_name: firstName,
        family_name: lastName
      });
      const data = {
        id: user.username,
        firstName: firstName,
        lastName: lastName
      };
      await API.graphql(
        graphqlOperation(isClinician ? updateClinician : updateClient, {
          input: data
        })
      );
      mixpanel.track('change_name');
      enqueueSnackbar('Name changed successfully!', {
        severity: 'success'
      });
    } catch (err) {
      enqueueSnackbar('Error changing name.', {
        severity: 'error'
      });
      setError(err.message);
    } finally {
      setChangingName(false);
    }
  }

  return (
    <Box p={1} my={2}>
      <SettingHeader label="Name" state={updateName} setState={setUpdateName} />
      <Box
        p={1}
        sx={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}
      >
        {user.attributes.given_name === undefined ? (
          <Skeleton />
        ) : (
          <Typography
            variant="h6"
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
            {user.attributes.given_name + ' ' + user.attributes.family_name}
          </Typography>
        )}
      </Box>

      <Collapse in={updateName}>
        <Box>
          <ChangeName
            firstName={firstName}
            setFirstName={setFirstName}
            lastName={lastName}
            setLastName={setLastName}
            changingName={changingName}
            handleChangeName={handleChangeName}
          />
          {Boolean(error) && <ErrorMessage message={error} />}
        </Box>
      </Collapse>
    </Box>
  );
}

export default memo(AccountName);

import * as React from 'react';
import { useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { useTheme } from '@emotion/react';
import { Add, ExitToApp, Help, Settings } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  Box,
  Container,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  SwipeableDrawer,
  Toolbar
} from '@mui/material';
import { Auth } from 'aws-amplify';
import { constants } from 'common/constants';
import { useAppContext } from 'context';
import { useNavigate } from 'react-router-dom';

import { ProfileAvatar } from 'components/atoms';
import Logo from 'components/atoms/Logo';
import { HtmlTooltip as Tooltip } from 'components/atoms/Tooltips';

function ListItemLink({ item, navigate }) {
  return (
    <ListItem key={item.name} disablePadding>
      <ListItemButton onClick={() => navigate(`${item.route}`)}>
        <ListItemText primary={item.name} />
      </ListItemButton>
    </ListItem>
  );
}

function DrawerList({
  toggleDrawer,
  anchor,
  navigate,
  signOut,
  MenuItemsToUse
}) {
  const handleClose = toggleDrawer(anchor, false);

  return (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={handleClose}
      onKeyDown={handleClose}
    >
      <Box ml={2} mt={1}>
        <Logo variant="dark" />
      </Box>
      <List>
        {MenuItemsToUse.map(item => (
          <ListItemLink key={item.name} item={item} navigate={navigate} />
        ))}

        <Divider />

        <ListItem disablePadding>
          <ListItemButton onClick={() => navigate('/settings')}>
            <ListItemText primary="Settings" />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton onClick={signOut}>
            <ListItemText primary="Sign Out" />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
}

function SwipeableTemporaryDrawer({ MenuItemsToUse }) {
  const navigate = useNavigate();
  const { dispatch } = useAppContext();

  async function signOut() {
    try {
      navigate('/');
      dispatch({ type: 'clear_all' });
      await Auth.signOut();
    } catch (err) {
      console.log('error signing out...');
    }
  }

  const [appBar, setAppBar] = useState({
    left: false
  });

  const toggleDrawer = (anchor, open) => event => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setAppBar({ ...appBar, [anchor]: open });
  };
  return (
    <div>
      {['left'].map(anchor => (
        <React.Fragment key={anchor}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={toggleDrawer(anchor, true)}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <SwipeableDrawer
            anchor={anchor}
            open={appBar[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            <DrawerList
              MenuItemsToUse={MenuItemsToUse}
              toggleDrawer={toggleDrawer}
              anchor={anchor}
              navigate={navigate}
              signout={signOut}
            />
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}

function ResponsiveAppBar({ setOpenHelpModal }) {
  const navigate = useNavigate();
  const { dispatch, state } = useAppContext();
  const { user } = useAuthenticator(context => [context.user]);
  const hasResource = user.attributes['custom:hasResources'] === 'true';

  const theme = useTheme();

  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenUserMenu = event => setAnchorElUser(event.currentTarget);
  const handleCloseUserMenu = () => setAnchorElUser(null);

  const handleSignOut = async () => {
    dispatch({ type: 'clear_all' });
    try {
      await Auth.signOut();
    } catch (err) {
      console.log('error signing out...', err);
    }
  };

  const baseMenuItems = [
    {
      name: 'New',
      route: '/'
    },
    {
      name: 'Entries',
      route: '/entries'
    },
    {
      name: 'Insights',
      route: '/insights'
    },
    {
      name: 'Notes',
      route: '/sessions'
    }
  ];

  const resourcesItem = { name: 'Resources', route: '/resources' };

  const MenuItemsToUse = hasResource
    ? [...baseMenuItems, resourcesItem]
    : baseMenuItems;

  return (
    <AppBar
      style={{ background: theme.palette.primary.dark }}
      position="sticky"
    >
      <Container maxWidth="xl">
        <Toolbar sx={{ justifyContent: 'space-between' }} disableGutters>
          <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }}>
            <SwipeableTemporaryDrawer MenuItemsToUse={MenuItemsToUse} />
          </Box>
          <Logo />
          <NavBar
            MenuItemsToUse={MenuItemsToUse}
            theme={theme}
            state={state}
            navigate={navigate}
          />
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton sx={{ mr: 1 }} onClick={() => setOpenHelpModal(true)}>
              <Help sx={{ color: 'white' }} />
            </IconButton>
            <UserMenu user={user} handleOpenUserMenu={handleOpenUserMenu} />
          </Box>
          <UserMenuList
            anchorElUser={anchorElUser}
            handleCloseUserMenu={handleCloseUserMenu}
            navigate={navigate}
            signOut={handleSignOut}
          />
        </Toolbar>
      </Container>
    </AppBar>
  );
}

function NavBar({ navigate, MenuItemsToUse, theme }) {
  return (
    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
      {MenuItemsToUse.map(item => (
        <NavBarItem
          theme={theme}
          key={item.name}
          item={item}
          navigate={navigate}
        />
      ))}
    </Box>
  );
}

const isItemSelected = (pathname, itemRoute) => {
  return (
    pathname === itemRoute ||
    (pathname.includes('/resource/') && itemRoute === '/resources') ||
    (pathname.includes('/session/') && itemRoute === '/sessions') ||
    (pathname.includes('/record/') && itemRoute === '/records') ||
    (pathname.includes('/entry') && itemRoute === '/') ||
    (pathname.includes('/entries/') && itemRoute === '/entries')
  );
};

const shouldDisplayAdd = itemRoute => {
  return itemRoute === '/';
};

function NavBarItem({ item, navigate, theme }) {
  const isSelected = isItemSelected(window.location.pathname, item.route);
  const displayAdd = shouldDisplayAdd(item.route);

  return (
    <MenuItem
      key={item.name}
      onClick={() => navigate(item.route)}
      sx={{
        my: 2,
        color: 'white',
        textTransform: 'uppercase',
        fontWeight: 600,
        fontSize: '0.9rem',
        letterSpacing: '0.04rem',
        '&.Mui-selected': {
          backgroundColor: theme.palette.primary.darkest,
          '&:hover': {
            backgroundColor: theme.palette.primary.darkHover
          }
        },
        '&:hover': {
          backgroundColor: theme.palette.primary.darkHover
        }
      }}
      selected={isSelected}
    >
      {displayAdd && <Add />}
      {item.name}
    </MenuItem>
  );
}

function UserMenu({ user, handleOpenUserMenu }) {
  return (
    <Box sx={{ flexGrow: 0 }}>
      <Stack alignItems={'center'} direction="row" spacing={2}>
        <Tooltip title="Account">
          <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
            <ProfileAvatar
              menu
              profile={{
                id: user.username,
                firstName: user.attributes.given_name,
                lastName: user.attributes.family_name,
                picture: Boolean(user.attributes.picture)
              }}
            />
          </IconButton>
        </Tooltip>
      </Stack>
    </Box>
  );
}

function UserMenuListItem({ path, icon, label, onClick }) {
  return (
    <MenuItem
      selected={window.location.pathname.includes(path)}
      onClick={onClick}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText>{label}</ListItemText>
    </MenuItem>
  );
}

function UserMenuList({
  anchorElUser,
  handleCloseUserMenu,
  navigate,
  signOut
}) {
  const menuItems = [
    {
      path: '/settings',
      icon: <Settings fontSize="small" />,
      label: 'Settings',
      onClick: () => navigate('/settings')
    },
    {
      path: constants.contactUrl,
      icon: <Help fontSize="small" />,
      label: 'Help',
      onClick: () => window.open('mailto:support@speechfit.io', '_blank')
    },
    {
      path: null,
      icon: <ExitToApp fontSize="small" />,
      label: 'Sign out',
      onClick: signOut
    }
  ];

  const menuItemElements = menuItems.flatMap((item, index) =>
    index === 1
      ? [
          <UserMenuListItem key={index} {...item} />,
          <Divider key={'divider'} />
        ]
      : [<UserMenuListItem key={index} {...item} />]
  );

  return (
    <Menu
      sx={{ mt: '45px' }}
      id="menu-appbar"
      anchorEl={anchorElUser}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={Boolean(anchorElUser)}
      onClose={handleCloseUserMenu}
    >
      {menuItemElements}
    </Menu>
  );
}

export default ResponsiveAppBar;

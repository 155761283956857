import React, { useEffect, useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { Add, SettingsAccessibility } from '@mui/icons-material';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  ButtonGroup,
  Container,
  Divider,
  Grid,
  Hidden,
  Stack,
  Typography
} from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import { useAppContext } from 'context';
import { clientsByClinicianID } from 'graphql/queries';

import { LoadingAtom } from 'components/atoms';
import { Clinician } from 'components/layout/';
import { ClinicianOnboarding, SearchBar } from 'components/molecules';
import ClientCard from 'components/molecules/ClientCard';

export default React.memo(function ClinicianHome() {
  const { dispatch, state } = useAppContext();
  let { user } = useAuthenticator(context => [context.user]);
  const [groupedClients, setGroupedClients] = useState();
  const [showNoClientsBox, setShowNoClientsBox] = useState(false);

  const [loading, setLoading] = useState(false);
  const [clients, setClients] = useState();
  const [search, setSearch] = useState('');

  useEffect(() => {
    async function fetchClients() {
      setLoading(true);

      const { data } = await API.graphql(
        graphqlOperation(clientsByClinicianID, {
          clinicianID: user.username
        })
      );

      const clientData = data.clientsByClinicianID.items;
      setClients(clientData);
    }

    fetchClients();
    return () => {
      setClients([]);
    };
  }, [user, state.modal]);

  useEffect(() => {
    if (!clients) return;

    let filteredClients = clients.filter(client => {
      const clientFullName1 =
        `${client?.clientFirstName} ${client?.clientLastName}`.toLowerCase();
      const clientFullName2 =
        `${client?.firstName} ${client?.lastName}`.toLowerCase();
      const lowerSearch = search.toLowerCase();

      return (
        (client?.email && client?.email.toLowerCase().includes(lowerSearch)) ||
        (client?.clientFirstName &&
          client?.clientFirstName.toLowerCase().includes(lowerSearch)) ||
        (client?.clientLastName &&
          client?.clientLastName.toLowerCase().includes(lowerSearch)) ||
        (client?.firstName &&
          client?.firstName.toLowerCase().includes(lowerSearch)) ||
        (client?.lastName &&
          client?.lastName.toLowerCase().includes(lowerSearch)) ||
        clientFullName1.includes(lowerSearch) ||
        clientFullName2.includes(lowerSearch)
      );
    });

    let grouped = filteredClients.reduce((r, e) => {
      let nameToUse = e.clientFirstName || e.firstName;
      let letter = nameToUse[0].toUpperCase();
      if (!r[letter]) r[letter] = { letter, children: [e] };
      else r[letter].children.push(e);
      return r;
    }, {});

    let groups;
    if (grouped) {
      groups = Object.values(grouped);
      groups.sort(function (a, b) {
        if (a.letter < b.letter) {
          return -1;
        }
        if (a.letter > b.letter) {
          return 1;
        }
        return 0;
      });
    }

    setLoading(false);
    setGroupedClients(groups);

    const timer = setTimeout(() => {
      setShowNoClientsBox(true);
    }, 500);

    // Cleanup function
    return () => {
      clearTimeout(timer);
      setGroupedClients([]);
    };
  }, [clients, search]);

  return (
    <Clinician
      props={{ title: 'Dashboard' }}
      AppBarComponents={
        <Box
          pl={4}
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" spacing={2}>
            <Typography color="text.primary" variant="h5">
              Clients
            </Typography>
          </Stack>
          <Stack direction="row" spacing={2} alignItems="center">
            <Hidden implementation="css" smDown>
              <Button
                size="small"
                startIcon={<Add />}
                onClick={() => dispatch({ type: 'set_modal', modal: true })}
              >
                Add Client
              </Button>
            </Hidden>
            <SearchBar state={search} setState={setSearch} />
          </Stack>
        </Box>
      }
    >
      <Container maxWidth="xl" sx={{ mb: 6 }}>
        {loading ? (
          <LoadingAtom />
        ) : (
          <Box>
            {user.attributes['custom:onboarding'] !== '5' && (
              <ClinicianOnboarding />
            )}
            <Box pb={4} my={1}>
              {groupedClients &&
                groupedClients.length > 0 &&
                groupedClients.map(group => (
                  <Box key={`ref-${group.letter}`} mb={1} mt={2}>
                    <Typography color="text.accent" variant="h3">
                      {group.letter}
                    </Typography>
                    <Divider sx={{ opacity: 0.4 }} />
                    <Grid container sx={{ mt: 1 }} spacing={2}>
                      {group.children.map(client => (
                        <ClientCard key={client?.id} client={client} />
                      ))}
                    </Grid>
                  </Box>
                ))}
              {!loading && showNoClientsBox && !groupedClients?.length && (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  minHeight="80vh"
                >
                  <Alert
                    icon={<SettingsAccessibility />}
                    sx={{ width: 400, maxWidth: '100%' }}
                    severity="info"
                  >
                    <AlertTitle>No Clients Found</AlertTitle>
                    <Typography variant="body2">
                      Looking to try SpeechFit before inviting client? Use an
                      email alias (e.g. yourname+test@email.com) to add a test
                      client.
                    </Typography>
                    <Box mt={2}>
                      <ButtonGroup size="small">
                        <Button
                          variant="contained"
                          onClick={() =>
                            dispatch({ type: 'set_modal', modal: true })
                          }
                        >
                          Add a Client
                        </Button>
                        <Button
                          target="_blank"
                          href="https://calendly.com/oseh-speechfit/speechfit-demo"
                        >
                          Schedule Demo
                        </Button>
                      </ButtonGroup>
                    </Box>
                  </Alert>
                </Box>
              )}
            </Box>
          </Box>
        )}
      </Container>
    </Clinician>
  );
});

import React, { useEffect, useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { Box, Grid, Typography } from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import { getClient } from 'graphql/queries';

import { Section } from 'components/atoms';
import { ClientProgram } from 'components/molecules';

import { toRomanNumeral } from 'utils/toRomanNumerals';

function TreatmentDetails() {
  const { user } = useAuthenticator(context => [context.user]);
  const [client, setClient] = useState(null);

  useEffect(() => {
    if (user) {
      const queryClient = async () => {
        const res = await API.graphql(
          graphqlOperation(getClient, { id: user.username })
        );
        setClient(res.data.getClient);
      };
      queryClient();
    }
  }, [user]);

  return (
    <Grid container spacing={2}>
      {client && client?.program && (
        <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
          <ClientProgram />
        </Grid>
      )}

      {client && client?.stage && (
        <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
          <Section label="Stage">
            <Box p={1}>
              <Typography ml={2} variant="h6">
                Stage {toRomanNumeral(client?.stage)}
              </Typography>
            </Box>
          </Section>
        </Grid>
      )}
    </Grid>
  );
}

export default React.memo(TreatmentDetails);

import { useEffect, useState } from 'react';

import { LoadingButton } from '@mui/lab';
import { Box, Collapse, Grid, TextField } from '@mui/material';
import isEmail from '@rearguard/is-email';
import { API, graphqlOperation } from 'aws-amplify';
import { getCliniciansByEmail } from 'graphql/queries';

import { BasicUserCard } from 'components/atoms';

export default function AddClinician({
  clinicianEmail,
  setClinicianEmail,
  clinicianFirstName,
  setClinicianFirstName,
  clinicianLastName,
  setClinicianLastName,
  addingClinician,
  handleAddClinician,
  setClinicianExists,
  clinicianExists
}) {
  const [clinicianResults, setClinicianResults] = useState(null);
  const [emailError, setEmailError] = useState('');
  const [showEmailError, setShowEmailError] = useState(false);
  const [showName, setShowName] = useState(false);
  const [selectedClinician, setSelectedClinician] = useState(null);
  const [firstNameError, setFirstNameError] = useState('');
  const [showFirstNameError, setShowFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState('');
  const [showLastNameError, setShowLastNameError] = useState(false);

  useEffect(() => {
    setShowName(
      isEmail(clinicianEmail) &&
        (!clinicianResults || clinicianResults.length === 0)
    );
  }, [clinicianEmail, clinicianResults]);

  const handleOnSubmit = () => {
    let valid = true;
    if (!isEmail(clinicianEmail)) {
      setEmailError('Invalid email address');
      setShowEmailError(true);
      valid = false;
    }
    if (showName) {
      if (clinicianFirstName.trim() === '') {
        setFirstNameError('First name is required');
        setShowFirstNameError(true);
        valid = false;
      }
      if (clinicianLastName.trim() === '') {
        setLastNameError('Last name is required');
        setShowLastNameError(true);
        valid = false;
      }
    }
    if (!valid) return;

    if (clinicianExists) {
      if (selectedClinician) {
        handleAddClinician(selectedClinician);
      } else {
        handleAddClinician(clinicianResults[0]);
      }
    } else {
      handleAddClinician();
    }
  };

  const lookUpClinician = async search => {
    setShowEmailError(false); // Hide errors when typing again
    const newEmail = search.target.value;
    setClinicianEmail(newEmail);

    if (newEmail !== '') {
      const res = await API.graphql(
        graphqlOperation(getCliniciansByEmail, { email: newEmail.trim() })
      );
      const items = res.data.getCliniciansByEmail.items;
      setClinicianResults(items);
      setClinicianExists(items.length > 0);
    }
  };

  const selectClinician = clinician => {
    if (selectedClinician?.id === clinician.id) {
      setSelectedClinician(null); // Unselect
    } else {
      setSelectedClinician(clinician); // Select
    }
  };

  return (
    <Box>
      <Collapse in={!Boolean(selectedClinician)}>
        <Box my={1}>
          <TextField
            fullWidth
            size="small"
            label="Clinician Email Address"
            value={clinicianEmail}
            onChange={lookUpClinician}
            error={showEmailError}
            helperText={showEmailError ? emailError : ''}
          />
        </Box>
      </Collapse>
      <Collapse in={showName}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <Box my={1}>
              <TextField
                fullWidth
                size="small"
                label="Clinician First Name"
                value={clinicianFirstName}
                onChange={e => {
                  setClinicianFirstName(e.target.value);
                  setShowFirstNameError(false); // Hide error when typing again
                }}
                error={showFirstNameError}
                helperText={showFirstNameError ? firstNameError : ''}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box my={1}>
              <TextField
                fullWidth
                size="small"
                label="Clinician Last Name"
                value={clinicianLastName}
                onChange={e => {
                  setClinicianLastName(e.target.value);
                  setShowLastNameError(false);
                }}
                error={showLastNameError}
                helperText={showLastNameError ? lastNameError : ''}
              />
            </Box>
          </Grid>
        </Grid>
      </Collapse>
      <Collapse in={clinicianResults?.length > 0}>
        {clinicianResults?.map(clinician => (
          <BasicUserCard
            key={clinician.id}
            profile={clinician}
            add={true}
            action={selectClinician}
            search={true}
            actionLoading={addingClinician}
            selectedClinician={selectedClinician}
          />
        ))}
      </Collapse>
      <Box my={2}>
        <LoadingButton
          size="small"
          variant="contained"
          onClick={handleOnSubmit}
          loading={addingClinician}
          fullWidth
        >
          Grant Clinician Access
        </LoadingButton>
      </Box>
    </Box>
  );
}

import { useEffect, useState } from 'react';

import { Clear, Face, RadioButtonChecked } from '@mui/icons-material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  CircularProgress,
  Collapse,
  Divider,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import { listClinics } from 'graphql/customQueries';
import {
  cliniciansByClinicByRole,
  getCliniciansByEmail
} from 'graphql/queries';

import { obscureEmail } from 'utils/obscureEmails';

export default function StepOne({
  setShouldShowAdmin,
  adminEmail,
  setAdminEmail,
  adminFirstName,
  setAdminFirstName,
  adminLastName,
  setAdminLastName,
  admins,
  setAdmins,
  hasAdmin,
  setHasAdmin,
  logo,
  setLogo,
  name,
  setName,
  website,
  setWebsite,
  errors,
  setErrors,
  role,
  setRole,
  setSelectedClinic,
  selectedClinic,
  cliniciansInClinic,
  setCliniciansInClinic,
  setHasClinician,
  hasClinician,
  clinician,
  setClinician,
  howManyClinicians,
  setHowManyClinicians
}) {
  const [foundClinics, setFoundClinics] = useState([]);
  const [searching, setSearching] = useState(false);
  const [searchingClinicians, setSearchingClinicians] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [showPrefix, setShowPrefix] = useState(true);
  const addNewClinic = false;

  useEffect(() => {
    if (foundClinics.length === 0 || (selectedClinic.id && role !== 'admin')) {
      setShouldShowAdmin(true);
    } else {
      setShouldShowAdmin(false);
    }
  }, [foundClinics, addNewClinic, selectedClinic, role, setShouldShowAdmin]);

  const handleLogoChange = event => {
    const file = event.target.files[0];
    if (file) {
      setLogo(file);
    }
  };

  const handleClearLogo = () => {
    setLogo(null);
  };

  const handleChange = (setter, field) => event => {
    const { value } = event.target;
    setter(value);
    if (errors && errors[field]) {
      setErrors({ ...errors, [field]: '' });
    }
  };

  const handleNameChange = async e => {
    setSearching(true);
    handleChange(setName, 'name')(e);
    const value = e.target.value;
    if (value) {
      const variables = {
        filter: {
          or: [
            { name: { contains: `${value}` } },
            { name: { contains: `${value.toLowerCase()}` } }
          ]
        }
      };
      try {
        const { data } = await API.graphql(
          graphqlOperation(listClinics, variables)
        );
        const clinics = data.listClinics.items;
        setFoundClinics(clinics);
      } catch (err) {
        console.log(err);
      } finally {
        setSearching(false);
      }
    } else {
      setSearching(false);
      setFoundClinics([]);
    }
  };

  const handleClinicianEmailChange = async e => {
    try {
      setSearchingClinicians(true);
      const value = e.target.value;
      setAdminEmail(value);
      const { data } = await API.graphql(
        graphqlOperation(getCliniciansByEmail, {
          email: value
        })
      );
      const clinicians = data.getCliniciansByEmail.items;
      if (clinicians.length > 0) {
        setHasClinician(true);
        setClinician(clinicians[0]);
      } else {
        setHasClinician(false);
        setClinician({});
      }
    } catch (err) {
      console.log('No results');
    } finally {
      setSearchingClinicians(false);
    }
  };

  useEffect(() => {
    const setUsers = () => {
      const clinicians = selectedClinic.clinicians.items;
      setHowManyClinicians(clinicians.length);
      setCliniciansInClinic(clinicians);
    };
    const searchAdmins = async () => {
      const { data } = await API.graphql(
        graphqlOperation(cliniciansByClinicByRole, {
          clinicID: selectedClinic.id,
          role: {
            eq: 'admin'
          }
        })
      );
      const admins = data.cliniciansByClinicByRole.items;
      if (admins.length > 0) {
        setAdmins(admins);
        setHasAdmin(true);
        setRole('clinician');
      } else {
        setAdmins([]);
        setHasAdmin(false);
      }
    };
    if (selectedClinic.id) {
      setUsers();
      searchAdmins();
    }
    return () => {
      setHowManyClinicians();
      setCliniciansInClinic([]);
      setAdmins([]);
      setHasAdmin(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClinic]);

  const handleToggleRole = role => {
    setRole(role);
  };

  useEffect(() => {
    if (!addNewClinic) {
      setHasAdmin(false);
      setHasClinician(false);
      setHowManyClinicians();
      setSelectedClinic({});
      setAdmins([]);
    }
  }, [
    addNewClinic,
    setSelectedClinic,
    setAdmins,
    setHasAdmin,
    setHasClinician,
    setHowManyClinicians
  ]);

  useEffect(() => {
    // Determine whether to show the "https://" prefix based on the current input
    const hasProtocol = /^https?:\/\//i.test(website);
    setShowPrefix(!hasProtocol);
  }, [website]);

  const isValidUrl = url => {
    try {
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  };

  const handleWebsiteChange = event => {
    const value = event.target.value;
    setWebsite(value); // Update the value in real-time

    if (typingTimeout) clearTimeout(typingTimeout);

    setTypingTimeout(
      setTimeout(() => {
        // Only prepend "https://" if the value doesn't already contain a protocol
        if (isValidUrl(value) && !/^https?:\/\//i.test(value)) {
          setWebsite(`https://${value}`);
        }
      }, 1000) // 1 second delay after user stops typing
    );
  };

  function RoleChip({ label, disabled, onClick, selected, customMsg, grayed }) {
    return (
      <Tooltip
        placement="top"
        title={selected ? '' : customMsg ? customMsg : null}
      >
        <Chip
          onClick={disabled ? null : onClick}
          clickable={!disabled}
          size="small"
          label={label}
          color={grayed ? 'default' : selected ? 'primary' : 'default'}
          variant={grayed ? 'filled' : selected ? 'filled' : 'outlined'}
        />
      </Tooltip>
    );
  }

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Box sx={{ mt: 1, mb: 2 }}>
        <Stack direction="row" sx={{ mb: 1 }}>
          <Typography variant="h6">Practice Details</Typography>
        </Stack>
        <Divider sx={{ opacity: 0.3 }} />
      </Box>

      <Grid container sx={{ alignItems: 'center' }}>
        <Grid item xs={12}>
          <Collapse in={Boolean(!selectedClinic.name)}>
            <TextField
              error={Boolean(errors.name)}
              helperText={errors.name}
              value={name}
              onChange={handleNameChange}
              size="small"
              fullWidth
              label="Practice Name"
              variant="filled"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {searching ? <CircularProgress size={20} /> : null}
                  </InputAdornment>
                )
              }}
            />
          </Collapse>
        </Grid>

        {selectedClinic.name && (
          <Grid item xs={12}>
            <FormLabel>Practice Name</FormLabel>
            <Card
              elevation={0}
              sx={{
                my: 1,
                backgroundColor: 'background.default'
              }}
            >
              <CardHeader
                title={selectedClinic.name}
                action={
                  <IconButton onClick={() => setSelectedClinic({})}>
                    <RadioButtonChecked />
                  </IconButton>
                }
              />
            </Card>
          </Grid>
        )}

        <Grid item xs={12}>
          <Collapse
            in={
              Boolean(selectedClinic.id && !addNewClinic) ||
              (hasClinician && !hasAdmin && clinician.role === 'admin')
            }
          >
            <Box mt={1}>
              <Alert
                severity={
                  howManyClinicians &&
                  (role === 'admin' || role === 'practicemanager') &&
                  !hasAdmin
                    ? 'warning'
                    : hasAdmin
                    ? 'success'
                    : hasClinician && clinician.role === 'admin'
                    ? 'warning'
                    : 'info'
                }
              >
                <AlertTitle>
                  {howManyClinicians &&
                  (role === 'admin' || role === 'practicemanager') &&
                  !hasAdmin
                    ? `You'll need confirmation from clinicians in this practice.`
                    : hasAdmin
                    ? 'We found your Admin.'
                    : hasClinician && clinician.role === 'admin'
                    ? clinician.clinic?.name
                      ? `Did you mean ${clinician.clinic?.name}`
                      : 'Did you type the practice name correctly?'
                    : role === 'admin'
                    ? "You're the first one here!"
                    : 'This practice has no admins!'}{' '}
                </AlertTitle>
                {howManyClinicians &&
                (role === 'admin' || role === 'practicemanager') &&
                !hasAdmin ? (
                  <>
                    Since there {howManyClinicians === 1 ? 'is' : 'are'} already{' '}
                    {howManyClinicians} clinician
                    {howManyClinicians === 1 ? '' : 's'} in this practice,
                    you'll need confirmation from at least one of these
                    clinicians:{' '}
                    {cliniciansInClinic
                      .map(clinician => obscureEmail(clinician.email))
                      .join(', ')}
                    .
                  </>
                ) : hasAdmin ? (
                  <>
                    Good news! We were able to find an admin for this clinic.
                    When you complete the form, we'll send them an email to
                    confirm your access. You can follow up with them if you know
                    their email address:{' '}
                    {admins.map(admin => obscureEmail(admin.email)).join(', ')}.
                  </>
                ) : hasClinician && clinician.role === 'admin' ? (
                  clinician.clinic?.name ? (
                    'The admin you selected has a practice under other name.'
                  ) : (
                    `We found your admin, but we're having trouble finding their practice. Please double check the spelling of your practice name.`
                  )
                ) : (
                  <>
                    We couldn't find an Admin or any Clinicians with Clients for
                    your Practice. You can continue setting up.{' '}
                    {role === 'admin'
                      ? `You'll be the Admin for your practice.`
                      : 'Invite your admin.'}
                  </>
                )}
              </Alert>
            </Box>
          </Collapse>
        </Grid>
        <Grid item xs={12}>
          <Collapse
            in={
              name !== ''
              //  &&
              // !searching &&
              // (Boolean(
              //   selectedClinic.id || addNewClinic || foundClinics.length === 0
              // ) ||
              //   (hasClinician && !hasAdmin))
            }
          >
            <Box sx={{ mt: addNewClinic ? 1 : 2 }}>
              <FormLabel>My Role</FormLabel>
              <Stack
                direction="row"
                spacing={1}
                sx={{ mt: 1 }}
                alignItems="center"
              >
                <RoleChip
                  grayed={hasAdmin}
                  customMsg={
                    hasAdmin
                      ? 'This Practice has an Admin. They can change your permission to Admin once your invitation is approved.'
                      : null
                  }
                  disabled={hasAdmin}
                  label="Admin"
                  selected={role.includes('admin')}
                  onClick={() => {
                    handleToggleRole('admin');
                    setAdminEmail('');
                  }}
                />
                <RoleChip
                  label="Practice Manager"
                  selected={role.includes('practicemanager')}
                  onClick={() => handleToggleRole('practicemanager')}
                />
                <RoleChip
                  label="Clinician"
                  selected={role.includes('clinician')}
                  onClick={() => handleToggleRole('clinician')}
                />
              </Stack>
            </Box>
          </Collapse>
        </Grid>
        <Grid item xs={12}>
          <Collapse
            in={Boolean(
              !hasAdmin &&
                Boolean(name) &&
                // !searching &&
                role !== 'admin'
              // &&

              // shouldShowAdmin
            )}
          >
            <Grid sx={{ mt: 2 }} spacing={1} container>
              <Grid item xs={12}>
                <FormLabel>Owner Details</FormLabel>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  error={Boolean(errors.adminEmail)}
                  helperText={errors.adminEmail}
                  onChange={handleClinicianEmailChange}
                  label="Admin's Email Address"
                  fullWidth
                  size="small"
                  value={adminEmail}
                  variant="filled"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {searchingClinicians ? (
                          <CircularProgress size={20} />
                        ) : hasClinician ? (
                          <Tooltip
                            placement="top"
                            title="We found this account. Let's proceed."
                          >
                            <Face />
                          </Tooltip>
                        ) : null}
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  error={Boolean(errors.adminFirstName)}
                  helperText={errors.adminFirstName}
                  label="Admin's First Name"
                  fullWidth
                  size="small"
                  variant="filled"
                  value={adminFirstName}
                  onChange={e => setAdminFirstName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  error={Boolean(errors.adminLastName)}
                  helperText={errors.adminLastName}
                  label="Admin's Last Name"
                  fullWidth
                  size="small"
                  variant="filled"
                  value={adminLastName}
                  onChange={e => setAdminLastName(e.target.value)}
                />
              </Grid>
            </Grid>
          </Collapse>
        </Grid>
        <Grid item xs={12}>
          <Collapse
            in={
              // (addNewClinic || foundClinics.length === 0) &&
              role === 'admin'
            }
          >
            <Box>
              <Grid
                sx={{ mt: 2, display: 'flex', alignItems: 'center' }}
                container
                spacing={2}
              >
                <Grid item xs={12} sm={8}>
                  <TextField
                    error={Boolean(errors.website)}
                    helperText={errors.website}
                    value={website}
                    onChange={handleWebsiteChange}
                    size="small"
                    fullWidth
                    label="Website"
                    variant="filled"
                    InputProps={{
                      startAdornment: showPrefix ? (
                        <InputAdornment position="start">
                          https://
                        </InputAdornment>
                      ) : null
                    }}
                  />
                </Grid>
                {!logo && (
                  <Grid item xs={12} sm={4} display="flex" alignItems="center">
                    <Button
                      sx={{ whiteSpace: 'nowrap' }}
                      variant="contained"
                      component="label"
                      startIcon={<CloudUploadIcon />}
                    >
                      Upload Logo
                      <input
                        type="file"
                        hidden
                        accept="image/*"
                        onChange={handleLogoChange}
                      />
                    </Button>
                  </Grid>
                )}
                {logo && (
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    container
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box
                      component="img"
                      src={URL.createObjectURL(logo)}
                      alt="Logo preview"
                      sx={{ width: 'auto', maxWidth: '100%', maxHeight: 100 }}
                    />
                    <Box>
                      <IconButton onClick={handleClearLogo}>
                        <Clear />
                      </IconButton>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Collapse>
        </Grid>
      </Grid>
    </Box>
  );
}

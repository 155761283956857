/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const userInputsByComponentType = /* GraphQL */ `
  query UserInputsByComponentType(
    $componentType: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserInputFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userInputsByComponentType(
      componentType: $componentType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        inputName
        createdAt
        updatedAt
        owner
        inputValue
        checkboxesValue {
          id
          label
          order
          __typename
        }
        emojiRaterValue
        radiosValue {
          id
          label
          order
          __typename
        }
        chipsValue {
          id
          label
          order
          __typename
        }
        sliderValue
        raterValue
        raterBoolean
        audioCompressionStatus
        audioDurationSeconds
        audioType
        audioReady
        audioRecording
        audioUploadingStatus
        componentType
        componentID
        componentOrder
        recordID
        createdBy
        updatedBy
        componentUserInputsId
        recordUserInputsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userInputsByComponentID = /* GraphQL */ `
  query UserInputsByComponentID(
    $componentID: ID!
    $enteredAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserInputFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userInputsByComponentID(
      componentID: $componentID
      enteredAt: $enteredAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        inputName
        enteredAt
        createdAt
        updatedAt
        owner
        inputValue
        checkboxesValue {
          id
          label
          order
          __typename
        }
        emojiRaterValue
        radiosValue {
          id
          label
          order
          __typename
        }
        sliderValue
        chipsValue {
          id
          label
          order
          __typename
        }
        raterValue
        raterBoolean
        audioCompressionStatus
        audioDurationSeconds
        audioType
        audioReady
        audioRecording
        audioUploadingStatus
        componentType
        componentID
        componentOrder
        recordID
        createdBy
        updatedBy
        componentUserInputsId
        recordUserInputsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userInputsByComponentOrder = /* GraphQL */ `
  query UserInputsByComponentOrder(
    $componentOrder: Int!
    $sortDirection: ModelSortDirection
    $filter: ModelUserInputFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userInputsByComponentOrder(
      componentOrder: $componentOrder
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        inputName
        createdAt
        updatedAt
        owner
        inputValue
        checkboxesValue {
          id
          label
          order
          __typename
        }
        emojiRaterValue
        radiosValue {
          id
          label
          order
          __typename
        }
        chipsValue {
          id
          label
          order
          __typename
        }
        sliderValue
        raterValue
        raterBoolean
        audioCompressionStatus
        audioDurationSeconds
        audioType
        audioReady
        audioRecording
        audioUploadingStatus
        componentType
        componentID
        componentOrder
        recordID
        createdBy
        updatedBy
        componentUserInputsId
        recordUserInputsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userInputsByRecordComponentType = /* GraphQL */ `
  query UserInputsByRecordID(
    $recordID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserInputFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userInputsByRecordComponentType(
      recordID: $recordID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        inputName
        enteredAt
        createdAt
        updatedAt
        owner
        inputValue
        checkboxesValue {
          id
          label
          order
  
        }
        emojiRaterValue
        radiosValue {
          id
          label
          order

        }
        sliderValue
        incrementerValue
        raterValue
        chipsValue {
          id
          label
          order
    
        }
        raterBoolean
        clockValue
        numberValue
        audioCompressionStatus
        audioDurationSeconds
        audioType
        audioReady
        audioRecording
        audioMode
        audioUploadingStatus
        componentType
        componentID
        componentOrder
        recordID
        createdBy
        updatedBy
        componentUserInputsId
        recordUserInputsId
        component {
          id
          createdAt
          updatedAt
          clientID
          complete
          owner
          type
          order
          settings
          to
          from
          rows
          name
          noEdit
          size
          label
          placeholder
          upload
          options
          content
          boolean
          variant
          prompts
          collapse
          showInfo
          showPrompts
          multiline
          addButton
          multiselect
          information
          instructions
          removeButton
          booleanLabel
          annotationLabels
          entrySectionID
          entrySection {
            id
            createdAt
            updatedAt
            name
            complete
            owner
            order
            noEdit
            settings
            showInfo
            information
            informationTitle
            orientation
            clientID
            entryID
            createdBy
            _version
            _deleted
            _lastChangedAt
            entryEntrySectionsId
            __typename
          }
          goalID
          _version
          _deleted
          _lastChangedAt
          entrySectionComponentsId
          __typename
        }
        _version
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const listSections = /* GraphQL */ `
  query ListSections(
    $filter: ModelSectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSections(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        order
        settings
        clientID
        createdBy
        entries {
          items {
            id
            name
            order
            entrySections {
              items {
                id
                name
                order
                components {
                  items {
                    id
                    name
                    order
                    type
                  }
                }
              }
            }
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const recordsByEntryID = /* GraphQL */ `
  query recordsByEntryID(
    $entryID: ID!
    $enteredAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRecordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    recordsByEntryID(
      entryID: $entryID
      enteredAt: $enteredAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        _deleted
        enteredAt
        createdBy
        entryID
        clientID
        hasData
        draft
        userInputs {
          items {
            id
            inputName
            inputValue
            checkboxesValue {
              id
              label
              order
              __typename
            }
           
            radiosValue {
              id
              label
              order
              __typename
            }
            clockValue
            numberValue
            audioReady
            audioDurationSeconds
            audioMode
            raterValue
            raterBoolean
            componentType
            chipsValue {
              id
              label
              order
              __typename
            }
            componentID
            componentOrder
            component {
              entrySection {
                order
                id
                name
              }
            }
          }
          nextToken
        }
        updatedAt
      }
      nextToken
    }
  }
`;

export const recordsBySectionID = /* GraphQL */ `
  query RecordsBySectionID(
    $sectionID: ID!
    $enteredAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRecordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    recordsBySectionID(
      sectionID: $sectionID
      enteredAt: $enteredAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        _deleted
        enteredAt
        createdBy
        sectionID
        entryID
        hasData
        clientID
        draft
        userInputs {
          items {
            id
            inputName
            inputValue
            checkboxesValue {
              id
              label
              order
              __typename
            }
            radiosValue {
              id
              label
              order
              __typename
            }
            chipsValue {
              id
              label
              order
              __typename
            }
            raterValue
            clockValue
            numberValue
            raterBoolean
            audioReady
            audioDurationSeconds
            audioMode
            componentType
            componentID
            componentOrder
            component {
              entrySection {
                order
                id
                name
              }
            }
          }
          nextToken
        }
        updatedAt
      }
      nextToken
    }
  }
`;

export const recordsByDate = /* GraphQL */ `
  query RecordsByDate(
    $clientID: ID!
    $enteredAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRecordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    recordsByDate(
      clientID: $clientID
      enteredAt: $enteredAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        _deleted
        createdAt
        enteredAt
        createdBy
        entryID
        entry {
          id
          createdAt
          updatedAt
          owner
          name
          icon
          type
          order
          addLogic
          logic
          inactive
          noEdit
          settings
          complete
          analytics
          draftRecordID
          clientID
          createdBy
          sectionID
          section {
            id
            createdAt
            updatedAt
            complete
            name
            inactive
            noEdit
            analytics
            order
            owner
            settings
            clientID
            createdBy
            goalID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          entrySections {
            nextToken
            startedAt
            __typename
          }
          count
          goalID
          goal {
            id
            owner
            name
            description
            createdAt
            updatedAt
            createdBy
            updatedBy
            targetNumber
            targetFrequency
            targetType
            recurringType
            recurringUntil
            recurringNumber
            color
            sectionID
            entryID
            componentID
            clientID
            order
            complete
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          _version
          _deleted
          _lastChangedAt
          sectionEntriesId
          __typename
        }
        clientID
        draft
        hasData
        userInputs {
          items {
            id
            inputName
            inputValue
            checkboxesValue {
              id
              label
              order
            
            }
            radiosValue {
              id
              label
              order
         
            }
            clockValue
            numberValue
            raterValue
            audioReady
            audioDurationSeconds
            audioMode
            raterBoolean
            componentType
            sliderValue
            incrementerValue
            componentID
            componentOrder
            chipsValue {
              id
              label
              order
        
            }
            component {
              entrySection {
                order
                id
                name
              }
            }
          }
          nextToken
        }
        updatedAt
      }
      nextToken
    }
  }
`;

export const exerciseIDsByID = /* GraphQL */ `
  query ExercisesByID(
    $createdBy: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelExercisesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    exercisesByID(
      createdBy: $createdBy
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
      }
      nextToken
    }
  }
`;

export const listExercises = /* GraphQL */ `
  query ListExercises(
    $filter: ModelExercisesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExercises(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdBy
      }
      nextToken
    }
  }
`;

export const getRecord = /* GraphQL */ `
  query GetRecord($id: ID!) {
    getRecord(id: $id) {
      id
      enteredAt
      createdAt
      updatedAt
      updatedBy
      hasUpdatedGoalEntry
      hasData
      owner
      draft
      createdBy
      editing
      sectionID
      entryID
      entry {
        id
        createdAt
        updatedAt
        owner
        name
        icon
        type
        order
        addLogic
        logic
        settings
        complete
        analytics
        draftRecordID
        clientID
        createdBy
        sectionID
        section {
          id
          createdAt
          updatedAt
          complete
          name
          order
          owner
          settings
          clientID
          createdBy
          __typename
        }
        count
        sectionEntriesId
        _version
        __typename
      }
      clientID
      userInputs {
        items {
          id
          inputName
          enteredAt
          createdAt
          updatedAt
          owner
          inputValue
          checkboxesValue {
            id
            label
            order
          }
          emojiRaterValue
          radiosValue {
            id
            label
            order
          }
          sliderValue
          incrementerValue
          clockValue
          raterValue
          raterBoolean
          audioCompressionStatus
          audioDurationSeconds
          audioType
          audioReady
          audioRecording
          audioUploadingStatus
          audioMode
          componentType
          componentID
          numberValue
          componentOrder
          recordID
          createdBy
          updatedBy
          componentUserInputsId
          recordUserInputsId
          chipsValue {
            id
            label
            order
          }
          _version
          __typename
        }
        nextToken
        __typename
      }
      _version
      __typename
    }
  }
`;

export const getEntry = /* GraphQL */ `
  query GetEntry($id: ID!) {
    getEntry(id: $id) {
      id
      createdAt
      updatedAt
      owner
      name
      icon
      type
      order
      addLogic
      logic
      settings
      complete
      analytics
      draftRecordID
      clientID
      createdBy
      sectionID
      entrySections {
        nextToken
        __typename
      }
      sectionEntriesId
      section {
        id
        name
      }
      __typename
    }
  }
`;


export const sectionByClientIDByOrder = /* GraphQL */ `
  query SectionByClientIDByOrder(
    $clientID: ID!
    $order: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sectionByClientIDByOrder(
      clientID: $clientID
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        complete
        order
        settings
        clientID
        createdBy
        entries {
          items {
            id
            name
            icon
            complete
            addLogic
            logic
            order
            count
            entrySections {
              items {
                id
                name
                order
                components {
                  items {
                    id
                    name
                    order
                    type
                  }
                }
              }
            }
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const updateGoal = /* GraphQL */ `
  mutation UpdateGoal(
    $input: UpdateGoalInput!
    $condition: ModelGoalConditionInput
  ) {
    updateGoal(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
      createdBy
      updatedBy
      targetNumber
      targetFrequency
      targetType
      recurringType
      recurringUntil
      recurringNumber
      color
      section {
        id
        createdAt
        updatedAt
        complete
        name
        noEdit
        analytics
        order
        owner
        settings
        clientID
        createdBy
        goalID
        entries { 
          items { 
            id
            createdAt
            updatedAt
            owner
            name
            icon
            type
            order
            addLogic
            logic
            inactive
            noEdit
            settings
            complete
            analytics
            draftRecordID
            clientID
            createdBy
            sectionID
            count
            goalID
            entrySections { 
              items { 
                id
                createdAt
                updatedAt
                name
                complete
                owner
                order
                noEdit
                settings
                showInfo
                information
                informationTitle
                orientation
                clientID
                entryID
                createdBy
                components { 
                  items { 
                    id
                    createdAt
                    updatedAt
                    clientID
                    complete
                    owner
                    type
                    order
                    settings
                    to
                    from
                    rows
                    name
                    noEdit
                    size
                    label
                    placeholder
                    upload
                    options
                    content
                    boolean
                    variant
                    prompts
                    collapse
                    showInfo
                    showPrompts
                    multiline
                    addButton
                    multiselect
                    information
                    instructions
                    removeButton
                    booleanLabel
                    annotationLabels
                    entrySectionID
                    goalID
                    _version
                    _deleted
                    _lastChangedAt
                    entrySectionComponentsId
                    __typename
                  
                  }
                }
                _version
                _deleted
                _lastChangedAt
                entryEntrySectionsId
                __typename
              }
            } 
            _version
            _deleted
            _lastChangedAt
            sectionEntriesId
            __typename
          }
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      sectionID
      entry {
        id
        createdAt
        updatedAt
        owner
        name
        icon
        type
        order
        addLogic
        logic
        inactive
        noEdit
        settings
        complete
        analytics
        draftRecordID
        clientID
        createdBy
        sectionID
        count
        goalID
        entrySections { 
          items { 
            id
            createdAt
            updatedAt
            name
            complete
            owner
            order
            noEdit
            settings
            showInfo
            information
            informationTitle
            orientation
            clientID
            entryID
            createdBy
            components { 
              items { 
                id
                createdAt
                updatedAt
                clientID
                complete
                owner
                type
                order
                settings
                to
                from
                rows
                name
                noEdit
                size
                label
                placeholder
                upload
                options
                content
                boolean
                variant
                prompts
                collapse
                showInfo
                showPrompts
                multiline
                addButton
                multiselect
                information
                instructions
                removeButton
                booleanLabel
                annotationLabels
                entrySectionID
                goalID
                _version
                _deleted
                _lastChangedAt
                entrySectionComponentsId
                __typename
              
              }
            }
            _version
            _deleted
            _lastChangedAt
            entryEntrySectionsId
            __typename
          }
        } 
        _version
        _deleted
        _lastChangedAt
        sectionEntriesId
        __typename
      }
      entryID
      component {
        id
        createdAt
        updatedAt
        clientID
        complete
        owner
        type
        order
        settings
        to
        from
        rows
        name
        noEdit
        size
        label
        placeholder
        upload
        options
        content
        boolean
        variant
        prompts
        collapse
        showInfo
        showPrompts
        multiline
        addButton
        multiselect
        information
        instructions
        removeButton
        booleanLabel
        annotationLabels
        userInputs {
          nextToken
          startedAt
          __typename
        }
        entrySectionID
        entrySection {
          id
          createdAt
          updatedAt
          name
          complete
          owner
          order
          noEdit
          settings
          showInfo
          information
          informationTitle
          orientation
          clientID
          entryID
          createdBy
          _version
          _deleted
          _lastChangedAt
          entryEntrySectionsId
          __typename
        }
        goalID
        goal {
          id
          name
          description
          createdAt
          updatedAt
          createdBy
          updatedBy
          targetNumber
          targetFrequency
          targetType
          recurringType
          recurringUntil
          recurringNumber
          color
          sectionID
          entryID
          componentID
          clientID
          order
          complete
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        infoContent {
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        entrySectionComponentsId
        __typename
      }
      componentID
      clientID
      order
      goalEntries {
        items {
          id
          date
          goalID
          trackingNumber
          celebrated
          complete
          clientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          goalGoalEntriesId
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      complete
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const goalByClientID = /* GraphQL */ `
  query GoalByClientID(
    $clientID: ID!
    $order: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGoalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    goalByClientID(
      clientID: $clientID
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
      id
      name
      description
      createdAt
      updatedAt
      createdBy
      updatedBy
      targetNumber
      targetFrequency
      targetType
      recurringType
      recurringUntil
      recurringNumber
      color
      section {
        id
        createdAt
        updatedAt
        complete
        name
        noEdit
        analytics
        order
        owner
        settings
        clientID
        createdBy
        goalID
        entries { 
          items { 
            id
            createdAt
            updatedAt
            owner
            name
            icon
            type
            order
            addLogic
            logic
            inactive
            noEdit
            settings
            complete
            analytics
            draftRecordID
            clientID
            createdBy
            sectionID
            count
            goalID
            entrySections { 
              items { 
                id
                createdAt
                updatedAt
                name
                complete
                owner
                order
                noEdit
                settings
                showInfo
                information
                informationTitle
                orientation
                clientID
                entryID
                createdBy
                components { 
                  items { 
                    id
                    createdAt
                    updatedAt
                    clientID
                    complete
                    owner
                    type
                    order
                    settings
                    to
                    from
                    rows
                    name
                    noEdit
                    size
                    label
                    placeholder
                    upload
                    options
                    content
                    boolean
                    variant
                    prompts
                    collapse
                    showInfo
                    showPrompts
                    multiline
                    addButton
                    multiselect
                    information
                    instructions
                    removeButton
                    booleanLabel
                    annotationLabels
                    entrySectionID
                    goalID
                    _version
                    _deleted
                    _lastChangedAt
                    entrySectionComponentsId
                    __typename
                  
                  }
                }
                _version
                _deleted
                _lastChangedAt
                entryEntrySectionsId
                __typename
              }
            } 
            _version
            _deleted
            _lastChangedAt
            sectionEntriesId
            __typename
          }
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      sectionID
      entry {
        id
        createdAt
        updatedAt
        owner
        name
        icon
        type
        order
        addLogic
        logic
        inactive
        noEdit
        settings
        complete
        analytics
        draftRecordID
        clientID
        createdBy
        sectionID
        count
        goalID
        entrySections { 
          items { 
            id
            createdAt
            updatedAt
            name
            complete
            owner
            order
            noEdit
            settings
            showInfo
            information
            informationTitle
            orientation
            clientID
            entryID
            createdBy
            components { 
              items { 
                id
                createdAt
                updatedAt
                clientID
                complete
                owner
                type
                order
                settings
                to
                from
                rows
                name
                noEdit
                size
                label
                placeholder
                upload
                options
                content
                boolean
                variant
                prompts
                collapse
                showInfo
                showPrompts
                multiline
                addButton
                multiselect
                information
                instructions
                removeButton
                booleanLabel
                annotationLabels
                entrySectionID
                goalID
                _version
                _deleted
                _lastChangedAt
                entrySectionComponentsId
                __typename
              
              }
            }
            _version
            _deleted
            _lastChangedAt
            entryEntrySectionsId
            __typename
          }
        } 
        _version
        _deleted
        _lastChangedAt
        sectionEntriesId
        __typename
      }
      entryID
      component {
        id
        createdAt
        updatedAt
        clientID
        complete
        owner
        type
        order
        settings
        to
        from
        rows
        name
        noEdit
        size
        label
        placeholder
        upload
        options
        content
        boolean
        variant
        prompts
        collapse
        showInfo
        showPrompts
        multiline
        addButton
        multiselect
        information
        instructions
        removeButton
        booleanLabel
        annotationLabels
        entrySectionID
        goalID
        _version
        _deleted
        _lastChangedAt
        entrySectionComponentsId
        __typename
      }
      componentID
      clientID
      order
      goalEntries {
        nextToken
        startedAt
        __typename
      }
      complete
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
`;


export const entriesBySectionByOrder = /* GraphQL */ `
  query EntriesBySectionByOrder(
    $sectionID: ID!
    $order: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    entriesBySectionByOrder(
      sectionID: $sectionID
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        owner
        name
        icon
        type
        order
        addLogic
        logic
        inactive
        noEdit
        settings
        complete
        analytics
        draftRecordID
        clientID
        createdBy
        sectionID
        section {
          id
          createdAt
          updatedAt
          complete
          name
          noEdit
          analytics
          order
          owner
          settings
          clientID
          createdBy
          goalID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        entrySections {
          items {
            id
            createdAt
            updatedAt
            name
            complete
            owner
            order
            noEdit
            settings
            showInfo
            information
            informationTitle
            orientation
            clientID
            entryID
            createdBy
            _version
            _deleted
            _lastChangedAt
            entryEntrySectionsId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        count
        goalID
        
        _version
        _deleted
        _lastChangedAt
        sectionEntriesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;

export const entrySectionsByEntryIDByOrder = /* GraphQL */ `
  query EntrySectionsByEntryIDByOrder(
    $entryID: ID!
    $order: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEntrySectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    entrySectionsByEntryIDByOrder(
      entryID: $entryID
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        _version
        name
        order
        settings
        clientID
        complete
        noEdit
        entryID
        createdBy
        components {
          items {
            id
            _version
            name
            complete
            to
            from
            rows
            noEdit
            label
            showInfo
            placeholder
            collapse
            upload
            annotationLabels
            options
            content
            multiline
            multiselect
            type
            order
            entrySectionID
            userInputs {
              nextToken
            }
          }
          nextToken
        }
        createdAt
        updatedAt
        entryEntrySectionsId
      }
      nextToken
    }
  }
`;

export const insightsSectionByClientIDandEntryIDandOrder = /* GraphQL */ `
  query InsightsSectionByClientIDandEntryIDandOrder(
    $entryID: ID!
    $clientIDOrder: ModelInsightsSectionInsightsSectionByClientIDandEntryIDandOrderCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInsightsSectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    insightsSectionByClientIDandEntryIDandOrder(
      entryID: $entryID
      clientIDOrder: $clientIDOrder
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        clientID
        settings
        order
        orientation
        entryID
        insights {
          items {
            id
            createdAt
            settings
            order
            type
            entryID
            insightsSection {
              id
              createdAt
              clientID
              settings
              order
              orientation
              entryID
              updatedAt
            }
            updatedAt
          }
        }
        updatedAt
      }
      nextToken
    }
  }
`;

export const getSessionsByClientByDate = /* GraphQL */ `
  query GetSessionsByClientByDate(
    $clientID: ID!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSessionsByClientByDate(
      clientID: $clientID
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
      }
    }
  }
`;

export const listClinics = /* GraphQL */ `
  query ListClinics(
    $filter: ModelClinicFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClinics(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        name
        description
        website
        locations {
          nextToken
          __typename
        }
        clinicians {
          items {
            id
            email
          }
          nextToken
          __typename
        }
        hasLogo
        imageType
        clients {
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const getClinic = /* GraphQL */ `
  query GetClinic($id: ID!) {
    getClinic(id: $id) {
      id
      createdAt
      name
      description
      website
      locations {
        items {
          id
          createdAt
          updatedAt
          name
          phone
          streetAddress
          streetAddress2
          city
          postcode
          state
          country
          clinicID
          clinicLocationsId
          owner
          clinicians {
            items {
              id
              sub
              createdAt
              updatedAt
              owner
              firstName
              lastName
              email
              picture
              showNameExercises
              exercises
              clinicID
              locationID
              role
              clinicCliniciansId
              locationCliniciansId
              suspended
            }
          }
          __typename
        }
        nextToken
        __typename
      }
      clinicians {
        items {
          id
          sub
          createdAt
          updatedAt
          owner
          firstName
          lastName
          email
          picture
          showNameExercises
          exercises
          clinicID
          locationID
          role
          clinicCliniciansId
          locationCliniciansId
          suspended
          clients {
            items {
              id
            }
          }
          __typename
        }
        nextToken
        __typename
      }
      hasLogo
      imageType
      updatedAt
      owner
      __typename
    }
  }
`;

export const getClinicShallow = /* GraphQL */ `
  query GetClinic($id: ID!) {
    getClinic(id: $id) {
      id
      createdAt
      name
      description
      website
      locations {
        items {
          id
          createdAt
          updatedAt
          name
          phone
          streetAddress
          streetAddress2
          city
          postcode
          state
          country
          clinicID
          clinicLocationsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      clinicians {
        items {
          id
          __typename
        }
        nextToken
        __typename
      }
      hasLogo
      imageType
      clients {
        items {
          id
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      owner
      __typename
    }
  }
`;

export const cliniciansByClinicID = /* GraphQL */ `
  query CliniciansByClinicID(
    $clinicID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelClinicianFilterInput
    $limit: Int
    $nextToken: String
  ) {
    cliniciansByClinicID(
      clinicID: $clinicID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sub
        createdAt
        updatedAt
        owner
        firstName
        lastName
        email
        picture
        showNameExercises
        exercises
        clients {
          items {
            activatedShowCountBefore
            age
            appointmentDay
            appointmentFrequency
            appointmentTime
            billingCycle
            clientFirstName
            clientLastName
            clinicianClientsId
            clinicianID
            createdAt
            dataConsent
            zoomLink
            updatedAt
            timeZone
            suds
            subscription
            sub
            stripeSubscriptionID
            stripeCustomerID
            status
            picture
            program
            resetDate
            ssComponentID
            stage
            sessionCount
            showCount
            owner
            for
            ftComponentID
            hasResources
            id
            lastName
            linkStatus
            date
            email
            enableVideoCalls
            enrollBeta
            firstName
          }
        }
        clinicID
        clinic {
          id
          createdAt
          name
          description
          website
          hasLogo
          imageType
          updatedAt
          owner
          __typename
        }
        location {
          id
          createdAt
          updatedAt
          name
          phone
          streetAddress
          streetAddress2
          city
          postcode
          state
          country
          clinicID
          clinicLocationsId
          owner
          __typename
        }
        locationID
        roles
        clinicCliniciansId
        locationCliniciansId
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const getClinicianShallow = /* GraphQL */ `
  query GetClinician($id: ID!) {
    getClinician(id: $id) {
      id
      sub
      createdAt
      updatedAt
      owner
      firstName
      lastName
      email
      picture
      showNameExercises
      exercises
      clients {
        items {
          id
          __typename
        }
        nextToken
        __typename
      }
      clinicID
      clinic {
        id
        createdAt
        name
        description
        website
        locations {
          nextToken
          __typename
        }
        clinicians {
          nextToken
          __typename
        }
        hasLogo
        imageType
        clients {
          nextToken
          __typename
        }
        updatedAt
        owner
        __typename
      }
      location {
        id
        createdAt
        updatedAt
        name
        phone
        streetAddress
        streetAddress2
        city
        postcode
        state
        country
        clinicID
        clinic {
          id
          createdAt
          name
          description
          website
          hasLogo
          imageType
          updatedAt
          owner
          __typename
        }
        clinicLocationsId
        owner
        __typename
      }
      locationID
      role
      createdBy
      source
      suspended
      clinicCliniciansId
      locationCliniciansId
      __typename
    }
  }
`;

export const getCliniciansByEmail = /* GraphQL */ `
  query GetCliniciansByEmail(
    $email: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClinicianFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCliniciansByEmail(
      email: $email
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sub
        createdAt
        updatedAt
        owner
        firstName
        lastName
        email
        picture
        showNameExercises
        exercises
        clients {
          items {
            id
          }
          nextToken
          __typename
        }
        clinicID
        clinic {
          id
          createdAt
          name
          description
          website
          hasLogo
          imageType
          updatedAt
          owner
          __typename
        }
        location {
          id
          createdAt
          updatedAt
          name
          phone
          streetAddress
          streetAddress2
          city
          postcode
          state
          country
          clinicID
          clinicLocationsId
          owner
          __typename
        }
        locationID
        role
        clinicCliniciansId
        locationCliniciansId
        __typename
      }
      nextToken
      __typename
    }
  }
`;

import { useEffect, useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { useTheme } from '@emotion/react';
import {
  AccountCircle,
  ArrowForward,
  Close,
  Lock,
  LockOpen,
  RadioButtonChecked,
  RadioButtonUnchecked
} from '@mui/icons-material';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  IconButton,
  Link,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Storage } from 'aws-amplify';
import { useAppContext } from 'context';
import { getImageFromIndexedDB, saveImageToIndexedDB } from 'indexedDB';
import { useNavigate } from 'react-router-dom';

import { capitalizeFirstLetter } from 'utils/capitaliseFirstLetter';
import { formatProgram } from 'utils/formatProgram';

export default function ResourceCard({
  client,
  resource,
  select,
  onSelect,
  selected,
  removeResource,
  clientResource
}) {
  const { user } = useAuthenticator(context => [context.user]);
  const { state } = useAppContext();
  const navigate = useNavigate();
  const [image, setImage] = useState(null);
  const imageID = resource.id;
  const isClinician = user.attributes['custom:type'] === 'clinician';

  async function getImageBlobFromS3(imageID, imageType) {
    try {
      const key = `resources/${imageID}.${imageType}`;
      const blob = await Storage.get(key, { download: true });
      return blob.Body;
    } catch (error) {
      console.error('Error fetching image from S3:', error);
      return null;
    }
  }

  useEffect(() => {
    let imageUrl;

    async function fetchImage() {
      if (imageID) {
        const blob = await getImageFromIndexedDB(imageID);
        if (blob) {
          imageUrl = URL.createObjectURL(blob);
          setImage(imageUrl);
        } else {
          const blobFromS3 = await getImageBlobFromS3(
            imageID,
            resource.imageType
          );
          await saveImageToIndexedDB(imageID, blobFromS3);
          imageUrl = URL.createObjectURL(blobFromS3);
          setImage(imageUrl);
        }
      }
    }
    if (resource?.hasImage) {
      fetchImage();
    }
    return () => {
      if (imageUrl) {
        URL.revokeObjectURL(imageUrl);
      }
    };
  }, [imageID, resource?.hasImage, resource?.imageType]);

  const theme = useTheme();

  const FadingOverlay = styled(Box)`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    background: linear-gradient(
      to top,
      ${theme.palette.background.paper},
      transparent 100%
    );
  `;

  return (
    <Card elevation={4}>
      <CardHeader
        title={resource.title}
        subheader={resource?.subTitle}
        action={
          state.edit ? (
            <IconButton onClick={() => removeResource(clientResource)}>
              <Close />
            </IconButton>
          ) : select ? (
            <IconButton onClick={() => onSelect(resource)}>
              {selected ? <RadioButtonChecked /> : <RadioButtonUnchecked />}
            </IconButton>
          ) : (
            <IconButton
              onClick={() =>
                navigate(
                  isClinician
                    ? client
                      ? `/client/resource/${resource.id}`
                      : `/resource/${resource.id}`
                    : `/resource/${resource.id}`
                )
              }
            >
              <ArrowForward />
            </IconButton>
          )
        }
      />
      {image && <CardMedia component="img" image={image} />}
      {resource?.previewText && (
        <Box
          p={1}
          sx={{ overflow: 'hidden', position: 'relative', maxHeight: 200 }}
        >
          <Typography variant="h4">{resource?.previewText}</Typography>
          <FadingOverlay />
        </Box>
      )}
      <CardContent>
        <Stack direction="row" spacing={1} sx={{ width: '100%' }}>
          {resource?.type && (
            <Chip label={capitalizeFirstLetter(resource?.type)} />
          )}
          {resource?.category && (
            <Chip label={capitalizeFirstLetter(resource?.category)} />
          )}
          {resource?.condition && (
            <Chip label={capitalizeFirstLetter(resource?.condition)} />
          )}
          {resource?.program && (
            <Chip
              label={formatProgram(resource?.program)}
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '100%'
              }}
            />
          )}
        </Stack>
        {resource?.sourceName && (
          <Stack
            direction="row"
            sx={{ mt: 2 }}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="caption">
              Source:{' '}
              {resource?.sourceLink ? (
                <Link href={resource.sourceLink}>{resource?.sourceName}</Link>
              ) : (
                resource?.sourceName
              )}
            </Typography>
            {isClinician && (
              <Stack alignItems="center" direction="row" spacing={2}>
                {user.username === resource?.createdBy && (
                  <Tooltip title="Created by me">
                    <AccountCircle />
                  </Tooltip>
                )}
                {resource?.accessType === 'private' && (
                  <Tooltip title="Only me and clients I share this resource with can access it.">
                    <Lock />
                  </Tooltip>
                )}
                {resource?.accessType === 'public' && (
                  <Tooltip title="Any clinician with a SpeechFit account can access this resource and share it with their clients.">
                    <LockOpen />
                  </Tooltip>
                )}
              </Stack>
            )}
          </Stack>
        )}
      </CardContent>
    </Card>
  );
}

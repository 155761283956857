import React, { useCallback, useEffect, useState } from 'react';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material';
import isEmail from '@rearguard/is-email';
import { constants } from 'common/constants';
import * as yup from 'yup';

function UnifiedSignInUp({
  givenName,
  setGivenName,
  familyName,
  setFamilyName,
  email,
  setEmail,
  password,
  setPassword,
  handleSignUp,
  handleSignIn,
  // handleGoogleSignIn,
  setIsSignUp,
  type,
  setType,
  signingUp,
  signingIn,
  isSignUp,
  handlePasswordResetRequest,
  sendingReset
}) {
  const [errors, setErrors] = useState({});
  const [submissionAttempted, setSubmissionAttempted] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [agreeToTerms, setAgreeToTerms] = useState(true);

  const validateForm = useCallback(async () => {
    let validationSchema;

    if (isSignUp) {
      validationSchema = yup.object().shape({
        email: yup
          .string()
          .required('Email is required')
          .test('isValid', 'Email is not valid', value => isEmail(value)),
        password: yup
          .string()
          .required('Password is required')
          .min(6, 'Password should be at least 6 characters')
          // eslint-disable-next-line no-useless-escape
          .matches(/\d/, 'Password should have at least one number')
          .matches(
            // eslint-disable-next-line no-useless-escape
            /[A-Z]|[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/,
            'Password should have at least one uppercase letter or special character'
          ),
        givenName: yup.string().required('Given Name is required'),
        familyName: yup.string().required('Family Name is required'),
        type: yup.string().required('Please select a type'),
        agreeToTerms: yup
          .bool()
          .oneOf([true], 'You must agree to the terms to proceed.')
      });
    } else {
      validationSchema = yup.object().shape({
        email: yup
          .string()
          .required('Email is required')
          .test('isValid', 'Email is not valid', value => isEmail(value)),
        password: yup.string().required('Password is required')
      });
    }

    try {
      await validationSchema.validate(
        {
          email,
          password,
          givenName,
          familyName,
          type,
          agreeToTerms
        },
        { abortEarly: false }
      );

      setErrors({});
      return true;
    } catch (err) {
      let newErrors = {};
      err.inner.forEach(e => {
        newErrors[e.path] = e.message;
      });
      setErrors(newErrors);
      return false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, password, givenName, familyName, type, agreeToTerms, isSignUp]);

  const validateField = (fieldName, value) => {
    let validationSchema;
    if (!submissionAttempted && !errors[fieldName]) return;

    switch (fieldName) {
      case 'email':
        validationSchema = yup
          .string()
          .required('Email is required')
          .test('isValid', 'Email is not valid', val => isEmail(val));
        break;
      case 'password':
        if (isSignUp) {
          validationSchema = yup
            .string()
            .required('Password is required')
            .min(6, 'Password should be at least 6 characters')
            //
            .matches(/\d/, 'Password should have at least one number')
            .matches(
              // eslint-disable-next-line no-useless-escape
              /[A-Z]|[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/,
              'Password should have at least one uppercase letter or special character'
            );
        } else {
          validationSchema = yup.string().required('Password is required');
        }
        break;
      case 'givenName':
        validationSchema = yup.string().required('Given Name is required');
        break;
      case 'familyName':
        validationSchema = yup.string().required('Family Name is required');
        break;
      case 'type':
        validationSchema = yup.string().required('Please select a type');
        break;
      case 'agreeToTerms':
        validationSchema = yup
          .bool()
          .oneOf([true], 'You must agree to the terms to proceed.');
        break;
      default:
        return;
    }

    try {
      validationSchema.validateSync(value);
      setErrors(prevErrors => {
        const newErrors = { ...prevErrors };
        delete newErrors[fieldName];
        return newErrors;
      });
    } catch (err) {
      setErrors(prevErrors => ({
        ...prevErrors,
        [fieldName]: err.message
      }));
    }
  };

  const resetForm = useCallback(() => {
    setGivenName('');
    setFamilyName('');
    setType('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFormSubmit = useCallback(async () => {
    const isValid = await validateForm();
    if (isValid) {
      const action = isSignUp ? handleSignUp : handleSignIn;
      action()
        .then(() => {
          if (isSignUp) {
            resetForm();
          }
        })
        .catch(err => console.error(err));
    } else {
      setSubmissionAttempted(true);
    }
  }, [validateForm, isSignUp, handleSignUp, handleSignIn, resetForm]);

  useEffect(() => {
    const handleKeyPress = e => {
      if (e.key === 'Enter') {
        handleFormSubmit();
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleFormSubmit]);

  useEffect(() => {
    if (isSignUp) {
      const lS = localStorage.getItem('type');
      if (!lS) {
        localStorage.setItem('type', 'client');
      }
    }
  }, [isSignUp]);

  return (
    <Box width="100%" sx={{ px: { xs: 2, sm: 0 } }}>
      <Collapse in={isSignUp}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          width="100%"
          my={1}
          mb={2}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <ToggleButtonGroup
              size="small"
              color="secondary"
              value={type}
              exclusive
              onChange={(event, newValue) => {
                if (newValue !== null) {
                  setType(newValue);
                  validateField('type', newValue);
                  localStorage.setItem('type', newValue);
                }
              }}
              aria-label="Platform"
            >
              <ToggleButton sx={{ fontWeight: 'bold' }} value="client">
                Client
              </ToggleButton>
              <ToggleButton sx={{ fontWeight: 'bold' }} value="clinician">
                Clinician
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>
          {errors.type && (
            <Typography variant="body2" color="error">
              {errors.type}
            </Typography>
          )}
        </Box>
        <Box mt={1} mb={2} width="100%">
          <Divider sx={{ opacity: 0.5 }} />
        </Box>
      </Collapse>

      <Box width="100%" my={1}>
        <TextField
          size="small"
          fullWidth
          label="Email"
          value={email}
          onChange={e => {
            setEmail(e.target.value);
            if (submissionAttempted || errors.email) {
              validateField('email', e.target.value);
            }
          }}
          error={!!errors.email}
          helperText={errors.email}
        />
      </Box>
      <Collapse in={!!email}>
        <Box width="100%" my={1}>
          <TextField
            size="small"
            fullWidth
            label="Password"
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={e => {
              setPassword(e.target.value);
              validateField('password', e.target.value);
            }}
            error={!!errors.password}
            helperText={errors.password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Box>
        {isSignUp && (
          <Grid spacing={1} container>
            <Grid item sm={6} xs={12}>
              <Box width="100%" my={1}>
                <TextField
                  size="small"
                  fullWidth
                  label="Given Name"
                  value={givenName}
                  onChange={e => {
                    setGivenName(e.target.value);
                    validateField('givenName', e.target.value);
                  }}
                  error={!!errors.givenName}
                  helperText={errors.givenName}
                />
              </Box>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Box width="100%" my={1}>
                <TextField
                  size="small"
                  fullWidth
                  label="Family Name"
                  value={familyName}
                  onChange={e => {
                    setFamilyName(e.target.value);
                    validateField('familyName', e.target.value);
                  }}
                  error={!!errors.familyName}
                  helperText={errors.familyName}
                />
              </Box>
            </Grid>
          </Grid>
        )}
      </Collapse>
      <Collapse in={isSignUp && !!email}>
        <Box width="100%" p={1}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={agreeToTerms}
                  onChange={e => {
                    setAgreeToTerms(e.target.checked);
                    validateField('agreeToTerms', e.target.checked);
                  }}
                />
              }
              label={
                <>
                  &nbsp;I agree to the{' '}
                  <Link href={constants.termsUrl}>Terms and Conditions</Link>{' '}
                  and <Link href={constants.privacyUrl}>Privacy Policy</Link>.
                </>
              }
              sx={{ '.MuiTypography-body1': { fontSize: '0.7rem' } }}
            />
            {errors.agreeToTerms && (
              <Typography variant="body2" color="error">
                {errors.agreeToTerms}
              </Typography>
            )}
          </FormGroup>
        </Box>
      </Collapse>

      <Box display="flex" width="100%" my={1}>
        <LoadingButton
          size="small"
          variant="contained"
          fullWidth
          onClick={handleFormSubmit}
          loading={isSignUp ? signingUp : signingIn}
        >
          {isSignUp ? 'Sign Up' : 'Sign In'}
        </LoadingButton>
      </Box>
      <Box
        display="flex"
        justifyContent={isSignUp ? 'flex-end' : 'space-between'}
        alignItems="center"
      >
        {!isSignUp && (
          <LoadingButton
            loading={sendingReset}
            onClick={handlePasswordResetRequest}
            size="small"
          >
            Forgot Password
          </LoadingButton>
        )}
        <Box>
          <Typography variant="caption">
            {isSignUp ? 'Already have an account? ' : "Don't have an account? "}
          </Typography>
          <Button size="small" onClick={() => setIsSignUp(!isSignUp)}>
            {isSignUp ? 'Sign In' : 'Sign Up'}
          </Button>
        </Box>
      </Box>
      {/* {!isSignUp && (
        <Box mt={1} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography align="center" variant="caption">
            If you have previously signed up with a Google account,{' '}
            <Link
              sx={{ cursor: 'pointer' }}
              onClick={() => handleGoogleSignIn()}
            >
              log in here.
            </Link>
          </Typography>
        </Box>
      )} */}
    </Box>
  );
}

export default UnifiedSignInUp;

import { Box, LinearProgress, Stack, Typography } from '@mui/material';

export default function ProgressIndicator({ label, value, ...rest }) {
  return (
    <Stack>
      <Box></Box>
      <Stack spacing={1}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="caption" component="div" color="text.accent">
            {label}
          </Typography>
          <Typography variant="caption" component="div" color="text.accent">
            {`${Math.round(value)}%`}
          </Typography>
        </Stack>
        <LinearProgress
          variant={label === 'Compressing' ? 'indeterminate' : 'determinate'}
          value={value}
          {...rest}
        />
      </Stack>
    </Stack>
  );
}

import React, { useCallback, useEffect, useState } from 'react';

import { LoadingButton } from '@mui/lab';
import { Alert, Box } from '@mui/material';

import { AuthCode } from 'components/atoms';

export default function Confirm({
  setOtp,
  otp,
  settingOtp,
  handleOtpSubmit,
  useDevice
}) {
  const [errors, setErrors] = useState({});
  const [submissionAttempted, setSubmissionAttempted] = useState(false);

  const validateOtp = useCallback(value => {
    if (!value) {
      setErrors({ otp: 'Please add a code.' });
      return false;
    } else if (value.length < 6) {
      setErrors({ otp: 'Code should be 6 characters long.' });
      return false;
    } else {
      setErrors({});
      return true;
    }
  }, []);

  const handleSubmit = useCallback(() => {
    setSubmissionAttempted(true);
    if (validateOtp(otp)) {
      handleOtpSubmit();
    }
  }, [otp, validateOtp, handleOtpSubmit]);

  const completeCode = useCallback(
    async code => {
      setOtp(code);
      if (submissionAttempted || errors.otp) {
        validateOtp(code);
      }
    },
    [setOtp, submissionAttempted, errors.otp, validateOtp]
  );

  useEffect(() => {
    const handleKeyPress = e => {
      if (e.key === 'Enter') {
        handleSubmit();
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleSubmit]);

  return (
    <Box width="100%">
      <Alert severity="info">
        {useDevice
          ? 'Enter the code from your authenticator app.'
          : 'We sent a code to your email address. Enter it here.'}
      </Alert>
      <Box width="100%" my={1}>
        <AuthCode
          error={!!errors.otp}
          helperText={errors.otp}
          length={6}
          onComplete={code => completeCode(code)}
        />
      </Box>
      <Box width="100%" my={2}>
        <LoadingButton
          loading={settingOtp}
          size="small"
          variant="contained"
          fullWidth
          onClick={handleSubmit}
        >
          Confirm
        </LoadingButton>
      </Box>
    </Box>
  );
}

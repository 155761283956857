import { List, ListItem, ListItemText, Typography } from '@mui/material';

export const oakvilleSteps = [
  {
    label:
      'Early Stage I - Speak With Minimal Stuttering in Everyday Conversations',
    description: (
      <>
        <Typography sx={{ mt: 1 }}>
          At the beginning of Stage 1, the parent and child initially consult
          with the clinician weekly, either in the clinic or by webcam. When the
          child can maintain STS for the 5–10-minute practice sessions and is
          complying with practice sessions 4–6 times per day, prompts to use
          occasional STS sentences in everyday conversations are introduced, and
          consultations move to fortnightly. When contingencies are introduced
          into the practice sessions (see Hybrid practice sessions, above) the
          number of these sessions reduces to a minimum of three; however, they
          are typically 10–15 minutes in duration.
        </Typography>
      </>
    )
  },
  {
    label:
      'Late Stage I - Speak With Minimal Stuttering in Everyday Conversations',
    description: (
      <>
        <Typography sx={{ mt: 1 }}>
          During the latter weeks of Stage 1, the parent slowly withdraws the
          last of the STS practice sessions, providing that it can be done
          without an increase in stuttering. At this time, STS during naturally
          occurring everyday conversations continues. As severity ratings
          decrease to less than 2, the number of sessions reduce to two per day.
          Just one practice session per day is required for several weeks before
          entering Stage 2. STS rarely remains part of the treatment focus at
          entry into Stage 2.
        </Typography>
        <Typography sx={{ mt: 1 }}>
          The child moves into Stage 2 when the following two criteria have been
          met for two consecutive fortnightly consultations:
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="1. Clinician SR of 0 or 1 during the consultation" />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="2. Daily parent typical SRs of 0 or 1 during the week preceding the consultation, with at least four of
              those seven SRs being 0. "
            />
          </ListItem>
        </List>
      </>
    )
  },
  {
    label: 'Stage II - Speak With Minimal Stuttering For a Long Time',
    description: (
      <>
        <Typography>Stage 2 serves three purposes:</Typography>
        <List>
          <ListItem>
            <ListItemText primary="(a) to withdraw practice sessions" />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="(b) to maintain the absence or low level of stuttering that was attained
              during Stage 1, and "
            />
          </ListItem>

          <ListItem>
            <ListItemText
              primary="(c) to ensure that the parent understands how to
              monitor and manage any increase that may occur in the child’s
              stuttering, reintroducing STS practice if needed"
            />
          </ListItem>
        </List>
        <Typography>
          Stage 2 continues until the child has sustained minimal stuttering for
          around a year. Subsequent to the conclusion of Stage 2, the parent is
          advised to contact the clinician if any relapse occurs that cannot be
          managed by short-term reintroduction of STS practice.
        </Typography>
      </>
    )
  }
];

import React, { useEffect, useState } from 'react';

import {
  Close,
  Delete,
  DragIndicator,
  Info,
  Settings
} from '@mui/icons-material';
import {
  Box,
  Card,
  Collapse,
  FormControlLabel,
  Hidden,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { useAppContext } from 'context';
import _ from 'lodash';
import uuid from 'react-uuid';

import InfoSection from '../InfoSection';

const RadioSettings = ({ onSettingChange, settings, setShowSettings }) => {
  return (
    <Card
      elevation={0}
      sx={{ my: 1, px: 1, backgroundColor: 'background.settings' }}
    >
      <Stack
        direction="row"
        spacing={1}
        px={1}
        py={1}
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        <IconButton
          color="tertiary"
          size="small"
          onClick={() => setShowSettings(prevShowSettings => !prevShowSettings)}
        >
          <Close color="primary" fontSize="small" />
        </IconButton>
        <Typography variant="caption">Radio Settings</Typography>
      </Stack>
      <Stack direction="row" spacing={1}>
        <TextField
          variant="filled"
          size="small"
          label="Name"
          color="tertiary"
          value={settings.name}
          onChange={event => onSettingChange?.('name', event.target.value)}
        />
        <TextField
          variant="filled"
          size="small"
          type="number"
          label="Radio Count"
          color="tertiary"
          value={settings.options?.length}
          onChange={event => {
            const count = Number(event.target.value);
            const optionsLength = settings.options?.length;
            if (typeof count === 'number')
              onSettingChange?.(
                'options',
                optionsLength < count
                  ? settings.options.concat(
                      ..._.range(count - optionsLength).map(i => ({
                        id: uuid(),
                        label: `Option ${optionsLength + i + 1}`,
                        order: (i + 1) * 2
                      }))
                    )
                  : _.take(settings.options, count)
              );
          }}
        />
      </Stack>
    </Card>
  );
};

const RadioOption = React.memo(function ({
  edit,
  label,
  onLabelChange,
  isSelected,
  value
}) {
  const [optionLabel, setOptionLabel] = useState(label);

  const handleChange = event => {
    setOptionLabel(event.target.value);
    onLabelChange?.(event.target.value);
  };

  return (
    <FormControlLabel
      value={value}
      control={
        <Radio
          sx={{
            '& .MuiSvgIcon-root': {
              opacity: isSelected ? 1 : 0.3,
              fontSize: 32
            }
          }}
        />
      }
      label={
        edit ? (
          <TextField size="small" value={optionLabel} onChange={handleChange} />
        ) : (
          label
        )
      }
    />
  );
});

const RadioOptions = React.memo(
  ({ state, options, data, handleDataClick, onNameChange }) => {
    const [radioData, setRadioData] = useState(data[0] ?? null);

    const handleRadioChange = event => {
      const selectedOption = options.find(
        option => option.id === event.target.value
      );
      setRadioData(selectedOption);
      handleDataClick(selectedOption);
    };

    return (
      <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
        <Stack
          direction="row"
          mt={1}
          ml={2}
          mb={2}
          sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}
        >
          <RadioGroup
            value={radioData ? radioData.id : ''}
            row
            onChange={handleRadioChange}
          >
            {options?.map(option => (
              <RadioOption
                key={option.id}
                edit={state.edit}
                label={option.label}
                onLabelChange={newLabel => onNameChange(option.id, newLabel)}
                value={option.id}
                isSelected={radioData?.id === option.id} // Pass isSelected based on current selection
              />
            ))}
          </RadioGroup>
        </Stack>
      </Box>
    );
  }
);

const RadioComponent = ({
  userInputID,
  component,
  dragHandleProps,
  initialData,
  name,
  options,
  onDataChange,
  onDelete,
  onSettingChange
}) => {
  const { state } = useAppContext();
  const [openInfo, setOpenInfo] = useState(false);

  const [selectedOptions, setSelectedOptions] = useState(initialData ?? []);
  const [settings, setSettings] = useState({
    options: JSON.parse(options),
    name
  });
  const [showSettings, setShowSettings] = useState(false);

  const handleDataClick = option => {
    let newSelectedOptions;

    const isOptionSelected = selectedOptions.some(
      selectedOption => selectedOption.id === option.id
    );

    if (isOptionSelected) {
      newSelectedOptions = [];
    } else {
      newSelectedOptions = [option];
    }

    setSelectedOptions(newSelectedOptions);
    onDataChange?.(newSelectedOptions, userInputID);
  };

  useEffect(() => {
    if (!settings.options || !settings.options.length) {
      const options = _.range(10).map(i => ({
        id: uuid(),
        label: `Option ${i + 1}`,
        order: (i + 1) * 2
      }));
      setSettings(prevSettings => ({ ...prevSettings, options }));
      onSettingChange?.('options', options);
    }
    /* eslint-disable */
  }, [settings]);

  const handleSettingChange = (settingName, settingValue) => {
    setSettings(prevSettings =>
      _.set({ ...prevSettings }, settingName, settingValue)
    );
    onSettingChange?.(settingName, settingValue);
  };

  const handleChipNameUpdate = (id, newName) => {
    const updatedOptions = settings.options.map(chip =>
      chip.id === id ? { ...chip, label: newName } : chip
    );
    handleSettingChange('options', updatedOptions);
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {state.edit && (
            <IconButton
              sx={{ ml: 1 }}
              onClick={() =>
                setShowSettings(prevShowSettings => !prevShowSettings)
              }
              size="small"
            >
              <Settings
                size="small"
                color={showSettings ? 'secondary' : 'primary'}
              />
            </IconButton>
          )}
          <Stack
            alignItems="center"
            width="100%"
            direction="row"
            spacing={2}
            justifyContent="space-between"
          >
            <Typography variant="h5"> {settings.name}</Typography>
            {component?.showInfo !== false && (
              <IconButton
                size="large"
                sx={{ ml: 1 }}
                onClick={() => setOpenInfo(prevOpenInfo => !prevOpenInfo)}
                color={openInfo ? 'secondary' : 'grey'}
              >
                <Info fontSize="large" />
              </IconButton>
            )}
          </Stack>
        </Box>
        {state.edit && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton onClick={onDelete}>
              <Delete />
            </IconButton>
            <IconButton {...dragHandleProps}>
              <DragIndicator />
            </IconButton>
          </Box>
        )}
      </Box>
      <Collapse in={state.edit && showSettings}>
        <Hidden smDown implementation="css">
          <RadioSettings
            onSettingChange={handleSettingChange}
            settings={settings}
            setShowSettings={setShowSettings}
          />
        </Hidden>
      </Collapse>
      <Collapse in={openInfo}>
        <InfoSection component={component} />
      </Collapse>
      <RadioOptions
        options={settings.options}
        data={selectedOptions}
        state={state}
        handleDataClick={handleDataClick}
        onSettingChange={onSettingChange}
        onNameChange={handleChipNameUpdate}
      />
    </Box>
  );
};

export default RadioComponent;

import { createTheme } from '@mui/material/styles';

const { palette } = createTheme();
const { augmentColor } = palette;

const createColor = mainColor => augmentColor({ color: { main: mainColor } });

export const darkTheme = createTheme({
  typography: {
    lineHeight: 1.2,
    h4: {
      fontSize: '2rem'
    },
    caption: {
      lineHeight: 1.2
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
          '&hover': {
            backgroundColor: '#51ABB7'
          }
        }
      }
    }
  },
  palette: {
    mode: 'dark',
    bgColor: createColor('#000e21'),
    grey: createColor('#757575'),
    waveColor: {
      main: '#D9D9D9'
    },
    headerButtons: {
      main: '#ADDCE2'
    },
    primary: {
      lightest: '#51ABB7',
      light: '#51ABB7',
      main: '#ADDCE2',
      dark: '#063F47',
      darkHover: '#09363d',
      darkest: '#033037'
    },
    tertiary: {
      main: '#A4A9D6'
    },
    secondary: {
      lightest: '#FEF0EA',
      light: '#A4A9D6',
      main: '#E39F4E'
    },
    divider: '#004282',
    background: {
      default: '#000e21',
      paper: '#000e21',
      card: '#0c1a2c'
    },
    text: {
      primary: '#fff',
      secondary: '#71717a',
      accent: '#FBFCF8'
    }
  }
});

export const lightTheme = createTheme({
  typography: {
    lineHeight: 1.2,
    h4: {
      fontSize: '2rem'
    },
    caption: {
      lineHeight: 1.2
    }
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: `
        ::selection {
          color: #2C1945;
          background-color: #ADDCE2;
        }
      `
    },
    MuiCard: {
      styleOverrides: {
        root: {
          '&[class*="MuiElevation-elevation4"]': {
            boxShadow: '1px 23px 50px 2px rgba(0, 33, 38, 0.15)'
          },
          '&[class*="MuiPaper-elevation4"]': {
            boxShadow: '1px 23px 50px 2px rgba(0, 33, 38, 0.15)'
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 'bold'
        }
      }
    },
    MuiCardHeader: {
      styleOverrides: {
        content: {
          overflow: 'hidden'
        }
      }
    },
    MuiPickerStaticWrapper: {
      styleOverrides: {
        content: {
          background: 'none'
        }
      }
    }
  },
  palette: {
    mode: 'light',
    bgColor: createColor('#f7f7f7'),
    lightGrey: createColor('#bdbdbd'),
    grey: createColor('#757575'),
    waveColor: {
      main: '#272941'
    },
    headerButtons: {
      main: '#ADDCE2'
    },
    primary: {
      lightest: '#ADDCE2',
      light: '#51ABB7',
      main: '#126A76',
      dark: '#063F47',
      darkHover: '#09363d',
      darkest: '#033037'
    },
    secondary: {
      lightest: '#FEF0EA',
      light: '#A4A9D6',
      main: '#E39F4E'
    },
    tertiary: {
      light: '#A4A9D6',
      main: '#747aad',
      dark: '#8884d8'
    },
    divider: '#51ABB7',
    background: {
      default: '#f7f7f7',
      paper: '#fff',
      card: 'rgba(0, 0, 0, 0.06)',
      settings: 'rgb(227 159 78 / 10%)'
    },
    text: {
      primary: '#2C1945',
      secondary: '#71717a',
      accent: '#033037',
      lightAccent: '#126A76'
    }
  }
});

import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { ArrowForward, EmojiEvents } from '@mui/icons-material';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Typography
} from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import { useAppContext } from 'context';
import { userInputsByComponentID } from 'graphql/customQueries';
import moment from 'moment-timezone';
import { useNavigate } from 'react-router';

import {
  ClientName,
  ClientNameSub,
  Preview,
  ProfileAvatar
} from 'components/atoms';

import { formatProgram } from 'utils/formatProgram';
import { toRomanNumeral } from 'utils/toRomanNumerals';

function ClientStage({ stage }) {
  if (!stage) return null;
  return (
    <Typography
      variant="caption"
      sx={{ display: 'flex', alignItems: 'center' }}
    >
      <span style={{ color: '#71717a' }}>STAGE</span>
      <strong style={{ paddingLeft: '10px' }}>
        {toRomanNumeral(stage)}
        {stage === 5 && (
          <Box>
            <EmojiEvents />
          </Box>
        )}
      </strong>
    </Typography>
  );
}

function ClientCard({ client }) {
  const { dispatch } = useAppContext();
  const [inputs, setInputs] = useState(null);
  const navigate = useNavigate();

  const toLocalDate = (date, timeZone, startOfDay = true) => {
    const localDate = new Date(
      date.toLocaleString('en-US', { timeZone: timeZone })
    );

    if (startOfDay) {
      localDate.setHours(0, 0, 0, 0);
    } else {
      localDate.setHours(23, 59, 59, 999);
    }

    return localDate;
  };

  const weekDays = useMemo(() => ['s', 'm', 't', 'w', 't', 'f', 's'], []);

  const generateDayKey = date =>
    `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1)
      .toString()
      .padStart(2, '0')}`;

  const getYesterdayInLocalTime = timeZone => {
    const now = new Date();
    now.setDate(now.getDate() - 1);
    const localString = now.toLocaleString('en-US', { timeZone: timeZone });
    const localDate = new Date(localString);
    localDate.setHours(23, 59, 59, 999);
    return localDate;
  };

  const getRecordsData = useCallback(async () => {
    const timeZone = moment.tz.guess();
    const yesterday = getYesterdayInLocalTime(timeZone);
    const userEndDate = yesterday;

    const userStartDate = toLocalDate(yesterday, timeZone);
    userStartDate.setDate(yesterday.getDate() - 6);

    const variables = {
      componentID: client?.ssComponentID,
      enteredAt: {
        between: [userStartDate.toISOString(), userEndDate.toISOString()]
      },
      filter: { _deleted: { ne: true } },
      sortDirection: 'ASC'
    };

    const res = await API.graphql(
      graphqlOperation(userInputsByComponentID, variables)
    );
    const results = res.data.userInputsByComponentID.items;

    const dataMap = {};

    for (const result of results) {
      const resultDate = toLocalDate(
        new Date(result.enteredAt),
        timeZone,
        true
      );

      const dayKey = generateDayKey(resultDate);
      const dayOfWeek = weekDays[resultDate.getDay()];

      if (dataMap[dayKey]) {
        dataMap[dayKey].count += 1;
        dataMap[dayKey].totalValue += result.raterValue;
      } else {
        dataMap[dayKey] = {
          count: 1,
          totalValue: result.raterValue,
          dayOfWeek
        };
      }
    }

    const output = [];
    for (let i = 0; i < 7; i++) {
      const date = new Date(userStartDate);
      date.setDate(userStartDate.getDate() + i);
      const dayKey = generateDayKey(date);
      const dayOfWeek = weekDays[date.getDay()];

      const value = dataMap[dayKey]
        ? dataMap[dayKey].totalValue / dataMap[dayKey].count
        : null;

      output.push({
        date: dayKey,
        day: dayOfWeek,
        value
      });
    }

    setInputs(output);
  }, [client?.ssComponentID, weekDays]);

  useEffect(() => {
    if (client?.ssComponentID) {
      getRecordsData();
    }
    return () => {
      setInputs(null);
    };
  }, [client, getRecordsData]);

  function goToClient() {
    dispatch({ type: 'add_client', client: client });
    navigate('/client');
  }

  return (
    <Grid item xl={3} lg={4} md={6} xs={12} sm={6}>
      <Card elevation={4} sx={{ height: '100%' }}>
        <CardHeader
          sx={{ display: 'flex' }}
          disableTypography
          action={
            <IconButton onClick={goToClient} aria-label="settings">
              <ArrowForward />
            </IconButton>
          }
          title={<ClientName client={client} />}
          avatar={<ProfileAvatar profile={client} />}
          subheader={<ClientNameSub client={client} />}
        />
        <CardContent
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            flexWrap: 'wrap'
          }}
        >
          <Box>{client?.ssComponentID && <Preview inputs={inputs} />}</Box>
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            <Typography
              fontWeight={'bold'}
              variant="caption"
              textTransform="uppercase"
            >
              {client?.program && formatProgram(client?.program, true)}
            </Typography>
            {!client?.program.toLowerCase().includes('monitoring') && (
              <ClientStage stage={client?.stage} />
            )}
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default ClientCard;

import { useAuthenticator } from '@aws-amplify/ui-react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { useAppContext } from 'context';

function RatingItem({ rating, description }) {
  return (
    <>
      <Grid item xs={2} sm={1}>
        <Typography variant="caption">
          <strong>{rating}</strong>
        </Typography>
      </Grid>
      <Grid item xs={10} sm={11}>
        <Typography variant="caption">{description}</Typography>
      </Grid>
      {rating !== '8' && (
        <Grid item xs={12}>
          <Box pt={0.5}>
            <Divider sx={{ opacity: 0.3 }} />
          </Box>
        </Grid>
      )}
    </>
  );
}

export default function RatingGuideSeverity() {
  const { user } = useAuthenticator(context => [context.user]);
  const { state } = useAppContext();
  const program = state.client?.program || user.attributes['custom:program'];
  const forValue = state.client?.forValue || user.attributes['custom:for'];

  const camperdownRatings = [
    { rating: '0', description: 'no stuttering' },
    {
      rating: '1',
      description: 'extremely mild stuttering'
    },
    {
      rating: '2 - 3',
      description: 'mild stuttering'
    },
    {
      rating: '4 - 5',
      description: 'moderate stuttering'
    },
    {
      rating: '6 - 7',
      description: 'severe stuttering'
    },
    {
      rating: '8',
      description: 'extremely severe stuttering'
    }
  ];
  const lidcombeRatings = [
    { rating: '0', description: 'no stuttering' },
    {
      rating: '1',
      description: 'extremely mild stuttering'
    },
    {
      rating: '10',
      description: 'extremely severe stuttering'
    }
  ];

  const guideToShow =
    forValue === 'adult' || program?.includes('camperdown')
      ? camperdownRatings
      : lidcombeRatings;

  return (
    <Box>
      <Grid container>
        {guideToShow.map(({ rating, description }) => (
          <RatingItem rating={rating} description={description} key={rating} />
        ))}
      </Grid>
    </Box>
  );
}

import { useEffect, useState } from 'react';

import { Auth } from '@aws-amplify/auth';
import { useAuthenticator } from '@aws-amplify/ui-react';
import {
  Face2,
  FaceRetouchingNatural,
  RadioButtonChecked,
  RadioButtonUnchecked
} from '@mui/icons-material';
import {
  Box,
  Card,
  CardActionArea,
  CircularProgress,
  Grid,
  Stack,
  Typography
} from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import { createUserAfterSignUp } from 'graphql/queries';

import { ErrorMessage } from 'components/atoms';

export default function TypeChooser() {
  const { user } = useAuthenticator(context => [context.user]);
  const [selectedType, setSelectedType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const savedType = localStorage.getItem('type');
    if (savedType) {
      setSelectedType(savedType);
      handleSelectType(savedType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectType = async type => {
    setLoading(true);
    setSelectedType(type);
    localStorage.setItem('type', type);

    try {
      let onboarding;
      const variables = {
        type: type,
        id: user.username,
        sub: user.attributes.sub,
        firstName: user.attributes.given_name,
        lastName: user.attributes.family_name,
        email: user.attributes.email
      };

      const { data } = await API.graphql(
        graphqlOperation(createUserAfterSignUp, variables)
      );

      if (data.createUserAfterSignUp.statusCode !== 200) {
        throw new Error(data.createUserAfterSignUp.body);
      }
      if (type === 'clinician') {
        onboarding = '4';
      } else {
        onboarding = '1';
      }
      await Auth.updateUserAttributes(user, {
        'custom:type': type,
        'custom:onboarding': onboarding
      });
    } catch (error) {
      console.error('Error updating user attributes:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return loading ? (
    <Box
      width="100%"
      minHeight="80vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress />
    </Box>
  ) : (
    !selectedType && !error && (
      <Card
        elevation={4}
        sx={{
          p: 2,
          px: 4,
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
          width: '100%'
        }}
      >
        <Box my={2}>
          <Typography variant="h5">Let's get set up!</Typography>
        </Box>
        <Box my={2}>
          <Typography variant="h6">I am a: </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Card
              elevation={0}
              sx={{
                backgroundColor: 'background.card',
                color: 'primary.dark'
              }}
            >
              <CardActionArea
                onClick={() => handleSelectType('client')}
                sx={{ p: 2 }}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Stack direction="row" spacing={2} alignItems="center">
                    <FaceRetouchingNatural fontSize="large" />
                    <Typography variant="h6"> Client</Typography>
                  </Stack>
                  <Box display="flex">
                    {selectedType === 'client' ? (
                      <RadioButtonChecked sx={{ fontSize: 36 }} />
                    ) : (
                      <RadioButtonUnchecked sx={{ fontSize: 36 }} />
                    )}
                  </Box>
                </Box>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card
              elevation={0}
              sx={{
                backgroundColor: 'background.card',
                color: 'primary.dark'
              }}
            >
              <CardActionArea
                onClick={() => handleSelectType('clinician')}
                sx={{ p: 2 }}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Face2 fontSize="large" />
                    <Typography variant="h6"> Clinician</Typography>
                  </Stack>
                  <Box display="flex">
                    {selectedType === 'clinician' ? (
                      <RadioButtonChecked sx={{ fontSize: 36 }} />
                    ) : (
                      <RadioButtonUnchecked sx={{ fontSize: 36 }} />
                    )}
                  </Box>
                </Box>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
        {Boolean(error) && (
          <Box my={2}>
            <ErrorMessage message={error} />
          </Box>
        )}
      </Card>
    )
  );
}

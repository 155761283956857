import { Box, Typography } from '@mui/material';

export default function DisplayInput({ input }) {
  return (
    <Box display={'flex'} alignItems="center">
      <Box display="flex" alignItems="center" height="50px">
        <Typography
          sx={{
            maxWidth: '120px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          }}
        >
          {input.inputValue}
        </Typography>
      </Box>
    </Box>
  );
}

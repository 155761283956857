export default function formatTime(seconds) {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.round(seconds % 60);
  return [h, m > 9 ? m : h ? '0' + m : m || '0', s > 9 ? s : '0' + s]
    .filter(Boolean)
    .join(':');
}

export function formatDate(dateString) {
  // Manually parse date string
  const dateParts = dateString.split('T')[0].split('-');
  const year = dateParts[0];
  const month = dateParts[1] - 1; // JavaScript months are 0-indexed
  const day = dateParts[2];

  // Create date object
  const date = new Date(year, month, day);

  // Define format options
  const options = { day: 'numeric', month: 'short', year: 'numeric' };

  // Format and return date string
  return date.toLocaleDateString('en-US', options);
}

// export function formatAudioTime(timeInSeconds) {
//   let minutes = Math.floor(timeInSeconds / 60);
//   let seconds = Math.round(timeInSeconds % 60);
//   return minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
// }

export function formatDateTime(isoString) {
  const date = new Date(isoString);
  const optionsDate = { year: 'numeric', month: 'long', day: 'numeric' };
  const optionsTime = { hour: '2-digit', minute: '2-digit', second: '2-digit' };

  const localDate = date.toLocaleDateString(undefined, optionsDate);
  const localTime = date.toLocaleTimeString(undefined, optionsTime);

  return `${localDate}, ${localTime}`;
}

export function formatAudioTime(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.round(seconds % 60);

  // Formatting as per requirements
  if (hours > 0) {
    return `${hours}:${String(minutes).padStart(2, '0')}:${String(
      remainingSeconds
    ).padStart(2, '0')}`;
  } else {
    return `${String(minutes).padStart(2, '0')}:${String(
      remainingSeconds
    ).padStart(2, '0')}`;
  }
}

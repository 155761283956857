import { useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  Stack,
  Step,
  StepLabel,
  Stepper
} from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import { useSnackbar } from 'context/SnackBar';
import { createInvitation } from 'graphql/mutations';
import { cliniciansClinicConnection, createClinician } from 'graphql/queries';
import * as yup from 'yup';

import { ErrorMessage } from 'components/atoms';

import Modal from '../Modal';
import StepOne from './StepOne';
import StepTwo from './StepTwo';

const stepZeroValidationSchema = yup.object({
  email: yup.string().email('Invalid email').required('Email is required.'),
  firstName: yup.string().required('First name is required.'),
  lastName: yup.string().required('Last name is required.'),
  permission: yup.string().required('A permission is required.'),
  location: yup
    .mixed()
    .nullable()
    .test(
      'is-valid-location',
      'A practice manager must have a location assigned.',
      function (value) {
        const { permission } = this.parent;
        if (permission === 'practicemanager') {
          return value && Object.keys(value).length > 0;
        }
        return true;
      }
    )
});

export default function AddClinicUser({
  clinic,
  open,
  setOpen,
  locations,
  setLocations,
  clinicians,
  setClinicians
}) {
  const { user } = useAuthenticator(context => [context.user]);
  const steps = ['Details', 'Complete'];
  const [activeStep, setActiveStep] = useState(0);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('clinician');
  const [location, setLocation] = useState({});
  const [howManyClients, setHowManyClients] = useState(null);
  const [errors, setErrors] = useState(null);
  const [error, setError] = useState();
  const [creatingUser, setCreatingUser] = useState(false);
  const clinicID = user.attributes['custom:clinicID'];
  const [clinicianExists, setClinicianExists] = useState(false);
  const [existingClinician, setExistingClinician] = useState();
  const { enqueueSnackbar } = useSnackbar();

  const validateStep = async step => {
    const schemas = [stepZeroValidationSchema];
    try {
      await schemas[step].validate(
        {
          email,
          firstName,
          lastName,
          permission: role,
          location
        },
        { abortEarly: false }
      );
      return true;
    } catch (err) {
      const errors = err.inner.reduce((acc, error) => {
        acc[error.path] = error.message;
        return acc;
      }, {});
      setErrors(errors);
      return false;
    }
  };

  const submitForm = async () => {
    try {
      setCreatingUser(true);
      if (clinicianExists) {
        const variables = {
          role,
          inviteeID: existingClinician.id,
          action: 'clinician_without_clinic',
          createdBy: user.username,
          clinicID: clinicID,
          locationID: location.id ? location.id : null
        };

        const { data } = await API.graphql(
          graphqlOperation(createInvitation, { input: variables })
        );
        const invitationID = data.createInvitation.id;
        const sendInvitation = await API.graphql(
          graphqlOperation(cliniciansClinicConnection, {
            action: 'clinician_without_clinic',
            inviteeID: existingClinician.id,
            firstName: user.attributes.given_name,
            lastName: user.attributes.family_name,
            email: user.attributes.email,
            role,
            name: clinic.name,
            invitationID: invitationID
          })
        );
        const res = sendInvitation.data.cliniciansClinicConnection;
        if (res.statusCode !== 200) {
          enqueueSnackbar('Something went wrong. Try again.', {
            severity: 'error'
          });
          throw new Error(res.body);
        } else {
          enqueueSnackbar(`Sent invitation to ${firstName}`, {
            severity: 'success'
          });
        }
      } else {
        const variables = {
          clinicianFirstName: firstName,
          clinicianLastName: lastName,
          clinicianEmail: email,
          role,
          locationID: location?.id,
          clinicID: clinicID,
          createdBy: user.username,
          createdByFirstName: user.attributes.given_name,
          createdByLastName: user.attributes.family_name,
          source: 'clinician'
        };
        const { data } = await API.graphql(
          graphqlOperation(createClinician, variables)
        );
        const parsedData = JSON.parse(data.createClinician);
        if (parsedData.statusCode !== 200) {
          throw new Error(parsedData.error);
        } else {
          const newClinicianID = parsedData.clinicianID;
          const newClinician = {
            id: newClinicianID,
            firstName,
            lastName,
            email,
            role,
            clinicID,
            locationID: location?.id
          };
          setClinicians([...clinicians, newClinician]);
          enqueueSnackbar(`Created and invited clinician: ${firstName}`, {
            severity: 'success'
          });
        }
      }

      handleReset();
      setOpen(false);
    } catch (err) {
      console.log(err);
      setError(err.message);
      enqueueSnackbar(err.message, {
        severity: 'error'
      });
    } finally {
      setCreatingUser(false);
    }
  };

  const handleNext = async () => {
    const isValidStep = await validateStep(activeStep);
    if (isValidStep) {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
      setErrors({});
    }
  };

  const handleReset = async () => {
    setActiveStep(0);
    setFirstName('');
    setLastName('');
    setEmail('');
    setRole('clinician');
    setLocation({});
    setHowManyClients(null);
    setErrors(null);
    setError();
    setCreatingUser(false);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const canReset = () => {
    const isAnyFieldChanged =
      firstName !== '' ||
      lastName !== '' ||
      email !== '' ||
      role !== 'clinician' ||
      (location && Object.keys(location).length > 0) ||
      howManyClients !== null;

    return isAnyFieldChanged;
  };

  return (
    <Modal
      open={open}
      setClose={() => setOpen(false)}
      title="New user"
      fullWidth
      maxWidth="sm"
    >
      <Box>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map(label => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent={creatingUser ? 'center' : 'flex-start'}
          alignItems="center"
          sx={{ minHeight: 400, py: 2 }}
        >
          {Boolean(error) ? (
            <ErrorMessage message={error} />
          ) : creatingUser ? (
            <Box
              sx={{
                height: '100%',
                width: '100%',
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box height="100%" width="100%">
              {activeStep === 0 && (
                <StepOne
                  existingClinician={existingClinician}
                  setExistingClinician={setExistingClinician}
                  clinicianExists={clinicianExists}
                  setClinicianExists={setClinicianExists}
                  errors={errors}
                  setErrors={setErrors}
                  setHowManyClients={setHowManyClients}
                  location={location}
                  setLocation={setLocation}
                  locations={locations}
                  setLocations={setLocations}
                  firstName={firstName}
                  setFirstName={setFirstName}
                  lastName={lastName}
                  setLastName={setLastName}
                  email={email}
                  setEmail={setEmail}
                  role={role}
                  setRole={setRole}
                />
              )}
              {activeStep === 1 && (
                <StepTwo
                  howManyClients={howManyClients}
                  location={location}
                  locations={locations}
                  firstName={firstName}
                  lastName={lastName}
                  email={email}
                  role={role}
                />
              )}
            </Box>
          )}
        </Box>
        <DialogActions
          sx={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Button size="small" disabled={activeStep === 0} onClick={handleBack}>
            Back
          </Button>

          <Stack direction="row" alignItems="center" spacing={2}>
            <Button size="small" disabled={!canReset()} onClick={handleReset}>
              Reset
            </Button>
            <LoadingButton
              size="small"
              loading={creatingUser}
              variant="contained"
              onClick={
                activeStep === steps.length - 1 ? submitForm : handleNext
              }
            >
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </LoadingButton>
          </Stack>
        </DialogActions>
      </Box>
    </Modal>
  );
}
